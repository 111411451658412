import { useState, useEffect, useContext, useRef } from 'react';
import { Autoheal_func } from '../../../api/automation/autoheal_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Card,
  Input,
  Subtitle2,
  Dropdown,
  Link,
  Image,
} from '@fluentui/react-components';
import {
  Delete20Regular,
  SaveCopy20Regular,
  ColumnTripleEdit20Regular,
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  CalendarClock20Regular,
  Wand20Regular,
  SettingsCogMultiple20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  AddSquare20Regular,
  Copy20Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  SlideEraser20Regular,
  Info24Filled,
  Dismiss16Regular,
  Open20Regular,
  Info20Filled,
  CheckmarkLock20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { Inputs } from '../../../components/textarea/input.component';
import { Intent } from '../../../components/messagebar/messagebar.component';
import DialogBox from '../../../components/dialog/dialog.component';
import { Form } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../../components/form-components';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, lightTheme, messageTheme } from '../../../styles/customStyles';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Autoheal = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('Intro');
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [automationOverLayData, setAutomationOverLayData]: any = useState([]);
  const [jsonSchema, setJsonData]: any = useState({});
  const [uiSchema, setUiData]: any = useState({});
  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [formEditedData, setFormEditedData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [deleteAutomation, setDeleteAutomation]: any = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [automationSearchVal, setAutomationSearchVal] = useState('');
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [automationStatus, setAutomationStatus] = useState('not attached');
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];

  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };

  var timer: any = '';
  const handleNewFormChange = (e: any) => {
    if (e.formData?.AutomationExecute?.length > 0) {
      timer = clearTimeout(timer);
      timer = setTimeout(() => {
        setAutomationSearchVal(e.formData.AutomationExecute);
      }, 1000);
    }

    setFormData({ ...formData, jsonData: e.formData });
  };

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name || (overlayName == 'editAutomation' && val.automationName === formEditedData.name)) {
        flag = false;
      }
    });

    if (overlayName == 'editAutomation' && formEditedData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, formEditedData, selectedAutomation]);

  const handleEditFormChange = (e: any) => {
    setFormEditedData({ ...formEditedData, jsonData: e.formData });
  };
  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations/configuration')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/selfhelp');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1>Self-Help </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => {
          localStorage.setItem('dartNo', '69');
          contextValue.setDartNo(69);
          navigate('/automate/automation/autoheal');
        }}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Wand20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Autoheal </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/automationschedule');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CalendarClock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automation Schedules </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '241');
        contextValue.setDartNo(241);
        navigate('/automate/automation/usersurvey');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ChatMultipleHeart20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> User Surveys </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key={3}
      onClick={() => {
        navigate('/automate/automation/compliance_policies/policy_configuration');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckmarkLock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here
  //fiter table clolumn start here
  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        {/* <Stack tokens={{childrenGap:10}} horizontal> */}
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            {/* <Stack tokens={{childrenGap:6}}>
                <StackItem><Text></Text></StackItem> */}
            <StackItem
              onClick={() => {
                setOverlayOpen(true);
                setAutomationStatus('not attached');
                setOverlayName('addAutomation');
                setFormData({ ...formData, name: '', description: '', jsonData: '' });
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <AddSquare20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Add Automation </Caption1>
                </StackItem>
              </Stack>
            </StackItem>
            {/* </Stack> */}
          </Button>
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <StackItem>
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                <StackItem>
                  <Stack tokens={{childrenGap:5}} horizontal>
                    <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
                    <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
                  </Stack>
                </StackItem>
            </Button>
          </StackItem>
 
          <StackItem>
        <Divider vertical/>
        </StackItem> */}

        <StackItem>
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {/* <Delete20Regular {...primaryIcon} /> */}
                      {isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      <Caption1> Delete </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Kindly verify your intent to delete the automation."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setDeleteAutomation(true);
                        userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} autoheal automations deleted"}`);
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <Stack tokens={{childrenGap:6}}> */}
        {/* <StackItem><Text></Text></StackItem> */}
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="autoheal_automations.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"Autoheal automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>

        {/* <StackItem>
              <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button>
            </StackItem> */}
        {/* </Stack>
        </Stack> */}
      </>
    ),
  };

  useEffect(() => {
    if (dartSpecs?.profile_DartTemplateDetails?.length > 0) {
      var details = dartSpecs.profile_DartTemplateDetails.length > 0 ? dartSpecs.profile_DartTemplateDetails[0].details : '{}';
      details = JSON.parse(details);

      overlayName == 'addAutomation' &&
        setAutomationOverLayData({
          headerTitle: 'Add a New Automation',
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myFormAutoheal" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <p>
                      <b>
                        Name
                        <Image src="/visualization/v2/image/required.svg" />
                      </b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                    </p>
                    {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                  </StackItem>

                  <StackItem>
                    <p>
                      <b>What does this Automation Do?</b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormData} inputValue={formData} />
                    </p>
                  </StackItem>

                  {/* <StackItem>
              <Intent title="" message="These are the basic details associated with this automation."></Intent>
            </StackItem> */}
                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These are the basic details associated with this automation. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>
                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configuration: (
              <Form
                ref={formRef}
                formData={formData.jsonData}
                schema={jsonSchema}
                uiSchema={uiSchema}
                validator={validator}
                widgets={CustomWidgets}
                onChange={e => handleNewFormChange(e)}
              />
            ),

            'Review Data': (
              <>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <p>
                      <b>Name</b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                    </p>
                  </StackItem>

                  <StackItem>
                    <p>
                      <b>What does this Automation Do?</b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                    </p>
                  </StackItem>
                </Stack>
                <Form formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} disabled />
              </>
            ),
          },
        });
    }

    Object.keys(formData).length > 0 &&
      overlayName == 'editAutomation' &&
      automationStatus === 'not attached' &&
      setAutomationOverLayData({
        headerTitle: `Edit ${selectedAutomation} automation`,
        size: 'large',
        isTabs: true,
        tabItems: {
          Introduction: (
            <form id="myFormAutoheal" onSubmit={e => handleSubmit(e)}>
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>
                      Name
                      <Image src="/visualization/v2/image/required.svg" />
                    </b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} required={true} />
                  </p>
                  {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} />
                  </p>
                </StackItem>
                {Object.keys(details).map(key => (
                  <>
                    <StackItem>
                      <p>
                        <b>{key}</b>
                      </p>
                    </StackItem>
                    <StackItem>
                      <p>{details[key]}</p>
                    </StackItem>
                  </>
                ))}
              </Stack>
              <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                Validate
              </Button>
            </form>
          ),

          Configure: (
            <Stack tokens={{ childrenGap: 15, padding: 10 }}>
              <Form
                ref={formRef}
                formData={formEditedData.jsonData}
                schema={jsonSchema}
                uiSchema={uiSchema}
                validator={validator}
                widgets={CustomWidgets}
                onChange={e => handleEditFormChange(e)}
              />
            </Stack>
          ),

          'Review Data': (
            <>
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} />
                  </p>
                </StackItem>
              </Stack>
              <Form
                formData={formEditedData.jsonData}
                schema={jsonSchema}
                uiSchema={uiSchema}
                validator={validator}
                widgets={CustomWidgets}
                disabled
              />
            </>
          ),
        },
      });

    Object.keys(formData).length > 0 &&
      overlayName == 'editAutomation' &&
      automationStatus !== 'not attached' &&
      setAutomationOverLayData({
        headerTitle: `${selectedAutomation} automation`,
        size: 'large',
        isTabs: true,
        tabItems: {
          Configuration: (
            <>
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} />
                  </p>
                </StackItem>
              </Stack>
              <Form
                formData={formEditedData.jsonData}
                schema={jsonSchema}
                uiSchema={uiSchema}
                validator={validator}
                widgets={CustomWidgets}
                disabled
              />
            </>
          ),
        },
      });
  }, [dartSpecs, overlayName, formData, formEditedData, classificationNameValidation]);
  // overflow set contents code ends here

  var tempTableData: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modified: val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-',
          status: status,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);
    var status = '';

    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON?.map((val: any, i: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'Not attached' ? 'show' : 'hide',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={(e: any) => {
                    setRowID(val.id);
                    setOverlayOpen(true);
                    setOverlayName('editAutomation');
                    val.status.includes(`${contextValue.tenantName}:`) ? setAutomationStatus('attached') : setAutomationStatus('not attached');
                    setSelectedAutomation(val.automationName);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {val.createdOn ? new Date(val.createdOn * 1000).toLocaleString() : '-'} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  var timer: any;
  const searchHandle = (data: any) => {
    // timer = clearTimeout(timer);

    // timer = setTimeout(() => {
    //   setSearchVal(data.target.value);
    //   setCurrentPageNo(1);
    // },1500);

    var searchData = exportDetails.filter((val: any) => val.automationName.includes(data));

    var searchedDataObj: any = { core_AutomationJSON: [] };
    searchedDataObj.core_AutomationJSON = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil(searchData.length / 50));
    setCurrentPageNo(1);
  };

  useEffect(() => {
    !overlayOpen && setOverlayName('');
  }, [overlayOpen]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Autoheal',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  useEffect(() => {
    if (saveBtnClicked) {
      overlayName == 'addAutomation' &&
        userActivity(
          'Add',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(formData.jsonData)},"type":"Autoheal","dartNo":"69","summary":"${
            formData.name
          } automation is created"}`,
        );

      overlayName == 'editAutomation' &&
        userActivity(
          'Edit',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(
            formEditedData.jsonData,
          )},"type":"Autoheal","dartNo":"69","summary":"${formEditedData.name} automation is edited"}`,
        );
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Automation" subPageName="All Automation" subPageLink="/automate/automation/all_automations" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <StackItem>
              <Stack verticalAlign="center">
                {/* <StackItem> <Text> <Breadcrumbs pageName="Automation"/> </Text> </StackItem> */}
                <Stack tokens={{ childrenGap: 25 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <FluentProvider theme={webLightTheme}>
                      <Title3> Automation | Auto-heal </Title3>
                    </FluentProvider>
                  </StackItem>
                  {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
                </Stack>

                <Stack tokens={{ childrenGap: 20 }}>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>

                <FluentProvider theme={webLightTheme}>
                  <Stack horizontal>
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                    <StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 1 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                          <Divider />
                        </StackItem>
                        <StackItem>
                          <StackItem>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                              <SearchBox
                                dismiss={null}
                                onChange={(e: any) => {
                                  searchHandle(e.target.value);
                                }}
                                placeholder="Search"
                                size="small"
                              />
                              <StackItem>
                                <MenuDropdown
                                  open={filterMenuOpen}
                                  btn={
                                    <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <Filter20Filled {...primaryIcon} />
                                        <Body1> Filter </Body1>
                                      </Stack>
                                    </MenuButton>
                                  }
                                  itemVal={
                                    <>
                                      <Body1Strong> Add a Filter </Body1Strong>
                                      <Divider />
                                      <MenuList>
                                        <Stack tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Filter </Caption1>
                                          </StackItem>
                                          <DropdownComponent
                                            optionData={columns1}
                                            setDropDownValue={setDropDownValue1}
                                            dropDownvalue={dropDownValue1}
                                          />
                                          <StackItem>
                                            <Divider />
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Value </Caption1>
                                          </StackItem>
                                          {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                          <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                          <StackItem>
                                            <Divider />
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <StackItem>
                                              <Button
                                                appearance="primary"
                                                onClick={() => {
                                                  handleFilterArray();
                                                  setFilterMenuOpen(false);
                                                }}
                                              >
                                                <Caption1> Apply </Caption1>
                                              </Button>
                                            </StackItem>
                                            <StackItem>
                                              <Button onClick={() => setFilterMenuOpen(false)}>
                                                <Caption1> Cancel </Caption1>
                                              </Button>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </MenuList>
                                    </>
                                  }
                                />
                              </StackItem>
                              <StackItem>
                                {filterArray.length > 0 &&
                                  filterArray.map((item, index) => (
                                    <Badge appearance="filled" color="informative">
                                      <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem> {item} </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </Badge>
                                  ))}
                              </StackItem>
                              <StackItem
                                onClick={() => {
                                  setFilterArray([]);
                                  setIsFilter(false);
                                  setDropDownValue2('');
                                  setResetDisabled(true);
                                }}
                              >
                                <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem>
                                      <FilterDismiss20Filled />
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Reset Filters </Caption1>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                          <StackItem>
                            <FluentProvider theme={messageTheme}>
                              <Card appearance="subtle" size="small">
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Info24Filled {...primaryIcon} />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem>
                                    {/* <Stack> */}
                                    <StackItem>
                                      <Caption1>
                                        Explore the Nanoheal Database for an in-depth understanding of this Automation Schedules. Learn more about its
                                        capabilities, features,and recommended practices to enhance your utilization of this automation solution
                                        within your ecosystem.
                                      </Caption1>
                                    </StackItem>
                                    {/* </Stack> */}
                                  </StackItem>

                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Stack
                                          tokens={{ childrenGap: 10 }}
                                          horizontal
                                          onClick={() => (window.location.href = 'https://www.google.com')}
                                        >
                                          <StackItem>
                                            <Link>
                                              <Caption1 underline> Know&nbsp;More </Caption1>
                                            </Link>
                                          </StackItem>
                                          <StackItem>
                                            <Open20Regular {...primaryIcon} />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Card>
                            </FluentProvider>
                          </StackItem>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                          {tableData.length > 0 ? (
                            <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                              <StackItem>
                                {totalRecords > 0 && (
                                  <Caption1>
                                    Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50}
                                    of {totalRecords} Records
                                  </Caption1>
                                )}
                              </StackItem>
                              <StackItem>
                                <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                              </StackItem>
                            </Stack>
                          ) : (
                            <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Caption1> No Results.</Caption1>
                              </StackItem>
                            </Stack>
                          )}
                        </StackItem>

                        {!saveBtnClicked && overlayName == '' && (
                          <Autoheal_func
                            data={final_data}
                            func={set_final_data}
                            setDartSpecs={setDartSpecs}
                            searchVal={searchVal}
                            pageNo={(currentPageNo - 1) * 50}
                            setTotalPages={setTotalPages}
                            setTotalRecords={setTotalRecords}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                            setJsonData={setJsonData}
                            setUiData={setUiData}
                            deleteAutomation={deleteAutomation}
                            setDeleteAutomation={setDeleteAutomation}
                            setDeleteDisabled={setDeleteDisabled}
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            overlayName={overlayName}
                            setIsToast={setIsToast}
                            setExportDetails={setExportDetails}
                            setRefreshToast={setRefreshToast}
                          />
                        )}

                        {saveBtnClicked && (
                          <Autoheal_func
                            newData={overlayName == 'addAutomation' ? formData : formEditedData}
                            saveBtnClicked={saveBtnClicked}
                            setSaveBtnClicked={setSaveBtnClicked}
                            overlayName={overlayName}
                            rowID={rowID}
                            setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                            func={set_final_data}
                            setIsToast={setIsToast}
                            setOverlayOpen={setOverlayOpen}
                            setErrorToast={setErrorToast}
                            setToastMessage={setToastMessage}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                          />
                        )}

                        {overlayName != '' && (
                          <Autoheal_func
                            rowID={rowID}
                            overlayName={overlayName}
                            setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                            formData={overlayName == 'addAutomation' ? formData : formEditedData}
                            setJsonData={setJsonData}
                            jsonSchema={jsonSchema}
                            setUiData={setUiData}
                            setDartSpecs={setDartSpecs}
                            automationSearchVal={automationSearchVal}
                            setErrorToast={setErrorToast}
                            setToastMessage={setToastMessage}
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            setRefreshToast={setRefreshToast}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                          />
                        )}

                        {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} setOverlayOpen={setOverlayOpen} />}

                        {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                      </Stack>
                    </StackItem>
                  </Stack>
                </FluentProvider>
              </Stack>
              <OverLay
                overlayopen={overlayOpen}
                setOverlayOpen={setOverlayOpen}
                overlayData={automationOverLayData}
                saveBtnFunc={setSaveBtnClicked}
                isNavBtn={automationStatus !== 'not attached' ? false : true}
                formRef={formRef}
                handleOutsideButton={handleOutsideButton}
                handleSubmit={handleSubmit}
                classificationNameValidation={classificationNameValidation}
              />

              {isToast && (
                <ToastComponent
                  setmessagebar={setIsToast}
                  isToastEnabled={isToast}
                  toastIntent="success"
                  position="top-end"
                  title="Success"
                  message={
                    !saveBtnClicked && overlayName == ''
                      ? 'Automation Deleted Successfully'
                      : overlayName == 'addAutomation'
                      ? 'New Automation Created Successfully'
                      : 'Automation Edited Successfully'
                  }
                  footer=""
                />
              )}

              {errorToast && (
                <ToastComponent
                  setmessagebar={setErrorToast}
                  toastIntent="error"
                  position="top-end"
                  title="Failed"
                  message={toastMessage}
                  footer=""
                />
              )}
              {refreshToast && (
                <ToastComponent
                  setmessagebar={setIsToast}
                  isToastEnabled={refreshToast}
                  toastIntent="success"
                  position="top-end"
                  title="Success"
                  message={'Data Table Refreshed'}
                  footer=""
                />
              )}
            </StackItem>
          </StackItem>
          <StackItem>
            <Text></Text>
          </StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};
