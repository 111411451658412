import { useState, useEffect } from "react";
import { Census_func } from "../../api/devices/census_func";
import { SideMenu } from "../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../components/data_table/data_table.component";
import OverLay from "../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, Card } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular,DesktopCheckmark20Regular, WrenchScrewdriver20Regular,AlertBadge20Regular,Info20Filled} from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../components/dropdown/menu.component";
import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { fullscreenIcon, lightTheme, primaryIcon, exitIcon, messageTheme } from "../../styles/customStyles";
import DialogBox from "../../components/dialog/dialog.component";
 
const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const Alert = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data] = useState({
    core_Census: [
      { alerName: "Teams Crash", nod: "2" },
      { alerName: "Google Chrome Crash ", nod: "5" },
      { alerName: "High CPU Usage", nod: "3" },
      { alerName: "Microsoft Edge Crash ", nod: "4" },
      { alerName: "Disk Space Full", nod: "1" },
      { alerName: "Windows Update Disabled  ", nod: "2" },
      { alerName: " Memory usage greater than 90% ", nod: "2" },
      { alerName: "Network Outage", nod: "3" },
      { alerName: "Database Connection Error", nod: "2" },
      { alerName: "Application Hang", nod: "4" }
      // Add as many data points as you need
    ]
  });
  const [tableData2, setTableData2] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleFilterValueSelect = (event: { target: { value: Event; }; }):void => {
    alert(event.target.value);
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("clicked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
 
  useEffect(() => {
   if(filterArray.length > 0)
   setResetDisabled(false)
  
  }, [filterArray])

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }
 
  var menuItems: any = [];

  menuItems.push
    (
      <FluentProvider theme={lightTheme}>
        <MenuItem onClick={() => navigate("/manage/troubleshoot/alert")}
            icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
                <StackItem><Text></Text></StackItem>
                <StackItem> <AlertBadge20Regular {...primaryIcon} /> </StackItem>
            </Stack>}>
            <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1> Alert </Caption1> </StackItem>
            </Stack>
        </MenuItem>
      </FluentProvider>
    );

  menuItems.push
    (
      
        <MenuItem onClick={() => navigate("/manage/troubleshoot/troubleshoot")}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem><Text></Text></StackItem>
              <StackItem> <WrenchScrewdriver20Regular {...primaryIcon} /> </StackItem>
            </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1> Troubleshoot </Caption1> </StackItem>
            <StackItem> <Stack tokens={{ childrenGap: 59 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          </Stack>
        </MenuItem>
      
    );

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/troubleshoot/status")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <DesktopCheckmark20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Status </Caption1> </StackItem>
        </Stack>
      </MenuItem>
  );
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/troubleshoot/compliance_policy")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <DesktopCheckmark20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Compliance Policy </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "notification_name", label: <Body1Strong> Alert Name </Body1Strong>  },
    // { key: "monitor_date", label: <Body1Strong> Monitor Date  </Body1Strong> },
    { key: "num_of_devices", label: <Body1Strong> Number of Alerts  </Body1Strong> }
  ];
  const datas = [
    {  notification_name: { label: <Caption1> High </Caption1>}, 
    num_of_devices: { label: <Caption1> High111 </Caption1>} }
   
];

  //data table column name code ends here
  //data table for filter
  var columns1 = [
    { key: "device_name", label: <Caption1> Name </Caption1>  },
    { key: "type", label: <Caption1> Type </Caption1> },
    { key: "createdBy", label: <Caption1> Created By  </Caption1> },
    { key: "createdOn", label: <Caption1> Created On  </Caption1> },
    { key: "last_modified", label: <Caption1> last Modified </Caption1> },
    { key: "status", label: <Caption1> Status </Caption1> },
  ];
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="compliance_Policies_assignmentlist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
 
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=",final_data);
   
    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
          menuLists.push();
 console.log("val.alerName=",val.alerName);
 
          tempExportData.push({
            notification_name: val.alerName,
            num_of_devices: val.nod,
          });


 
          tempTableData.push({
            checkbox:'hide',
            notification_name:{ label: <Button appearance="transparent" size="small" onClick={() => setOverlayOpen(true)}> {val.alerName} </Button> },
            num_of_devices: { label: <Caption1> {val.nod} </Caption1> },
          });
        }) : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    console.log("tableData=",tableData);
    
  }, [tableData]);

   // data table values append code ends here
   var overLayData: any = {
    
    headerTitle: "Memory usage greater than 90%",
    size: "large",
    isTabs: true,
    tabItems: {
      Introduction: 
      <>
        <Stack tokens={{childrenGap:15, padding:10}}>
        <StackItem>
    <Body1Strong>What does this Alert Do?</Body1Strong>
  </StackItem>
  <StackItem>
    <Caption1>
      This alert indicates that data in Office mobile apps may be shared or leaked to unmanaged apps on iOS and Android devices. In this scenario, data protection settings should be applied to your organization’s managed mobile apps to prevent such occurrences.
    </Caption1>
  </StackItem>
  <StackItem>
    <Body1Strong>What is the Benefit of Addressing this Alert?</Body1Strong>
  </StackItem>
  <StackItem>
    <Caption1>
      Addressing this alert helps prevent data in Office mobile apps from being shared or leaked to unmanaged apps on iOS and Android devices, ensuring better data security for your organization.
    </Caption1>
  </StackItem>
        </Stack>
      </>,

      Devices: 
      <>
        <Stack tokens={{childrenGap:15, padding:10}}>
        <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      <Divider />
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                              <Divider />
                              <MenuList>
                                <Stack tokens={{ childrenGap: 5 }}>
                                  <StackItem> <Text></Text> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem> <Button appearance="primary" onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>} />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                          <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> <FilterDismiss20Filled /> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5, padding: 5 }}>
                          <StackItem><Text></Text></StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                                <StackItem> <Caption1>Executing the following troubleshooters will assist you in devices management and provide a resolutions for any errors. Be mindful  when executing the troubleshooter on multiple devices. </Caption1>  </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem>
                          <Caption1> Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records </Caption1>
                        </StackItem>
                        <StackItem>

                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack> : <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>}
                    </StackItem>
                   {/* <StackItem>Data table comes here.</StackItem> */}
                   {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
                  </Stack>
        </Stack>
      </>,
    },
    footerData:
    (
      <>
        <Button appearance="primary" size="medium" onClick={() => {setOverlayOpen(false); 
            setTimeout(() => {
                navigate("/manage/troubleshoot/troubleshoot");
                }, 1000); 
            }} > Troubleshoot Fix </Button>
     </>
    )
  };
 
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
    <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Alert" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
                <FluentProvider theme={webLightTheme}> 
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem> 
                  <Title3>Troubleshooting | Alert </Title3>  
              </StackItem>
          </Stack>
 
          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
         
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                  <Divider/>
                </StackItem>
                <StackItem>
                  <StackItem>
                      <Stack horizontal tokens={{childrenGap:20}}>
                        <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                        <StackItem>
                          <MenuDropdown open={filterMenuOpen} btn=
                          { 
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <Filter20Filled {...primaryIcon}/>
                                <Body1> Filter </Body1> 
                              </Stack>
                            </MenuButton>
                          } itemVal = {
                          <>
                            <Body1Strong> Add a Filter </Body1Strong>
                            <Divider/>
                            <MenuList>
                              <Stack tokens={{childrenGap:5}}>
                                <StackItem> <Text></Text> </StackItem>
                                <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                                <StackItem> <Divider/> </StackItem>
                                <StackItem> <Caption1> Value </Caption1> </StackItem>
                                <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                <StackItem> <Divider /> </StackItem>
                                <Stack tokens={{childrenGap:10}} horizontal>
                                  <StackItem> <Button appearance="primary"  onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                  <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                </Stack>
                              </Stack>
                            </MenuList>
                          </>
                          }
                          />
                        </StackItem>
                        <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                        <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                          <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                            <Stack tokens={{childrenGap:5}} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                    <StackItem> <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem>
                    <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                  </Stack>
                </StackItem>
                {/* <Census_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo = {(currentPageNo - 1) * 10} setTotalPages = {setTotalPages} setTotalRecords = {setTotalRecords} /> */}
                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
              </Stack>

            </StackItem>
          </Stack>
        </Stack>
        <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData}/>
      </StackItem>
      <StackItem><Text></Text></StackItem>
      </Stack>
        </FluentProvider>



</FullScreen>
    </>
  );
};
