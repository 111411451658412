import { Stack, StackItem } from '@fluentui/react';
import { Body1, Caption1, Divider, Text, MenuTrigger, Image, Card, CardHeader, CardPreview } from '@fluentui/react-components';

const truncateText = (text: string, limit: number) => {
  const words = text.split(' ');
  const truncatedWords = words.slice(0, limit);
  return truncatedWords.join(' ');
};

export const Cards = (props: any) => {
  const truncatedTitle = truncateText(props.title, 7);
  const finaldata1 = truncatedTitle.length > 30 ? truncatedTitle + ' ' : truncatedTitle;
  const truncatedDescription = truncateText(props.description, 20);
  const finaldata = truncatedDescription.length > 100 ? truncatedDescription + '..' : truncatedDescription;

  return (
    <Stack>
      <StackItem>
        <Card size="large">
          <Stack verticalAlign="center">
            <StackItem>
              <Stack styles={{ root: { width: 192, height: 30 } }}>
                <StackItem>
                  <CardHeader
                    header={
                      <Text weight="semibold">
                        {finaldata1} {/*{props.title}*/}
                      </Text>
                    }
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
          <Divider />
          <CardPreview>
            <Stack verticalAlign="center" tokens={{ padding: 5 }}>
              <StackItem>
                <Body1>
                  <Stack styles={{ root: { width: 192, height: 120 } }}>
                    <Stack horizontal tokens={{ childrenGap: 13 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Caption1 title={props.description}> {finaldata} </Caption1>
                      </StackItem>
                    </Stack>
                  </Stack>
                </Body1>
              </StackItem>
            </Stack>
          </CardPreview>
          {/* <CardFooter> */}
          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              <MenuTrigger>{props.btn}</MenuTrigger>
            </StackItem>
            <StackItem>
              <Stack horizontal>
                {props.os.includes('windows') && (
                  <StackItem>
                    <Image src="/visualization/v2/image/cardWindows.svg" alt="Windows" />
                  </StackItem>
                )}
                {props.os.includes('android') && (
                  <StackItem>
                    <Image src="/visualization/v2/image/cardAndroid.svg" alt="Android" />
                  </StackItem>
                )}
                {props.os.includes('ios') && (
                  <StackItem>
                    <Image src="/visualization/v2/image/cardIos.svg" alt="Ios" />
                  </StackItem>
                )}
              </Stack>
            </StackItem>
          </Stack>
          {/* </CardFooter> */}
        </Card>
      </StackItem>
    </Stack>
  );
};
