import { useState, useEffect, useContext, useRef } from 'react';
import { UserSurvey_func } from '../../../api/automation/userSurvey_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import DialogBox from '../../../components/dialog/dialog.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Option,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Dropdown,
  Card,
  Link,
  Input,
  Image,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Tooltip,
} from '@fluentui/react-components';
import {
  AddSquare20Regular,
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  CalendarClock20Regular,
  Wand20Regular,
  SettingsCogMultiple20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  Delete20Regular,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Info24Filled,
  Open20Regular,
  Info20Filled,
  Dismiss16Regular,
  CheckmarkLock20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Inputs } from '../../../components/textarea/input.component';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import {
  fullscreenIcon,
  primaryIcon,
  exitIcon,
  deleteIcon,
  secondaryIcon,
  primaryWidth,
  secondaryWidth,
  accordionMargin,
  lightTheme,
  messageTheme,
} from '../../../styles/customStyles';

interface FormItem {
  id: number;
  data: {};
}
interface FormItems {
  id: number;
  data: {};
}

export const UserSurvey = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isRefresh, setRefresh] = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [surveyCounter, setSurveyCounter] = useState(2);
  const [selectedOption, setSelectedOption] = useState('');
  const [rowID, setRowID]: any = useState(null);
  const [automationOverLayData, setAutomationOverLayData]: any = useState([]);
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [saveBtnClicked1, setSaveBtnClicked1]: any = useState(false);
  const [deleteSurvey, setDeleteSurvey]: any = useState(false);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [userActivityData, setUserActivityData] = useState({});
  const [tracker, setTracker] = useState(false);
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [automationStatus, setAutomationStatus] = useState('not attached');

  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });

  const [configFormData, setConfigFormData]: any = useState({
    surveyName: '',
    surveyid: '',
    greeting: '',
    header: '',
    SurveyTimeOutInMsec: '',
    SurveyIntervalInMsec: '',
    MaxTriesCount: '',
    questions: [],
    FinalMessage: '',
  });

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };

  //clone code starts here
  const [cloneScore, setCloneScore] = useState<FormItems[]>([
    {
      id: 1,
      data: {
        answerid: 1,
        alabel: '',
        score: '',
        adesc: '',
      },
    },
  ]);

  const [cloneForms, setCloneForms] = useState<FormItem[]>([
    {
      id: 1,
      data: {
        questionid: 1,
        qlabel: '',
        type: '',
        qdesc: '',
        answer: [
          {
            answerid: 1,
            alabel: '',
            score: '',
            adesc: '',
          },
        ],
      },
    },
  ]);

  const handleAddAnswerClone = (ids: any, i: any) => {
    if (cloneScore.length < 5) {
      setCloneForms((prev: any) => [
        ...prev.slice(0, i),
        {
          ...prev[i],
          data: {
            ...prev[i].data,
            answer: [
              ...prev[i].data.answer,
              {
                answerid: ids,
                alabel: '',
                score: '',
                adesc: '',
              },
            ],
          },
        },
        ...prev.slice(i + 1),
      ]);
    }
  };

  const handleRemoveAnswerClone = (id: any, qid: any) => {
    var tempArr: any = [];

    cloneForms?.map((val: any) => {
      if (val.id == qid) {
        val.data.answer
          .filter((ele: any) => ele.answerid != id)
          .map((value: any, i: any) => {
            tempArr.push({ ...value, answerid: i + 1 });
          });
      }
    });
    setCloneForms((prev: any) => [
      ...prev.slice(0, qid - 1),
      { ...prev[qid - 1], data: { ...prev[qid - 1].data, answer: tempArr } },
      ...prev.slice(qid),
    ]);
  };

  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name) {
        flag = false;
      }
    });

    if (overlayName == 'editSurvey' && formData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, selectedAutomation]);

  useEffect(() => {
    console.log('Clone Forms: ', cloneForms);
    setConfigFormData({ ...configFormData, questions: cloneForms?.map((val: any) => val.data) });
  }, [cloneForms]);

  useEffect(() => {
    console.log('Config Form Data: ', configFormData);
  }, [configFormData]);

  useEffect(() => {
    if (overlayName == 'addSurvey') {
      var name = formData.name;
      var id = name
        .split(' ')
        .map((val: any) => val.charAt(0))
        .join('');
      setConfigFormData({ ...configFormData, surveyName: name, surveyid: id });
    } else if (overlayName == 'editSurvey') {
      var timeout = parseInt(formData.jsonData.SurveyTimeOutInMsec) / 60000;
      var interval = parseInt(formData.jsonData.SurveyIntervalInMsec) / 60000;
      setConfigFormData({ ...formData.jsonData, SurveyTimeOutInMsec: timeout.toString(), SurveyIntervalInMsec: interval.toString() });
      setCloneForms(
        formData.jsonData?.questions?.map((val: any, i: any) => {
          return { id: i + 1, data: val };
        }),
      );
    }
  }, [formData]);

  const handleAddClone = (ids: any) => {
    if (cloneForms.length < 5) {
      setCloneForms([
        ...cloneForms,
        {
          id: ids,
          data: {
            questionid: ids,
            qlabel: '',
            type: '',
            qdesc: '',
            answer: [
              {
                answerid: 1,
                alabel: '',
                score: '',
                adesc: '',
              },
            ],
          },
        },
      ]);
      setSurveyCounter(surveyCounter + 1);
      //setCloneForms([...cloneForms, { id: surveyCounter, data: {} }]);
    }
  };

  const handleRemoveClone = (id: any) => {
    var tempArr: any = [];
    cloneForms
      .filter(form => form.id != id)
      .map((val: any, i: any) => {
        tempArr.push({ ...val, id: i + 1, data: { ...val.data, questionid: i + 1 } });
      });
    setCloneForms(tempArr);
    setSurveyCounter(surveyCounter - 1);
  };

  const handleInput = (i: any, val: any, res: any) => {
    setCloneForms((prev: any) => [...prev.slice(0, i), { ...prev[i], data: { ...prev[i].data, [`${res}`]: val } }, ...prev.slice(i + 1)]);
  };

  const handleAnswerInput = (i: any, j: any, val: any, res: any) => {
    setCloneForms((prev: any) => [
      ...prev.slice(0, i),
      {
        ...prev[i],
        data: {
          ...prev[i].data,
          answer: [...prev[i].data.answer.slice(0, j), { ...prev[i].data.answer[j], [`${res}`]: val }, ...prev[i].data.answer.slice(j + 1)],
        },
      },
      ...prev.slice(i + 1),
    ]);
  };

  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const handleCloseFilter = (index: any): any => {
    console.log('Clicked...');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('Entered Value: ', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations/configuration')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/selfhelp');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1>Self-Help </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '69');
        contextValue.setDartNo(69);
        navigate('/automate/automation/autoheal');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Wand20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Autoheal </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/automationschedule');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CalendarClock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automation Schedules </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => {
          contextValue.setDartNo(241);
          navigate('/automate/automation/usersurvey');
        }}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <ChatMultipleHeart20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> User Surveys </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      key={3}
      onClick={() => {
        navigate('/automate/automation/compliance_policies/policy_configuration');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckmarkLock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter column starts here
  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button
            size="small"
            appearance="subtle"
            onClick={() => {
              setOverlayOpen(true);
              setOverlayName('addSurvey');
              setOverlayOpen(true);
              setFormData({ name: '', description: '', jsonData: '' });
              setAutomationStatus('not attached');
              setConfigFormData({ surveyName: '', surveyid: '', greeting: '', header: '', questions: [] });
              setCloneForms([
                { id: 1, data: { questionid: 1, qlabel: '', type: '', qdesc: '', answer: [{ answerid: 1, alabel: '', score: '', adesc: '' }] } },
              ]);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <AddSquare20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1>Add Survey</Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        {/* <StackItem> <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}> <Stack tokens={{childrenGap:5}} horizontal> <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem> <StackItem> <Caption1> Duplicate </Caption1> </StackItem> </Stack> </Button> </StackItem> */}

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <DialogBox
            content="Are you sure you wish to remove the selected user survey ?"
            headerButton={
              <Button size="small" appearance="subtle" disabled={isdeletedisabled ? true : false}>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>{isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}</StackItem>
                  <StackItem>
                    <Caption1> Delete </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            }
            footer={
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <StackItem>
                  <Button
                    size="small"
                    appearance="primary"
                    onClick={() => {
                      setDeleteSurvey(true);
                      userActivity(
                        'Delete',
                        `{
                          "summary":
                          "${contextValue.selectedRowIdInTable.length} 
                          ${contextValue.selectedRowIdInTable.length > 1 ? 'automations' : 'automation'} 
                          deleted"
                        }`,
                      );
                    }}
                  >
                    Confirm
                  </Button>
                </StackItem>
                <StackItem>
                  <Button size="small"> Cancel </Button>
                </StackItem>
              </Stack>
            }
          />
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setRefresh(true);
              setCurrentPageNo(1);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink data={exportData} id="csv-export-link" filename="usersurveylist.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"user survey automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>

        {/* <StackItem> <Button appearance="subtle" size="small"> <Stack tokens={{childrenGap:5}} horizontal> <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem> <StackItem> <Caption1> Manage View </Caption1> </StackItem> </Stack> </Button> </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          status: status,
          last_modified: val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-',
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('Final Data: ', final_data);
    var status = '';

    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'Not attached' ? 'show' : 'hide',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={() => {
                    setRowID(val.id);
                    val.status.includes(`${contextValue.tenantName}:`) ? setAutomationStatus('attached') : setAutomationStatus('not attached');
                    setOverlayOpen(true);
                    setOverlayName('editSurvey');
                    setSelectedAutomation(val.automationName);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    if (saveBtnClicked) {
      setFormData({ ...formData, jsonData: configFormData });
      setSaveBtnClicked1(true);
      setTimeout(() => {
        setSaveBtnClicked(false);
      }, 1000);
    } else {
      setSaveBtnClicked1(false);
      setFormData({ name: '', description: '', jsonData: '' });
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    if (dartSpecs?.profile_DartTemplateDetails?.length > 0) {
      var details = dartSpecs.profile_DartTemplateDetails.length > 0 ? dartSpecs.profile_DartTemplateDetails[0].details : '{}';
      details = JSON.parse(details);

      overlayName == 'addSurvey' &&
        setAutomationOverLayData({
          size: 'large',
          isTabs: true,
          headerTitle: <Title3> Add a New Survey </Title3>,

          tabItems: {
            Introduction: (
              <form id="myFormUsersurvey" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 10, padding: 12 }}>
                  {/* <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{childrenGap:10}} horizontal>
                        <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                        <StackItem> <Caption1> These details will make it easy to find the snippets later when you need to use them. </Caption1> </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem> */}

                  <StackItem>
                    <Body1Strong>
                      Name <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>

                  {/* <Stack horizontal tokens={{childrenGap:550}}> */}
                  <StackItem grow>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                    </p>
                    {!classificationNameValidation && <Body1> This automation name already exists </Body1>}
                  </StackItem>
                  {/* <StackItem><Text></Text></StackItem> */}
                  {/* </Stack> */}

                  <StackItem>
                    <Body1Strong> Survey Description </Body1Strong>
                  </StackItem>

                  <Resize inputChange={setFormData} inputValue={formData} />

                  <StackItem>
                    {/* <Intent title="" message="These are the Basic details associated with this Automation." /> */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These are the Basic details associated with this User Survey. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b> {key} </b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p> {details[key]} </p>
                      </StackItem>
                    </>
                  ))}
                </Stack>

                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configuration: (
              <form id="myFormUsersurvey" onSubmit={e => handleSubmit(e)}>
                <>
                  {/* <Stack> <UserSurveyForm/> </Stack> */}
                  <Stack tokens={{ childrenGap: 15, padding: 15 }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong>
                          Greetings to be displayed to the User <Image src="/visualization/v2/image/required.svg" />
                        </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="11"
                              size="small"
                              required
                              value={configFormData.greeting}
                              onChange={e => {
                                setConfigFormData({ ...configFormData, greeting: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong> The need for the User Survey </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="12"
                              size="small"
                              value={configFormData.header}
                              onChange={e => {
                                setConfigFormData({ ...configFormData, header: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong>
                          Survery time out in minutes <Image src="/visualization/v2/image/required.svg" />
                        </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="13"
                              size="small"
                              required
                              value={configFormData.SurveyTimeOutInMsec}
                              onChange={(e: any) => {
                                setConfigFormData({ ...configFormData, SurveyTimeOutInMsec: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong>
                          Time interval after which Survey should be retried <Image src="/visualization/v2/image/required.svg" />
                        </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="14"
                              size="small"
                              required
                              value={configFormData.SurveyIntervalInMsec}
                              onChange={(e: any) => {
                                setConfigFormData({ ...configFormData, SurveyIntervalInMsec: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong>
                          Maximum trials for the Survey <Image src="/visualization/v2/image/required.svg" />
                        </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="15"
                              size="small"
                              required
                              value={configFormData.MaxTriesCount}
                              onChange={(e: any) => {
                                setConfigFormData({ ...configFormData, MaxTriesCount: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>

                    <StackItem>
                      {cloneForms?.map((form: any, i: any) => (
                        <>
                          {/* {i != 0 && <Divider />} */}
                          <Stack horizontal key={form.id}>
                            <StackItem grow>
                              <Stack>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Stack key={form.id} tokens={{ childrenGap: 10 }}>
                                        <StackItem>
                                          <Accordion style={accordionMargin}>
                                            <AccordionItem value="configuration_1">
                                              <AccordionHeader expandIconPosition="end">
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                  <StackItem>
                                                    <Body1Strong>
                                                      Survey Question {i + 1} <Image src="/visualization/v2/image/required.svg" />
                                                    </Body1Strong>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                      <Input
                                                        id={i}
                                                        size="small"
                                                        required
                                                        value={form.data.qdesc}
                                                        onChange={(e: any) => {
                                                          handleInput(i, e.target.value, 'qdesc');
                                                        }}
                                                        style={primaryWidth}
                                                      />
                                                      <StackItem>
                                                        {i == 0 ? (
                                                          <Tooltip withArrow content="Add a New Question" relationship="label">
                                                            <Button
                                                              appearance="subtle"
                                                              aria-label="Close"
                                                              icon={<AddSquare20Regular {...primaryIcon} />}
                                                              onClick={() => {
                                                                handleAddClone(cloneForms.length + 1);
                                                              }}
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip withArrow content="Ignore this Question" relationship="label">
                                                            <Button
                                                              appearance="subtle"
                                                              aria-label="Close"
                                                              icon={<Delete20Regular {...deleteIcon} />}
                                                              onClick={() => handleRemoveClone(i + 1)}
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </StackItem>
                                                    </Stack>
                                                  </StackItem>
                                                </Stack>
                                              </AccordionHeader>

                                              <AccordionPanel>
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                  <StackItem>
                                                    <Text></Text>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Stack tokens={{ childrenGap: 10 }}>
                                                      <StackItem>
                                                        <Body1Strong> Question Label </Body1Strong>
                                                      </StackItem>
                                                      <Input
                                                        id={i}
                                                        size="small"
                                                        value={form.data.qlabel}
                                                        onChange={(e: any) => {
                                                          handleInput(i, e.target.value, 'qlabel');
                                                        }}
                                                        style={primaryWidth}
                                                      />
                                                    </Stack>
                                                  </StackItem>

                                                  <StackItem>
                                                    <Stack tokens={{ childrenGap: 10 }}>
                                                      <StackItem>
                                                        <Body1Strong>
                                                          Survey Answers <Image src="/visualization/v2/image/required.svg" />
                                                        </Body1Strong>
                                                      </StackItem>
                                                      <StackItem>
                                                        <Dropdown placeholder="Select" defaultValue={form.data.type} style={primaryWidth}>
                                                          <Option
                                                            key={3}
                                                            value="Checkbox"
                                                            onClick={() => {
                                                              setSelectedOption('checkbox');
                                                              handleInput(i, 'checkbox', 'type');
                                                            }}
                                                          >
                                                            Checkbox
                                                          </Option>

                                                          <Option
                                                            key={4}
                                                            value="Radio-button"
                                                            onClick={() => {
                                                              setSelectedOption('radio');
                                                              handleInput(i, 'radio', 'type');
                                                            }}
                                                          >
                                                            Radio-button
                                                          </Option>
                                                        </Dropdown>
                                                      </StackItem>

                                                      <StackItem>
                                                        {form.data.answer.map((subform: any, j: any) => (
                                                          <Stack key={subform.id}>
                                                            {form.data.type != '' && (
                                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                                <StackItem>
                                                                  <Stack tokens={{ childrenGap: 10 }}>
                                                                    <StackItem>
                                                                      <Body1Strong> Answer label </Body1Strong>
                                                                    </StackItem>
                                                                    <Input
                                                                      id={j}
                                                                      size="small"
                                                                      value={subform.alabel}
                                                                      required
                                                                      onChange={(e: any) => {
                                                                        handleAnswerInput(i, j, e.target.value, 'alabel');
                                                                      }}
                                                                      style={secondaryWidth}
                                                                    />
                                                                  </Stack>
                                                                </StackItem>

                                                                <StackItem>
                                                                  <Stack tokens={{ childrenGap: 10 }}>
                                                                    <StackItem>
                                                                      <Body1Strong> Score </Body1Strong>
                                                                    </StackItem>
                                                                    <Input
                                                                      id={j}
                                                                      size="small"
                                                                      value={subform.score}
                                                                      required
                                                                      onChange={(e: any) => {
                                                                        handleAnswerInput(i, j, e.target.value, 'score');
                                                                      }}
                                                                      style={secondaryWidth}
                                                                    />
                                                                  </Stack>
                                                                </StackItem>

                                                                <StackItem>
                                                                  <Stack tokens={{ childrenGap: 10 }}>
                                                                    <StackItem>
                                                                      <Body1Strong> Answer description </Body1Strong>
                                                                    </StackItem>
                                                                    <Input
                                                                      id={j}
                                                                      size="small"
                                                                      value={subform.adesc}
                                                                      onChange={(e: any) => {
                                                                        handleAnswerInput(i, j, e.target.value, 'adesc');
                                                                      }}
                                                                      style={secondaryWidth}
                                                                    />
                                                                  </Stack>
                                                                </StackItem>

                                                                <StackItem>
                                                                  {j == 0 ? (
                                                                    <Stack tokens={{ childrenGap: 20 }}>
                                                                      <StackItem>
                                                                        <Text></Text>
                                                                      </StackItem>
                                                                      <StackItem>
                                                                        <Button
                                                                          appearance="subtle"
                                                                          aria-label="Close"
                                                                          icon={<AddSquare20Regular {...primaryIcon} />}
                                                                          onClick={() => handleAddAnswerClone(form.data.answer.length + 1, i)}
                                                                        />
                                                                      </StackItem>
                                                                    </Stack>
                                                                  ) : (
                                                                    <Stack tokens={{ childrenGap: 20 }}>
                                                                      <StackItem>
                                                                        <Text></Text>
                                                                      </StackItem>
                                                                      <StackItem>
                                                                        <Button
                                                                          appearance="subtle"
                                                                          aria-label="Close"
                                                                          icon={<Delete20Regular {...deleteIcon} />}
                                                                          onClick={() => handleRemoveAnswerClone(j + 1, i + 1)}
                                                                        />
                                                                      </StackItem>
                                                                    </Stack>
                                                                  )}
                                                                </StackItem>
                                                              </Stack>
                                                            )}
                                                          </Stack>
                                                        ))}
                                                      </StackItem>
                                                    </Stack>
                                                  </StackItem>
                                                </Stack>
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            {/* <StackItem> <Stack horizontal tokens={{childrenGap:20}}> <StackItem> <Button appearance="subtle" aria-label="Close" icon={<Delete20Regular/>} onClick={() => handleRemoveClone(form.id)}/> </StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> */}
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                        </>
                      ))}
                    </StackItem>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Body1Strong> Final Message to be displayed after the Survey </Body1Strong>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow>
                          <Stack>
                            <Input
                              id="16"
                              size="small"
                              value={configFormData.FinalMessage}
                              onChange={(e: any) => {
                                setConfigFormData({ ...configFormData, FinalMessage: e.target.value });
                              }}
                              style={primaryWidth}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 15 }}>
                <StackItem>
                  <Body1Strong> Survey Name </Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 550 }}>
                  <StackItem grow>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>

                <StackItem>
                  <Body1Strong> Survey Description </Body1Strong>
                </StackItem>

                <Resize inputChange={setFormData} inputValue={formData} disabled={true} />

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Greetings to be displayed to the User </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="11"
                          size="small"
                          disabled={true}
                          value={configFormData.greeting}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, greeting: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> The need for the User Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="12"
                          size="small"
                          disabled={true}
                          value={configFormData.header}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, header: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Survery time out in minutes </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="13"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyTimeOutInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyTimeOutInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Time interval after which survey should be retried </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="14"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyIntervalInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyIntervalInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Maximun trials for the survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="15"
                          size="small"
                          disabled={true}
                          value={configFormData.MaxTriesCount}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, MaxTriesCount: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <StackItem>
                  {cloneForms?.map((form: any, i: any) => (
                    <>
                      {/* {i != 0 && <Divider />} */}
                      <Stack horizontal key={form.id}>
                        <StackItem grow>
                          <Stack>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Stack key={form.id}>
                                    <StackItem>
                                      <Accordion collapsible style={accordionMargin}>
                                        <AccordionItem value="reviewData_1">
                                          <AccordionHeader expandIconPosition="end">
                                            <Stack tokens={{ childrenGap: 10 }}>
                                              <StackItem>
                                                <Body1Strong> Survey Question {i + 1} </Body1Strong>
                                              </StackItem>
                                              <StackItem>
                                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                  <Input
                                                    id={i}
                                                    size="small"
                                                    disabled={true}
                                                    value={form.data.qdesc}
                                                    onChange={(e: any) => {
                                                      handleInput(i, e.target.value, 'qdesc');
                                                    }}
                                                    style={primaryWidth}
                                                  />
                                                </Stack>
                                              </StackItem>
                                            </Stack>
                                          </AccordionHeader>

                                          <AccordionPanel>
                                            <Stack tokens={{ childrenGap: 10 }}>
                                              <StackItem>
                                                <Text></Text>
                                              </StackItem>
                                              <StackItem>
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                  <StackItem>
                                                    <Body1Strong> Question Label </Body1Strong>
                                                  </StackItem>
                                                  <Input
                                                    id={i}
                                                    size="small"
                                                    disabled={true}
                                                    value={form.data.qlabel}
                                                    onChange={(e: any) => {
                                                      handleInput(i, e.target.value, 'qlabel');
                                                    }}
                                                    style={primaryWidth}
                                                  />
                                                </Stack>
                                              </StackItem>

                                              <StackItem>
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                  <StackItem>
                                                    <Body1Strong>
                                                      Survey Answers <Image src="/visualization/v2/image/required.svg" />
                                                    </Body1Strong>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Dropdown placeholder="Select" defaultValue={form.data.type} disabled style={primaryWidth} />
                                                  </StackItem>

                                                  <StackItem>
                                                    {form.data.answer.map((subform: any, j: any) => (
                                                      <Stack key={subform.id}>
                                                        {form.data.type != '' && (
                                                          <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                            <StackItem>
                                                              <Stack tokens={{ childrenGap: 10 }}>
                                                                <StackItem>
                                                                  <Body1Strong> Answer label </Body1Strong>
                                                                </StackItem>
                                                                <Input
                                                                  id={j}
                                                                  size="small"
                                                                  disabled={true}
                                                                  value={subform.alabel}
                                                                  onChange={(e: any) => {
                                                                    handleAnswerInput(i, j, e.target.value, 'alabel');
                                                                  }}
                                                                  style={secondaryWidth}
                                                                />
                                                              </Stack>
                                                            </StackItem>

                                                            <StackItem>
                                                              <Stack tokens={{ childrenGap: 10 }}>
                                                                <StackItem>
                                                                  <Body1Strong> Score </Body1Strong>
                                                                </StackItem>
                                                                <Input
                                                                  id={j}
                                                                  size="small"
                                                                  disabled={true}
                                                                  value={subform.score}
                                                                  onChange={(e: any) => {
                                                                    handleAnswerInput(i, j, e.target.value, 'score');
                                                                  }}
                                                                  style={secondaryWidth}
                                                                />
                                                              </Stack>
                                                            </StackItem>

                                                            <StackItem>
                                                              <Stack tokens={{ childrenGap: 10 }}>
                                                                <StackItem>
                                                                  <Body1Strong> Answer description </Body1Strong>
                                                                </StackItem>
                                                                <Input
                                                                  id={j}
                                                                  size="small"
                                                                  disabled={true}
                                                                  value={subform.adesc}
                                                                  onChange={(e: any) => {
                                                                    handleAnswerInput(i, j, e.target.value, 'adesc');
                                                                  }}
                                                                  style={secondaryWidth}
                                                                />
                                                              </Stack>
                                                            </StackItem>
                                                          </Stack>
                                                        )}
                                                      </Stack>
                                                    ))}
                                                  </StackItem>
                                                </Stack>
                                              </StackItem>
                                            </Stack>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </StackItem>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </>
                  ))}
                </StackItem>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Final Message to be displayed after the Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="16"
                          size="small"
                          disabled={true}
                          value={configFormData.FinalMessage}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, FinalMessage: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>
              </Stack>
            ),
          },
        });

      overlayName == 'editSurvey' &&
        automationStatus === 'not attached' &&
        setAutomationOverLayData({
          size: 'large',
          isTabs: true,
          headerTitle: <Title3> Edit {selectedAutomation} Survey </Title3>,

          tabItems: {
            Introduction: (
              <form id="myFormUsersurvey" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 10, padding: 12 }}>
                  <StackItem>
                    {/* <Intent title="" message="These details will make it easy to find the snippets later when you need to use them." /> */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Body1> These details will make it easy to find the snippets later when you need to use them. </Body1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  <StackItem>
                    <Body1Strong> Survey Name </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 550 }}>
                    <StackItem grow>
                      <Inputs inputChange={setFormData} inputVal={formData} />

                      {!classificationNameValidation && <Body1> This automation name already exists </Body1>}
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>

                  <StackItem>
                    <Body1Strong> Survey Description </Body1Strong>
                  </StackItem>

                  <Resize inputChange={setFormData} inputValue={formData} />

                  <StackItem>
                    {/* <Intent title="" message="These are the Basic details associated with this Automation." /> */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Body1> These are the Basic details associated with this Automation. </Body1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b> {key} </b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p> {details[key]} </p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configuration: (
              <form id="myFormUsersurvey" onSubmit={e => handleSubmit(e)}>
                {/* <Stack> <UserSurveyForm/> </Stack> */}
                <Stack tokens={{ childrenGap: 15, padding: 15 }}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> Greetings to be displayed to the User </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="11"
                            size="small"
                            value={configFormData.greeting}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, greeting: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> The need for the User Survey </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="12"
                            size="small"
                            value={configFormData.header}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, header: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> Survery time out in minutes </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="13"
                            size="small"
                            value={configFormData.SurveyTimeOutInMsec}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, SurveyTimeOutInMsec: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> Time interval after which survey should be retried </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="14"
                            size="small"
                            value={configFormData.SurveyIntervalInMsec}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, SurveyIntervalInMsec: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> Maximun trials for the survey </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="15"
                            size="small"
                            value={configFormData.MaxTriesCount}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, MaxTriesCount: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <StackItem>
                    {cloneForms?.map((form: any, i: any) => (
                      <>
                        {/* {i != 0 && <Divider />} */}
                        <Stack horizontal key={form.id}>
                          <StackItem grow>
                            <Stack>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Stack key={form.id}>
                                      <StackItem>
                                        <Accordion collapsible style={accordionMargin}>
                                          <AccordionItem value="configuration_2">
                                            <AccordionHeader expandIconPosition="end">
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Body1Strong>
                                                    Survey Question {i + 1} <Image src="/visualization/v2/image/required.svg" />
                                                  </Body1Strong>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                    <Input
                                                      id={i}
                                                      size="small"
                                                      value={form.data.qdesc}
                                                      onChange={(e: any) => {
                                                        handleInput(i, e.target.value, 'qdesc');
                                                      }}
                                                      style={primaryWidth}
                                                    />
                                                    <StackItem>
                                                      {i == 0 ? (
                                                        <Tooltip withArrow content="Add a New Question" relationship="label">
                                                          <Button
                                                            appearance="subtle"
                                                            aria-label="Close"
                                                            icon={<AddSquare20Regular {...primaryIcon} />}
                                                            onClick={() => {
                                                              handleAddClone(cloneForms.length + 1);
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      ) : (
                                                        <Tooltip withArrow content="Ignore this Question" relationship="label">
                                                          <Button
                                                            appearance="subtle"
                                                            aria-label="Close"
                                                            icon={<Delete20Regular {...deleteIcon} />}
                                                            onClick={() => handleRemoveClone(i + 1)}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </AccordionHeader>

                                            <AccordionPanel>
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 10 }}>
                                                    <StackItem>
                                                      <Body1Strong> Question Label </Body1Strong>
                                                    </StackItem>
                                                    <Input
                                                      id={i}
                                                      size="small"
                                                      value={form.data.qlabel}
                                                      onChange={(e: any) => {
                                                        handleInput(i, e.target.value, 'qlabel');
                                                      }}
                                                      style={primaryWidth}
                                                    />
                                                  </Stack>
                                                </StackItem>

                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 10 }}>
                                                    <StackItem>
                                                      <Body1Strong>
                                                        Survey Answers <Image src="/visualization/v2/image/required.svg" />
                                                      </Body1Strong>
                                                    </StackItem>
                                                    <StackItem>
                                                      <Dropdown placeholder="Select" defaultValue={form.data.type} style={primaryWidth}>
                                                        <Option
                                                          key={3}
                                                          value="Checkbox"
                                                          onClick={() => {
                                                            setSelectedOption('checkbox');
                                                            handleInput(i, 'checkbox', 'type');
                                                          }}
                                                        >
                                                          Checkbox
                                                        </Option>

                                                        <Option
                                                          key={4}
                                                          value="Radio-button"
                                                          onClick={() => {
                                                            setSelectedOption('radio');
                                                            handleInput(i, 'radio', 'type');
                                                          }}
                                                        >
                                                          Radio-button
                                                        </Option>
                                                      </Dropdown>
                                                    </StackItem>

                                                    <StackItem>
                                                      {form.data.answer.map((subform: any, j: any) => (
                                                        <Stack key={subform.id}>
                                                          {form.data.type != '' && (
                                                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                              <StackItem>
                                                                <Stack tokens={{ childrenGap: 10 }}>
                                                                  <StackItem>
                                                                    <Body1Strong> Answer label </Body1Strong>
                                                                  </StackItem>
                                                                  <Input
                                                                    id={j}
                                                                    size="small"
                                                                    value={subform.alabel}
                                                                    onChange={(e: any) => {
                                                                      handleAnswerInput(i, j, e.target.value, 'alabel');
                                                                    }}
                                                                    style={secondaryWidth}
                                                                  />
                                                                </Stack>
                                                              </StackItem>

                                                              <StackItem>
                                                                <Stack tokens={{ childrenGap: 10 }}>
                                                                  <StackItem>
                                                                    <Body1Strong> Score </Body1Strong>
                                                                  </StackItem>
                                                                  <Input
                                                                    id={j}
                                                                    size="small"
                                                                    value={subform.score}
                                                                    onChange={(e: any) => {
                                                                      handleAnswerInput(i, j, e.target.value, 'score');
                                                                    }}
                                                                    style={secondaryWidth}
                                                                  />
                                                                </Stack>
                                                              </StackItem>

                                                              <StackItem>
                                                                <Stack tokens={{ childrenGap: 10 }}>
                                                                  <StackItem>
                                                                    <Body1Strong> Answer description </Body1Strong>
                                                                  </StackItem>
                                                                  <Input
                                                                    id={j}
                                                                    size="small"
                                                                    value={subform.adesc}
                                                                    onChange={(e: any) => {
                                                                      handleAnswerInput(i, j, e.target.value, 'adesc');
                                                                    }}
                                                                    style={secondaryWidth}
                                                                  />
                                                                </Stack>
                                                              </StackItem>

                                                              <StackItem>
                                                                {j == 0 ? (
                                                                  <Stack tokens={{ childrenGap: 20 }}>
                                                                    <StackItem>
                                                                      <Text></Text>
                                                                    </StackItem>
                                                                    <StackItem>
                                                                      <Button
                                                                        appearance="subtle"
                                                                        aria-label="Close"
                                                                        icon={<AddSquare20Regular {...primaryIcon} />}
                                                                        onClick={() => handleAddAnswerClone(form.data.answer.length + 1, i)}
                                                                      />
                                                                    </StackItem>
                                                                  </Stack>
                                                                ) : (
                                                                  <Stack tokens={{ childrenGap: 20 }}>
                                                                    <StackItem>
                                                                      <Text></Text>
                                                                    </StackItem>
                                                                    <StackItem>
                                                                      <Button
                                                                        appearance="subtle"
                                                                        aria-label="Close"
                                                                        icon={<Delete20Regular {...deleteIcon} />}
                                                                        onClick={() => handleRemoveAnswerClone(j + 1, i + 1)}
                                                                      />
                                                                    </StackItem>
                                                                  </Stack>
                                                                )}
                                                              </StackItem>
                                                            </Stack>
                                                          )}
                                                        </Stack>
                                                      ))}
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </AccordionPanel>
                                          </AccordionItem>
                                        </Accordion>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          </StackItem>
                          {/* <StackItem> <Stack horizontal tokens={{childrenGap:20}}> <StackItem> <Button appearance="subtle" aria-label="Close" icon={<Delete20Regular/>} onClick={() => handleRemoveClone(form.id)}/> </StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> */}
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                        </Stack>
                      </>
                    ))}
                  </StackItem>

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Body1Strong> Final Message to be displayed after the Survey </Body1Strong>
                    </StackItem>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem grow>
                        <Stack>
                          <Input
                            id="16"
                            size="small"
                            value={configFormData.FinalMessage}
                            onChange={(e: any) => {
                              setConfigFormData({ ...configFormData, FinalMessage: e.target.value });
                            }}
                            style={primaryWidth}
                          />
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <Stack tokens={{ padding: 15 }}>
                <StackItem>
                  <Body1Strong> Survey Name </Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 550 }}>
                  <StackItem grow>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>

                <StackItem>
                  <Body1Strong> Survey Description </Body1Strong>
                </StackItem>

                <Resize inputChange={setFormData} inputValue={formData} disabled={true} />

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Greetings to be displayed to the User </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="11"
                          size="small"
                          disabled={true}
                          value={configFormData.greeting}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, greeting: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> The need for the User Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="12"
                          size="small"
                          disabled={true}
                          value={configFormData.header}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, header: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Survery time out in minutes </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="13"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyTimeOutInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyTimeOutInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Time interval after which survey should be retried </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="14"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyIntervalInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyIntervalInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Maximun trials for the survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="15"
                          size="small"
                          disabled={true}
                          value={configFormData.MaxTriesCount}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, MaxTriesCount: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <StackItem>
                  {cloneForms?.map((form: any, i: any) => (
                    <>
                      {/* {i != 0 && <Divider />} */}
                      <Stack horizontal key={form.id}>
                        <StackItem grow>
                          <Stack>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Stack key={form.id}>
                                    <Accordion collapsible style={accordionMargin}>
                                      <AccordionItem value="reviewData_2">
                                        <AccordionHeader expandIconPosition="end">
                                          <Stack tokens={{ childrenGap: 10 }}>
                                            <StackItem>
                                              <Body1Strong> Survey Question {i + 1} </Body1Strong>
                                            </StackItem>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                <Input
                                                  id={i}
                                                  size="small"
                                                  disabled={true}
                                                  value={form.data.qdesc}
                                                  onChange={(e: any) => {
                                                    handleInput(i, e.target.value, 'qdesc');
                                                  }}
                                                  style={primaryWidth}
                                                />
                                              </Stack>
                                            </StackItem>
                                          </Stack>
                                        </AccordionHeader>

                                        <AccordionPanel>
                                          <Stack tokens={{ childrenGap: 10 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Body1Strong> Question Label </Body1Strong>
                                                </StackItem>
                                                <Input
                                                  id={i}
                                                  size="small"
                                                  disabled={true}
                                                  value={form.data.qlabel}
                                                  onChange={(e: any) => {
                                                    handleInput(i, e.target.value, 'qlabel');
                                                  }}
                                                  style={primaryWidth}
                                                />
                                              </Stack>
                                            </StackItem>

                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Body1Strong>
                                                    Survey Answers <Image src="/visualization/v2/image/required.svg" />
                                                  </Body1Strong>
                                                </StackItem>
                                                <StackItem>
                                                  <Dropdown placeholder="Select" defaultValue={form.data.type} disabled style={primaryWidth} />
                                                </StackItem>

                                                <StackItem>
                                                  {form.data.answer.map((subform: any, j: any) => (
                                                    <Stack key={subform.id}>
                                                      {form.data.type != '' && (
                                                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Answer label </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.alabel}
                                                                onChange={(e: any) => {
                                                                  handleAnswerInput(i, j, e.target.value, 'alabel');
                                                                }}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>

                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Score </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.score}
                                                                onChange={(e: any) => {
                                                                  handleAnswerInput(i, j, e.target.value, 'score');
                                                                }}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>

                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Answer description </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.adesc}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>
                                                        </Stack>
                                                      )}
                                                    </Stack>
                                                  ))}
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                          </Stack>
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </>
                  ))}
                </StackItem>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Final Message to be displayed after the Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="16"
                          size="small"
                          disabled={true}
                          value={configFormData.FinalMessage}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, FinalMessage: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>
              </Stack>
            ),
          },
        });

      overlayName == 'editSurvey' &&
        automationStatus !== 'not attached' &&
        setAutomationOverLayData({
          size: 'large',
          isTabs: true,
          headerTitle: <Title3> {selectedAutomation} Survey </Title3>,
          tabItems: {
            Configuration: (
              <Stack tokens={{ padding: 15 }}>
                <StackItem>
                  <Body1Strong> Survey Name </Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 550 }}>
                  <StackItem grow>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>

                <StackItem>
                  <Body1Strong> Survey Description </Body1Strong>
                </StackItem>

                <Resize inputChange={setFormData} inputValue={formData} disabled={true} />

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Greetings to be displayed to the User </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="11"
                          size="small"
                          disabled={true}
                          value={configFormData.greeting}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, greeting: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> The need for the User Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="12"
                          size="small"
                          disabled={true}
                          value={configFormData.header}
                          onChange={e => {
                            setConfigFormData({ ...configFormData, header: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Survery time out in minutes </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="13"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyTimeOutInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyTimeOutInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Time interval after which survey should be retried </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="14"
                          size="small"
                          disabled={true}
                          value={configFormData.SurveyIntervalInMsec}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, SurveyIntervalInMsec: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Maximun trials for the survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="15"
                          size="small"
                          disabled={true}
                          value={configFormData.MaxTriesCount}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, MaxTriesCount: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>

                <StackItem>
                  {cloneForms?.map((form: any, i: any) => (
                    <>
                      {/* {i != 0 && <Divider />} */}
                      <Stack horizontal key={form.id}>
                        <StackItem grow>
                          <Stack>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Stack key={form.id}>
                                    <Accordion collapsible style={accordionMargin}>
                                      <AccordionItem value="reviewData_2">
                                        <AccordionHeader expandIconPosition="end">
                                          <Stack tokens={{ childrenGap: 10 }}>
                                            <StackItem>
                                              <Body1Strong> Survey Question {i + 1} </Body1Strong>
                                            </StackItem>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                <Input
                                                  id={i}
                                                  size="small"
                                                  disabled={true}
                                                  value={form.data.qdesc}
                                                  onChange={(e: any) => {
                                                    handleInput(i, e.target.value, 'qdesc');
                                                  }}
                                                  style={primaryWidth}
                                                />
                                              </Stack>
                                            </StackItem>
                                          </Stack>
                                        </AccordionHeader>

                                        <AccordionPanel>
                                          <Stack tokens={{ childrenGap: 10 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Body1Strong> Question Label </Body1Strong>
                                                </StackItem>
                                                <Input
                                                  id={i}
                                                  size="small"
                                                  disabled={true}
                                                  value={form.data.qlabel}
                                                  onChange={(e: any) => {
                                                    handleInput(i, e.target.value, 'qlabel');
                                                  }}
                                                  style={primaryWidth}
                                                />
                                              </Stack>
                                            </StackItem>

                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                  <Body1Strong>
                                                    Survey Answers <Image src="/visualization/v2/image/required.svg" />
                                                  </Body1Strong>
                                                </StackItem>
                                                <StackItem>
                                                  <Dropdown placeholder="Select" defaultValue={form.data.type} disabled style={primaryWidth} />
                                                </StackItem>

                                                <StackItem>
                                                  {form.data.answer.map((subform: any, j: any) => (
                                                    <Stack key={subform.id}>
                                                      {form.data.type != '' && (
                                                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Answer label </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.alabel}
                                                                onChange={(e: any) => {
                                                                  handleAnswerInput(i, j, e.target.value, 'alabel');
                                                                }}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>

                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Score </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.score}
                                                                onChange={(e: any) => {
                                                                  handleAnswerInput(i, j, e.target.value, 'score');
                                                                }}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>

                                                          <StackItem>
                                                            <Stack tokens={{ childrenGap: 10 }}>
                                                              <StackItem>
                                                                <Body1Strong> Answer description </Body1Strong>
                                                              </StackItem>
                                                              <Input
                                                                id={j}
                                                                size="small"
                                                                disabled={true}
                                                                value={subform.adesc}
                                                                style={secondaryWidth}
                                                              />
                                                            </Stack>
                                                          </StackItem>
                                                        </Stack>
                                                      )}
                                                    </Stack>
                                                  ))}
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                          </Stack>
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </>
                  ))}
                </StackItem>

                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Body1Strong> Final Message to be displayed after the Survey </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem grow>
                      <Stack>
                        <Input
                          id="16"
                          size="small"
                          disabled={true}
                          value={configFormData.FinalMessage}
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, FinalMessage: e.target.value });
                          }}
                          style={primaryWidth}
                        />
                      </Stack>
                    </StackItem>
                  </Stack>
                </Stack>
              </Stack>
            ),
          },
        });
    }
  }, [dartSpecs, overlayName, cloneForms, cloneScore, formData, selectedOption, configFormData, classificationNameValidation]);

  useEffect(() => {
    !overlayOpen && setOverlayName('');
  }, [overlayOpen]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'User Survey',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });
    setTracker(true);
  };

  useEffect(() => {
    if (saveBtnClicked1) {
      overlayName == 'addSurvey' &&
        userActivity(
          'Add',
          `{
          "automationName":
          "${formData.name}",
          "configuration":
          ${JSON.stringify(formData.jsonData)},
          "type":
          "UserSurvey",
          "summary":
          "${formData.name} survey is created"
        }`,
        );

      overlayName == 'editSurvey' &&
        userActivity(
          'Edit',
          `{
          "automationName":
          "${formData.name}",
          "configuration":
          ${JSON.stringify(formData.jsonData)},
          "type":
          "UserSurvey",
          "summary":
          "${formData.name} survey is edited"
        }`,
        );
    }
  }, [saveBtnClicked1]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  return (
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink>
                <Breadcrumbs pageName="Automation" subPageName="All Automation" subPageLink="/automate/automation/all_automations" />
              </StackItem>
            </Stack>
          </StackItem>

          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink>
                <Button
                  style={fullscreenIcon}
                  size="small"
                  shape="square"
                  appearance="subtle"
                  onClick={handleFullScreen}
                  title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                  icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>

      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          {/* <StackItem> <Text> <Breadcrumbs pageName="Automation" /> </Text>  </StackItem> */}
          <Stack tokens={{ childrenGap: 25 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <FluentProvider theme={webLightTheme}>
                <Title3> Automation | User Survey </Title3>
              </FluentProvider>
            </StackItem>
            {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
          </Stack>

          <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>

          <FluentProvider theme={webLightTheme}>
            <Stack horizontal>
              {/* SPACING BETWEEN INLINE AND PAGE */}
              <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
              <StackItem>
                <StackItem>
                  <Text></Text>
                </StackItem>
                <Stack tokens={{ childrenGap: 1 }}>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>
                <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                    <Divider />
                  </StackItem>

                  <StackItem>
                    <StackItem>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <SearchBox
                          size="small"
                          placeholder="Search"
                          onChange={(e: any) => {
                            setSearchVal(e.target.value);
                          }}
                          dismiss={null}
                        />
                        <StackItem>
                          <MenuDropdown
                            open={filterMenuOpen}
                            btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            }
                            itemVal={
                              <>
                                <Body1Strong> Add a Filter </Body1Strong>
                                <Divider />
                                <MenuList>
                                  <Stack tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Filter </Caption1>
                                    </StackItem>

                                    <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />

                                    <StackItem>
                                      <Divider />
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Value </Caption1>
                                    </StackItem>
                                    {/* <DropdownComponent optionData={columns}/> */}

                                    <Input size="small" name="filter" onChange={inputChange} value={dropDownValue2} />

                                    <StackItem>
                                      <Divider />
                                    </StackItem>

                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                      <StackItem>
                                        <Button
                                          appearance="primary"
                                          onClick={() => {
                                            handleFilterArray();
                                            setFilterMenuOpen(false);
                                          }}
                                        >
                                          <Caption1> Apply </Caption1>
                                        </Button>
                                      </StackItem>
                                      <StackItem>
                                        <Button onClick={() => setFilterMenuOpen(false)}>
                                          <Caption1> Cancel </Caption1>
                                        </Button>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                </MenuList>
                              </>
                            }
                          />
                        </StackItem>

                        <StackItem>
                          {filterArray.length > 0 &&
                            filterArray.map((item, index) => (
                              <Badge appearance="filled" color="informative">
                                <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Dismiss16Regular
                                      onClick={() => {
                                        handleCloseFilter(index);
                                      }}
                                    />
                                  </StackItem>
                                </Stack>
                              </Badge>
                            ))}
                        </StackItem>

                        <StackItem
                          onClick={() => {
                            setFilterArray([]);
                            setIsFilter(false);
                            setDropDownValue2('');
                            setResetDisabled(true);
                          }}
                        >
                          <Button size="small" appearance="subtle" disabled={isResetDisabled}>
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                              <StackItem>
                                {isResetDisabled ? <FilterDismiss20Filled {...secondaryIcon} /> : <FilterDismiss20Filled {...primaryIcon} />}
                              </StackItem>
                              <StackItem>
                                <Caption1> Reset Filters </Caption1>
                              </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>

                    <Stack tokens={{ childrenGap: 10, padding: 5 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>

                    <StackItem>
                      <FluentProvider theme={messageTheme}>
                        <Card appearance="subtle" size="small">
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                                <StackItem>
                                  <Info24Filled {...primaryIcon} />
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <StackItem>
                              <Caption1>
                                Explore the Nanoheal Database for an in-depth understanding of this Automation Schedules. Learn more about its
                                capabilities, features, and recommended practices to enhance your utilization of this automation solution within your
                                ecosystem.
                              </Caption1>
                            </StackItem>

                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>

                            <StackItem>
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal onClick={() => (window.location.href = 'https://www.google.com')}>
                                    <StackItem>
                                      <Link>
                                        <Caption1 underline> Know&nbsp;More </Caption1>
                                      </Link>
                                    </StackItem>
                                    <StackItem>
                                      <Open20Regular {...primaryIcon} />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </Card>
                      </FluentProvider>
                    </StackItem>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>

                    {tableData.length > 0 ? (
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem>
                          {totalRecords > 0 && (
                            <Caption1>
                              Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                              {totalRecords} Records
                            </Caption1>
                          )}
                        </StackItem>

                        <StackItem>
                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack>
                    ) : (
                      <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Caption1> No Results. </Caption1>
                        </StackItem>
                      </Stack>
                    )}
                  </StackItem>

                  {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}

                  {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}

                  {!saveBtnClicked && overlayName == '' && (
                    <UserSurvey_func
                      data={final_data}
                      func={set_final_data}
                      setDartSpecs={setDartSpecs}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 50}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                      deleteSurvey={deleteSurvey}
                      setDeleteSurvey={setDeleteSurvey}
                      setDeleteDisabled={setDeleteDisabled}
                      isRefresh={isRefresh}
                      setRefresh={setRefresh}
                      overlayName={overlayName}
                      setIsToast={setIsToast}
                      setExportDetails={setExportDetails}
                      setRefreshToast={setRefreshToast}
                    />
                  )}

                  {!saveBtnClicked && overlayName == 'editSurvey' && (
                    <UserSurvey_func
                      data={final_data}
                      func={set_final_data}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 50}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                      deleteSurvey={deleteSurvey}
                      setDeleteSurvey={setDeleteSurvey}
                      setDeleteDisabled={setDeleteDisabled}
                      isRefresh={isRefresh}
                      setRefresh={setRefresh}
                      overlayName={overlayName}
                      setIsToast={setIsToast}
                      setDartSpecs={setDartSpecs}
                      rowID={rowID}
                      setFormData={setFormData}
                      setConfigFormData={setConfigFormData}
                      setErrorToast={setErrorToast}
                      setToastMessage={setToastMessage}
                    />
                  )}

                  {saveBtnClicked1 && overlayName != '' && (
                    <UserSurvey_func
                      newData={formData}
                      saveBtnClicked={saveBtnClicked}
                      setSaveBtnClicked={setSaveBtnClicked1}
                      overlayName={overlayName}
                      rowID={rowID}
                      setFormData={setFormData}
                      func={set_final_data}
                      setIsToast={setIsToast}
                      setOverlayOpen={setOverlayOpen}
                      setErrorToast={setErrorToast}
                      setToastMessage={setToastMessage}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                    />
                  )}

                  {/* {saveBtnClicked1 && overlayName != '' && <UserSurvey_func rowID={rowID} overlayName={overlayName} setFormData={setFormData} formData={formData} setDartSpecs={setDartSpecs} />} */}
                </Stack>
              </StackItem>
            </Stack>
          </FluentProvider>
        </Stack>

        <OverLay
          overlayopen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          overlayData={automationOverLayData}
          saveBtnFunc={setSaveBtnClicked}
          isNavBtn={automationStatus !== 'not attached' ? false : true}
          handleOutsideButton={handleOutsideButton}
          handleSubmit={handleSubmit}
          classificationNameValidation={classificationNameValidation}
        />

        {isToast && (
          <ToastComponent
            setmessagebar={setIsToast}
            isToastEnabled={isToast}
            toastIntent="success"
            position="top-end"
            title="Success"
            footer=""
            message={
              !saveBtnClicked1 && overlayName == ''
                ? 'Automation Deleted Successfully'
                : overlayName == 'addSurvey'
                ? 'New Automation Created Successfully'
                : 'Automation Edited Successfully'
            }
          />
        )}

        {refreshToast && (
          <ToastComponent
            setmessagebar={setIsToast}
            isToastEnabled={refreshToast}
            toastIntent="success"
            position="top-end"
            title="Success"
            message={'Data Table Refreshed'}
            footer=""
          />
        )}

        {errorToast && (
          <ToastComponent setmessagebar={setErrorToast} toastIntent="error" position="top-end" title="Failed" message={toastMessage} footer="" />
        )}
      </StackItem>
    </FullScreen>
  );
};
