import { Stack, StackItem } from "@fluentui/react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbDivider, BreadcrumbButton } from "@fluentui/react-breadcrumb-preview";
import { Body1, FluentProvider, Text } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { breadcrumbTheme } from "../../styles/customStyles";

export const Breadcrumbs = (props: any) => {
  const navigate = useNavigate();

  return (
  <>
    <FluentProvider theme={breadcrumbTheme}>
      <Stack horizontal tokens={{childrenGap:20}}>
        <StackItem><Text></Text></StackItem>
        <StackItem>
          <Breadcrumb aria-label="Breadcrumb default example">
            <BreadcrumbItem> 
              <BreadcrumbButton size="small"onClick={() => navigate("/Dashboard")}> 
                <Body1> Home </Body1> 
              </BreadcrumbButton> 
            </BreadcrumbItem>

            { props.subPageName?.length == 0 && 
              <>
                <Stack tokens={{childrenGap:3}}>
                  <StackItem><Text></Text></StackItem>
                  <StackItem> <BreadcrumbDivider/> </StackItem>
                </Stack>

                <BreadcrumbItem> 
                  <BreadcrumbButton size="small" onClick={() => navigate("#")}> 
                    <Body1> {props.pageName} </Body1> 
                  </BreadcrumbButton> 
                </BreadcrumbItem>
              </>
            }
            
            { props.subPageName?.length > 0 && 
              <>
                <BreadcrumbDivider/>
                <BreadcrumbItem>
                  <BreadcrumbButton onClick={() => navigate(props.subPageLink)}> 
                    <Body1> {props.pageName} | {props.subPageName} </Body1> 
                  </BreadcrumbButton>
                </BreadcrumbItem>
              </>
            }
          </Breadcrumb>
        </StackItem>
      </Stack>
    </FluentProvider>
  </>
  );
};