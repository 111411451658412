import { gql } from '@apollo/client';

const insertFeedback = gql`
mutation MyMutation($expression: String = "", $experience: String = "", $email: String = "", $contact_me: String = "") {
insert_audit_Feedback(objects: {contact_me: $contact_me, email: $email, experience: $experience, expression: $expression}) {
    affected_rows
}
}
`;




export {insertFeedback};