import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery } from "../../graphql/configuration_queries";
import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    deleteSurvey: any;
    setDeleteSurvey: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
}

const UserSurvey_func = (props: any) => {
  console.log("props new data=",props?.newData);
  
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;

  const deleteUnattachedAutomation = async (ids: any) => {
    var dartid = parseInt(dartNo);
    const { data } = await client.mutate({
      mutation: deleteUnattachedAutomationQuery,
      variables: {ids, dartid},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteSurvey(false);
      props.setIsToast(true);
      getUserSurveyAutomationList({dartid: {_eq: dartid}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
    }
  }

  const getUserSurveyAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });
    
    if(!loading && data){
      if(props.overlayName == 'editSurvey'){
        console.log("edited value=",data.core_AutomationJSON[0]);
        
        props.setFormData({...props.formData,
          name: data.core_AutomationJSON[0]?.automationName,
          description: data.core_AutomationJSON[0]?.description,
          jsonData: JSON.parse(data.core_AutomationJSON[0]?.jsonValue)
        });
      }else {
        props.func(data);
      }
    }
  };

  const getUserSurveyAutomationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON?.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON?.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON?.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };

  const addSurvey = async (automationName: String, description: String, jsonVal: any, createdBy: String, dartid: number) => {
    const createdOn =  Math.floor(Date.now() / 1000);

    jsonVal.SurveyTimeOutInMsec = (parseInt(jsonVal.SurveyTimeOutInMsec) * 60000).toString();
    jsonVal.SurveyIntervalInMsec = (parseInt(jsonVal.SurveyIntervalInMsec) * 60000).toString();
    var jsonValue = JSON.stringify(jsonVal);
    var types: any = 'usersurvey';
    var category: any = 'User Survey';
    var varValueObj: any = {};
    var varValues = '';

    // if(dartid == 60){
    //   varValueObj = jsonVal;
    //   var componentsArr = ['S00060TargetStr', 'S00060FileGroupsStr'];
      
    //   componentsArr.map((compName: any) => {
    //     var compVal = '';

    //     varValueObj[`${compName}`]?.map((val: any, i: any) => {
    //       compVal += i == 0 ? Object.values(val).join(",") : "\n" + Object.values(val).join(",");
    //     });
    //     console.log(compName+"="+compVal);
        
    //     varValueObj = {...varValueObj, [`${compName}`]: compVal};
    //   });
    // }
    // varValues = JSON.stringify(varValueObj);
    
    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid, category, varValues}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      props.setSaveBtnClicked(false);
      getUserSurveyAutomationList({dartid: {_eq: dartid}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
    }
  };

  const editSurvey = async (automationName: String, description: String, jsonVal: any, rowID: Number, dartid: Number) => {
    const lastModified: any = Math.floor(Date.now() / 1000);
    
    jsonVal.SurveyTimeOutInMsec = (parseInt(jsonVal.SurveyTimeOutInMsec) * 60000).toString();
    jsonVal.SurveyIntervalInMsec = (parseInt(jsonVal.SurveyIntervalInMsec) * 60000).toString();
    var jsonValue: any = JSON.stringify(jsonVal);
    
    var varValueObj: any = {};
    var varValues = '';
 
    varValueObj = jsonVal;
    // if(dartid == 60){
    //   var componentsArr = ['S00060TargetStr', 'S00060FileGroupsStr'];
      
    //   componentsArr.map((compName: any) => {
    //     var compVal = '';

    //     varValueObj[`${compName}`]?.map((val: any, i: any) => {
    //       compVal += i == 0 ? Object.values(val).join(",") : "\n" + Object.values(val).join(",");
    //     });
        
    //     varValueObj = {...varValueObj, [`${compName}`]: compVal};
    //   });
    // }
    varValues = JSON.stringify(varValueObj);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid, varValues}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      props.setSaveBtnClicked(false);
      getUserSurveyAutomationList({dartid: {_eq: dartid}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };
    
  useEffect(() => {    
    var dartid = parseInt(dartNo);
    
    if(props.overlayName == '' && props.isRefresh == false && props.deleteSurvey == false){
      getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
      props.setRefresh(false); 
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }

    if(props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
        getUserSurveyAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
        getUserSurveyAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    }
    // else if(props.isFilter == true){
    //   if(props.dropDownValue1 == 'Name'){
    //     getUserSurveyAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
    //     getUserSurveyAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.dropDownValue2}}); 
    //   }else if(props.dropDownValue1 == 'Created by'){
    //     getUserSurveyAutomationList({dartid: {_eq: dartid}, createdBy: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
    //     getUserSurveyAutomationListCnt({dartid: {_eq: dartid}, createdBy: {contains: props.dropDownValue2}}); 
    //   }else if(props.dropDownValue1 == 'Status'){
    //     getUserSurveyAutomationList({dartid: {_eq: dartid}, status: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
    //     getUserSurveyAutomationListCnt({dartid: {_eq: dartid}, status: {contains: props.dropDownValue2}}); 
    //   }
    // }
    else if(props.deleteSurvey && contextValue.selectedRowIdInTable.length > 0){
        deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
        console.log("props.newData=",props.newData);
        
        props.overlayName == 'addSurvey' && addSurvey(props.newData.name, props.newData.description, props.newData.jsonData, userName, dartNo);

        props.overlayName == 'editSurvey' && editSurvey(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID, dartNo);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editSurvey'){
      getUserSurveyAutomationList({dartid: {_eq: dartNo}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else{
      props.overlayName != 'addSurvey' && getUserSurveyAutomationList({dartid: {_eq: dartid}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));

      props.overlayName == '' && getUserSurveyAutomationListCnt({dartid: {_eq: dartid}, types: {_eq: 'usersurvey'}});
    }
    
  },[props.deleteSurvey, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo, props.isFilte]);

  return (
    <></>
  )
}

export { UserSurvey_func }
