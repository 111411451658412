import { useState, useEffect, SetStateAction, useContext, useRef } from "react";
import { Swd_func } from "../../../api/swd_func";
// import { Census_func } from "../../../api/devices/census_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { Card, MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, Dropdown, Popover, PopoverTrigger, PopoverSurface, ButtonProps, Tooltip } from "@fluentui/react-components";
import { AddSquare20Regular, SaveCopy20Regular, Info20Filled, Filter20Filled, FilterDismiss20Filled, SlideEraser20Regular, Warning20Filled, ArrowMaximize20Regular, Desktop20Filled, Delete24Regular, AddSquare20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, DismissSquare24Filled, AppsAddIn20Regular, BookTemplate20Regular, BoxCheckmark20Regular, CheckboxCheckedSync20Regular, PulseSquare20Regular, ShieldLock20Regular, SquareHintApps20Regular, UninstallApp20Regular, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { Resize } from "../../../components/textarea/textarea.component";
import { Checked } from "../../../components/switch/switch.component";
import { Intent } from "../../../components/messagebar/messagebar.component";
import { Placeholder } from "../../../components/textarea/placeholder.component";
import { Inputs } from "../../../components/textarea/input.component";
import { AccordionComponent } from "../../../components/accordion/accordion.component";
import { RadioGroups } from "../../../components/radio_group/radioGroup.component";
import FileUploadComponent from "../../../components/fileImport/file.import.component";
import { CloneForm } from "../../../components/bit_confi_form/cloneform";
import { ToastComponent } from "../../../components/toast/toast.component";
import { Form, Widgets } from "@rjsf/fluent-ui";
import React from "react";
import { CustomWidgets } from '../../../components/form-components';
import validator from '@rjsf/validator-ajv8';
//////////////////////////////////////////////////////////
import { GlobalContext } from "../../../hooks/globalContext";
import DialogBox from "../../../components/dialog/dialog.component";
import { RJSFSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, messageTheme, lightTheme, overflowDivider } from "../../../styles/customStyles";
import { useLocation } from "react-router-dom";
import { UserRoleClaim, PermissionClaim } from "supertokens-web-js/recipe/userroles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";










declare module 'json-schema' {
  export interface JSONSchema7 {
    enumNames?: Array<string>;
  }
}


function handleExportCSV() {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
}

export const Appinstallation = () => {
  const location = useLocation();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [rowID, setRowID]: any = useState(null);
  const [rowconfID, setRowconfID]: any = useState(null);
  const [installationOverLayData, setInstallationOverLayData]: any = useState([]);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isdeletedisabled, setDeleteDisabled] = useState(false);
  const [itemArr, setItemArr]: any = useState([]);
  const [remDiv, setRemDiv]: any = useState(2);
  const [preCheck, setPreCheck] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null || '');
  const [selectUpload, setUpload] = useState('existing');
  const [selectCommon, setCommon] = useState('');
  const [savePackageDetails, setSavePackageDetails] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [jsonSchemaPkg, setJsonDataPkg]: any = useState({});
  const [uiSchemaPkg, setUiDataPkg]: any = useState({});
  const [jsonSchemaApp, setJsonDataApp]: any = useState({});
  const [uiSchemaApp, setUiDataApp]: any = useState({});
  const [jsonSchemaAdv, setJsonDataAdv]: any = useState({});
  const [uiSchemaAdv, setUiDataAdv]: any = useState({});
  const [formDataUser, setFormDataUser] = useState(null);
  const [isDelete, setDelete] = useState(false)
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [permissions,setPermissions] = useState([''])


  // const [formDataPkg, setFormDataPkg] = React.useState(null);
  const [formDataPkg, setFormDataPkg] = React.useState({});
  const [formDataApp, setFormDataApp] = React.useState({});
  const [formDataAdv, setFormDataAdv] = React.useState({});


  // console.log("checking the formdataAPP",formDataApp)
  console.log("checking the formData of pkg configuration", formDataPkg)
  console.log("checking the formData of App configuration", formDataApp)
  console.log("checking the formData of Adv configuration", formDataAdv)
  console.log("checking rowID here ", rowID)

  // console.log("checking the formData of ADVANCECONFIGURATION configuration",formDataAdv)

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log("entered value", enteredValue)
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  }

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  console.log("checking the formdatAdv", formDataAdv)
  // call the save package here
  const saveBtnFunc = () => {
    setSavePackageDetails(true)
    setIsToast(true)
    setOverlayOpen(false)
  }

  const handleEditData = (data: any) => {
    const pkgDetails = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataPkg)
    const appDetail = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataApp)
    const advDetail = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataAdv)
    setFormDataPkg(pkgDetails)
    setFormDataApp(appDetail);
    setFormDataAdv(advDetail);
  }
  console.log('datapkg', formDataUser)

  const [deleteprofile, setDeleteprofile]: any = useState(false);
  const [deleteId, SetDeleteId]: any = useState([]);

  //advance config start

  const [readfile, setReadFile] = useState(false);
  const [postvalidation, setPostvalidaation] = useState(false);

  //advance config end
  // logic for clone form starts here
  interface FormItem {
    id: number;
    data: {};
  }

  const [selectedFrs, setSelectedFrs] = React.useState('files');
  const handleFrs = (e: any) => {
    var optionFrs = e.target.value;
    setSelectedFrs(optionFrs);
  }
  const [cloneForms, setCloneForms] = useState<FormItem[]>([]);

  const handleAddClone = () => {
    if (cloneForms.length < 3) {
      setCloneForms([...cloneForms, { id: Date.now(), data: {} }]);

    }

  };
  const handleRemoveClone = (id: any) => {
    setCloneForms(cloneForms.filter((form) => form.id !== id));
  };

  //will remove later after checking
  const handleOptionChange = (value: any) => {
    var optionValue = value.target.value;
    setSelectedOption(optionValue);

    if (optionValue === 'yes' && cloneForms.length != 4) {
      handleAddClone();
      setSelectedOption('');
    }
    else {
      setSelectedOption('no');
    }
  }

  const userActivity = (action: String, activity: String, status: any = 'success') => {
    setUserActivityData({ pageName: 'App Installation', moduleName: "apps", action: action, time: Math.floor(Date.now() / 1000), activity: activity, status: status });

    setTracker(true);
  }
  useEffect(() => {
    if (savePackageDetails) {
      // overlayName == 'addInstallation' && userActivity('add', `{"applicationname":"${JSON.stringify(formDataPkg)}","configuration":${JSON.stringify(formDataApp)},"summary":"${JSON.stringify(formDataPkg)} app is added"}`);
      // overlayName == 'editInstallation' && userActivity('edit', `{"applicationname":"${JSON.stringify(formDataPkg)}","configuration":${JSON.stringify(formDataApp)},"summary":"${JSON.stringify(formDataPkg)} app is added"}`);
    }
  }, [savePackageDetails]);


  //logic for clone form end here

  const handleCommon = (value: any) => {
    var commonOption = value.target.value;
    setCommon(commonOption);
  }

  const handleUpload = (value: any) => {
    var uploadOption = value.target.value;
    setUpload(uploadOption);
  }

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if(value1 !== undefined && value1 !== '' && value2 !== undefined && value2 !== '') {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray,result])
      setIsFilter(true);
    }
  }
  const handleFilterValueSelect = (event: { target: { value: Event; }; }):void => {
    alert(event.target.value);
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("clicked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
 
  useEffect(() => {
   if(filterArray.length > 0)
   setResetDisabled(false)
  
  }, [filterArray]);





  var menuItems: any = [];

  const visibility = [ "/automate/apps/appinstallation", "/automate/apps/appuninstallprofile", "/automate/apps/appprotectionpolicies" ].includes(location.pathname);



  {
    !(visibility) && menuItems.push
      (
        <MenuItem
          onClick={() => navigate("/manage/apps/appmonitor")} icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem><Text></Text></StackItem>
              <StackItem> <PulseSquare20Regular {...primaryIcon} /> </StackItem>
            </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > App&nbsp;Monitored</Caption1> </StackItem>
            <StackItem> <Stack tokens={{ childrenGap: 48 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
            {/* SPACING IN INLINE SIDEBAR */}
          </Stack>
        </MenuItem>

      );
  }


  {
    (visibility) && menuItems.push
      (
        <FluentProvider theme={lightTheme}>
          <MenuItem onClick={() => navigate("/automate/apps/appinstallation")} icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem><Text></Text></StackItem>
              <StackItem><AppsAddIn20Regular {...primaryIcon} /> </StackItem>
            </Stack>}>
            <Stack tokens={{ childrenGap: 30 }} horizontal>
              <StackItem><Text></Text></StackItem>
              <StackItem> <Caption1 > App&nbsp;Installation </Caption1> </StackItem>
              <StackItem> <Stack tokens={{ childrenGap: 48 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
            </Stack>
          </MenuItem>
        </FluentProvider>

      );
  }

  {
    !(visibility) && menuItems.push
      (
        <MenuItem onClick={() => navigate("/manage/apps/appinstallstatus")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <BoxCheckmark20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1  > App Install Status </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      );
  }

  {
    (visibility) && menuItems.push
      (
        <MenuItem onClick={() => navigate("/automate/apps/appuninstallprofile")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <UninstallApp20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > App Uninstall Profiles </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      );
  }

  {
    !(visibility) && menuItems.push
      (
        <MenuItem onClick={() => navigate("/manage/apps/appuninstallstatus")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <CheckboxCheckedSync20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > App Uninstall Status </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      );
  }
  {
    !(visibility) && menuItems.push
      (
        <MenuItem onClick={() => navigate("/manage/apps/discoveredapps")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <SquareHintApps20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > Discovered Apps </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      );
  }

  {
    (visibility) && menuItems.push
      (
        <MenuItem onClick={() => navigate("/automate/apps/appprotectionpolicies")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <ShieldLock20Regular {...primaryIcon} /></StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > App Protection Policies </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      );
  }
  {
    !(visibility) && menuItems.push
      (

        <MenuItem onClick={() => navigate("/manage/apps/activitylogg")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <BookTemplate20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > Activity Log </Caption1> </StackItem>
          </Stack>
        </MenuItem>

      );
  }
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    // { key: "pkgId", label: <Body1Strong> pkgName </Body1Strong> },
    { key: "names", label: <Body1Strong> Name </Body1Strong> },
    { key: "description", label: <Body1Strong>Description </Body1Strong> },
    { key: "version", label: <Body1Strong>Version</Body1Strong> },
    { key: "os", label: <Body1Strong>Operating System</Body1Strong> },
    { key: "created_on", label: <Body1Strong>Created On</Body1Strong> },
    { key: "modified_on", label: <Body1Strong>Modified On</Body1Strong> },
    { key: "status", label: <Body1Strong>Status</Body1Strong> },

  ];

  var columns1 = [
    // { key: "pkgId", label: <Body1Strong> pkgName </Body1Strong> },
    { key: "names", label: <Caption1> Name </Caption1> },
    { key: "version", label: <Caption1>Version</Caption1> },
    { key: "description", label: <Caption1>Description </Caption1> },
    { key: "os", label: <Caption1>Operating System</Caption1> },
    { key: "created_on", label: <Caption1>Created On</Caption1> },
    { key: "status", label: <Caption1>Status</Caption1> },

  ];



  var radioData = {
    "Upload the app": "upload",
    "Select a pre-existing application": "existing",
  };
  var radioData2 = {
    "Yes": "yes",
    "No": "no",
  };
  var radioData3 = {
    "Proceed only if pre-check value exist": "yes",
    "Proceed only if pre-check value absent": "no"
  };

  var radioData4 = {
    "File": "files",
    "Registry": "registry",
  };
  var radioData5 = {
    "Default": "yes",
    "Specify the fil path": "no",
  };
  var radioData6 = {
    "Default": "yes",
    "Specify download location": "no",
  };
  var radioData7 = {
    "Fore Ground": "yes",
    "Background": "no",
  };
  var radioData8 = {
    "Yes": "yes",
    "No": "no",
  };

  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>

        <Stack tokens={{ childrenGap: 10 }} horizontal>
        {permissions?.includes("appInstallation_Fullaccess") ?
          <StackItem>
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem><Text></Text></StackItem>
                <StackItem>
                  <Stack tokens={{childrenGap:5}} horizontal>
                   <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                   <StackItem> 
                   <Caption1 onClick={() => {setFormDataPkg({}); setOverlayOpen(true);setOverlayName('addInstallation');}}> Add App Installation Profile </Caption1>
                   </StackItem>
                 </Stack>
                </StackItem>
              </Stack>
            </Button>
          </StackItem>
       :
          <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
          <Button appearance="subtle" size="small" disabled>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem><Text></Text></StackItem>
              <StackItem>
                <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <AddSquare20Regular {...secondaryIcon} /> </StackItem>
                  <StackItem><Caption1> Add App Installation Profile </Caption1></StackItem>
                </Stack>
              </StackItem>
            </Stack>
          </Button>
        </Tooltip> 
       }


          <StackItem>
            <Divider vertical />
          </StackItem>

          {/* <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:6}} horizontal>
             <StackItem><Text></Text></StackItem>
             <StackItem>
              <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
              </Stack>
              </StackItem>
            </Stack>
          </Button>
        </StackItem> */}

          {/* <StackItem>
        <Divider vertical/>
        </StackItem> */}

          <StackItem>
            <DialogBox
              headerButton={
                <>
                  <Button appearance="transparent" size="small"
                    disabled={isdeletedisabled ? false : true} >
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {isdeletedisabled
                          ? (<SlideEraser20Regular {...primaryIcon} />)
                          : (<SlideEraser20Regular {...secondaryIcon} />)
                        }
                      </StackItem>
                      <StackItem onClick={() => { setDelete(true) }}> <Caption1> Delete </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </>
              }

              content="Kindly verify your intent to delete the package."

              footer=
              {
                <>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Button appearance="primary" onClick={() => { setDeleteprofile(true); setCurrentPageNo(1); userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} app deleted"}`);}} size="small">Confirm</Button>
                    </StackItem>
                    <StackItem>
                      <Button size="small">Cancel</Button>
                    </StackItem>
                  </Stack>
                </>
              }
            />
          </StackItem>

          <StackItem>
            <Divider vertical />
          </StackItem>

          {/* <Stack tokens={{childrenGap:6}}> */}
          <StackItem><Text></Text></StackItem>
          <StackItem>
            <Button appearance="subtle" size="small" onClick={() => { setCurrentPageNo(1); setIsRefresh(true); }}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowClockwise20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Refresh </Caption1> </StackItem>
              </Stack>
            </Button>
           {permissions?.includes("appInstallation_Fullaccess") || permissions?.includes("appInstallation_ReadandExport") ?
              <CSVLink id="csv-export-link" data={exportData} filename="appinstallations_list.csv">
                <Button appearance="subtle" size="small" onClick={() => { userActivity('Export', `{"summary" : "App Installation List Exported"}`) }}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>  
           :
              <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
                <Button appearance="subtle" size="small" disabled>
                 <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <ArrowDownload20Regular {...secondaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Export </Caption1> </StackItem>
                 </Stack>
               </Button>
             </Tooltip>
           }

            {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
          </StackItem>
          {/* </Stack> */}
        </Stack>
      </>
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        names: val.packageName,
        description: val.packageDesc,
        version: val.version,
        os: val.platform,
        created_on: val.creationTime,
        modified_on: new Date(val.lastModified * 1000).toLocaleString(),
        status: val.status
      });
    });
    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    
    async function loadRoute() {
      console.log("role:-",sessionStorage.getItem('role'))
      const role = sessionStorage.getItem('role');
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    // data table values append code starts here
    console.log("final_data=", final_data);
    const uniqueIds = new Set(); // Set to store unique app_packageIds
    const updatedTempTableData = final_data !== undefined && final_data !== null
      ? final_data.softinst_Packages.reduce((accumulator: any, val: any, i: any) => {
        if (!uniqueIds.has(val.app_packageId)) {
          uniqueIds.add(val.app_packageId); // Add app_packageId to the set
          {permissions?.includes("appInstallation_Fullaccess") ?
            accumulator.push({
              id: val.app_packageId,
              names: { label: <Caption1 onClick={(e: any) => { setRowID(val.id); setRowconfID(val.app_packageId); setOverlayOpen(true); setOverlayName('editInstallation'); }}> {val.packageName !== null ? val.packageName : 'N/A'} </Caption1>, id: `${i}` },
              description: { label: <Caption1 > {val.packageDesc != "" ? val.packageDesc : 'N/A'} </Caption1>, status: "" },
              version: { label: <Caption1> {val.version} </Caption1>},
              os: { label: <Caption1> {val.platform} </Caption1>},
              created_on: { label: <Caption1> {val.creationTime != null ? val.creationTime : '-'} </Caption1>},
              modified_on: { label: <Caption1>{new Date(val.lastModified * 1000).toLocaleString()}</Caption1>},
              status: { label: <Caption1> {val.status != null ? "Draft" : "Draft"} </Caption1>},
            })
          :
            accumulator.push({
              checkbox: "hide",
              id: val.app_packageId,
              names: { label: <Caption1> {val.packageName !== null ? val.packageName : 'N/A'} </Caption1>, id: `${i}` },
              description: { label: <Caption1 > {val.packageDesc != "" ? val.packageDesc : 'N/A'} </Caption1>, status: "" },
              version: { label: <Caption1> {val.version} </Caption1>},
              os: { label: <Caption1> {val.platform} </Caption1>},
              created_on: { label: <Caption1> {val.creationTime != null ? val.creationTime : '-'} </Caption1>},
              modified_on: { label: <Caption1>{new Date(val.lastModified * 1000).toLocaleString()}</Caption1>},
              status: { label: <Caption1> {val.status != null ? "Draft" : "Draft"} </Caption1>},
            })
          }
        }

        return accumulator;
      }, [])
      : [];

    console.log("tempTableData=", updatedTempTableData);

    setTableData(updatedTempTableData);
  }, [final_data, rowID]);


  // useEffect(() => {
  //   console.log("TableData=",tableData);

  // }, [tableData])


  // data table values append code ends here
  //   var overLayData: any = {
  //     headerTitle: "Add a New Installation Profile ",
  //     size: "large",
  //     isTabs: true,
  //     tabItems: {
  //       Basic_Details:<Stack tokens={{padding:5}}>
  //        <Form  formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
  //         </Stack>,


  //       App_Configuration: 
  //       <Stack>
  //       <Form formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>

  //       </Stack>,
  //       "Advance Configuration":
  //        <>
  //             <Stack horizontal tokens={{childrenGap:157, padding:5}}>
  //               <Form   formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
  //             </Stack>
  //         </>,

  // "Review Data":<Stack>
  //     <Stack tokens={{padding:5}}>
  //         <Text as = "h4" >Package Details</Text>
  //        <Form   disabled = {true} formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
  //     </Stack>
  //     <Stack>
  //         <Text>App Configurations</Text>
  //         <Form  disabled = {true} formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>
  //     </Stack>
  //         <Text>Advance Configurations</Text>
  //         <Stack horizontal tokens={{childrenGap:157, padding:5}}>
  //         <Form   disabled = {true}  formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
  //     </Stack>
  //    </Stack>
  //     },
  //   };

  useEffect(() => {
    console.log("hiaaaaaaaaaaaaaaaaa");
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  }, []);

  useEffect(() => {
    console.log("main Table=", contextValue.selectedRowIdInTable);
    console.log("sub Table=", contextValue.selectedRowIdInSubTable);

    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(true) : setDeleteDisabled(false);
    console.log("length", contextValue.selectedRowIdInTable.length)


  }, [contextValue.selectedRowIdInTable]);


  useEffect(() => {
    if (formDataPkg != undefined) {

      overlayName == 'addInstallation' && setInstallationOverLayData({
        headerTitle: "Add a New Installation Profile",
        size: "large",
        isTabs: true,
        tabItems: {

          "Basic Details": <Stack tokens={{ padding: 5 }}>
            <StackItem>
              <FluentProvider theme={messageTheme}>
                <Card appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit.  </Caption1>  </StackItem>
                  </Stack>
                </Card>
              </FluentProvider>
            </StackItem>
            <StackItem>
              <Form ref={formRef} formData={formDataPkg}
                schema={jsonSchemaPkg}
                uiSchema={uiSchemaPkg}
                validator={validator}
                widgets={CustomWidgets}
                onChange={(e) => setFormDataPkg(e.formData)} />
            </StackItem>
          </Stack>,

          "App Configuration": <Stack tokens={{ padding: 5 }}>
            <StackItem>
              <FluentProvider theme={messageTheme}>
                <Card appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                  </Stack>
                </Card>
              </FluentProvider>
            </StackItem>
            <StackItem>
              <Form
                ref={formRef}
                formData={formDataApp}
                schema={jsonSchemaApp}
                uiSchema={uiSchemaApp}
                validator={validator}
                widgets={CustomWidgets}
                onChange={(e) => setFormDataApp(e.formData)} />
            </StackItem>
          </Stack>,


          "Advance Configuration":
            <>
              <Stack tokens={{ padding: 5 }}>
                <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                        <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem>
                <Form
                  ref={formRef}
                  formData={formDataAdv}
                  schema={jsonSchemaAdv}
                  uiSchema={uiSchemaAdv}
                  validator={validator}
                  widgets={CustomWidgets}
                  onChange={(e) => setFormDataAdv(e.formData)} />
              </Stack>
            </>,

          "Review Data":
            <>
              <Stack>
                <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                        <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem>
                <Stack tokens={{ padding: 5 }}>
                  {/* <Text as = "h4" >Package Details</Text> */}
                  <Body1Strong>Package Details</Body1Strong>
                  <Form disabled={true} formData={formDataPkg} schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)} />
                </Stack>
                <Stack>
                  {/* <Text>App Configurations</Text> */}
                  <Body1Strong>App Configurations</Body1Strong>
                  <Form disabled={true} formData={formDataApp} schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)} />
                </Stack>
                {/* <Text>Advance Configurations</Text> */}
                <Body1Strong>Advance Configurations</Body1Strong>
                <Stack horizontal tokens={{ childrenGap: 157, padding: 5 }}>
                  <Form disabled={true} formData={formDataAdv} schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)} />
                </Stack>    </Stack>
            </>
        }
      });


      // Object.keys(formDataPkg)?.length > 0 &&
      overlayName == 'editInstallation' && setInstallationOverLayData({
        headerTitle: "Edit Installation Profile",
        size: "large",
        isTabs: true,
        tabItems: {

          "Basic Details": <Stack tokens={{ padding: 5 }}>
            <StackItem>
              <FluentProvider theme={messageTheme}>
                <Card appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit.  </Caption1>  </StackItem>
                  </Stack>
                </Card>
              </FluentProvider>
            </StackItem>
            <StackItem>
              <Form formData={formDataPkg}
                schema={jsonSchemaPkg}
                uiSchema={uiSchemaPkg}
                validator={validator}
                widgets={CustomWidgets}
                onChange={(e) => setFormDataPkg(e.formData)} />
            </StackItem>
          </Stack>,

          "App Configuration": <Stack tokens={{ padding: 5 }}>
            <StackItem>
              <FluentProvider theme={messageTheme}>
                <Card appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                  </Stack>
                </Card>
              </FluentProvider>
            </StackItem>
            <StackItem>
              <Form
                formData={formDataApp}
                schema={jsonSchemaApp}
                uiSchema={uiSchemaApp}
                validator={validator}
                widgets={CustomWidgets}
                onChange={(e) => setFormDataApp(e.formData)} />
            </StackItem>
          </Stack>,


          "Advance Configuration":
            <>
              <Stack tokens={{ padding: 5 }}>
                <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                        <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem>
                <Form
                  formData={formDataAdv}
                  schema={jsonSchemaAdv}
                  uiSchema={uiSchemaAdv}
                  validator={validator}
                  widgets={CustomWidgets}
                  onChange={(e) => setFormDataAdv(e.formData)} />
              </Stack>
            </>,

          "Review Data":
            <>
              <Stack>
                <Stack tokens={{ padding: 5 }}>
                  <Text as="h4" >Package Details</Text>
                  <Form disabled={true} formData={formDataPkg} schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)} />
                </Stack>
                <Stack>
                  <Text>App Configurations</Text>
                  <Form disabled={true} formData={formDataApp} schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)} />
                </Stack>
                <Text>Advance Configurations</Text>
                <Stack horizontal tokens={{ childrenGap: 157, padding: 5 }}>
                  <Form disabled={true} formData={formDataAdv} schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)} />
                </Stack>    </Stack>
            </>
        },
      });
    }
  }, [overlayName, formDataPkg, formDataApp, formDataAdv]);
  
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>
              {/* <Text> <Breadcrumbs pageName="Apps" /> </Text> */}
            </StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Apps | App Installation Profile </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem> <Text></Text> </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>

                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                              <Divider />
                              <MenuList>
                                <Stack tokens={{ childrenGap: 5 }}>
                                  <StackItem> <Text></Text> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                  <StackItem> <Divider /> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  <Input size='small' onChange={inputChange} name="filter" value={dropDownValue2} />
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem> <Button appearance="primary" onClick={() => { handleFilterArray(); setFilterMenuOpen(false) }}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>} />
                          </StackItem>

                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>

                          <StackItem onClick={() => { setFilterArray([]); setIsFilter(false); setDropDownValue2(''); setResetDisabled(true); }}>
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> <FilterDismiss20Filled /> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> <Caption1>  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1> </StackItem>
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} /> </StackItem>
                      </Stack>
                    </StackItem >
                    <Swd_func data={final_data}
                      func={set_final_data}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 50}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2}
                      savePackageDetails={savePackageDetails}
                      setSavePackageDetails={setSavePackageDetails}
                      setDeleteDisabled={setDeleteDisabled}
                      deleteprofile={deleteprofile}
                      setDeleteprofile={setDeleteprofile}
                      pkgId={deleteId}
                      setJsonDataPkg={setJsonDataPkg}
                      setUiDataPkg={setUiDataPkg}
                      setJsonDataApp={setJsonDataApp}
                      setUiDataApp={setUiDataApp}
                      setJsonDataAdv={setJsonDataAdv}
                      setUiDataAdv={setUiDataAdv}
                      formDataPkg={formDataPkg}
                      formDataApp={formDataApp}
                      formDataAdv={formDataAdv}
                      setFormDataPkg={setFormDataPkg}
                      setFormDataApp={setFormDataApp}
                      setFormDataAdv={setFormDataAdv}
                      rowID={rowID}
                      setRowconfID={setRowconfID}
                      rowconfID={rowconfID}
                      overlayName={overlayName}
                      handleEditData={handleEditData}
                      overlayOpen={overlayOpen}
                      setOverlayOpen={setOverlayOpen}
                      setRowId={setRowID}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                      setRefreshToast={setRefreshToast}
                      isDelete={isDelete}
                      setDelete={setDelete}
                      setExportDetails={setExportDetails}
                    />
                    {tableData.length > 0 && (<DataTable tableData={tableData} columns={columns} ></DataTable>)}
                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={installationOverLayData}
            saveBtnFunc={saveBtnFunc}
            isNavBtn={true}
            formRef={formRef}
          />
          {/* {savePackageDetails && <ToastComponent  setMess  toastIntent="success" position="top-end" title="Success" message="Package  Details have been saved Successfully" footer="" /> }   */}
          {isToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={isToast} toastIntent="success" position="top-end" title="Success" message={"Package Saved Successfully"} footer="" />}
          {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
          {deleteprofile && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Deleted Successfully'} footer="" />}

        </StackItem>
      </FullScreen>
    </>
  );
};
