import { gql } from '@apollo/client';

const getAutomationListsQuery = gql`
  query MyQuery($whereCondition: JSON!, $limit: Int = null, $offset: Int = null) {
    core_AutomationJSON(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      createdBy
      createdOn
      dartid
      description
      status
      automationName
      lastModified
      jsonValue
      varValues
      types
      category
      subCategory
    }
  }
`;

const getDartDetailsQuery = gql`
  query MyQuery($dartid: Int!) {
    profile_DartTemplateDetails(where: { dartNo: { _eq: $dartid } }) {
      supportingOS
      details
      templateName
    }
  }
`;

const getJsonSchemaQuery = gql`
  query MyQuery($dartid: Int!) {
    core_DartJsonSchema(where: { dartid: { _eq: $dartid } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getCategory = gql`
  query MyQuery {
    mdm_AdmxAdml {
      Category
    }
  }
`;
const getPolicyName = gql`
  query MyQuery($category: String = "All") {
    mdm_AdmxAdml(where: { Category: { _eq: $category } }) {
      policy_name
    }
  }
`;

const getAdmxAdml = gql`
  query MyQuery($admxl: String = "") {
    mdm_AdmxAdml(where: { policy_name: { _eq: $admxl } }) {
      ADMX
      ADML
      policy_id
      policy_name
    }
  }
`;
const getAdxAdml = gql`
  query MyQuery($admxl: String = "") {
    mdm_AdmxAdml {
      policy_id
      Category
    }
  }
`;
const addAutomationQuery = gql`
  mutation MyMutation(
    $automationName: String = ""
    $description: String = ""
    $jsonValue: String = ""
    $status: String = "not attached"
    $types: String = "automation"
    $createdBy: String = ""
    $createdOn: Int
    $dartid: Int
    $category: String = ""
    $subCategory: String = ""
    $varValues: String = ""
  ) {
    insert_core_AutomationJSON(
      objects: {
        automationName: $automationName
        description: $description
        jsonValue: $jsonValue
        status: $status
        types: $types
        createdBy: $createdBy
        createdOn: $createdOn
        dartid: $dartid
        category: $category
        subCategory: $subCategory
        varValues: $varValues
      }
    ) {
      affected_rows
    }
  }
`;

const updateAutomationQuery = gql`
  mutation MyMutation(
    $automationName: String!
    $description: String!
    $jsonValue: String!
    $lastModified: Int!
    $rowID: Int!
    $dartid: Int
    $category: String = ""
    $subCategory: String = ""
    $varValues: String = ""
  ) {
    update_core_AutomationJSON(
      where: { id: { _eq: $rowID }, dartid: { _eq: $dartid }, status: { _eq: "not attached" } }
      _set: {
        automationName: $automationName
        description: $description
        jsonValue: $jsonValue
        lastModified: $lastModified
        category: $category
        subCategory: $subCategory
        varValues: $varValues
      }
    ) {
      affected_rows
    }
  }
`;

const deleteUnattachedAutomationQuery = gql`
  mutation deleteUnattachedAutomation($ids: [Int!] = [], $dartid: Int!) {
    delete_core_AutomationJSON(where: { id: { _in: $ids }, dartid: { _eq: $dartid }, status: { _eq: "not attached" } }) {
      affected_rows
    }
  }
`;

const deleteUnattachedSingleAutomationQuery = gql`
  mutation deleteUnattachedSingleAutomation($ids: [Int!] = []) {
    delete_core_AutomationJSON(where: { id: { _in: $ids }, status: { _eq: "not attached" } }) {
      affected_rows
    }
  }
`;

export {
  getAutomationListsQuery,
  getDartDetailsQuery,
  getJsonSchemaQuery,
  addAutomationQuery,
  deleteUnattachedAutomationQuery,
  updateAutomationQuery,
  deleteUnattachedSingleAutomationQuery,
  getCategory,
  getPolicyName,
  getAdmxAdml,
  getAdxAdml,
};
