const API_URL = window.location.origin + '/visualization/api';

const AppConfig = {
  API_URL,
  API_ME: `${API_URL}/me`,
  API_STORAGE: `${window.location.origin}/storage`.replace(':3001', ':5001'),
  API_LOGIN: `${API_URL}/login`,
  API_LOGOUT: `${API_URL}/logout`,
  API_CUBE_TOKEN: `${API_URL}/cubejsToken`,
  API_STORAGE_TOKEN: `${API_URL}/storageToken`,
  API_GRAPHQL: `${API_URL}/graphql`,
  API_GRAPHQL_V2: `${API_URL}/graphql/v2`,
  Dashboard_URL: window.location.origin,
  DASHBOARD_V2_API_URL: window.location.origin + '/visualization/api/v2',
  DASHBOARD_V2_WEBSITE_DOMAIN: window.location.origin + '/visualization/v2',
};

export default AppConfig;
