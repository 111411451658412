
import { Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme, SkeletonItem } from '@fluentui/react-components';
import { skeleton7, skeleton8 } from '../../styles/customStyles';

export const SkeletonDataTable = () => {
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15}}>
            <SkeletonItem size={24} style={skeleton7}/>
            <SkeletonItem style={skeleton8}/>
        </Stack>
      </FluentProvider>
    </>
  );
};