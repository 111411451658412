import React from "react";
import { Button, Combobox, Option, useId } from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";

type optionsProps = {
  options: any;
  multi?: boolean;
  tag?: boolean;
  setSelectedOptions?: any;
  selectedOptions?: any
  placeholder?: any
}

export const MultiselectWithTags: React.FC<Partial<ComboboxProps> & optionsProps> = (props) => {
  const comboId = useId("combo-multi");
  const selectedListId = `${comboId}-selection`;
  const selectedListRef = React.useRef<HTMLDivElement>(null);
  const comboboxInputRef = React.useRef<HTMLInputElement>(null);
  const [selectedOptions, setSelectedOptions] = React.useState<any[]>(props.selectedOptions);
  const [filteredOptions, setFilteredOptions] = React.useState<string[]>(props.options);

  React.useEffect(() => {setFilteredOptions(props.options);},[props.options]);

  React.useEffect(() => {
    console.log("selectedOptions: ", selectedOptions);
    console.log("props.selectedOptions: ", props.selectedOptions);
    // props.selectedOptions.length <= selectedOptions.length && props.multi ? selectedOptions.length > 0 && !(props.selectedOptions.includes(selectedOptions.length - 1)) && props.setSelectedOptions([...props.selectedOptions, selectedOptions[selectedOptions.length - 1]]) : selectedOptions.length > 0 && !(props.selectedOptions.includes(selectedOptions.length - 1)) && props.setSelectedOptions([selectedOptions[selectedOptions.length - 1]]);
  },[selectedOptions]);

  const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    setSelectedOptions(data.selectedOptions);
    props.setSelectedOptions(data.selectedOptions);
  };

  const onTagRemoveClick = (option: string, index: number) => {
    console.log("selectedOptions: ", selectedOptions);
    console.log("option: ", option);
    console.log("selectedOptions.filter((o)=>o!==option): ", selectedOptions.filter((val: any) => val !== option));
    
    props.setSelectedOptions(selectedOptions.filter((val: any) => val !== option));
    setSelectedOptions(selectedOptions.filter((val: any) => val !== option));

    const indexToFocus = index === 0 ? 1 : index - 1;
    const optionToFocus = selectedListRef.current?.querySelector(`#${comboId}-remove-${indexToFocus}` );
    optionToFocus ? (optionToFocus as HTMLButtonElement).focus() : comboboxInputRef.current?.focus();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilteredOptions(props.options.filter((option: any) => option.toLowerCase().includes(searchTerm)));
  };

  const labelledBy = selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;
  
  return (
    <div>
      { props.tag && selectedOptions.length 
        ?  <div id={selectedListId} ref={selectedListRef}>
            { selectedOptions?.filter((val) => val !== '').map((option, i) => (
              <span key={option}>
                <Button
                  size="small"
                  shape="circular"
                  appearance="primary"
                  id={`${comboId}-remove-${i}`}
                  aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                >
                  {option}
                  <Dismiss12Regular onClick={() => onTagRemoveClick(option, i)} id={`${comboId}-remove-${i}`} aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`} />
                </Button>
              </span>
            ))}
          </div> : null
      }
      <Combobox
        aria-labelledby={labelledBy}
        multiselect={props.multi}
        placeholder={props?.placeholder ? props.placeholder : 'Please Select'}
        selectedOptions={selectedOptions}
        onOptionSelect={onSelect}
        ref={comboboxInputRef}
        onChange={handleInputChange} // Handle filtering in the onChange event
        {...props} //value={selectedOptions.join(', ')}
      >
        {filteredOptions.map((option: any) => (<Option key={option}>{option}</Option>))}
      </Combobox>
    </div>
  );
};