import { Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme, SkeletonItem } from '@fluentui/react-components';
import { skeleton2, skeleton4, signinFormPosition } from '../../styles/customStyles';

export const SkeletonSignin = () => {
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15}} style={signinFormPosition}>
          <SkeletonItem size={48} style={skeleton4}/>
          <SkeletonItem size={24} style={skeleton2}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={28} style={skeleton2}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={28} style={skeleton2}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={28} style={skeleton4}/>
        </Stack>
      </FluentProvider>
    </>
  );
};