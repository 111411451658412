import { useState, useEffect, useContext } from 'react';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Image,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  MessageBar,
  Card,
  Input,
  ButtonProps,
} from '@fluentui/react-components';
import {
  PulseSquare20Regular,
  AppsAddIn20Regular,
  BoxCheckmark20Regular,
  UninstallApp20Regular,
  CheckboxCheckedSync20Regular,
  SquareHintApps20Regular,
  ShieldLock20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  AddSquare20Regular,
  SlideEraser20Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  Info20Filled,
  Dismiss16Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { Install_Status_func } from '../../../api/app_install_status_func';
import { TroubleshooterDevice_fun } from '../../../api/troubleshooterDevice_fun';
import { DataTableDevice } from '../../../components/data_table/data_table.Device';
import { Census_func } from '../../../api/devices/census_func';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Form, Widgets } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../../components/form-components';
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, exitIcon, overflowDivider } from '../../../styles/customStyles';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';

// import { Resize } from "../../../components/textarea/textarea.component";
// import { Checked } from "../../../components/switch/switch.component";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Appinstallstatus = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [final_data1, set_final_data1]: any = useState(null);
  const [final_data2, set_final_data2]: any = useState(null);
  const [final_data3, set_final_data3]: any = useState(null);
  // const [final_data3, set_final_data3]: any = useState(null);
  const [deviceList, setDeviceList]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchDevVal, setSearchDevVal] = useState('');
  const [groupName, setGroupName] = useState('');
  const [allDevice, setallDevice] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages1, setTotalPages1] = useState(1);
  const [currentPageNo1, setCurrentPageNo1] = useState(1);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportData1, setExportData1] = useState([]);
  const [deviceName, setDeviceName] = useState('');
  const [rowID, setRowID]: any = useState(null);
  const [pacakgeName, setPackageName]: any = useState(null);
  const [rowDesc, setRowDesc]: any = useState(null);
  const [isToast, setIsToast] = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [deployPackageDetails, setDeployPackageDetails]: any = useState(false);
  // const [dropDownValue1,setDropDownValue1] = useState<any[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [machineStatus, setMachineStatus]: any = useState({});

  const [permissions, setPermissions] = useState(['']);
  console.log('checking the permissions------>', permissions);

  const [isSaveEnabled, setButtonEnabled] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== '' && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };

  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const [formDataPkg, setFormDataPkg] = useState({});
  const [formDataApp, setFormDataApp] = useState({});
  const [formDataAdv, setFormDataAdv] = useState({});
  const [jsonSchemaPkg, setJsonDataPkg]: any = useState({});
  const [uiSchemaPkg, setUiDataPkg]: any = useState({});
  const [jsonSchemaApp, setJsonDataApp]: any = useState({});
  const [uiSchemaApp, setUiDataApp]: any = useState({});
  const [jsonSchemaAdv, setJsonDataAdv]: any = useState({});
  const [uiSchemaAdv, setUiDataAdv]: any = useState({});
  //for popup message
  const handleEditData = (data: any) => {
    const pkgDetails = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataPkg);
    const appDetail = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataApp);
    const advDetail = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataAdv);
    console.log(pkgDetails, 'pkgDetails');
    setFormDataPkg(pkgDetails);
    setFormDataApp(appDetail);
    setFormDataAdv(advDetail);
  };

  const [messagebar1, setmessagebar1] = useState(false);
  const [messagebar2, setmessagebar2] = useState(false);
  const handleBothSetMessage = () => {
    setmessagebar1(false);
    setmessagebar2(true);
    setDeployPackageDetails(true);
  };

  var menuItems: any = [];

  console.log('rowid', rowID);

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appmonitor')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <PulseSquare20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> App&nbsp;Monitored</Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 48 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  // menuItems.push
  // (

  //   <MenuItem onClick={() => navigate("/manage/apps/appinstallation")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <AppsAddIn20Regular {...primaryIcon}  /> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Installation </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>

  // );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/manage/apps/appinstallstatus')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <BoxCheckmark20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> App Install Status </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  // menuItems.push
  // (
  //   <MenuItem onClick={()=>navigate("/manage/apps/appuninstallprofile")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <UninstallApp20Regular {...primaryIcon}/> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Uninstall Profiles </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>
  // );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appuninstallstatus')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckboxCheckedSync20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> App Uninstall Status </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/discoveredapps')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SquareHintApps20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Discovered Apps </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  // menuItems.push
  // (
  //   <MenuItem onClick={()=>navigate("/manage/apps/appprotectionpolicies")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <ShieldLock20Regular {...primaryIcon}/> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Protection Policies </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>
  // );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/activitylogg')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong>Description </Body1Strong> },
    { key: 'version', label: <Body1Strong>Version</Body1Strong> },
    { key: 'os', label: <Body1Strong>Operating System</Body1Strong> },
    { key: 'created_on', label: <Body1Strong>Created On</Body1Strong> },
    { key: 'modified_on', label: <Body1Strong>Modified On</Body1Strong> },
    // { key: "status", label: <Body1Strong>Status</Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter starts here
  var columns1 = [
    // { key: "pkgId", label: <Body1Strong> pkgName </Body1Strong> },
    { key: 'names', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1>Description </Caption1> },
    { key: 'version', label: <Caption1>Version</Caption1> },
    { key: 'os', label: <Caption1>Operating System</Caption1> },
    { key: 'created_on', label: <Caption1>Created On</Caption1> },
    { key: 'modified_on', label: <Caption1>Modified On</Caption1> },

    // { key: "status", label: <Caption1>Status</Caption1> },
  ];

  //overlay datatable code start here
  //  var overlayColumns = [
  //     { key: "device_name", label: <Body1Strong> Device Name</Body1Strong> },
  //     // { key: "deployement_status", label: <Body1Strong> Deployment Status </Body1Strong> },
  //     { key: "operating_system", label: <Body1Strong> Operating System </Body1Strong> },
  //  ];
  var overlayColumns = [
    { key: 'host', label: <Body1Strong> Device Name</Body1Strong> },
    { key: 'site', label: <Body1Strong> Device Classification </Body1Strong> },
    { key: 'os', label: <Body1Strong> Operating System </Body1Strong> },
  ];

  var overlayStatusColumns = [
    { key: 'device_name', label: <Body1Strong> Device Name</Body1Strong> },
    { key: 'deployement_status', label: <Body1Strong> Deployment Status </Body1Strong> },
    { key: 'date', label: <Body1Strong> Date </Body1Strong> },
  ];
  var overlayColumns2 = [{ key: 'classificationName', label: <Body1Strong> All Device Name</Body1Strong> }];
  //overlay datatable code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        {/* <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1 onClick={() => {
                              setOverlayOpen(true);
                            }}> Deploy </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}

        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          {/* <CSVLink id="csv-export-link" data={exportData1} filename="installstatus_list.csv">
            <Button appearance="subtle" size="small">
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}
          {permissions.includes('appInstall_Read') ? (
            <TooltipHost content="This feature is not available for you!">
              <Button appearance="subtle" size="small">
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('appInstall_Fullaccess') || permissions.includes('appInstall_ReadandExport') ? (
            <CSVLink id="csv-export-link" data={exportData1} filename="installstatus_list.csv">
              <Button appearance="subtle" size="small">
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </CSVLink>
          ) : null}
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  var tempTableData1: any = [];
  var menuLists1: any = [];
  var tempExportData1: any = [];

  var tempTableData2: any = [];
  var menuLists2: any = [];
  var tempExportData2: any = [];

  //////////////////////this is first page data table ///////////////////////////////
  useEffect(() => {
    console.log('final_data=', final_data);
    //roles and permissions
    async function loadRoute() {
      const role = 'Nano_test';
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    const uniqueIds = new Set(); // Set to store unique app_packageIds
    const updatedTempTableData =
      final_data !== undefined && final_data !== null
        ? final_data.softinst_Packages.reduce((accumulator: any, val: any, i: any) => {
            if (!uniqueIds.has(val.app_packageId)) {
              uniqueIds.add(val.app_packageId); // Add app_packageId to the set
              // accumulator.push({
              //   // id: val.app_packageId,
              //   checkbox: "hide",
              //   name: { label: <Caption1 onClick={(e: any) => { setRowID(val.id); setRowDesc(val.packageDesc); setOverlayOpen(true); setPackageName(val.packageName) }}> {val.packageName !== null ? val.packageName : 'N/A'} </Caption1>, id: `${i}` },
              //   description: { label: <Caption1 > {val.packageDesc != "" ? val.packageDesc : 'N/A'} </Caption1>, status: "" },
              //   version: { label: <Caption1> {val.version} </Caption1>, },
              //   os: { label: <Caption1> {val.platform} </Caption1>, },
              //   created_on: { label: <Caption1> {val.creationTime != null ? val.creationTime : '-'} </Caption1>, },
              //   modified_on: { label: <Caption1>{new Date(val.lastModified * 1000).toLocaleString()}</Caption1>, },
              //   status: { label: <Caption1> {val.status != null ? "Draft" : "Draft"} </Caption1>, },
              // });

              if (permissions.includes('appInstall_Read') || permissions.includes('appInstall_ReadandExport')) {
                accumulator.push({
                  checkbox: 'hide',
                  name: {
                    label: (
                      <Caption1>
                        <TooltipHost content="This feature is not available for you!">
                          {val.packageName !== null ? val.packageName : 'N/A'}
                        </TooltipHost>
                      </Caption1>
                    ),
                    id: `${i}`,
                  },
                  description: { label: <Caption1> {val.packageDesc != '' ? val.packageDesc : 'N/A'} </Caption1>, status: '' },
                  version: { label: <Caption1> {val.version} </Caption1> },
                  os: { label: <Caption1> {val.platform} </Caption1> },
                  created_on: { label: <Caption1> {val.creationTime != null ? val.creationTime : '-'} </Caption1> },
                  modified_on: { label: <Caption1>{new Date(val.lastModified * 1000).toLocaleString()}</Caption1> },
                  status: { label: <Caption1> {val.status != null ? 'Draft' : 'Draft'} </Caption1> },
                });
              } else if (permissions.includes('appInstall_Fullaccess')) {
                accumulator.push({
                  checkbox: 'hide',
                  name: {
                    label: (
                      <Caption1
                        onClick={(e: any) => {
                          setRowID(val.id);
                          setRowDesc(val.packageDesc);
                          setOverlayOpen(true);
                          setPackageName(val.packageName);
                        }}
                      >
                        {val.packageName !== null ? val.packageName : 'N/A'}
                      </Caption1>
                    ),
                    id: `${i}`,
                  },
                  description: { label: <Caption1> {val.packageDesc != '' ? val.packageDesc : 'N/A'} </Caption1>, status: '' },
                  version: { label: <Caption1> {val.version} </Caption1> },
                  os: { label: <Caption1> {val.platform} </Caption1> },
                  created_on: { label: <Caption1> {val.creationTime != null ? val.creationTime : '-'} </Caption1> },
                  modified_on: { label: <Caption1>{new Date(val.lastModified * 1000).toLocaleString()}</Caption1> },
                  status: { label: <Caption1> {val.status != null ? 'Draft' : 'Draft'} </Caption1> },
                });
              }
            }
            return accumulator;
          }, [])
        : [];

    console.log('tempTableData=', updatedTempTableData);

    setTableData(updatedTempTableData);
  }, [final_data, rowID]);

  //////////////////////////////first page datatable ends here/////////////////////

  ////////////////////device classification//////////////////
  useEffect(() => {
    // Overlay device table values append code starts here
    final_data2 !== undefined && final_data2 !== null
      ? final_data2 &&
        final_data2.core_DeviceClassification.map((val: any, i: any) => {
          const deviceData = val && val.classificationName;
          return <Caption1>{deviceData} </Caption1>;
        })
      : console.log('null');
    console.log('2', final_data2);
  }, [final_data2]);

  const [deviceDataWithStatus, setDeviceDataWithStatus]: any = useState({});

  useEffect(() => {
    if (final_data3) {
      const deviceStatusMap: any = {};
      final_data3.communication_Audit.forEach((entry: any) => {
        const { MachineTag, JobStatus, ClientExecutedTime } = entry;
        if (!deviceStatusMap[MachineTag]) {
          deviceStatusMap[MachineTag] = { JobStatus, ClientExecutedTime };
        }
      });
      console.log('qwertyuiopoiuytrewq', deviceStatusMap); // Check if deviceStatusMap contains data
      setDeviceDataWithStatus(deviceStatusMap);
    }
  }, [final_data3]);

  //////////////////////////ends here////////////////////////////////

  /////////////////////device classification device table starts here//////////////
  useEffect(() => {
    // Overlay data table values append code starts here
    console.log('final_data1*************', final_data1);
    console.log('machineStatus***********', machineStatus);

    final_data1 !== undefined && final_data1 !== null
      ? final_data1.core_Census?.map((val: any, i: any) => {
          var deviceStatus: any = machineStatus && machineStatus[`${val.host}`] ? machineStatus[`${val.host}`][0] : 'offline';
          var deviceType: any = machineStatus && machineStatus[`${val.host}`] ? machineStatus[`${val.host}`][1] : 'default';

          var currentIcon = <Badge size="extra-small" appearance="filled" color={deviceStatus === 'online' ? 'success' : 'informative'}></Badge>;

          if (deviceStatus === 'online') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/G_Win.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/G_Android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/G_Apple.svg" alt="Analyze Icon" />;
            }
          } else if (deviceStatus === 'offline') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/Windows.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/apple.svg" alt="Analyze Icon" />;
            }
          }

          tempTableData.push({
            id: val.host,
            host: { label: <Caption1> {val.host} </Caption1>, icon: currentIcon, id: `${i}` },
            site: { label: <Caption1> {val.site} </Caption1>, status: '' },
            os: { label: <Caption1> {val.os} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData1(tempTableData) : setTableData1([]);
  }, [final_data1, machineStatus]);

  ////////////////////////////////ends here/////////////////////////////////

  useEffect(() => {
    // Overlay data table values append code starts here
    console.log('final_data1*************', final_data1);
    console.log('machineStatus***********', machineStatus);

    final_data1 !== undefined && final_data1 !== null
      ? final_data1.core_Census?.map((val: any, i: any) => {
          const deviceData = val && val.host;

          const jobStatus = deviceDataWithStatus[deviceData]?.JobStatus;
          let executeTime = deviceDataWithStatus[deviceData]?.ClientExecutedTime || '-';
          if (executeTime != '-') {
            const convertedTime = new Date(executeTime * 1000);
            executeTime = convertedTime.toLocaleString();
          }
          console.log('job stttttttttttt.....', jobStatus);
          console.log('time stttttttttttt.....', executeTime);

          // <Caption1>{deviceData} - {jobStatus}</Caption1>;
          var deviceStatus: any = machineStatus && machineStatus[`${val.host}`] ? machineStatus[`${val.host}`][0] : 'offline';
          var deviceType: any = machineStatus && machineStatus[`${val.host}`] ? machineStatus[`${val.host}`][1] : 'default';

          var currentIcon = <Badge size="extra-small" appearance="filled" color={deviceStatus === 'online' ? 'success' : 'informative'}></Badge>;

          if (deviceStatus === 'online') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/G_Win.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/G_Android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/G_Apple.svg" alt="Analyze Icon" />;
            }
          } else if (deviceStatus === 'offline') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/Windows.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/apple.svg" alt="Analyze Icon" />;
            }
          }

          tempTableData1.push({
            checkbox: 'hide',
            id: val.host,
            device_name: { label: <Caption1>{deviceData}</Caption1>, id: `${i}` },
            deployement_status: { label: <Caption1>{jobStatus == 2 ? 'success' : jobStatus == 3 ? 'failed' : 'pending'} </Caption1> },
            date: { label: <Caption1>{executeTime} </Caption1>, status: '' },
          });
        })
      : (tempTableData1 = []);

    tempTableData1.length > 0 ? setTableData2(tempTableData1) : setTableData2([]);
  }, [final_data1, machineStatus, deviceDataWithStatus]);

  var overLayData: any = {
    //here this is heading of the overlay
    headerTitle: <Title3>{pacakgeName}</Title3>,
    size: 'large',
    isTabs: true,

    //this is button present in the overlay
    footerData: (
      <>
        <Button
          appearance="primary"
          onClick={() => {
            setmessagebar1(true);
          }}
          disabled={isSaveEnabled ? false : true}
        >
          Deploy App
        </Button>
      </>
    ),

    //data presents in the overlay(overlay content)
    tabItems: {
      Introduction: (
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <Form
              disabled
              formData={formDataPkg}
              schema={jsonSchemaPkg}
              uiSchema={uiSchemaPkg}
              validator={validator}
              widgets={CustomWidgets}
              onChange={e => setFormDataPkg(e.formData)}
            />
          </Stack>
        </FluentProvider>
      ),
      Configuration: (
        <FluentProvider theme={webLightTheme}>
          <Stack>
            <Text>App Configurations</Text>
            <Form
              disabled={true}
              formData={formDataApp}
              schema={jsonSchemaApp}
              uiSchema={uiSchemaApp}
              validator={validator}
              widgets={CustomWidgets}
              onChange={e => setFormDataApp(e.formData)}
            />
          </Stack>
          <Text>Advance Configurations</Text>
          <Stack horizontal tokens={{ childrenGap: 157, padding: 5 }}>
            <Form
              disabled={true}
              formData={formDataAdv}
              schema={jsonSchemaAdv}
              uiSchema={uiSchemaAdv}
              validator={validator}
              widgets={CustomWidgets}
              onChange={e => setFormDataAdv(e.formData)}
            />
          </Stack>
        </FluentProvider>
      ),
      Deploy: (
        <>
          <Stack horizontalAlign="start">
            <StackItem grow shrink>
              <Card appearance="subtle">
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                  <StackItem>
                    {/* LEFT SIDE DATA HERE */}
                    <Body1Strong> Data&nbsp;Classification </Body1Strong>
                    <Divider></Divider>
                    <Stack tokens={{ padding: 5 }} horizontal>
                      <StackItem> </StackItem>
                    </Stack>
                    <Stack tokens={{ padding: 5, childrenGap: 10 }} horizontalAlign="start" verticalAlign="start">
                      <StackItem
                        onClick={() => {
                          setallDevice(true);
                        }}
                      >
                        <Button size="medium" appearance="transparent">
                          All Devices
                        </Button>
                      </StackItem>

                      {final_data2 !== undefined && final_data2 !== null
                        ? final_data2 &&
                          final_data2.core_DeviceClassification.map((val: any, i: any) => {
                            const deviceData = val.classificationName;
                            return (
                              <StackItem
                                onClick={() => {
                                  setGroupName(deviceData);
                                  setallDevice(false);
                                }}
                              >
                                <Button appearance="transparent" size="small">
                                  {deviceData}
                                </Button>
                              </StackItem>
                            );
                          })
                        : console.log('null')}

                      {/* {tableData2.length > 0 && groupName != '' && (<DataTable tableData={tableData2} columns={overlayColumns2} />)} */}
                    </Stack>
                  </StackItem>

                  <Divider inset vertical />

                  <StackItem>
                    {/* RIGHT SIDE DATA HERE */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                    <Stack tokens={{ padding: 5 }}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      {/* <SearchBox onChange={(e: any) => { searchDevHandle(e) }} placeholder="Search" size="small" /> */}
                      <SearchBox
                        onChange={(e: any) => {
                          setSearchDevVal(e.target.value);
                        }}
                        placeholder="Search"
                        size="small"
                      />
                      <StackItem>
                        <MenuDropdown
                          open={filterMenuOpen}
                          btn={
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <Filter20Filled {...primaryIcon} />
                                <Body1> Filter </Body1>
                              </Stack>
                            </MenuButton>
                          }
                          itemVal={
                            <>
                              <Body1Strong> Add a Filter </Body1Strong>
                              <Divider />
                              <MenuList>
                                <Stack tokens={{ childrenGap: 5 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Caption1> Filter </Caption1>
                                  </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                  <StackItem>
                                    <Divider />
                                  </StackItem>
                                  <StackItem>
                                    <Caption1> Value </Caption1>
                                  </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                  <StackItem>
                                    <Divider />
                                  </StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem>
                                      <Button
                                        appearance="primary"
                                        onClick={() => {
                                          handleFilterArray();
                                          setFilterMenuOpen(false);
                                        }}
                                      >
                                        <Caption1> Apply </Caption1>
                                      </Button>
                                    </StackItem>
                                    <StackItem>
                                      <Button onClick={() => setFilterMenuOpen(false)}>
                                        <Caption1> Cancel </Caption1>
                                      </Button>
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>
                          }
                        />
                      </StackItem>
                      <StackItem>
                        {filterArray.length > 0 &&
                          filterArray.map((item, index) => (
                            <Button size="small" shape="circular">
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> {item} </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 1 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Dismiss16Regular
                                        onClick={() => {
                                          handleCloseFilter(index);
                                        }}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </Button>
                          ))}
                      </StackItem>
                      <StackItem
                        onClick={() => {
                          setFilterArray([]);
                          setIsFilter(false);
                          setDropDownValue2('');
                          setResetDisabled(true);
                        }}
                      >
                        <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                          <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <StackItem>
                              <FilterDismiss20Filled />
                            </StackItem>
                            <StackItem>
                              <Caption1> Reset Filters </Caption1>
                            </StackItem>
                          </Stack>
                        </Button>
                      </StackItem>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <FluentProvider theme={webLightTheme}>
                        {tableData1.length > 0 ? (
                          <DataTable tableData={tableData1} columns={overlayColumns} />
                        ) : (
                          <Stack horizontalAlign="center" tokens={{ childrenGap: 15, padding: 10 }}>
                            <StackItem>
                              <Text size={500}>No data available</Text>
                            </StackItem>
                          </Stack>
                        )}
                      </FluentProvider>
                    </Stack>
                  </StackItem>
                </Stack>
              </Card>
            </StackItem>
          </Stack>
        </>
      ),
      Status: (
        <>
          <Stack horizontalAlign="start">
            <StackItem grow shrink>
              <Card appearance="subtle">
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                  <StackItem>
                    {/* LEFT SIDE DATA HERE */}
                    <Body1Strong> Data&nbsp;Classification </Body1Strong>
                    <Divider></Divider>
                    <Stack tokens={{ padding: 5 }} horizontal>
                      <StackItem> </StackItem>
                    </Stack>
                    <Stack tokens={{ padding: 5, childrenGap: 10 }} horizontalAlign="start" verticalAlign="start">
                      <StackItem
                        onClick={() => {
                          setallDevice(true);
                        }}
                      >
                        <Button size="medium" appearance="transparent">
                          All Devices
                        </Button>
                      </StackItem>

                      {final_data2 !== undefined && final_data2 !== null
                        ? final_data2 &&
                          final_data2.core_DeviceClassification.map((val: any, i: any) => {
                            const deviceData = val.classificationName;
                            return (
                              <StackItem
                                onClick={() => {
                                  setGroupName(deviceData);
                                  setallDevice(false);
                                }}
                              >
                                <Button appearance="transparent" size="small">
                                  {deviceData}
                                </Button>
                              </StackItem>
                            );
                          })
                        : console.log('null')}

                      {/* {tableData2.length > 0 && groupName != '' && (<DataTable tableData={tableData2} columns={overlayStatusColumns} />)} */}
                    </Stack>
                  </StackItem>

                  <Divider inset vertical />

                  <StackItem>
                    {/* RIGHT SIDE DATA HERE */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                    <Stack tokens={{ padding: 5 }}></Stack>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      {/* <SearchBox onChange={(e: any) => { searchDevHandle(e) }} placeholder="Search" size="small" /> */}
                      <SearchBox
                        onChange={(e: any) => {
                          setSearchDevVal(e.target.value);
                        }}
                        placeholder="Search"
                        size="small"
                      />
                      <StackItem>
                        <MenuDropdown
                          open={filterMenuOpen}
                          btn={
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <Filter20Filled {...primaryIcon} />
                                <Body1> Filter </Body1>
                              </Stack>
                            </MenuButton>
                          }
                          itemVal={
                            <>
                              <Body1Strong> Add a Filter </Body1Strong>
                              <Divider />
                              <MenuList>
                                <Stack tokens={{ childrenGap: 5 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Caption1> Filter </Caption1>
                                  </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                  <StackItem>
                                    <Divider />
                                  </StackItem>
                                  <StackItem>
                                    <Caption1> Value </Caption1>
                                  </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                  <StackItem>
                                    <Divider />
                                  </StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem>
                                      <Button
                                        appearance="primary"
                                        onClick={() => {
                                          handleFilterArray();
                                          setFilterMenuOpen(false);
                                        }}
                                      >
                                        <Caption1> Apply </Caption1>
                                      </Button>
                                    </StackItem>
                                    <StackItem>
                                      <Button onClick={() => setFilterMenuOpen(false)}>
                                        <Caption1> Cancel </Caption1>
                                      </Button>
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>
                          }
                        />
                      </StackItem>
                      <StackItem>
                        {filterArray.length > 0 &&
                          filterArray.map((item, index) => (
                            <Button size="small" shape="circular">
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> {item} </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 1 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Dismiss16Regular
                                        onClick={() => {
                                          handleCloseFilter(index);
                                        }}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </Button>
                          ))}
                      </StackItem>
                      <StackItem
                        onClick={() => {
                          setFilterArray([]);
                          setIsFilter(false);
                          setDropDownValue2('');
                          setResetDisabled(true);
                        }}
                      >
                        <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                          <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <StackItem>
                              <FilterDismiss20Filled />
                            </StackItem>
                            <StackItem>
                              <Caption1> Reset Filters </Caption1>
                            </StackItem>
                          </Stack>
                        </Button>
                      </StackItem>
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                      <FluentProvider theme={webLightTheme}>
                        {tableData2.length > 0 ? (
                          <DataTable tableData={tableData2} columns={overlayStatusColumns} />
                        ) : (
                          <Stack horizontalAlign="center" tokens={{ childrenGap: 15, padding: 10 }}>
                            <StackItem>
                              <Text size={500}>No data available</Text>
                            </StackItem>
                          </Stack>
                        )}
                      </FluentProvider>
                    </Stack>
                  </StackItem>
                </Stack>
              </Card>
            </StackItem>
          </Stack>
        </>
      ),
    },
  };

  useEffect(() => {
    console.log('hiaaaaaaaaaaaaaaaaa');
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  }, []);

  useEffect(() => {
    console.log('main Table=', contextValue.selectedRowIdInTable);
    console.log('sub Table=', contextValue.selectedRowIdInSubTable);
    contextValue.selectedRowIdInTable.length > 0 ? setButtonEnabled(true) : setButtonEnabled(false);
  }, [contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Apps" /> </Text> */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Apps | App Install Status </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              setSearchVal(e.target.value);
                            }}
                            value={searchVal}
                            placeholder="Search"
                            size="small"
                            dismiss={null}
                            contentAfter={<DismissButton aria-label="Clear" />}
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                          </StackItem>
                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem>
                          <Caption1>
                            Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                            {totalRecords} Records
                          </Caption1>
                        </StackItem>
                        <StackItem>
                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack>
                    </StackItem>

                    <Install_Status_func
                      data={final_data}
                      func={set_final_data}
                      func1={set_final_data3}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 50}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                      setJsonDataPkg={setJsonDataPkg}
                      setUiDataPkg={setUiDataPkg}
                      deployPackageDetails={deployPackageDetails}
                      setDeployPackageDetails={setDeployPackageDetails}
                      setJsonDataApp={setJsonDataApp}
                      setRowId={setRowID}
                      rowId={rowID}
                      rowDesc={rowDesc}
                      setUiDataApp={setUiDataApp}
                      setJsonDataAdv={setJsonDataAdv}
                      setUiDataAdv={setUiDataAdv}
                      setRefreshToast={setRefreshToast}
                      handleEditData={handleEditData}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                    />
                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns}></DataTable>}
                    {refreshToast && (
                      <ToastComponent
                        setmessagebar={setIsToast}
                        isToastEnabled={refreshToast}
                        toastIntent="success"
                        position="top-end"
                        title="Success"
                        message={'Data Table Refreshed'}
                        footer=""
                      />
                    )}
                    <>
                      <TroubleshooterDevice_fun
                        groupData={final_data2}
                        groupFunc={set_final_data2}
                        data={final_data1}
                        func={set_final_data1}
                        searchDevVal={searchDevVal}
                        pageNo={0}
                        setTotalPages={setTotalPages1}
                        setTotalRecords={setTotalRecords1}
                        groupName={groupName}
                        setGroupName={setGroupName}
                        allDevice={allDevice}
                        overlayOpen={overlayOpen}
                        setallDevice={setallDevice}
                        setMachineStatus={setMachineStatus}
                      />
                      {/* <TroubleshooterDevice_fun
      groupData={deviceList}
      groupFunc={setDeviceList}
      data={final_data1}
      func={set_final_data1}
      searchVal={searchVal}
      pageNo={(currentPageNo - 1) * 50}
      setTotalPages={setTotalPages}
      setTotalRecords={setTotalRecords}
      // groupName={deviceName}
      // setGroupName={setDeviceName}
      groupName={groupName} 
      setGroupName={setGroupName} 
      allDevice={allDevice} overlayOpen={overlayOpen}
      setallDevice={setallDevice} 
      setMachineStatus={setMachineStatus}/> */}
                      {tableData1.length > 0 && deviceName !== '' && <DataTable tableData={tableData1} columns={overlayStatusColumns} />}
                    </>
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={overLayData}
            // isNavBtn={true}
          />
          <StackItem>
            {messagebar1 && (
              <ToastComponent
                setmessagebar={setmessagebar1}
                toastIntent="warning"
                position="top"
                title={`Are you sure you want to proceed with publishing this app on ${contextValue.selectedRowIdInTable.length} devices?`}
                footer={
                  <>
                    <Button
                      appearance="primary"
                      onClick={handleBothSetMessage}
                      onClickCapture={() => {
                        setOverlayOpen(false);
                        setButtonEnabled(false);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        setmessagebar1(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                }
              />
            )}
          </StackItem>
        </StackItem>
      </FullScreen>
    </>
  );
};
