import { useState, useEffect, useContext, useRef } from 'react';
import { Automaiton_schedule_func } from '../../../api/automation/automationSchedule_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { DatePicker, Stack, StackItem, TimePicker, getBackgroundShade } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Option,
  Image,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Dropdown,
  Input,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Link,
  MessageBarActions,
  Checkbox,
  ToggleButton,
  Textarea,
  Card,
  useId,
} from '@fluentui/react-components';
import {
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  CalendarClock20Regular,
  Wand20Regular,
  SettingsCogMultiple20Regular,
  Filter20Filled,
  Delete20Regular,
  Desktop20Filled,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  AddSquare20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  AddSquare20Regular,
  FilterDismiss20Filled,
  Open20Regular,
  Info24Filled,
  Dismiss16Regular,
  CheckmarkLock20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { Inputs } from '../../../components/textarea/input.component';
import { RadioGroups } from '../../../components/radio_group/radioGroup.component';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { ToastComponent } from '../../../components/toast/toast.component';
import DialogBox from '../../../components/dialog/dialog.component';
import { MultiselectWithTags } from '../../../components/dropdown/multiselect.component';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import type { DropdownProps } from '@fluentui/react-components';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { fullscreenIcon, primaryIcon, secondaryIcon, messageTheme, exitIcon, deleteIcon, lightTheme } from '../../../styles/customStyles';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const AutomationSchedule = (props: Partial<DropdownProps>) => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const dropdownId = useId('dropdown-default');
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [automationSearchVal, setAutomationSearchVal] = useState('');
  const [automationSearchData, setAutomationSearchData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [buttonStates, setButtonStates] = useState({});
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [isRefresh, setRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [saveBtnClicked1, setSaveBtnClicked1]: any = useState(false);
  const [deleteAutomation, setDeleteAutomation]: any = useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [cloneDailyForms, setcloneDailyForms] = useState<FormDailyItem[]>([]);
  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: {
      type: '',
    },
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [automationStatus, setAutomationStatus] = useState('not attached');

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const [formEditedData, setFormEditedData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [configFormData, setConfigFormData]: any = useState({
    executionTime: [],
  });
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [automationOverLayData, setAutomationOverLayData]: any = useState({});
  const [weekendChecked, setWeekendChecked] = useState(false);
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dates = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];

  const handleOptionChange = (value: any) => {
    var optionValue = value.target.value;
    setConfigFormData({
      executionTime: [],
    });
    setSelectedOption(optionValue);
  };

  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  };
  const [showAdditionalContent, setShowAdditionalContent] = useState(true);
  const handleSearchBoxClick = (e: any) => {
    //setShowAdditionalContent(!showAdditionalContent);
    setAutomationSearchVal(e.target.value);
  };

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name || (overlayName == 'editAutomation' && val.automationName === formEditedData.name)) {
        flag = false;
      }
    });

    if (overlayName == 'editAutomation' && formEditedData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, formEditedData, selectedAutomation]);

  useEffect(() => {
    if (saveBtnClicked) {
      var tempJsonData: any = {};
      var tempTimeArr: any = configFormData?.executionTime?.map((val: any) => val.time);
      var tempDateArr: any = configFormData?.date?.map((val: any) => val.date);

      console.log('configFormData?.executionTime=', configFormData?.executionTime);
      console.log('month arr=', configFormData?.months);

      tempJsonData =
        selectedOption == 'daily'
          ? {
              type: selectedOption,
              scheduledAutomations: selectedOptions,
              executionTime: tempTimeArr,
              weekend: configFormData.weekend,
              recommendedAction: configFormData.recommendedAction,
            }
          : selectedOption == 'weekly'
          ? {
              type: selectedOption,
              scheduledAutomations: selectedOptions,
              executionTime: tempTimeArr,
              days: configFormData.days,
              recommendedAction: configFormData.recommendedAction,
            }
          : {
              type: selectedOption,
              scheduledAutomations: selectedOptions,
              months: configFormData?.months,
              date: tempDateArr,
              executionTime: tempTimeArr,
              weekend: configFormData.weekend,
              recommendedAction: configFormData.recommendedAction,
            };

      setFormData({ ...formData, jsonData: tempJsonData });
      setSaveBtnClicked1(true);
    } else {
      setSaveBtnClicked1(false);
      setFormData({ name: '', description: '', jsonData: { type: '' } });
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    console.log('edited formData(yashas)', overlayName);

    if (formData?.name != '' && overlayName == 'editAutomation') {
      var type = formData.jsonData.type;
      setSelectedOption(type);
      setSelectedOptions(formData.jsonData.scheduledAutomations);

      var timeArr: any = [];
      if (type == 'daily') {
        formData.jsonData?.executionTime?.map((val: any, i: any) => {
          i == 0 ? timeArr.push({ id: 0, time: val }) : timeArr.push({ id: Date.now(), time: val });
        });
        setConfigFormData({
          executionTime: timeArr,
          recommendedAction: formData.jsonData.recommendedAction,
          weekend: formData.jsonData.weekend,
        });

        formData?.jsonData?.executionTime?.map((val: any, i: any) => {
          i != 0 && setcloneDailyForms([...cloneDailyForms, { id: Date.now(), data: {} }]);
        });

        setClickedDays(
          daysOfWeek.reduce((acc, day) => {
            acc = false;
            return acc;
          }, {}),
        );

        setClickedMonths(
          months.reduce((acc, month) => {
            acc = false;
            return acc;
          }, {}),
        );
      } else if (type == 'weekly') {
        formData.jsonData?.executionTime?.map((val: any, i: any) => {
          i == 0 ? timeArr.push({ id: 0, time: val }) : timeArr.push({ id: Date.now(), time: val });
        });

        setConfigFormData({
          executionTime: timeArr,
          recommendedAction: formData.jsonData.recommendedAction,
          days: formData.jsonData.days,
        });

        formData?.jsonData?.days?.map((val: any, i: any) => {
          setClickedDays(prevClickedDays => ({
            ...prevClickedDays,
            [`isWeeklyButton${val}Clicked`]: !prevClickedDays[`isWeeklyButton${val}Clicked`],
          }));
        });

        formData?.jsonData?.executionTime?.map((val: any, i: any) => {
          i != 0 && setCloneWeeklyForms([...cloneDailyForms, { id: Date.now(), data: {} }]);
        });

        setcloneDailyForms([]);

        setClickedMonths(
          months.reduce((acc, month) => {
            acc = false;
            return acc;
          }, {}),
        );
      } else {
        //monthly scheduler
        formData?.jsonData?.executionTime?.map((val: any, i: any) => {
          i == 0 ? timeArr.push({ id: 0, time: val }) : timeArr.push({ id: Date.now(), time: val });
        });

        var dateArr: any = [];
        formData?.jsonData?.date?.map((val: any, i: any) => {
          i == 0 ? dateArr.push({ id: 0, date: val }) : dateArr.push({ id: Date.now(), date: val });
        });

        formData?.jsonData?.months?.map((val: any) => {
          setClickedMonths(prevClickedMonths => ({
            ...prevClickedMonths,
            [`isMonthButton${val}Clicked`]: !prevClickedMonths[`isMonthButton${val}Clicked`],
          }));
        });

        formData?.jsonData?.date?.map((val: any, i: any) => {
          i !== 0 && setcloneMonthlyFormsDate([...cloneMonthlyFormsDate, { id: Date.now(), data: {} }]);
        });

        setcloneDailyForms([]);

        setClickedDays(
          daysOfWeek.reduce((acc, day) => {
            acc = false;
            return acc;
          }, {}),
        );

        setConfigFormData({
          executionTime: timeArr,
          recommendedAction: formData.jsonData.recommendedAction,
          date: dateArr,
          months: formData.jsonData.months,
        });
      }
    }
  }, [formData]);

  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [dropDownValue3, setDropDownValue3] = useState<any>();
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };

  // weekly buttons starts here
  const [clickedDays, setClickedDays] = useState<{ [key: string]: boolean }>(
    daysOfWeek.reduce((acc, day) => {
      acc = false;
      return acc;
    }, {}),
  );

  var daysArr: any = [];
  const handleWeeklyButtonClick = (day: string) => {
    setClickedDays(prevClickedDays => ({
      ...prevClickedDays,
      [`isWeeklyButton${day}Clicked`]: !prevClickedDays[`isWeeklyButton${day}Clicked`],
    }));

    daysArr = configFormData?.days?.length > 0 ? configFormData?.days : [];

    if (daysArr.includes(day)) {
      daysArr = daysArr.filter((val: any) => val != day);
    } else {
      daysArr.push(day);
    }

    setConfigFormData({ ...configFormData, days: daysArr });
  };
  // weekly buttons starts here

  const [clickedMonths, setClickedMonths] = useState<{ [key: string]: boolean }>(
    months.reduce((acc, month) => {
      acc = false;
      return acc;
    }, {}),
  );

  var monthArr: any = [];
  const handleMonthButtonClick = (month: string) => {
    setClickedMonths(prevClickedMonths => ({
      ...prevClickedMonths,
      [`isMonthButton${month}Clicked`]: !prevClickedMonths[`isMonthButton${month}Clicked`],
    }));

    monthArr = configFormData?.months?.length > 0 ? configFormData?.months : [];

    if (monthArr.includes(month)) {
      monthArr = monthArr.filter((val: any) => val != month);
    } else {
      monthArr.push(month);
    }

    setConfigFormData({ ...configFormData, months: monthArr });
  };

  // Clone daily logic start here
  interface FormDailyItem {
    id: number;
    data: {};
  }
  const handleDailyAddClone = () => {
    if (cloneDailyForms.length < 100) {
      setcloneDailyForms([...cloneDailyForms, { id: Date.now(), data: {} }]);
    }
  };

  const handleRemoveDailyClone = (id: any) => {
    setcloneDailyForms(cloneDailyForms?.filter(formDaily => formDaily.id !== id));

    var timeArr = configFormData.executionTime?.filter((val: any) => val.id !== id);
    setConfigFormData({ ...configFormData, executionTime: timeArr });
  };
  // clone daily logic ends here

  // clone weekly logic start here
  interface FormWeeklyItem {
    id: number;
    data: {};
  }
  const [cloneWeeklyForms, setCloneWeeklyForms] = useState<FormWeeklyItem[]>([]);
  const handleWeeklyAddClone = () => {
    if (cloneWeeklyForms.length < 100) {
      setCloneWeeklyForms([...cloneWeeklyForms, { id: Date.now(), data: {} }]);
    }
  };
  const handleRemoveWeeklyClone = (id: any) => {
    setCloneWeeklyForms(cloneWeeklyForms.filter(formWeekly => formWeekly.id !== id));
  };
  // clone weekly logic ends here

  // clone monthly logic start here for time
  interface FormMonthlyTimeItem {
    id: number;
    data: {};
  }
  const [cloneMonthlyFormsTime, setcloneMonthlyFormsTime] = useState<FormMonthlyTimeItem[]>([]);
  const handleMonthlyAddClone = () => {
    if (cloneMonthlyFormsTime.length < 100) {
      setcloneMonthlyFormsTime([...cloneMonthlyFormsTime, { id: Date.now(), data: {} }]);
    }
  };
  const handleRemoveMonthlyCloneTime = (id: any) => {
    setcloneMonthlyFormsTime(cloneMonthlyFormsTime.filter(formMonthlyTime => formMonthlyTime.id !== id));
  };
  // clone monthly logic ends here for time

  // clone monthly logic start here for date
  interface FormMonthlyDateItem {
    id: number;
    data: {};
  }
  const [cloneMonthlyFormsDate, setcloneMonthlyFormsDate] = useState<FormMonthlyDateItem[]>([]);
  const handleMonthlyAddCloneDate = () => {
    if (cloneMonthlyFormsDate.length < 100) {
      setcloneMonthlyFormsDate([...cloneMonthlyFormsDate, { id: Date.now(), data: {} }]);
    }
  };
  const handleRemoveMonthlyCloneDate = (id: any) => {
    setcloneMonthlyFormsDate(cloneMonthlyFormsDate.filter(formMonthlyDate => formMonthlyDate.id !== id));
  };
  // clone monthly logic ends here for date

  // const handleNewFormChange = (e: any) => {
  //   setFormData({...formData,
  //     jsonData: e.formData
  //   });
  // }

  // const handleEditFormChange = (e: any) => {
  //   setFormEditedData({...formEditedData,
  //     jsonData: e.formData
  //   });
  // }

  const handleDatepicker = (e: any, ids: any) => {
    const formattedDate = e;
    // const formattedDate = parseInt(e.toISOString().split('T')[0].split("-")[2]) + 1;
    console.log('formattedDate=', formattedDate);

    var DateArr: any = configFormData?.date?.length ? configFormData?.date : [];
    var flag = 1;

    DateArr.map((val: any) => {
      if (flag == 1 && val.id == ids) {
        flag = 0;
        val.date = formattedDate.toString();
      }
    });

    flag == 1 && DateArr.push({ id: ids, date: formattedDate.toString() });
    setConfigFormData({ ...configFormData, date: DateArr });
  };

  const handleTimepicker = (e: any, ids: any) => {
    var timeArr: any = configFormData.executionTime.length ? configFormData.executionTime : [];
    console.log('time arr=', timeArr);

    var flag = 1;
    timeArr.map((val: any) => {
      if (flag == 1 && val.id == ids) {
        flag = 0;
        val.time = e;
      }
    });

    flag == 1 && timeArr.push({ id: ids, time: e });
    setConfigFormData({ ...configFormData, executionTime: timeArr });
  };

  const handleWeekendCheckbox = (e: any) => {
    setWeekendChecked(e.target.checked);
    setConfigFormData({ ...configFormData, weekend: e.target.checked });
  };

  const handleRadioGrps = (e: any) => {
    setConfigFormData({ ...configFormData, recommendedAction: e.target.value });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  useEffect(() => {
    console.log('configFormData=', configFormData);
  }, [configFormData]);

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1 onClick={() => navigate('/automate/automation/all_automations')}> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations/configuration')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/selfhelp');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1>Self-Help </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '69');
        contextValue.setDartNo(69);
        navigate('/automate/automation/autoheal');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Wand20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Autoheal </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => {
          localStorage.setItem('dartNo', '304');
          contextValue.setDartNo(304);
          navigate('/automate/automation/automationschedule');
        }}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <CalendarClock20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Automation Schedules </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '241');
        contextValue.setDartNo(241);
        navigate('/automate/automation/usersurvey');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ChatMultipleHeart20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> User Surveys </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key={3}
      onClick={() => {
        navigate('/automate/automation/compliance_policies/policy_configuration');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckmarkLock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];

  //filter column starts here
  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  const radioData = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
  };
  const radioData1 = {
    'Execute earliest available opportunity': '1',
    'Execute next available opportunity': '2',
  };

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        {/* <Stack tokens={{childrenGap:10}} horizontal> */}
        <StackItem>
          <Button appearance="subtle" size="small">
            {/* <Stack tokens={{childrenGap:6}}> */}
            {/* <StackItem><Text></Text></StackItem> */}
            <StackItem
              onClick={() => {
                setSelectedOptions([]);
                setOverlayOpen(true);
                setOverlayName('addAutomation');
                setAutomationStatus('not attached');
                setFormData({ ...formData, name: '', description: '', jsonData: {} });
                setConfigFormData({ executionTime: [] });
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <AddSquare20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Add Automation </Caption1>
                </StackItem>
              </Stack>
            </StackItem>
            {/* </Stack> */}
          </Button>
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {/* <Delete20Regular {...primaryIcon} />  */}
                      {isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      <Caption1> Delete </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Kindly verify your intent to delete the automation."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setDeleteAutomation(true);
                        userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} scheduled automations deleted"}`);
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <Stack tokens={{childrenGap:6}}> */}
        {/* <StackItem><Text></Text></StackItem> */}
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setRefresh(true);
              setCurrentPageNo(1);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>

          <CSVLink id="csv-export-link" data={exportData} filename="scheduled_automations.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"scheduled automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>

          {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
        </StackItem>
        {/* </Stack>
        </Stack> */}
      </>
    ),
  };

  useEffect(() => {
    console.log('outside=', clickedDays);
    console.log('selectedOptions=', selectedOptions);
    console.log('automationSearchData=', automationSearchData);
    console.log('dartSpecs=', dartSpecs?.profile_DartTemplateDetails);
    console.log('overlayName=', overlayName);

    if (dartSpecs?.profile_DartTemplateDetails?.length > 0 || automationSearchData?.length > 0 || selectedOptions?.length > 0) {
      console.log('inside if');

      var details = dartSpecs.profile_DartTemplateDetails?.length > 0 ? dartSpecs.profile_DartTemplateDetails[0]?.details : '{}';
      details = JSON.parse(details);

      overlayName == 'addAutomation' &&
        setAutomationOverLayData({
          headerTitle: 'Add a new automation schedule',
          size: 'large',
          isTabs: true,
          tabItems: {
            Basic: (
              <form id="myFormAutomationschedule" onSubmit={e => handleSubmit(e)}>
                <>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    <StackItem>
                      <FluentProvider theme={messageTheme}>
                        <Card appearance="subtle" size="small">
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Info24Filled {...primaryIcon} />
                            </StackItem>
                            <StackItem>
                              <Caption1> These details will make it easy to find the snippets later when you need to use them </Caption1>
                            </StackItem>
                          </Stack>
                        </Card>
                      </FluentProvider>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong>
                            Name of the automation schedule <Image src="/visualization/v2/image/required.svg" />
                          </Body1Strong>
                        </StackItem>
                        <StackItem>
                          <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                          {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong> Description </Body1Strong>
                        </StackItem>
                        <StackItem>
                          <Resize inputChange={setFormData} inputValue={formData} />
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong>
                            Select the Automation that you want to schedule
                            <Image src="/visualization/v2/image/required.svg" />
                          </Body1Strong>
                        </StackItem>
                        <StackItem>{selectedOptions.length > 0 && <Badge>{selectedOptions[0]}</Badge>}</StackItem>
                        <StackItem>
                          <MultiselectWithTags
                            onChange={(e: any) => setAutomationSearchVal(e.target.value)}
                            options={automationSearchData}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}
                            multi={false}
                            tag={false}
                            placeholder="Please Search"
                            required
                          />
                        </StackItem>
                        <StackItem></StackItem>
                      </Stack>
                    </StackItem>
                    {showAdditionalContent && (
                      <>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem>
                                  <Info24Filled {...primaryIcon} />
                                </StackItem>
                                <StackItem>
                                  <Caption1> These are the basic details associated with this Automation that you selected </Caption1>
                                </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem>

                        {Object.keys(details).map(key => (
                          <>
                            <StackItem>
                              <p>
                                <b>{key}</b>
                              </p>
                            </StackItem>
                            <StackItem>
                              <p>{details[key]}</p>
                            </StackItem>
                          </>
                        ))}
                      </>
                    )}
                  </Stack>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                </>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configuration: (
              <form id="myFormAutomationschedule" onSubmit={e => handleSubmit(e)}>
                <>
                  <Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>

                    <StackItem>
                      <RadioGroups
                        data={radioData}
                        layout="horizontal"
                        value={selectedOption}
                        onChange={e => handleOptionChange(e)}
                        required={true}
                      />
                    </StackItem>

                    <StackItem>
                      <Stack tokens={{ padding: 10 }}>
                        <StackItem>
                          <>
                            {selectedOption == 'daily' && (
                              <Stack tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 20 }} horizontal>
                                    <StackItem>
                                      {configFormData.executionTime[0]?.time?.length > 0 ? (
                                        <TimePicker
                                          placeholder="Enter Time Here"
                                          onChange={(e: any) => {
                                            handleTimepicker(e.target.innerText, 0);
                                          }}
                                          onKeyUp={(e: any) => {
                                            handleTimepicker(e.target.value, 0);
                                          }}
                                          defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                        />
                                      ) : (
                                        <TimePicker
                                          placeholder="Enter Time Here"
                                          onChange={(e: any) => {
                                            handleTimepicker(e.target.innerText, 0);
                                          }}
                                          onKeyUp={(e: any) => {
                                            handleTimepicker(e.target.value, 0);
                                          }}
                                          required
                                        />
                                      )}
                                    </StackItem>
                                    <StackItem>
                                      <Image src="/visualization/v2/image/required.svg" />
                                    </StackItem>
                                    <StackItem>
                                      <Checkbox checked={configFormData.weekend} onChange={handleWeekendCheckbox} label="Include Weekends" />
                                    </StackItem>
                                    {/* <StackItem> 
                                <Stack tokens={{childrenGap:5}}>
                                  <StackItem><Text></Text></StackItem>
                                  <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleDailyAddClone}/> </StackItem>
                                </Stack>
                              </StackItem> */}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  {cloneDailyForms.map((formDaily: any, i: any) => (
                                    <Stack key={formDaily.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem>
                                          {selectedOption === 'daily' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 25 }} horizontal>
                                                <StackItem>
                                                  {configFormData.executionTime[i + 1]?.time?.length > 0 ? (
                                                    <TimePicker
                                                      placeholder="Enter Time Here"
                                                      onChange={(e: any) => {
                                                        handleTimepicker(e.target.innerText, formDaily.id);
                                                      }}
                                                      onKeyUp={(e: any) => {
                                                        handleTimepicker(e.target.value, formDaily.id);
                                                      }}
                                                      defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[i + 1]?.time}:00`)}
                                                      required
                                                    />
                                                  ) : (
                                                    <TimePicker
                                                      placeholder="Enter Time Here"
                                                      onChange={(e: any) => {
                                                        handleTimepicker(e.target.innerText, formDaily.id);
                                                      }}
                                                      onKeyUp={(e: any) => {
                                                        handleTimepicker(e.target.value, formDaily.id);
                                                      }}
                                                    />
                                                  )}
                                                  <StackItem>
                                                    <Image src="/visualization/v2/image/required.svg" />
                                                  </StackItem>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 5 }}>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <Delete20Regular {...deleteIcon} onClick={() => handleRemoveDailyClone(formDaily.id)} />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                        <StackItem>
                          <>
                            {selectedOption == 'weekly' && (
                              <Stack tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                                        {daysOfWeek.map(day => (
                                          <StackItem key={day}>
                                            <Button
                                              size="medium"
                                              appearance={clickedDays[`isWeeklyButton${day}Clicked`] ? 'primary' : 'outline'}
                                              onClick={() => handleWeeklyButtonClick(day)}
                                            >
                                              {day.substring(0, 3)}
                                            </Button>
                                          </StackItem>
                                        ))}
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack horizontal>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 10 }}>
                                        <Text>
                                          At what time should scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                        </Text>
                                        <StackItem>
                                          <Stack tokens={{ childrenGap: 20 }} horizontal>
                                            <StackItem>
                                              {configFormData.executionTime[0]?.time?.length > 0 ? (
                                                <TimePicker
                                                  placeholder="Enter Time Here"
                                                  onChange={(e: any) => {
                                                    handleTimepicker(e.target.innerText, 0);
                                                  }}
                                                  onKeyUp={(e: any) => {
                                                    handleTimepicker(e.target.value, 0);
                                                  }}
                                                  defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                                />
                                              ) : (
                                                <TimePicker
                                                  placeholder="Enter Time Here"
                                                  onChange={(e: any) => {
                                                    handleTimepicker(e.target.innerText, 0);
                                                  }}
                                                  onKeyUp={(e: any) => {
                                                    handleTimepicker(e.target.value, 0);
                                                  }}
                                                />
                                              )}
                                            </StackItem>
                                            {/* <StackItem>  
                                        <Stack tokens={{childrenGap:5}}>
                                          <StackItem><Text></Text></StackItem>
                                          <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleWeeklyAddClone}/> </StackItem>
                                        </Stack>
                                      </StackItem> */}
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      {cloneWeeklyForms.map((formWeekly: any, i: any) => (
                                        <Stack key={formWeekly.id}>
                                          <Stack horizontal tokens={{ childrenGap: 480 }}>
                                            <StackItem>
                                              {selectedOption === 'weekly' && (
                                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                  <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                    <StackItem>
                                                      {configFormData.executionTime[i + 1]?.time?.length > 0 ? (
                                                        <TimePicker
                                                          placeholder="Enter Time Here"
                                                          onChange={(e: any) => {
                                                            handleTimepicker(e.target.innerText, formWeekly.id);
                                                          }}
                                                          onKeyUp={(e: any) => {
                                                            handleTimepicker(e.target.value, formWeekly.id);
                                                          }}
                                                          defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[i + 1]?.time}:00`)}
                                                        />
                                                      ) : (
                                                        <TimePicker
                                                          placeholder="Enter Time Here"
                                                          onChange={(e: any) => {
                                                            handleTimepicker(e.target.innerText, formWeekly.id);
                                                          }}
                                                          onKeyUp={(e: any) => {
                                                            handleTimepicker(e.target.value, formWeekly.id);
                                                          }}
                                                        />
                                                      )}
                                                      <Image src="/visualization/v2/image/required.svg" />
                                                    </StackItem>
                                                    <StackItem>
                                                      <Image src="/visualization/v2/image/required.svg" />
                                                    </StackItem>
                                                    <StackItem>
                                                      <Stack tokens={{ childrenGap: 5 }}>
                                                        <StackItem>
                                                          <Text></Text>
                                                        </StackItem>
                                                        <StackItem>
                                                          <Delete20Regular {...deleteIcon} onClick={() => handleRemoveWeeklyClone(formWeekly.id)} />
                                                        </StackItem>
                                                      </Stack>
                                                    </StackItem>
                                                  </Stack>
                                                </Stack>
                                              )}
                                            </StackItem>
                                          </Stack>
                                          <Stack tokens={{ childrenGap: 20 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      ))}
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                        <StackItem>
                          <>
                            {selectedOption == 'monthly' && (
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {months.slice(0, 6).map(month => (
                                      <StackItem key={month}>
                                        <Button
                                          size="medium"
                                          appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                          onClick={() => handleMonthButtonClick(month)}
                                        >
                                          {month}
                                        </Button>
                                      </StackItem>
                                    ))}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {months.slice(6).map(month => (
                                      <StackItem key={month}>
                                        <Button
                                          size="medium"
                                          appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                          onClick={() => handleMonthButtonClick(month)}
                                        >
                                          {month}
                                        </Button>
                                      </StackItem>
                                    ))}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                  </Stack>
                                  <Stack>
                                    <StackItem>
                                      <Body1> On which date should the scheduler execute? </Body1>
                                      <StackItem grow>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem grow>
                                            {/* <DatePicker onSelectDate={(e: any) => {handleDatepicker(e,0)}} placeholder="Enter Date Here" defaultValue={configFormData} /> */}

                                            <Dropdown
                                              value={configFormData?.date ? configFormData?.date[0]?.date : ''}
                                              placeholder="Enter Date Here"
                                              aria-labelledby={dropdownId}
                                              size="medium"
                                            >
                                              {dates.map(val => (
                                                <Option
                                                  key={`op_${val}`}
                                                  text={val}
                                                  value={val}
                                                  onClick={() => {
                                                    handleDatepicker(parseInt(val), 0);
                                                  }}
                                                >
                                                  {val}
                                                </Option>
                                              ))}
                                            </Dropdown>
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 15 }} horizontal>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 15 }} horizontal>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <AddSquare20Regular {...primaryIcon} onClick={handleMonthlyAddCloneDate} />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </StackItem>
                                    </StackItem>
                                  </Stack>
                                  {/* WRITE MONTHLY CLONE DATE STARTS HERE */}
                                  <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text>&nbsp;</Text>
                                      </StackItem>
                                    </Stack>
                                    {cloneMonthlyFormsDate.map((formMonthlyDate: any, i: any) => (
                                      <Stack key={formMonthlyDate.id}>
                                        <Stack horizontal tokens={{ childrenGap: 480 }}>
                                          <StackItem grow>
                                            {selectedOption === 'monthly' && (
                                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                  <StackItem grow>
                                                    {/* <DatePicker onSelectDate={(e: any) => {handleDatepicker(e,formMonthlyDate.id)}} onKeyUp={(e: any) => {handleDatepicker(e,formMonthlyDate.id)}} placeholder = "Enter Date Here......................." /> */}
                                                    <Dropdown
                                                      value={configFormData?.date ? configFormData?.date[i + 1]?.date : ''}
                                                      placeholder="Enter Date Here"
                                                      aria-labelledby={dropdownId}
                                                      size="medium"
                                                      aria-required
                                                    >
                                                      {dates.map(val => (
                                                        <Option
                                                          key={`op_${val}`}
                                                          text={val}
                                                          value={val}
                                                          onClick={() => {
                                                            handleDatepicker(parseInt(val), formMonthlyDate.id);
                                                          }}
                                                        >
                                                          {val}
                                                        </Option>
                                                      ))}
                                                    </Dropdown>
                                                    <Image src="/visualization/v2/image/required.svg" />
                                                  </StackItem>
                                                  <StackItem>
                                                    <Stack tokens={{ childrenGap: 5 }}>
                                                      <StackItem>
                                                        <Text></Text>
                                                      </StackItem>
                                                      <StackItem>
                                                        <Delete20Regular
                                                          {...deleteIcon}
                                                          onClick={() => handleRemoveMonthlyCloneDate(formMonthlyDate.id)}
                                                        />
                                                      </StackItem>
                                                    </Stack>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            )}
                                          </StackItem>
                                        </Stack>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    ))}
                                  </StackItem>
                                  {/* WRITE MONTHLY CLONE DATE ENDS HERE */}
                                  <Stack tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                  </Stack>
                                  <Stack>
                                    <StackItem>
                                      <Body1>
                                        At what time should the scheduler execute?
                                        <Image src="/visualization/v2/image/required.svg" />
                                      </Body1>
                                      <StackItem grow>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem grow>
                                            {configFormData.executionTime[0]?.time?.length > 0 ? (
                                              <TimePicker
                                                required
                                                placeholder="Enter Time Here"
                                                onChange={(e: any) => {
                                                  handleTimepicker(e.target.innerText, 0);
                                                }}
                                                onKeyUp={(e: any) => {
                                                  handleTimepicker(e.target.value, 0);
                                                }}
                                                defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                              />
                                            ) : (
                                              <TimePicker
                                                placeholder="Enter Time Here"
                                                onChange={(e: any) => {
                                                  handleTimepicker(e.target.innerText, 0);
                                                }}
                                                onKeyUp={(e: any) => {
                                                  handleTimepicker(e.target.value, 0);
                                                }}
                                              />
                                            )}
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 307 }} horizontal>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  {/* <Stack tokens={{childrenGap:15}} horizontal>
                                              <StackItem><Text></Text></StackItem>
                                              <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleMonthlyAddClone}/> </StackItem>
                                            </Stack> */}
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </StackItem>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                {/* WRITE MONTHLY CLONE TIME STARTS HERE */}
                                <StackItem>
                                  {cloneMonthlyFormsTime.map(formMonthlyTime => (
                                    <Stack key={formMonthlyTime.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem>
                                          {selectedOption === 'monthly' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                <StackItem>
                                                  <TimePicker
                                                    placeholder="Enter Time Here"
                                                    onChange={(e: any) => {
                                                      handleTimepicker(e.target.innerText, formMonthlyTime.id);
                                                    }}
                                                    onKeyUp={(e: any) => {
                                                      handleTimepicker(e.target.value, formMonthlyTime.id);
                                                    }}
                                                  />
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 5 }}>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <Delete20Regular
                                                        {...deleteIcon}
                                                        onClick={() => handleRemoveMonthlyCloneTime(formMonthlyTime.id)}
                                                      />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                {/* WRITE MONTHLY CLONE TIME ENDS HERE */}
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <>
                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Name of the automation schedule </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Description </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Automation that you scheduled</Body1Strong>
                      </StackItem>
                      <StackItem>{selectedOptions.length > 0 && <Badge color="informative">{selectedOptions[0]}</Badge>}</StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack tokens={{ padding: 10 }}>
                      <StackItem>
                        <>
                          {selectedOption == 'daily' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                  <StackItem>
                                    <Dropdown
                                      disabled={true}
                                      value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                      placeholder="Enter Time Here"
                                      aria-labelledby={dropdownId}
                                      size="medium"
                                    ></Dropdown>
                                  </StackItem>
                                  <StackItem>
                                    <Checkbox
                                      checked={configFormData.weekend}
                                      onChange={handleWeekendCheckbox}
                                      label="Include Weekends"
                                      disabled={true}
                                    />
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                {cloneDailyForms.map((formDaily: any, i: any) => (
                                  <Stack key={formDaily.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'daily' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 25 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  disabled={true}
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'weekly' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {daysOfWeek.map(day => (
                                        <StackItem key={day}>
                                          <Button
                                            disabled={true}
                                            size="medium"
                                            appearance={clickedDays[`isWeeklyButton${day}Clicked`] ? 'primary' : 'outline'}
                                            onClick={() => handleWeeklyButtonClick(day)}
                                          >
                                            {day.substring(0, 3)}
                                          </Button>
                                        </StackItem>
                                      ))}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <Text>
                                        At what time should scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                      </Text>
                                      <StackItem>
                                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                                          <StackItem>
                                            <Dropdown
                                              disabled={true}
                                              value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                              placeholder="Enter Time Here"
                                              aria-labelledby={dropdownId}
                                              size="medium"
                                            ></Dropdown>
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    {cloneWeeklyForms.map((formWeekly: any, i: any) => (
                                      <Stack key={formWeekly.id}>
                                        <Stack horizontal tokens={{ childrenGap: 480 }}>
                                          <StackItem>
                                            {selectedOption === 'weekly' && (
                                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                  <StackItem>
                                                    <Dropdown
                                                      disabled={true}
                                                      value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                      placeholder="Enter Time Here"
                                                      aria-labelledby={dropdownId}
                                                      size="medium"
                                                    ></Dropdown>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            )}
                                          </StackItem>
                                        </Stack>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    ))}
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'monthly' && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(0, 6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1> On which date should the scheduler execute? </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.date ? configFormData?.date[0]?.date : ''}
                                            placeholder="Enter Date Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                                {/* WRITE MONTHLY CLONE DATE STARTS HERE */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text>&nbsp;</Text>
                                    </StackItem>
                                  </Stack>
                                  {cloneMonthlyFormsDate.map((formMonthlyDate: any, i: any) => (
                                    <Stack key={formMonthlyDate.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem grow>
                                          {selectedOption === 'monthly' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                <StackItem grow>
                                                  <Dropdown
                                                    value={configFormData?.date ? configFormData?.date[i + 1]?.date : ''}
                                                    placeholder="Enter Date Here"
                                                    aria-labelledby={dropdownId}
                                                    size="medium"
                                                    disabled={true}
                                                  ></Dropdown>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                {/* WRITE MONTHLY CLONE DATE ENDS HERE */}
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1>
                                      At what time should the scheduler execute?
                                      <Image src="/visualization/v2/image/required.svg" />
                                    </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                            placeholder="Enter Time Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME STARTS HERE */}
                              <StackItem>
                                {cloneMonthlyFormsTime.map(formMonthlyTime => (
                                  <Stack key={formMonthlyTime.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'monthly' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                  disabled={true}
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME ENDS HERE */}
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </>
            ),
          },
        });

      overlayName == 'editAutomation' &&
        automationStatus === 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `Edit ${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Basic: (
              <form id="myFormAutomationschedule" onSubmit={e => handleSubmit(e)}>
                <>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    <StackItem>
                      <FluentProvider theme={messageTheme}>
                        <Card appearance="subtle" size="small">
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Info24Filled {...primaryIcon} />
                            </StackItem>
                            <StackItem>
                              <Caption1> These details will make it easy to find the snippets later when you need to use them </Caption1>
                            </StackItem>
                          </Stack>
                        </Card>
                      </FluentProvider>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong>
                            Name of the automation schedule <Image src="/visualization/v2/image/required.svg" />
                          </Body1Strong>
                        </StackItem>
                        <StackItem>
                          <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                          {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong> Description </Body1Strong>
                        </StackItem>
                        <StackItem>
                          <Resize inputChange={setFormData} inputValue={formData} />
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem>
                      <Stack>
                        <StackItem>
                          <Body1Strong>
                            Select the Automation that you want to schedule
                            <Image src="/visualization/v2/image/required.svg" />
                          </Body1Strong>
                        </StackItem>
                        <StackItem>
                          <MultiselectWithTags
                            onChange={(e: any) => setAutomationSearchVal(e.target.value)}
                            options={automationSearchData}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}
                            multi={false}
                            tag={true}
                            placeholder="Please Search"
                            required
                          />
                        </StackItem>
                        <StackItem></StackItem>
                      </Stack>
                    </StackItem>
                    {showAdditionalContent && (
                      <>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem>
                                  <Info24Filled {...primaryIcon} />
                                </StackItem>
                                <StackItem>
                                  <Caption1> These are the basic details associated with this Automation that you selected </Caption1>
                                </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem>
                        {Object.keys(details).map(key => (
                          <>
                            <StackItem>
                              <p>
                                <b>{key}</b>
                              </p>
                            </StackItem>
                            <StackItem>
                              <p>{details[key]}</p>
                            </StackItem>
                          </>
                        ))}
                      </>
                    )}
                  </Stack>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                </>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configuration: (
              <form id="myFormAutomationschedule" onSubmit={e => handleSubmit(e)}>
                <>
                  <Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>
                    <StackItem>
                      <RadioGroups
                        defaultValue={formData.jsonData.type}
                        data={radioData}
                        layout="horizontal"
                        value={selectedOption}
                        onChange={e => handleOptionChange(e)}
                        required={true}
                      />
                    </StackItem>
                    <StackItem>
                      <Stack tokens={{ padding: 10 }}>
                        <StackItem>
                          <>
                            {selectedOption == 'daily' && (
                              <Stack tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 20 }} horizontal>
                                    <StackItem>
                                      {configFormData.executionTime[0]?.time?.length > 0 ? (
                                        <TimePicker
                                          placeholder="Enter Time Here"
                                          onChange={(e: any) => {
                                            handleTimepicker(e.target.innerText, 0);
                                          }}
                                          onKeyUp={(e: any) => {
                                            handleTimepicker(e.target.value, 0);
                                          }}
                                          defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                        />
                                      ) : (
                                        <TimePicker
                                          placeholder="Enter Time Here"
                                          onChange={(e: any) => {
                                            handleTimepicker(e.target.innerText, 0);
                                          }}
                                          onKeyUp={(e: any) => {
                                            handleTimepicker(e.target.value, 0);
                                          }}
                                        />
                                      )}
                                    </StackItem>
                                    <StackItem>
                                      <Checkbox checked={configFormData.weekend} onChange={handleWeekendCheckbox} label="Include Weekends" />
                                    </StackItem>
                                    {/* <StackItem> 
                                <Stack tokens={{childrenGap:5}}>
                                  <StackItem><Text></Text></StackItem>
                                  <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleDailyAddClone}/> </StackItem>
                                </Stack>
                              </StackItem> */}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  {cloneDailyForms.map(formDaily => (
                                    <Stack key={formDaily.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem>
                                          {selectedOption === 'daily' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 25 }} horizontal>
                                                <StackItem>
                                                  <TimePicker
                                                    placeholder="Enter Time Here"
                                                    onChange={(e: any) => {
                                                      handleTimepicker(e.target.innerText, formDaily.id);
                                                    }}
                                                    onKeyUp={(e: any) => {
                                                      handleTimepicker(e.target.value, formDaily.id);
                                                    }}
                                                  />
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 5 }}>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <Delete20Regular {...deleteIcon} onClick={() => handleRemoveDailyClone(formDaily.id)} />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                        <StackItem>
                          <>
                            {selectedOption == 'weekly' && (
                              <Stack tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                                        {daysOfWeek.map(day => (
                                          <StackItem key={day}>
                                            <Button
                                              size="medium"
                                              appearance={clickedDays[`isWeeklyButton${day}Clicked`] ? 'primary' : 'outline'}
                                              onClick={() => handleWeeklyButtonClick(day)}
                                            >
                                              {day.substring(0, 3)}
                                            </Button>
                                          </StackItem>
                                        ))}
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack horizontal>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 10 }}>
                                        <Text>
                                          At what time should scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                        </Text>
                                        <StackItem>
                                          <Stack tokens={{ childrenGap: 20 }} horizontal>
                                            <StackItem>
                                              {configFormData.executionTime[0]?.time?.length > 0 ? (
                                                <TimePicker
                                                  placeholder="Enter Time Here"
                                                  onChange={(e: any) => {
                                                    handleTimepicker(e.target.innerText, 0);
                                                  }}
                                                  onKeyUp={(e: any) => {
                                                    handleTimepicker(e.target.value, 0);
                                                  }}
                                                  defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                                />
                                              ) : (
                                                <TimePicker
                                                  placeholder="Enter Time Here"
                                                  onChange={(e: any) => {
                                                    handleTimepicker(e.target.innerText, 0);
                                                  }}
                                                  onKeyUp={(e: any) => {
                                                    handleTimepicker(e.target.value, 0);
                                                  }}
                                                />
                                              )}
                                            </StackItem>
                                            {/* <StackItem>  
                                        <Stack tokens={{childrenGap:5}}>
                                          <StackItem><Text></Text></StackItem>
                                          <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleWeeklyAddClone}/> </StackItem>
                                        </Stack>
                                      </StackItem> */}
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      {cloneWeeklyForms.map(formWeekly => (
                                        <Stack key={formWeekly.id}>
                                          <Stack horizontal tokens={{ childrenGap: 480 }}>
                                            <StackItem>
                                              {selectedOption === 'weekly' && (
                                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                  <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                    <StackItem>
                                                      <TimePicker
                                                        placeholder="Enter Time Here"
                                                        onChange={(e: any) => {
                                                          handleTimepicker(e.target.innerText, formWeekly.id);
                                                        }}
                                                        onKeyUp={(e: any) => {
                                                          handleTimepicker(e.target.value, formWeekly.id);
                                                        }}
                                                      />
                                                    </StackItem>
                                                    <StackItem>
                                                      <Stack tokens={{ childrenGap: 5 }}>
                                                        <StackItem>
                                                          <Text></Text>
                                                        </StackItem>
                                                        <StackItem>
                                                          <Delete20Regular {...deleteIcon} onClick={() => handleRemoveWeeklyClone(formWeekly.id)} />
                                                        </StackItem>
                                                      </Stack>
                                                    </StackItem>
                                                  </Stack>
                                                </Stack>
                                              )}
                                            </StackItem>
                                          </Stack>
                                          <Stack tokens={{ childrenGap: 20 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      ))}
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                        <StackItem>
                          <>
                            {selectedOption == 'monthly' && (
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {months.slice(0, 6).map(month => (
                                      <StackItem key={month}>
                                        <Button
                                          size="medium"
                                          appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                          onClick={() => handleMonthButtonClick(month)}
                                        >
                                          {month}
                                        </Button>
                                      </StackItem>
                                    ))}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {months.slice(6).map(month => (
                                      <StackItem key={month}>
                                        <Button
                                          size="medium"
                                          appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                          onClick={() => handleMonthButtonClick(month)}
                                        >
                                          {month}
                                        </Button>
                                      </StackItem>
                                    ))}
                                  </Stack>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                  </Stack>
                                  <Stack>
                                    <StackItem>
                                      <Body1> On which date should the scheduler execute? </Body1>
                                      <StackItem grow>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem>
                                            {/* <DatePicker onSelectDate={(e: any) => {handleDatepicker(e,0)}} placeholder="Enter Date Here"/>  */}

                                            <Dropdown
                                              value={configFormData?.date ? configFormData?.date[0]?.date : ''}
                                              placeholder="Enter Date Here"
                                              aria-labelledby={dropdownId}
                                              size="medium"
                                            >
                                              {dates.map(val => (
                                                <Option
                                                  key={`op_${val}`}
                                                  text={val}
                                                  value={val}
                                                  onClick={() => {
                                                    handleDatepicker(parseInt(val), 0);
                                                  }}
                                                >
                                                  {val}
                                                </Option>
                                              ))}
                                            </Dropdown>
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 307 }} horizontal>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 15 }} horizontal>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <AddSquare20Regular {...primaryIcon} onClick={handleMonthlyAddCloneDate} />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </StackItem>
                                    </StackItem>
                                  </Stack>
                                  {/* WRITE MONTHLY CLONE DATE STARTS HERE */}
                                  <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text>&nbsp;</Text>
                                      </StackItem>
                                    </Stack>
                                    {cloneMonthlyFormsDate.map((formMonthlyDate: any, i: any) => (
                                      <Stack key={formMonthlyDate.id}>
                                        <Stack horizontal tokens={{ childrenGap: 480 }}>
                                          <StackItem grow>
                                            {selectedOption === 'monthly' && (
                                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                  <StackItem>
                                                    {/* <DatePicker onSelectDate={(e: any) => {handleDatepicker(e,formMonthlyDate.id)}} onKeyUp={(e: any) => {handleDatepicker(e,formMonthlyDate.id)}} placeholder = "Enter Date Here......................." />  */}

                                                    <Dropdown
                                                      value={configFormData?.date ? configFormData?.date[i + 1]?.date : ''}
                                                      placeholder="Enter Date Here"
                                                      aria-labelledby={dropdownId}
                                                      size="medium"
                                                    >
                                                      {dates.map(val => (
                                                        <Option
                                                          key={`op_${val}`}
                                                          text={val}
                                                          value={val}
                                                          onClick={() => {
                                                            handleDatepicker(parseInt(val), formMonthlyDate.id);
                                                          }}
                                                        >
                                                          {val}
                                                        </Option>
                                                      ))}
                                                    </Dropdown>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Stack tokens={{ childrenGap: 5 }}>
                                                      <StackItem>
                                                        <Text></Text>
                                                      </StackItem>
                                                      <StackItem>
                                                        <Delete20Regular
                                                          {...deleteIcon}
                                                          onClick={() => handleRemoveMonthlyCloneDate(formMonthlyDate.id)}
                                                        />
                                                      </StackItem>
                                                    </Stack>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            )}
                                          </StackItem>
                                        </Stack>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    ))}
                                  </StackItem>
                                  {/* WRITE MONTHLY CLONE DATE ENDS HERE */}
                                  <Stack tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                  </Stack>
                                  <Stack>
                                    <StackItem>
                                      <Body1>
                                        At what time should the scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                      </Body1>
                                      <StackItem grow>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem grow>
                                            {configFormData.executionTime[0]?.time?.length > 0 ? (
                                              <TimePicker
                                                placeholder="Enter Time Here"
                                                onChange={(e: any) => {
                                                  handleTimepicker(e.target.innerText, 0);
                                                }}
                                                onKeyUp={(e: any) => {
                                                  handleTimepicker(e.target.value, 0);
                                                }}
                                                defaultValue={new Date(`November 25, 2023 ${configFormData.executionTime[0]?.time}:00`)}
                                              />
                                            ) : (
                                              <TimePicker
                                                placeholder="Enter Time Here"
                                                onChange={(e: any) => {
                                                  handleTimepicker(e.target.innerText, 0);
                                                }}
                                                onKeyUp={(e: any) => {
                                                  handleTimepicker(e.target.value, 0);
                                                }}
                                              />
                                            )}
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 307 }} horizontal>
                                            <StackItem>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 15 }} horizontal>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    {/* <StackItem> <AddSquare20Filled {...primaryIcon} onClick={handleMonthlyAddClone}/> </StackItem> */}
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </StackItem>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                                {/* WRITE MONTHLY CLONE TIME STARTS HERE */}
                                <StackItem>
                                  {cloneMonthlyFormsTime.map(formMonthlyTime => (
                                    <Stack key={formMonthlyTime.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem>
                                          {selectedOption === 'monthly' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                <StackItem>
                                                  <TimePicker
                                                    placeholder="Enter Time Here"
                                                    onChange={(e: any) => {
                                                      handleTimepicker(e.target.innerText, formMonthlyTime.id);
                                                    }}
                                                    onKeyUp={(e: any) => {
                                                      handleTimepicker(e.target.value, formMonthlyTime.id);
                                                    }}
                                                  />
                                                </StackItem>
                                                <StackItem>
                                                  <Stack tokens={{ childrenGap: 5 }}>
                                                    <StackItem>
                                                      <Text></Text>
                                                    </StackItem>
                                                    <StackItem>
                                                      <Delete20Regular
                                                        {...deleteIcon}
                                                        onClick={() => handleRemoveMonthlyCloneTime(formMonthlyTime.id)}
                                                      />
                                                    </StackItem>
                                                  </Stack>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                {/* WRITE MONTHLY CLONE TIME ENDS HERE */}
                                <StackItem>
                                  <Stack>
                                    <StackItem>
                                      <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                    </StackItem>
                                    <StackItem>
                                      <Body1>
                                        <RadioGroups
                                          defaultValue={configFormData.recommendedAction}
                                          onChange={handleRadioGrps}
                                          data={radioData1}
                                          layout="horizontal"
                                        />
                                      </Body1>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                          </>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <>
                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Name of the automation schedule </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Description </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Automation that you scheduled</Body1Strong>
                      </StackItem>
                      <StackItem>{selectedOptions.length > 0 && <Badge color="informative">{selectedOptions[0]}</Badge>}</StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack tokens={{ padding: 10 }}>
                      <StackItem>
                        <>
                          {selectedOption == 'daily' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                  <StackItem>
                                    <Dropdown
                                      disabled={true}
                                      value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                      placeholder="Enter Time Here"
                                      aria-labelledby={dropdownId}
                                      size="medium"
                                    ></Dropdown>
                                  </StackItem>
                                  <StackItem>
                                    <Checkbox
                                      checked={configFormData.weekend}
                                      onChange={handleWeekendCheckbox}
                                      label="Include Weekends"
                                      disabled={true}
                                    />
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                {cloneDailyForms.map((formDaily: any, i: any) => (
                                  <Stack key={formDaily.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'daily' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 25 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  disabled={true}
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'weekly' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {daysOfWeek.map(day => (
                                        <StackItem key={day}>
                                          <Button
                                            disabled={true}
                                            size="medium"
                                            appearance={clickedDays[`isWeeklyButton${day}Clicked`] ? 'primary' : 'outline'}
                                            onClick={() => handleWeeklyButtonClick(day)}
                                          >
                                            {day.substring(0, 3)}
                                          </Button>
                                        </StackItem>
                                      ))}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <Text>
                                        At what time should scheduler execute?
                                        <Image src="/visualization/v2/image/required.svg" />
                                      </Text>
                                      <StackItem>
                                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                                          <StackItem>
                                            <Dropdown
                                              disabled={true}
                                              value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                              placeholder="Enter Time Here"
                                              aria-labelledby={dropdownId}
                                              size="medium"
                                            ></Dropdown>
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    {cloneWeeklyForms.map((formWeekly: any, i: any) => (
                                      <Stack key={formWeekly.id}>
                                        <Stack horizontal tokens={{ childrenGap: 480 }}>
                                          <StackItem>
                                            {selectedOption === 'weekly' && (
                                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                  <StackItem>
                                                    <Dropdown
                                                      disabled={true}
                                                      value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                      placeholder="Enter Time Here"
                                                      aria-labelledby={dropdownId}
                                                      size="medium"
                                                    ></Dropdown>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            )}
                                          </StackItem>
                                        </Stack>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    ))}
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'monthly' && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(0, 6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1>
                                      On which date should the scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                    </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.date ? configFormData?.date[0]?.date : ''}
                                            placeholder="Enter Date Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                                {/* WRITE MONTHLY CLONE DATE STARTS HERE */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text>&nbsp;</Text>
                                    </StackItem>
                                  </Stack>
                                  {cloneMonthlyFormsDate.map((formMonthlyDate: any, i: any) => (
                                    <Stack key={formMonthlyDate.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem grow>
                                          {selectedOption === 'monthly' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                <StackItem grow>
                                                  <Dropdown
                                                    value={configFormData?.date ? configFormData?.date[i + 1]?.date : ''}
                                                    placeholder="Enter Date Here"
                                                    aria-labelledby={dropdownId}
                                                    size="medium"
                                                    disabled={true}
                                                  ></Dropdown>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                {/* WRITE MONTHLY CLONE DATE ENDS HERE */}
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1>
                                      At what time should the scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                    </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                            placeholder="Enter Time Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME STARTS HERE */}
                              <StackItem>
                                {cloneMonthlyFormsTime.map(formMonthlyTime => (
                                  <Stack key={formMonthlyTime.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'monthly' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                  disabled={true}
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME ENDS HERE */}
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </>
            ),
          },
        });

      overlayName == 'editAutomation' &&
        automationStatus !== 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Configuration: (
              <>
                <Stack tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Name of the automation schedule </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Description </Body1Strong>
                      </StackItem>
                      <StackItem>
                        <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack>
                      <StackItem>
                        <Body1Strong> Automation that you scheduled</Body1Strong>
                      </StackItem>
                      <StackItem>{selectedOptions.length > 0 && <Badge color="informative">{selectedOptions[0]}</Badge>}</StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Stack tokens={{ padding: 10 }}>
                      <StackItem>
                        <>
                          {selectedOption == 'daily' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                  <StackItem>
                                    <Dropdown
                                      disabled={true}
                                      value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                      placeholder="Enter Time Here"
                                      aria-labelledby={dropdownId}
                                      size="medium"
                                    ></Dropdown>
                                  </StackItem>
                                  <StackItem>
                                    <Checkbox
                                      checked={configFormData.weekend}
                                      onChange={handleWeekendCheckbox}
                                      label="Include Weekends"
                                      disabled={true}
                                    />
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                {cloneDailyForms.map((formDaily: any, i: any) => (
                                  <Stack key={formDaily.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'daily' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 25 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  disabled={true}
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'weekly' && (
                            <Stack tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {daysOfWeek.map(day => (
                                        <StackItem key={day}>
                                          <Button
                                            disabled={true}
                                            size="medium"
                                            appearance={clickedDays[`isWeeklyButton${day}Clicked`] ? 'primary' : 'outline'}
                                            onClick={() => handleWeeklyButtonClick(day)}
                                          >
                                            {day.substring(0, 3)}
                                          </Button>
                                        </StackItem>
                                      ))}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <Text>
                                        At what time should scheduler execute?
                                        <Image src="/visualization/v2/image/required.svg" />
                                      </Text>
                                      <StackItem>
                                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                                          <StackItem>
                                            <Dropdown
                                              disabled={true}
                                              value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                              placeholder="Enter Time Here"
                                              aria-labelledby={dropdownId}
                                              size="medium"
                                            ></Dropdown>
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    {cloneWeeklyForms.map((formWeekly: any, i: any) => (
                                      <Stack key={formWeekly.id}>
                                        <Stack horizontal tokens={{ childrenGap: 480 }}>
                                          <StackItem>
                                            {selectedOption === 'weekly' && (
                                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                  <StackItem>
                                                    <Dropdown
                                                      disabled={true}
                                                      value={configFormData?.executionTime ? configFormData?.executionTime[i + 1]?.time : ''}
                                                      placeholder="Enter Time Here"
                                                      aria-labelledby={dropdownId}
                                                      size="medium"
                                                    ></Dropdown>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            )}
                                          </StackItem>
                                        </Stack>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    ))}
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                      <StackItem>
                        <>
                          {selectedOption == 'monthly' && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(0, 6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                  {months.slice(6).map(month => (
                                    <StackItem key={month}>
                                      <Button
                                        size="medium"
                                        appearance={clickedMonths[`isMonthButton${month}Clicked`] ? 'primary' : 'outline'}
                                        onClick={() => handleMonthButtonClick(month)}
                                        disabled={true}
                                      >
                                        {month}
                                      </Button>
                                    </StackItem>
                                  ))}
                                </Stack>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1>
                                      On which date should the scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                    </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.date ? configFormData?.date[0]?.date : ''}
                                            placeholder="Enter Date Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                                {/* WRITE MONTHLY CLONE DATE STARTS HERE */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Text>&nbsp;</Text>
                                    </StackItem>
                                  </Stack>
                                  {cloneMonthlyFormsDate.map((formMonthlyDate: any, i: any) => (
                                    <Stack key={formMonthlyDate.id}>
                                      <Stack horizontal tokens={{ childrenGap: 480 }}>
                                        <StackItem grow>
                                          {selectedOption === 'monthly' && (
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                              <Stack tokens={{ childrenGap: 20 }} horizontal>
                                                <StackItem grow>
                                                  <Dropdown
                                                    value={configFormData?.date ? configFormData?.date[i + 1]?.date : ''}
                                                    placeholder="Enter Date Here"
                                                    aria-labelledby={dropdownId}
                                                    size="medium"
                                                    disabled={true}
                                                  ></Dropdown>
                                                </StackItem>
                                              </Stack>
                                            </Stack>
                                          )}
                                        </StackItem>
                                      </Stack>
                                      <Stack tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  ))}
                                </StackItem>
                                {/* WRITE MONTHLY CLONE DATE ENDS HERE */}
                                <Stack tokens={{ childrenGap: 20 }}>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                </Stack>
                                <Stack>
                                  <StackItem>
                                    <Body1>
                                      At what time should the scheduler execute? <Image src="/visualization/v2/image/required.svg" />
                                    </Body1>
                                    <StackItem grow>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem grow>
                                          <Dropdown
                                            value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                            placeholder="Enter Time Here"
                                            aria-labelledby={dropdownId}
                                            size="medium"
                                            disabled={true}
                                          ></Dropdown>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME STARTS HERE */}
                              <StackItem>
                                {cloneMonthlyFormsTime.map(formMonthlyTime => (
                                  <Stack key={formMonthlyTime.id}>
                                    <Stack horizontal tokens={{ childrenGap: 480 }}>
                                      <StackItem>
                                        {selectedOption === 'monthly' && (
                                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                              <StackItem>
                                                <Dropdown
                                                  value={configFormData?.executionTime ? configFormData?.executionTime[0]?.time : ''}
                                                  placeholder="Enter Time Here"
                                                  aria-labelledby={dropdownId}
                                                  size="medium"
                                                  disabled={true}
                                                ></Dropdown>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        )}
                                      </StackItem>
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                              {/* WRITE MONTHLY CLONE TIME ENDS HERE */}
                              <StackItem>
                                <Stack>
                                  <StackItem>
                                    <Body1> In the case of a missed schedule, what is the the recommended course of action? </Body1>
                                  </StackItem>
                                  <StackItem>
                                    <Body1>
                                      <RadioGroups
                                        defaultValue={configFormData.recommendedAction}
                                        onChange={handleRadioGrps}
                                        data={radioData1}
                                        layout="horizontal"
                                        disabled={true}
                                      />
                                    </Body1>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          )}
                        </>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </>
            ),
          },
        });
    }
  }, [
    dartSpecs,
    overlayName,
    formData,
    selectedOption,
    showAdditionalContent,
    cloneWeeklyForms,
    clickedDays,
    cloneDailyForms,
    automationSearchData,
    configFormData,
    cloneMonthlyFormsDate,
    cloneMonthlyFormsTime,
    selectedOptions,
    classificationNameValidation,
  ]);
  // overflow set contents code ends here

  useEffect(() => {
    console.log('rowID=', rowID);
  }, [rowID]);

  var tempTableData: any = [];
  var menuLists: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modified: val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-',
          status: status,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);
    var status = '';
    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any, i: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'Not attached' ? 'show' : 'hide',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={(e: any) => {
                    setOverlayOpen(true);
                    setOverlayName('editAutomation');
                    setRowID(val.id);
                    val.status.includes(`${contextValue.tenantName}:`) ? setAutomationStatus('attached') : setAutomationStatus('not attached');
                    setFormData({ ...formData, name: '', description: '', jsonData: {} });
                    setSelectedAutomation(val.automationName);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    !overlayOpen && setOverlayName('');
  }, [overlayOpen]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Scheduler',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  useEffect(() => {
    if (saveBtnClicked1) {
      overlayName == 'addAutomation' &&
        userActivity(
          'Add',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(formData.jsonData)},"type":"Scheduler","summary":"${
            formData.name
          } automation is created"}`,
        );

      overlayName == 'editAutomation' &&
        userActivity(
          'Edit',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(formEditedData.jsonData)},"type":"Scheduler","summary":"${
            formEditedData.name
          } automation is edited"}`,
        );
    }
  }, [saveBtnClicked1]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Automation" subPageName="All Automation" subPageLink="/automate/automation/all_automations" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <StackItem>
              <Stack verticalAlign="center">
                <StackItem>{/* <Text> <Breadcrumbs pageName="Automation Schedule"/> </Text> */}</StackItem>
                <Stack tokens={{ childrenGap: 25 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <FluentProvider theme={webLightTheme}>
                      <Title3> Automation | Automation Schedule </Title3>
                    </FluentProvider>
                  </StackItem>
                  {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
                </Stack>
                <Stack tokens={{ childrenGap: 20 }}>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>
                <FluentProvider theme={webLightTheme}>
                  <Stack horizontal>
                    {/* SPACING BETWEEN INLINE AND PAGE */}
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                    <StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 1 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                          <Divider />
                        </StackItem>
                        <StackItem>
                          <StackItem>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                              <SearchBox
                                onChange={(e: any) => {
                                  setSearchVal(e.target.value);
                                }}
                                placeholder="Search"
                                size="small"
                              />
                              <StackItem>
                                <MenuDropdown
                                  open={filterMenuOpen}
                                  btn={
                                    <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <Filter20Filled {...primaryIcon} />
                                        <Body1> Filter </Body1>
                                      </Stack>
                                    </MenuButton>
                                  }
                                  itemVal={
                                    <>
                                      <Body1Strong> Add a Filter </Body1Strong>
                                      <Divider />
                                      <MenuList>
                                        <Stack tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Filter </Caption1>
                                          </StackItem>
                                          <DropdownComponent
                                            optionData={columns1}
                                            setDropDownValue={setDropDownValue1}
                                            dropDownvalue={dropDownValue1}
                                          />
                                          <StackItem>
                                            <Divider />
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Value </Caption1>
                                          </StackItem>
                                          {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                          <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                          <StackItem>
                                            <Divider />
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <StackItem>
                                              <Button
                                                appearance="primary"
                                                onClick={() => {
                                                  handleFilterArray();
                                                  setFilterMenuOpen(false);
                                                }}
                                              >
                                                <Caption1> Apply </Caption1>
                                              </Button>
                                            </StackItem>
                                            <StackItem>
                                              <Button onClick={() => setFilterMenuOpen(false)}>
                                                <Caption1> Cancel </Caption1>
                                              </Button>
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </MenuList>
                                    </>
                                  }
                                />
                              </StackItem>
                              <StackItem>
                                {filterArray.length > 0 &&
                                  filterArray.map((item, index) => (
                                    <Badge appearance="filled" color="informative">
                                      <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem> {item} </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </Badge>
                                  ))}
                              </StackItem>

                              <StackItem
                                onClick={() => {
                                  setFilterArray([]);
                                  setIsFilter(false);
                                  setDropDownValue2('');
                                  setResetDisabled(true);
                                }}
                              >
                                <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem>
                                      <FilterDismiss20Filled />
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Reset Filters </Caption1>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                          <StackItem>
                            <FluentProvider theme={messageTheme}>
                              <Card appearance="subtle" size="small">
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Info24Filled {...primaryIcon} />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem>
                                    <Stack>
                                      <StackItem>
                                        <Caption1>
                                          Explore the Nanoheal Database for an in-depth understanding of this Automation Schedules. Learn more about
                                          its capabilities, features, and recommended practices to enhance your utilization of this automation
                                          solution within your ecosystem.
                                        </Caption1>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Stack
                                          tokens={{ childrenGap: 10 }}
                                          horizontal
                                          onClick={() => (window.location.href = 'https://www.google.com')}
                                        >
                                          <StackItem>
                                            <Link>
                                              <Caption1 underline> Know&nbsp;More </Caption1>
                                            </Link>
                                          </StackItem>
                                          <StackItem>
                                            <Open20Regular {...primaryIcon} />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Card>
                            </FluentProvider>
                          </StackItem>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                          {tableData.length > 0 ? (
                            <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                              <StackItem>
                                {totalRecords > 0 && (
                                  <Caption1>
                                    Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50}
                                    of {totalRecords} Records
                                  </Caption1>
                                )}
                              </StackItem>
                              <StackItem>
                                <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                              </StackItem>
                            </Stack>
                          ) : (
                            <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Caption1> No Results.</Caption1>
                              </StackItem>
                            </Stack>
                          )}
                        </StackItem>

                        {!saveBtnClicked && overlayName == '' && (
                          <Automaiton_schedule_func
                            data={final_data}
                            func={set_final_data}
                            searchVal={searchVal}
                            pageNo={(currentPageNo - 1) * 50}
                            setTotalPages={setTotalPages}
                            setTotalRecords={setTotalRecords}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                            deleteAutomation={deleteAutomation}
                            setDeleteAutomation={setDeleteAutomation}
                            setDeleteDisabled={setDeleteDisabled}
                            isRefresh={isRefresh}
                            setRefresh={setRefresh}
                            overlayName={overlayName}
                            setIsToast={setIsToast}
                            setDartSpecs={setDartSpecs}
                            setExportDetails={setExportDetails}
                            setRefreshToast={setRefreshToast}
                          />
                        )}

                        {saveBtnClicked1 && formData.name != '' && (
                          <Automaiton_schedule_func
                            data={final_data}
                            func={set_final_data}
                            searchVal={searchVal}
                            pageNo={(currentPageNo - 1) * 50}
                            setTotalPages={setTotalPages}
                            setTotalRecords={setTotalRecords}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                            setIsToast={setIsToast}
                            newData={formData}
                            saveBtnClicked={saveBtnClicked}
                            setSaveBtnClicked={setSaveBtnClicked}
                            overlayName={overlayName}
                            setOverlayOpen={setOverlayOpen}
                            setOverlayName={setOverlayName}
                            rowID={rowID}
                            setFormData={setFormData}
                            setErrorToast={setErrorToast}
                            setToastMessage={setToastMessage}
                          />
                        )}

                        {!saveBtnClicked && overlayName == 'editAutomation' && (
                          <Automaiton_schedule_func
                            setFormData={setFormData}
                            overlayName={overlayName}
                            isRefresh={isRefresh}
                            deleteAutomation={deleteAutomation}
                            searchVal={searchVal}
                            saveBtnClicked={saveBtnClicked}
                            automationSearchVal={automationSearchVal}
                            rowID={rowID}
                            setAutomationSearchData={setAutomationSearchData}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                          />
                        )}

                        {automationSearchVal.length > 0 && (
                          <Automaiton_schedule_func
                            automationSearchVal={automationSearchVal}
                            setAutomationSearchData={setAutomationSearchData}
                            data={final_data}
                            func={set_final_data}
                            isFilter={isfilter}
                            dropDownValue1={dropDownValue1}
                            setIsFilter={setIsFilter}
                            dropDownValue2={dropDownValue2}
                          />
                        )}

                        {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}

                        {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                      </Stack>
                    </StackItem>
                  </Stack>
                </FluentProvider>
              </Stack>
              <OverLay
                overlayopen={overlayOpen}
                setOverlayOpen={setOverlayOpen}
                overlayData={automationOverLayData}
                saveBtnFunc={setSaveBtnClicked}
                isNavBtn={automationStatus !== 'not attached' ? false : true}
                handleOutsideButton={handleOutsideButton}
                handleSubmit={handleSubmit}
                classificationNameValidation={classificationNameValidation}
              />

              {isToast && (
                <ToastComponent
                  setmessagebar={setIsToast}
                  isToastEnabled={isToast}
                  toastIntent="success"
                  position="top-end"
                  title="Success"
                  message={
                    !saveBtnClicked && overlayName == ''
                      ? 'Automation Deleted Successfully'
                      : overlayName == 'addAutomation'
                      ? 'New Automation Created Successfully'
                      : 'Automation Edited Successfully'
                  }
                  footer=""
                />
              )}
              {refreshToast && (
                <ToastComponent
                  setmessagebar={setIsToast}
                  isToastEnabled={refreshToast}
                  toastIntent="success"
                  position="top-end"
                  title="Success"
                  message={'Data Table Refreshed'}
                  footer=""
                />
              )}
              {errorToast && (
                <ToastComponent
                  setmessagebar={setErrorToast}
                  toastIntent="error"
                  position="top-end"
                  title="Failed"
                  message={toastMessage}
                  footer=""
                />
              )}
            </StackItem>
          </StackItem>
          <StackItem>
            <Text></Text>
          </StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};
