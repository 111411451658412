import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getStatusActivityCntQuery, getStatusActivityQuery, getStatusSolnDetailsQuery} from "../graphql/status_queries";
import { GlobalContext } from "../hooks/globalContext";


type dataProps = {
    data?: any;
    func?: any;
    searchVal?: any
    pageNo?: any
    setTotalPages?: any
    setTotalRecords?: any
    isRefresh?: any,
    setIsRefresh?: any

    activityData?: any
    activityFunc?: any
    selectedAct?: any

}


export const Status_target_fun = (props: dataProps) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    const tenantName = contextValue.tenantName;
    const selectedActivity = props.selectedAct;

    const getStatusActivitySearch = async (whereCondition: any, time: any) => {
        try {
            const { data, loading, error } = await client.query({
                query: getStatusSolnDetailsQuery,
                variables: { whereCondition,  time},
                fetchPolicy: 'network-only',
            });

            if (!loading && data) {
                //console.log("Status target fun data****-------", data);
                props.func(data);
            }
        } catch (error) {
            console.error("Error in getStatusActivityCnt:", error);
        }
    }
    

    const getStatusActivityCnt = async (whereCondition: any, time: any) => {
        try 
        {
            const { data, loading, error } = await client.query({
            query: getStatusActivityCntQuery,
            variables: { whereCondition,  time},
            fetchPolicy: 'network-only',
        });

        if (!loading && data) {
            console.log("Status target fun data getStatusActivityCnt****-------", data);
            // props.setTotalRecords(data.core_AutomationJSON.length);
            // props.setTotalPages(Math.ceil((data.core_AutomationJSON.length)/50));
        }
    } catch (error) {
        console.error("Error in getStatusActivityCnt:", error);
    }
    }




    useEffect(() => {
        let selectedActname = selectedActivity.name;
        let selectedActTime = selectedActivity.time;
        const selectedActivityTime = parseInt(selectedActTime);
        if (props.searchVal?.length > 0 && props.searchVal !== '') { 

            getStatusActivitySearch({ automationName: { _eq: selectedActname }, MachineTag: { contains: props.searchVal }}, selectedActivityTime);
            getStatusActivityCnt({ automationName: { _eq: selectedActname }, MachineTag: { contains: props.searchVal }}, selectedActivityTime);
        }
        else {

            getStatusActivitySearch({ automationName: { _eq: selectedActname } }, selectedActivityTime);
            getStatusActivityCnt({ automationName: { _eq: selectedActname } }, selectedActivityTime);
        }

    }, [props.searchVal, props.pageNo, props.isRefresh, props.selectedAct]);

    return (
        <></>
    )
}
