import React, { useEffect ,useContext} from 'react'
import { useQuery, useApolloClient } from '@apollo/client';
import { insertFeedback } from '../../graphql/feedback_queries';
import { findSchemaDefinition } from '@rjsf/utils';
import client from '../apollo';

type dataprops={
  formData?:any
  setFormData?:any
  setFormSubmit?:any
  formSubmit?:any

}


export const Feedback_Func= (props:dataprops) => {



  const AddFeedback = async ()=>{
    let expression=props.formData.Expression;
    let experience =props.formData.Experience;
    let contact_me =props.formData.ContactMe;
    let email= props.formData.Email;
     
    const {data} =await client.mutate({
      mutation: insertFeedback,
      variables: {
        expression,
        experience,
        email,
        contact_me
      },
    });

    if (data.insert_audit_Feedback?.affected_rows > 0) {
      console.log("data inserted in feedback table")
      props.setFormSubmit(false);
    }
  
};
       

    

     useEffect(() => {   
           
      props.formSubmit && AddFeedback();
       
    },[props.formSubmit]);
   

  return (
    <>
    </>
  )
  
}