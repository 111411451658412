import { useState, useEffect } from "react";
import { Status_func } from "../../api/status_func";
import { SideMenu } from "../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../components/data_table/data_table.component";
import OverLay from "../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Subtitle2Stronger, Display, Card, Input, ButtonProps } from "@fluentui/react-components";
import { DesktopCheckmark20Regular, AlertBadge20Regular, WrenchScrewdriver20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowMaximize20Regular, DismissSquare24Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Info20Filled, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../components/dropdown/menu.component";
import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { Census_func } from "../../api/devices/census_func";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ToastComponent } from "../../components/toast/toast.component";
import { Activity_log_func } from "../../api/activity_log/activity_log_func";

import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { Status_target_fun } from "../../api/status_target_fun";
import { any } from "prop-types";
import { fullscreenIcon, primaryIcon, exitIcon, overflowDivider } from "../../styles/customStyles";
import { shouldLoadRoute } from "../../Authentication/RouteProtection";

const handleExportCSV = () => {
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Status = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [final_data2, set_final_data2]: any = useState(null);
  const [selectedActivity, setselectedActivity] = useState({ name: '', time: '' });
  const [automationName, setAutomationName] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [jsonValue, setJsonValue]: any = useState(null);
  const [automationJsonData, setAutomationJsonData] = useState<string[]>([]);
  const [final_Targetdata, set_final_Targetdata]: any = useState(null);
  const [tableData1, setTableData1] = useState([]);
  const [searchTargetVal, setSearchTargetVal] = useState("");
  const [uniqueAutomationNames, setUniqueAutomationNames] = useState<string[]>([]);
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log("entered value", enteredValue)
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  }
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }

  const handleCloseFilter = (index: any): any => {
    console.log("clicked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }

  useEffect(() => {
    if (filterArray.length > 0)
      setResetDisabled(false)

  }, [filterArray])

  var menuItems: any = [];

  //inline side menu content code starts here
  const selectedTheme: BrandVariants = {
    10: "#010404",
    20: "#0F1A1D",
    30: "#142B32",
    40: "#163842",
    50: "#164652",
    60: "#165464",
    70: "#136275",
    80: "#0C7187",
    90: "#038099",
    100: "#388DA4",
    110: "#d6d6d6",
    120: "#6FA8B9",
    130: "#86B5C4",
    140: "#9DC3CF",
    150: "#B4D1DA",
    160: "#CADFE5",
  };
  const lightTheme: Theme = {
    ...createLightTheme(selectedTheme)
  };
  const darkTheme: Theme = {
    ...createDarkTheme(selectedTheme)
  };
  darkTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground4 = "#0a0a0a";
  darkTheme.colorNeutralForeground1 = "#ffff00";

  // message bar starts here
  const messageTheme: Theme = {
    ...createLightTheme(selectedTheme),
  };
  messageTheme.colorNeutralBackground1 = "#EBF3FC";
  // message bar ends here

  //inline side menu contents start here
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/troubleshoot/alert")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <AlertBadge20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Alert </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/troubleshoot/troubleshoot")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <WrenchScrewdriver20Regular {...primaryIcon} onClick={() => navigate("/manage/troubleshoot/troubleshoot/")} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 onClick={() => navigate("/manage/troubleshoot/troubleshoot/")}> Troubleshoot </Caption1> </StackItem>
          <StackItem> <Stack tokens={{ childrenGap: 59 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
        </Stack>
      </MenuItem>
    );

  menuItems.push
    (
      <FluentProvider theme={lightTheme}>
        <MenuItem onClick={() => navigate("/manage/troubleshoot/status")}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem><Text></Text></StackItem>
              <StackItem> <DesktopCheckmark20Regular {...primaryIcon} onClick={() => navigate("/manage/troubleshoot/status")} /> </StackItem>
            </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 onClick={() => navigate("/manage/troubleshoot/status")}> Status </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      </FluentProvider>
    );
    menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/troubleshoot/compliance_policy")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <DesktopCheckmark20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Compliance Policy </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: "JobCreatedTime", label: <Body1Strong>&nbsp; Date </Body1Strong> },
    { key: "ProfileName", label: <Body1Strong> Solution </Body1Strong> },
    //{ key: "SelectionType", label: <Body1Strong> Description </Body1Strong> },
    { key: "MachineTag", label: <Body1Strong> Scope </Body1Strong> },
    { key: "AgentName", label: <Body1Strong> Initiated by </Body1Strong> },
    { key: "JobStatus", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter starts here
  var columns1 = [
    { key: "JobCreatedTime", label: <Caption1> Date </Caption1> },
    { key: "ProfileName", label: <Caption1> Solution </Caption1> },
    { key: "SelectionType", label: <Caption1> Description </Caption1> },
    { key: "MachineTag", label: <Caption1> Target </Caption1> },
    { key: "AgentName", label: <Caption1> Initiated by </Caption1> },
    { key: "JobStatus", label: <Caption1> Status </Caption1> },
  ];


  //overlay datatable code start here
  var overlayColumns = [
    { key: "dname", label: <Body1Strong> Device Name</Body1Strong> },
    { key: "status", label: <Body1Strong> Solution Status </Body1Strong> },
    { key: "exetime", label: <Body1Strong> Executed Time </Body1Strong> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => { setCurrentPageNo(1); setIsRefresh(true); }}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon} /> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          {/* <CSVLink id="csv-export-link" data={exportData} filename="troubleshootstatuslist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}

          {
            permissions.includes("status_Read") ? (
              <TooltipHost content="This feature is not available for you!">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>
            ) : permissions.includes("status_Fullaccess") || permissions.includes("status_ReadandExport") ? (
              <CSVLink id="csv-export-link" data={exportData} filename="troubleshootstatuslist.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>

            ) : null
          }
        </StackItem>


      </>
    ,
  };
  // overflow set contents code ends here

  var timer: any;
  const searchHandle = (data: any) => {
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchVal(data.target.value);
      setCurrentPageNo(1);
    }, 1500);
  };

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
  var message: any;

  useEffect(() => {
    // data table values append code starts here
    //console.log("===============final_data status========", final_data);

    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    const communicationAudits = final_data && final_data.communication_Audit;

    const uniqueCombinations = new Set();
    const repeatedRows = new Set();

    const filteredCommunicationAudits = communicationAudits && communicationAudits.filter((audit: any) => {
      const combination = `${audit.JobCreatedTime}_${audit.ProfileName}`;

      // Check if the combination has already been encountered
      if (uniqueCombinations.has(combination)) {
        repeatedRows.add(combination);
        return false;
      } else {
        uniqueCombinations.add(combination);
        return true;
      }
    });

    filteredCommunicationAudits !== undefined && filteredCommunicationAudits !== null
      ? filteredCommunicationAudits.map((data: any, ind: any) => {
        // final_data !== undefined && final_data !== null
        //   ? final_data.communication_Audit.map((data: any, ind: any) => {
        const timeProfile = `${data.JobCreatedTime}_${data.ProfileName}`;
        // console.log(`communication_Audit data==========, ${timeProfile}`);


        if (data.Communcication_Audit_Census.length > 0) {

          const profilename = data.Communcication_Audit_Census;
          const statuslen = data.Communcication_Audit_Census;
          statuslen.map((val: any, i: any) => {

            const ProfileNameval = data.ProfileName;
            const ProfileName = ProfileNameval.replace(/%20/g, ' ');
            // const MachineTag1 = repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification" ;
            // console.log("ProfileName in status-------", MachineTag1);

            const statusvalue = data.JobStatus;
            if (statusvalue === 0) {
              message = 'Pending';
            } else if (statusvalue === 2) {
              message = 'Success';
            } else if (statusvalue === 3) {
              message = 'Failed';
            } else {
              message = 'Failed';
            }


            tempExportData.push({
              JobCreatedTime: new Date(data.JobCreatedTime * 1000).toLocaleString(),
              ProfileName: ProfileName,
              //SelectionType: data.SelectionType,
              MachineTag: repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification",
              AgentName: data.AgentName,
              JobStatus: message,
              //ClientExecutedTime: new Date(data.ClientExecutedTime * 1000).toLocaleString(),
            });

            // tempTableData.push({
            //   id: val.MachineTag,
            //   checkbox: "hide",
            //   JobCreatedTime: {
            //     label:
            //       <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setselectedActivity({ name: ProfileName, time: data.JobCreatedTime }) }}>
            //         {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
            //           year: 'numeric',
            //           month: '2-digit',
            //           day: '2-digit',
            //           hour: '2-digit',
            //           minute: '2-digit',
            //           second: '2-digit',
            //           hour12: false,
            //         })}  { }
            //       </Button>, id: `${i}`
            //   },
            //   ProfileName: { label: <Caption1> {ProfileName} </Caption1>, status: "" },
            //   MachineTag: { label: <Caption1> {repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification"} </Caption1> },
            //   AgentName: { label: <Caption1> {(data.AgentName !== null && data.AgentName !== undefined && data.AgentName.length > 0) ? data.AgentName : '-'} </Caption1>, icon: "", status: "" },
            //   JobStatus: { label: <Caption1>{message} </Caption1> },
            // });




            // if (permissions.includes("status_Read") || permissions.includes("status_ReadandExport")) {
            //   tempTableData.push({
            //     id: val.MachineTag,
            //     checkbox: "hide",
            //     JobCreatedTime: {
            //       label:
            //         <TooltipHost content="This feature is not available for you!">
            //           <Button appearance="transparent" size="small">
            //             {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
            //               year: 'numeric',
            //               month: '2-digit',
            //               day: '2-digit',
            //               hour: '2-digit',
            //               minute: '2-digit',
            //               second: '2-digit',
            //               hour12: false,
            //             })}  { }
            //           </Button>
            //         </TooltipHost>, id: `${i}`
            //     },
            //     ProfileName: { label: <Caption1> {ProfileName} </Caption1>, status: "" },
            //     MachineTag: { label: <Caption1> {repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification"} </Caption1> },
            //     AgentName: { label: <Caption1> {(data.AgentName !== null && data.AgentName !== undefined && data.AgentName.length > 0) ? data.AgentName : '-'} </Caption1>, icon: "", status: "" },
            //     JobStatus: { label: <Caption1>{message} </Caption1> },
            //   });
            // } else if (permissions.includes("status_Fullaccess")) {
            //   tempTableData.push({
            //     id: val.MachineTag,
            //     checkbox: "hide",
            //     JobCreatedTime: {
            //       label:
            //         <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setselectedActivity({ name: ProfileName, time: data.JobCreatedTime }) }}>
            //           {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
            //             year: 'numeric',
            //             month: '2-digit',
            //             day: '2-digit',
            //             hour: '2-digit',
            //             minute: '2-digit',
            //             second: '2-digit',
            //             hour12: false,
            //           })}  { }
            //         </Button>, id: `${i}`
            //     },
            //     ProfileName: { label: <Caption1> {ProfileName} </Caption1>, status: "" },
            //     MachineTag: { label: <Caption1> {repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification"} </Caption1> },
            //     AgentName: { label: <Caption1> {(data.AgentName !== null && data.AgentName !== undefined && data.AgentName.length > 0) ? data.AgentName : '-'} </Caption1>, icon: "", status: "" },
            //     JobStatus: { label: <Caption1>{message} </Caption1> },
            //   });
            // }


            

            const createTableDataEntry = (hasFullAccess:any, val:any, data:any, ProfileName:any, message:any, i:any, timeProfile:any, repeatedRows:any) => {
              const jobCreatedTimeLabel = hasFullAccess ? 
                <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setselectedActivity({ name: ProfileName, time: data.JobCreatedTime }) }}>
                  {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                  })}  { }
                </Button> :
                <TooltipHost content="This feature is not available for you!">
                  <Button appearance="transparent" size="small">
                    {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false,
                    })}  { }
                  </Button>
                </TooltipHost>;
            
              return {
                id: val.MachineTag,
                checkbox: "hide",
                JobCreatedTime: {
                  label: jobCreatedTimeLabel,
                  id: `${i}`
                },
                ProfileName: { label: <Caption1>{ProfileName}</Caption1>, status: "" },
                MachineTag: { label: <Caption1>{repeatedRows.has(timeProfile) ? "All Devices" : "Device Classification"}</Caption1> },
                AgentName: { label: <Caption1>{(data.AgentName !== null && data.AgentName !== undefined && data.AgentName.length > 0) ? data.AgentName : '-'}</Caption1>, icon: "", status: "" },
                JobStatus: { label: <Caption1>{message}</Caption1> },
              };
            };
            
            if (permissions.includes("status_Read") || permissions.includes("status_ReadandExport")) {
              tempTableData.push(createTableDataEntry(false, val, data, ProfileName, message, i, timeProfile, repeatedRows));
            } else if (permissions.includes("status_Fullaccess")) {
              tempTableData.push(createTableDataEntry(true, val, data, ProfileName, message, i, timeProfile, repeatedRows));
            }


          })
        }
      }) : (tempTableData = []);

    setExportData(tempExportData);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    // Overlay activity status values append code starts here
    //console.log("final_data status overlay--------", final_data2);

    final_data2 !== undefined && final_data2 !== null
      ? final_data2 && final_data2.core_AutomationJSON.map((actVal: any, i: any) => {

        setAutomationName(actVal.automationName);
        setCreatedBy(actVal.createdBy);
        const dataObject = JSON.parse(actVal.jsonValue);
        setJsonValue(dataObject);

        if (actVal.core_AutomationJSON_Audit.length > 0) {
          const data = actVal.core_AutomationJSON_Audit
          const uniqueData = Array.from(new Set(data.map((item: any) => item.AgentUniqId))).map(agentId => {
            return data.find((item: any) => item.AgentUniqId === agentId);
          });

          setAutomationJsonData(uniqueData);
        } else {
          console.log("it's empty");
        }

      }) : (console.log("null"));
  }, [final_data2, selectedActivity, searchTargetVal]);



  useEffect(() => {
    // Overlay data table values append code starts here
    console.log("Status final_Targetdata ---------->", final_Targetdata);


    final_Targetdata !== undefined && final_Targetdata !== null
      ? final_Targetdata.automationWithCondition.map((val: any, i: any) => {

        const deviceData = val && val.core_AutomationJSON_Audit;
        let auditData = deviceData && deviceData.map((data: any, i: any) => {
          return data;
        })

        if (auditData) {
          auditData.forEach((data: any, i: any) => {
            const statusdata = data.JobStatus;
            if (statusdata === 0) {
              message = 'Pending';
            } else if (statusdata === 2) {
              message = 'Success';
            } else if (statusdata === 3) {
              message = 'Failed';
            } else {
              message = 'Failed';
            }

            tempTableData.push({
              id: data.MachineTag,
              checkbox: "hide",
              dname: { label: <Caption1> {data.MachineTag}  </Caption1>, icon: "", id: `${i}` },
              status: { label: <Caption1> {message} </Caption1>, icon: "", status: "" },
              exetime: {
                label: <Caption1> {new Date(data.ClientExecutedTime * 1000).toLocaleString('en-IN', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false,
                })} </Caption1>, icon: ""
              }
            });
          });
        }

      }) : (tempTableData = []);

    tempTableData.length > 0 ? setTableData1(tempTableData) : setTableData1([]);
  }, [final_Targetdata, selectedActivity]);



  //Solu details
  useEffect(() => {
    let automationSequencesArray: any = [];
    let automationWithoutConditionIds: any = [];
    console.log('Selected Activity final_Targetdata:', final_Targetdata);

    if (final_Targetdata !== undefined && final_Targetdata !== null && final_Targetdata.automationWithCondition) {
      final_Targetdata.automationWithCondition.forEach((val: any, i: any) => {

        const jsonData = val && val.jsonValue;
        const parsedData = JSON.parse(jsonData);
        const automationSequence = parsedData.automation_sequence;

        automationSequencesArray.push(automationSequence);
      });
    } else {
      console.log(null);
    }

    if (final_Targetdata !== undefined && final_Targetdata !== null && final_Targetdata.automationWithoutCondition) {
      automationWithoutConditionIds = final_Targetdata.automationWithoutCondition.map((val: any) => {
        return {
          automationName: val.automationName,
          id: val.id,
        };
      });
    } else {
      console.log(null);
    }
    console.log("Automation Sequences Array:", automationSequencesArray);
    // console.log("Automation Without Condition IDs:", automationWithoutConditionIds);

    const uniqueIds = new Set();
    if (automationSequencesArray.length > 0) {
      let sequenceIds = automationSequencesArray[0].split(',').map((id: any) => parseInt(id.trim()));

      //console.log("sequenceIds************", sequenceIds);

      let matchingAutomations = automationWithoutConditionIds.filter((automation: any) => sequenceIds.includes(automation.id));

      const automationNames = matchingAutomations.map((data: any) => data.automationName);

      const uniqeAutoName = new Set(automationNames);
      const uniqueAutoNames = [...(uniqeAutoName as unknown as any[])];
      //console.log("Each automationNames*****************", uniqueAutoNames);
      setUniqueAutomationNames(uniqueAutoNames);

    } else {
      console.log("automationSequencesArray is empty");
    }

  }, [final_Targetdata, selectedActivity]);






  //overlay code starts here
  var overLayData: any = {
    headerTitle:
      (
        <>
          <Stack>
            <FluentProvider theme={webLightTheme}>
              <Title3>Troubleshooter Status Details</Title3>
            </FluentProvider>
          </Stack>
        </>
      ),
    size: "large",
    isTabs: true,
    tabItems: {
      Activity:
        <Stack>
          <Stack tokens={{ childrenGap: 10, padding: 10 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem><Text></Text></StackItem>
            {automationName ? (
              <>
                <StackItem>
                  <Subtitle2Stronger>Automation Name: </Subtitle2Stronger>
                  {automationName}
                </StackItem>
                <StackItem>
                  <Subtitle2Stronger>Created By: </Subtitle2Stronger> {createdBy}
                </StackItem>
              </>
            ) : (
              ' No result! '
            )}

            {automationJsonData && automationJsonData.length > 0 && (
              <>
                <StackItem> <Subtitle2Stronger> Solution name : </Subtitle2Stronger>  {jsonValue.automation_name} </StackItem>
                <StackItem> <Subtitle2Stronger> Solution description :  </Subtitle2Stronger> {jsonValue.description} </StackItem>
                <StackItem> <Subtitle2Stronger> Category Name : </Subtitle2Stronger> {jsonValue.category} </StackItem>
                <StackItem> <Subtitle2Stronger> Category description : </Subtitle2Stronger> {jsonValue.category_description} </StackItem>
                <StackItem> <Subtitle2Stronger> Sub Category Name :  </Subtitle2Stronger>{jsonValue.sub_category} </StackItem>
                <StackItem> <Subtitle2Stronger>Sub Category description : </Subtitle2Stronger> {jsonValue.sub_category_description} </StackItem>
              </>
            )}
            <StackItem>
              <Stack tokens={{ childrenGap: 10 }}>
                {automationJsonData.map((data: any, inx: any) => {
                  const WordSelectionType = data.SelectionType.split(' ')[0];

                  return (
                    <>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Subtitle2Stronger>Job Created Time: </Subtitle2Stronger>
                          {new Date(data.JobCreatedTime * 1000).toLocaleString('en-IN', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                          })}
                        </StackItem>

                        <StackItem>
                          <Subtitle2Stronger>Scope:</Subtitle2Stronger>
                          {WordSelectionType === 'Site' ? (
                            <span> All Devices </span>
                          ) : WordSelectionType === 'Machine' ? (
                            <span> Machine</span>
                          ) : (
                            <span> Device Classification </span>
                          )}
                        </StackItem>

                        <StackItem> <Subtitle2Stronger>Agent Id:</Subtitle2Stronger> {data.AgentUniqId}
                        </StackItem>

                        <StackItem> <Subtitle2Stronger>Agent Name:</Subtitle2Stronger> {data.AgentName.length > 0 ? data.AgentName : "-"}
                        </StackItem>
                      </Stack>
                    </>
                  );
                })}
              </Stack>
            </StackItem>

          </Stack>
        </Stack>,
      Targets:
        <Stack tokens={{ childrenGap: 15 }}>
          <StackItem>
            <FluentProvider theme={messageTheme} >
              <Card appearance="subtle" size="small">
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                  <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>  </StackItem>
                </Stack>
              </Card>
            </FluentProvider>
          </StackItem>
          <StackItem>
            <StackItem>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                {/* <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/> */}
                <SearchBox onChange={(e: any) => { searchHandle(e) }} placeholder="Search" size="small" />
                <StackItem>
                  <MenuDropdown btn={
                    <MenuButton shape="circular" size="small">
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <Filter20Filled {...primaryIcon} />
                        <Body1> Filter </Body1>
                      </Stack>
                    </MenuButton>
                  } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                    <Divider />
                    <MenuList>
                      <Stack tokens={{ childrenGap: 5 }}>
                        <StackItem> <Text></Text> </StackItem>
                        <StackItem> <Caption1> Filter </Caption1> </StackItem>
                        <DropdownComponent optionData={columns} />
                        <StackItem> <Divider /> </StackItem>
                        <StackItem> <Caption1> Value </Caption1> </StackItem>
                        <DropdownComponent optionData={columns} />
                        <StackItem> <Divider /> </StackItem>
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                          <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                        </Stack>
                      </Stack>
                    </MenuList>
                  </>} />
                </StackItem>
                <StackItem>
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem> <FilterDismiss20Filled /> </StackItem>
                      <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </StackItem>
              </Stack>
            </StackItem>
          </StackItem>

          <StackItem>
            <Status_target_fun data={final_Targetdata} func={set_final_Targetdata} selectedAct={selectedActivity} searchVal={searchTargetVal} />

            {/* {tableData1.length > 0 ? (<DataTable tableData={tableData1} columns={overlayColumns} />) : <Stack horizontalAlign="center"  tokens={{childrenGap: 15, padding: 10}}><StackItem><Text size={500}>No data available</Text></StackItem></Stack>} */}
            <DataTable tableData={tableData1} columns={overlayColumns} />

          </StackItem>



        </Stack>,
      "Solution Details":
        <Stack>
          {/* HI */}

          <Stack tokens={{ childrenGap: 10, padding: 10 }}>
            <Stack tokens={{ childrenGap: 10, padding: 10 }}>
              <StackItem><Text>
                <Subtitle2Stronger>
                  Below are the automation sequence executed,
                </Subtitle2Stronger></Text></StackItem>
              <StackItem><Text></Text></StackItem>
              {uniqueAutomationNames.map((name, index) => (
                <>
                  <StackItem> <Text>&bull; {name} </Text> </StackItem>
                  <StackItem> <Text> </Text> </StackItem>
                </>
              ))}
            </Stack>
          </Stack>

        </Stack>,
    },
  };
  //overlay code ends here

  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={() => setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Troubleshooting" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>
              {/* <Text> <Breadcrumbs pageName="Troubleshooting   " /> </Text> */}
            </StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3>Troubleshooting | Status</Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem> <Text></Text> </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          {/* <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/> */}
                          <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear" />} />
                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                              <Divider />
                              <MenuList>
                                <Stack tokens={{ childrenGap: 5 }}>
                                  <StackItem> <Text></Text> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                  <StackItem> <Divider /> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns} /> */}
                                  <Input size='small' onChange={inputChange} name="filter" value={dropDownValue2} />
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <StackItem> <Button appearance="primary" onClick={() => { handleFilterArray(); setFilterMenuOpen(false) }}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>} />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item, index) => (
                              <Badge appearance="filled" color="informative">
                                <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem>{item}</StackItem>
                                  <StackItem>
                                    <Dismiss16Regular onClick={() => { handleCloseFilter(index) }}>
                                    </Dismiss16Regular>
                                  </StackItem>
                                </Stack>
                              </Badge>
                            ))}
                          </StackItem>
                          <StackItem onClick={() => { setFilterArray([]); setIsFilter(false); setDropDownValue2(''); setResetDisabled(true); }}>
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> <FilterDismiss20Filled /> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        {/* <StackItem> <Caption1>  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1> </StackItem>
                         <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} /> </StackItem>
                        {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem>

                          <Caption1> Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records </Caption1>
                        </StackItem>
                        <StackItem>

                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack> : <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>} */}

                        {tableData.length > 0
                          ?
                          <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                            <StackItem>
                              {
                                totalRecords > 0 &&
                                <Caption1>
                                  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records
                                </Caption1>
                              }
                            </StackItem>
                            <StackItem>
                              <Pagination
                                totalPages={totalPages}
                                currentPageNo={currentPageNo}
                                setCurrentPageNo={setCurrentPageNo}
                              />
                            </StackItem>
                          </Stack>
                          :
                          <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                            <StackItem><Text></Text></StackItem>
                            <StackItem> <Caption1>  </Caption1> </StackItem>
                          </Stack>
                        }
                      </Stack>

                    </StackItem>
                    <Status_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setRefreshToast={setRefreshToast} activityData={final_data2} activityFunc={set_final_data2} selectedAct={selectedActivity} isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} />

                    {tableData.length > 0 && (<DataTable tableData={tableData} columns={columns} />)}
                    {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}


                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={overLayData}
            isNavBtn={true}
          />
        </StackItem>
      </FullScreen>
    </>
  );
};
