import React from 'react';

export function validation(formRef: any) {
  if (formRef && formRef?.current) {
    // if(formRef && formRef?.current?.props?.schema?.properties?.form1 && formRef?.current?.props?.formData != null)
    // {
    //     var nextFlag =  formRef?.current?.props?.schema?.properties?.form1?.required?.filter((val: any) => formRef?.current?.props?.formData[val] == undefined);
    //     console.log("nextFlag=",nextFlag);
    //     console.log("hit here");

    //     if(nextFlag == undefined){
    //         return true
    //     }else{
    //         return nextFlag?.length === 0;
    //     }
    // }
    // else if(formRef && formRef.current.props?.schema?.items && formRef.current.props?.formData != null)
    // {
    //     var nextFlag =  formRef.current.props?.schema?.items?.allOf[0].required?.filter((val: any) => formRef.current.props?.formData[val] == undefined);
    //     console.log("nextFlag=",nextFlag);
    //     console.log("hit here");

    //     if(nextFlag == undefined){
    //         return true
    //     }else{
    //         return nextFlag?.length === 0;
    //     }
    // }
    // else{
    var nextFlag = formRef?.current?.props?.schema?.required?.filter((val: any) => formRef?.current?.props?.formData[val] == undefined);
    console.log('formRef?.current?.props?.schema?.required=', formRef?.current?.props?.formData);

    console.log('nextFlag=', nextFlag);
    if (nextFlag == undefined) {
      return true;
    } else {
      return nextFlag?.length === 0;
    }
    // }
  } else {
    var nextFlag = formRef?.current?.props?.schema?.required?.filter((val: any) => formRef?.current?.props?.formData[val] == undefined);
    console.log('nextFlag=', nextFlag);

    if (nextFlag == undefined) {
      return true;
    } else {
      return nextFlag?.length === 0;
    }
  }
  return false;
}
