import { useState, useEffect } from 'react';
import { getSitesListsQuery } from '../../graphql/home_queries';
import { useApolloClient } from '@apollo/client';

export const Tenant_func = (setOptions: any) => {
  const client = useApolloClient();

  const [myData, setMydata] = useState(null);
  const getSiteList = async () => {
    const { data, loading, error } = await client.query({
      query: getSitesListsQuery,
    });

    if (!loading && data) {
      setMydata(data.getInstallSites);
      setOptions(data.getInstallSites.slice().sort((a: any, b: any) => a.sitename.localeCompare(b.sitename)));
    }
  };

  useEffect(() => {
    getSiteList();
  }, []);

  return myData;
};
