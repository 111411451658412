import { useState, useEffect } from "react";
import { Census_func } from "../../../api/devices/census_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, ButtonProps } from "@fluentui/react-components";
import { PulseSquare20Regular, AppsAddIn20Regular, BoxCheckmark20Regular, UninstallApp20Regular, CheckboxCheckedSync20Regular, SquareHintApps20Regular, ShieldLock20Regular, BookTemplate20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, SlideEraser20Regular, Copy20Regular, AddSquare20Regular, Dismiss16Regular, ArrowMaximize20Regular, DismissSquare24Filled } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { Resize } from "../../../components/textarea/textarea.component";
import { Checked } from "../../../components/switch/switch.component";
import { CheckBox } from "../../../components/checkbox/checkbox_component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, lightTheme } from "../../../styles/customStyles";
import DialogBox from "../../../components/dialog/dialog.component";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Appuninstallstatus = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isdeletedisabled, setDeleteDisabled] = useState(false);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();

  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)


  const handleFilterArray = (): any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  useEffect(() => {
    if (filterArray.length > 0)
      setResetDisabled(false)

  }, [filterArray])

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log("entered value", enteredValue)
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  }
  const handleCloseFilter = (index: any): any => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  }

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };



  var menuItems: any = [];

  menuItems.push
    (
      <MenuItem
        onClick={() => navigate("/manage/apps/appmonitor")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <PulseSquare20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > App&nbsp;Monitored</Caption1> </StackItem>
          <StackItem> <Stack tokens={{ childrenGap: 48 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>

    );


  // menuItems.push
  // ( 


  //   <MenuItem onClick={() => navigate("/manage/apps/appinstallation")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <AppsAddIn20Regular {...primaryIcon}  /> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Installation </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>


  // );

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/apps/appinstallstatus")} icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <BoxCheckmark20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1  > App Install Status </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );

  // menuItems.push
  // (
  //   <MenuItem onClick={()=>navigate("/manage/apps/appuninstallprofile")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <UninstallApp20Regular {...primaryIcon}/> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Uninstall Profiles </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>
  // );

  menuItems.push
    (
      <FluentProvider theme={lightTheme}>

        <MenuItem onClick={() => navigate("/manage/apps/appuninstallstatus")} icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <CheckboxCheckedSync20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1 > App Uninstall Status </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      </FluentProvider>
    );
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/apps/discoveredapps")} icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <SquareHintApps20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > Discovered Apps </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
  // menuItems.push
  // (
  //   <MenuItem onClick={()=>navigate("/manage/apps/appprotectionpolicies")} icon={
  //     <Stack horizontal tokens={{childrenGap:40}}>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <ShieldLock20Regular {...primaryIcon}/> </StackItem>
  //     </Stack>}>
  //     <Stack tokens={{childrenGap:30}} horizontal>
  //       <StackItem><Text></Text></StackItem>
  //       <StackItem> <Caption1 > App Protection Policies </Caption1> </StackItem>
  //     </Stack>
  //   </MenuItem>
  // );
  menuItems.push
    (

      <MenuItem onClick={() => navigate("/manage/apps/activitylogg")} icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <BookTemplate20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > Activity Log </Caption1> </StackItem>
        </Stack>
      </MenuItem>

    );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: "device_name", label: <Body1Strong> Device Name </Body1Strong> },
    { key: "manufacturer", label: <Body1Strong> Manufacturer </Body1Strong> },
    { key: "os", label: <Body1Strong> Operating System </Body1Strong> },
    { key: "installation_date", label: <Body1Strong> Installation Date </Body1Strong> },
    { key: "nanoheal_version", label: <Body1Strong> Nanoheal Version </Body1Strong> },
    { key: "last_event", label: <Body1Strong> Last Event </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter starts here
  var columns1 = [
    { key: "names", label: <Caption1> Name </Caption1> },
    { key: "description", label: <Caption1>Description </Caption1> },
    { key: "version", label: <Caption1>Version</Caption1> },
    { key: "os", label: <Caption1>Operating System</Caption1> },
    { key: "created_on", label: <Caption1>Created On</Caption1> },
    { key: "modified_on", label: <Caption1>Modified On</Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>

        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem>
            {/* <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:6}}>
                <StackItem onClick={() => { setOverlayOpen(true); }}>
                  <Stack tokens={{childrenGap:5}} horizontal>
                    <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                    <StackItem> <Caption1> Add Classification </Caption1> </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </Button> */}
            {/* {
              permissions.includes("appUnInstall_Read") || permissions.includes("appUnInstall_ReadandExport") ? (
                <TooltipHost content="This feature is not available for you!">
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 6 }}>
                      <StackItem>
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                          <StackItem> <AddSquare20Regular {...primaryIcon} /> </StackItem>
                          <StackItem> <Caption1> Add Classification </Caption1> </StackItem>
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Button>
                </TooltipHost>
              ) : permissions.includes("appUnInstall_Fullaccess") ? (
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 6 }}>
                    <StackItem onClick={() => { setOverlayOpen(true); }}>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem> <AddSquare20Regular {...primaryIcon} /> </StackItem>
                        <StackItem> <Caption1> Add Classification </Caption1> </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              ) : null
            } */}

            {
              permissions.includes("appUnInstall_Read") || permissions.includes("appUnInstall_Fullaccess") ? (
                <Button appearance="subtle" size="small" onClick={() => {
                  if (permissions.includes("appUnInstall_Fullaccess")) {
                    setCurrentPageNo(1);
                    setOverlayOpen(true);
                  }
                }}>
                  <TooltipHost content={permissions.includes("appUnInstall_Read") && !permissions.includes("appUnInstall_Fullaccess") ? "This feature is not available for you!" : undefined}>
                    <Stack tokens={{ childrenGap: 6 }}>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem> <AddSquare20Regular {...primaryIcon} /> </StackItem>
                        <StackItem> <Caption1> Add Classification </Caption1> </StackItem>
                      </Stack>
                    </Stack>
                  </TooltipHost>
                </Button>
              ) : null
            }



          </StackItem>

          <StackItem>
            <Divider vertical />
          </StackItem>

          <StackItem>
            <DialogBox
              headerButton={
                <>
                  <Button appearance="transparent" size="small"
                    disabled={isdeletedisabled ? false : true} >
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {/* <SlideEraser20Regular {...primaryIcon} />  */}
                        {isdeletedisabled
                          ? (<SlideEraser20Regular {...primaryIcon} />)
                          : (<SlideEraser20Regular {...secondaryIcon} />)
                        }
                      </StackItem>
                      <StackItem> <Caption1> Delete </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </>
              }

              content="Kindly verify your intent to delete the automation."

              footer=
              {
                <>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Button appearance="primary" size="small">Confirm</Button>
                    </StackItem>
                    <StackItem>
                      <Button size="small">Cancel</Button>
                    </StackItem>
                  </Stack>
                </>
              }
            />
          </StackItem>

          <StackItem>
            <Divider vertical />
          </StackItem>

          {/* <Stack tokens={{childrenGap:6}}> */}
          <StackItem><Text></Text></StackItem>
          <StackItem>
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowClockwise20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Refresh </Caption1> </StackItem>
              </Stack>
            </Button>

            {/* <CSVLink id="csv-export-link" data={exportData} filename="uninstall_status_list.csv">
              <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Export </Caption1> </StackItem>
                </Stack>
              </Button>
            </CSVLink> */}

            {
              permissions.includes("appUnInstall_Read") ? (
                <TooltipHost content="This feature is not available for you!">
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                      <StackItem> <Caption1> Export </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </TooltipHost>
              ) : permissions.includes("appUnInstall_Fullaccess") || permissions.includes("appUnInstall_ReadandExport") ? (
                <CSVLink id="csv-export-link" data={exportData} filename="uninstall_status_list.csv">
                  <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                      <StackItem> <Caption1> Export </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </CSVLink>

              ) : null
            }

            {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
          </StackItem>
          {/* </Stack> */}
        </Stack>
      </>
    ,
  };
  // overflow set contents code ends here
  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=", final_data);

    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
        menuLists.push();

        tempExportData.push({
          device_name: val.host,
          manufacturer: val.site,
          os: val.os,
          osversion: val.osversion,
          nanoheal_version: val.clientversion,
          installation_date: new Date(val.born * 1000).toLocaleString(),
          last_event: new Date(val.last * 1000).toLocaleString()
        });

        // tempTableData.push({
        //   device_name: { label: <Caption1> {val.host} </Caption1>, icon: (<Badge size="extra-small" appearance="filled" color="success" > </Badge>), id: `${i}` },
        //   manufacturer: { label: <Caption1> {val.site} </Caption1>, status: "" },
        //   os: { label: <Caption1> {val.os} </Caption1> },
        //   osversion: { label: <Caption1> {val.clientversion} </Caption1> },
        //   nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1> },
        //   installation_date: { label: <Caption1> {val.born} </Caption1> },
        //   last_event: { label: <Caption1> {val.last} </Caption1> },
        // });

        if (permissions.includes("appUnInstall_Read")|| permissions.includes("appUnInstall_ReadandExport")) {
          tempTableData.push({
            checkbox:"hide",
            device_name: { label: <Caption1> {val.host} </Caption1>, icon: (<Badge size="extra-small" appearance="filled" color="success" > </Badge>), id: `${i}` },
            manufacturer: { label: <Caption1> {val.site} </Caption1>, status: "" },
            os: { label: <Caption1> {val.os} </Caption1> },
            osversion: { label: <Caption1> {val.clientversion} </Caption1> },
            nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1> },
            installation_date: { label: <Caption1> {val.born} </Caption1> },
            last_event: { label: <Caption1> {val.last} </Caption1> },
          });

        } else if (permissions.includes("appUnInstall_Fullaccess") ) {
          tempTableData.push({
            device_name: { label: <Caption1> {val.host} </Caption1>, icon: (<Badge size="extra-small" appearance="filled" color="success" > </Badge>), id: `${i}` },
            manufacturer: { label: <Caption1> {val.site} </Caption1>, status: "" },
            os: { label: <Caption1> {val.os} </Caption1> },
            osversion: { label: <Caption1> {val.clientversion} </Caption1> },
            nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1> },
            installation_date: { label: <Caption1> {val.born} </Caption1> },
            last_event: { label: <Caption1> {val.last} </Caption1> },
          });
        }

      }) : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here
  var overLayData: any = {
    headerTitle: "Temp File Clean Up",
    size: "large",
    isTabs: true,
    tabItems: {
      Introduction:
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            Introduction
          </Stack>
        </>,

      Configuration:
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            Configuration
          </Stack>
        </>,
    },
  };

  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={() => setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <StackItem>
              <Stack verticalAlign="center">
                <Stack tokens={{ childrenGap: 25 }} horizontal>
                  <StackItem> <Text></Text> </StackItem>
                  <StackItem>
                    <FluentProvider theme={webLightTheme}>
                      <Title3> Apps | App Uninstall Status </Title3>
                    </FluentProvider>
                  </StackItem>
                </Stack>

                <Stack tokens={{ childrenGap: 20 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>

                <FluentProvider theme={webLightTheme}>
                  <Stack horizontal>
                    {/* SPACING BETWEEN INLINE AND PAGE */}
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                    <StackItem>
                      <StackItem> <Text></Text> </StackItem>
                      <Stack tokens={{ childrenGap: 1 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                          {tableData.length > 0 ? <Divider /> : <Divider style={{ width: "68vw" }} />}
                        </StackItem>
                        <StackItem>

                          <StackItem>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                              <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear" />} />
                              <StackItem>
                                <MenuDropdown open={filterMenuOpen} btn={
                                  <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <Filter20Filled {...primaryIcon} />
                                      <Body1> Filter </Body1>
                                    </Stack>
                                  </MenuButton>
                                } itemVal={
                                  <>
                                    <Body1Strong> Add a Filter </Body1Strong>
                                    <Divider />
                                    <MenuList>
                                      <Stack tokens={{ childrenGap: 5 }}>
                                        <StackItem> <Text></Text> </StackItem>
                                        <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                        <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem> <Divider /> </StackItem>
                                        <StackItem> <Caption1> Value </Caption1> </StackItem>
                                        <Input size='small' onChange={inputChange} name="filter" value={dropDownValue2} />
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem> <Divider /> </StackItem>
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                          <StackItem> <Button appearance="primary" onClick={() => { handleFilterArray(); setFilterMenuOpen(false) }}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                          <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                        </Stack>
                                      </Stack>
                                    </MenuList>
                                  </>
                                } />
                              </StackItem>
                              <StackItem>
                                {filterArray.length > 0 && filterArray.map((item, index) => (
                                  <Button size="small" shape="circular">
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem> {item} </StackItem>
                                      <StackItem>
                                        <Stack tokens={{ childrenGap: 1 }}>
                                          <StackItem><Text></Text></StackItem>
                                          <StackItem><Dismiss16Regular onClick={() => { handleCloseFilter(index) }} /></StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </Button>
                                ))}
                              </StackItem>
                              {/* <StackItem>
                        {filterArray.length > 0 && filterArray.map((item,index) => (
                          <Button size="small" shape="circular">
                            <Stack tokens={{childrenGap:5}} horizontal>
                               <StackItem> {item} </StackItem>
                              <StackItem>
                                <Stack tokens={{childrenGap:1}}>
                                  <StackItem><Text></Text></StackItem>
                                  <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                </Stack>
                              </StackItem>      
                            </Stack>
                          </Button>
                        ))}
                      </StackItem> */}
                              <StackItem onClick={() => { setFilterArray([]); setIsFilter(false); setDropDownValue2(''); setResetDisabled(true); }}>
                                <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> <FilterDismiss20Filled /> </StackItem>
                                    <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>

                          <Stack tokens={{ childrenGap: 10 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                          {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                            <StackItem>
                              <Caption1> Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records </Caption1>
                            </StackItem>
                            <StackItem>

                              <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                            </StackItem>
                          </Stack> : <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>}
                        </StackItem >

                        {tableData.length > 0 && (<DataTable tableData={tableData} columns={columns} ></DataTable>)}
                      </Stack>
                    </StackItem>
                  </Stack>
                </FluentProvider>
              </Stack>
              <OverLay
                overlayopen={overlayOpen}
                setOverlayOpen={setOverlayOpen}
                overlayData={overLayData}
              />
            </StackItem>
          </StackItem>
          <StackItem><Text></Text></StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};