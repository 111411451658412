import { gql } from '@apollo/client';

// USers| All Users
// list of all USers

const getUsersListsQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int!, $offset: Int! = 0) {
    core_Users(where: $whereCondition, limit: $limit, offset: $offset) {
      firstName
      lastName
      user_email
      userType
      userStatus
      userid
      passwordDate
      modifiedOn
      Created_on
    }
  }
`;

//Query to get the individual data from the id for edit purpose
const getUserDetailsQuery = gql`
  query getUserFormData($userId: Int!) {
    core_Users(where: { userid: { _eq: $userId } }) {
      formDataEdit
    }
  }
`;
const getUsersListsCntQuery = gql`
  query MyQuery($whereCondition: JSON) {
    core_Users(where: $whereCondition) {
      firstName
      lastName
      user_email
      userType
      userStatus
      userid
      passwordDate
      modifiedOn
      Created_on
    }
  }
`;

// query to add userdetails
const addUserQuery = gql`
  mutation MyMutation(
    $firstName: String
    $lastName: String
    $userEmail: String
    $timeZone: String
    $securityType: String
    $formDataEdit: String
    $Created_on: String
    $userType: String
    $role_assigned: String
  ) {
    insert_core_Users(
      objects: {
        firstName: $firstName
        lastName: $lastName
        user_email: $userEmail
        timezone: $timeZone
        securityType: $securityType
        formDataEdit: $formDataEdit
        Created_on: $Created_on
        userType: $userType
        role_assigned: $role_assigned
      }
    ) {
      affected_rows
      returning {
        lastName
        firstName
      }
    }
  }
`;
const updateUserQuery = gql`
  mutation updateUserDetails(
    $rowId: Int!
    $firstName: String
    $lastName: String
    $userEmail: String
    $timeZone: String
    $securityType: String
    $formDataEdit: String
    $role_assigned: String
    $modifiedOn: String
  ) {
    update_core_Users(
      where: { userid: { _eq: $rowId } }
      _set: {
        firstName: $firstName
        lastName: $lastName
        user_email: $userEmail
        timezone: $timeZone
        securityType: $securityType
        formDataEdit: $formDataEdit
        role_assigned: $role_assigned
        modifiedOn: $modifiedOn
      }
    ) {
      returning {
        firstName
        lastName
        user_email
      }
    }
  }
`;
const deleteUserQuery = gql`
  mutation deleteUser($_eq: [Int!] = []) {
    delete_core_Users(where: { userid: { _in: $_eq } }) {
      affected_rows
      returning {
        userid
      }
    }
  }
`;

const getUserQuery = gql`
  query MyQuery($userEmail: String) {
    core_Users(where: { user_email: { _eq: $userEmail } }) {
      userid
    }
  }
`;

const userActiveQuery = gql`
  mutation MyMutation($email: String) {
    update_core_Users(where: { user_email: { _eq: $email } }, _set: { userStatus: "1" }) {
      returning {
        user_email
      }
    }
  }
`;
const userEmailQuery = gql`
  query MyQuery($userid: Int) {
    core_Users(where: { userid: { _eq: $userid } }) {
      user_email
    }
  }
`;

export {
  getUsersListsQuery,
  addUserQuery,
  updateUserQuery,
  getUsersListsCntQuery,
  getUserDetailsQuery,
  getUserQuery,
  userActiveQuery,
  deleteUserQuery,
  userEmailQuery,
};
