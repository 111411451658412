import { Stack, StackItem } from '@fluentui/react';
import {
  Text,
  Body1,
  Popover,
  PopoverTrigger,
  Button,
  PopoverSurface,
  Divider,
  Avatar,
  Card,
  Body1Stronger,
  Body1Strong,
  Image,
  Tooltip,
  FluentProvider,
  webLightTheme,
  Textarea,
  Caption1,
  Caption2,
} from '@fluentui/react-components';
import {
  Alert20Regular,
  Settings20Regular,
  ChevronDown20Regular,
  SignOut20Regular,
  ChatBubblesQuestion20Regular,
  PersonFeedback20Regular,
  EmojiSmileSlight24Filled,
  EmojiSmileSlight24Regular,
  EmojiSad24Filled,
  EmojiSad24Regular,
} from '@fluentui/react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { CheckBox } from '../checkbox/checkbox_component';
import { GlobalContext } from '../../hooks/globalContext';
import OverLay from '../side_menu/overlay_side_menu.component';
import { primaryIcon } from '../../styles/customStyles';
import { Feedback_Func } from '../../api/feedback/feedback_func';

type propsTypes = { pageName: any };

const Header = (props: propsTypes) => {
  const navigate = useNavigate();
  const location = useLocation();
  const contextValue: any = useContext(GlobalContext);
  const value = sessionStorage.getItem('userEmail');
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [isChecked, setIsChecked]: any = useState(false);
  const [feedbackUnsatisfied, setFeedbackUnsatisfied]: any = useState(true);
  const [feedbackSatisfied, setFeedbackSatisfied]: any = useState(true);
  const [formData, setFormData]: any = useState({ Experience: '', Expression: '', ContactMe: 'No', Email: '' });
  const [formSubmit, setFormSubmit]: any = useState(false);
  // const userEmail = contextValue.userEmail;
  // const [dashboardLocation, setDashboardLocation] = useState(''); // CAN REMOVE THIS:- But if you do not want buttons to work in Dashboard, the buttons are in line 153, 159, 165, and 171 respectively.

  formData.Email = value;
  const handleChange = (e: any) => {
    setFormData((prevData: any) => (e.target.name === 'experience' ? { ...prevData, Experience: e.target.value } : prevData));
    console.log(e.target.value);
  };
  console.log('Form Data: ', formData);
  formData.Expression = feedbackUnsatisfied ? 'Satisfied' : feedbackSatisfied ? 'Unsatisfied' : null;

  const handleSignOut = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const handleWebCheckbox = (e: any) => {
    const isCheck = e.target.checked;
    setIsChecked(isCheck);
    setFormData((prevData: any) => ({ ...prevData, ContactMe: isCheck ? 'Yes' : 'No' }));
  };

  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function avatarText(string: any) {
    return string.charAt(0).toUpperCase();
  }
  const userName = `${
    contextValue.userSessionDetails.userName.toLowerCase() === 'admin'
      ? 'Admin'
      : `${capitalizeFirstLetter(contextValue.userSessionDetails.userName)} ${capitalizeFirstLetter(contextValue.userSessionDetails.userLName)}`
  }`;
  const userEmail = contextValue.userSessionDetails.userEmail;
  const userAvatar = `${
    contextValue.userSessionDetails.userName.toLowerCase() === 'admin'
      ? avatarText('Admin')
      : `${avatarText(contextValue.userSessionDetails.userName)} ${avatarText(contextValue.userSessionDetails.userLName)}`
  }`;

  const headerVisibility = ['/', '/signin', '/auth/reset-password', '/signup', '/generatepassword', '/auth/change-password'].includes(
    location.pathname,
  );

  var overLayData: any = {
    isTabs: false,
    size: overlayName == 'notification' ? 'small' : 'medium',
    headerTitle: overlayName == 'notification' ? 'Notifications' : 'Feedback',

    bodyData:
      overlayName == 'notification' ? (
        <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
          <StackItem>
            <Image src="/visualization/v2/image/notificationBell.svg" />
          </StackItem>
          <StackItem>
            <Body1Stronger> No new notifications from this session </Body1Stronger>
          </StackItem>
          <StackItem>
            <Body1> More Events in the Activity Log </Body1>
          </StackItem>
        </Stack>
      ) : (
        <form>
          <Stack tokens={{ childrenGap: 10 }}>
            <StackItem>
              <Body1> Thank you for taking the time to give us feedback. </Body1>
            </StackItem>
            <Divider />
            <StackItem>
              <Caption1> Are you Satisfied with your experience? </Caption1>
            </StackItem>
            <StackItem>
              <Stack tokens={{ childrenGap: 10 }} horizontal>
                <Tooltip
                  withArrow
                  appearance="inverted"
                  content={feedbackSatisfied ? 'Satisfied' : 'Satisfied'}
                  positioning="below"
                  relationship="label"
                >
                  <Button
                    appearance="transparent"
                    icon={feedbackSatisfied ? <EmojiSmileSlight24Regular {...primaryIcon} /> : <EmojiSmileSlight24Filled {...primaryIcon} />}
                    onClick={() => {
                      setFeedbackSatisfied(!feedbackSatisfied);
                      setFeedbackUnsatisfied(true);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  withArrow
                  appearance="inverted"
                  content={feedbackUnsatisfied ? 'Not Satisfied' : 'Not Satisfied'}
                  positioning="below"
                  relationship="label"
                >
                  <Button
                    appearance="transparent"
                    icon={feedbackUnsatisfied ? <EmojiSad24Regular {...primaryIcon} /> : <EmojiSad24Filled {...primaryIcon} />}
                    onClick={() => {
                      setFeedbackUnsatisfied(!feedbackUnsatisfied);
                      setFeedbackSatisfied(true);
                    }}
                  />
                </Tooltip>
              </Stack>
            </StackItem>
            <Textarea
              name="experience"
              size="large"
              placeholder="Tell us about your experience..."
              rows={10}
              onChange={e => {
                handleChange(e);
              }}
              value={formData.Experience}
            />
          </Stack>
        </form>
      ),

    footerData: !(overlayName == 'notification') ? (
      <Stack tokens={{ childrenGap: 10 }}>
        <CheckBox
          size="large"
          type="checkbox"
          label="It's Ok to contact me about my feedback"
          checked={isChecked}
          onChange={e => handleWebCheckbox(e)}
        />
        <Caption2> By pressing submit, you agree that your feedback will be used by us to improve our services and products. </Caption2>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem>
            <Button
              shape="square"
              appearance="primary"
              type="submit"
              disabled={(feedbackSatisfied && feedbackUnsatisfied) || formData.Experience == ''}
              onClick={() => {
                setOverlayOpen(false);
                setFormSubmit(true);
              }}
            >
              Submit
            </Button>
          </StackItem>
          <StackItem>
            <Button
              shape="square"
              appearance="secondary"
              onClick={() => {
                setOverlayOpen(false);
                setIsChecked(false);
              }}
            >
              Cancel
            </Button>
          </StackItem>
        </Stack>
      </Stack>
    ) : (
      <></>
    ),
  };

  // const handleSubmitFeedback = () => { /* Assuming you're using fetch API to send a POST request to your backend */ fetch('/api/send-feedback-email', { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ /* You can include any data related to the feedback in the request body */ })}) .then(response => response.ok ? response : Promise.reject('Failed to send feedback')) /* Handle successful response if needed */ .catch(error => console.error('Error sending feedback:', error)); /* Handle error if needed */ };

  // useEffect(() => {setDashboardLocation(location.pathname)}, [location]); /* CAN REMOVE THIS:- But if you do not want buttons to work in Dashboard, the buttons are in line 153, 159, 165, and 171 respectively. */
  // const handleHeaderButtons = () => { if (dashboardLocation !== '/Dashboard') { navigate("/manage/administration/administration"); } }; /* CAN REMOVE THIS:- But if you do not want buttons to work in Dashboard, the buttons are in line 153, 159, 165, and 171 respectively. */

  return (
    <>
      {!headerVisibility ? (
        <Stack>
          <Stack verticalAlign="center" tokens={{ childrenGap: 5 }}>
            <Stack horizontal horizontalAlign="space-between" verticalFill enableScopedSelectors>
              <StackItem align="center">
                <Stack tokens={{ childrenGap: 20, padding: 10 }} horizontal>
                  <StackItem>
                    <Body1> Nanoheal Management Portal </Body1>
                  </StackItem>
                  <StackItem>
                    <Divider vertical />
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem>
                <Stack horizontal>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 10, padding: 8 }} horizontal>
                      <StackItem>
                        <Tooltip withArrow appearance="inverted" content="Click Here to check Notifications" relationship="label">
                          <Button
                            appearance="transparent"
                            icon={<Alert20Regular />}
                            onClick={() => {
                              setOverlayOpen(true);
                              setOverlayName('notification');
                            }}
                          />
                          {/* <Button appearance="transparent" icon={<Alert20Regular/>} onClick={() => {setOverlayOpen(true); }} disabled={dashboardLocation === '/Dashboard'}/> */}
                        </Tooltip>
                      </StackItem>
                      <StackItem>
                        <Tooltip withArrow appearance="inverted" content="Administration" relationship="label">
                          <Button
                            appearance="transparent"
                            icon={<Settings20Regular />}
                            onClick={() => navigate('/manage/administration/administration')}
                          />
                          {/* <Button appearance="transparent" icon={<Settings20Regular/>} onClick={handleHeaderButtons} disabled={dashboardLocation === '/Dashboard'}/> */}
                        </Tooltip>
                      </StackItem>
                      <StackItem>
                        <Tooltip withArrow appearance="inverted" content="Submit Feedback" relationship="label">
                          <Button
                            appearance="transparent"
                            icon={<PersonFeedback20Regular />}
                            onClick={() => {
                              setOverlayOpen(true);
                              setOverlayName('feedback');
                            }}
                          />
                          {/* <Button appearance="transparent" icon={<PersonFeedback20Regular/>} onClick={handleHeaderButtons} disabled={dashboardLocation === '/Dashboard'}/> */}
                        </Tooltip>
                      </StackItem>
                      <StackItem>
                        <Tooltip withArrow appearance="inverted" content="Contact Support" relationship="label">
                          <Button
                            appearance="transparent"
                            icon={<ChatBubblesQuestion20Regular />}
                            onClick={() => window.open('https://www.nanoheal.com/blank', '_blank')}
                          />
                          {/* <Button appearance="transparent" icon={<ChatBubblesQuestion20Regular/>} onClick={handleHeaderButtons} disabled={dashboardLocation === '/Dashboard'}/> */}
                        </Tooltip>
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 4 }}>
                    <StackItem>
                      <Text />
                    </StackItem>
                    <StackItem>
                      <Text align="center">
                        <Popover>
                          <PopoverTrigger disableButtonEnhancement>
                            <Button appearance="transparent">
                              <Stack horizontal tokens={{ childrenGap: 10, padding: 2 }}>
                                <StackItem>
                                  <Body1> Welcome, {userName} </Body1>
                                </StackItem>
                                <StackItem>
                                  <ChevronDown20Regular />
                                </StackItem>
                              </Stack>
                            </Button>
                          </PopoverTrigger>
                          <FluentProvider theme={webLightTheme}>
                            <PopoverSurface tabIndex={-1}>
                              <Card appearance="filled-alternative">
                                {/* <Stack>
                                    <StackItem grow shrink>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                        <StackItem grow shrink> <Stack horizontalAlign="start"> <Body1> Set Time Zone </Body1> </Stack> </StackItem>
                                        <StackItem grow shrink> <Stack horizontalAlign="end"> <Body1> View Account </Body1> </Stack> </StackItem> 
                                      </Stack>
                                    </StackItem>
                                  </Stack> */}
                                <Stack tokens={{ childrenGap: 15, padding: 10 }} horizontal>
                                  <StackItem>
                                    <Avatar
                                      name={userAvatar}
                                      size={48}
                                      active="active"
                                      color="colorful"
                                      activeAppearance="ring-shadow"
                                      badge={{ status: 'available' }}
                                    />
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Body1Stronger> {userName} </Body1Stronger>
                                      </StackItem>
                                      <StackItem>
                                        <Body1Strong> {userEmail} </Body1Strong>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                                <Stack horizontal>
                                  <StackItem grow>
                                    <Stack horizontalAlign="start">
                                      <Button onClick={() => navigate('/auth/change-password')}> Change Password </Button>
                                    </Stack>
                                  </StackItem>
                                  <StackItem grow>
                                    <Stack horizontalAlign="end">
                                      <Button onClick={handleSignOut} icon={<SignOut20Regular />} iconPosition="after">
                                        Sign out
                                      </Button>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Card>
                            </PopoverSurface>
                          </FluentProvider>
                        </Popover>
                      </Text>
                    </StackItem>
                  </Stack>
                </Stack>
              </StackItem>
            </Stack>
          </Stack>
          <Feedback_Func formData={formData} setFormData={setFormData} setFormSubmit={setFormSubmit} formSubmit={formSubmit} />
          <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
