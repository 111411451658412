import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery } from "../../graphql/configuration_queries";
import { getAutoHealListsQuery } from "../../graphql/autoheal_queries";
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
}

const Autoheal_func = (props: any) => {
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;

  const deleteUnattachedAutomation = async (ids: any) => {
    var dartid = parseInt(dartNo);
    const { data } = await client.mutate({
      mutation: deleteUnattachedAutomationQuery,
      variables: {ids, dartid},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteAutomation(false);
      props.setIsToast(true);
      getAutoHealList({dartid: {_eq: dartid}, types: {_eq: 'autoheal'}}, 50, parseInt(props.pageNo));
    }
  }

  const getAutoHealList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      if(props.overlayName == 'editAutomation'){
        console.log("edited value=",data.core_AutomationJSON[0]);
        
        props.setFormData({...props.formData,
          name: data.core_AutomationJSON[0]?.automationName,
          description: data.core_AutomationJSON[0]?.description,
          jsonData: JSON.parse(data.core_AutomationJSON[0]?.jsonValue)
        });
      }else {
        props.func(data);
      }
    }
  };

  const getAutoHealListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON?.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON?.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON?.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };

  const getJsonSchema = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchema = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);

      data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchema);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
    }
  };

  const addAutomation = async (automationName: String, description: String, jsonVal: String, createdBy: String, dartid: number) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);
    var types: any = 'autoheal';

    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      props.setSaveBtnClicked(false);
      getAutoHealList({dartid: {_eq: dartid}, types: {_eq: 'autoheal'}}, 50, parseInt(props.pageNo));
    }
  };

  const editAutomation = async (automationName: String, description: String, jsonVal: String, rowID: Number, dartid: Number) => {
    const lastModified: any = Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      props.setSaveBtnClicked(false);
      getAutoHealList({dartid: {_eq: dartid}, types: {_eq: 'autoheal'}}, 50, parseInt(props.pageNo));
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };

  const automationSearch = async (whereCondition: any, limit: any, offset: any) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var jsonVal = props.jsonSchema;
      
      data.core_AutomationJSON.forEach((val: any) => {
        !(jsonVal.properties?.AutomationExecute?.examples.includes(val.automationName)) && jsonVal.properties?.AutomationExecute?.examples.push(val.automationName);
      });
    }
  }
  
  useEffect(() => {    
    var dartid = parseInt(dartNo);

    if(props.overlayName == '' && props.isRefresh == false && props.deleteAutomation == false){
      getJsonSchema(parseInt(dartNo));
      getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
      props.setIsRefresh(false); 
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }
    if(props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
      getAutoHealList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
      getAutoHealListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    }else if(props.isFilter == true){
      if(props.dropDownValue1 == 'Name'){
        getAutoHealList({dartid: {_eq: dartid}, automationName: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutoHealListCnt({dartid: {_eq: dartid}, automationName: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Created by'){
        getAutoHealList({dartid: {_eq: dartid}, createdBy: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutoHealListCnt({dartid: {_eq: dartid}, createdBy: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Status'){
        getAutoHealList({dartid: {_eq: dartid}, status: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutoHealListCnt({dartid: {_eq: dartid}, status: {contains: props.dropDownValue2}});
      }
    }
    else if(props.deleteAutomation && contextValue.selectedRowIdInTable.length > 0){
      console.log("delete func calling");
      
      deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
      props.overlayName == 'addAutomation' && addAutomation(props.newData.name, props.newData.description, props.newData.jsonData, userName, dartNo);
      props.overlayName == 'editAutomation' && editAutomation(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID, dartNo);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editAutomation' && props.automationSearchVal?.length < 1){
        getAutoHealList({dartid: {_eq: dartNo}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else if(props.automationSearchVal?.length > 0 && props.automationSearchVal !== ''){
      automationSearch({types: {_eq: 'automation'}, automationName: {contains: props.automationSearchVal}}, null, null);
    }else{
      props.overlayName != 'addAutomation' && getAutoHealList({dartid: {_eq: dartid}, types: {_eq: 'autoheal'}}, 50, parseInt(props.pageNo));
      props.overlayName == '' && getAutoHealListCnt({dartid: {_eq: dartid}, types: {_eq: 'autoheal'}});
    }
   
  },[props.deleteAutomation, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo, props.automationSearchVal, props.isFilter]);
  return (
    <></>
  )
}

export { Autoheal_func }
