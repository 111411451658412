import React, { useState, useEffect, useContext } from "react";
import { getTroubleshooterQuery, getTroubleshooterCntQuery, setExecuteTroubleShooter } from "../graphql/troubleshoot_queries";
import { getJsonSchemaQuery } from "../graphql/configuration_queries";
import { useQuery, useApolloClient } from "@apollo/client";
import { GlobalContext } from "../hooks/globalContext";
import { Link } from "react-router-dom";


type dataProps = {
    data: any;
    func: any;
    searchVal: any;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    executeBtnClicked: any;
    setExecuteBtnClicked: any;
    selectedAuto: any;
    setmessagebar: any;
    setmessage: any;
    deviceCnt: any;
    isRefresh?: any,
    setIsRefresh?: any
    setRefreshToast?: any
    setSubAutomationDetails: any
    setJsonData: any
    setUiData: any
    setExportDetails: any
    isFilter?: any
    dropDownValue1?: any
    setIsFilter?: any
    dropDownValue2?: any
}

export const Troubleshooter_func = (props: dataProps) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    const tenantName = contextValue.tenantName;
    const selectedIDs = contextValue.selectedRowIdInTable;

    //console.log("executeBtnClicked----------", props.executeBtnClicked);


    let profile = props.selectedAuto.name;
    profile = profile.toString();
    const profileName = profile.replace(/\s/g, '');
    var automationDesc = props.selectedAuto.desc;

    const getshooterList = async (whereCondition: any, limit: any, offset: any, order: any, listType: String = '') => {
        const { data, loading, error } = await client.query({
            query: getTroubleshooterQuery,
            variables: { whereCondition, limit, offset, order },
            fetchPolicy: 'network-only'
        });

        if (!loading && data) {
            if (listType === 'subAutomation') {
                data.core_AutomationJSON.map(async (data: any) => {
                    await getJsonSchema(data.dartid);
                })
                props.setSubAutomationDetails(data);
            } else {
                props.func(data);
            }
        }
    };

    const getJsonSchema = async (dartid: number) => {
        const { data, loading, error } = await client.query({
            query: getJsonSchemaQuery,
            variables: { dartid },
            fetchPolicy: 'network-only',
        });

        if (!loading && data) {
            var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
            uiSchema["ui:submitButtonOptions"] = {
                "norender": true,
            };

            var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);

            data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData((prev: any) => { return { ...prev, [`${dartid}`]: jsonSchemaData } });

            data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData((prev: any) => { return { ...prev, [`${dartid}`]: uiSchema } });
        }
    };

    const getTroubleshooterCnt = async (whereCondition: any) => {
        const { data, loading, error } = await client.query({
            query: getTroubleshooterCntQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only'
        });

        if (!loading && data) {
            props.setExportDetails && props.setExportDetails(data.core_AutomationJSON);
            props.setTotalRecords(data.core_AutomationJSON?.length);
            props.setTotalPages(Math.ceil((data.core_AutomationJSON?.length) / 50));
        }
    };



    const troubleshooter_exec = async (userId:any, Dart: any, name: any, NotificationWindow: any, OS: any, ProfileName: any, ProfileOS: any, shortDesc: any, type: any, Jobtype: any, variable: any) => {
        const { data } = await client.mutate({
            mutation: setExecuteTroubleShooter,
            variables: { userId, OS, type, name, Dart, variable, shortDesc, Jobtype, ProfileName, NotificationWindow, ProfileOS },
            fetchPolicy: 'network-only'
        });


        if (data?.AddRemoteJobs?.length > 0) {

            if (
                data.AddRemoteJobs[0].code === 'ok' &&
                data.AddRemoteJobs[0].onlineOffline === 'Online'
            ) {
                const troubleshootingLink = <Link to="/manage/troubleshoot/status">Troubleshooting Status</Link>;
                props.setmessage(
                    <>Troubleshooter Executed Successfully. Please check {troubleshootingLink} for detailed information on the execution status.</>
                );
            } else if (data.AddRemoteJobs[0].code === 'ok' && data.AddRemoteJobs[0].onlineOffline === 'Offline') {
                props.setmessage("Troubleshooter Pushed Successfully");
            }

            props.setmessagebar(true);
            props.setExecuteBtnClicked(false);
        } else {
            props.setmessagebar(true);
            props.setExecuteBtnClicked(false);
        }
    }


    useEffect(() => {
        if (props.searchVal?.length > 0 && props.searchVal !== '') {

            getshooterList({ automationName: { contains: props.searchVal }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" })
            getTroubleshooterCnt({ automationName: { contains: props.searchVal }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
        }
        else if (props.isFilter == true) {
            if (props.dropDownValue1 == 'Name') {
                getshooterList({ automationName: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" })
                getTroubleshooterCnt({ automationName: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
            } else if (props.dropDownValue1 == 'Description') {
                getshooterList({ description: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" })
                getTroubleshooterCnt({ description: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
            } else if (props.dropDownValue1 == 'Category') {
                getshooterList({ category: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" })
                getTroubleshooterCnt({ category: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
            } else if (props.dropDownValue1 == 'Sub Category') {
                getshooterList({ subCategory: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" })
                getTroubleshooterCnt({ subCategory: { contains: props.dropDownValue2 }, types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
            }
        }


        else if (props.executeBtnClicked) {

            if (Array.isArray(selectedIDs)) {
                const selectedIDsLen = selectedIDs.length;
                let type: any;
                // console.log("selectedIDsLen------------", selectedIDsLen);

                //if (selectedIDsLen != null) {
                if (selectedIDsLen != null && selectedIDsLen !== undefined) {
                    // if (selectedIDsLen == 1) {
                    //     type = "ServiceTag";
                    // }
                    // else if (selectedIDsLen == props.deviceCnt) {
                    //     type = "Groups";
                    // }
                    // else {
                    //     type = "Sites";
                    // }
                    type = "ServiceTag";

                    var uniqueSelectedIDs = new Set(selectedIDs);
                    var selectedIDsArr = Array.from(uniqueSelectedIDs);

                    //var userId = 235
                    selectedIDsArr.map(async (val: any) => {
                        await troubleshooter_exec(235, 286, val, 0, "unknown", profile, "common", automationDesc, type, "Interactive", profileName);
                    });
                }
                else {
                    // console.log("selectedIDsLen is null or undefined.");
                }
            } else {
                //console.log("selectedIDs is not an array.");
            }
        } else if (props.isRefresh) {
            props.setIsRefresh(false);
            getshooterList({ types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" });
            getTroubleshooterCnt({ types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
            props.setRefreshToast(true);

            setTimeout(() => {
                props.setRefreshToast(false);
            }, 5000);

        } else if (props.selectedAuto.name.length > 0) {
            getshooterList({ id: { _in: props.selectedAuto.subAutomation.split(",").map(Number) } }, null, null, { automationName: "desc" }, 'subAutomation');
        } else {
            getshooterList({ types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } }, 50, parseInt(props.pageNo), { automationName: "desc" });
            getTroubleshooterCnt({ types: { _in: ["selfhelp", "proactive"] }, status: { contains: `${tenantName}:` } });
        }
    }, [props.searchVal, props.pageNo, props.executeBtnClicked, props.isRefresh, props.selectedAuto, props.isFilter]);


    return (
        <></>
    )
}
