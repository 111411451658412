
import { Stack, StackItem } from '@fluentui/react';
import { FluentProvider, webLightTheme, Card, Divider, Title3, SkeletonItem } from '@fluentui/react-components';
import { homeImage, skeleton1, skeleton2, skeleton3, parentHome, childHome, dividerStyle, skeleton7, } from '../../styles/customStyles';

export const SkeletonHome = (props: any) => {
  return (
    <>
      <FluentProvider theme={webLightTheme} style={parentHome}>
          <Stack tokens={{padding:15}}> <Title3> Nanoheal </Title3> </Stack>
          <Stack tokens={{childrenGap:10}} horizontalAlign="center" horizontal style={parentHome}>
            <Card size='large' style={homeImage}>
              <Stack tokens={{childrenGap:10, padding:10}} style={parentHome} verticalAlign="space-between">
                <StackItem>
                  <Stack tokens={{childrenGap:15}}>
                    <StackItem grow shrink>
                      <Stack tokens={{childrenGap:10}} horizontal>
                        <StackItem grow shrink>
                          <Stack horizontalAlign="start"> <StackItem> <SkeletonItem size={36} style={skeleton1}/> </StackItem> </Stack>
                        </StackItem>
                        <StackItem grow shrink>
                          <Stack horizontalAlign="end"> <StackItem> <SkeletonItem size={36} style={skeleton2}/> </StackItem></Stack>
                        </StackItem> 
                      </Stack>
                    </StackItem>
                    <StackItem> <SkeletonItem size={32} style={skeleton7}/> </StackItem>
                    <StackItem>
                      <Stack tokens={{childrenGap:10}} horizontal>
                        <StackItem> <SkeletonItem size={28} style={skeleton1}/> </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Stack tokens={{childrenGap:10}} horizontalAlign="center" horizontal>
                    <Card style={childHome}>
                      <Stack> <StackItem> <SkeletonItem size={24} style={skeleton3}/> </StackItem> </Stack>
                      <Stack tokens={{childrenGap:20}} horizontal>
                        <StackItem grow shrink>
                          <Stack tokens={{childrenGap:10}} horizontal>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{childrenGap:10}}>
                                    <StackItem>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                         <StackItem><Stack horizontal style={dividerStyle}><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                                        <StackItem>
                                          <Stack tokens={{childrenGap:2}}>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                         <StackItem><Stack horizontal style={dividerStyle}><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                                        <StackItem>
                                          <Stack tokens={{childrenGap:2}}>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                         <StackItem><Stack horizontal style={dividerStyle}><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                                        <StackItem>
                                          <Stack tokens={{childrenGap:2}}>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{childrenGap:10}}>
                                    <StackItem>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                         <StackItem><Stack horizontal style={dividerStyle}><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                                        <StackItem>
                                          <Stack tokens={{childrenGap:2}}>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{childrenGap:10}} horizontal>
                                         <StackItem><Stack horizontal style={dividerStyle}><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                                        <StackItem>
                                          <Stack tokens={{childrenGap:2}}>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                            <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Card>
                    <Card style={childHome}>
                      <Stack> <StackItem> <SkeletonItem size={24} style={skeleton3}/> </StackItem> </Stack>
                      <Stack tokens={{childrenGap:10}} horizontal>
                        <StackItem grow shrink>
                          <Stack tokens={{childrenGap:10}} horizontal>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{childrenGap:10}}>
                                    <StackItem> <SkeletonItem size={24} style={skeleton2}/> </StackItem>
                                    <StackItem> <SkeletonItem size={96} style={skeleton2}/> </StackItem>
                                    <StackItem> <SkeletonItem size={24} style={skeleton2}/> </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <StackItem><Stack horizontal style={dividerStyle} horizontalAlign="center"><Divider vertical/><Divider vertical/><Divider vertical/></Stack></StackItem>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{childrenGap:10}}>
                                    <StackItem> <SkeletonItem size={24} style={skeleton2}/> </StackItem>
                                    <StackItem> <SkeletonItem size={96} style={skeleton2}/> </StackItem>
                                    <StackItem> <SkeletonItem size={24} style={skeleton2}/> </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Card>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Card>
                    <StackItem> <SkeletonItem size={24} style={skeleton3}/> </StackItem>
                    <Stack tokens={{childrenGap:10}} horizontalAlign="center" horizontal>
                      <Card style={childHome}>
                        <Stack tokens={{childrenGap:10}} horizontal>
                          <StackItem> <SkeletonItem size={40} shape="square"/> </StackItem>
                          <StackItem>
                            <Stack tokens={{childrenGap:5}}>
                              <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                              <StackItem> <SkeletonItem size={40} style={skeleton2}/> </StackItem>
                            </Stack>
                          </StackItem>
                        </Stack>
                      </Card>
                      <Card style={childHome}>
                        <Stack tokens={{childrenGap:10}} horizontal>
                          <StackItem> <SkeletonItem size={40} shape="square"/> </StackItem>
                          <StackItem>
                            <Stack tokens={{childrenGap:5}}>
                              <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                              <StackItem> <SkeletonItem size={40} style={skeleton2}/> </StackItem>
                            </Stack>
                          </StackItem>
                        </Stack>
                      </Card>
                      <Card style={childHome}>
                        <Stack tokens={{childrenGap:10}} horizontal>
                          <StackItem> <SkeletonItem size={40} shape="square"/> </StackItem>
                          <StackItem>
                            <Stack tokens={{childrenGap:5}}>
                              <StackItem> <SkeletonItem size={20} style={skeleton2}/> </StackItem>
                              <StackItem> <SkeletonItem size={40} style={skeleton2}/> </StackItem>
                            </Stack>
                          </StackItem>
                        </Stack>
                      </Card>
                    </Stack>
                  </Card>
                </StackItem>                
              </Stack>
            </Card>
          </Stack>
          <Card size="small" appearance="subtle" style={{width:'98.9vw'}} />
        </FluentProvider>
    </>
  );
};