import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import AppConfig from '../utility/AppConfig';

function isLocalhost() {
  return window.location.hostname === 'localhost';
}

export const SuperTokenConfig: any = {
  appInfo: {
    // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
    appName: 'react_exp',
    apiDomain: isLocalhost() ? `http://localhost:3000` : AppConfig.DASHBOARD_V2_API_URL,
    websiteDomain: isLocalhost() ? `http://localhost:3000` : AppConfig.DASHBOARD_V2_WEBSITE_DOMAIN,
    apiBasePath: '/visualization/v2/auth',
    websiteBasePath: '/visualization/v2/auth',
  },
  recipeList: [
    EmailPassword.init(),
    Session.init(),
    //EmailPassword.init(), // initializes signin / sign up features,
    // ThirdPartyEmailPassword.init()
    // ThirdPartyEmailPassword.init({/*TODO: See next step*/}),
  ],
};
