import { useState, useEffect, useContext } from "react";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular, PhoneLaptop20Regular, BookNumber20Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { ToastComponent } from "../../../components/toast/toast.component";
import { GlobalContext } from "../../../hooks/globalContext";
import { FileDownloadComponent } from "../../../components/fileExport/file.export.component";
import { fullscreenIcon, lightTheme, primaryIcon, exitIcon, overflowDivider } from "../../../styles/customStyles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";
 
const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const DeviceClassificationAuditlog = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const[isRefresh,setRefresh]:any =useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [isToast, setIsToast] = useState(false);
  const [overlayData, setOverlayData] = useState<any>(false);
  const [activityExportData, setActivityExportData] = useState<any>('No data available');
  
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
  useEffect(() => {
    if(filterArray.length > 0)
    setResetDisabled(false)
   
   }, [filterArray])
 
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
 
  var menuItems: any = [];
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification/devices")}
      icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <PhoneLaptop20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Devices </Caption1> </StackItem>
        <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification/scores")}
      icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookNumber20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Scores </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/manage/devices/device_classification/audit_log")}
        icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Audit Log </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "date", label: <Body1Strong> Date </Body1Strong>  },
    { key: "page", label: <Body1Strong> Module </Body1Strong> },
    // { key: "os", label: <Body1Strong> Scope </Body1Strong> },
    { key: "activity", label: <Body1Strong> Activity </Body1Strong> },
    { key: "initiated_by", label: <Body1Strong> Initiated by </Body1Strong> },
    { key: "action", label: <Body1Strong> Action </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter starts here
  var columns1 = [
    { key: "page", label: <Caption1> Module </Caption1> },
    { key: "os", label: <Caption1> Scope </Caption1> },
    { key: "", label: <Caption1> Target </Caption1>  },
    { key: "status", label: <Caption1> Status </Caption1> },
    { key: "initiated_by", label: <Caption1> Initiated by </Caption1> },
    { key: "date", label: <Caption1> Date </Caption1>  },
    { key: "activity", label: <Caption1> Activity </Caption1> },
  ];
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setRefresh(true);}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          {
            permissions.includes("deviceClassification_Read") ? (
              <TooltipHost content="This feature is not available for you!">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>
            ) : permissions.includes("deviceClassification_Fullaccess") || permissions.includes("deviceClassification_ReadandExport") ? (

              <CSVLink id="csv-export-link" data={exportData} filename="device_classification_auditlog.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>
            ) : 
              <TooltipHost content="This feature is not available for you!">
                  <Button appearance="subtle" size="small" disabled>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                      <StackItem> <Caption1> Export </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </TooltipHost>
           }
          {/* <CSVLink id="csv-export-link" data={exportData} filename="device_classification_auditlog.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];
  var menuLists: any = [];

  useEffect(() => {    
    var tempExportData: any = [];
    
    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      if(val.pageName === 'Devices'){
        tempExportData.push({
          Date: new Date(val.time * 1000).toLocaleString(),
          Page_Name: val.pageName,
          Activity: val.activity,
          Initiated_by: val.initiatedBy,
          Action: val.action,
          Status: val.status,
        });
      }
    });

    setExportData(tempExportData);
  }, [exportDetails]);
 
  const [activity, setActivity]: any = useState('{}');
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=", final_data);

    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        console.log("role ------------>>", role);
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions)
          console.log('Route loaded successfully.', permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data.audit_ActivityLog.map((val: any, i: any) => {
        // var activityVal = JSON.parse(val.activity).summary;
        if (val.pageName === 'Devices') {
          console.log("val--------->", val);
          const parsedActivity = JSON.parse(val.activity);
          const activity = parsedActivity.summary;
          //console.log("activitySummary", activitySummary);

          // tempTableData.push({
          //   id: val.ID,
          //   checkbox: "hide",
          //   date: { label: <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}> {val.time ? new Date(val.time * 1000).toLocaleString() : ''} </Button>, id: `${i}` },
          //   page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
          //   activity: { label: <Caption1> val.activity </Caption1> },
          //   initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
          //   action: { label: <Caption1> {val.action} </Caption1> },
          //   status: { label: <Caption1> {val.status} </Caption1> },
          // });

          if (permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_ReadandExport")) {
            tempTableData.push({
              id: val.ID,
              checkbox: "hide",
              date: {
                label:
                  <TooltipHost content="This feature is not available for you!">
                    <Button appearance="transparent" size="small"> {val.time ? new Date(val.time * 1000).toLocaleString() : ''} </Button>
                  </TooltipHost>, id: `${i}`
              },
              page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
              activity: { label: <Caption1> {activity} </Caption1> },
              initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
              action: { label: <Caption1> {val.action} </Caption1> },
              status: { label: <Caption1> {val.status} </Caption1> },
            });
          } else if (permissions.includes("deviceClassification_Fullaccess")) {
            tempTableData.push({
              id: val.ID,
              checkbox: "hide",
              date: { label: <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}> {val.time ? new Date(val.time * 1000).toLocaleString() : ''} </Button>, id: `${i}` },
              page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
              activity: { label: <Caption1> {activity} </Caption1> },
              initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
              action: { label: <Caption1> {val.action} </Caption1> },
              status: { label: <Caption1> {val.status} </Caption1> },
            });
          }

          // const dateLabel = (val:any, i:any) => {
          //   if (permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_ReadandExport")) {
          //     return (
          //       <TooltipHost content="This feature is not available for you!"> 
          //         <Button appearance="transparent" size="small"> {val.time ? new Date(val.time * 1000).toLocaleString() : ''} </Button> 
          //       </TooltipHost>
          //     );
          //   } else if (permissions.includes("deviceClassification_Fullaccess")) {
          //     return (
          //       <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}> {val.time ? new Date(val.time * 1000).toLocaleString() : ''} </Button>
          //     );
          //   }
          // };

          // tempTableData.push({
          //   id: val.ID,
          //   checkbox: "hide",
          //   date: { label: dateLabel(val, i), id: `${i}` },
          //   page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
          //   activity: { label: <Caption1> {val.activity} </Caption1> },
          //   initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
          //   action: { label: <Caption1> {val.action} </Caption1> },
          //   status: { label: <Caption1> {val.status} </Caption1> },
          // });



        }
      }) : (tempTableData = []);
    
      tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    var data = '';

    data = `Activity
            \nDate and time of activity - ${overlayData?.time ? new Date(overlayData?.time *1000).toLocaleString() : ''}
            \nModule where the activity was performed - ${overlayData?.pageName}
            \nActivity - ${activity?.summary}
            \nInitiated by - ${overlayData?.initiatedBy}
            \nAction - ${overlayData?.action}
            \nStatus - ${overlayData?.status}`;

    if (overlayData?.pageName === "Devices"){
      if(overlayData.action === 'Edit'){
        data += `\n----------------------------------------\nTarget
                \nClassification name - ${activity.name}
                \nOperation type - ${activity.type}
                \nTotal number of devices modified - ${activity.deviceCnt}`;

        data += `\n----------------------------------------\nModified Properties
                \nDevices Names
                ${JSON.parse(overlayData.activity).deviceList.split(",").map((val: any) => `\n${val}` )}`;
      }else{
        data += `\n----------------------------------------\nTarget
              \nNot applicalble`;

        data += `\n----------------------------------------\nModified Properties
                \nNot applicalble`;
      }
    }

    setActivityExportData(data);
  }, [overlayData]);
  
  
  //overlay code starts here
  var overLayData: any = {
    headerTitle:
    (
     <>
      <Stack>
       <Text size={600} weight="semibold"> Audit Log Details <FileDownloadComponent data={activityExportData} fileName="Activity_log" /></Text>
      </Stack>
     </>
    ),
    size: "large",
    isTabs: true,
    tabItems: {Activity:
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15, padding:10}}>
          <StackItem> 
            <Body1Strong> Date and time of activity </Body1Strong> - <Caption1> {overlayData?.time ? new Date(overlayData?.time *1000).toLocaleString() : ''} </Caption1> 
          </StackItem>

          <StackItem> 
            <Body1Strong> Module where the activity was performed  </Body1Strong> - <Caption1>{overlayData?.pageName}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Activity </Body1Strong> - <Caption1>{activity?.summary}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Initiated By </Body1Strong> - <Caption1>{overlayData?.initiatedBy}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Action </Body1Strong> - <Caption1>{overlayData?.action}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Status </Body1Strong> - <Caption1>{overlayData?.status}</Caption1>
          </StackItem>

        </Stack>
      </FluentProvider>},
      Targets: '',
      "Modified Properties": ''
  };
  //overlay code ends here

  if (overlayData?.pageName === "Devices"){
    if(overlayData.action === 'Edit'){
      overLayData.tabItems.Targets = 
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15, padding:10}}>
          <StackItem> 
            <Body1Strong> Classification name </Body1Strong> - <Caption1> {activity.name} </Caption1> 
          </StackItem>
          
          {activity.type && <StackItem> 
            <Body1Strong> Operation type </Body1Strong> - <Caption1> {activity.type} </Caption1> 
          </StackItem>}

          <StackItem> 
            <Body1Strong> Total number of devices modified </Body1Strong> - <Caption1> {activity.deviceCnt} </Caption1> 
          </StackItem>

          <StackItem> 
            <Body1Strong> List of devices </Body1Strong> - <Button size="small" onClick={() => {localStorage.setItem('classificationName',activity.name); contextValue.setSelectedRowDetails({...contextValue.selectedRowDetails, name:activity.name}); navigate("/manage/devices/device_classification/devices") }}> Click here </Button> 
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems['Modified Properties'] = 
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15, padding:10}}>
          <DataTable tableData={JSON.parse(overlayData.activity).deviceList.split(",").map((val: any, i: any) => {return (
            { id: i,
              checkbox: "hide",
              deviceName: {label: <Caption1> {val} </Caption1>, status: ""}
            }
          )})}
          columns={[{ key: "deviceName", label: <Body1Strong> Device Name </Body1Strong>}]} />
        </Stack> 
      </FluentProvider>
    }else{
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15, padding:10}}>
          <StackItem>
            Not applicalble
          </StackItem>  
        </Stack> 
      </FluentProvider>

      overLayData.tabItems['Modified Properties'] = <FluentProvider theme={webLightTheme}>
      <Stack tokens={{childrenGap:15, padding:10}}>
        <StackItem>
          Not applicalble
        </StackItem>  
      </Stack> 
      </FluentProvider>
    }
  }
 
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="Device Classification" subPageLink="/manage/devices/device_classification" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button 
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Devices | Audit Log </Title3>  
                </FluentProvider>
              </StackItem>
          </Stack>
 
          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
         
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                  { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                </StackItem>
                <StackItem>
                <StackItem>
                      <Stack horizontal tokens={{childrenGap:20}}>
                        <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                        <StackItem>
                          <MenuDropdown open={filterMenuOpen} btn=
                          {
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <Filter20Filled {...primaryIcon}/>
                                <Body1> Filter </Body1>
                              </Stack>
                            </MenuButton>
                          } itemVal = {
                          <>
                            <Body1Strong> Add a Filter </Body1Strong>
                            <Divider/>
                            <MenuList>
                              <Stack tokens={{childrenGap:5}}>
                                <StackItem> <Text></Text> </StackItem>
                                <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                {/* <DropdownComponent optionData={columns} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} /> */}
                                <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/>
                                <StackItem> <Divider/> </StackItem>
                                <StackItem> <Caption1> Value </Caption1> </StackItem>
                                {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                <StackItem> <Divider /> </StackItem>
                                <Stack tokens={{childrenGap:10}} horizontal>
                                  <StackItem> <Button appearance="primary" onClick={() => {handleFilterArray(); setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                  <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                </Stack>
                              </Stack>
                            </MenuList>
                          </>
                          }
                          />
                        </StackItem>
                         <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                        <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                          <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                            <Stack tokens={{childrenGap:5}} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                    {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> {totalRecords > 0 && <Caption1>  Showing {((currentPageNo-1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1>} </StackItem> 
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                      </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Text size={400}>No Results</Text></StackItem></Stack>}
                </StackItem>
                
                <Activity_log_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} type="getLog" moduleName="Devices" setExportDetails={setExportDetails} setRefresh={setRefresh} isRefresh={isRefresh} setRefreshToast={setRefreshToast} />

                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
                {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider>
        </Stack>
        <OverLay
        overlayopen={overlayOpen}
        setOverlayOpen={setOverlayOpen}
        overlayData={overLayData}/>
      </StackItem>
      <StackItem><Text></Text></StackItem>
      </Stack>
    </FullScreen>
    </>
  );
};