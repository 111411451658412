import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery } from "../../graphql/configuration_queries";

import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
}

const Selfhelp_func = (props: any) => {
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;

  const deleteUnattachedAutomation = async (ids: any) => {
    var dartid = parseInt(dartNo);
    const { data } = await client.mutate({
      mutation: deleteUnattachedAutomationQuery,
      variables: {ids, dartid},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteAutomation(false);
      props.setIsToast(true);
      getAutomationList({types: {_in: ['selfhelp','proactive']}}, 50, parseInt(props.pageNo));
    }
  }

  const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null, type: any = '') => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      if(props.overlayName == 'editAutomation'){
        if(type == 'subAutomation'){
          var subAutomationNames: any = [];
          var subAutoamtionDataObj: any = {};

          data.core_AutomationJSON.map((val: any) => {
            subAutomationNames.push(val.automationName);
            subAutoamtionDataObj[`${val.automationName}`] = val.id;
          })
          
          props.setAutomationSearchDataObj(subAutoamtionDataObj);
          props.setSelectedOptions(subAutomationNames); 
        }else{
          console.log("setConfigFormData func called");
          
          props.setFormData({...props.formData,
            name: data.core_AutomationJSON[0]?.automationName,
            description: data.core_AutomationJSON[0]?.description,
            jsonData: data.core_AutomationJSON[0]?.jsonValue
          });
  
          var jsonVal = JSON.parse(data.core_AutomationJSON[0]?.jsonValue);
          props.setConfigFormData({
              clientEnabled: jsonVal.clientEnabled,
              automation_name: jsonVal.automation_name,
              description: jsonVal.description,
              category: jsonVal.category,
              category_description: jsonVal.category_description,
              sub_category: jsonVal.sub_category,
              sub_category_description: jsonVal.sub_category_description,
              os: jsonVal.os,
              automation_sequence: jsonVal.automation_sequence,
          });
          
          props.setSelectedCategoryOptions([jsonVal?.category]);
          props.setSelectedSubCategoryOptions([jsonVal?.sub_category]);

          await getAutomationList({id: {_in: jsonVal.automation_sequence.split(",").map(Number)}}, null, null, 'subAutomation');
        }
      }else {
        props.func(data);
      }
    }
  };

  const getAutomationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON?.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };

//   const getJsonSchema = async (dartid: number) => {
//     const { data, loading, error } = await client.query({
//       query: getJsonSchemaQuery,
//       variables: {dartid},
//       fetchPolicy: 'network-only',
//     });

//     if(!loading && data){
//       var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
//       uiSchema["ui:submitButtonOptions"] = {
//        "norender": true,
//       };

//       var jsonSchema = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);

//       data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchema);
//       data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
//     }
//   };

  const addAutomation = async (automationName: String, description: String, jsonVal: any, createdBy: String, dartid: number) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    var types: any = jsonVal.clientEnabled ? 'selfhelp' : 'proactive';
    var category: any = jsonVal.category;
    var subCategory: any = jsonVal.sub_category;
    var jsonValue = JSON.stringify(jsonVal);

    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid, category, subCategory}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
        props.setOverlayName(false);
      }, 2000);
      getAutomationList({types: {_in: ['selfhelp','proactive']}}, 50, parseInt(props.pageNo));
    }
  };

  const editAutomation = async (automationName: String, description: String, jsonVal: any, rowID: Number, dartid: Number) => {
    console.log("editAutomation called=",rowID);
    
    const lastModified: any = Math.floor(Date.now() / 1000);
    var category: any = jsonVal.category;
    var subCategory: any = jsonVal.sub_category;
    var jsonValue = JSON.stringify(jsonVal);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid, category, subCategory}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
        props.setOverlayName('');
        getAutomationList({types: {_in: ['selfhelp','proactive']}}, 50, parseInt(props.pageNo));
      }, 2000);
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };

  const automationSearch = async (whereCondition: any, limit: any, offset: any, type: String = 'automation') => {
    console.log("automation search func called");
    
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    var searchDataArr: any = [];
    var searchDataObj: any = {};
    var tempCatObj: any = {};
    var tempSubCatObj: any = {};

    if(!loading && data.core_AutomationJSON.length > 0){
      searchDataObj = props.automationSearchDataObj;

      data.core_AutomationJSON.forEach(async (val: any) => {
        console.log("search val=",val);
        var res: any = type == 'automation' ? val.automationName : type == 'category' ? val.category : type == 'subCategory' ? val.subCategory : val.automationName;
        
        if(type == 'automation'){
          searchDataObj[`${val.automationName}`] = val.id;
        }
        
        if(type == 'category'){
          var jsonVal = JSON.parse(val.jsonValue);
          tempCatObj[`${val.category}`] = {desc: jsonVal?.category_description, types: val?.types};
        }
            
        if(type == 'subCategory'){
          var jsonVal = JSON.parse(val.jsonValue);
          tempSubCatObj[`${val.subCategory}`] = {desc: jsonVal?.sub_category_description, types: val?.types};
        }
        
        await searchDataArr.push(res);
      });
      type == 'automation' && props.setAutomationSearchData(searchDataArr);
      type == 'automation' && props.setAutomationSearchDataObj(searchDataObj);
      
      type == 'category' && props.setCategorySearchData(searchDataArr);
      type == 'category' && props.setCategoryData(tempCatObj);
      type == 'category' && props.setDisableCatInput(true);

      type == 'subCategory' && props.setSubCategorySearchData(searchDataArr);
      type == 'subCategory' && props.setSubCategoryData(tempSubCatObj);
      type == 'subCategory' && props.setDisableSubCatInput(true);
    }else{
      type == 'automation' && props.setAutomationSearchData(searchDataArr);
      type == 'automation' && props.setAutomationSearchDataObj(searchDataObj);
      
      type == 'category' && props.setCategorySearchData([props.categorySearchVal]);
      type == 'category' && props.setCategoryData(tempCatObj);
      type == 'category' && props.setDisableCatInput(false);

      type == 'subCategory' && props.setSubCategorySearchData([props.subCategorySearchVal]);
      type == 'subCategory' && props.setSubCategoryData(tempSubCatObj);
      type == 'subCategory' && props.setDisableSubCatInput(false);
    }
  }
  
  useEffect(() => {    
    var dartid = parseInt(dartNo);
    console.log("entering func=",dartid);

    if(props.overlayName == '' && props.isRefresh == false && props.deleteAutomation == false ){
        //getJsonSchema(parseInt(dartNo));
        getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
        props.setRefresh(false); 
    }

    if(props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
      getAutomationList({automationName: {contains: props.searchVal}, types: {_in: ['selfhelp','proactive']}}, 50, parseInt(props.pageNo));
      getAutomationListCnt({automationName: {contains: props.searchVal}, types: {_in: ['selfhelp','proactive']}});
    }else if(props.deleteAutomation && contextValue.selectedRowIdInTable.length > 0){
      deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
      props.overlayName == 'addAutomation' && addAutomation(props.newData.name, props.newData.description, props.newData.jsonData, userName, dartNo);
      
      props.overlayName == 'editAutomation' && editAutomation(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID, dartNo);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editAutomation' && props.automationSearchVal?.length < 1){
      getAutomationList({dartid: {_eq: dartNo}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else if(props.automationSearchVal?.length > 0 && props.automationSearchVal !== ''){
      automationSearch({types: {_in: ['automation']}, automationName: {contains: props.automationSearchVal}}, null, null,'automation');
    }else if(props.categorySearchVal?.length > 0 && props.categorySearchVal !== ''){
        automationSearch({types: {_in: ['selfhelp','proactive']}, category: {contains: props.categorySearchVal}}, null, null, 'category');
    }else if(props.subCategorySearchVal?.length > 0 && props.subCategorySearchVal !== ''){
        automationSearch({types: {_in: ['selfhelp','proactive']}, subCategory: {contains: props.subCategorySearchVal}}, null, null, 'subCategory');
    }else{
        console.log("else");
        
      props.overlayName != 'addAutomation' && getAutomationList({id: {_eq: props.rowID}, types: {_in: ['selfhelp','proactive']}}, 50, parseInt(props.pageNo));

      props.overlayName == '' && getAutomationListCnt({types: {_in: ['selfhelp','proactive']}});
    } 
  },[props.deleteAutomation, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo, props.automationSearchVal, props.categorySearchVal, props.subCategorySearchVal, props.formData, contextValue.dartNo]);

  return (
    <></>
  )
}

export { Selfhelp_func }
