import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';
import { Stack } from '@fluentui/react';
import { GlobalContext } from '../../hooks/globalContext';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { iframeStyle } from '../../styles/customStyles';

export const DashboardLists = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [urlSrc, setUrlSrc] = useState('#');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [name, setDashboardName]: any = useState('');

  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    var dashboardName = searchParams.get('name');
    setDashboardName(dashboardName);
    var url = '';
    /**
     * @todo: https://nanoheal.atlassian.net/browse/NCP-3207
     * Use method `getMenuList` and take data from db core.UserDashboards
     * Use var `window.APP_ENV.METABASE_SITE_URL` as metabase host name
     */
    if (dashboardName == 'Application Health') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/56c122c1-2112-4893-a795-61f98afe46e8';
    } else if (dashboardName == 'Business Applications Experience') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/38eced85-12b5-44b8-b771-0193081cb2bb';
    } else if (dashboardName == 'System CPU Utilization') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/1871791b-08f4-44a7-b8c6-523a36b6344b';
    } else if (dashboardName == 'Process Memory Utilization') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/9943125b-4151-483b-ad5a-ce74ce3a646e';
    } else if (dashboardName == 'Process CPU Utilization') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/5d501e85-291d-4143-86b7-8ca07f2e8947';
    } else if (dashboardName == 'System Memory Utilization') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/497ba6ea-9ec4-4576-a453-423ca2ee4839';
    } else if (dashboardName == 'Digital Experience') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/a574de66-338b-46a1-a96b-b01016e438ac';
    } else if (dashboardName == 'Device Experience') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/db2aa178-97d8-45b9-bc25-fba00238257e';
    } else if (dashboardName == 'Automation Analytics') {
      url = 'https://us-east-metabase.nanoheal.app/public/dashboard/70bdb7d9-9102-439e-96d0-67512ae41d2f';
    } else if (dashboardName == 'Automation Overview') {
      url =
        'https://lightdash.nanoheal.work/projects/5aef0f73-dd9e-4ce7-8445-23ebfc46ae43/dashboards/ec1ed751-0a79-4a49-9375-4577683fc47b/view?filters=%7B%22dimensions%22%3A%5B%7B%22id%22%3A%22395ebd1b-043e-4171-a318-1fd30e2e0246%22%2C%22label%22%3A%22Date+Filter%22%2C%22target%22%3A%7B%22fieldId%22%3A%22t1_d312_dart286_v1_servertime_day%22%2C%22tableName%22%3A%22t1_d312_dart286_v1%22%7D%2C%22values%22%3A%5B%222024-04-03%22%5D%2C%22operator%22%3A%22lessThanOrEqual%22%2C%22tileTargets%22%3A%5B%222de2aee6-da12-456d-b396-73e02bc70140%22%2C%2233947154-78b7-44ec-9aaa-2ef9829d890e%22%2C%22700490de-aa03-435b-b43c-806e15eca087%22%2C%228a4796f1-5477-4ce7-adb0-7878311bb036%22%2C%2299260cd3-8119-49e7-ad1c-6ef8b9f72a13%22%2C%229af1fe4f-fbf2-4d14-b9c9-111a904c6dab%22%2C%22d8350dd7-8474-4cb1-8734-0dcfa8fc756f%22%2C%2264d2c22c-c0ef-4a15-8944-06f1bd6aad0f%22%2C%222c8ef886-50c0-431f-88ea-558004570afa%22%2C%22b42c039a-0787-494a-a554-5798e8eedadd%22%5D%7D%2C%7B%22id%22%3A%22f251d4cf-9793-4abd-866f-281e889836a2%22%2C%22target%22%3A%7B%22fieldId%22%3A%22t1_d312_dart286_v1_customer%22%2C%22tableName%22%3A%22t1_d312_dart286_v1%22%7D%2C%22values%22%3A%5B%22Accenture_Testing%22%5D%2C%22operator%22%3A%22equals%22%2C%22tileTargets%22%3A%5B%222de2aee6-da12-456d-b396-73e02bc70140%22%2C%2233947154-78b7-44ec-9aaa-2ef9829d890e%22%2C%22700490de-aa03-435b-b43c-806e15eca087%22%2C%228a4796f1-5477-4ce7-adb0-7878311bb036%22%2C%2299260cd3-8119-49e7-ad1c-6ef8b9f72a13%22%2C%229af1fe4f-fbf2-4d14-b9c9-111a904c6dab%22%2C%22d8350dd7-8474-4cb1-8734-0dcfa8fc756f%22%2C%2264d2c22c-c0ef-4a15-8944-06f1bd6aad0f%22%2C%222c8ef886-50c0-431f-88ea-558004570afa%22%2C%22b42c039a-0787-494a-a554-5798e8eedadd%22%5D%7D%5D%2C%22metrics%22%3A%5B%5D%7D';
    }
    setUrlSrc(url);
  });

  return (
    <Stack tokens={{ childrenGap: 10 }} horizontalAlign="start">
      <Breadcrumbs pageName={name} subPageName="" subPageLink={`/investigate/dashboardlists?name=${name}`} />
      <iframe src={urlSrc} style={iframeStyle} allowTransparency />
    </Stack>
  );
};
