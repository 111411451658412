import { useState, useEffect, useContext, useRef } from 'react';
import { validation } from '../../../utility/validation';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  MessageBar,
  Card,
  Input,
  ButtonProps,
  Tooltip,
} from '@fluentui/react-components';
import {
  PulseSquare20Regular,
  AppsAddIn20Regular,
  BoxCheckmark20Regular,
  UninstallApp20Regular,
  CheckboxCheckedSync20Regular,
  SquareHintApps20Regular,
  ShieldLock20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  AddSquare20Regular,
  SlideEraser20Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  Info20Filled,
  Dismiss16Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { Install_Status_func } from '../../../api/app_install_status_func';
import { TroubleshooterDevice_fun } from '../../../api/troubleshooterDevice_fun';
import { DataTableDevice } from '../../../components/data_table/data_table.Device';
import { Census_func } from '../../../api/devices/census_func';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { RJSFSchema } from '@rjsf/utils';
import { CustomWidgets } from '../../../components/form-components';
import validator from '@rjsf/validator-ajv8';
import { Form, Widgets } from '@rjsf/fluent-ui';
import { Users_func } from '../../../api/users_func';
import { isNullLiteral } from '@babel/types';
import { Tenant_func } from '../../../api/home/tenant_func';
import { getSitesListsQuery } from '../../../graphql/home_queries';
import { useApolloClient } from '@apollo/client';
import DialogBox from '../../../components/dialog/dialog.component';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, lightTheme, overflowDivider } from '../../../styles/customStyles';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';
import { getRolePermission } from '../../../graphql/admin_queries';

// import { Resize } from "../../../components/textarea/textarea.component";
// import { Checked } from "../../../components/switch/switch.component";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const AllUsers = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [final_data1, set_final_data1]: any = useState(null);
  const [deviceList, setDeviceList]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [deviceName, setDeviceName] = useState('');
  const [rowID, setRowID]: any = useState(null);
  const [rowDesc, setRowDesc]: any = useState(null);
  const [formDataUser, setFormDataUser] = useState(null);
  const [currentSelectedUserRole, setCurrentSelectedUserRole] = useState<string | null>(null);
  const [saveUserDetails, setSaveUserDetails] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [deleteUser, setdeleteUser]: any = useState(false);
  const [deleteUserToast, setdeleteUserToast]: any = useState(false);
  const [emailIdSupertoken, setEmailIdSuperToken] = useState(null);
  const [isForm, setIsForm] = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [permissions, setPermissions] = useState(['']);
  const [uischema, setUischema] = useState({});
  console.log('checking the permissions------>', permissions);

  const formRef = useRef(null);

  // const [isSaveEnabled, setButtonEnabled] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const handle = useFullScreenHandle();
  //get the SiteList from the db
  const client = useApolloClient();
  const [siteList, setSiteList] = useState([]);
  const getSiteList = async () => {
    const { data, loading, error } = await client.query({
      query: getSitesListsQuery,
    });

    if (!loading && data) {
      setSiteList(data.getInstallSites);
    }
  };

  const [roleList, setRoleList] = useState([]);
  const getRoles = async () => {
    const { data, loading, error } = await client.query({
      query: getRolePermission,
    });

    if (!loading && data) {
      setRoleList(data.supertokens_roles);
      console.log('data is here', data);
    }
  };

  const roleEnum = roleList?.map((role: any) => role?.role);
  const roleEnumNames = roleList?.map((role: any) => role?.role);

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  //for popup message
  const handleOnCLickEdit = (val: any) => {
    setRowID(val.userid);
    setOverlayName('edit');
    setOverlayOpen(true);
    setReadOnly(true);
  };
  //for saving user details
  const saveBtnFunc = () => {
    if (formDataUser == null) {
      //alert("alert form is empty")
    }
    // else{

    overlayName == 'edit' ? setEditUserDetails(true) : setSaveUserDetails(true);
    // setIsToast(true)

    // }
    setOverlayOpen(false);
  };

  const handleEditData = (data: any) => {
    const userDetails = JSON.parse(data.core_Users[0].formDataEdit);
    setFormDataUser(userDetails);
    setCurrentSelectedUserRole(userDetails.role);
    console.log('userDetails -->', userDetails.role);
  };

  const [messagebar1, setmessagebar1] = useState(false);
  const [messagebar2, setmessagebar2] = useState(false);
  const handleBothSetMessage = () => {
    setmessagebar1(false);
    setmessagebar2(true);
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== '' && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };
  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };

  var menuItems: any = [];

  // const jsonSchema1: RJSFSchema =

  // {

  //   "type": "object",

  //   "properties": {

  //     "firstName": {

  //       "type": "string",

  //       "title": "First name",
  //       "readOnly": readOnly

  //     },

  //     "lastName": {

  //       "type": "string",

  //       "title": "Last name",
  //       "readOnly": readOnly

  //     },

  //     "emailid": {

  //       "type": "string",

  //       "format": "email",

  //       "title": "Email Id",
  //       "readOnly": readOnly

  //     },

  //     "Tenant": {

  //       "type": "string",

  //       "title": "Tenant access",

  //       "enum": siteList.map((val: any) => val.sitename),

  //       "enumNames": siteList.map((val: any) => val.sitename),

  //     },

  //     "TimeZone": {

  //       "type": "string",

  //       "title": "TimeZone",

  //       "enum": [

  //         "UTC",

  //         "America/New_York",

  //         "America/Chicago",

  //         "America/Denver",

  //         "America/Los_Angeles"

  //       ],
  //       "enumNames": [

  //         "UTC",

  //         "America/New_York",

  //         "America/Chicago",

  //         "America/Denver",

  //         "America/Los_Angeles"

  //       ]

  //     },

  //     "Security": {

  //       "type": "string",

  //       "title": "Security",

  //       "enum": [

  //         "Email",

  //         "None",

  //       ],
  //       "enumNames": [

  //         "Email",

  //         "None",

  //       ]

  //     }

  //   },

  //   "required": [

  //     "firstName",

  //     "lastName",

  //     "emailid"

  //   ]

  // }

  const jsonSchema1: RJSFSchema = {
    type: 'object',
    properties: {
      firstName: {
        type: 'string',
        title: 'First name',
        readOnly: readOnly,
      },
      lastName: {
        type: 'string',
        title: 'Last name',
        readOnly: readOnly,
      },
      emailid: {
        type: 'string',
        format: 'email',
        title: 'Email Id',
        readOnly: readOnly,
      },
      Tenant: {
        type: 'string',
        title: 'Tenant access',
        enum: siteList.map((val: any) => val.sitename),
        enumNames: siteList.map((val: any) => val.sitename),
      },

      // "Tenant": {
      //   "type": "array", // Change type to array
      //   "title": "Tenant access",
      //   "items": {
      //     "type": "string",
      //     "enum": siteList.map((val: any) => val.sitename),
      //     "enumNames": siteList.map((val: any) => val.sitename)
      //   }
      // },

      TimeZone: {
        type: 'string',
        title: 'TimeZone',
        enum: ['UTC', 'America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles'],
        enumNames: ['UTC', 'America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles'],
      },
      Security: {
        type: 'string',
        title: 'Security',
        enum: ['Email', 'None'],
        enumNames: ['Email', 'None'],
      },
      role: {
        type: 'string',
        title: 'Role',
        enum: roleEnum,
        enumNames: roleEnumNames,
      },
    },
    required: [
      'firstName',
      'lastName',
      'emailid',
      'role', // Make role required
    ],
  };

  const uiSchema1 = {
    firstName: {
      'ui:widget': 'text',

      'ui:required': true,
    },

    lastName: {
      'ui:widget': 'text',

      'ui:required': true,
    },

    emailid: {
      'ui:widget': 'text',

      'ui:required': true,
    },
    'ui:submitButtonOptions': {
      norender: true,
    },
  };

  //below uischema added to handle disabled state for firstName, lastName and Email
  const uiSchema2 = {
    firstName: {
      'ui:widget': 'text',

      'ui:required': true,

      'ui:disabled': true,
    },

    lastName: {
      'ui:widget': 'text',

      'ui:required': true,

      'ui:disabled': true,
    },

    emailid: {
      'ui:widget': 'text',

      'ui:required': true,

      'ui:disabled': true,
    },
    'ui:submitButtonOptions': {
      norender: true,
    },
  };

  // below useEffect will handle setting uischema on rjsf form
  useEffect(() => {
    overlayName == 'edit' ? setUischema(uiSchema2) : setUischema(uiSchema1);
  }, [overlayOpen, overlayName]);

  if (permissions.includes('allUsers_Read') || permissions.includes('allUsers_ReadandExport') || permissions.includes('allUsers_Fullaccess')) {
    menuItems.push(
      <FluentProvider theme={lightTheme}>
        <MenuItem
          onClick={() => navigate('/manage/user/alluser')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                {' '}
                <PulseSquare20Regular {...primaryIcon} />{' '}
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              {' '}
              <Caption1> All&nbsp;Users</Caption1>{' '}
            </StackItem>
            <StackItem>
              {' '}
              <Stack tokens={{ childrenGap: 48 }} horizontal>
                {' '}
                <StackItem>
                  <Text></Text>
                </StackItem>{' '}
                <StackItem>
                  <Text></Text>
                </StackItem>{' '}
              </Stack>{' '}
            </StackItem>
            {/* SPACING IN INLINE SIDEBAR */}
          </Stack>
        </MenuItem>
      </FluentProvider>,
    );
  }

  if (permissions.includes('userLog_Read') || permissions.includes('userLog_ReadandExport') || permissions.includes('userLog_Fullaccess')) {
    menuItems.push(
      <MenuItem
        onClick={() => navigate('/manage/user/activitylog')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              {' '}
              <BoxCheckmark20Regular {...primaryIcon} />{' '}
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <Caption1> Activity&nbsp;Log </Caption1>{' '}
          </StackItem>
          <StackItem>
            {' '}
            <Stack tokens={{ childrenGap: 48 }} horizontal>
              {' '}
              <StackItem>
                <Text></Text>
              </StackItem>{' '}
              <StackItem>
                <Text></Text>
              </StackItem>{' '}
            </Stack>{' '}
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'firstname', label: <Body1Strong> First Name </Body1Strong> },
    { key: 'lastname', label: <Body1Strong> Last Name</Body1Strong> },
    { key: 'email', label: <Body1Strong>Email</Body1Strong> },
    { key: 'usertype', label: <Body1Strong>{'\u00A0'.repeat(4)}User Type</Body1Strong> },
    { key: 'createdon', label: <Body1Strong>Created On</Body1Strong> },
    { key: 'modifiedon', label: <Body1Strong>Modified On</Body1Strong> },
    { key: 'status', label: <Body1Strong>Status</Body1Strong> },
  ];
  //data table column name code ends here
  var columns1 = [
    { key: 'firstname', label: <Caption1> First Name </Caption1> },
    { key: 'lastname', label: <Caption1> Last Name</Caption1> },
    { key: 'email', label: <Caption1>Email</Caption1> },
    { key: 'usertype', label: <Caption1>User Type</Caption1> },
    { key: 'status', label: <Caption1>Status</Caption1> },
  ];
  //data table for filter column starts here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        {permissions.includes('allUsers_Fullaccess') ? (
          <StackItem>
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack
                tokens={{ childrenGap: 5 }}
                horizontal
                onClick={() => {
                  setFormDataUser(null);
                  setOverlayOpen(true);
                  setOverlayName('');
                }}
              >
                <StackItem>
                  {' '}
                  <AddSquare20Regular {...primaryIcon} />{' '}
                </StackItem>
                <StackItem>
                  {' '}
                  <Caption1> Add User </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </StackItem>
        ) : (
          <StackItem>
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {' '}
                        <AddSquare20Regular {...secondaryIcon} />{' '}
                      </StackItem>
                      <StackItem>
                        <Caption1> Add User </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          </StackItem>
        )}

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          {/* <Button appearance="subtle" size="small" > */}
          {/* <Stack tokens={{childrenGap:5}} horizontal> */}
          {/* <StackItem> <SlideEraser20Regular {...primaryIcon}/> </StackItem> */}
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {isdeletedisabled ? <SlideEraser20Regular {...secondaryIcon} /> : <SlideEraser20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      {' '}
                      <Caption1> Delete </Caption1>{' '}
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Are you sure you want to delete the user? This action cannot be reverted."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setdeleteUser(true);
                        userActivity(
                          'Delete',
                          `{"summary":"${contextValue.selectedRowIdInTable.length} user deleled with id ${contextValue.selectedRowIdInTable}"}`,
                        );
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>

                    {/* <Button appearance="primary" onClick={() => {setDeleteAppMonitored(true)}} size="small">Confirm</Button> */}
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
          {/* </Stack> */}
          {/* </Button> */}
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                {' '}
                <ArrowClockwise20Regular {...primaryIcon} />{' '}
              </StackItem>
              <StackItem>
                {' '}
                <Caption1> Refresh </Caption1>{' '}
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        {permissions.includes('allUsers_ReadandExport') || permissions.includes('allUsers_Fullaccess') ? (
          <StackItem>
            <CSVLink id="csv-export-link" data={exportData} filename="users.csv">
              <Button
                appearance="subtle"
                size="small"
                onClick={() => {
                  userActivity('Export', `{"summary":"Users List Exported"}`);
                }}
              >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    {' '}
                    <ArrowDownload20Regular {...primaryIcon} />{' '}
                  </StackItem>
                  <StackItem>
                    {' '}
                    <Caption1> Export </Caption1>{' '}
                  </StackItem>
                </Stack>
              </Button>
            </CSVLink>
          </StackItem>
        ) : (
          <StackItem>
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {' '}
                        <ArrowDownload20Regular {...secondaryIcon} />{' '}
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          </StackItem>
        )}

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        tempExportData.push({
          id: val.userid,
          firstname: val.firstName,
          lastname: val.lastName,
          email: val.user_email,
          usertype: val.userType,
          createdon: val.Created_on,
          status: val.status,
          modifiedon: val.modifiedOn,
        });
      });
    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    //roles and permissions
    async function loadRoute() {
      console.log('role:-', sessionStorage.getItem('role'));
      const role = sessionStorage.getItem('role');

      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          console.log('permission===', permissions);

          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }

    loadRoute();
  }, []);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data USERS=', final_data);
    getSiteList();
    getRoles();

    final_data !== undefined && final_data !== null
      ? final_data.core_Users?.map((val: any, i: any) => {
          //console.log("final data email--->", val.user_email);
          const email = val.user_email;
          const formattedEmail = email.substring(0, 10) + '\n' + email.substring(10, 23) + '\n' + email.substring(23);
          //console.log("formattedEmail----->\n", formattedEmail);

          const createdOn = val.Created_on
            ? new Date(val.Created_on * 1000).toLocaleString('en-IN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
              })
            : '';
          menuLists.push();
          {
            permissions.includes('allUsers_Fullaccess')
              ? tempTableData.push({
                  id: val.userid,
                  name: val.user_email,
                  checkbox: 'show',
                  firstname: {
                    label: (
                      <Caption1
                        onClick={(e: any) => {
                          handleOnCLickEdit(val);
                        }}
                      >
                        {' '}
                        {val.firstName !== null ? val.firstName : 'N/A'}{' '}
                      </Caption1>
                    ),
                    icon: '',
                    id: `${i}`,
                  },
                  lastname: {
                    label: (
                      <Caption1>
                        {' '}
                        {val.lastName != '' ? val.lastName : 'N/A'} {setRowDesc(val.lastName)}{' '}
                      </Caption1>
                    ),
                    status: '',
                  },
                  // email: { label: <Caption1> {val.user_email} </Caption1>},
                  email: { label: <Caption1> {formattedEmail} </Caption1> },
                  usertype: {
                    label: (
                      <Caption1>
                        {'\u00A0'.repeat(4)} {val?.userType ?? 'local'}{' '}
                      </Caption1>
                    ),
                  },
                  //createdon: { label: <Caption1> {val.Created_on} </Caption1>},
                  createdon: { label: <Caption1> {createdOn} </Caption1> },
                  // modifiedon: { label: <Caption1>{new Date(val.passwordDate *1000).toLocaleString()}</Caption1>},
                  modifiedon: {
                    label: (
                      <Caption1>
                        {new Date(val.modifiedOn * 1000).toLocaleString('en-IN', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })}{' '}
                        {}
                      </Caption1>
                    ),
                  },
                  status: { label: val.userStatus == 1 ? <Caption1>Active</Caption1> : <Caption1>Pending</Caption1> },
                })
              : tempTableData.push({
                  id: val.userid,
                  name: val.user_email,
                  checkbox: 'hide',
                  firstname: { label: <Caption1> {val.firstName !== null ? val.firstName : 'N/A'} </Caption1>, icon: '', id: `${i}` },
                  lastname: {
                    label: (
                      <Caption1>
                        {' '}
                        {val.lastName != '' ? val.lastName : 'N/A'} {setRowDesc(val.lastName)}{' '}
                      </Caption1>
                    ),
                    status: '',
                  },
                  // email: { label: <Caption1> {val.user_email} </Caption1>},
                  email: { label: <Caption1> {formattedEmail} </Caption1> },
                  usertype: {
                    label: (
                      <Caption1>
                        {'\u00A0'.repeat(4)} {val?.userType ?? 'local'}{' '}
                      </Caption1>
                    ),
                  },
                  //createdon: { label: <Caption1> {val.Created_on} </Caption1>},
                  createdon: { label: <Caption1> {createdOn} </Caption1> },
                  // modifiedon: { label: <Caption1>{new Date(val.passwordDate *1000).toLocaleString()}</Caption1>},
                  modifiedon: {
                    label: (
                      <Caption1>
                        {new Date(val.modifiedOn * 1000).toLocaleString('en-IN', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })}{' '}
                        {}
                      </Caption1>
                    ),
                  },
                  status: { label: val.userStatus == 1 ? <Caption1>Active</Caption1> : <Caption1>Pending</Caption1> },
                });
          }
        })
      : (tempTableData = []);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data, formDataUser]);
  // data table values append code ends here
  var overLayData: any = {
    //here this is heading of the overlay
    headerTitle: <>{overlayName == 'edit' ? <Title3>Edit User</Title3> : <Title3>Add User</Title3>}</>,
    size: 'large',
    isTabs: true,

    //this is button present in the overlay

    //data presents in the overlay(overlay content)
    tabItems: {
      Introduction: (
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <Form
              ref={formRef}
              formData={formDataUser}
              schema={jsonSchema1}
              uiSchema={uischema}
              validator={validator}
              widgets={CustomWidgets}
              onChange={e => setFormDataUser(e.formData)}
            />
          </Stack>
        </FluentProvider>
      ),
      'Review Data': (
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <Form
              disabled={true}
              formData={formDataUser}
              schema={jsonSchema1}
              uiSchema={uischema}
              validator={validator}
              widgets={CustomWidgets}
              onChange={e => setFormDataUser(e.formData)}
            />
          </Stack>
        </FluentProvider>
      ),
    },
  };

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'User',
      moduleName: 'Users',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  useEffect(() => {
    if (saveUserDetails) {
      overlayName != 'edit' && userActivity('Add', `{"summary":"Added User"}`);
      overlayName == 'edit' && userActivity('Edit', `{"summary":"Edited User Details"}`);
    }
  }, [saveUserDetails]);

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  {' '}
                  <Breadcrumbs pageName="Users" subPageName="" subPageLink="#" />{' '}
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Apps" /> </Text> */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                {' '}
                <Text></Text>{' '}
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Users | All Users </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    {' '}
                    <Text></Text>{' '}
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              setSearchVal(e.target.value);
                              setCurrentPageNo(1);
                            }}
                            value={searchVal}
                            placeholder="Search"
                            size="small"
                            dismiss={null}
                            contentAfter={<DismissButton aria-label="Clear" />}
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        {' '}
                                        <Text></Text>{' '}
                                      </StackItem>
                                      <StackItem>
                                        {' '}
                                        <Caption1> Filter </Caption1>{' '}
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        {' '}
                                        <Divider />{' '}
                                      </StackItem>
                                      <StackItem>
                                        {' '}
                                        <Caption1> Value </Caption1>{' '}
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        {' '}
                                        <Divider />{' '}
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          {' '}
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            {' '}
                                            <Caption1> Apply </Caption1>{' '}
                                          </Button>{' '}
                                        </StackItem>
                                        <StackItem>
                                          {' '}
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            {' '}
                                            <Caption1> Cancel </Caption1>{' '}
                                          </Button>{' '}
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                              setFilterArray([]);
                                              setIsFilter(false);
                                              setDropDownValue2('');
                                              setResetDisabled(true);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                          </StackItem>

                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  {' '}
                                  <FilterDismiss20Filled />{' '}
                                </StackItem>
                                <StackItem>
                                  {' '}
                                  <Caption1> Reset Filters </Caption1>{' '}
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem>
                          {' '}
                          <Caption1>
                            {' '}
                            Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of{' '}
                            {totalRecords} Records{' '}
                          </Caption1>{' '}
                        </StackItem>
                        <StackItem>
                          {' '}
                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />{' '}
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <Users_func
                      data={final_data}
                      func={set_final_data}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 10}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                      saveUserDetails={saveUserDetails}
                      setSaveUserDetails={setSaveUserDetails}
                      formDataUser={formDataUser}
                      setFormDataUser={setFormDataUser}
                      deleteUser={deleteUser}
                      setDeleteUser={setdeleteUser}
                      setDeleteDisabled={setDeleteDisabled}
                      setEditUserDetails={setEditUserDetails}
                      editUserDetails={editUserDetails}
                      rowId={rowID}
                      setRowID={setRowID}
                      handleEditData={handleEditData}
                      currentSelectedUserRole={currentSelectedUserRole}
                      setCurrentSelectedUserRole={setCurrentSelectedUserRole}
                      setIsToast={setIsToast}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                      setRefreshToast={setRefreshToast}
                      setExportDetails={setExportDetails}
                      deleteUserToast={deleteUserToast}
                      setdeleteUserToast={setdeleteUserToast}
                    />
                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns}></DataTable>}
                  </Stack>
                  {overlayName == 'edit' && editUserDetails && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={isToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={'User Details Updated Successfully'}
                      footer=""
                    />
                  )}
                  {overlayName != 'edit' && isToast && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={isToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={'User Details Saved Successfully'}
                      footer=""
                    />
                  )}
                  {deleteUserToast && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={isToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={'User Detail Deleted Successfully'}
                      footer=""
                    />
                  )}
                  {refreshToast && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={refreshToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={'Data Table Refreshed'}
                      footer=""
                    />
                  )}
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={overLayData}
            isNavBtn={true}
            saveBtnFunc={saveBtnFunc}
            formRef={formRef}
          />
          <StackItem>
            {messagebar1 && (
              <ToastComponent
                setmessagebar={setmessagebar1}
                toastIntent="warning"
                position="top"
                title={`Are you sure you want to proceed with publishing this app on ${contextValue.selectedRowIdInTable.length} devices?`}
                footer={
                  <>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setdeleteUser(true);
                      }}
                    >
                      {' '}
                      Confirm{' '}
                    </Button>
                    <Button
                      onClick={() => {
                        setmessagebar1(false);
                      }}
                    >
                      {' '}
                      Cancel{' '}
                    </Button>
                  </>
                }
              />
            )}
          </StackItem>
        </StackItem>
      </FullScreen>
    </>
  );
};
