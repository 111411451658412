import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, StackItem } from '@fluentui/react';
import {
  FluentProvider,
  webLightTheme,
  Image,
  Input,
  Button,
  Tooltip,
  Badge,
  Spinner,
  LargeTitle,
  Body1,
  Body2,
  Body1Strong,
} from '@fluentui/react-components';
import { EyeOff20Filled, Eye20Filled, Open20Regular } from '@fluentui/react-icons';
import { signIn } from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';
import { GlobalContext } from '../../hooks/globalContext';
import { SkeletonSignin } from '../../components/skeleton/skeletonSignin';
import { useApolloClient } from '@apollo/client';
import { getUserDetails } from '../../api/home/user_details_func';
import { formPosition, backgroundPosition, signLogo } from '../../styles/customStyles';
import AppConfig from '../../utility/AppConfig';

interface User {
  username: string;
  password: string;
}

export const Signin = (props: any) => {
  const Navigate = useNavigate();
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);

  const [loginPage, magicLinkPage] = useState(false);
  const [submissionPage, setSubmissionPage] = useState(false);
  const [formError, setFormError] = useState('');
  const [credentialError, setCredentialError] = useState(false);
  const [isAltert, setAlert] = useState(false);
  const [user, setUser] = useState<User>({ username: '', password: '' });
  const [isOnline, setIsOnline] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState('');
  const EnablePage = () => magicLinkPage(true);
  const SubmissionPageEnable = () => setSubmissionPage(true);
  // const handleButtons = () => setShowButton(true);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  localStorage.clear(); // sign out logic clearing local storage and session

  useEffect(() => {
    localStorage.clear();
    const checkConnection = async () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
      await Session.signOut();
    };
    checkConnection();
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, [props.layoutCondition]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (e.target.type == 'email') {
      const email = e.target.value;
      sessionStorage.setItem('userEmail', email);
    }
    setUser(prevUser => ({ ...prevUser, [name]: value }));
  };

  async function getToken(): Promise<void> {
    const accessToken = await Session.getAccessToken();
    console.log('Access Token', accessToken);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setAlert(false);
    setCredentialError(false);
    setFormError('');
    setIsLoading(true); //Set loading state when sign-in process starts

    // Navigate ("/") // async function signInClicked(email: string, password: string) { }
    try {
      let response = await signIn({
        formFields: [
          { id: 'email', value: user.username },
          { id: 'password', value: user.password },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach(formField => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax). window.alert(formField.error)
            setCredentialError(false);
            setFormError(formField.error);
            setIsLoading(false);
          }
        });
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        // window.alert("Email and Password combination is Incorrect.!")
        setFormError('');
        setCredentialError(true);
        setIsLoading(false);
      } else if (response.status === 'SIGN_IN_NOT_ALLOWED') {
        // this can happen due to automatic account linking. Tell the user that their input credentials is wrong (so that they do through the password reset flow)
        setError('Signin&nbsp;not&nbsp;allowed');
      } else {
        // sign in successful. The session tokens are automatically handled by the frontend SDK.
        if (await Session.doesSessionExist()) {
          // User is logged in
          const token = await Session.getAccessToken;
          getToken();
          localStorage.clear();
          sessionStorage.setItem('user-login', String(token));
          setIsLoading(true);

          await fetch(`${AppConfig.DASHBOARD_V2_API_URL}/get-user-info`)
            .then(response => response.json())
            .then(async (res: any) => {
              if (res.message !== 'unauthorised') {
                var user_email = res?.emails[0];

                const { data, loading, error } = await client.query({
                  query: getUserDetails,
                  variables: { user_email },
                  fetchPolicy: 'network-only',
                });

                if (!loading && data.core_Users.length > 0) {
                  var userDetails = data.core_Users[0];
                  localStorage.setItem('userDetails', JSON.stringify(userDetails));
                  contextValue.setUserSessionDetails({
                    userId: userDetails.userid,
                    userName: userDetails.firstName,
                    userLName: userDetails.lastName,
                    userEmail: userDetails.user_email,
                    otherDetails: JSON.parse(userDetails.formDataEdit),
                  });
                }
              }

              fetch(`${AppConfig.DASHBOARD_V2_API_URL}/sessioninfo`)
                .then(response => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then(data => {
                  console.log('Session info:', data);
                  console.log('role assigned -->', data.accessTokenPayload['st-role'].v[0]);
                  sessionStorage.setItem('role', data.accessTokenPayload['st-role'].v[0]);
                  // contextValue.setUserRole(data.accessTokenPayload["st-role"].v[0])
                })
                .catch(error => {
                  console.error('Error fetching session info:', error);
                });
            });
          Navigate('/Dashboard');
        } else {
          // user has not logged in yet // setisLoggedInFlag(false)
        }
      }
    } catch (err: any) {
      console.log('catch -->', err);
      if (err.isSuperTokensGeneralError === true) {
        // window.alert(err.message); // this may be a custom error message sent from the API by you.
      } else {
        // window.alert("Oops! Something went wrong.");
        setIsLoading(false);
        setAlert(true);
      }
    }
  };

  return (
    <>
      {isOnline ? (
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal tokens={{ childrenGap: 100, padding: 10 }}>
            <StackItem style={signLogo}>
              <Image src="/visualization/v2/image/signinLogo.svg" alt="Nanoheal" />
            </StackItem>

            <Stack tokens={{ childrenGap: 15 }} style={formPosition}>
              <form onSubmit={handleSubmit}>
                <Stack tokens={{ childrenGap: 15 }}>
                  <LargeTitle> Sign in </LargeTitle>
                  <Body1> Hello there! Please input your details to Proceed </Body1>

                  {!loginPage ? (
                    <>
                      <Body2> Username </Body2>
                      <Input
                        type="email"
                        id="username"
                        name="username"
                        placeholder="Enter Username Here"
                        value={user.username}
                        onChange={handleInputChange}
                        required
                      />

                      <Body2> Password </Body2>
                      <Input
                        id="password"
                        name="password"
                        placeholder="Enter Password Here"
                        value={user.password}
                        onChange={handleInputChange}
                        onFocus={handlePasswordFocus}
                        type={showPassword ? 'text' : 'password'}
                        required
                        contentAfter={
                          <>
                            {passwordFocused && (
                              <Button
                                size="small"
                                appearance="transparent"
                                onClick={handleTogglePassword}
                                icon={
                                  showPassword ? (
                                    <Tooltip withArrow content="Hide Password" relationship="label">
                                      <EyeOff20Filled />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip withArrow content="Show Password" relationship="label">
                                      <Eye20Filled />
                                    </Tooltip>
                                  )
                                }
                              />
                            )}
                          </>
                        }
                      />

                      {formError && (
                        <Badge appearance="ghost" color="danger">
                          <Body2> {formError} </Body2>
                        </Badge>
                      )}
                      {credentialError && (
                        <Badge appearance="ghost" color="danger">
                          <Body2> Invalid email id or password! </Body2>
                        </Badge>
                      )}
                      {isAltert && (
                        <Badge appearance="ghost" color="danger">
                          <Body2> Oops! Something went wrong. </Body2>
                        </Badge>
                      )}

                      <Link to="/auth/reset-password">
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                          <StackItem>
                            <Body1 underline onClick={() => Navigate('/auth/reset-password')}>
                              Forgot Password?
                            </Body1>
                          </StackItem>
                          <StackItem>
                            <Open20Regular onClick={() => Navigate('/auth/reset-password')} />
                          </StackItem>
                        </Stack>
                      </Link>

                      <StackItem>
                        <Button appearance="primary" type="submit">
                          <Body1Strong> Sign In </Body1Strong>
                        </Button>
                      </StackItem>

                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Body1> Don't have an account? </Body1>
                        </StackItem>
                        <StackItem>
                          <Link onClick={() => Navigate('/signup')}>
                            <Body1Strong> Sign Up </Body1Strong>
                          </Link>
                        </StackItem>
                      </Stack>
                      {/* <Stack horizontal tokens={{childrenGap:10}}>
                                <StackItem> <Body1> REMOVE ME AND ADD ME IN HEADER </Body1> </StackItem>
                                <StackItem> <Link onClick={() => Navigate("/auth/change-password")}> <Body1Strong> Change Password </Body1Strong> </Link> </StackItem>
                              </Stack> */}

                      <Stack horizontalAlign="start"> {isLoading && <Spinner delay={1000} size="extra-tiny" label="Please wait...." />} </Stack>
                      {/* <Stack horizontalAlign='start'> {isLoading && <Spinner delay={1000} size='huge' label="Please wait...."/>} </Stack> <Divider alignContent='start'> <Body1> <Button size='small' appearance='transparent' onClick={handleButtons}> Other Options </Button> </Body1> </Divider> */}

                      {showButton && (
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                          <StackItem grow shrink>
                            <Stack tokens={{ childrenGap: 5 }} horizontalAlign="center">
                              <StackItem>
                                <Button size="small" onClick={EnablePage}>
                                  <Body1Strong> Magic Link / OTP </Body1Strong>
                                </Button>
                              </StackItem>
                              <StackItem>
                                <Button size="small">
                                  <Body1Strong> Sign in with Apple </Body1Strong>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>
                          <StackItem grow shrink>
                            <Stack tokens={{ childrenGap: 5 }} horizontalAlign="center">
                              <StackItem>
                                <Button size="small">
                                  <Body1Strong> Sign in with Google </Body1Strong>
                                </Button>
                              </StackItem>
                              <StackItem>
                                <Button size="small">
                                  <Body1Strong> Sign in with GitHub </Body1Strong>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>
                        </Stack>
                      )}
                    </>
                  ) : (
                    <>
                      {submissionPage ? (
                        <>
                          <Input
                            type="email"
                            id="username"
                            name="username"
                            placeholder="Enter OTP"
                            value={user.username}
                            onChange={handleInputChange}
                          />
                          <StackItem>
                            <Button type="submit">
                              <Body1Strong> Submit OTP </Body1Strong>
                            </Button>
                          </StackItem>
                          <StackItem>
                            <Button type="submit">
                              <Body1Strong> Change Email / Phone </Body1Strong>
                            </Button>
                          </StackItem>
                          <StackItem>
                            <Button type="submit">
                              <Body1Strong> Resend OTP / Link </Body1Strong>
                            </Button>
                          </StackItem>
                        </>
                      ) : (
                        <>
                          <Body2> Email / Phone </Body2>
                          <Input
                            type="email"
                            id="username"
                            name="username"
                            placeholder="Enter Email or Phone"
                            value={user.username}
                            onChange={handleInputChange}
                          />
                          <StackItem>
                            <Button appearance="primary" type="submit" onClick={SubmissionPageEnable}>
                              <Body1Strong> Send Magic Link / OTP </Body1Strong>
                            </Button>
                          </StackItem>
                          <StackItem>
                            <Button type="submit">
                              <Body1Strong> Email / Password </Body1Strong>
                            </Button>
                          </StackItem>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </form>
            </Stack>
            <Stack style={backgroundPosition}>
              <Image block src="/visualization/v2/signin_background.png" alt="Background" height={`100%`} />
            </Stack>
          </Stack>
        </FluentProvider>
      ) : (
        <SkeletonSignin />
      )}
    </>
  );
};
