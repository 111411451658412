import { gql } from '@apollo/client';

const getSitesListsQuery = gql`
  query getInstallSites {
    getInstallSites {
      sitename
      firstcontact
    }
  }
`;

const countCensusDevice = gql`
  query countCensusDevice($siteName: String!) {
    countCensusDevice(siteName: $siteName)
  }
`;
const countCensusDeviceWithBornDate = gql`
  query countCensusDeviceWithBornDate($siteName: String!, $bornFromDate: Int!) {
    countCensusDeviceWithBornDate(siteName: $siteName, bornFromDate: $bornFromDate)
  }
`;

export { countCensusDevice, getSitesListsQuery, countCensusDeviceWithBornDate };
