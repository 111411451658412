import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getStatusListsQuery, getStatusListsCntQuery, getStatusActivityQuery } from "../graphql/status_queries";
import { GlobalContext } from "../hooks/globalContext";


type dataProps = {
    data: any;
    func: any;
    searchVal: any
    pageNo: any
    setTotalPages: any
    setTotalRecords: any
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
    activityData?:any
    activityFunc?:any
    selectedAct?: any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropDownValue2?:any

}


export const Status_func = (props: dataProps) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    
    const tenantName = contextValue.tenantName;
    const selectedActivity = props.selectedAct;
    console.log("searchVal--------------------", props.searchVal);


    const getStatusList = async (whereCondition: any, MachineTag: String = '', limit: number, offset: number, order: any) => {
        const { data, loading, error } = await client.query({
            query: getStatusListsQuery,
             variables: {whereCondition, MachineTag, limit, offset, order},
            fetchPolicy: 'network-only',
        });

        if(!loading && data){
           // console.log("Status data-1*******************************",data); 
            props.func(data);
        }
    };


    const getStatusListCnt = async (whereCondition: any, MachineTag: String = '') => {
        const { data, loading, error } = await client.query({
            query: getStatusListsCntQuery,
            variables: {whereCondition, MachineTag},
            fetchPolicy: 'network-only',
        });

        if(!loading && data){
            //console.log("Status data-2*******************************",data); 
            props.setTotalRecords(data.communication_Audit?.length);
            props.setTotalPages(Math.ceil((data.communication_Audit?.length)/50));
        }
    }


    const getStatusActivity = async (whereCondition: any, time: any) => {
        const { data, loading, error } = await client.query({
          query: getStatusActivityQuery,
          variables: { whereCondition,  time}, 
          fetchPolicy: 'network-only',
        });
      
        if (!loading && data) {
          //console.log("Status details, Activity-------------", data);
          props.activityFunc(data);
        }
        
      };





    useEffect(() => {
        let selectedActname = selectedActivity.name;
        let selectedActTime = selectedActivity.time;

        if(props.searchVal?.length > 0 && props.searchVal !== ''){
            getStatusList({site: { _eq: tenantName }}, props.searchVal, 50, parseInt(props.pageNo), {AID: "desc"})
            getStatusListCnt({site: { _eq: tenantName } }, props.searchVal);

        }else if(selectedActivity.name?.length > 0 && selectedActTime){
            const selectedActivityTime = parseInt(selectedActTime);

             getStatusActivity({ automationName: { _eq: selectedActname } }, selectedActivityTime);
            
        }else if (props.isRefresh) {
            props.setIsRefresh(false);
            getStatusList({site: { _eq: tenantName }}, '', 50, parseInt(props.pageNo), {AID: "desc"})
            getStatusListCnt({site: { _eq: tenantName } } );
            props.setRefreshToast(true);
            setTimeout(() => {
                props.setRefreshToast(false);
            }, 5000);

        }else{
            getStatusList({site: { _eq: tenantName } }, '', 50, parseInt(props.pageNo), {AID: "desc"});
            getStatusListCnt({site: {_eq: tenantName} });
        }
    }, [props.searchVal, props.pageNo, props.isRefresh, props.selectedAct ]);

    return (
        <></>
    )
}
