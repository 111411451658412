import { gql } from '@apollo/client';

export const getPinot_d312_singleAsset_v1_installedsoftwarenames = gql`
  query getPinot_d312_singleAsset_v1_installedsoftwarenames($params: JSON) {
    getPinot_d312_singleAsset_v1_installedsoftwarenames(params: $params) {
      error
      answer
    }
  }
`;

export const getPinot_d312_singleAsset_v1_machine = gql`
  query getPinot_d312_singleAsset_v1_machine($params: JSON) {
    getPinot_d312_singleAsset_v1_machine(params: $params) {
      error
      answer
    }
  }
`;
