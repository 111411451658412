import { GlobalContext } from "../../hooks/globalContext";
import { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, StackItem } from "@fluentui/react";
import { MotionState } from "@fluentui/react-motion-preview";
import { MenuList, MenuItem, Body1Strong, Tooltip } from "@fluentui/react-components";
import { ChevronDoubleRight16Filled, AppFolder20Regular, DesktopFlow20Regular, DesktopTower20Regular, DocumentSearch20Regular, Patch20Regular, People20Regular, Settings20Regular, WrenchScrewdriver20Regular, ChevronDoubleLeft16Filled } from "@fluentui/react-icons";
import { primaryIcon, sidebarHeight } from '../../styles/customStyles';

export const Sidebar = (props: { leftOpen: boolean | MotionState<HTMLDivElement> | undefined; setLeftOpen: (arg0: boolean) => void; }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const contextValue: any = useContext(GlobalContext);
    const sidebarVisibility = ["/", "/signin", "/auth/reset-password", "/signup", "/auth/change-password", "/Dashboard"].includes(location.pathname);
    const investigatePage = ["/investigate/dashboardlists"].includes(location.pathname);
    const navigationIcons = ["/automate/automation/all_automations", "/automate/automation/all_automations/configuration", "/automate/automation/autoheal", "/automate/automation/selfhelp", "/automate/automation/automationschedule", "/automate/automation/usersurvey", "/automate/automation/compliancepolices", "/automate/automation/activitylog", "/automate/apps/appinstallation", "/automate/apps/appuninstallprofile", "/automate/apps/appprotectionpolicies", "/automate/patch/patch", "/investigate/dashboardlists","/automate/automation/compliance_policies/policy_configuration"].includes(location.pathname);

    const menuOpen = (title: string, icon: any, path: string) => (
        <MenuItem icon={icon} onClick={() => navigate(path)}> {title} </MenuItem>
    );
    const menuClose = (icon: JSX.Element, path: string, tooltipContent: string) => (
        <Tooltip content={tooltipContent} withArrow appearance="inverted" positioning="after" relationship="label">
            <MenuItem icon={icon} onClick={() => navigate(path)} />
        </Tooltip>
    );

    const openMenu = () => {
        return (
            (!navigationIcons)
                ?   <>
                        {menuOpen("Devices", <DesktopTower20Regular {...primaryIcon} />, "/manage/devices/overview")}
                        {menuOpen("Apps", <AppFolder20Regular {...primaryIcon} />, "/manage/apps/appmonitor")}
                        {menuOpen("Search", <DocumentSearch20Regular {...primaryIcon} />, "/manage/search/search")}
                        {menuOpen("Troubleshoot", <WrenchScrewdriver20Regular {...primaryIcon} />, "/manage/troubleshoot/troubleshoot")}
                        {menuOpen("Users", <People20Regular {...primaryIcon} />, "/manage/user/alluser")}
                        {menuOpen("Administration", <Settings20Regular {...primaryIcon} />, "/manage/administration/administration")}
                    </>
                :   (!investigatePage)
                        ?   <>
                                {menuOpen("Automation", <DesktopFlow20Regular {...primaryIcon} />, "/automate/automation/all_automations")}
                                {menuOpen("Apps", <AppFolder20Regular {...primaryIcon} />, "/automate/apps/appinstallation")}
                                {menuOpen("Patch", <Patch20Regular {...primaryIcon} />, "/automate/patch/patch")}
                            </>
                        :   <>
                                {menuOpen("Application Health", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Application Health")}
                                {menuOpen("Business Applications Experience", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Business Applications Experience")}
                                {menuOpen("System CPU Utilization", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=System CPU Utilization")}
                                {menuOpen("Process Memory Utilization", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Process Memory Utilization")}
                                {menuOpen("Process CPU Utilization", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Process CPU Utilization")}
                                {menuOpen("System Memory Utilization", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=System Memory Utilization")}
                                {menuOpen("Digital Experience", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Digital Experience")}
                                {menuOpen("Device Experience", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Device Experience")}
                                {menuOpen("Automation Analytics", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Automation Analytics")}
                                {menuOpen("Automation Overview", <AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Automation Overview")}
                            </>
        )
    };

    const closeMenu = () => {
        return (
            (!navigationIcons)
                ?   <>
                        {menuClose(<DesktopTower20Regular {...primaryIcon} />, "/manage/devices/overview", "Devices")}
                        {menuClose(<AppFolder20Regular {...primaryIcon} />, "/manage/apps/appmonitor", "Apps")}
                        {menuClose(<DocumentSearch20Regular {...primaryIcon} />, "/manage/search/search", "Search")}
                        {menuClose(<WrenchScrewdriver20Regular {...primaryIcon} />, "/manage/troubleshoot/troubleshoot", "Troubleshoot")}
                        {menuClose(<People20Regular {...primaryIcon} />, "/manage/user/alluser", "Users")}
                        {menuClose(<Settings20Regular {...primaryIcon} />, "/manage/administration/administration", "Administration")}
                    </>
                : (!investigatePage)
                    ?   <>
                            {menuClose(<DesktopFlow20Regular {...primaryIcon} />, "/automate/automation/all_automations", "Automation")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/automate/apps/appinstallation", "Apps")}
                            {menuClose(<Patch20Regular {...primaryIcon} />, "/automate/patch/patch", "Patch")}
                        </> 
                    :   <>
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Application Health", "Application Health")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Business Applications Experience", "Business Applications Experience")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=System CPU Utilization", "System CPU Utilization")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Process Memory Utilization", "Process Memory Utilization")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Process CPU Utilization?name=Process CPU Utilization", "Process CPU Utilization")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=System Memory Utilization", "System Memory Utilization")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Digital Experience", "Digital Experience")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Device Experience", "Device Experience")}
                            {menuClose(<AppFolder20Regular {...primaryIcon} />, "/investigate/dashboardlists?name=Automation Analytics", "Automation Analytics")}
                        </>
        )
    };

    return (
        <>
            {!sidebarVisibility &&
                <Stack style={sidebarHeight}>
                    {props.leftOpen
                        ?   <MenuList>
                                <MenuItem>
                                    <Stack tokens={{ childrenGap: 80 }} horizontal onClick={() => props.setLeftOpen(!props.leftOpen)}>
                                        <Stack horizontalAlign="start">
                                            <Body1Strong> {contextValue.tenantName} </Body1Strong>
                                        </Stack>
                                        <Stack horizontalAlign="end" title="Hide text labels">
                                            <ChevronDoubleLeft16Filled {...primaryIcon} />
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                                {openMenu()}
                            </MenuList>
                        :   <MenuList>
                                <MenuItem>
                                    <StackItem align="start" title="Show text labels">
                                        <ChevronDoubleRight16Filled onClick={() => props.setLeftOpen(!props.leftOpen)} />
                                    </StackItem>
                                </MenuItem>
                                {closeMenu()}
                            </MenuList>
                    }
                </Stack>
            }
        </>
    );
};