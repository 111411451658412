import { useState, useEffect, useContext, useRef } from "react";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, ButtonProps } from "@fluentui/react-components";
import { BookTemplate20Regular, Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, ArrowMaximize20Regular, DismissSquare24Filled, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, ChatMultipleHeart20Regular, Info20Regular, DesktopPulse20Regular, GroupList20Regular, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { CheckBox } from "../../../components/checkbox/checkbox_component";
import { App_Monitored_Func } from "../../../api/app_monitored_func";
import { GlobalContext } from "../../../hooks/globalContext";
import { ToastComponent } from "../../../components/toast/toast.component";
import { Data_Collection_func } from "../../../api/data_collection/data_collection_func";
import { fullscreenIcon, lightTheme, primaryIcon, exitIcon, overflowDivider } from "../../../styles/customStyles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";

const handleExportCSV = () => { // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById("csv-export-link");
  if (csvExportLink) {csvExportLink.click()}
};

export const DataCollection = () => {
  const contextValue: any = useContext(GlobalContext);
  const navigate = useNavigate();

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [isBinaries, setBinaries]: any = useState(false);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isChecked1, setIsChecked1]: any = useState(false);
  const [deleteAppMonitored, setDeleteAppMonitored]: any = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [dropDownValue1, setDropDownValue1] = useState<any[]>([]);
  const [deviceName, setDeviceName] = useState('');
  const [monitoredOverLayData, setMonitoredOverLayData]: any = useState([]);
  const [rowID, setRowID]: any = useState(null);
  const [error, seterror] = useState(false);
  const [categorySearchVal, setCategorySearchVal] = useState("");
  const [scoresCpuRequired, setScoreCpuRequired] = useState(false);
  const [scoresMemRequired, setScoreMemRequired] = useState(false);
  const [scoresNetRequired, setScoreNetRequired] = useState(false);
  const [final_data1, set_final_data1]: any = useState(null);
  const [tableData1, setTableData1] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [processName, setProcessname]: any = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [saveBtnClicked1, setSaveBtnClicked1]: any = useState(false);
  const [score, setScore]: any = useState('');
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [deviceList, setDeviceList]: any = useState(null);
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [selectedOption, setSelectedOption] = useState('');
  const [cloneCpuForms, setCloneCpuForms] = useState([]);
  const [deleteMonitoredApp, setDeleteMonitoredApp]: any = useState(false);
  const [cloneMemForms, setcloneMemForms] = useState<FormMem[]>([])
  const [cloneNetForms, setcloneNetForms] = useState<FormNet[]>([]);
  const [formData, setFormData]: any = useState({ name: '', description: '' });
  const [categorySearchData, setCategorySearchData] = useState(['Business Application', 'Productivity Application', 'Collaboration Tools', 'Developer Tools']);
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const [formDataBasic, setFormDataBasic]: any = useState({
    Category: '',
    Subcategory: '',
    ApplicationName: '',
    ApplicationType: '',
    UrlPatterns: [{ /* urlpattern: '', */ }],
    ApplicationBinaries: '',
    cpuUtilisation: [{ cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
    memUtilisation: [{ memFrom: '', memTo: '', memScore: '', memRank: '' }],
    netUtilisation: [{ netFrom: '', netTo: '', netScore: '', netRank: '' }]
  });

  const [editformDataBasic, setEditFormDataBasic] = useState({
    Category: '',
    Subcategory: '',
    ApplicationName: '',
    ApplicationType: '',
    UrlPatterns: '',
    ApplicationBinaries: '',
    cpuUtilisation: [{ cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
    memUtilisation: [{ memFrom: '', memTo: '', memScore: '', memRank: '' }],
    netUtilisation: [{ netFrom: '', netTo: '', netScore: '', netRank: '' }]
  }); console.log("Device Name: ", deviceName);

  const buttonref: any = useRef(null);
  const handleOutsideButton = () => { buttonref?.current?.click(); }; // console.log("Date Now: ", Date.now());

  console.log("Form Data: ", formDataBasic);
  console.log("Qwertyuio: ", formDataBasic.memUtilisation);

  const convertedDataCpu = {
    range: formDataBasic.cpuUtilisation.map((item: any) => ({
      cw: "1",
      mw: "1",
      sc: item.cpuScore,
      to: item.cpuTo,
      scw: "1",
      from: item.cpuFrom,
      rank: item.cpuRank,
    })),
  };

  const convertedDataMem = {
    range: formDataBasic.memUtilisation.map((item: any) => ({
      cw: "1",
      mw: "1",
      sc: item.memScore,
      to: item.memTo,
      scw: "1",
      from: item.memFrom,
      rank: item.memRank,
    })),
  };

  const convertedDataNet = {
    range: formDataBasic.netUtilisation.map((item: any) => ({
      cw: "1",
      mw: "1",
      sc: item.netScore,
      to: item.netTo,
      scw: "1",
      from: item.netFrom,
      rank: item.netRank,
    })),
  };

  const convertedDataNetstring = JSON.stringify(convertedDataNet, null, 2)

  console.log("Converted CPU to JSON String: ", convertedDataNetstring);
  useEffect(() => { console.log("Form Data Basic: ", formDataBasic); }, [formDataBasic])

  // const handlewebcheckbox=(e: any) => { setFormDataBasic((prevData: any) => { if(e.target.label == 'web') { if(e.target.checked ) { return{...prevData, ApplicationType: 'Web' } } } else { return{...prevData, ApplicationType: 'Desktop'} } })} 

  const handleWebCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    setFormDataBasic((prevData: any) => {
      if (isChecked) { return { ...prevData, ApplicationType: 'Web', /* Web: true, Desktop: false */ }; }
      else {
        if (!prevData.Desktop) { return { ...prevData, ApplicationType: '', /* Web: false */ }; }
        else { return { ...prevData, /* Web: false */ }; }
      }
    });
  }

  const handleDesktopCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    setFormDataBasic((prevData: any) => {
      if (isChecked) { return { ...prevData, ApplicationType: 'Desktop', /* Desktop: true, Web: false */ }; }
      else {
        if (!prevData.Web) { return { ...prevData, ApplicationType: '', /* Desktop: false */ }; }
        else { return { ...prevData, /* Desktop: false */ }; }
      }
    });
  }

  <Stack horizontal tokens={{ childrenGap: 105, padding: 10 }}>
    <StackItem> <Body1> Application Type </Body1> </StackItem>
    <StackItem shrink grow>
      <CheckBox label="Web" checked={formDataBasic.Web} onChange={(e) => handleWebCheckbox(e)}/>
      <CheckBox label="Desktop" checked={formDataBasic.Desktop} onChange={(e) => handleDesktopCheckbox(e)}/>
    </StackItem>
  </Stack>

  const handleChange = (name: any, val: any) => { // Special handling for multi-select dropdowns (ApplicationType, UrlPatterns, ApplicationBinaries)
    if (name == 'applicationtype') {
      isChecked1 ? setIsChecked1(!isChecked1) : setIsChecked1(!isChecked1)
      console.log("Here check inside: ", isChecked1);
      // Handle multi-select dropdown logic and Assuming your dropdown values are arrays (e.g., ApplicationType: [], UrlPatterns: [])
      // const selectedOptions = Array.from(e.target.selectedOptions, (option: any) => option.value); setFormDataBasic((prevData) => ({ ...prevData, [name]: selectedOptions}));
    } else { setFormDataBasic((prevData: any) => ({ ...prevData, [`${name}`]: val, })); /* Handle regular dropdowns */ }
  };

  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  interface FormMem { id: number; data: {}; }
  console.log("Row ID: ", rowID);

  interface FormNet { id: number; data: {}; }
  var menuItems: any = [];

  // const array = options; let options1: any = ['Business Application','Productivity Application','Collaboration Tools','Developer Tools']; const array1 = options; var dropdownData1 = [ { key: "businessapplications", label: <Body1 onClick={() => {handleChange('Subcategory','Business Application');} }> Business Application </Body1> }, { key: "productapplications", label: <Body1 onClick={() => handleChange('Subcategory','Productivity Application')}> Productivity Application </Body1> }, { key: "collaborationtools", label: <Body1 onClick={() => handleChange('Subcategory','Collaboration Tools')}>  Collaboration Tools </Body1> }, { key: "developers", label: <Body1 onClick={() => handleChange('Subcategory','Developer')}> Developer </Body1> }, ]; const dropdownOptions: IDropdownOption[] = [ { key: 'businessapplication', text: 'Business Application' }, { key: 'productapplication', text: 'Productivity Application' }, {key:'collaborationtool', text:'Collaboration Tools'}, {key:'developer',text:'Developer'} ];

  menuItems.push (
    <MenuItem key={0} onClick={() => navigate("/manage/devices/overview")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <Info20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Overview </Caption1> </StackItem>
        <StackItem> <Stack tokens={{ childrenGap: 80 }} horizontal> <StackItem><Text/></StackItem> <StackItem><Text/></StackItem> </Stack> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={1} onClick={() => navigate("/manage/devices/device_classification")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <GroupList20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Device Classification </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={2} onClick={() => navigate("/manage/devices/monitor")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <DesktopPulse20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Monitors </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={3} onClick={() => navigate("/manage/devices/remediation")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <Wrench20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Remediations </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={4} onClick={() => navigate("/manage/devices/compliancepolicies")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <ShieldQuestion20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={5} onClick={() => navigate("/manage/devices/conditionalaccess")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <LockClosedKey20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Conditional Access </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (
    <MenuItem key={6} onClick={() => navigate("/manage/devices/usersurvey")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <ChatMultipleHeart20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> User Survey </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push (<FluentProvider theme={lightTheme}>
    <MenuItem key={7} onClick={() => navigate("/manage/devices/datacollection")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <BookDatabase20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Data Collection </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  </FluentProvider>
  );

  menuItems.push (
    <MenuItem key={8} onClick={() => navigate("/manage/devices/activitylog")}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem><Text/></StackItem>
          <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
        </Stack>
      }>
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  ); // inline side menu content code ends here

  var columns = [ // data table column name code starts here
    { key: "category", label: <Body1Strong onClick={() => { setOverlayOpen(true); }}>&nbsp; Category </Body1Strong> },
    { key: "subcategory", label: <Body1Strong> Subcategory </Body1Strong> },
    { key: "name", label: <Body1Strong> Name </Body1Strong> },
    { key: "url_pattern", label: <Body1Strong> Url Patterns </Body1Strong> },
    { key: "desktop_pattern", label: <Body1Strong>  Desktop Binaries</Body1Strong> }
  ]; // data table column name code ends here

  var overFlowComponent = { // overflow set contents code starts here
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => { setCurrentPageNo(1); setIsRefresh(true); }}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1 > Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
        <StackItem>
          {/* <Button appearance="subtle" size="small" onClick={() => {userActivity('Export', `App Monitor List Exported`)}}> */}
          {/* <CSVLink id="csv-export-link" data={exportData} filename="appmonitored_list.csv"> 
            <Button appearance="subtle" size="small">
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}
          {
            permissions.includes("dataCollection_Read") ? (
              <TooltipHost content="This feature is not available for you!">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>
            ) : permissions.includes("dataCollection_Fullaccess") || permissions.includes("dataCollection_ReadandExport") ? (
              <CSVLink id="csv-export-link" data={exportData} filename="appmonitored_list.csv">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>

            ) : null
          }

        </StackItem>
      </>
  }; // overflow set contents code ends here

  useEffect(() => {
    console.log("Category Options: ", dropDownValue1, "Object.keys(categoryData)");
    if (dropDownValue1?.length > 0) { setFormDataBasic({ ...formDataBasic, Category: dropDownValue1[0] }); }
    else { console.log("Cat else"); }
  }, [dropDownValue1]);

  var tempTableData: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        category: val.Category,
        subcategory: val.subcategory,
        name: val.MetricDesc,
        url_pattern: val.UrlPatters,
        desktop_pattern: val.SpecificInfo
      });
    });
    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => { // data table values append code starts here
    console.log("Final Data: ", final_data);
    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data.analytics_test_Scores.map((val: any, i: any) => {
        // tempTableData.push({
        //   id: val.id,
        //   checkbox: "hide",
        //   category: { label: <Button appearance="transparent" size="small" onClick={() => { localStorage.setItem('selectedapp', JSON.stringify({ name: val.MetricDesc, rowid: val.id, url_pattern: val.UrlPatters, desktop_binaries: val.SpecificInfo })); setBinaries(true); navigate("/manage/devices/datacollections") }}> {val.Category} </Button >, id: `${i}` },
        //   subcategory: { label: <Caption1> {val.subcategory} </Caption1>, status: "" },
        //   name: { label: <Caption1> {val.MetricDesc} </Caption1> },
        //   url_pattern: { label: <Caption1> {val.UrlPatters != null ? val.UrlPatters:"-"} </Caption1> },
        //   desktop_pattern: { label: <Caption1> {val.SpecificInfo} </Caption1> },
        // });

        const categoryLabel = permissions.includes("dataCollection_Read") ? (
          <TooltipHost content="This feature is not available for you!">
            <Button appearance="transparent" size="small"> {val.Category} </Button>
          </TooltipHost>
        ) : (
          <Button
            appearance="transparent"
            size="small"
            onClick={() => {
              localStorage.setItem('selectedapp', JSON.stringify({
                name: val.MetricDesc,
                rowid: val.id,
                url_pattern: val.UrlPatters,
                desktop_binaries: val.SpecificInfo
              }));
              setBinaries(true);
              navigate("/manage/devices/datacollections");
            }}
          >
            {val.Category}
          </Button>
        );
        
        tempTableData.push({
          id: val.id,
          checkbox: "hide",
          category: { label: categoryLabel, id: `${i}` },
          subcategory: { label: <Caption1> {val.subcategory} </Caption1>, status: "" },
          name: { label: <Caption1> {val.MetricDesc} </Caption1> },
          url_pattern: { label: <Caption1> {val.UrlPatters != null ? val.UrlPatters:"-"} </Caption1> },
          desktop_pattern: { label: <Caption1> {val.SpecificInfo} </Caption1> },
        });
      }) : (tempTableData = []);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]); // data table values append code ends here

  useEffect(() => {
    !overlayOpen && setOverlayName('');
    console.log("Overlay Name: ", overlayName);
  }, [overlayOpen]);

  useEffect(() => { contextValue.setSelectedRowIdInTable([]); }, []);

  useEffect(() => {
    console.log("Main Table: ", contextValue.selectedRowIdInTable);
    console.log("Table Length: ", contextValue.selectedRowIdInTable.length);
    console.log("Sub Table: ", contextValue.selectedRowIdInSubTable);
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  // const userActivity = (action: String, activity: String, status: any = 'success') => { setUserActivityData({pageName: 'App monitor', moduleName: "Apps", action: action, time: Math.floor(Date.now() / 1000), activity: activity, status: status}); setTracker(true); } useEffect(() => { if(saveBtnClicked) { overlayName == 'addMonitoredApp' && userActivity('Add', `{"applicationname":"${formDataBasic.ApplicationName}","configuration":${JSON.stringify(formDataBasic)},"summary":"${formDataBasic.ApplicationName} app is added"}`); overlayName == 'editMonitoredApp' && userActivity('Edit', `{"applicationname":"${formDataBasic.ApplicationName}","configuration":${JSON.stringify(formDataBasic)},"summary":"${formDataBasic.ApplicationName} app is edited"}`); } }, [saveBtnClicked]);

  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  
  return (
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="" subPageLink="#"/> </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink>
                <Button
                  style={fullscreenIcon}
                  appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                  title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                  icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
               />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem> {/* pages code starts here */}
        <FluentProvider theme={webLightTheme}>
          <Stack verticalAlign="center">
            <StackItem> {/* <Text> <Breadcrumbs pageName="Apps"/> </Text> */} </StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem><Text/></StackItem>
              <StackItem> <Title3> Devices | Data Collection </Title3> </StackItem> {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>
            <Stack tokens={{ childrenGap: 20 }}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
            <Stack horizontal> {/* SPACING BETWEEN INLINE AND PAGE */}
              <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
              <StackItem>
                <StackItem><Text/></StackItem>
                <Stack tokens={{ childrenGap: 1 }}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
                <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                    { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                  </StackItem>
                  <StackItem>
                    <StackItem>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                        {/* <StackItem>
                          <MenuDropdown
                            btn={
                              <MenuButton shape="circular" size="small">
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon}/>
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            }
                            itemVal={
                              <>
                                <Body1Strong> Add a Filter </Body1Strong>
                                <Divider/>
                                <MenuList>
                                  <Stack tokens={{ childrenGap: 5 }}>
                                    <StackItem><Text/></StackItem>
                                    <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                    <DropdownComponent optionData={columns}/>
                                    <StackItem><Divider/></StackItem>
                                    <StackItem> <Caption1> Value </Caption1> </StackItem>
                                    <DropdownComponent optionData={columns}/>
                                    <StackItem><Divider/></StackItem>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                      <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                                      <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                    </Stack>
                                  </Stack>
                                </MenuList>
                              </>
                            }
                         />
                        </StackItem> */}
                        {/* <StackItem>
                          <Button appearance="subtle" size="small">
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem> */}
                      </Stack>
                    </StackItem>
                    <Stack tokens={{ childrenGap: 10 }}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
                    <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                      <StackItem> <Caption1>  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1> </StackItem>
                      <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                    </Stack>
                  </StackItem>

                  {!saveBtnClicked && <App_Monitored_Func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} setDeleteDisabled={setDeleteDisabled} deleteAppMonitored={deleteAppMonitored} setDeleteAppMonitored={setDeleteAppMonitored} setOverlayOpen={setOverlayOpen} setFormData={setFormDataBasic} formdata={formDataBasic} overlayName={overlayName} saveBtnClicked={saveBtnClicked} setsaveBtnClicked={setSaveBtnClicked} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setRefreshToast={setRefreshToast} setExportDetails={setExportDetails} deviceName={deviceName}/* setOverlayOpen={setOverlayOpen} *//>}
                  {tableData.length > 0 && <DataTable tableData={tableData} columns={columns}/>}

                  {isBinaries && <Data_Collection_func setBinaries={setBinaries} isBinaries={isBinaries}/>}
                  {/* {isBinaries && console.log("Data Collection Called")} {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog"/>} */}
                </Stack>
              </StackItem>
            </Stack>
            <OverLay isNavBtn={true} overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} saveBtnFunc={setSaveBtnClicked} overlayData={monitoredOverLayData} handleOutsideButton={handleOutsideButton}/>
            {saveBtnClicked && <ToastComponent setmessagebar={setIsToast} isToastEnabled={isToast} toastIntent="success" position="top-end" title="Success" message={(!saveBtnClicked && overlayName == '') ? "Monitored App Deleted Successfully" : overlayName == 'addMonitoredApp' ? "New Monitoring App Added Successfully" : "Edited App Monitor Successfully"} footer=""/>}
            {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer=""/>}
            {deleteAppMonitored && <ToastComponent setmessagebar={setIsToast} isToastEnabled={isToast} toastIntent="success" position="top-end" title="Success" message={"Monitored App Deleted Successfully"} footer=""/>}
          </Stack>
        </FluentProvider>
      </StackItem>
    </FullScreen>
  );
};