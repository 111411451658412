import { useState, useEffect, useContext } from "react";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from "react-csv";
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Card, ButtonProps } from "@fluentui/react-components";
import { LinkSquare20Regular, Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Save20Regular, Info20Filled, ArrowMaximize20Regular, DismissSquare24Filled, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { ToastComponent } from "../../../components/toast/toast.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Data_Collection_func } from "../../../api/data_collection/data_collection_func";
import { GlobalContext } from "../../../hooks/globalContext";
import DialogBox from "../../../components/dialog/dialog.component";
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, exitIcon, overflowDivider } from "../../../styles/customStyles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";
import { Remediations_scheduler_func } from "../../../api/devices/remediations_scheduler_func";
import { Data_Collections_func } from "../../../api/data_collection/data_collections_func";

const handleExportCSV = () => { // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById("csv-export-link");
  if (csvExportLink) { csvExportLink.click() }
};

export const DataCollections = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [messagebar2, setmessagebar2] = useState(false);
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [isSaveClicked, setSaveClicked] = useState(false);
  const [deviceCnt, setDeviceCnt]: any = useState(0);
  const [previousSelectedGroups, setPreviousSelectedGroups]: any = useState([]);
  const [groupDeviceCnt, setGroupDeviceCnt]: any = useState({});
  const [tableSelected, setSelected]: any = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messagebar1, setmessagebar1] = useState(false);
  const [defaultSelectedRows, setDefaultSelectedRows]: any = useState([]);
  const [rowID, setRowID]: any = useState(null);
  const [leftOpen, setLeftOpen] = useState(false);
  const [attachmentGrp, setAttachmentGrp]: any = useState([]);
  const [detachmentGrp, setDetachmentGrp]: any = useState([]);
  const [allAttachmentGrp, setAllAttachmentGrp] = useState(['']);
  const [allDetachmentGrp, setAllDetachmentGrp] = useState(['']);

  // console.log("Checking messagebar1 value: ", messagebar1); console.log("Checking messagebar2 value: ", messagebar2)
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const handleBothSetMessage = () => {
    setmessagebar1(false);
    setmessagebar2(true);
  };

  // const handleSaveActive: any = (data: any) => { setSaveEnabled(data.target.checked); onsole.log("Set Save Active Called: ", data.target.checked); };

  const handleButtonClick = () => { setShowMessage(true); };

  const truncateSelectedDevices = (selectedDevices: string[]) => {
    const MAX_DISPLAY = 3;
    if (selectedDevices.length > MAX_DISPLAY) {
      const truncatedList = selectedDevices.slice(0, MAX_DISPLAY);
      truncatedList.push(`${selectedDevices.length - MAX_DISPLAY}+ more`);
      return truncatedList;
    }; return selectedDevices;
  };

  var selectedapp = localStorage.getItem("selectedapp");
  let parsedData: any = null; // Initialize parsedData as null initially
  if (selectedapp !== null && selectedapp !== "") { parsedData = JSON.parse(selectedapp); /* Check if selectedapp is not null or empty and Parse the JSON string */ }
  const nameValue = parsedData?.name;
  const selectedId = parsedData?.rowid;
  console.log("Selected ID: ", selectedId);

  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];

  useEffect(() => {
    console.log("Selected Classification: ", contextValue.selectedRowIdInTable);
    contextValue.selectedRowIdInTable.length > 0 ? setSaveEnabled(true) : setSaveEnabled(false);
    setSelected(contextValue.selectedRowIdInTable);
  }, [contextValue.selectedRowIdInTable.length]);

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text /></StackItem>
            <StackItem> <LinkSquare20Regular {...primaryIcon} /> </StackItem>
          </Stack>
        }>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text /></StackItem>
          <StackItem> <Caption1> Assignment </Caption1> </StackItem>
          <StackItem> <Stack tokens={{ childrenGap: 80 }} horizontal> <StackItem><Text /></StackItem> <StackItem><Text /></StackItem> </Stack> </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>
  ); // inline side menu content code ends here

  // menuItems.push ( <MenuItem icon={ <Stack horizontal tokens={{childrenGap:40}}> <StackItem><Text/></StackItem> <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem> </Stack> }> <Stack tokens={{childrenGap:30}} horizontal> <StackItem><Text/></StackItem> <StackItem> <Caption1> Audit Log </Caption1> </StackItem> </Stack> </MenuItem> );

  var columns = [ // data table column name code starts here
    { key: "group_name", label: <Body1Strong> Group Name </Body1Strong> },
    { key: "device_cnt", label: <Body1Strong> Count of Devices </Body1Strong> },
  ]; // data table column name code ends here

  var overFlowComponent = { // overflow set contents code starts here
    data: (
      <>
        <StackItem>
          <DialogBox
            headerButton={
              <Button appearance="transparent" size="small"
                disabled={isSaveEnabled ? false : true} >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <Save20Regular {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Save </Caption1> </StackItem>
                </Stack>
              </Button>
            }
            // console.log("Previous Selected Groups: ", previousSelectedGroups, "Checked: ", contextValue.selectedRowIdInTable, "Attached: ", attachmentArr, "Unattached: ", detachmentArr); content= {(!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) ?  "Please confirm your decision to proceed with this change. The policy will be assigned to 'All devices'." : (previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.length == 0) ?  "Please confirm your decision to proceed with this change. The policy will be removed from 'All devices'." : `This change will be pushed to all connected devices that belong to the following groups:\r\n${contextValue.selectedRowIdInTable.join(",")}`}
            content={
              <Stack tokens={{ childrenGap: 5 }}>
                <StackItem>
                  {(!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices'))
                    ? "Are you sure you want to enable the automation for all devices?"
                    : (previousSelectedGroups.length > 0 && contextValue.selectedRowIdInTable.length === 0)
                      ? `Are you sure you want to detach the automation?`
                      : `Are you sure you want to enable the automation for the selected device classifications:\r\n${truncateSelectedDevices(contextValue.selectedRowIdInTable).join(', ')}`
                  }
                </StackItem>
              </Stack>
            }

            footer={
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <StackItem> <Button appearance="primary" onClick={() => { handleBothSetMessage(); setSaveClicked(true) }} size="small"> Confirm </Button> </StackItem>
                <StackItem> <Button size="small" onClick={() => { setmessagebar1(false); }}> Cancel </Button> </StackItem>
              </Stack>
            }
          />
        </StackItem>
        <StackItem><Divider vertical /></StackItem>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon} /> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="datacollection_assignemntlist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem> {/* <StackItem> <Button appearance="subtle" size="small"> <Stack tokens={{childrenGap:5}} horizontal> <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem> <StackItem> <Caption1> Manage View </Caption1> </StackItem> </Stack> </Button> </StackItem> */}
      </>
    ),
  }; // overflow set contents code ends here

  // var tempTableData: any = []; var menuLists: any = []; var tempExportData: any = []; useEffect(() => { setmessagebar2(false); final_data !== undefined && final_data !== null ? final_data.core_Census.map((val: any, i: any) => { menuLists.push(); tempExportData.push({ device_name: val.host, manufacturer: val.site, os: val.os, osversion: val.osversion, nanoheal_version: val.clientversion, installation_date: new Date(val.born * 1000).toLocaleString(), last_event: new Date(val.last * 1000).toLocaleString(), }); tempTableData.push({ device_name: { label: <Caption1> {val.host} </Caption1>, icon: ( <Badge size="extra-small" appearance="filled" color="success"/> ), id: `${i}`, }, manufacturer: { label: <Caption1> {val.site} </Caption1>status: "", }, os: { label: <Caption1> {val.os} </Caption1>}, osversion: { label: <Caption1> {val.clientversion} </Caption1>}, nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1>}, installation_date: { label: <Caption1> {val.born} </Caption1>}, last_event: { label: <Caption1> {val.last} </Caption1>}, }); }) : (tempTableData = []); setExportData(tempExportData); tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []); }, [final_data]);

  var tempTableData: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();


    setmessagebar2(false);
    var defaultSelectedIds: any = [];
    deviceCnt > 0 && tempTableData.push({
      id: 'All Devices',
      group_name: { label: <Caption1> All Devices </Caption1>, id: 1, },
      device_cnt: { label: <Caption1> {deviceCnt} </Caption1>, status: "", }
    });
    previousSelectedGroups.includes('All Devices') && defaultSelectedIds.push(0);
    final_data !== undefined && final_data !== null
      ? final_data.core_DeviceClassification.map((val: any, i: any) => {
        tempExportData.push({
          group_name: val.classificationName,
          device_cnt: groupDeviceCnt[`${val.classificationName}`]
        });
        previousSelectedGroups.includes(val.classificationName) && defaultSelectedIds.push(i + 1);
        // tempTableData.push({
        //   id: val.classificationName,
        //   group_name: { label: <Caption1> {val.classificationName} </Caption1>, id: `${i}`, },
        //   device_cnt: { label: <Caption1> {groupDeviceCnt[`${val.classificationName}`]} </Caption1>, status: "" }
        // });

        const featureAvailable = permissions.includes("dataCollection_Fullaccess");

        const groupNameLabelContent = featureAvailable ?
          val.classificationName :
          <TooltipHost content="This feature is not available for you!">{val.classificationName}</TooltipHost>;

        tempTableData.push({
          id: val.classificationName,
          checkbox: featureAvailable ? "show" : "hide",
          group_name: {
            label: <Caption1>{groupNameLabelContent}</Caption1>,
            id: `${i}`
          },
          device_cnt: {
            label: <Caption1>{groupDeviceCnt[val.classificationName]}</Caption1>,
            status: ""
          }
        });




      }) : (tempTableData = []);
    setExportData(tempExportData);
    setDefaultSelectedRows(defaultSelectedIds);
    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data, groupDeviceCnt, previousSelectedGroups]); // data table values append code ends here

  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={() => setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };

  return (
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="Data Collection" subPageLink="/manage/devices/datacollection" /> </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink>
                <Button
                  style={fullscreenIcon}
                  appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                  title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                  icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem> {/* pages code starts here */}
            <Stack verticalAlign="center">
              <Stack tokens={{ childrenGap: 25 }} horizontal>
                <StackItem><Text /></StackItem>
                <StackItem> <Title3> {nameValue} </Title3> </StackItem>
              </Stack>
              <Stack tokens={{ childrenGap: 20 }}><StackItem><Text /></StackItem><StackItem><Text /></StackItem></Stack>
              <Stack horizontal> {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem><Text /></StackItem>
                  <Stack tokens={{ childrenGap: 1 }}><StackItem><Text /></StackItem><StackItem><Text /></StackItem></Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear" />} />
                          <StackItem>
                            <MenuDropdown
                              btn={
                                <MenuButton shape="circular" size="small">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem><Text /></StackItem>
                                      <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                      <DropdownComponent optionData={columns} />
                                      <StackItem><Divider /></StackItem>
                                      <StackItem> <Caption1> Value </Caption1> </StackItem>
                                      <DropdownComponent optionData={columns} />
                                      <StackItem><Divider /></StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal >
                                        <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                                        <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            <Button appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem> <FilterDismiss20Filled /> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}><StackItem><Text /></StackItem><StackItem><Text /></StackItem></Stack>
                      <Stack horizontal>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem> <Info20Filled {...primaryIcon} /> </StackItem>
                                <StackItem> <Caption1> If you choose the option 'All devices' The policy will be applied universally to all devices and will become detached from Devices groups. </Caption1> </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem><StackItem></StackItem>
                      </Stack>
                      <Stack tokens={{ childrenGap: 10 }}><StackItem><Text /></StackItem><StackItem><Text /></StackItem></Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> <Caption1> Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of {totalRecords} Records </Caption1> </StackItem>
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} /> </StackItem>
                      </Stack>
                    </StackItem>
                    {/* <Census_func  data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 10} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords}/> */}
                    <Data_Collection_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} setDeviceCnt={setDeviceCnt} isSaveClicked={isSaveClicked} setSaveClicked={setSaveClicked} groupDeviceCnt={groupDeviceCnt} setGroupDeviceCnt={setGroupDeviceCnt} setPreviousSelectedGroups={setPreviousSelectedGroups} tableSelected={tableSelected}/* isRefresh={isRefresh} setRefresh={setRefresh} setRefreshToast={setRefreshToast} */ />
                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}
                    {isSaveClicked && 
                        <Data_Collections_func
                          saveBtnClicked={isSaveClicked}
                          setSaveBtnClicked={setSaveClicked}
                          setmessagebar1={setmessagebar1}
                          setmessagebar2={setmessagebar2}
                          groupAttachment={attachmentGrp}
                          groupDetachment={detachmentGrp}
                          setAllAttachmentGrp={setAllAttachmentGrp}
                          setAllDetachmentGrp={setAllDetachmentGrp}
                        />
                      }
                  </Stack>
                </StackItem>
              </Stack>
            </Stack>
          </StackItem>
          {/* <StackItem> {messagebar1 && ( <ToastComponent setmessagebar={setmessagebar1}  toastIntent="warning" position="top" title="Please confirm your decision to proceed with change. the policy will be assigned to 'All devices'." footer={ <> <Button appearance="primary" onClick={handleBothSetMessage}> Confirm </Button> <Button onClick={() => { setmessagebar1(false); }}> Cancel </Button> </> }/> )} </StackItem> */}
          <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census" />
          {messagebar2 && <ToastComponent setmessagebar={setmessagebar2} toastIntent="success" position="top-end" title="Success" message="Assignment Details have been revised" footer="" />}
          <StackItem><Text /></StackItem>
        </Stack>
      </FluentProvider>
    </FullScreen>
  );
};