import { useState, useEffect, useContext } from 'react';
import { Census_func } from '../../../api/devices/census_func';
import { Groups_func } from '../../../api/devices/groups_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Modal, Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Toast,
  ToastTitle,
  Toaster,
  useId,
  ToastPosition,
  useToastController,
  ToastBody,
  ToastTrigger,
  Link,
  ToastFooter,
  Field,
  ProgressBar,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Input,
  Label,
  Card,
  ButtonProps,
  Image,
  ToggleButton,
  Tooltip,
} from '@fluentui/react-components';
import {
  PhoneLaptop20Regular,
  BookNumber20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  AddSquare20Regular,
  SlideEraser20Regular,
  Warning20Filled,
  Dismiss16Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  Info20Filled,
} from '@fluentui/react-icons';
import { AddSquare24Regular, Delete24Regular } from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { GlobalContext } from '../../../hooks/globalContext';
import { ToastComponent } from '../../../components/toast/toast.component';
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, exitIcon, overflowDivider, deleteIcon } from '../../../styles/customStyles';
import { TroubleshooterDevice_fun } from '../../../api/troubleshooterDevice_fun';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';

export const DeviceClassificationScores = () => {
  const contextValue: any = useContext(GlobalContext);

  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  };

  const [isModalIconEnabled, setModalIconEnabled] = useState(false);
  const handleModalIconActive: any = (data: any) => {
    setModalIconEnabled(data.target.checked);
  };

  const [messagebar, setmessagebar] = useState(false);
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [dropDownValue1, setDropDownValue1] = useState<any[]>([]);
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [scoresCpuRequired, setScoreCpuRequired] = useState(false);
  const [scoresMemRequired, setScoreMemRequired] = useState(false);
  const [scoresNetRequired, setScoreNetRequired] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [deviceList, setDeviceList]: any = useState(null);
  const [permissions, setPermissions] = useState(['']);

  const [formDataBasic, setFormDataBasic]: any = useState({
    Category: '',
    Subcategory: '',
    ApplicationName: '',

    ApplicationType: '',
    UrlPatterns: [
      {
        // urlpattern: '',
      },
    ],
    ApplicationBinaries: '',
    cpuUtilisation: [
      {
        cpuFrom: '',
        cpuTo: '',
        cpuScore: '',
        cpuRank: '',
      },
    ],
    memUtilisation: [
      {
        memFrom: '',
        memTo: '',
        memScore: '',
        memRank: '',
      },
    ],
    netUtilisation: [
      {
        netFrom: '',
        netTo: '',
        netScore: '',
        netRank: '',
      },
    ],
  });

  const inputId = useId('input');

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  //   const handleOutsideButton = () => {
  //     buttonref?.current?.click();
  //  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const handleInput = (value: any, processName: String, compName: any, index: number) => {
    if (processName == 'cpuUtilisation' && value != '') {
      setScoreCpuRequired(true);
    } else {
      setScoreCpuRequired(false);
    }
    if (processName == 'memUtilisation' && value != '') {
      setScoreMemRequired(true);
    } else {
      setScoreMemRequired(false);
    }
    if (processName == 'netUtilisation' && value != '') {
      setScoreNetRequired(true);
    } else {
      setScoreNetRequired(false);
    }

    setFormDataBasic({
      ...formDataBasic,
      [`${processName}`]: [
        ...formDataBasic[`${processName}`].slice(0, index),
        { ...formDataBasic[`${processName}`][index], [`${compName}`]: value },
        ...formDataBasic[`${processName}`].slice(index + 1),
      ],
    });
  };
  const handleAddClone = (index: any, processName: String) => {
    if (processName == 'cpuUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
      });
    } else if (processName == 'memUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { memFrom: '', memTo: '', memScore: '', memRank: '' }],
      });
    } else if (processName == 'netUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { netFrom: '', netTo: '', netScore: '', netRank: '' }],
      });
    } else if (processName == 'UrlPatterns' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({ ...formDataBasic, [`${processName}`]: [...formDataBasic[`${processName}`], {}] });
    }
  };
  const handleRemoveClone = (id: any, processName: String) => {
    var tempArr = formDataBasic[`${processName}`].filter((val: any, i: any) => i !== id);

    setFormDataBasic({ ...formDataBasic, [`${processName}`]: tempArr });
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };

  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  var menuItems: any = [];

  const toasterId = useId();
  const { dispatchToast } = useToastController(toasterId);
  const [position, setPosition] = React.useState<ToastPosition>('top-end');

  const deleteToast = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>
                <Dismiss16Regular />
              </Link>
            </ToastTrigger>
          }
        >
          Sucess
        </ToastTitle>
        <ToastBody> Device Classification Chem Factory Worker - 001 is deleted succesfully </ToastBody>
      </Toast>,
      { position, intent: 'success' },
    );

  const configModal = () => <Modal>HEY</Modal>;

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/device_classification/devices')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <PhoneLaptop20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Devices </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 80 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/manage/devices/device_classification/scores')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <BookNumber20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Scores </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/device_classification/audit_log')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Audit Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'classification_name', label: <Body1Strong>&nbsp; Category </Body1Strong> },
    { key: 'created_by', label: <Body1Strong>&nbsp; Subcategory </Body1Strong> },
    { key: 'created_on', label: <Body1Strong>&nbsp; Name </Body1Strong> },
    { key: 'last_modifed', label: <Body1Strong>&nbsp; URL Patterns </Body1Strong> },
    { key: 'linked_devices', label: <Body1Strong>&nbsp; Desktop Binaries </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter
  var columns1 = [
    { key: '', label: <Caption1> Application verison </Caption1> },
    { key: 'category', label: <Caption1> Category </Caption1> },
    { key: '', label: <Caption1> Installed On </Caption1> },
    { key: 'created_by', label: <Caption1> Subcategory </Caption1> },
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'last_modifed', label: <Caption1> URL </Caption1> },
    { key: 'desktop_pattern', label: <Caption1> Binaries </Caption1> },
    { key: 'last_modifed', label: <Caption1> Scores </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem>
            {permissions.includes('deviceClassification_Read') || permissions.includes('deviceClassification_ReadandExport') ? (
              <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 6 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem>
                          <AddSquare20Regular {...primaryIcon} />
                        </StackItem>
                        <StackItem>
                          <Caption1>Modify Scores </Caption1>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              </Tooltip>
            ) : permissions.includes('deviceClassification_Fullaccess') ? (
              <Button
                appearance="subtle"
                size="small"
                onClick={() => {
                  setOverlayOpen(true);
                }}
              >
                <Stack tokens={{ childrenGap: 6 }}>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <AddSquare20Regular {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1>Modify Scores </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            ) : null}
          </StackItem>

          {/* <StackItem>

            <Button appearance="subtle" size="small" onClick={() => {setOverlayOpen(true)}}>
                <Stack tokens={{childrenGap:6}}>
                    <StackItem><Text></Text></StackItem>
                    <StackItem>
                    <Stack tokens={{childrenGap:5}} horizontal>
                        <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                        <StackItem> <Caption1>Modify Scores </Caption1> </StackItem>
                    </Stack>
                    </StackItem>
                </Stack>
            </Button>
          </StackItem> */}

          <StackItem>
            <Divider inset vertical />
          </StackItem>

          <Stack tokens={{ childrenGap: 6 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Button
                appearance="subtle"
                size="small"
                onClick={() => {
                  setCurrentPageNo(1);
                  setIsRefresh(true);
                }}
              >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowClockwise20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Refresh </Caption1>
                  </StackItem>
                </Stack>
              </Button>

              {permissions.includes('deviceClassification_Read') ? (
                <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <ArrowDownload20Regular {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </Button>
                </Tooltip>
              ) : permissions.includes('deviceClassification_Fullaccess') || permissions.includes('deviceClassification_ReadandExport') ? (
                <CSVLink id="csv-export-link" data={exportData} filename="device_scoreslist.csv">
                  <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <ArrowDownload20Regular {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </Button>
                </CSVLink>
              ) : (
                <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <ArrowDownload20Regular {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </Button>
                </Tooltip>
              )}

              {/* <CSVLink id="csv-export-link" data={exportData} filename="device_scoreslist.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink> */}

              {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
            </StackItem>
          </Stack>
        </Stack>
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);

    //roles and permissions
    async function loadRoute() {
      const role = 'Nano_test';
      try {
        console.log('role ------------>>', role);
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions);
          console.log('Route loaded successfully.', permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
          tempExportData.push({
            classification_name: val.host,
            created_by: val.site,
            created_on: val.created_on,
            osversion: val.osversion,
            linked_devices: val.clientversion,
            last_modifed: new Date(val.born * 1000).toLocaleString(),
            config_group: new Date(val.last * 1000).toLocaleString(),
          });

          tempTableData.push({
            id: val.host,
            checkbox: 'hide',
            classification_name: { label: <Caption1> {val.host} </Caption1>, icon: '', id: `${i}` },
            created_by: { label: <Caption1> {val.site} </Caption1>, icon: '', status: '' },
            created_on: { label: <Caption1> {val.os} </Caption1>, icon: '' },
            osversion: { label: <Caption1> {val.clientversion} </Caption1>, icon: '' },
            linked_devices: { label: <Caption1> {val.clientversion} </Caption1>, icon: '' },
            last_modifed: { label: <Caption1> {val.born} </Caption1>, icon: '' },
            config_group: { label: <Caption1> {val.last} </Caption1>, icon: '' },
          });
        })
      : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  const [showMessage, setShowMessage] = useState(false);

  //overlay code starts here
  var overLayData: any = {
    size: 'large',
    isTabs: true,
    headerTitle: (
      <>
        <FluentProvider theme={webLightTheme}>
          <Title3> Business Applications </Title3>
        </FluentProvider>
      </>
    ),

    tabItems: {
      Scores: (
        <form id="myFormAppmonitored" onSubmit={handleSubmit}>
          <>
            <Stack>
              <StackItem>
                <FluentProvider theme={messageTheme}>
                  <Card appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                      <StackItem>
                        <Info20Filled {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Here you can assign scores </Caption1>
                      </StackItem>
                    </Stack>
                  </Card>
                </FluentProvider>
              </StackItem>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                {/* <StackItem>
             <Stack tokens={{childrenGap:10}} horizontal>
               <StackItem><Text></Text></StackItem>
               <StackItem> <Body1Strong>Data Classification</Body1Strong> </StackItem>
             </Stack>
               <Divider/>    
         <StackItem>
           
               <Stack tokens={{childrenGap:5}} horizontal>
               <StackItem onClick={() => setDeviceName('All Device')}><Button appearance="transparent" size="small"><Body1 >All&nbsp;Devices&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Body1></Button> </StackItem>

               </Stack>
         
            <Stack tokens={{ childrenGap: 3 }} verticalFill>
                { 
                  deviceList !== undefined && deviceList !== null
                    ? deviceList && deviceList.core_DeviceClassification.map((val: any, i: any) => {
                      const deviceData = val && val.classificationName;
                      return <StackItem onClick={() => setDeviceName(deviceData)}> 
                      <ToggleButton appearance="subtle" size="small"><Body1>{deviceData}</Body1></ToggleButton> </StackItem>;
                  }) : (console.log("null"))
                } 
            </Stack>
         </StackItem>
      </StackItem> */}
                {/* <TroubleshooterDevice_fun
      groupData={deviceList}
      groupFunc={setDeviceList}
      data={final_data1}
      func={set_final_data1}
      searchVal={searchVal}
      pageNo={(currentPageNo - 1) * 10}
      setTotalPages={setTotalPages}
      setTotalRecords={setTotalRecords}
      groupName={deviceName}
      setGroupName={setDeviceName}
      setallDevice={setallDevice}
    /> */}
                {/* </Stack> */}
              </StackItem>
              {/*this is the second Stack of the overlay page it contain datatable  */}
              <StackItem>
                <Stack tokens={{ childrenGap: 10 }}>
                  <FluentProvider theme={webLightTheme}>
                    <Stack horizontal tokens={{ childrenGap: 15, padding: 5 }}>
                      <StackItem>
                        <Body1Strong>
                          Application Scores <Image src="/visualization/v2/image/required.svg" />
                        </Body1Strong>
                      </StackItem>
                      <StackItem
                        onClick={() =>
                          setFormDataBasic({
                            Category: '',
                            Subcategory: '',
                            ApplicationName: '',

                            ApplicationType: '',
                            UrlPatterns: [
                              {
                                // urlpattern: '',
                              },
                            ],
                            ApplicationBinaries: '',
                            cpuUtilisation: [
                              {
                                cpuFrom: '',
                                cpuTo: '',
                                cpuScore: '',
                                cpuRank: '',
                              },
                            ],
                            memUtilisation: [
                              {
                                memFrom: '',
                                memTo: '',
                                memScore: '',
                                memRank: '',
                              },
                            ],
                            netUtilisation: [
                              {
                                netFrom: '',
                                netTo: '',
                                netScore: '',
                                netRank: '',
                              },
                            ],
                          })
                        }
                      >
                        <Button appearance="primary" size="small">
                          <Caption1>Reset</Caption1>
                        </Button>
                      </StackItem>
                    </Stack>
                    <Stack tokens={{ childrenGap: 25 }}>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Body1>&nbsp;Process Cpu Utilisation</Body1>
                        </StackItem>
                        <StackItem>
                          {formDataBasic.cpuUtilisation?.map((formCpu: any, i: any) => (
                            <Stack key={i} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                {/* From and To fields in one row */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* From */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="From"
                                        type="number"
                                        value={formCpu.cpuFrom}
                                        onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuFrom', i)}
                                        required={scoresCpuRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* To */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="To"
                                        type="number"
                                        id={inputId}
                                        value={formCpu.cpuTo}
                                        onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuTo', i)}
                                        required={scoresCpuRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>

                              {/* Score and Rank fields in another row */}
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Score */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Score"
                                        type="number"
                                        id={inputId}
                                        value={formCpu.cpuScore}
                                        onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuScore', i)}
                                        required={scoresCpuRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Rank */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Rank"
                                        type="number"
                                        id={inputId}
                                        value={formCpu.cpuRank}
                                        onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuRank', i)}
                                        required={scoresCpuRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                {/* Add or Remove icons */}
                                {i === 0 ? (
                                  <StackItem>
                                    <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'cpuUtilisation')} />
                                  </StackItem>
                                ) : (
                                  <StackItem>
                                    <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'cpuUtilisation')} />
                                  </StackItem>
                                )}
                              </Stack>
                            </Stack>
                          ))}
                        </StackItem>
                      </Stack>

                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Body1>&nbsp;Process Mem Utilisation</Body1>
                        </StackItem>
                        <StackItem>
                          {formDataBasic.memUtilisation.map((formMem: any, i: any) => (
                            <Stack key={i + 5} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                {/* From and To fields in one row */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* From */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="From"
                                        type="number"
                                        id={inputId}
                                        value={formMem.memFrom}
                                        onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memFrom', i)}
                                        required={scoresMemRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* To */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="To"
                                        type="number"
                                        id={inputId}
                                        value={formMem.memTo}
                                        onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memTo', i)}
                                        required={scoresMemRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>

                              {/* Score and Rank fields in another row */}
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Score */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Score"
                                        type="number"
                                        id={inputId}
                                        value={formMem.memScore}
                                        onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memScore', i)}
                                        required={scoresMemRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Rank */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Rank"
                                        type="number"
                                        id={inputId}
                                        value={formMem.memRank}
                                        onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memRank', i)}
                                        required={scoresMemRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                {/* Add or Remove icons */}
                                {i === 0 ? (
                                  <StackItem>
                                    <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'memUtilisation')} />
                                  </StackItem>
                                ) : (
                                  <StackItem>
                                    <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'memUtilisation')} />
                                  </StackItem>
                                )}
                              </Stack>
                            </Stack>
                          ))}
                        </StackItem>
                      </Stack>

                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Body1>&nbsp;Process Network Utilisation</Body1>
                        </StackItem>
                        <StackItem>
                          {formDataBasic.netUtilisation.map((formNet: any, i: number) => (
                            <Stack key={formNet.id} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                {/* From and To fields in one row */}
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* From */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="From"
                                        type="number"
                                        id={inputId}
                                        value={formNet.netFrom}
                                        onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netFrom', i)}
                                        required={scoresNetRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* To */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="To"
                                        type="number"
                                        id={inputId}
                                        value={formNet.netTo}
                                        onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netTo', i)}
                                        required={scoresNetRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>

                              {/* Score and Rank fields in another row */}
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Score */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Score"
                                        type="number"
                                        id={inputId}
                                        value={formNet.netScore}
                                        onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netScore', i)}
                                        required={scoresNetRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Caption1>{/* Rank */}</Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Label size="small" htmlFor={inputId} />
                                      <Input
                                        placeholder="Rank"
                                        type="number"
                                        id={inputId}
                                        value={formNet.netRank}
                                        onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netRank', i)}
                                        required={scoresNetRequired}
                                      />
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                {/* Add or Remove icons */}
                                {i === 0 ? (
                                  <StackItem>
                                    <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'netUtilisation')} />
                                  </StackItem>
                                ) : (
                                  <StackItem>
                                    <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'netUtilisation')} />
                                  </StackItem>
                                )}
                              </Stack>
                            </Stack>
                          ))}
                        </StackItem>
                      </Stack>
                    </Stack>
                  </FluentProvider>
                </Stack>
              </StackItem>
            </Stack>
          </>
          {/* <Button style={{display:"none"}} ref={buttonref} type="submit">Validate</Button> */}
        </form>
      ),
    },
  };
  //overlay code ends here
  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Devices" subPageName="Device Classification" subPageLink="/manage/devices/device_classification/" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <Stack tokens={{ childrenGap: 10 }} horizontal>
              <StackItem>
                {/* pages code starts here */}
                <Stack verticalAlign="center">
                  <Stack tokens={{ childrenGap: 25 }} horizontal>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <FluentProvider theme={webLightTheme}>
                        <Title3> {contextValue.selectedRowDetails.name} | Scores </Title3>
                      </FluentProvider>
                    </StackItem>
                  </Stack>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <FluentProvider theme={webLightTheme}>
                    <Stack horizontal>
                      {/* SPACING BETWEEN INLINE AND PAGE */}
                      <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                      <StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 1 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                        </Stack>
                        <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                            {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                          </StackItem>
                          <StackItem>
                            <StackItem>
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <SearchBox
                                  onChange={(e: any) => {
                                    setSearchVal(e.target.value);
                                  }}
                                  value={searchVal}
                                  placeholder="Search"
                                  size="small"
                                  dismiss={null}
                                  contentAfter={<DismissButton aria-label="Clear" />}
                                />
                                <StackItem>
                                  <MenuDropdown
                                    open={filterMenuOpen}
                                    btn={
                                      <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <Filter20Filled {...primaryIcon} />
                                          <Body1> Filter </Body1>
                                        </Stack>
                                      </MenuButton>
                                    }
                                    itemVal={
                                      <>
                                        <Body1Strong> Add a Filter </Body1Strong>
                                        <Divider />
                                        <MenuList>
                                          <Stack tokens={{ childrenGap: 5 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Caption1> Filter </Caption1>
                                            </StackItem>
                                            {/* <DropdownComponent optionData={columns}/> */}
                                            <DropdownComponent
                                              optionData={columns1}
                                              setDropDownValue={setDropDownValue1}
                                              dropDownvalue={dropDownValue1}
                                            />
                                            <StackItem>
                                              <Divider />
                                            </StackItem>
                                            <StackItem>
                                              <Caption1> Value </Caption1>
                                            </StackItem>
                                            {/* <DropdownComponent optionData={columns}/> */}
                                            <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                            <StackItem>
                                              <Divider />
                                            </StackItem>
                                            <Stack tokens={{ childrenGap: 10 }} horizontal>
                                              <StackItem>
                                                <Button
                                                  appearance="primary"
                                                  onClick={() => {
                                                    handleFilterArray();
                                                    setFilterMenuOpen(false);
                                                  }}
                                                >
                                                  <Caption1> Apply </Caption1>
                                                </Button>
                                              </StackItem>
                                              <StackItem>
                                                <Button onClick={() => setFilterMenuOpen(false)}>
                                                  <Caption1> Cancel </Caption1>
                                                </Button>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        </MenuList>
                                      </>
                                    }
                                  />
                                </StackItem>
                                <StackItem>
                                  {filterArray.length > 0 &&
                                    filterArray.map((item, index) => (
                                      <Button size="small" shape="circular">
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem> {item} </StackItem>
                                          <StackItem>
                                            <Stack tokens={{ childrenGap: 1 }}>
                                              <StackItem>
                                                <Text></Text>
                                              </StackItem>
                                              <StackItem>
                                                <Dismiss16Regular
                                                  onClick={() => {
                                                    handleCloseFilter(index);
                                                  }}
                                                />
                                              </StackItem>
                                            </Stack>
                                          </StackItem>
                                        </Stack>
                                      </Button>
                                    ))}
                                </StackItem>
                                <StackItem
                                  onClick={() => {
                                    setFilterArray([]);
                                    setIsFilter(false);
                                    setDropDownValue2('');
                                    setResetDisabled(true);
                                  }}
                                >
                                  <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem>
                                        <FilterDismiss20Filled />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Reset Filters </Caption1>
                                      </StackItem>
                                    </Stack>
                                  </Button>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                            </Stack>
                            <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                              <StackItem>
                                <Caption1>
                                  Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of
                                  {totalRecords} Records
                                </Caption1>
                              </StackItem>
                              <StackItem>
                                <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                              </StackItem>
                            </Stack>
                          </StackItem>
                          <Census_func
                            data={final_data}
                            func={set_final_data}
                            searchVal={searchVal}
                            pageNo={(currentPageNo - 1) * 10}
                            setTotalPages={setTotalPages}
                            setTotalRecords={setTotalRecords}
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            setRefreshToast={setRefreshToast}
                          />
                          {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}
                        </Stack>
                      </StackItem>
                    </Stack>
                  </FluentProvider>
                </Stack>
                <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} isNavBtn={true} />
                {refreshToast && (
                  <ToastComponent
                    setmessagebar={setIsToast}
                    isToastEnabled={refreshToast}
                    toastIntent="success"
                    position="top-end"
                    title="Success"
                    message={'Data Table Refreshed'}
                    footer=""
                  />
                )}
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem>
            <Text></Text>
          </StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};
