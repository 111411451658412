import { Link, Stack, StackItem } from '@fluentui/react';
import {
  Button,
  Text,
  Input,
  FluentProvider,
  webLightTheme,
  LargeTitle,
  Body1,
  Body2,
  Body1Strong,
  Skeleton,
  SkeletonItem,
  SkeletonProps,
  Title2,
  PresenceBadge,
  Image,
  Tooltip,
  Badge,
  Caption1Strong,
  Body1Stronger,
} from '@fluentui/react-components';
import { EyeOff20Filled, Eye20Filled, Dismiss16Filled, Checkmark16Filled, Info20Filled } from '@fluentui/react-icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SkeletonSignin } from '../../components/skeleton/skeletonSignin';
import { signLogo } from '../../styles/customStyles';
import { ToastComponent } from '../../components/toast/toast.component';
// import { formPosition, backgroundPosition } from '../../styles/customStyles';

interface User {
  [x: string]: string | undefined;
  firstName: string;
  lastName: string;
  email: string;

  password: string;
}

export const Signup = (props: Partial<SkeletonProps>) => {
  const Navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [user, setUser] = useState<User>({ email: '', lastName: '', firstName: '', password: '' });
  const [error, setError] = useState('');
  const [isOnline, setIsOnline] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messagebar2, setmessagebar2] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    // confirmPassword: ""
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };
  // const handleConfirmPasswordFocus = () => { setConfirmPasswordFocused(true); };

  // const [loginPage, magicLinkPage] = useState(false);
  // const [submissionPage , setSubmissionPage] = useState(false);

  const handleButtons = () => setShowSuccess(true);
  const HandlePassword = () => setForgetPass(true);
  // const EnablePage = () => magicLinkPage(true);
  // const SubmissionPageEnable = () => setSubmissionPage(true);

  const backgroundPosition: any = { position: 'fixed', right: 0, top: 0, width: '50%', height: '100%' };
  const formPosition: any = { position: 'fixed', left: 100, top: '10%', width: '20%' };
  const iconStyleProps: any = { primaryFill: '#008099' };
  useEffect(() => {
    const checkConnection = () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
    };
    checkConnection();
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  const checkPasswordMatch = (user: any) => {
    const reenteredPassword = user.reenteredPassword;
    const password = user.password;
    console.log('checking password = ', password);
    console.log('checking re enter password = ', reenteredPassword);
    if (reenteredPassword === password && reenteredPassword !== '' && password !== '') {
      return (
        <Badge appearance="ghost" color="success" icon={<Checkmark16Filled />} iconPosition="after">
          <Caption1Strong> Matches </Caption1Strong>
        </Badge>
      );
    } else if ((reenteredPassword === '' || password === '') && reenteredPassword !== password) {
      return null; // Hide the message when both fields are empty or when one field was cleared
    } else {
      return (
        <Badge appearance="ghost" color="danger" icon={<Dismiss16Filled />} iconPosition="after">
          <Caption1Strong> Does not match </Caption1Strong>
        </Badge>
      );
    }
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //     setUser(prevUser => ({ ...prevUser, [name]: value }));

  //   };

  // const handleInputChange = (e:any) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(
      prevUser =>
        ({
          ...prevUser,
          [name]: value.trim(),
          passwordStrength: checkPasswordStrength(value.trim()),
          passwordMatch: checkPasswordMatch({ ...prevUser, [name]: value.trim() }),
        } as User),
    ); // Add 'as User' to explicitly assert the return type as User
  };

  const [pswStrengthFlag, setPswStrengthFlag] = useState(true);
  const checkPasswordStrength = (password: string) => {
    const strength = [/[A-Z]/.test(password), /[\W_]/.test(password), (password.match(/\d/g) || []).length >= 3, password.length >= 8].reduce(
      (acc, condition) => acc + (condition ? 1 : 0),
      0,
    );
    setPswStrengthFlag(false);
    if (strength === 0)
      return (
        <Badge appearance="ghost" color="severe">
          <Caption1Strong> Very Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 1)
      return (
        <Badge appearance="ghost" color="danger">
          <Caption1Strong> Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 2)
      return (
        <Badge appearance="ghost" color="warning">
          <Caption1Strong> Moderate </Caption1Strong>
        </Badge>
      );
    if (strength === 3)
      return (
        <Badge appearance="ghost" color="success">
          <Caption1Strong> Strong </Caption1Strong>
        </Badge>
      );
    setPswStrengthFlag(true);
    return (
      <Badge appearance="ghost" color="success">
        <Caption1Strong> Very Strong </Caption1Strong>
      </Badge>
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (user.username !== ' ' /*&& user.password === 'nanoheal@123'*/) {
      Navigate('/');
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  return (
    <>
      {isOnline ? (
        <>
          <FluentProvider theme={webLightTheme}>
            <Stack horizontal tokens={{ childrenGap: 100 }}>
              <StackItem>
                <Stack style={signLogo} tokens={{ padding: 10 }}>
                  <Text align="center">
                    <Stack>
                      <Image block src="/visualization/v2/image/signinLogo.svg" />
                    </Stack>
                  </Text>
                </Stack>
              </StackItem>

              <Stack tokens={{ childrenGap: 15 }} style={formPosition}>
                <form onSubmit={handleSubmit}>
                  <Stack tokens={{ childrenGap: 15 }}>
                    {!showSuccess ? (
                      <>
                        <LargeTitle> Sign up </LargeTitle>
                        <Body1> Hello there! Please input your details to Proceed </Body1>

                        <Body2> First Name </Body2>
                        <Input
                          placeholder="Enter Firstname Here"
                          size="medium"
                          type="text"
                          required
                          id="firstname"
                          name="firstrname"
                          value={user.firstname}
                          onChange={handleInputChange}
                        />

                        <Body2> Last Name </Body2>
                        <Input
                          placeholder="Enter Lastname Here"
                          size="medium"
                          type="text"
                          required
                          id="lastname"
                          name="lastname"
                          value={user.lastname}
                          onChange={handleInputChange}
                        />

                        <Body2> Email </Body2>
                        <Input
                          placeholder="Enter your email Here"
                          size="medium"
                          type="text"
                          required
                          id="email"
                          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                          name="email"
                          value={user.email}
                          onChange={handleInputChange}
                        />
                        <Body2> Password </Body2>
                        <Input
                          placeholder="Enter Password Here"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          name="password"
                          value={user.password}
                          onChange={handleInputChange}
                          onFocus={handlePasswordFocus}
                          required
                          contentAfter={
                            <>
                              {passwordFocused && (
                                <Button
                                  size="small"
                                  appearance="transparent"
                                  onClick={handleTogglePassword}
                                  icon={
                                    <>
                                      {showPassword ? (
                                        <Tooltip withArrow content="Hide Password" relationship="label">
                                          <EyeOff20Filled />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip withArrow content="Show Password" relationship="label">
                                          <Eye20Filled />
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </>
                          }
                        />
                        <Body1Strong> Password Strength: {user.passwordStrength} </Body1Strong>
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...iconStyleProps} />
                          </StackItem>
                          <StackItem>
                            <Caption1Strong align="justify">
                              Create a password with at least 8 characters. Including a mix of uppercase, lowecase, special characters and at least 3
                              numbers . Avoid a common words and personal information. Keep your account safe by choosing a unique password.
                            </Caption1Strong>
                          </StackItem>
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <Body1> Already have an account? </Body1>
                          </StackItem>
                          <StackItem>
                            <Link onClick={() => Navigate('/')}>
                              <Body1Strong> Login </Body1Strong>
                            </Link>
                          </StackItem>
                        </Stack>
                        {/* <StackItem> <Body2> Re-enter Password </Body2> </StackItem>
                                        <Input
                                          // pattern="^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9].*[0-9].*[0-9]).{8,}$"
                                          placeholder='Re-Enter Password Here'
                                          type={showConfirmPassword ? 'text' : 'password'}
                                          id='reenteredPassword'
                                          name='reenteredPassword'
                                          value={user.reenteredPassword}
                                          onChange={handleInputChange}
                                          onFocus={handleConfirmPasswordFocus}
                                          contentAfter={
                                            <>
                                              {confirmPasswordFocused && (
                                                <Button 
                                                  size="small" 
                                                  appearance='transparent'  
                                                  onClick={handleTogglePassword} 
                                                  icon={
                                                    <> 
                                                      {showConfirmPassword 
                                                        ? 
                                                        <Tooltip withArrow content="Hide Password" relationship="label">
                                                          <EyeOff20Filled/>
                                                        </Tooltip>
                                                        : 
                                                        <Tooltip withArrow content="Show Password" relationship="label">
                                                          <Eye20Filled/>
                                                        </Tooltip>
                                                      } 
                                                    </>
                                                  } 
                                                />
                                              )}
                                            </>
                                          }
                                        />  */}

                        {/* <Body2> Organization Name </Body2>
                                    <Input
                                      placeholder='Enter your Organization name Here' 
                                      size='medium' 
                                      required 
                                      type='text' 
                                      id="password" 
                                      name="password" 
                                      value={user.password} 
                                      onChange={handleInputChange}
                                    /> */}

                        <StackItem>
                          <Button
                            size="medium"
                            appearance="primary"
                            type="submit"
                            // onClick={handleButtons}
                          >
                            <Body1Strong> Submit </Body1Strong>
                          </Button>
                        </StackItem>
                        <FluentProvider theme={webLightTheme}>
                          {messagebar2 && (
                            <ToastComponent
                              setmessagebar={setmessagebar2}
                              isToastEnabled={messagebar2}
                              footer=""
                              toastIntent={
                                user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                  ? 'warning'
                                  : 'success'
                              }
                              position="top-end"
                              title={
                                user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                  ? 'Warning'
                                  : 'Success'
                              }
                              message={
                                user.password === '' || user.reenteredPassword === ''
                                  ? 'Field cannot be empty'
                                  : user.password.length < 8
                                  ? 'Password must be at least 8 characters long.'
                                  : user.reenteredPassword !== user.password || !pswStrengthFlag
                                  ? 'Please check the password  or read the password instruciton'
                                  : 'Password reset successful!'
                              }
                            />
                          )}
                        </FluentProvider>

                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                          <StackItem>
                            <Body1>Already have an accout?</Body1>
                          </StackItem>
                          <StackItem>
                            <Link onClick={() => Navigate('/')}>
                              <Body1Strong>Sign in</Body1Strong>
                            </Link>
                          </StackItem>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <PresenceBadge size="large" />
                        <Title2> Verify Your Email Address </Title2>
                        <Body1> Please click on the link in the email we just sent you to confirm your email address. </Body1>
                      </>
                    )}
                  </Stack>
                </form>
              </Stack>
              <Stack style={backgroundPosition}>
                <Image block src="/visualization/v2/signin_background.png" height={`100%`} />
              </Stack>
            </Stack>
          </FluentProvider>
        </>
      ) : (
        <SkeletonSignin />
      )}
    </>
  );
};
