import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getTDeviceListsQuery, getTDeviceListsCntQuery, getDeviceClassification, getStatusOfdevices } from "../graphql/troubleshooterDevice_queries";
import { GlobalContext } from "../hooks/globalContext";


type dataProps = {
    data: any;
    func: any;
    searchVal: any
    pageNo: any
    setTotalPages: any
    setTotalRecords: any
    setGroupName: any
    searchDevVal: any
    allDevice:any
    // setallDevice:any
    setMachineStatus?: any
}

export const TroubleshooterDevice_fun = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);

    const tenantName = contextValue.tenantName;



    const getDeviceClassificationList = async (whereCondition: any) => {
        const { data, loading, error } = await client.query({
            query: getDeviceClassification,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });

        if (!loading && data) {
            props.groupFunc(data);
            props.setallDevice(true);
        }
    };

    

    const getTDeviceList = async (whereCondition: any, limit: number, order: any) => {
        const { data, loading, error } = await client.query({
            query: getTDeviceListsQuery,
            variables: { whereCondition, limit, order },
            fetchPolicy: 'network-only',
        });

        if (!loading && data) {
            console.log("Census data------=", data.core_Census); 
            props.func(data);

            getStatusOfdevicesList(tenantName, "Sites", "yes");
        }
    };

    const getTDeviceListsCnt = async (whereCondition: any) => {
        const { data, loading, error } = await client.query({
            query: getTDeviceListsCntQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });

        if (!loading && data) {
            props.setTotalRecords(data.core_Census.length);
            props.setTotalPages(Math.ceil((data.core_Census.length) / 10));
        }
    }


    
    const getStatusOfdevicesList = async (name: String, type: String, deviceStatus: String) => {
        const { data, loading, error } = await client.query({
            query: getStatusOfdevices,
            variables: {name, type, deviceStatus},
            fetchPolicy:"network-only"
        });

        if(!loading && data.getMachineSites?.machines?.length > 0){
            //console.log("------------------", data);
            var statusObj: any = {};
            data.getMachineSites.machines.map((val: any) => {
                statusObj[`${val.id}`] = JSON.parse(val.name);
            });

            props?.setMachineStatus(statusObj);
        }
    } 

    

    useEffect(() => {
        
        if (props.searchDevVal?.length > 0 && props.searchDevVal !== '') {
            getTDeviceList({ site: { _eq: tenantName }, host: { contains: props.searchDevVal } }, 10, { host: "desc" })
            getTDeviceListsCnt({ site: { _eq: tenantName }, host: { contains: props.searchDevVal } });
             
        } 
        else if (props.allDevice)
        {
            getTDeviceList({ site: { _eq: tenantName }}, 10, { host: "desc" });
        }             
        else if (props.groupName?.length > 0 && props.groupName != '') {
            getTDeviceList({ site: { _eq: tenantName }, deviceClassifications: { contains: props.groupName } }, 10, { host: "desc" });
        }
        else {
            getDeviceClassificationList({ tenant: { _eq: tenantName }, isGroupConfigured: { _eq: "Yes" } });
            getTDeviceListsCnt({ site: { _eq: tenantName } });
        }
    

    }, [props.searchDevVal, props.pageNo, props.data, props.groupName, props.allDevice]);

    return (
        <></>
    )
}
