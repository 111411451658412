import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupIdOfTenantQuery, getComponentsDetailsOfDartQuery, insertValueOfComponentQuery, updateValueOfComponentQuery, updateAutomationStautsQuery, deleteValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { useNavigate } from "react-router-dom";
import { log } from 'console';

type dataProps = {
    data?: any;
    func?: any;
    searchVal?: String;
    pageNo?: any;
    setTotalPages?: any;
    setTotalRecords?: any;
    setDartSpecs?: any;
    setJsonData?: any;
    setUiData?: any;
    deleteAutomation?: any;
    setDeleteAutomation?: any;
    setDeleteDisabled?: any;
    isRefresh?: any;
    setRefresh?: any;
}

const Data_Collections_func = (props: any) => {
    const client = useApolloClient();
    const navigate = useNavigate();

    const contextValue: any = useContext(GlobalContext);
    var selctedValue: any = '';
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    // var mgroupuniq = '';
    var insertCounter = 0;
    var totalGrpDataCnt = 0;
    var failedAttachmentGroups: any = [];
    var failedDetachmentGroups: any = [];
    var mcatuniq = '';
    var varnameuniq = '';
    var automationData = {
        id: '',
        MetricName: '',
        desktop_binaries: '',
        UrlPatters: '',
        ApplicationType: '',
        status: ''
    };

    var dataCollectionConfigData: any = {};
    var serviceLogMasterData: any = {};
    var subAutomation: any = [];

    const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null, name: String = '') => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data?.core_AutomationJSON?.length > 0){
            automationData.id = data.core_AutomationJSON[0].id;
            automationData.MetricName = data.core_AutomationJSON[0].MetricName;
            automationData.desktop_binaries = data.core_AutomationJSON[0].desktop_binaries;
            automationData.UrlPatters = data.core_AutomationJSON[0].UrlPatters;
            automationData.ApplicationType = data.core_AutomationJSON[0].ApplicationType;
            automationData.status = data.core_AutomationJSON[0].status;
        }
    }
    
    //to check site/group is configured or not
    const getGroupIdOfTenant = async (whereCondition: any, action: any) => {
        insertCounter = 0;
        const { data, loading, error } = await client.query({
            query: getGroupIdOfTenantQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            totalGrpDataCnt = data.core_MachineGroups.length;
            var selectedapp: any = localStorage.getItem('selectedapp');
            selectedapp = JSON.parse(selectedapp);
            var id = selectedapp.rowid;

            data.core_MachineGroups.map(async (val: any) => {
                insertCounter++;
                mcatuniq = val.mcatuniq;
                
                await getComponentsDetailsOfDart([310], val.mgroupuniq, val.name);
                await getAutomationList({id: {_eq: id}}, null, null,'dataCollectionConfig');

                if(action == 'detach'){
                    console.log("deleteing...........");

                    // setTimeout(() => {
                        removeValueInDataCollectionComponent(val.mgroupuniq, val.name);
                    // }, 2000);
                } 
                
                if(action == 'attach'){
                    console.log("inserting...........");
    
                    // setTimeout(() => {
                        setValueOfdataCollectionComponent(val.mgroupuniq, val.name);
                    // }, 2000);
                }
            });
        }else{
            console.log("Site has not configured");

            props.setmessagebar1(false);
            props.setmessagebar2(true);
            props.setToastMessage("Assignment failed");
        }
    }

    //to fetch the name, varnameuniq, varscopuniq, varuniq of components in dart 69
    const getComponentsDetailsOfDart = async (dartId: any, groupId: any, name: String) => {
        const { data, loading, error } = await client.query({
            query: getComponentsDetailsOfDartQuery,
            variables: { dartId, groupId },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data) {
            data.core_Variables.map((val: any) => {
                if(val.name === 'S00310ProcessConf'){
                    var dataCollectionConfigIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    dataCollectionConfigData[`${name}`] = dataCollectionConfigIds;
                }
            });
        }
    }
    
    const setValueOfdataCollectionComponent = async (mgroupuniq: any, name: String) => {
        var varValue: any = '';

        var dataCollectionComponentVal = '';
        var componentVal = '';
        var status: any = false;
    
        console.log("AutoHeal component loop entered=", dataCollectionConfigData[`${name}`].componentValue);
        
        if(dataCollectionConfigData[`${name}`].varuniq?.length > 0 && dataCollectionConfigData[`${name}`]?.componentValue != undefined){ // to check if autoHealConfig is present for particluar site/group in varvalues table 
            var res = dataCollectionConfigData[`${name}`].componentValue;
            var revised = dataCollectionConfigData[`${name}`].revised;
            
            console.log("imm res=",res);
            if(res?.length > 0){
                dataCollectionComponentVal = res?.trim();
                
                var urlPatters = automationData.UrlPatters;
                    
                dataCollectionComponentVal += "\n" + generatePairs(automationData.desktop_binaries,urlPatters);

                console.log("dataCollectionComponentVal=",dataCollectionComponentVal);
                console.log("grp name=",name);

                    
                //status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

                status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: dataCollectionConfigData[`${name}`].varuniq}, varscopuniq: {_eq: dataCollectionConfigData[`${name}`].varscopuniq}}, dataCollectionComponentVal, (revised + 1));

                // status = await updateValueOfComponents({valueid: {_eq: serviceLogMasterData[`${name}`].id}}, autoHealVal, (revised + 1));
                
            }else{
                dataCollectionComponentVal = res?.trim();
                var urlPatters = automationData.UrlPatters;
                    
                dataCollectionComponentVal = generatePairs(automationData.desktop_binaries,urlPatters);

                // status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

                status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: dataCollectionConfigData[`${name}`].varuniq}, varscopuniq: {_eq: dataCollectionConfigData[`${name}`].varscopuniq}}, dataCollectionComponentVal, (revised + 1));

                // status = await updateValueOfComponents({valueid: {_eq: autoHealConfigData[`${name}`].id}}, autoHealVal, (revised + 1));
            }
        }else{
            dataCollectionComponentVal = res?.trim();
            var urlPatters = automationData.UrlPatters;
                
            dataCollectionComponentVal = generatePairs(automationData.desktop_binaries,urlPatters);

            status = await insertValueOfComponent(dataCollectionComponentVal, 0, dataCollectionConfigData[`${name}`].varnameuniq, dataCollectionConfigData[`${name}`].varscopuniq, dataCollectionConfigData[`${name}`].varuniq, mgroupuniq);
        }

        console.log("totalGrpDataCnt=",totalGrpDataCnt, "insertCounter=",insertCounter);

        
        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;
            
            if(selctedValue == 'tenant'){
        
                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":all";
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:all`);

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `${tenantName}:all`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:all}`;
                }
            }else{
                var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');
                console.log("attached selectedGroups=",selectedGroups);
                selectedGroups = selectedGroups.filter((val: any) => !(failedAttachmentGroups.includes(val)));
                console.log("filtered attached selectedGroups=",selectedGroups);

                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":" + selectedGroups.join(",");
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1];

                        console.log("attachment curSelectedGRp=",curSelectedGRp);

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp = automationStatus.split(":")[1];

                        selctedValue = `${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`;
                    }
                }else{

                    selctedValue = `${automationStatus}|${tenantName}:${selectedGroups.join(",")}`; 
                }
            }
            console.log("selctedValue=",selctedValue);
            
            
            await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartNo}}, selctedValue, 0, 'attach');
        }
    };

    function generatePairs(desktopBinary:any, urlPattern:any) {
        var pairs: any;
        var formatedString: any;

        const urlPatterns = urlPattern?.split(',').map((pattern: any) => pattern?.trim());
        pairs = urlPatterns?.map((pattern: any) => `${desktopBinary},${pattern +"*"}`);
        formatedString=pairs?.join("\n");

        if(urlPattern === "" || pairs === undefined){
            pairs= desktopBinary;
            formatedString=pairs
        }
        console.log("this is the required pairs",pairs);
        console.log("formated string",formatedString)
        return formatedString;
    }


    const removeValueInDataCollectionComponent = async (mgroupuniq: any, name: String) => {
        var status: any = false;
        var res = dataCollectionConfigData[`${name}`].componentValue;
        var revised = dataCollectionConfigData[`${name}`].revised;
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var subDartNo = subAutomation[0].dartId;
        var dartId = '1'
        var autoHealVal = res?.trim();

        if(autoHealVal != undefined && autoHealVal?.length > 0){
            autoHealVal = autoHealVal.split('$$$').filter((val: any) => !(val.includes(`DN:${subDartNo}`))).join("$$$");

            // status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

            status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: dataCollectionConfigData[`${name}`].varuniq}, varscopuniq: {_eq: dataCollectionConfigData[`${name}`].varscopuniq}}, autoHealVal, (revised + 1));

            // status = await updateValueOfComponents({valueid: {_eq: autoHealConfigData[`${name}`].id}}, autoHealVal, (revised + 1));
        }else{
            status = true;
        } 

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;

            if(selctedValue == 'tenant'){
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `not attached`;
                    }
                }else{
                    selctedValue = automationStatus;
                }
            }else{
                var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedDetachmentGroups.includes(val)));

                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1].split(",");

                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        curSelectedGRp.length > 0 && statusArr.push(`${tenantName}:${curSelectedGRp.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp: any = automationStatus.split(":")[1].split(",");
                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        if(curSelectedGRp.length == 0){
                            selctedValue = 'not attached';
                        }else{
                            selctedValue = `${tenantName}:${curSelectedGRp.join(",")}`;
                        }
                    }
                }else{
                    selctedValue = automationStatus; 
                }
            }

            let flag = props.groupAttachment?.length ? props.groupAttachment?.length : 0;
            
            await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, flag, 'detach');
            
            // if(props.groupAttachment.length > 0){
            //     automationData.status = selctedValue;
            //     setTimeout(() => {
            //         attachRemediation();
            //     }, 1000);
            // }
        } 
    }

    const updateAutomationStauts = async (whereCondition: any, status: any, flag: any, type: String = '') => { 
        const { data } = await client.mutate({
            mutation: updateAutomationStautsQuery,
            variables: { whereCondition, status },
            fetchPolicy: 'network-only',
        });

        if(data.update_core_AutomationJSON.affected_rows > 0){
            if(flag == 0){
                if(type == 'attach'){
                    props.setToastMessage(`Automation attached to ${props.groupAttachment[0]} ${props.groupAttachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);
                    
                    props.setAllAttachmentGrp((prev: any) => [...prev.slice(1)]);
                }else if(type == 'detach'){
                    props.setToastMessage(`Automation detached from ${props.groupDetachment[0]} ${props.groupDetachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);

                    props.setAllDetachmentGrp((prev: any) => [...prev.slice(1)]);
                } 

                // props.setToastMessage("Automation attached to selected devices successfully.");
                props.setmessagebar1(false);
                props.setmessagebar2(true);

                // setTimeout(() => {
                //     navigate("/manage/devices/remediation");
                // }, 5000);
            }
        }
    }

    const insertValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any ) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: insertValueOfComponentQuery,
          variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
    
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any, valueid: any) => {
        console.log("valueid=",valueid);
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
            mutation: insertValueOfComponentQuery,
            variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
        
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponents = async (whereCondition: any, valu: String, revl: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: updateValueOfComponentQuery,
          variables: {whereCondition, valu, revl, lastchange}
        });
    
        if(data.update_core_VarValues.affected_rows > 0){
            console.log("component value updated");
            return true;
        }else{
            return false;
        }
    };

    const attachRemediation = async () => {
        var selected = contextValue.selectedRowIdInTable;
        var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

        if(selected.includes('All Devices')){
            selctedValue = 'tenant';
            await getGroupIdOfTenant({name: {_eq: tenantName}}, 'attach');
        }
        
        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification'; 
            await getGroupIdOfTenant({name: {_in: selectedGroups}}, 'attach');
        }
    };

    const detachRemediation = () => {
        var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');

        if(props.groupDetachment.includes('All Devices')){
            selctedValue = 'tenant';
            getGroupIdOfTenant({name: {_eq: tenantName}}, 'detach');
        }
        
        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification';
            getGroupIdOfTenant({name: {_in: selectedGroups}}, 'detach');
        }
    };
  
    const modifyRemediation = async () => {
        await getAutomationList({dartid: {_eq: 310}, automationName: {_eq: automationName}});

        if(props.groupDetachment.length > 0){
            detachRemediation();
        }else if(props.groupAttachment.length > 0){
            attachRemediation();
        }
    }
  
    useEffect(() => {   
        modifyRemediation();
    },[props.saveBtnClicked, props.groupAttachment, props.groupDetachment]);

    return (
        <></>
    )
}

export { Data_Collections_func }