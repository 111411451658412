import React, { useState, useEffect, useContext } from 'react';
import { countCensusDevice, countCensusDeviceWithBornDate } from '../../graphql/home_queries';
import { useQuery, useApolloClient } from '@apollo/client';

type siteProp = {
  propTname: any;
  propdeviceCnt: any;
  proponedeviceCnt: any;
  propthirtyDeviceCnt: any;
  propninetyDeviceCnt: any;
};

export const Tenant_dev = (props: siteProp) => {
  const client = useApolloClient();

  const [myDeviceLen, setmyDeviceLen] = useState(null);

  // console.log("propTname----->>", props.propTname);

  //dates conversion
  let currentDate = new Date();
  let yesterday = new Date(currentDate);
  yesterday.setDate(yesterday.getDate() - 1);

  const timeInMillisecond1 = yesterday.getTime();
  const unixTimestamp_yes = Math.floor(yesterday.getTime() / 1000);

  const thirtydate = new Date();
  thirtydate.setDate(thirtydate.getDate() - 30);

  const timeInMillisecond3 = thirtydate.getTime();
  const unixTimestamp_thirty = Math.floor(thirtydate.getTime() / 1000);

  const ninedate = new Date();
  ninedate.setDate(ninedate.getDate() - 90);

  const timeInMillisecond4 = ninedate.getTime();
  const unixTimestamp_nine = Math.floor(ninedate.getTime() / 1000);

  const date = new Date(Date.now());
  const timeInMillisecond2 = date.getTime();
  const unixTimestamp_tdy = Math.floor(date.getTime() / 1000);

  const getSiteDeviceList = async (siteName: any) => {
    const { data, loading, error } = await client.query({
      query: countCensusDevice,
      variables: { siteName },
    });

    if (!loading && data) {
      props.propdeviceCnt(data.countCensusDevice);

      // const coreCensusArray = data.install_Sites.filter((site: any) => site.InstallSite_coreCensus.length > 0);
      // const lengthOfOutput = coreCensusArray ? coreCensusArray.length : 0;
      // if (lengthOfOutput !== 0) {
      //   const Devices = coreCensusArray.map((Joineddata: any) => {
      //     const InstCoreLen = Joineddata.InstallSite_coreCensus.length;
      //     return InstCoreLen;
      //   });

      //   const Totdev = Devices.reduce((n: any, n2: any) => {
      //     return n + n2;
      //   }, 0);
      //   props.propdeviceCnt(Totdev);
      // } else {
      //   const Totdev = 0;
      //   props.propdeviceCnt(Totdev);
      // }
    }
  };
  useEffect(() => {
    getSiteDeviceList(props.propTname);
  }, [props.propTname]);

  const getSitesDevice1day = async (siteName: any, bornYesDate: any, bornTdyDate: any) => {
    const { data, loading, error } = await client.query({
      query: countCensusDeviceWithBornDate,
      variables: { siteName, bornFromDate: bornYesDate },
    });

    if (!loading && data) {
      props.proponedeviceCnt(data.countCensusDeviceWithBornDate);
      // const coreCensusArray = data.install_Sites.filter((site: any) => site.InstallSite_coreCensus.length > 0);
      // const lengthOfOutput = coreCensusArray ? coreCensusArray.length : 0;

      // if (lengthOfOutput !== 0) {
      //   const Devices = coreCensusArray.map((Joineddata: any) => {
      //     const InstCoreLen = Joineddata.InstallSite_coreCensus.length;
      //     return InstCoreLen;
      //   });

      //   const onedayDevCnt = Devices.reduce((n: any, n2: any) => {
      //     return n + n2;
      //   }, 0);
      //   props.proponedeviceCnt(onedayDevCnt);
      // } else {
      //   const onedayDevCnt = 0;
      //   props.proponedeviceCnt(onedayDevCnt);
      // }
    }
  };
  useEffect(() => {
    getSitesDevice1day(props.propTname, unixTimestamp_yes, unixTimestamp_tdy);
  }, [props.propTname]);

  const getSitesDevicethirtyday = async (siteName: any, bornthirtyDate: any, bornTdyDate: any) => {
    const { data, loading, error } = await client.query({
      query: countCensusDeviceWithBornDate,
      variables: { siteName, bornFromDate: bornthirtyDate },
    });

    if (!loading && data) {
      props.propthirtyDeviceCnt(data.countCensusDeviceWithBornDate);
      // const coreCensusArray = data.install_Sites.filter((site: any) => site.InstallSite_coreCensus.length > 0);
      // const lengthOfOutput = coreCensusArray ? coreCensusArray.length : 0;
      // if (lengthOfOutput !== 0) {
      //   const Devices = coreCensusArray.map((Joineddata: any) => {
      //     const InstCoreLen = Joineddata.InstallSite_coreCensus.length;
      //     return InstCoreLen;
      //   });

      //   const thirtDevCnt = Devices.reduce((n: any, n2: any) => {
      //     return n + n2;
      //   }, 0);
      //   props.propthirtyDeviceCnt(thirtDevCnt);
      // } else {
      //   const thirtDevCnt = 0;
      //   props.propthirtyDeviceCnt(thirtDevCnt);
      // }
    }
  };
  useEffect(() => {
    getSitesDevicethirtyday(props.propTname, unixTimestamp_thirty, unixTimestamp_tdy);
  }, [props.propTname]);

  const getSitesDeviceninety = async (siteName: any, bornninetyDate: any, bornTdyDate: any) => {
    const { data, loading, error } = await client.query({
      query: countCensusDeviceWithBornDate,
      variables: { siteName, bornFromDate: bornninetyDate },
    });

    if (!loading && data) {
      props.propninetyDeviceCnt(data.countCensusDeviceWithBornDate);
      // const coreCensusArray = data.install_Sites.filter((site: any) => site.InstallSite_coreCensus.length > 0);
      // const lengthOfOutput = coreCensusArray ? coreCensusArray.length : 0;

      // if (lengthOfOutput !== 0) {
      //   const Devices = coreCensusArray.map((Joineddata: any) => {
      //     const InstCoreLen = Joineddata.InstallSite_coreCensus.length;
      //     return InstCoreLen;
      //   });

      //   const ninetyDevCnt = Devices.reduce((n: any, n2: any) => {
      //     return n + n2;
      //   }, 0);
      //   props.propninetyDeviceCnt(ninetyDevCnt);
      // } else {
      //   const ninetyDevCnt = 0;
      //   props.propninetyDeviceCnt(ninetyDevCnt);
      // }
    }
  };

  useEffect(() => {
    getSitesDeviceninety(props.propTname, unixTimestamp_nine, unixTimestamp_tdy);
  }, [props.propTname]);

  return <></>;
};
