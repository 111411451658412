import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import session from 'supertokens-web-js/recipe/session';
import Session from 'supertokens-web-js/recipe/session';

type dataprops = {
    handleFixedLayout:any
}

export const PrivateRoutes = (props:dataprops) => {

    // const [token,setToken] = useState(localStorage.getItem('user-login'))
    let [token,setToken] = useState(false)

    const userToken = sessionStorage.getItem('user-login');
    if (!userToken || userToken === 'undefined') {
        console.log("invalid unable to login")
        token = false
    }
    else{
      console.log("Successfule authentication of token")
      token = true
      props.handleFixedLayout(token)
    }

    // const checkUserToken = async  () => {
    //     const userToken = localStorage.getItem('user-login');
    //     if (!userToken || userToken === 'undefined') {
    //         console.log("token value undefined or error",token)
    //         return true
    //     }
    //     else{
    //       console.log("token value persists after login happening",token)

    //     }
    //     // setToken(true);
    //     // console.log("YESSS function being called after true",token)
    // }
    useEffect( () => {
            // checkUserToken();
        }, [token]);

    
return (
    token == true  ? <Outlet/> : <Navigate to='/'/>
  )
}