import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getDeviceClassificationListQuery, getDevicesListQuery, getGroupListsQuery, viewGroupDetailsQuery, getMcatidQuery, insertConfigGroupQuery, insertDeviceClassificationQuery, updateDeviceClassificationQuery, getAttachedGroupsOfDeviceQuery, attachDevicesToGroupsQuery, deleteUnGroupedClassificationQuery } from "../../graphql/groups_queries";
import { getGroupedDevicesListQuery } from "../../graphql/device_classification_devices_queries";
import { getCensusListsQuery } from "../../graphql/census_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { Activity_log_func } from "../../api/activity_log/activity_log_func";

type dataProps = {
    data: any;
    func: any;
    searchVal?: any
    pageNo?: any
    setTotalPages?: any
    setTotalRecords?: any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any 
}

export const Groups_func = (props: any) => {
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;
  var selectedRowsID = contextValue.selectedRowIdInTable;

  var device_classification: any = [];

  const getGroupsList = async (whereCondition: any, limit: any, offset: any) => {
    const { data, loading, error } = await client.query({
      query: getDeviceClassificationListQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      data.core_DeviceClassification.map(async (val: any) => {
        var linkedDeviceCnt = await getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: val.classificationName}}, null, 0);

        props.setLinkedDeviceCnt((prev: any) => ({
          ...prev,
          [`${val.classificationName}`]: linkedDeviceCnt,
        }));
      });
       
      props.func(data);
    }
  };

  const getGroupedDevicesList = async (whereCondition: any, limit: any, offset: number) => {
    const { data, loading, error } = await client.query({
        query: getGroupedDevicesListQuery,
        variables: {whereCondition, limit, offset},
        fetchPolicy: 'network-only'
    });

    if(!loading && data){
      return data.core_Census.length;
    }
  };

  const getGroupsListCnt = async (limit: any, offest: any, whereCondition: any) => {
    const { data, loading, error } = await client.query({
      query: getDeviceClassificationListQuery,
      variables: {limit, offest, whereCondition},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_DeviceClassification.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_DeviceClassification);
      props.setTotalRecords(data.core_DeviceClassification.length);
      props.setTotalPages(Math.ceil((data.core_DeviceClassification.length)/50));
    }
  };

  const getDevicesList = async (whereCondition: any) => {
    const { data, loading, error } = await client.query({
      query: getDevicesListQuery,
      variables: {whereCondition}
    });

    if(!loading && data){
      props.setDevicesData(data);
    }
  };

  const getMcatidOfCategory = async (category: String) => {
    const { data, loading, error } = await client.query({
      query: getMcatidQuery,
      variables: {category}
    });

    if(!loading && data){
      var mcatUniq = data.core_MachineCategories[0].mcatuniq;
      var md5 = require('md5');

      selectedRowsID.map(async(val : any, i: any) => {
        var id = val[0]; 
        var classificationName = val[1];
        var mgroupUniq = md5(`${mcatUniq},${classificationName}`);
        var type = props.operationType[`${classificationName}`];

        await insertConfigGroup(id,classificationName, mcatUniq, mgroupUniq, userName, type);

        if(selectedRowsID.length == (i+1)){
          console.log("all selected");

          // props.setUserActivityData(JSON.stringify({...props.userActivityData, success: `${props.userActivityData.success?.length > 0 ? `${props.userActivityData.success},${classificationName}` : `${classificationName}`}`}));
          
          // props.setTracker(true);
        }
      });

      getGroupsList({tenant: {_eq: tenantName}}, 50, props.pageNo);
      getGroupsListCnt(null, null, {tenant: {_eq: tenantName}});
    }
  }

  useEffect(() => {
    console.log("props.userActivityData=",props.userActivityData);
  }, [props.userActivityData]);
  

  const insertConfigGroup = async (id: any, name: String, mcatuniq: String, mgroupuniq: String, username: String, type: String) => {
    const created =  Math.floor(Date.now() / 1000);

    const { data } = await client.mutate({
      mutation: insertConfigGroupQuery,
      variables: {name, created, mcatuniq, mgroupuniq, username, type}
    });

    if(data?.insert_core_MachineGroups?.affected_rows > 0){
      updateDeviceClassification(id,name,mgroupuniq);

      // props.setUserActivityData(JSON.stringify({...props.userActivityData, success: `"${props.userActivityData.success?.length > 0 ? `${props.userActivityData.success},${name}` : `${name}`}"`}));

      // props.setUserActivityData(JSON.stringify({...props.userActivityData, success: `${name}`}));
      
    }else if(data?.errors[0]?.message){
      console.log("group configuration failed");

      // props.setUserActivityData({...props.userActivityData, failed: `"${props.userActivityData.failed.length > 0 ? `${props.userActivityData.failed},${name}` : `${name}`}"`, status: "Failed"});
    }
  }

  const updateDeviceClassification = async (id: any, classificationName: String, mgroupuniq: String) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    const isGroupConfigured = 'Yes';

    const { data } = await client.mutate({
      mutation: updateDeviceClassificationQuery,
      variables: {id, classificationName, isGroupConfigured, mgroupuniq}
    });

    if(data.update_core_DeviceClassification.affected_rows > 0){
      props.setGroupConfigCnt((prev: any) => prev + 1);
    }
  };

  const viewGroupDetails = async (mgroupid: number) => {
    const { data, loading, error } = await client.query({
      query: viewGroupDetailsQuery,
      variables: {mgroupid}
    });

    if(!loading && data){
      console.log("viewGroupDetails=",data);
    }
  }

  const addClassification = async (name: String) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    const isGroupConfigured = 'No';
    var classificationName = name.trim();
    var createdBy = userName;
    var tenant = tenantName;
    var type = props.selectedValue == 1 ? 'Manual' : props.selectedValue == 2 ? 'CSV' : '0';

    const { data } = await client.mutate({
      mutation: insertDeviceClassificationQuery,
      variables: {classificationName,  createdBy, createdOn, isGroupConfigured, tenant, type}
    });

    if(data.insert_core_DeviceClassification?.affected_rows > 0){
      
      await getAttachedGroupsOfDevice({site: {_eq: tenantName}, host: {_in: contextValue.selectedRowIdInSubTable}});
      
      await contextValue.selectedRowIdInSubTable.map(async (val: any) => {
        await addDevices(tenantName,val,classificationName);
      });

      props.setIsToast(true);
      setTimeout(() => {
        props.setAddClassification(false);
      }, 2000);
      props.setOverlayOpenNew(false);

      getGroupsList({tenant: {_eq: tenantName}}, 50, props.pageNo);
      getGroupsListCnt(null, null, {tenant: {_eq: tenantName}});
    }
  };

  const getAttachedGroupsOfDevice = async (whereCondition: any, name: String = '') => {
    var { data, loading, error } = await client.query({
      query: getAttachedGroupsOfDeviceQuery,
      variables: {whereCondition}
    });

    if(!loading && data){
      data.core_Census.map((val: any) => {
        device_classification = [...device_classification, 
          {
            classificationName: name,
            host: val.host,
            deviceClassifications: val.deviceClassifications
          }];
      });
    }
  };

  const addDevices = async (site: any, host: String, classificationName: String) => {
    var classifications = device_classification.filter((val: any) => val.host == host && !val.deviceClassifications?.split(',').includes(classificationName)); 

    if(classifications.length > 0){
      classifications = classifications[0]?.deviceClassifications;

      var deviceClassifications = (classifications?.length > 0) ? classifications + "," + classificationName : classificationName;

      var { data } = await client.mutate({
        mutation: attachDevicesToGroupsQuery,
        variables: {site, host, deviceClassifications}
      });
    
      if(data.update_core_Census?.affected_rows > 0){
        console.log("census table updated");
      }
    }
  };

  const deleteUnGroupedClassification = async (ids: any) => {
    await Promise.all(ids.map(async (val: any) => {
      await getAttachedGroupsOfDevice({site: {_eq: tenantName}, deviceClassifications: {contains: val[1]}}, val[1]);
    }));

    var finalClassification: any = '';

    finalClassification = device_classification.reduce((result: any, current: any) => {
      var existingObject = result.find((obj: any) => obj.host === current.host);
       
      if (existingObject) {
        existingObject.deviceClassifications = existingObject.deviceClassifications.split(',').filter((ele: any) => existingObject.classificationName != ele).filter((ele: any) => current.classificationName != ele).join(',');
      } else {
        current.deviceClassifications = current.deviceClassifications.split(",").filter((ele: any) => ele != current.classificationName).join(",");
       
        result.push({ ...current });
      }
      return result;
    }, []);

    await Promise.all(finalClassification.map(async (res: any) => {
      await updateAttachedGroupsOfDevice(tenantName, res.host, res.deviceClassifications);
    }));

    var idArr = ids.map((val: any) => val[0]);
    
    const { data } = await client.mutate({
      mutation: deleteUnGroupedClassificationQuery,
      variables: {idArr, tenantName},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_DeviceClassification?.affected_rows > 0){
      props.setDeleteEnabled(false);
      props.setDeleteClassification(false);
      props.setIsToast(true);
    }
  }

  const updateAttachedGroupsOfDevice = async (site: any, host: any, deviceClassifications: any) => {
    var { data } = await client.mutate({
      mutation: attachDevicesToGroupsQuery,
      variables: {site, host, deviceClassifications}
    });
  
    if(data.update_core_Census?.affected_rows > 0){
      console.log("census table updated");
    }
  }

  const getCensusList = async (whereCondition: any, limit: any = null, offset: any = null, order: any) => {
    const { data, loading, error } = await client.query({
        query: getCensusListsQuery,
        variables: {whereCondition, limit, offset, order}
    });

    if(!loading && data){
      props.setDevicesOfTenant(data);
    }
  };

  useEffect(() => {
    // props.deleteClassification && contextValue.selectedRowIdInTable.length > 0 &&
    //   deleteUnGroupedClassification(contextValue.selectedRowIdInTable);

    if(props.deleteClassification && contextValue.selectedRowIdInTable.length > 0){
      deleteUnGroupedClassification(contextValue.selectedRowIdInTable);
    }else if(props.isFilter == true){
      if(props.dropDownValue1 == 'Classification Name'){
        getGroupsList({tenant: {_eq: tenantName}, classificationName: {contains: props.dropDownValue2}},50, props.pageNo);
        getGroupsListCnt(null, null, {tenant: {_eq: tenantName}, classificationName: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Created By'){
        getGroupsList({tenant: {_eq: tenantName}, createdBy: {contains: props.dropDownValue2}},50, props.pageNo);
        getGroupsListCnt(null, null, {tenant: {_eq: tenantName}, createdBy: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Configuration group'){
        getGroupsList({tenant: {_eq: tenantName}, isGroupConfigured: {contains: props.dropDownValue2}},50, props.pageNo);
        getGroupsListCnt(null, null, {tenant: {_eq: tenantName}, isGroupConfigured: {contains: props.dropDownValue2}});
      }
    }
    else if(props?.csvDevicesList?.length > 0){
      getCensusList({site: {_eq: tenantName}}, null, null, {born: "desc"});
    }else if(props.searchVal?.length > 0 && props.searchVal != '' && !(props?.configGroupBtnCliked)){
      getGroupsList({tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}},50, props.pageNo);
      getGroupsListCnt(null, null, {tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}});
    }else if(props?.deviceSearchVal?.length > 0 && props?.deviceSearchVal != '' && !(props?.configGroupBtnCliked)){
      getDevicesList({site: {_eq: tenantName}, host: {contains: props.deviceSearchVal.toUpperCase()}})
    }else if(props?.addClassification){
      addClassification(props.classificationName);
    }else if(props?.configGroupBtnCliked){
      getMcatidOfCategory("Wiz_SCOP_MC");
      props.setConfigGroupBtnCliked(false);
    }else if(props.isRefresh){
      props.setRefresh(false);
      getGroupsList({tenant: {_eq: tenantName}}, 50, props.pageNo);
      getGroupsListCnt(null, null, {tenant: {_eq: tenantName}});
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }
    else{
      getGroupsList({tenant: {_eq: tenantName}}, 50, props.pageNo);
      getGroupsListCnt(null, null, {tenant: {_eq: tenantName}});

      getDevicesList({site: {_eq: tenantName}});

      // props.isRefresh && props.setRefresh(false);
    }

    //viewGroupDetails(349); //it should be called when clicked on view group details
  }, [props.deleteClassification, props.pageNo, props.searchVal, props.isRefresh, props.deviceSearchVal, props.configGroupBtnCliked, props.addClassification, props.csvDevicesList,props.isFilter]);
  
  return (
    <>
      {/* {props.tracker && <Activity_log_func data={props.userActivityData} setTracker={props.setTracker} type="insertLog" />} */}
    </>
  )
}
