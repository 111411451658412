import { gql } from '@apollo/client';

//need to check for dynamically adding the order by col name (i.e., born)
// const getCensusListsQuery = gql`
//     query MyQuery($tenantName: String!, $hostName: String, $osName: String, $limit: Int!, $order: JSON) {
//         core_Census(where: {site: {_eq: $tenantName}, _or: {host: {contains: $hostName}, os: {contains: $osName}}}, limit: $limit, order_by: $order) {
//             host
//             site
//             last
//             id
//             os
//             clientversion
//             born
//         }
//     }
// `;

const getCensusListsQuery = gql`
  query CensusData($whereCondition: JSON!, $limit: Int, $offset: Int = 0) {
    core_Census(where: $whereCondition, limit: $limit, offset: $offset, order_by: { born: desc }) {
      host
      site
      last
      id
      os
      clientversion
      born
      censusuniq
      Census_AssestData(where: { dataid: { _eq: 5 } }) {
        value
      }
    }
  }
`;

const getCensusListsCntQuery = gql`
  query MyQuery($whereCondition: JSON!) {
    core_Census(where: $whereCondition) {
      host
      site
      last
      id
      os
      clientversion
      born
      Census_AssestData(where: { dataid: { _eq: 5 } }) {
        value
      }
    }
  }
`;

// const getCensusListsCntQuery = gql `
//     query MachineTotalCnt($whereCondition: JSON) {
//         core_Census_aggregate(where: $whereCondition) {
//             aggregate {
//                 count(column: host, distinct: true)
//             }
//         }
//     }
// `;

// hostname is device name (ex. DESKTOP-QEO74IK), get it from table data
const getMachineIdQuery = gql`
  query MyQuery($hostname: [String!], $tenantName: String!) {
    asset_Machine(where: { host: { _in: $hostname }, cust: { _eq: $tenantName } }) {
      machineid
    }
  }
`;

//cust is siteName and host is device name
const deleteFromMachineQuery = gql`
  mutation MyMutation($tenantName: String!, $hostname: [String!]) {
    delete_asset_Machine(where: { cust: { _eq: $tenantName }, host: { _in: $hostname } }) {
      affected_rows
    }
  }
`;

//send machine name and tenantName
const deleteFromEventsQuery = gql`
  mutation MyMutation($hostname: [String!], $tenantName: String!) {
    delete_event_Events(where: { machine: { _in: $hostname }, customer: { _eq: $tenantName } }) {
      affected_rows
    }
  }
`;

const deleteFromUpdateMachinesQuery = gql`
  mutation MyMutation($hostname: [String!], $tenantName: String!) {
    delete_swupdate_UpdateMachines(where: { machine: { _in: $hostname }, sitename: { _eq: $tenantName } }) {
      affected_rows
    }
  }
`;

//get the id (censusId) from getCensusListsQuery query
const deleteFromSoftInstMachineQuery = gql`
  mutation MyMutation($censusIds: [Int!]) {
    delete_softinst_Machine(where: { id: { _in: $censusIds } }) {
      affected_rows
    }
  }
`;

//get the id (censusId) from getCensusListsQuery query
const deleteFromPatchStatusOuery = gql`
  mutation MyMutation($censusIds: [Int!]) {
    delete_softinst_PatchStatus(where: { id: { _in: $censusIds } }) {
      affected_rows
    }
  }
`;

//get the id (censusId) from getCensusListsQuery query
const deleteFromMachineGroupMapQuery = gql`
  mutation MyMutation($censusIds: [Int!]) {
    delete_core_MachineGroupMap(where: { MachineGroupMap_Census: { id: { _in: $censusIds } } }) {
      affected_rows
    }
  }
`;

//get the censusuniq from getCensusListsQuery query
const deleteFromMachineGroupsOuery = gql`
  mutation MyMutation($censusuniq: [String!]) {
    delete_core_MachineGroups(where: { mgroupuniq: { _in: $censusuniq } }) {
      affected_rows
    }
  }
`;

//no need
//get the censusuniq from getCensusListsQuery query
const deleteFromValueMapOuery = gql`
  mutation MyMutation($censusUniq: Int!) {
    delete_core_ValueMap(where: { censusuniq: { _eq: $censusUniq } }) {
      affected_rows
    }
  }
`;

// get the machine ID from getMachineIdQuery
const deleteFromAssetDataQuery = gql`
  mutation MyMutation($machineid: [Int!]) {
    delete_asset_AssetData(where: { machineid: { _in: $machineid } }) {
      affected_rows
    }
  }
`;

//pass the id(censusID) from getMachineIdQuery to this query
const deleteCensusRowQuery = gql`
  mutation MyMutation($censusIds: [Int!]) {
    delete_core_Census(where: { id: { _in: $censusIds } }) {
      affected_rows
    }
  }
`;

// no need
//pass the id(censusID) from getMachineIdQuery to this query
const deleteCensusPermQuery = gql`
  mutation MyMutation($id: Int!) {
    delete_core_CensusPerm(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

//pass the id from getMachineIdQuery to this query
// const deleteMachineQuery = `
//     mutation MyMutation($id: [Int!]) {
//         PackagesConfiguration_new {
//             _delete_softinst_Machine(where: {machineid: {_in: $id}}) {
//                 affected_rows
//             }
//         }
//     }
// `;

// Online/Offline status of devices
const getStatusOfdevices = gql`
  query getMachineSites($name: String!, $type: String!, $deviceStatus: String = "no") {
    getMachineSites(name: $name, type: $type, deviceStatus: $deviceStatus) {
      machines {
        id
        name
      }
    }
  }
`;

export {
  getCensusListsQuery,
  getCensusListsCntQuery,
  getMachineIdQuery,
  deleteCensusRowQuery,
  deleteCensusPermQuery,
  getStatusOfdevices,
  deleteFromMachineQuery,
  deleteFromEventsQuery,
  deleteFromUpdateMachinesQuery,
  deleteFromSoftInstMachineQuery,
  deleteFromPatchStatusOuery,
  deleteFromMachineGroupMapQuery,
  deleteFromMachineGroupsOuery,
  deleteFromAssetDataQuery,
};
