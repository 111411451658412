import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getGroupedDevicesListQuery, updateLastModifiedQuery } from "../../graphql/device_classification_devices_queries";
import { getAttachedGroupsOfDeviceQuery, attachDevicesToGroupsQuery, getDevicesListQuery } from "../../graphql/groups_queries";
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: any
    pageNo: any
    setTotalPages: any
    setTotalRecords: any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    deleteDevices: any
    addDevices: any
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
}

const Device_classification_devices_func = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    
    const tenantName = contextValue.tenantName;
    var tempName = localStorage.getItem('classificationName');
    const classificationName = (tempName != undefined && tempName.length > 0) ? tempName : contextValue.selectedRowDetails.name;

    var device_classification: any = [];
    const getGroupedDevicesList = async (whereCondition: any, limit: any, offset: number) => {
        const { data, loading, error } = await client.query({
            query: getGroupedDevicesListQuery,
            variables: {whereCondition, limit, offset},
            fetchPolicy: 'network-only'
        });

        if(!loading && data){
            props.func(data);
        }
    };

    const getGroupedDevicesListCnt = async (whereCondition: any, limit: any = null, offset: number = 0) => {
        const { data, loading, error } = await client.query({
            query: getGroupedDevicesListQuery,
            variables: {whereCondition, limit, offset},
            fetchPolicy: 'network-only'
        });

        if(!loading && data){
            var tempArr: any = [];
            data.core_Census.map((val: any) => {
                tempArr.push(val.host);
            })
            props.setSelectedDevices(tempArr);
            
            props?.setExportDetails && props?.setExportDetails(data.core_Census);
            props.setTotalRecords(data.core_Census?.length);
            props.setTotalPages(Math.ceil((data.core_Census?.length)/10));
        }
    };

    const getDevicesList = async (whereCondition: any) => {
        const { data, loading, error } = await client.query({
          query: getDevicesListQuery,
          variables: {whereCondition}
        });
    
        if(!loading && data){
          props.setDevicesData(data);
        }
    };

    const deleteUnGroupedClassification = async (ids: any) => {
        await getAttachedGroupsOfDevice({site: {_eq: tenantName}, host: {_in: ids}});

        device_classification.map(async (res: any) => {
            var finalClassification: any = '';

            finalClassification = res.deviceClassifications.split(",").filter((ele: any) => ele != classificationName).join(",");
            
            await updateAttachedGroupsOfDevice(tenantName, res.host, finalClassification);
        });

        props.setIsToast(true);
        props.setOverlayOpen(false);
        setTimeout(() => {
            props.setDeleteDevices(false);
        },100);
    }

    const getAttachedGroupsOfDevice = async (whereCondition: any) => {
        var { data, loading, error } = await client.query({
          query: getAttachedGroupsOfDeviceQuery,
          variables: {whereCondition}
        });
    
        if(!loading && data){
          data?.core_Census.map((val: any) => {
            device_classification = [...device_classification, 
              {
                host: val.host,
                deviceClassifications: val.deviceClassifications
              }];
          });
        }
    };

    const updateAttachedGroupsOfDevice = async (site: any, host: any, deviceClassifications: any) => {
        updateLastModified();
        
        var { data } = await client.mutate({
          mutation: attachDevicesToGroupsQuery,
          variables: {site, host, deviceClassifications}
        });
      
        if(data.update_core_Census?.affected_rows > 0){
          console.log("census table updated");
          getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, 10, parseInt(props.pageNo));
          
          getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, null, 0);
        }
    }

    const updateLastModified = async () => {
        var lastModified: any = Math.floor(Date.now() / 1000); 
        
        var { data } = await client.mutate({
            mutation: updateLastModifiedQuery,
            variables: {classificationName, lastModified}
        });
        
        if(data.update_core_DeviceClassification?.affected_rows > 0){
            return true;
        }
    }

    const addDevices = async (site: any, hosts: any, classificationName: String) => {
        await getAttachedGroupsOfDevice({site: {_eq: tenantName}, host: {_in: hosts}});
        
        hosts.map(async (host: any) => {
            var classifications = device_classification.filter((val: any) => val.host == host && !val.deviceClassifications?.split(',').includes(classificationName)); 
            
            if(classifications.length > 0){
                classifications = classifications[0]?.deviceClassifications;
            
                var deviceClassifications = (classifications?.length > 0) ? classifications + "," + classificationName : classificationName;
            
                await updateAttachedGroupsOfDevice(site, host, deviceClassifications);
                props.setIsToast(true);
                props.setOverlayOpen(false);
                setTimeout(() => {
                  props.setAddDevices(false);
                },100);
            }
        });
    };

    useEffect(() => {
        props.deleteDevices && contextValue.selectedRowIdInTable.length > 0 &&
        deleteUnGroupedClassification(contextValue.selectedRowIdInTable);
        props.addDevices && contextValue.selectedRowIdInSubTable.length > 0 && addDevices(tenantName, contextValue.selectedRowIdInSubTable, classificationName);

        if(props.searchVal.length > 0 && props.searchVal !== ''){
            getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, host: {contains: props.searchVal}}, 10, parseInt(props.pageNo))
            getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, host: {contains: props.searchVal}}, null, 0);
        }
        else if(props.isFilter == true){
            if(props.dropDownValue1 == 'Device Name'){
                getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, host: {contains: props.dropDownValue2}}, 10, parseInt(props.pageNo))
                getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, host: {contains: props.dropDownValue2}}, null, 0);
            }else if(props.dropDownValue1 == 'Operating System'){
                getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, os: {contains: props.dropDownValue2}}, 10, parseInt(props.pageNo))
            getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, os: {contains: props.dropDownValue2}}, null, 0);
            }else if(props.dropDownValue1 == 'Nanoheal Version'){
                getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, clientversion: {contains: props.dropDownValue2}}, 10, parseInt(props.pageNo))
            getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, clientversion: {contains: props.dropDownValue2}}, null, 0);
            }
            else if(props.dropDownValue1 == 'Installation Date'){
                getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, born: {contains: props.dropDownValue2}}, 10, parseInt(props.pageNo))
            getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}, born: {contains: props.dropDownValue2}}, null, 0);
            }
        }
        else if(props?.deviceSearchVal?.length > 0 && props?.deviceSearchVal != ''){
            getDevicesList({site: {_eq: tenantName}, host: {contains: props.deviceSearchVal.toUpperCase()}})
        }else if(props.isRefresh){
          props.setIsRefresh(false);
          getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, 10, parseInt(props.pageNo));
          getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, null, 0);
          getDevicesList({site: {_eq: tenantName}})
          props.setRefreshToast(true);  
          setTimeout(() => {
            props.setRefreshToast(false);  
          }, 3000);
        }
        else{
            getGroupedDevicesList({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, 10, parseInt(props.pageNo));
            getGroupedDevicesListCnt({site: {_eq: tenantName}, deviceClassifications: {contains: classificationName}}, null, 0);

            getDevicesList({site: {_eq: tenantName}}) //to list all the devices that are under one tenant
        }
    }, [props.deleteDevices, props.searchVal, props.pageNo, props.isRefresh, props.deviceSearchVal, props.addDevices, props.isFilter]);

    return (
        <></>
    )
}

export { Device_classification_devices_func }
