import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery,getCategory,getPolicyName,getAdmxAdml,deleteUnattachedSingleAutomationQuery} from "../../graphql/configuration_queries";
import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    categorySearchVal?:any
    categorySearchData?:any
    setCategorySearchData?:any
    policySearchVal?:any
    setPolicySearchVal?:any
    dropdownValue3?:any
    setPolicySearchData?:any
    policySearchData?:any
    setDropDownValue4?:any
    dropDownValue4?:any
    setAdmxData?:any
    setAdmlData?:any
    varvalues?:any
    setDropDownValue3?:any
    setdartid?:any
    dartid?:any

}

const Policy_configuration_func = (props: any) => {
  console.log("props new data=",props?.newData);
  console.log("dartid is here :-", props.dartid);
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;
  const[policyid,setPolicyId]:any=useState(null);
  // const[policyname,setPolicyName]:any=useState("sabal");
  let category='';
  let admxl='';
  // let policyid:any= +'';
  // let policyname:any ="";
  console.log("inside function drop3",props.dropDownValue3)
  console.log("policy id set :-", policyid);

  const deleteUnattachedAutomation = async (ids: any) => {
    const { data } = await client.mutate({
      mutation: deleteUnattachedSingleAutomationQuery,
      variables: {ids},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteAutomation(false);
      props.setIsToast(true);
      getAutomationList({types: {_eq: 'policy'}}, 50, parseInt(props.pageNo));
    }
  }

  const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });
    
    if(!loading && data){
      if(props.overlayName == 'editAutomation'){
        console.log("edited value=",data.core_AutomationJSON[0]);
        
        props.setFormData({...props.formData,
          name: data.core_AutomationJSON[0]?.automationName,
          description: data.core_AutomationJSON[0]?.description,
          jsonData: JSON.parse(data.core_AutomationJSON[0]?.jsonValue)
        });
      }else {
        props.func(data);
      }
    }
  };

  const getAutomationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON?.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON?.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };
  const getCategories = async () => {
    const { data, loading, error } = await client.query({
      query: getCategory,
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var allCategories = data?.mdm_AdmxAdml?.map((item: { Category: any; }) => item.Category);
      var categoriesSet = new Set(allCategories);
      var filteredCategories = Array.from(categoriesSet);

      props.setCategorySearchData(filteredCategories);
      getPolicy();
    }
  };
  const getPolicy = async () => {
    const { data, loading, error } = await client.query({
      query: getPolicyName,
      variables: {category},
      fetchPolicy: 'network-only',
    });

    if(data){
      props.setPolicySearchData(data.mdm_AdmxAdml.map((item: {policy_name: any; }) => item.policy_name));
      console.log("asdfghjk",data.mdm_AdmxAdml.map((item: {policy_name: any; }) => item.policy_name))
    }
  };
  const getAdmxl = async () => {
    const { data, loading, error } = await client.query({
      query: getAdmxAdml,
      variables: {admxl},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      
      console.log("admldata",data)
      props.setAdmlData(data.mdm_AdmxAdml[0].ADML);
      setPolicyId(data.mdm_AdmxAdml[0].policy_id);
      console.log("21",data.mdm_AdmxAdml[0].policy_id);
      console.log("22",data.mdm_AdmxAdml[0].policy_name)
      // setPolicyName(data.mdm_AdmxAdml[0].policy_name);
      props.setAdmxData(data.mdm_AdmxAdml[0].ADMX);
      // console.log('getAdmxl',data.mdm_AdmxAdml[0]?.ADML.map((item: {ADML: any; }) => item.ADML))
    }
  };

  const getJsonSchema = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data?.core_DartJsonSchema?.length > 0){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
     // console.log("jsonSchemaData=",jsonSchemaData);

      data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
    }
  };

  const addAutomation = async (automationName: String, description: String, jsonVal: String, createdBy: String, dartid: number) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);
    var types: String = 'policy';
    var category: String = 'Policy';
    var varValueObj: any = {};
    var varValues = '';

    varValueObj = jsonVal;
    // if(dartid == 60){
    //   var componentsArr = ['S00060TargetStr', 'S00060FileGroupsStr'];
      
    //   componentsArr.map((compName: any) => {
    //     var compVal = '';

    //     varValueObj[`${compName}`]?.map((val: any, i: any) => {
    //       compVal += i == 0 ? Object.values(val).join(",") : "\n" + Object.values(val).join(",");
    //     });
    //     console.log(compName+"="+compVal);
        
    //     varValueObj = {...varValueObj, [`${compName}`]: compVal};
    //   });
    // }
    varValues = props.varvalues;
    
    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid, category, varValues}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      setPolicyId(null);
      props.setDropDownValue3('');
      props.setDropDownValue4('');
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
      getAutomationList({dartid: {_eq: dartid}, types: {_eq: 'policy'}}, 50, parseInt(props.pageNo));
    }
  };

  const editAutomation = async (automationName: String, description: String, jsonVal: String, rowID: Number, dartid: Number) => {
    
    const lastModified: any = Math.floor(Date.now() / 1000);
    // dartid= policyid;
    dartid=props.dartid;
    var jsonValue = JSON.stringify(jsonVal);
    var varValueObj: any = {};
    var varValues = '';

    varValueObj = jsonVal;
    varValues =(props.varvalues);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid, varValues}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
      getAutomationList({dartid: {_eq: dartid}, types: {_eq: 'policy'}}, 50, parseInt(props.pageNo));
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };
    
  useEffect(() => {    
    var dartid = parseInt(dartNo);
  
    getCategories();
    // getPolicy();
    if(props.overlayName == '' && props.isRefresh == false && props.deleteAutomation == false ){
      getJsonSchema(parseInt(dartNo));
      getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
      props.setRefresh(false); 
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }

    if(props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
      getAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
      getAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    }
    // else if(props.isFilter == true){
    //  if(props.dropDownValue1 == 'Name'){
    //   getAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    //  }else if(props.dropDownValue1 == 'Created by'){
    //   getAutomationList({dartid: {_eq: dartid}, createdBy: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, createdBy: {contains: props.searchVal}});
    //  }else if(props.dropDownValue1 == 'Status'){
    //   getAutomationList({dartid: {_eq: dartid}, status: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, status: {contains: props.searchVal}});
    //  }
    // }
    
    else if(props.deleteAutomation && contextValue.selectedRowIdInTable.length > 0){
      deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
      console.log("props.newData=",props.newData);
      
      props.overlayName == 'addAutomation' && policyid != null && addAutomation(props.newData.name, props.newData.description, props.newData.jsonData, userName, policyid);

      props.overlayName == 'editAutomation' && policyid != null && editAutomation(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID,policyid);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editAutomation'){
      // getAutomationList({dartid: {_eq: props.dartid}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else{
      props.overlayName != 'addAutomation' && getAutomationList({ types: {_eq: 'policy'}}, 50, parseInt(props.pageNo));

      props.overlayName == '' && getAutomationListCnt({ types: {_eq: 'policy'}});
    }
  },[props.deleteAutomation, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo,props.isFilter,props.dropDownValue3,policyid]);

  useEffect(() => {
    console.log("pppp=", props.dropDownValue3);
    console.log("props,formdata",props?.formData?.jsonData?.Category)
    category = props?.dropDownValue3?.length > 0 ? props?.dropDownValue3[0] : "";
    admxl = props?.dropDownValue4?.length > 0 ? props?.dropDownValue4[0] : "";
    console.log("category",category)
    
    if(props?.dropDownValue3?.length > 0 || props?.formData?.jsonData?.Category?.length == true){
      getPolicy();
      console.log("value3",props.dropDownValue3);
      console.log("length",props?.formData?.jsonData?.Category?.length > 0)
    }
    if(props?.dropDownValue4?.length > 0 || props?.formData?.jsonData?.Policy?.length == true){
      getAdmxl();
      console.log("4",props.dropDownValue4);
    }
  },[props.dropDownValue3,props.dropDownValue4,policyid,props.formData])
   
  useEffect(() => {
    if(props.overlayName == 'editAutomation'){    
      setPolicyId(props.dartid);
    } 
  }, [props.dartid])
  
  return (
    <></>
  )
}

export { Policy_configuration_func }
