import { gql } from '@apollo/client';

// const getOsIdQuery = gql`
//     query MyQuery {
//         profile_WizardNameNH(where: {parent_name: {_eq: "Others"}, child_role: {_nin: ["otherConfigs", "messageConfiguration"]}}, order_by: {Name: asc}) {
//             Id
//             Name
//             profiles
//         }
//     }
// `;

// const getAutomationTemplateQuery = gql`
//     query MyQuery($dartNos: [Int!] = []) {
//         profile_WizardMasterNH(where: {GUIType: {_eq: "checkbox"}, DartNo: {_in: $dartNos}}) {
//             DartNo
//             Name
//             Help
//         }
//     }
// `;

const getdartTemplateQuery = gql`
  query MyQuery($whereCondition: JSON) {
    profile_DartTemplateDetails(where: $whereCondition) {
      id
      dartNo
      description
      supportingOS
      templateName
    }
  }
`;

export { getdartTemplateQuery };
