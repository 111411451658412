import { gql } from '@apollo/client';

// const getStatusListsQuery = gql`
// query MyQuery ($whereCondition: JSON!,$limit: Int!, $offset: Int! = 0, $order: JSON){
//   core_Census(where: $whereCondition, limit: $limit, offset: $offset, order_by: $order) {
//       Core_Census_Audit (where: {JobType: {_eq: "Interactive"}}){
//         AgentName
//         MachineTag
//         Dart
//         JobType
//          JobCreatedTime
//          ProfileName
//          SelectionType
//          MachineTag
//          MachineOs
//          AgentName
//          JobStatus
//     }
//    }
//   }
// `;

const getStatusListsQuery = gql`
  query MyQuery($whereCondition: JSON!, $MachineTag: String!, $limit: Int!, $offset: Int! = 0, $order: [communication_Audit_order_by!]) {
    communication_Audit(
      where: { JobType: { _eq: "Interactive" }, MachineTag: { contains: $MachineTag } }
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      Communcication_Audit_Census(where: $whereCondition) {
        site
      }
      AgentName
      MachineTag
      Dart
      JobType
      JobCreatedTime
      ProfileName
      SelectionType
      MachineTag
      MachineOs
      AgentName
      JobStatus
      AID
      ClientExecutedTime
    }
  }
`;

const getStatusListsCntQuery = gql`
  query MyQuery($whereCondition: JSON!, $MachineTag: String!) {
    communication_Audit(where: { JobType: { _eq: "Interactive" }, MachineTag: { contains: $MachineTag } }) {
      Communcication_Audit_Census(where: $whereCondition) {
        site
      }
      AgentName
      MachineTag
      Dart
      JobType
      JobCreatedTime
      ProfileName
      SelectionType
      MachineTag
      MachineOs
      AgentName
      JobStatus
      AID
      ClientExecutedTime
    }
  }
`;

// const getStatusListsCntQuery = gql`
// query MyQuery($whereCondition: JSON!) {
//   core_Census(where: $whereCondition) {
//     Core_Census_Audit (where: {JobType: {_eq: "Interactive"}}){
//     Dart
//     MachineOs
//     JobStatus
//     SelectionType
//   }
// }
// }
// `;

const getStatusActivityQuery = gql`
  query MyQuery($whereCondition: JSON!, $time: Int!) {
    core_AutomationJSON(where: $whereCondition) {
      core_AutomationJSON_Audit(where: { JobType: { _eq: "Interactive" }, JobCreatedTime: { _eq: $time } }) {
        JobCreatedTime
        SelectionType
        AgentName
        AgentUniqId
        MachineTag
      }
      automationName
      createdBy
      jsonValue
    }
  }
`;

//status_target_fun

const getStatusActivityCntQuery = gql`
  query MyQuery($whereCondition: JSON!, $time: Int!) {
    core_AutomationJSON(where: $whereCondition) {
      core_AutomationJSON_Audit(where: { JobType: { _eq: "Interactive" }, JobCreatedTime: { _eq: $time } }) {
        JobCreatedTime
        SelectionType
        AgentName
        AgentUniqId
        MachineTag
        JobStatus
        ClientExecutedTime
      }
      automationName
      createdBy
      jsonValue
    }
  }
`;

//solution details
const getStatusSolnDetailsQuery = gql`
  query MyQuery($whereCondition: JSON!, $time: Int!) {
    automationWithCondition: core_AutomationJSON(where: $whereCondition) {
      core_AutomationJSON_Audit(where: { JobType: { _eq: "Interactive" }, JobCreatedTime: { _eq: $time } }) {
        JobCreatedTime
        SelectionType
        AgentName
        AgentUniqId
        MachineTag
        JobStatus
        ClientExecutedTime
      }
      automationName
      createdBy
      jsonValue
      id
    }
    automationWithoutCondition: core_AutomationJSON {
      automationName
      id
    }
  }
`;

export { getStatusListsQuery, getStatusListsCntQuery, getStatusActivityQuery, getStatusActivityCntQuery, getStatusSolnDetailsQuery };
