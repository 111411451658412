import { gql } from '@apollo/client';

const getDeviceClassificationListQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int = null, $offset: Int = null) {
    core_DeviceClassification(where: $whereCondition, limit: $limit, offset: $offset) {
      classificationName
      createdOn
      createdBy
      id
      isGroupConfigured
      lastModified
      type
    }
  }
`;

const getDevicesListQuery = gql`
  query MyQuery($whereCondition: JSON) {
    core_Census(where: $whereCondition, limit: 20) {
      host
      id
      os
    }
  }
`;

const getMcatidQuery = gql`
  query MyQuery($category: String!) {
    core_MachineCategories(where: { category: { _eq: $category } }) {
      mcatid
      mcatuniq
    }
  }
`;

const insertConfigGroupQuery = gql`
  mutation MyMutation($name: String!, $created: Int, $mcatuniq: String!, $mgroupuniq: String!, $username: String!, $type: String) {
    insert_core_MachineGroups(
      objects: {
        name: $name
        created: $created
        mcatuniq: $mcatuniq
        mgroupuniq: $mgroupuniq
        username: $username
        global: 1
        human: 1
        style: 2
        eventquery: 0
        eventspan: 0
        assetquery: 0
        boolstring: $type
      }
    ) {
      affected_rows
    }
  }
`;

const insertDeviceClassificationQuery = gql`
  mutation MyMutation(
    $classificationName: String!
    $createdBy: String!
    $createdOn: Int!
    $isGroupConfigured: String!
    $tenant: String!
    $type: String
  ) {
    insert_core_DeviceClassification(
      objects: {
        classificationName: $classificationName
        createdBy: $createdBy
        createdOn: $createdOn
        isGroupConfigured: $isGroupConfigured
        tenant: $tenant
        type: $type
      }
    ) {
      affected_rows
    }
  }
`;

const updateDeviceClassificationQuery = gql`
  mutation MyMutation($id: Int!, $classificationName: String!, $isGroupConfigured: String!, $mgroupuniq: String!) {
    update_core_DeviceClassification(
      where: { id: { _eq: $id }, classificationName: { _eq: $classificationName } }
      _set: { isGroupConfigured: $isGroupConfigured, mgroupuniq: $mgroupuniq }
    ) {
      affected_rows
    }
  }
`;

const getAttachedGroupsOfDeviceQuery = gql`
  query MyQuery($whereCondition: JSON) {
    core_Census(where: $whereCondition) {
      deviceClassifications
      host
    }
  }
`;

const attachDevicesToGroupsQuery = gql`
  mutation MyMutation($site: String!, $host: String!, $deviceClassifications: String!) {
    update_core_Census(where: { site: { _eq: $site }, host: { _eq: $host } }, _set: { deviceClassifications: $deviceClassifications }) {
      affected_rows
    }
  }
`;

//get mcatid from above query and username from session
// const getGroupListsQuery = gql `
//     query MyQuery($limit: Int, $offset: Int, $whereCondition: JSON, $order_by: [core_MachineGroups_order_by!], $username: String!, $mcatid: Int!) {
//         core_MachineGroups(where: $whereCondition, limit: $limit, offset: $offset, order_by: $order_by) {
//             MachineGroups_GroupMappings(where: {username: {_eq: $username}}) {
//                 modifiedby
//                 modifiedtime
//             }
//             name
//             MachineGroups_MachineCategories(where: {mcatid: {_eq: $mcatid}}) {
//                 category
//             }
//             MachineGroups_MachineGroupMaps_aggregate {
//                 aggregate {
//                     count(column: mgmapid)
//                 }
//             }
//             assetquery
//             boolstring
//             cksum
//             created
//             eventquery
//             eventspan
//             global
//             human
//             mcatuniq
//             mgroupid
//             mgroupuniq
//             revlname
//             style
//             updated
//             username
//         }
//     }
// `;

const getGroupListsQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int, $offset: Int) {
    core_DeviceClassification(where: $whereCondition, limit: $limit, offset: $offset) {
      classificationName
      id
    }
  }
`;

//get the mgroupid from the above query
const viewGroupDetailsQuery = gql`
  query MyQuery($mgroupid: Int) {
    core_MachineGroups(where: { mgroupid: { _eq: $mgroupid } }) {
      MachineGroups_MachineGroupMaps {
        MachineGroupMap_Census {
          site
          last
          host
        }
      }
    }
  }
`;

const deleteUnGroupedClassificationQuery = gql`
  mutation MyMutation($idArr: [Int!], $tenantName: String) {
    delete_core_DeviceClassification(where: { id: { _in: $idArr }, tenant: { _eq: $tenantName } }) {
      affected_rows
    }
  }
`;

export {
  getDeviceClassificationListQuery,
  getDevicesListQuery,
  getGroupListsQuery,
  viewGroupDetailsQuery,
  getMcatidQuery,
  insertConfigGroupQuery,
  insertDeviceClassificationQuery,
  updateDeviceClassificationQuery,
  attachDevicesToGroupsQuery,
  getAttachedGroupsOfDeviceQuery,
  deleteUnGroupedClassificationQuery,
};
