import { Radio, RadioGroup, useId } from "@fluentui/react-components";
import type { RadioGroupProps } from "@fluentui/react-components";

type dataProps = {
    data: any
    defaultValue?: any
    disabled?: boolean
}

export const RadioGroups = (props: Partial<RadioGroupProps & dataProps>) => {
    const labelId = useId("label");
    console.log("Data: ", props.data);
    let names = Object.keys(props.data);

    return (
        <div>
            {/* <Label id={labelId}>Suggested Fixes</Label> */}
            <RadioGroup {...props} aria-labelledby={labelId} defaultValue={props.defaultValue} disabled={props?.disabled != undefined ? props.disabled : false} required={props.required}>
                {names.map((val: any) => <Radio className="fixes" value={props.data[val]} label={val}/>)}
            </RadioGroup>
        </div>
    );
};