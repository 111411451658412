import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  getId,
  editswdConf,
  getSwdListsQuery,
  getSwdListsCntQuery,
  addSwdPackageQuery,
  addSwdPackageConfigurationQuery,
  deleteSwdPackage,
  getJsonSchemaQueryPackageDetails,
  getJsonSchemaQueryAppConfiguration,
  getJsonSchemaQueryAdvanceConfiguration,
  editswdForm,
  getswdForm,
  deleteConfignew,
  getJsonSchemaQueryAppConfigurationU,
  getPackageID,
} from '../graphql/swd_queries';
import { async } from 'q';
import { v4 as uuidv4 } from 'uuid';
import path from 'path';
import { GlobalContext } from '../hooks/globalContext';
import OverLay from '../components/side_menu/overlay_side_menu.component';
import AppConfig from '../utility/AppConfig';
// import getStorageToken from '../utility/authUtils';

type dataProps = {
  [x: string]: any;
  data: any;
  func: any;
  searchVal: any;
  pageNo: any;
  setTotalPages: any;
  setTotalRecords: any;
  savePackageDetails?: any; // Making it optional
  setSavePackageDetails?: any;
  deleteprofile: any;
  setDeleteprofile: any;
  setDeleteDisabled: any;
  pkgId: any;
  setJsonDataPkg: any;
  setUiDataPkg: any;
  setJsonDataApp: any;
  setUiDataApp: any;
  setJsonDataAdv: any;
  setUiDataAdv: any;
  formDataPkg: any;
  formDataApp: any;
  formDataAdv: any;
  setFormDataPkg: any;
  setFormDataApp: any;
  setFormDataAdv: any;
  rowID?: any;
  overlayName?: any;
  handleEditData?: any;
  overlay?: any;
  setOverlayOpen?: any;
  setRowId?: any;
  isFilter?: any;
  dropDownValue1?: any;
  dropDownValue2?: any;
  setIsFilter?: any;
  isRefresh?: any;
  setIsRefresh?: any;
  setRefreshToast?: any;
  setDelete?: any;
  isDelete?: any;
  status?: any;
  setStatus?: any;
  setExportDetails?: any;
  rowconfID?: any;
  setRowconfID?: any;
};

export const Swd_func = (props: dataProps) => {
  const client = useApolloClient();
  const app_packageId = uuidv4();
  const [filesList, setFilesList] = useState([1]);
  const [storageToken, setStorageToken] = useState('');
  const [isdeleteList, setDeleteList] = useState([]);
  // const[,setPackageId]:any = useState(null)
  let packageId: any;
  let deletelist: any = [];

  // const [formData, setFormData] = useState({
  //     packageName: 'packagenameSWD',
  //     packageDesc: 'packageDescSWD',
  //     version: 'packageSWD',
  //     platform : 'platformSWD',
  //     applicationName : 'applicationNameSWD',
  //   });
  const contextValue: any = useContext(GlobalContext);
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;
  var selectedRowsID = contextValue.selectedRowIdInTable;
  var device_package: any = [];

  // const [formData, setFormData] = useState({
  //     packageName: 'packagenameSWD',
  //     packageDesc: 'packageDescSWD',
  //     version: 'packageSWD',
  //     platform : 'platformSWD',
  //     applicationName : 'applicationNameSWD',
  //   });

  const getJsonSchemaQueryPkg = async () => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQueryPackageDetails,
    });

    if (!loading && data) {
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema['ui:submitButtonOptions'] = {
        norender: true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
      console.log('jsonSchemaData=', jsonSchemaData);
      (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataPkg(jsonSchemaData);
      // (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setUiData(jsonSchemaData);
      // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataPkg(uiSchema);
    }
  };

  const getJsonSchemaQueryApp = async () => {
    if (!props.status) {
      const { data, loading, error } = await client.query({
        query: getJsonSchemaQueryAppConfiguration,
        // variables: {dartid},
        // fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
        uiSchema['ui:submitButtonOptions'] = {
          norender: true,
        };

        // get the list of files from s3 and set dropdown
        const headers = {
          'x-auth-token':
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGF0dXMiOnRydWUsImlhdCI6MTcwOTEwMzk0NCwiZXhwIjoxNzA5MTA3NTQ0fQ.TrZ0b_SQ5kVQOkTItuuzz4HAZe7QpSZtTzmbw9IB0V8',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const requestOptions = {
          method: 'GET', // specify the HTTP method (GET, POST, etc.)
          headers: headers,
          // add other options like 'body' if needed
        }; //await fetch('http://localhost:5001/storage/api/getList',requestOptions)

        await fetch(`${AppConfig.DASHBOARD_V2_API_URL}/api/files`)
          .then(response => response.json())
          .then(data => {
            var list = data.map((val: any) => val.Key);
            setFilesList(list);
          })
          .catch(error => console.error('Error fetching files:', error));

        var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
        if (jsonSchemaData?.items?.allOf[0]?.dependencies?.Application_details?.oneOf[1]?.properties?.uploadApplication1) {
          jsonSchemaData.items.allOf[0].dependencies.Application_details.oneOf[1].properties.uploadApplication1.enumNames = filesList; //map same thing at
          jsonSchemaData.items.allOf[0].dependencies.Application_details.oneOf[1].properties.uploadApplication1.enum = filesList; //both places.
        }
        console.log('jsonSchemaData=', jsonSchemaData);
        (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataApp(jsonSchemaData);
        // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
        data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataApp(uiSchema);
      }
    } else {
      const { data, loading, error } = await client.query({
        query: getJsonSchemaQueryAppConfigurationU,
        // variables: {dartid},
        // fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
        uiSchema['ui:submitButtonOptions'] = {
          norender: true,
        };

        var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
        console.log('jsonSchemaData=', jsonSchemaData);
        (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataApp(jsonSchemaData);
        // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
        data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataApp(uiSchema);
      }
    }
  };

  const getJsonSchemaQueryAdv = async () => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQueryAdvanceConfiguration,
      // variables: {dartid},
      // fetchPolicy: 'network-only',
    });

    if (!loading && data) {
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema['ui:submitButtonOptions'] = {
        norender: true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
      console.log('jsonSchemaData=', jsonSchemaData);
      (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataAdv(jsonSchemaData);
      // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataAdv(uiSchema);
    }
  };

  const getSwdList = async (whereCondition: any, limit: Number, offset: Number) => {
    try {
      const { data, loading, error } = await client.query({
        query: getSwdListsQuery,
        variables: { whereCondition, limit, offset },
        fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        console.log('Swd data=', data.core_Swd);
        props.func(data);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const getSwdListCnt = async (whereCondition: any) => {
    try {
      const { data, loading, error } = await client.query({
        query: getSwdListsCntQuery,
        variables: { whereCondition },
        fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        props?.setExportDetails && props?.setExportDetails(data.softinst_Packages);
        props.setTotalRecords(data.softinst_Packages.length);
        props.setTotalPages(Math.ceil(data.softinst_Packages.length / 50));
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  //CONFIGURATION APPENDING TO A PARTICULAR FORMAT 32bit and 64 bit
  // example confStrDownload_32 =
  //  1,NT,https://deployment-static-storage.s3.amazonaws.com/41-qbzkbibgygxq/softdist/7z2201-x32.exe,0,0,4,C:\Program Files\Nanoheal\Client\Tools\Scripts\

  const getForm = async () => {
    const app_packageiD = props.rowID;
    try {
      const { data, loading, error } = await client.query({
        query: getswdForm,
        variables: { app_packageiD },
        fetchPolicy: 'network-only',
        // fetchPolicy: "network-only"
      });
      // const dataa=data.softinst_PackagesConfiguration_new.formDataApp;
      if (!loading && data) {
        console.log('YESSSSSSS');
        props.handleEditData(data);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const addSwdPackage = () => {
    // form_data here
    // package details
    const packageDesc = props.formDataPkg.Package_description ? props.formDataPkg.Package_description : null;
    const packageName = props.formDataPkg.Package_name ? props.formDataPkg.Package_name : null;
    const version = props.formDataPkg.Package_version ? props.formDataPkg.Package_version : null;
    const platform = props.formDataPkg.OS ? props.formDataPkg.OS : null;

    //Advance Configuration  starts
    let pathLogFile = '';
    const readLogFileCheck = props.formDataAdv.Logfile; //1 -readlogfilebutton enabled  or 0 readlogFilebutton disabled
    if (readLogFileCheck == 1) {
      pathLogFile = props.formDataAdv.Log_file; //path of log file
    } else {
      pathLogFile = '';
    }

    var status: any;
    if (props.status === true) {
      status = 'Uninstallation';
    } else {
      status = 'Inatallation';
    }

    //postValidation in advance configuration
    const postValidationEnabledCheckAc = props.formDataAdv.Validation; //1 if postvalidation button is enabled 0 postvalidation button is disabled
    const validationSelectType = props.formDataAdv.Validation_selectType ? props.formDataAdv.Validation_selectType : null; //File or Registry
    const validationFilePath = props.formDataAdv.Validation_filePath ? props.formDataAdv.Validation_filePath : null;
    const registryRootkeyAc = props.formDataAdv.Validation_Reg_rootKey ? props.formDataAdv.Validation_Reg_rootKey : null;
    const registryPathAc = props.formDataAdv.Validation_Registry_path ? props.formDataAdv.Validation_Registry_path : null;
    const registryTypeAc = props.formDataAdv.Validation_Reg_type ? props.formDataAdv.Validation_Reg_type : null;
    const registryValueAc = props.formDataAdv.Validation_Registry_value ? props.formDataAdv.Validation_Registry_value : null;

    //   //8. post validation for the format/advance configuration
    //   if(preCheckMode === 'File'){
    //     //check condition for proceed // example formqat . 0#C:\test.txt
    //     confStrDownload_32+="0" + `${filepath_32downloadAc}`
    // }
    // else if(preCheckMode === 'Registry'){
    //   //1#1#SOFTWARE\Nanoheal\Nanoheal Client\Install#HFNload
    //   confStrDownload_32+="1" + `#` + `${Registry_rootkey_32downloadAc}` + `#` + `${Registry_path_32downloadAc}` + `#` + `${Registry_value_32downloadAc}`
    // }
    // Advance configuration ends here

    //content below this would be looped for 4 applications
    let config32bit = '';
    let config64bit = '';
    const messageBoxArray: string[] = [];
    const patch = '';
    props.formDataApp.map(async (App: any, indexValue: number) => {
      // APP details
      const applicationName = App.Application_name ? App.Application_name : null;
      const config3264type = App.commonFilesOption ? App.commonFilesOption : null;
      // console.log("checking the FILE DATA HERE",App.uploadApplication32Bit.files[0])
      let uploadedApplication = App?.uploadApplication1 ?? '';

      const path = getDownloadableLink(uploadedApplication);
      const path2 = null;
      //app details  section end

      //----------------DONWLOAD SECTION 32bit ---------------
      const executeIn_download_32 = App.Executein32bitd ? App.Executein32bitd : null;
      const downloadOperationMode_download_32 = App.Operation_mode32bit ? App.Operation_mode32bit : null;
      let downloadLocation_bit_32 = '';
      if (App.Download_location32bit == 'Default') {
        downloadLocation_bit_32 = 'C:\\ProgramData';
      } else {
        downloadLocation_bit_32 = App.download_location32bit ? App.download_location32bit : '';
      }
      const preCheckMode_download_32 = App.selectType32bitd ? App.selectType32bitd : null; // 0 for file ,  1 for software, 2 for registry
      const preCheckValue_download_32 = App.preCheck32bitd ? App.preCheck32bitd : null;
      // const messageDuringDownload_32 = App.MessageboxD ? App.MessageboxD : null
      // if(messageDuringDownload_32 != null){
      // messageBoxArray.push(messageDuringDownload_32)
      // }
      // const processToTerminate = App.Operation_mode32bit ? App.Operation_mode32bit : null
      const filepath_download_32 = App.filePath32bitd ? App.filePath32bitd : ' ';
      const Registry_rootkey_download_32 = App.RegrootKey32bitd ? App.RegrootKey32bitd : null;
      const Registry_path_download_32 = App.Registrypath32bitd ? App.Registrypath32bitd : null;
      const Registry_type_download_32 = App.Regtype32bitd ? App.Regtype32bitd : null;
      const Registry_value_download_32 = App.Registryvalue32bitd ? App.Registryvalue32bitd : null;
      const softwareName_download_32 = App.selectType_softName32bitd ? App.selectType_softName32bitd : null;
      const softwareVersion_download_32 = App.selectType_softVersion32bitd ? App.selectType_softVersion32bitd : null;
      // -----------DONWLOAD SECTION PARAMATERS 32bit END-----

      //-----------INSTALL SECTION PARAMERTES  32bit START
      const executeIn_install_32 = App.Executein32bitI ? App.Executein32bitI : null;
      const installationMode_install_32 = App.Installationmode32bit ? App.Installationmode32bit : '';
      const commandLineInstallationInstruction_install_32 = App.CmdInstr32bit ? App.CmdInstr32bit : '';
      const MaxTime32bit_install_32 = App.Max_time32bit ? App.Max_time32bit : null;
      const messageBox32bit_install_32 = App.MessageBox ? App.MessageBox : null;
      // if(messageBox32bit_install_32 != null){
      // messageBoxArray.push(messageBox32bit_install_32)
      // }
      const terminateProcess32bit_install_32 = App.Terminate32bit ? App.Terminate32bit : null;
      const preCheckMode_install_32 = App.selectType32bitI ? App.selectType32bitI : null; //file, registry, software
      const preCheckValue_install_32 = App.preCheck32bitI ? App.preCheck32bitI : null;

      const filepath_install_32 = App.filePath32bitI ? App.filePath32bitI : null;
      const Registry_rootkey_install_32 = App.RegrootKey32bitI ? App.RegrootKey32bitI : null;
      const Registry_path_install_32 = App.Registrypath32bitI ? App.Registrypath32bitI : null;
      const Registry_type_install_32 = App.Regtype32bitI ? App.Regtype32bitI : null;
      const Registry_value_install_32 = App.Registryvalue32bitI ? App.Registryvalue32bitI : null;
      const softwareName_install_32 = App.selectType_softName32bitI ? App.selectType_softName32bitI : null;
      const softwareVersion_install_32 = App.selectType_softVersion32bitI ? App.selectType_softVersion32bitI : null;
      //-----------INSTALL SECTION PARAMETERS 32bit END---------

      // 64 bit configuration Download STARTS
      const executeIn_download_64 = App.Executein64bitd ? App.Executein64bitd : null;
      const downloadOperationMode_download_64 = App.Operation_mode64bit ? App.Operation_mode64bit : null;
      let downloadLocation_bit_64 = '';
      if (App.Download_location64bit == 'Default') {
        downloadLocation_bit_64 = 'C:\\ProgramData';
      } else {
        downloadLocation_bit_64 = App.download_location64bit ? App.download_location64bit : '';
      }
      // const downloadLocation_bit_64  = App.download_location64bit ? App.download_location64bit : null
      const preCheckMode_download_64 = App.selectType64bitd ? App.selectType64bitd : null; // 0 for file ,  1 for software, 2 for registry
      const preCheckValue_download_64 = App.preCheck64bitd ? App.preCheck64bitd : null;
      const messageDuringDownload_64 = App.Messagebox ? App.Messagebox : ' ';
      // if(messageDuringDownload_64 != null){
      // messageBoxArray.push(messageDuringDownload_64)
      // }
      // const processToTerminate_64 = App.Operation_mode64bit ? App.Operation_mode64bit : null
      const filepath_download_64 = App.filePath64bitd ? App.filePath64bitd : '';
      const Registry_rootkey_download_64 = App.RegrootKey64bitd ? App.RegrootKey64bitd : null;
      const Registry_path_download_64 = App.Registrypath64bitd ? App.Registrypath64bitd : null;
      const Registry_type_download_64 = App.Regtype64bitd ? App.Regtype64bitd : null;
      const Registry_value_download_64 = App.Registryvalue64bitd ? App.Registryvalue64bitd : null;

      const softwareName_download_64 = App.selectType_softName64bitd ? App.selectType_softName64bitd : null;
      const softwareVersion_download_64 = App.selectType_softVersion64bitd ? App.selectType_softVersion64bitd : null;
      // 64 bit configuration Download ENDS

      // 64 bit configurations install starts
      const executeIn_install_64 = App.Executein64bitI ? App.Executein64bitI : '';
      const installationMode_install_64 = App.Installationmode64bit ? App.Installationmode64bit : null;
      const commandLineInstallationInstruction_install_64 = App.CmdInstr64bit ? App.CmdInstr64bit : '';
      const MaxTime64bit_install_64 = App.Max_time64bit ? App.Max_time64bit : null;
      const messageBox64bit_install_64 = App.MessageBox ? App.MessageBox : null;
      // if(messageBox64bit_install_64 != null){
      //   messageBoxArray.push(messageBox64bit_install_64)
      // }
      const terminateProcess64bit_install_64 = App.Terminate64bit ? App.Terminate64bit : null;
      const preCheckMode_install_64 = App.selectType64bitI ? App.selectType64bitI : null; //file, registry, software
      const preCheckValue_install_64 = App.preCheck64bitI ? App.preCheck64bitI : null;
      const filepath_install_64 = App.filePath64bitI ? App.filePath64bitI : null;
      const Registry_rootkey_install_64 = App.RegrootKey64bitI ? App.RegrootKey64bitI : null;

      const Registry_path_install_64 = App.Registrypath64bitI ? App.Registrypath64bitI : null;
      const Registry_type_install_64 = App.Regtype64bitI ? App.Regtype64bitI : null;
      const Registry_value_install_64 = App.Registryvalue64bitI ? App.Registryvalue64bitI : null;
      const softwareName_install_64 = App.selectType_softName64bitI ? App.selectType_softName64bitI : null;
      const softwareVersion_install_64 = App.selectType_softVersion64bitI ? App.selectType_softVersion64bitI : null;
      const maxpatchtime = App.Maxtime ? App.Maxtime : '';
      const processtokill = App.Terminateprocess ? App.Terminateprocess : '';
      let messagestatus = '';
      messagestatus = App?.MessageBox != '' ? '1' : '0'; // 64 bit configuration install ends
      //Generic parameters for each application
      //messages
      const messageDuringDownload = App.MessageboxD ? App.MessageboxD : null;
      if (messageDuringDownload != null) {
        messageBoxArray.push(messageDuringDownload);
      }
      const messageDuringInstall = App.MessageboxI ? App.MessageboxI : null;
      if (messageDuringInstall != null) {
        messageBoxArray.push(messageDuringInstall);
      }
      ////////////////////////edit/////////////////////////////////////////////////////////////////////////
      if (props.overlayName == 'editInstallation' && props.rowID) {
        const app_packageiD = props.rowconfID;
        console.log('package name here', packageName);
        try {
          const { data } = await client.mutate({
            mutation: editswdForm,
            variables: {
              app_packageiD,
              packageName,
              packageDesc,
              version,
              platform,
              applicationName,
              path,
              path2,
              creationTime: new Date().toLocaleString(),
            },
          });
          //adding the configuration for the above package and its applications to the
          if (data) {
            // console.log("number of affected rows",data);
            console.log('rows affected packagedetails:', data.insert_softinst_Packages.returning);
          }
        } catch (error) {
          console.error('An unexpected error occurred:', (error as Error).message);
        }
      }
      /////////////////////////add/////////////////////////////////////////////////////////////////////
      else {
        try {
          const { data } = await client.mutate({
            mutation: addSwdPackageQuery,
            variables: {
              app_packageId,
              packageName,
              packageDesc,
              version,
              platform,
              applicationName,
              config3264type,
              path,
              path2,
              creationTime: new Date().toLocaleString(),
              status,
            },
          });
          //adding the configuration for the above package and its applications to the
          if (data) {
            // console.log("data returning",data.insert_softinst_Packages)
            // console.log("data returning",data.insert_softinst_Packages.
            // returning[0].id);
            // packageId=data.insert_softinst_Packages.returning[0].id;
            // // setPackageId(something);
            // console.log("checking the packageId",packageId)
            // console.log("number of affected rows",data);
            props.setStatus(false);
            console.log('rows affected packagedetails:', data.insert_softinst_Packages.returning);
          }
        } catch (error) {
          console.error('An unexpected error occurred:', (error as Error).message);
        }
      }

      // const packagePromise = new Promise((resolve,reject) => {
      const confStrDownload_32 = configuration_download(
        platform,
        path,
        executeIn_download_32,
        downloadOperationMode_download_32,
        downloadLocation_bit_32,
        preCheckMode_download_32,
        preCheckValue_download_32,
        filepath_download_32,
        Registry_rootkey_download_32,
        Registry_path_download_32,
        Registry_type_download_32,
        Registry_value_download_32,
        softwareName_download_32,
        softwareVersion_download_32,
      );
      const confStrInstall_32 = configuration_install(
        platform,
        path,
        executeIn_install_32,
        installationMode_install_32,
        downloadLocation_bit_32,
        commandLineInstallationInstruction_install_32,
        preCheckMode_install_32,
        preCheckValue_install_32,
        filepath_install_32,
        Registry_rootkey_install_32,
        Registry_path_install_32,
        Registry_type_install_32,
        Registry_value_install_32,
        softwareName_install_32,
        softwareVersion_install_32,
      );
      const confStrDownload_64 = configuration_download(
        platform,
        path,
        executeIn_download_64,
        downloadOperationMode_download_64,
        downloadLocation_bit_64,
        preCheckMode_download_64,
        preCheckValue_download_64,
        filepath_download_64,
        Registry_rootkey_download_64,
        Registry_path_download_64,
        Registry_type_download_64,
        Registry_value_download_64,
        softwareName_download_64,
        softwareVersion_download_64,
      );
      const confStrInstall_64 = configuration_install(
        platform,
        path,
        executeIn_install_64,
        installationMode_install_64,
        downloadLocation_bit_64,
        commandLineInstallationInstruction_install_64,
        preCheckMode_install_64,
        preCheckValue_install_64,
        filepath_install_64,
        Registry_rootkey_install_64,
        Registry_path_install_64,
        Registry_type_install_64,
        Registry_value_install_64,
        softwareName_install_64,
        softwareVersion_install_64,
      );
      const packagePromiseArray = [];
      packagePromiseArray.push(confStrDownload_32);
      packagePromiseArray.push(confStrInstall_32);
      packagePromiseArray.push(confStrDownload_64);
      packagePromiseArray.push(confStrInstall_64);
      // resolve(packagePromiseArray)
      // })
      // packagePromise.then((result) => {
      //   const{confD_32,confI_32,confD_64,confI_64}:any = result
      //   config32bit+=  "\n" + `${confD_32}` + "\n" + `${confI_32}`
      //   config64bit+=  "\n" + `${confD_64}` + "\n" + `${confI_64}`
      // })
      if (indexValue == 0) {
        config32bit = config32bit + `${confStrDownload_32}` + '\n' + `${confStrInstall_32}`;
        config64bit = config64bit + `${confStrDownload_64}` + '\n' + `${confStrInstall_64}`;
      } else {
        config32bit = config32bit + '\n' + `${confStrDownload_32}` + '\n' + `${confStrInstall_32}`;
        config64bit = config64bit + '\n' + `${confStrDownload_64}` + '\n' + `${confStrInstall_64}`;
      }

      addSwdPackageConfiguration(config32bit, config64bit, packageName, pathLogFile, messageBoxArray, maxpatchtime, processtokill, messagestatus);
    }); // loop content ends here..
  }; // end of addSwdPackage function

  const addSwdPackageConfiguration = async (
    config32bit: String,
    config64bit: String,
    packageName: String,
    pathLogFile: String,
    messageBoxArray: string[],
    maxPatchTime: any,
    processtokill: any,
    messagestatus: any,
  ) => {
    const config32bitFinal = config32bit;
    const config64bitFinal = config64bit;
    const formDataPkgVal: any = JSON.stringify(props.formDataPkg);
    const formDataAppVal: any = JSON.stringify(props.formDataApp);
    const formDataAdvVal: any = JSON.stringify(props.formDataAdv);
    // genrating final messagebox configuration
    let finalMessageBoxText = '';
    if (messageBoxArray.length != 0) {
      messageBoxArray.map((msg, msgindex) => {
        msgindex == 0 ? (finalMessageBoxText += `[${msgindex + 1}]-${msg ?? ''}`) : (finalMessageBoxText += `#[${msgindex + 1}]-${msg ?? ''}`);
      });
    }
    //generating final processToterminate time
    // generating edconfig here
    let edConfig =
      `1~[${packageName}]` +
      '\n' +
      '32Link:' +
      '\n' +
      config32bitFinal +
      '\n' +
      '64Link:' +
      '\n' +
      config64bitFinal +
      '\n' +
      'Positive:' +
      '\n' +
      'Negative:' +
      '\n' +
      'Special:' +
      '\n' +
      `LogFile:${pathLogFile}` +
      '\n' +
      'Default:' +
      '\n' +
      'DeleteLogFile:' +
      '\n' +
      `StatusMessageBox:${messagestatus}` +
      '\n' +
      `MessageBoxText:${finalMessageBoxText}` +
      '\n' +
      `MaxTimePerPatch:${maxPatchTime}` +
      '\n' +
      `ProcessToKill:${processtokill}`;

    // set the packageId
    // getPackageId();
    if (props.overlayName !== 'editInstallation') {
      const { data, loading, error } = await client.query({
        query: getPackageID,
        variables: { app_packageId },
        fetchPolicy: 'network-only',
      });
      if (!loading && data) {
        console.log('checking the packageId here', data.softinst_Packages[0].id);
        packageId = data?.softinst_Packages[0]?.id;
        console.log('packaging set', packageId);
      }
    }

    if (props.overlayName == 'editInstallation' && props.rowID) {
      const app_packageiD = props.rowID;
      try {
        const { data } = await client.mutate({
          mutation: editswdConf,
          variables: { app_packageiD, config32bitFinal, config64bitFinal, edConfig, formDataPkgVal, formDataAppVal, formDataAdvVal },
          fetchPolicy: 'network-only',
        });
        //adding the configuration for the above package and its applications to the
        if (data) {
          // console.log("number of affected rows",data);
          console.log('rows affected:', data.insert_softinst_Packages.returning);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', (error as Error).message);
      }
    } else {
      try {
        const { data } = await client.mutate({
          mutation: addSwdPackageConfigurationQuery,
          variables: { packageId, config32bitFinal, config64bitFinal, edConfig, formDataPkgVal, formDataAppVal, formDataAdvVal },
        });
        //adding the configuration for the above package and its applications to the
        if (data) {
          // console.log("number of affected rows",data);
          console.log('rows affected:', data.insert_softinst_Packages.returning);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', (error as Error).message);
      }
    }
  };

  const configuration_download = (
    platform: any,
    path: any,
    executeIn_download: any,
    downloadOperationMode_download: any,
    downloadLocation_bit: any,
    preCheckMode_download: String,
    preCheckValue_download: any,
    filepath_download: any,
    Registry_rootkey_download: any,
    Registry_path_download: any,
    Registry_type_download: any,
    Registry_value_download: any,
    softwareName_download: any,
    softwareVersion_download: any,
  ) => {
    //   START OF CONFIGURATION_32/64 BIT DOWNLOAD
    let confStrDownload = '1,'; //enabled mode
    // 1 appending platform
    if (platform == 'Windows') {
      confStrDownload += 'NT,'; //configuration appending for OS
    }
    // 2. configuration append URL/path
    let pathDownload = path + ',';
    confStrDownload += pathDownload;

    // 3 .configuration appending for session mode
    if (executeIn_download === 'background') {
      confStrDownload += '0,';
    } else if (executeIn_download === 'foreground') {
      confStrDownload += '1,';
    }
    // else{
    //   confStrDownload+='0,'
    // }

    //4. configuration for install choose installation mode
    // if(downloadOperationMode_download == "System privilege"){
    //confStrDownload+="0,"
    //}
    if (downloadOperationMode_download === 'System privilege') {
      confStrDownload += '1,';
    }
    if (downloadOperationMode_download === 'User privilege') {
      confStrDownload += '4,';
    }
    if (downloadOperationMode_download === 'User privilege with UAC') {
      confStrDownload += '6,';
    }
    // else{
    //   confStrDownload+="1,"
    // }

    //5. Append Command since its a download add 4 value to confstring meaning the package for distribution
    confStrDownload += '4,';

    //6.download location for download the package
    if (downloadLocation_bit) {
      const downloadLocation = downloadLocation_bit + ',';
      confStrDownload += downloadLocation;
    }
    //7.pre check mode 0-file, 1-software, 2-Registry
    if (preCheckMode_download === 'File') {
      if (preCheckValue_download == 0) {
        //check condition for proceed
        confStrDownload += '0,' + `${filepath_download}`;
      } else {
        confStrDownload += '!0,' + `${filepath_download}`;
      }
    }

    if (preCheckMode_download === 'Registry') {
      let Registry_rootkey_downloadValue: string = '';
      if (Registry_rootkey_download == 'HKEY_CLASSES_ROOT') {
        Registry_rootkey_downloadValue = '3';
      }
      if (Registry_rootkey_download == 'HKEY_CURRENT_USER') {
        Registry_rootkey_downloadValue = '4';
      }
      if (Registry_rootkey_download == 'HKEY_LOCAL_MACHINE') {
        Registry_rootkey_downloadValue = '1';
      }
      if (Registry_rootkey_download == 'HKEY_USERS') {
        Registry_rootkey_downloadValue = '5';
      }
      if (Registry_rootkey_download == 'HKEY_PERFORMANCE_DATA') {
        Registry_rootkey_downloadValue = '7';
      }
      if (Registry_rootkey_download == 'HKEY_PERFORMANCE_TEXT') {
        Registry_rootkey_downloadValue = '8';
      }
      if (Registry_rootkey_download == 'HKEY_PERFORMANCE_NLSTEXT') {
        Registry_rootkey_downloadValue = '9';
      }
      if (Registry_rootkey_download == 'HKEY_CURRENT_CONFIG') {
        Registry_rootkey_downloadValue = '2';
      }
      if (Registry_rootkey_download == 'HKEY_DYN_DATA') {
        Registry_rootkey_downloadValue = '6';
      }
      if (preCheckValue_download == 0) {
        // ,2,MainKey#SubKey#ValueName#ValueType#Value
        confStrDownload +=
          '2,' +
          `${Registry_rootkey_downloadValue}` +
          `#` +
          `${Registry_path_download}` +
          `#` +
          `${Registry_type_download}` +
          `#` +
          `${Registry_value_download}`;
      } else {
        confStrDownload +=
          '!2,' +
          `${Registry_rootkey_downloadValue}` +
          `#` +
          `${Registry_path_download}` +
          `#` +
          `${Registry_type_download}` +
          `#` +
          `${Registry_value_download}`;
      }
    }
    if (preCheckMode_download === 'Software Name') {
      confStrDownload += '1,' + `${softwareName_download}` + `#` + `${softwareVersion_download}`;
    }
    confStrDownload = confStrDownload.replace(/,\s*$/, ''); //remove commas at the end of configuriation

    return confStrDownload;
    //   END OF CONFIGURATION_BIT DOWNLOAD
  };

  // START OF CONFIGURATION FOR 32/64BIT INSTALL
  const configuration_install = (
    platform: any,
    path: any,
    executeIn_install: any,
    installationMode_install: any,
    downloadLocation_download: any,
    commandLineInstallationInstruction_install: any,
    preCheckMode_install: any,
    preCheckValue_install: any,
    filepath_install: any,
    Registry_rootkey_install: any,
    Registry_path_install: any,
    Registry_type_install: any,
    Registry_value_install: any,
    softwareName_install: any,
    softwareVersion_install: any,
  ) => {
    let confStrInstall = '1,'; //enabled mode
    // 1 appending platform
    if (platform == 'Windows') {
      confStrInstall += 'NT,'; //configuration appending for OS
    }

    // 2. configuration append URL/path
    let pathInstall_32 = '%WINDIR%\\System32\\cmd.exe' + ','; // this will be default value for installation
    confStrInstall += pathInstall_32;

    // 3 .configuration appending for session mode
    if (executeIn_install === 'background') {
      confStrInstall += '0,';
    } else if (executeIn_install === 'foreground') {
      confStrInstall += '1,';
    }
    // else{
    //   confStrInstall+='0,'
    // }
    //4. configuration for install choose installation mode
    //if(installationMode_install == "System privilege"){
    //confStrInstall+="0,"
    //}
    if (installationMode_install === 'System privilege') {
      confStrInstall += '1,';
    }
    if (installationMode_install === 'User privilege') {
      confStrInstall += '4,';
    }
    if (installationMode_install === 'User privilege with UAC') {
      confStrInstall += '6,';
    }

    //5. Append Command since its a install add 1 value to confany meaning the package for distribution
    confStrInstall += '1,';

    //6 command line installation instruction , append values from Url path end and downloadLocation_32download to create configuration for this
    const urlParts = path.split('/');
    // Get the last element of the array
    const lastElement = urlParts[urlParts.length - 1];

    const finalCommandLineInstallationInstruction =
      downloadLocation_download.length > 0
        ? `/k("${downloadLocation_download}${lastElement}" ${commandLineInstallationInstruction_install} &exit),`
        : `/k(&exit),`;
    confStrInstall += finalCommandLineInstallationInstruction;

    //7.pre check mode 0-file, 1-software, 2-Registry
    if (preCheckMode_install === 'File') {
      if (preCheckValue_install == '0') {
        //check condition for proceed
        confStrInstall += '0,' + `${filepath_install}`;
      } else {
        confStrInstall += '!0,' + `${filepath_install}`;
      }
    }
    if (preCheckMode_install === 'Registry') {
      let Registry_rootkey_installValue: string = '';
      if (Registry_rootkey_install == 'HKEY_CLASSES_ROOT') {
        Registry_rootkey_installValue = '3';
      }
      if (Registry_rootkey_install == 'HKEY_CURRENT_USER') {
        Registry_rootkey_installValue = '4';
      }
      if (Registry_rootkey_install == 'HKEY_LOCAL_MACHINE') {
        Registry_rootkey_installValue = '1';
      }
      if (Registry_rootkey_install == 'HKEY_USERS') {
        Registry_rootkey_installValue = '5';
      }
      if (Registry_rootkey_install == 'HKEY_PERFORMANCE_DATA') {
        Registry_rootkey_installValue = '7';
      }
      if (Registry_rootkey_install == 'HKEY_PERFORMANCE_TEXT') {
        Registry_rootkey_installValue = '8';
      }
      if (Registry_rootkey_install == 'HKEY_PERFORMANCE_NLSTEXT') {
        Registry_rootkey_installValue = '9';
      }
      if (Registry_rootkey_install == 'HKEY_CURRENT_CONFIG') {
        Registry_rootkey_installValue = '2';
      }
      if (Registry_rootkey_install == 'HKEY_DYN_DATA') {
        Registry_rootkey_installValue = '6';
      }
      if (preCheckValue_install == '0') {
        // ,2,MainKey#SubKey#ValueName#ValueType#Value
        confStrInstall +=
          '2,' +
          `${Registry_rootkey_installValue}` +
          `#` +
          `${Registry_path_install}` +
          `#` +
          `${Registry_type_install}` +
          `#` +
          `${Registry_value_install}`;
      } else {
        confStrInstall +=
          '!2,' +
          `${Registry_rootkey_installValue}` +
          `#` +
          `${Registry_path_install}` +
          `#` +
          `${Registry_type_install}` +
          `#` +
          `${Registry_value_install}`;
      }
    }
    if (preCheckMode_install === 'Software Name') {
      confStrInstall += '1,' + `${softwareName_install}` + `#` + `${softwareVersion_install}`;
    }
    confStrInstall = confStrInstall.replace(/,\s*$/, '');

    return confStrInstall;
  };
  //END OF CONFIGURATION FOR 32BIT INSTALL

  ///getid////////////////
  const getIds = async () => {
    let tempdel: any = [];
    try {
      for (const id of selectedRowsID) {
        const { data, loading, error } = await client.query({
          query: getId,
          variables: { selectedRowsID: id },
          fetchPolicy: 'network-only',
        });
        if (!loading && data) {
          console.log('checking the packageId here', data.softinst_Packages[0].id);
          tempdel.push(data?.softinst_Packages[0]?.id);
          console.log('packaging set', deletelist);
        }
      }
      setDeleteList(tempdel);
      console.log(isdeleteList);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  ////////deleteQuery/////

  const delConfNew = async () => {
    try {
      console.log('packaging set inside delete cofig', isdeleteList);
      const { data } = await client.mutate({
        mutation: deleteConfignew,
        variables: { isdeleteList },
        fetchPolicy: 'network-only',
      });

      if (data.delete_softinst_Packages?.affected_rows > 0) {
        // props.setDeleteDisabled(true);
        // props.setDeleteprofile(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const delSwdprofile = async (_eq: any) => {
    try {
      const { data } = await client.mutate({
        mutation: deleteSwdPackage,
        variables: { _eq },
        fetchPolicy: 'network-only',
      });
      if (data.delete_softinst_Packages?.affected_rows > 0) {
        //  props.setDeleteDisabled(true);
        //  props.setDeleteprofile(false);
        getSwdList({ packageName: { contains: props.searchVal } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ packageName: { contains: props.searchVal } });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  //getting the packageID from the packages table to enter in packageconfiguriation
  // const getPackageId:any = async () => {
  //   const { data, loading, error } = await client.query({
  //     query: getPackageID,
  //     variables: {app_packageId},
  //     fetchPolicy: 'network-only',
  //   });
  //   if(!loading && data){
  //       console.log("checking the packageId here",data.softinst_Packages[0].id)
  //       packageId=data?.softinst_Packages[0]?.id;
  //       console.log("packaging set",packageId);
  //   }
  // };

  const getStorageToken = async () => {
    await fetch(`${AppConfig.DASHBOARD_V2_API_URL}/get-token`)
      .then(response => response.json())
      .then((data: any) => {
        console.log('JWT TOKEN HERE', data);
        setStorageToken(data.tokenname);
        // Handle data
      });
  };
  const getDownloadableLink = (filepath: string) => {
    return `${process.env.REACT_APP_STORAGE_URL}/storage/${filepath}`;
  };

  // useEffect(() => {
  // console.log("packaging set", packageId); // This will run whenever packageId state changes
  // }, [packageId]);

  useEffect(() => {
    //get jwt token for authentication of storage service
    getStorageToken();
    if (props.deleteprofile && contextValue.selectedRowIdInTable.length > 0) {
      delSwdprofile(contextValue.selectedRowIdInTable);
      delConfNew();
      contextValue.setSelectedRowIdInTable([]);
      setTimeout(() => {
        // props.setDeleteDisabled(true);
        props.setDeleteprofile(false);
      }, 3000);
      getSwdList(null, 50, parseInt(props.pageNo));
      getSwdListCnt(null);
    } else {
      getSwdList(null, 50, parseInt(props.pageNo));
      getSwdListCnt(null);
      getJsonSchemaQueryPkg();
      getJsonSchemaQueryApp();
      getJsonSchemaQueryAdv();
    }
    if (props.searchVal.length > 0 && props.searchVal !== '') {
      getSwdList({ packageName: { contains: props.searchVal } }, 50, parseInt(props.pageNo));
      getSwdListCnt({ packageName: { contains: props.searchVal } });
    } else if (props.isFilter == true) {
      if (props.dropDownValue1 == 'Name') {
        getSwdList({ packageName: { contains: props.dropDownValue2 } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ packageName: { contains: props.dropDownValue2 } });
      } else if (props.dropDownValue1 == 'Version') {
        getSwdList({ version: { contains: props.dropDownValue2 } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ version: { contains: props.dropDownValue2 } });
      } else if (props.dropDownValue1 == 'Operating System') {
        getSwdList({ platform: { contains: props.dropDownValue2 } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ platform: { contains: props.dropDownValue2 } });
      } else if (props.dropDownValue1 == 'Created On') {
        getSwdList({ creationTime: { contains: props.dropDownValue2 } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ creationTime: { contains: props.dropDownValue2 } });
      } else if (props.dropDownValue1 == 'Status') {
        getSwdList({ status: { contains: props.dropDownValue2 } }, 50, parseInt(props.pageNo));
        getSwdListCnt({ status: { contains: props.dropDownValue2 } });
      }
    } else if (props.isRefresh) {
      props.setIsRefresh(false);
      getSwdList(null, 50, parseInt(props.pageNo));
      getSwdListCnt(null);
      props.setRefreshToast(true);
      setTimeout(() => {
        props.setRefreshToast(false);
      }, 3000);
    } else {
      getSwdList(null, 50, parseInt(props.pageNo));
      getSwdListCnt(null);
    }

    if (props.savePackageDetails == true && props.overlayName != 'editInstallation') {
      addSwdPackage();
      setTimeout(() => {
        getSwdList(null, 50, parseInt(props.pageNo));
        getSwdListCnt(null);
      }, 2000);
      props.setSavePackageDetails(false);
      props.setFormDataPkg(null);
      props.setFormDataApp(null);
      props.setJsonDataAdv(null);
      props.setRowId(null);
    }
    if (props.overlayName != 'editInstallation') {
      props.setFormDataPkg(null);
      props.setFormDataApp(null);
      props.setFormDataAdv(null);
      props.setRowId(null);
    }

    if (props.savePackageDetails == true && props.overlayName == 'editInstallation') {
      addSwdPackage();
      setTimeout(() => {
        getSwdList(null, 50, parseInt(props.pageNo));
        getSwdListCnt(null);
      }, 2000);
      props.setSavePackageDetails(false);
      props.setFormDataPkg({});
      props.setFormDataApp({});
      props.setFormDataAdv({});
    }
  }, [
    props.searchVal,
    props.pageNo,
    props.savePackageDetails,
    props.deleteprofile,
    props.overlayName,
    props.isRefresh,
    props.formValidation,
    props.isFilter,
  ]);
  useEffect(() => {
    if (props.rowID) {
      props.setFormDataPkg({});
      props.setFormDataAdv({});
      props.setFormDataApp({});
      getForm();
    }
  }, [props.rowID]);

  useEffect(() => {
    getIds();
  }, [selectedRowsID]);

  return <></>;
};
