import { useState, useEffect, useContext, useRef } from 'react';
import { Policy_configuration_func } from '../../../api/automation/policy_configuration_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
//import { DataTable } from "../../../components/data_table/dataTable.component";
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import handleNextBtnClick from '../../../components/side_menu/overlay_side_menu.component';
import DialogBox from '../../../components/dialog/dialog.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  createTableColumn,
  TableCellLayout,
  Card,
  Image,
  Input,
  RadioGroup,
  Radio,
  Checkbox,
  Label,
  Textarea,
  Dropdown,
  useId,
} from '@fluentui/react-components';
import {
  AddSquare20Regular,
  SaveCopy20Regular,
  Delete20Regular,
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  CalendarClock20Regular,
  Wand20Regular,
  SettingsCogMultiple20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  Save20Regular,
  Info20Filled,
  Dismiss16Regular,
  CheckmarkLock20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { Inputs } from '../../../components/textarea/input.component';
import { Intent } from '../../../components/messagebar/messagebar.component';
import { Form } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../../components/form-components';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, messageTheme, lightTheme, inputsizestyle } from '../../../styles/customStyles';
import convert from 'xml-js';
import { Console, log } from 'console';
import { RadioGroups } from '../../../components/radio_group/radioGroup.component';
import type { DropdownProps } from '@fluentui/react-components';
import { MultipleSelectToString } from '../../../components/form-components/MultipleSelectToString';
import { MultiselectWithTags } from '../../../components/dropdown/multiselect.component';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Policy_config = (props: Partial<DropdownProps>) => {
  const dropdownId = useId('dropdown-default');
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const formRef = useRef(null);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [dropDownValue3, setDropDownValue3] = useState<any>([]);
  const [dropDownValue4, setDropDownValue4] = useState<any>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('Introduction');
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [automationOverLayData, setAutomationOverLayData]: any = useState([]);
  const [jsonSchema, setJsonData]: any = useState({});
  const [uiSchema, setUiData]: any = useState({});
  const [required, setRequired]: any = useState(true);
  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [formEditedData, setFormEditedData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
    category: '',
    policy: '',
  });
  const [deleteAutomation, setDeleteAutomation]: any = useState(false);
  const [isRefresh, setRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [isToast, setIsToast] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [automationSatus, setAutomationSatus] = useState('');
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [categorySearchVal, setCategorySearchVal] = useState('');
  const [policySearchVal, setPolicySearchVal] = useState('');
  const [categorySearchData, setCategorySearchData] = useState([]);
  const [policySearchData, setPolicySearchData] = useState(['']);
  const [varvalues, setVarvalues]: any = useState('{asdf}');
  const [adml, setAdmlData] = useState(`<resources>
  <string id="L_AllowCamera">Allow Use of Camera</string>
</resources> `);
  const [admx, setAdmxData] =
    useState(`<policy name="L_AllowCamera" class="Machine" displayName="$(string.L_AllowCamera)" explainText="$(string.L_AllowCameraExplain)" key="software\\Policies\\Microsoft\\Camera" valueName="AllowCamera">
  <parentCategory ref="L_Camera_GroupPolicyCategory" />
  <supportedOn ref="windows:SUPPORTED_Windows_10_0" />
  <enabledValue>
      <decimal value="1" />
  </enabledValue>
  <disabledValue>
      <decimal value="0" />
  </disabledValue>
</policy>`);
  const [dartid, setdartid] = useState(null);

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== '' && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };
  // useEffect(() => {
  //   console.log("CategoryOptions=",dropDownValue1);
  //   console.log("Object.keys(categoryData)=");
  //   if(dropDownValue1?.length > 0){
  //     setCategorySearchVal(dropDownValue1)

  //   } else{
  //     console.log("cat else");
  //   }
  // },[dropDownValue1]);
  // const [error,setError]:any = useState(true);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [selectedOption, setSelectedOption]: any = useState('');
  const [polformData, setpolFormData] = useState({
    text: '',
    checked: 0,
  });
  const handleUserInput = (data: any, keyName: string) => {
    var checked = data;

    setFormData({ ...formData, jsonData: { ...formData.jsonData, [keyName]: data } });
  };

  useEffect(() => {
    setSelectedOption(formData.jsonData.polValue);
  }, [formData]);

  const radioData = {
    'Not Configured': '2',
    Enable: '1',
    Disable: '0',
  };

  var admxData = admx;
  var admlData = adml;

  const elementsToRender: any = [];
  var polRes = [];
  var regKeyValue: any = {};
  var options = { ignoreComment: true, alwaysChildren: true, compact: true };
  var admxJson: any = convert.xml2js(admxData, options);
  var admlJson: any = convert.xml2js(admlData, options);

  var admxObj = admxJson?.policy;
  var admx_arr: any;
  var classValue;
  var regPath;
  var admlValue;
  var admlString = admlJson?.resources?.string;

  var displayString = admxObj._attributes.displayName.match(/\.(.*?)\)/);
  var displayName = displayString ? displayString[1] : '';
  var classValue = admxObj._attributes.class;
  var regPath = admxObj._attributes.key;

  if (!admlString.length) {
    admlString = [admlString];
  }
  admlString.map((ID: any) => {
    const Values = admlString.find((adml: any) => adml._attributes.id === displayName);
    admlValue = Values ? Values._text : null;
  });

  if (admxObj._attributes.valueName) {
    regKeyValue[key] = admxObj._attributes.valueName;
  }
  if (admxObj.elements) {
    var value: any = {};
    var key: any = {};
    for ([key, value] of Object.entries(admxObj.elements)) {
      switch (key) {
        case 'text':
          var textarray = Array.isArray(admlJson.resources.textBox) ? admlJson.resources.textBox : [admlJson.resources.textBox];
          admx_arr = Array.isArray(value) ? value : [value];
          admx_arr.map((ID: any) => {
            var admx_id = ID._attributes.id;
            const Values = textarray.find((adml: any) => adml._attributes.refId === admx_id);
            var admx_valueName = ID._attributes.valueName;
            var componentToRender = (
              <Input type="text" name={key} id={admx_valueName} onChange={e => handleUserInput(e.target.value, admx_valueName)} />
            );
            var textvalue = Values ? Values.label._text : null;
            regKeyValue[key] = admx_valueName;
            elementsToRender.push(
              <Stack tokens={{ childrenGap: 10 }}>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }} horizontal id={admx_id}>
                    <StackItem>
                      <Body1> {textvalue} </Body1>
                    </StackItem>
                    <StackItem> {componentToRender} </StackItem>
                  </Stack>
                </StackItem>
              </Stack>,
            );
          });
          break;
        case 'boolean':
          var booleanarray = Array.isArray(admlJson.resources.checkBox) ? admlJson.resources.checkBox : [admlJson.resources.checkBox];
          admx_arr = Array.isArray(value) ? value : [value];
          admx_arr.map((ID: any) => {
            var admx_id = ID._attributes.id;
            const Values = booleanarray.find((adml: any) => adml._attributes.refId === admx_id);
            var textvalue = Values ? Values._text : null;
            var admx_valueName = ID._attributes.valueName;
            var componentToRender = (
              <Checkbox type="checkbox" name={key} id={admx_valueName} onChange={e => handleUserInput(e.target.checked, admx_valueName)} />
            );
            regKeyValue[key] = admx_valueName;
            elementsToRender.push(
              <Stack tokens={{ childrenGap: 10 }}>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }} horizontal id={admx_id}>
                    <StackItem>
                      <Body1> {textvalue} </Body1>
                    </StackItem>
                    <StackItem> {componentToRender} </StackItem>
                  </Stack>
                </StackItem>
              </Stack>,
            );
          });
          break;
        case 'decimal':
          var numberarray = Array.isArray(admlJson.resources.decimalTextBox)
            ? admlJson.resources.decimalTextBox
            : [admlJson.resources.decimalTextBox];
          admx_arr = Array.isArray(value) ? value : [value];
          admx_arr.map((ID: any) => {
            var admx_id = ID._attributes.id;
            const Values = numberarray.find((adml: any) => adml._attributes.refId === admx_id);
            var textvalue = Values ? Values._text : null;
            var admx_valueName = ID._attributes.valueName;
            var componentToRender = (
              <Input type="number" name={key} id={admx_valueName} onChange={e => handleUserInput(e.target.value, admx_valueName)} />
            );
            regKeyValue[key] = admx_valueName;
            elementsToRender.push(
              <Stack tokens={{ childrenGap: 10 }}>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }} horizontal id={admx_id}>
                    <StackItem>
                      <Body1> {textvalue} </Body1>
                    </StackItem>
                    <StackItem> {componentToRender} </StackItem>
                  </Stack>
                </StackItem>
              </Stack>,
            );
          });
          break;
        case 'multiText':
          var multiTextarray = Array.isArray(admlJson.resources.multiTextBox) ? admlJson.resources.multiTextBox : [admlJson.resources.multiTextBox];
          admx_arr = Array.isArray(value) ? value : [value];
          admx_arr.map((ID: any) => {
            var admx_id = ID._attributes.id;
            const Values = multiTextarray.find((adml: any) => adml._attributes.refId === admx_id);
            var textvalue = Values ? Values._text : null;
            var admx_valueName = ID._attributes.valueName;
            var componentToRender = (
              <Textarea
                resize="both"
                value={formData.jsonData.admx_valueName}
                size="small"
                onChange={(e: any) => handleUserInput(e.target.value, admx_valueName)}
              />
            );
            regKeyValue[key] = admx_valueName;
            elementsToRender.push(
              <Stack tokens={{ childrenGap: 10 }}>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }} horizontal id={admx_id}>
                    <StackItem>
                      <Body1> {textvalue} </Body1>
                    </StackItem>
                    <StackItem> {componentToRender} </StackItem>
                  </Stack>
                </StackItem>
              </Stack>,
            );
          });
          break;
        case 'enum':
          var dropdown: any = [];
          var admx_valueName;
          var admx_id: any;
          var textvalue;
          var enumArray = Array.isArray(admlJson.resources.dropdownList) ? admlJson.resources.dropdownList : [admlJson.resources.dropdownList];
          admx_arr = Array.isArray(value) ? value : [value];
          var items = value.item;
          items.map((name: any) => {
            const displayName = name._attributes.displayName.replace('$(string.', '').replace(')', '');
            const dropdownValues = admlString.find((values: any) => values._attributes.id === displayName);
            var textvalue = dropdownValues ? dropdownValues._text : null;
            dropdown.push(textvalue);
          });
          admx_arr.map((ID: any) => {
            admx_id = ID._attributes.id;
            const Values = enumArray.find((adml: any) => adml._attributes.refId === admx_id);
            textvalue = Values ? Values._text : null;
            admx_valueName = ID._attributes.valueName;
          });
          regKeyValue[key] = admx_valueName;
          var componentToRender = (
            <Dropdown aria-labelledby={dropdownId} placeholder="Select an option" {...props}>
              {dropdown.map((option: any) => (
                <p>{option}</p>
                // <Option key={option} >
                //   {option}
                //   </Option>
              ))}
            </Dropdown>
          );
          elementsToRender.push(
            <Stack tokens={{ childrenGap: 10 }} horizontal id={admx_id}>
              <StackItem>
                <Body1> {textvalue} </Body1>
              </StackItem>
              <StackItem> {componentToRender} </StackItem>
            </Stack>,
          );
          break;
        default:
          console.log('Undefined component');
          break;
      }
    }
  }
  var polObj = {
    name: displayName,
    pol_name: admlValue,
    class: classValue,
    regpath: regPath,
    regkey: regKeyValue,
  };
  polRes.push(polObj);
  console.log('polres=', polRes);

  // console.log("CHECKING THE ERROR HOOK",error)
  const handlenextbutton = () => {
    setActiveTab('Configure');
  };
  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  // const somefunction =()=>{
  //   const form = document.getElementById("myForm");
  //   form?.addEventListener("submit", function(event) {
  //   const requiredFields = form?.querySelectorAll("[required]");
  //   let isEmpty = false;
  //   for (let i = 0; i < requiredFields.length; i++) {
  //     const field = requiredFields[i];
  //     alert("here")
  //     if ((field as HTMLInputElement)?.value?.trim() === "") {
  //       isEmpty = true;
  //       alert("fill the form")
  //       event.preventDefault();
  //       // break;
  //     }
  //     return true;
  //   }
  //   if (!isEmpty) {
  //    alert("form filled");
  //   }
  //   return false;
  // });
  // }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // handleNextBtnClick(e);
  };

  // const validate=()=>{
  //   console.log(formData.name);
  //   setError(false);
  //   if(formData.name === '')
  //   { console.log("CODE CHECK WHEN DATA EMPTY")
  //     setError(true);
  //   }
  //   if(formData?.name)
  //   { console.log("CODE CHECK WHEN DATA NOT EMPTY")
  //     setError(false)
  //   }

  // }
  useEffect(() => {
    setFormData({ ...formData, jsonData: { ...formData.jsonData, Category: dropDownValue3 } });
  }, [dropDownValue3]);

  useEffect(() => {
    setFormData({ ...formData, jsonData: { ...formData.jsonData, Policy: dropDownValue4 } });
  }, [dropDownValue4]);

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name || (overlayName == 'editAutomation' && val.automationName === formEditedData.name)) {
        flag = false;
      }
    });

    if (overlayName == 'editAutomation' && formEditedData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, formEditedData, selectedAutomation]);

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations/configuration')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/selfhelp');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1>Self-Help </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '69');
        contextValue.setDartNo(69);
        navigate('/automate/automation/autoheal');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Wand20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Autoheal </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/automationschedule');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CalendarClock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automation Schedules </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        contextValue.setDartNo(241);
        navigate('/automate/automation/usersurvey');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ChatMultipleHeart20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> User Surveys </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => {
          contextValue.setDartNo(241);
          navigate('/automate/automation/compliance_policies/policy_configuration');
        }}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <CheckmarkLock20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Compliance Policies </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <AddSquare20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1
                  onClick={() => {
                    setOverlayOpen(true);
                    setOverlayName('addAutomation');
                    setFormData({ ...formData, name: '', description: '' });
                  }}
                >
                  Add Policy
                </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
        <StackItem>
        <Divider vertical/>
        </StackItem> */}
        <StackItem>
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {/* <Delete20Regular {...primaryIcon} />  */}
                      {isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      <Caption1> Delete </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Kindly verify your intent to delete the policy."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setDeleteAutomation(true);
                        userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} policy deleted"}`);
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>
        <StackItem>
          <Divider vertical />
        </StackItem>
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setRefresh(true);
              setCurrentPageNo(1);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="configurationlist.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"Automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  const handleNewFormChange = (e: any) => {
    setFormData({ ...formData, jsonData: e.formData });
  };

  const handleEditFormChange = (e: any) => {
    setFormEditedData({ ...formEditedData, jsonData: e.formData });
  };

  const searchHandle = (data: any) => {
    var data = data.toLowerCase();
    var searchData = exportDetails.filter((val: any) => val.host.toLowerCase().includes(data));

    var searchedDataObj: any = { core_Census: [] };
    searchedDataObj.core_Census = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil(searchData.length / 50));
    setCurrentPageNo(1);
  };

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modified: new Date(val.lastModified * 1000).toLocaleString(),
          status: status,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);
    var status = '';
    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any, i: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'show',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={(e: any) => {
                    setRowID(val.id);
                    setdartid(val.dartid);
                    setOverlayOpen(true);
                    setOverlayName('editAutomation');
                    status === 'not attached' ? setAutomationSatus(status) : setAutomationSatus('attached');
                    setSelectedAutomation(val.automationName);
                    setFormEditedData({
                      ...formEditedData,
                      name: val.automationName,
                      description: val.description,
                      category: JSON.parse(val.jsonValue).Category,
                      policy: JSON.parse(val.jsonValue).Policy,
                    });
                    console.log('form edited data policy', formEditedData);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    console.log('jsonSchema?.properties=', jsonSchema);
    if (Object.keys(jsonSchema).length > 0) {
      var varValues = Object.keys(jsonSchema?.properties);

      var tempObj: any = {};
      varValues.map((ele: any) => {
        tempObj[`${ele}`] = jsonSchema.properties[`${ele}`]?.default;
      });
      console.log('tempObj=', tempObj);

      setFormData({ ...formData, jsonData: tempObj });
    }
  }, [jsonSchema]);

  useEffect(() => {
    if (dartSpecs?.profile_DartTemplateDetails?.length > 0) {
      var details = dartSpecs.profile_DartTemplateDetails.length > 0 ? dartSpecs.profile_DartTemplateDetails[0].details : '{}';
      details = JSON.parse(details);

      overlayName == 'addAutomation' &&
        setAutomationOverLayData({
          headerTitle: 'Add a New Policy',
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myForm" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <p>
                      <b>
                        Name <Image src="/visualization/v2/image/required.svg" />
                      </b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} required={required} />
                    </p>
                    {!classificationNameValidation && <Body1>This Policy name already exists</Body1>}
                  </StackItem>
                  {/* {error && <StackItem>*This is required field</StackItem>} */}

                  <StackItem>
                    <p>
                      <b>What does this Policy Do?</b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormData} inputValue={formData} />
                    </p>
                  </StackItem>

                  {/* <StackItem>
              <Intent title="" message="These are the basic details associated with this automation."></Intent>
            </StackItem> */}

                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These are the basic details associated with this Policy.</Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),
            Configure: (
              <form id="myForm" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  {/* <Form ref={formRef} formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} onChange={(e) => handleNewFormChange(e)} /> */}
                  {/* <Form ref={formRef} schema={jsonSchema} validator={validator}/> */}
                  {/* <RadioGroups data={radioData} layout="horizontal" value={selectedOption} /> */}
                  <Stack tokens={{ childrenGap: 4 }}>
                    <Body1>Category</Body1>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <MultiselectWithTags
                        style={inputsizestyle}
                        onChange={(e: any) => setCategorySearchVal(e.target.value)}
                        options={categorySearchData}
                        setSelectedOptions={setDropDownValue3}
                        selectedOptions={dropDownValue3}
                        multi={false}
                        tag={false}
                        placeholder="Please Search"
                        required
                      />
                    </StackItem>
                  </Stack>
                  <Stack tokens={{ childrenGap: 4 }}>
                    <Body1>Policy</Body1>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <MultiselectWithTags
                        style={inputsizestyle}
                        onChange={(e: any) => setPolicySearchVal(e.target.value)}
                        options={policySearchData}
                        setSelectedOptions={setDropDownValue4}
                        selectedOptions={dropDownValue4}
                        multi={false}
                        tag={false}
                        placeholder="Please Search"
                        required
                      />
                    </StackItem>
                  </Stack>
                  {dropDownValue4 != '' && (
                    <>
                      <Body1> {polObj.pol_name} </Body1>
                      {/* <form onSubmit={formSubmit}> */}
                      <RadioGroups data={radioData} layout="horizontal" onChange={(e: any) => handleUserInput(e.target.value, 'polValue')} /> ,
                    </>
                  )}

                  {/* <Stack horizontal tokens={{childrenGap:10}}>
                <Radio label="Not Configured" value="notConfigured" />
                <Radio label="Enable" value="enable" />
                <Radio label="Disable" value="disable" />
              </Stack> */}

                  <StackItem> {selectedOption === '2' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                  <StackItem>
                    {selectedOption === '1' && (
                      <Stack tokens={{ padding: 10 }}>
                        <Body1> {elementsToRender} </Body1>
                      </Stack>
                    )}
                  </StackItem>
                  <StackItem> {selectedOption === '0' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                  {/* </form> */}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),
            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Policy Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    {/* <Form ref={formRef} formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} onChange={(e) => handleNewFormChange(e)} /> */}
                    {/* <Form ref={formRef} schema={jsonSchema} validator={validator}/> */}
                    {/* <RadioGroups data={radioData} layout="horizontal" value={selectedOption} /> */}
                    <Stack tokens={{ childrenGap: 4 }}>
                      <Body1>Category</Body1>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <MultiselectWithTags
                          style={inputsizestyle}
                          onChange={(e: any) => setCategorySearchVal(e.target.value)}
                          options={categorySearchData}
                          setSelectedOptions={setDropDownValue3}
                          selectedOptions={dropDownValue3}
                          multi={false}
                          tag={false}
                          placeholder="Please Search"
                          required
                        />
                      </StackItem>
                    </Stack>
                    <Stack tokens={{ childrenGap: 4 }}>
                      <Body1>Policy</Body1>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        {/* <MultiselectWithTags style={inputsizestyle} onChange={(e: any) => setPolicySearchVal(e.target.value)} options={policySearchData} setSelectedOptions={setDropDownValue4} selectedOptions={dropDownValue4} multi={false} tag={false} placeholder="Please Search" required/>  */}
                        <p>
                          <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                        </p>
                      </StackItem>
                    </Stack>
                    {dropDownValue4 != '' && (
                      <>
                        <Body1> {polObj.pol_name} </Body1>
                        {/* <form onSubmit={formSubmit}> */}
                        <RadioGroups data={radioData} layout="horizontal" onChange={(e: any) => handleUserInput(e.target.value, 'polValue')} /> ,
                      </>
                    )}

                    {/* <Stack horizontal tokens={{childrenGap:10}}>
                <Radio label="Not Configured" value="notConfigured" />
                <Radio label="Enable" value="enable" />
                <Radio label="Disable" value="disable" />
              </Stack> */}

                    <StackItem> {selectedOption === '2' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                    <StackItem>
                      {selectedOption === '1' && (
                        <Stack tokens={{ padding: 10 }}>
                          <Body1> {elementsToRender} </Body1>
                        </Stack>
                      )}
                    </StackItem>
                    <StackItem> {selectedOption === '0' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                    {/* </form> */}
                  </Stack>
                </StackItem>
              </Stack>
            ),
          },
        });

      Object.keys(formData).length > 0 &&
        overlayName == 'editAutomation' /*&& automationSatus != 'not attached'*/ &&
        setAutomationOverLayData({
          headerTitle: `Edit ${selectedAutomation} policy`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myForm" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <p>
                      <b>
                        Name <Image src="/visualization/v2/image/required.svg" />
                      </b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormEditedData} inputVal={formEditedData} required={true} />
                    </p>
                    {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                  </StackItem>

                  <StackItem>
                    <p>
                      <b>What does this Policy Do?</b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormEditedData} inputValue={formEditedData} />
                    </p>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configure: (
              <form id="myForm" onSubmit={e => handleSubmit(e)}>
                {/* <Form ref={formRef} formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} onChange={(e) => handleNewFormChange(e)} /> */}
                {/* <Form ref={formRef} schema={jsonSchema} validator={validator}/> */}
                {/* <RadioGroups data={radioData} layout="horizontal" value={selectedOption} /> */}
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  {/* <Form ref={formRef} formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} onChange={(e) => handleNewFormChange(e)} /> */}
                  {/* <Form ref={formRef} schema={jsonSchema} validator={validator}/> */}
                  {/* <RadioGroups data={radioData} layout="horizontal" value={selectedOption} /> */}
                  <Stack tokens={{ childrenGap: 4 }}>
                    <Body1>Category</Body1>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      {/* <MultiselectWithTags style={inputsizestyle} onChange={(e: any) => setCategorySearchVal(e.target.value)} options={categorySearchData} setSelectedOptions={setDropDownValue3} selectedOptions={formEditedData.category} multi={false} tag={true} placeholder="Please Search" disabled={true} />  */}
                      {/* <p>{formEditedData.category}</p> */}
                      <p>
                        <Input value={formEditedData.category} disabled={true} />
                      </p>
                    </StackItem>
                  </Stack>
                  <Stack tokens={{ childrenGap: 4 }}>
                    <Body1>Policy</Body1>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    {/* <p>{formEditedData.policy}</p> */}
                    <StackItem>
                      {/* <MultiselectWithTags style={inputsizestyle} onChange={(e: any) => setPolicySearchVal(e.target.value)} options={policySearchData} setSelectedOptions={setDropDownValue4} selectedOptions={formEditedData.policy} multi={false} tag={true} placeholder="Please Search" disabled={true}/>  */}
                      <p>
                        <Input value={formEditedData.policy} disabled={true} />
                      </p>
                    </StackItem>
                  </Stack>
                  {formEditedData.policy !== '' && formEditedData.policy !== undefined && (
                    <>
                      <Body1> {polObj.pol_name} </Body1>
                      {/* <form onSubmit={formSubmit}> */}
                      <RadioGroups data={radioData} layout="horizontal" onChange={(e: any) => handleUserInput(e.target.value, 'polValue')} /> ,
                    </>
                  )}

                  {/* <Stack horizontal tokens={{childrenGap:10}}>
                <Radio label="Not Configured" value="notConfigured" />
                <Radio label="Enable" value="enable" />
                <Radio label="Disable" value="disable" />
              </Stack> */}

                  <StackItem> {selectedOption === '2' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                  <StackItem>
                    {selectedOption === '1' && (
                      <Stack tokens={{ padding: 10 }}>
                        <Body1> {elementsToRender} </Body1>
                      </Stack>
                    )}
                  </StackItem>
                  <StackItem> {selectedOption === '0' && <Stack tokens={{ padding: 10 }}> </Stack>} </StackItem>
                  {/* </form> */}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Policy Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Form
                    formData={formEditedData.jsonData}
                    schema={jsonSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    widgets={CustomWidgets}
                    disabled
                  />
                </StackItem>
              </Stack>
            ),
          },
        });

      // Object.keys(formData).length > 0 && overlayName == 'editAutomation' && automationSatus == 'not attached' && setAutomationOverLayData({
      //   headerTitle: "Temp File Clean Up",
      //   size: "large",
      //   isTabs: true,
      //   tabItems: {
      //     'Configuration': <Stack tokens={{childrenGap:15, padding:10}}>
      //     <StackItem>
      //       <p> <b>Name</b></p>
      //     </StackItem>
      //     <StackItem>
      //       <p> <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} /></p>
      //     </StackItem>

      //     <StackItem>
      //     <p> <b>What does this Automation Do?</b></p>
      //     </StackItem>
      //     <StackItem>
      //     <p> <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} /></p>
      //     </StackItem>

      //     <StackItem>
      //       <Form formData={formEditedData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} disabled />
      //     </StackItem>
      //     </Stack>
      //   },
      // });
    }
  }, [dartSpecs, overlayName, formData, formEditedData, classificationNameValidation, selectedOption, policySearchData, categorySearchData]);

  useEffect(() => {
    !overlayOpen && setOverlayName('');
  }, [overlayOpen]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Automation',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };
  var varValue: any = {};
  useEffect(() => {
    if (saveBtnClicked) {
      var jsonArr: { [key: string]: any }[] = Array.isArray(formData.jsonData) ? formData.jsonData : [formData.jsonData];
      var repeatCommand = true;
      var commadLine: any;
      var midval: any = '';

      var count: number = 1;
      var pol: any;
      for ([key, pol] of Object.entries(polObj.regkey)) {
        var typeData: any;
        switch (key) {
          case 'text':
            typeData = 'STRING';
            break;
          case 'boolean':
            typeData = 'DWORD';
            break;
          case 'decimal':
            typeData = 'STRING';
            break;
          case 'multiText':
            typeData = 'MultiString';
            break;
          case 'enum':
            typeData = 'DWORD';
            break;
          default:
            typeData = 'STRING';
        }
        if (formData.jsonData.polValue === '1') {
          if (admxObj._attributes.valueName) {
            if (repeatCommand) {
              if (polObj.class === 'Both') {
                var classVal = 'User';
                for (var i = 0; i < 2; i++) {
                  var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${classVal}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${admxObj._attributes.valueName}' -Data '${formData.jsonData.polValue}' -Type '${typeData}'\"`;
                  varValue = { ...varValue, [count]: commandLine };
                  count++;
                  classVal = 'Machine';
                }
              } else {
                var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${polObj.class}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${admxObj._attributes.valueName}' -Data '${formData.jsonData.polValue}' -Type '${typeData}'\"`;
                varValue = { ...varValue, [count]: commandLine };
                count++;
              }
              repeatCommand = false;
            }
          }
          if (admxObj.elements) {
            jsonArr.map((key: any) => {
              if (key.hasOwnProperty(pol)) {
                // console.log(`Value of objects:`, key[pol]);
                if (typeof key[pol] == 'boolean') {
                  key[pol] = key[pol] ? 1 : 0;
                }
                if (polObj.class === 'Both') {
                  var classVal = 'User';
                  for (var i = 0; i < 2; i++) {
                    var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${classVal}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}' -Data '${key[pol]}' -Type '${typeData}'\"`;
                    varValue = { ...varValue, [count]: commandLine };
                    count++;
                    classVal = 'Machine';
                  }
                } else {
                  var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${polObj.class}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}' -Data '${key[pol]}' -Type '${typeData}'\"`;
                  varValue = { ...varValue, [count]: commandLine };
                  count++;
                }
              }
            });
          }
        } else if (formData.jsonData.polValue === '0') {
          if (polObj.class === 'Both') {
            var classVal = 'User';
            for (var i = 0; i < 2; i++) {
              var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${classVal}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}' -Data '${formData.jsonData.polValue}' -Type '${typeData}'\"`;
              varValue = { ...varValue, [count]: commandLine };
              count++;
              classVal = 'Machine';
            }
          } else {
            var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Set-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${polObj.class}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}' -Data '${formData.jsonData.polValue}' -Type '${typeData}'\"`;
            varValue = { ...varValue, [count]: commandLine };
            count++;
          }
        } else if (formData.jsonData.polValue === '2') {
          if (polObj.class === 'Both') {
            var classVal = 'User';
            for (var i = 0; i < 2; i++) {
              var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Remove-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${classVal}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}'\"`;
              varValue = { ...varValue, [count]: commandLine };
              count++;
              classVal = 'Machine';
            }
          } else {
            var commandLine = `powershell -ExecutionPolicy Bypass -Command \"Remove-PolicyFileEntry -Path 'C:\\Windows\\System32\\GroupPolicy\\${polObj.class}\\registry.pol' -Key '${polObj.regpath}' -ValueName '${pol}'\"`;
            varValue = { ...varValue, [count]: commandLine };
            count++;
          }
        }
      }

      console.log('formData.jsonData', formData.name.split(' ').join(''));
      var polName = formData.name.split(' ').join('');

      // console.log("varValue value=",varValue);

      Object.values(varValue).map(value => {
        commadLine = `\n1,NT,C:\\windows\\system32\\cmd.exe,0,1,1,/k(` + value + ` &exit)`;
        midval += commadLine;
      });

      var policyPatch =
        `1~[${polName}]` +
        '\n' +
        '32Link:' +
        midval +
        '\n' +
        '1,NT,C:\\windows\\system32\\cmd.exe,0,1,1,/k(gpupdate /force &exit)' +
        '\n' +
        '64Link:' +
        midval +
        '\n' +
        '1,NT,C:\\windows\\system32\\cmd.exe,0,1,1,/k(gpupdate /force &exit)' +
        '\n' +
        'Positive:' +
        '\n' +
        'Negative:' +
        '\n' +
        'Special:' +
        '\n' +
        `LogFile:` +
        '\n' +
        'Default:' +
        '\n' +
        'DeleteLogFile:' +
        '\n' +
        `StatusMessageBox:` +
        '\n' +
        `MessageBoxText:` +
        '\n' +
        `MaxTimePerPatch:5` +
        '\n' +
        `ProcessToKill:`;
      // console.log("policyPatch=",policyPatch);

      var dartDataObj = {
        S00288PatchesAvailableNew5: [policyPatch],
        S00288IndividualPatches: `1,${polName}`,
        S00288RunNowButtonnew: '2',
      };
      console.log('dartDataObj=', dartDataObj);
      var dartData = JSON.stringify(dartDataObj);

      setVarvalues(dartData);

      overlayName == 'addAutomation' &&
        userActivity(
          'Add',
          `{"automationName":"${formData.name}","dartNo":"${contextValue.dartNo}","configuration":${JSON.stringify(
            formData.jsonData,
          )},"type":"Automation","summary":"${formData.name} automation is created"}`,
        );

      overlayName == 'editAutomation' &&
        userActivity(
          'Edit',
          `{"automationName":"${formData.name}","dartNo":"${contextValue.dartNo}","configuration":${JSON.stringify(
            formEditedData.jsonData,
          )},"type":"Automation","summary":"${formEditedData.name} automation is edited"}`,
        );
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  useEffect(() => {
    errorToast && userActivity('Edit', '{"summary":"Tried to edit the automation that is already configured"}', 'failure');
  }, [errorToast]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs
                    pageName="Automation"
                    subPageName="Compliance Policies"
                    subPageLink="/automate/automation/compliance_policies/policy_configuration"
                  />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Configuration" /> </Text>  */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Automation | Pocily Configuration </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      <Divider />
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            dismiss={null}
                            onChange={(e: any) => {
                              searchHandle(e.target.value);
                            }}
                            placeholder="Search"
                            size="small"
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                          </StackItem>
                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10, padding: 5 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem>
                                  <Info20Filled {...primaryIcon} />
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Explore the Nanoheal Database for an in-depth understanding of this Automation Template. Learn more about its
                                    capabilities, features, and recommended practices to enhance your utilization of this automation solution within
                                    your ecosystem.
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem>
                      </Stack>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      {tableData.length > 0 ? (
                        <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                          <StackItem>
                            {totalRecords > 0 && (
                              <Caption1>
                                Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                                {totalRecords} Records
                              </Caption1>
                            )}
                          </StackItem>
                          <StackItem>
                            <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                          </StackItem>
                        </Stack>
                      ) : (
                        <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Caption1> No Results.</Caption1>
                          </StackItem>
                        </Stack>
                      )}
                    </StackItem>

                    {!saveBtnClicked && overlayName == '' && (
                      <Policy_configuration_func
                        data={final_data}
                        func={set_final_data}
                        setDartSpecs={setDartSpecs}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setJsonData={setJsonData}
                        setUiData={setUiData}
                        deleteAutomation={deleteAutomation}
                        setDeleteAutomation={setDeleteAutomation}
                        setDeleteDisabled={setDeleteDisabled}
                        isRefresh={isRefresh}
                        setRefresh={setRefresh}
                        overlayName={overlayName}
                        setIsToast={setIsToast}
                        setRefreshToast={setRefreshToast}
                        categorySearchData={categorySearchData}
                        setCategorySearchData={setCategorySearchData}
                        categorySearchVal={categorySearchVal}
                        dropDownValue3={dropDownValue3}
                        policySearchVal={policySearchVal}
                        setPolicySearchVal={setPolicySearchVal}
                        setPolicySearchData={setPolicySearchData}
                        policySearchData={policySearchData}
                        dropDownValue4={dropDownValue4}
                        setDropDownValue4={setDropDownValue4}
                        setAdmxData={setAdmxData}
                        setAdmlData={setAdmlData}
                        varvalues={varvalues}
                      />
                    )}

                    {saveBtnClicked && (
                      <Policy_configuration_func
                        newData={overlayName == 'addAutomation' ? formData : formEditedData}
                        saveBtnClicked={saveBtnClicked}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setSaveBtnClicked={setSaveBtnClicked}
                        overlayName={overlayName}
                        rowID={rowID}
                        setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                        func={set_final_data}
                        setIsToast={setIsToast}
                        setOverlayOpen={setOverlayOpen}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        categorySearchData={categorySearchData}
                        setCategorySearchData={setCategorySearchData}
                        categorySearchVal={categorySearchVal}
                        dropDownValue3={dropDownValue3}
                        policySearchVal={policySearchVal}
                        setPolicySearchVal={setPolicySearchVal}
                        setPolicySearchData={setPolicySearchData}
                        policySearchData={policySearchData}
                        dropDownValue4={dropDownValue4}
                        setDropDownValue4={setDropDownValue4}
                        setAdmxData={setAdmxData}
                        setAdmlData={setAdmlData}
                        varvalues={varvalues}
                        setDropDownValue3={setDropDownValue3}
                        dartid={dartid}
                      />
                    )}

                    {overlayName != '' && !saveBtnClicked && (
                      <Policy_configuration_func
                        rowID={rowID}
                        overlayName={overlayName}
                        setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                        formData={overlayName == 'addAutomation' ? formData : formEditedData}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setJsonData={setJsonData}
                        setUiData={setUiData}
                        setDartSpecs={setDartSpecs}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        categorySearchData={categorySearchData}
                        setCategorySearchData={setCategorySearchData}
                        categorySearchVal={categorySearchVal}
                        dropDownValue3={dropDownValue3}
                        policySearchVal={policySearchVal}
                        setPolicySearchVal={setPolicySearchVal}
                        setPolicySearchData={setPolicySearchData}
                        policySearchData={policySearchData}
                        dropDownValue4={dropDownValue4}
                        setDropDownValue4={setDropDownValue4}
                        setAdmxData={setAdmxData}
                        setAdmlData={setAdmlData}
                        varvalues={varvalues}
                        setdartid={setdartid}
                        dartid={dartid}
                      />
                    )}

                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} setOverlayOpen={setOverlayOpen}></DataTable>}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={automationOverLayData}
            saveBtnFunc={setSaveBtnClicked}
            isNavBtn={true}
            formRef={formRef}
            handleOutsideButton={handleOutsideButton}
            handleSubmit={handleSubmit}
            classificationNameValidation={classificationNameValidation}
          />
          {isToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={isToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={
                !saveBtnClicked && overlayName == ''
                  ? 'Policy Deleted Successfully'
                  : overlayName == 'addAutomation'
                  ? 'New Policy Created Successfully'
                  : 'Policy Edited Successfully'
              }
              footer=""
            />
          )}

          {errorToast && (
            <ToastComponent setmessagebar={setErrorToast} toastIntent="error" position="top-end" title="Failed" message={toastMessage} footer="" />
          )}
          {refreshToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={refreshToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={'Data Table Refreshed'}
              footer=""
            />
          )}
        </StackItem>
      </FullScreen>
    </>
  );
};
