import { useState, useEffect } from "react";
import { Census_func } from "../../../api/devices/census_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular,Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { fullscreenIcon, lightTheme, primaryIcon, exitIcon, overflowDivider } from "../../../styles/customStyles";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const Monitor = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data] = useState({
    core_Census: [
        { automationName: "Memory usage greater than 90%", createdBy: "Admin", createdOn: "3/20/2024, 12:07:17 PM", lastModified: "3/20/2024, 5:20:06 PM", status: "Not attached" },
  { automationName: "Application Hang", createdBy: "User", createdOn: "3/21/2024, 10:15:00 AM", lastModified: "3/21/2024, 11:30:00 AM", status: "Attached" },
  { automationName: "Database Connection Error", createdBy: "Admin", createdOn: "3/20/2024, 2:45:00 PM", lastModified: "3/21/2024, 9:00:00 AM", status: "Not attached" },
  { automationName: "Disk Space Full", createdBy: "User", createdOn: "3/19/2024, 8:30:00 AM", lastModified: "3/20/2024, 3:45:00 PM", status: "Attached" },
  { automationName: "Google Chrome Crash", createdBy: "Admin", createdOn: "3/18/2024, 5:00:00 PM", lastModified: "3/19/2024, 10:20:00 AM", status: "Not attached" },
  { automationName: "High CPU Usage", createdBy: "User", createdOn: "3/17/2024, 1:20:00 PM", lastModified: "3/18/2024, 4:00:00 PM", status: "Attached" },
  { automationName: "Microsoft Edge Crash", createdBy: "Admin", createdOn: "3/16/2024, 11:10:00 AM", lastModified: "3/17/2024, 2:30:00 PM", status: "Not attached" },
  { automationName: "Network Outage", createdBy: "User", createdOn: "3/15/2024, 3:40:00 PM", lastModified: "3/16/2024, 8:00:00 AM", status: "Attached" },
  { automationName: "Teams Crash", createdBy: "Admin", createdOn: "3/14/2024, 9:25:00 AM", lastModified: "3/15/2024, 11:45:00 AM", status: "Not attached" },
  { automationName: "Windows Update Disabled", createdBy: "User", createdOn: "3/13/2024, 4:55:00 PM", lastModified: "3/14/2024, 12:10:00 PM", status: "Attached" }
      // Add as many data points as you need
    ]
  });
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleFilterValueSelect = (event: { target: { value: Event; }; }):void => {
    alert(event.target.value);
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("clicked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
  useEffect(() => {
    if(filterArray.length > 0)
    setResetDisabled(false)
   
   }, [filterArray])
   const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  // const [isZoomed, setZoom] = useState(false);
  // const handleZoomToggle = () => {
  //   setZoom(prevZoom => {
  //     const newZoom = !prevZoom;
  //     document.body.style.transform = newZoom ? 'scale(1.5)' : 'scale(1)';
  //     return newZoom;
  //   });
  // };
 
  var menuItems: any = [];
 
  menuItems.push
  (
      <MenuItem onClick={() => navigate("/manage/devices/overview")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Info20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Overview </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <GroupList20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Device Classification </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/manage/devices/monitor")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <DesktopPulse20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Monitors </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/remediation")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Wrench20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Remediations </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/compliancepolicies")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ShieldQuestion20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/conditionalaccess")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <LockClosedKey20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Conditional Access </Caption1> </StackItem>
      </Stack>
    </MenuItem>  
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/usersurvey")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ChatMultipleHeart20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> User Survey </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/datacollection")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookDatabase20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Data Collection </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/activitylog")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "automation_name", label: <Body1Strong>&nbsp; Name </Body1Strong>  },
    { key: "created_by", label: <Body1Strong> Created By </Body1Strong> },
    { key: "created_on", label: <Body1Strong> Created On </Body1Strong> },
    { key: "last_modified", label: <Body1Strong> last Modified </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  //Data tabel for filter columns starts here
  var columns1 = [
    { key: "name", label: <Caption1> Name </Caption1>  },
    { key: "created_by", label: <Caption1> Created By </Caption1> },
    { key: "created_on", label: <Caption1> Created On </Caption1> },
    {key: "status", label: <Caption1> Status </Caption1>},
    { key: "last_modified", label: <Caption1> last Modified </Caption1> },
  ];
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
     
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="monitorslist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here
 
 
  var tempTableData: any = [];
  var status = 'Success';
  var menuLists: any = [];
  var tempExportData: any = [];
 
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=",final_data);
   
    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
          menuLists.push();
 console.log("val.alerName=",val.alerName);
 
 tempExportData.push({
  automation_name: val.automationName,
  // type: val.types,
  created_by: val.createdBy,
  created_on: new Date(val.createdOn * 1000).toLocaleString(),
  last_modified: new Date(val.lastModified * 1000).toLocaleString(),
  status: status,
});

          tempTableData.push({
            id: val.id,
            checkbox: 'hide',
            automation_name: { label: <Button appearance="transparent" size="small"> {val.automationName} </Button> } ,
            created_by: { label: <Caption1> {val.createdBy} </Caption1>},
            created_on: { label: <Caption1> {val.createdOn} </Caption1>},
            last_modified: { label: <Caption1> {val.lastModified} </Caption1>},
            status: { label: <Caption1> {status} </Caption1>, },
          });
        }) : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    console.log("tableData=",tableData);
    
  }, [tableData]);
  // data table values append code ends here
 
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{childrenGap:10}} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="" subPageLink="#" /> </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink> 
                <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Devices | Monitor </Title3>
                </FluentProvider>
              </StackItem>
             
          </Stack>
 
          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
         
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                   { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                </StackItem>
                <StackItem>
                <StackItem>
                        <Stack horizontal tokens={{childrenGap:20}}>
                          <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn={ 
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <Filter20Filled {...primaryIcon}/>
                                  <Body1> Filter </Body1> 
                                </Stack>
                              </MenuButton>
                            } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                              <Divider/>
                              <MenuList>
                                <Stack tokens={{childrenGap:5}}>
                                  <StackItem> <Text></Text> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                                  {/* <DropdownComponent optionData={columns}/> */}
                                  <StackItem> <Divider /> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns}/> */}
                                  <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{childrenGap:10}} horizontal>
                                    <StackItem> <Button appearance="primary" onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>}/>
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                          <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <StackItem> <FilterDismiss20Filled/> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                  <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                    <StackItem> <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem>
                    <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                  </Stack>
                </StackItem>
                
                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider>
        </Stack>
        <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census"/>
      </StackItem>
      <StackItem><Text></Text></StackItem>
      </Stack>
      </FullScreen>
    </>
  );
};