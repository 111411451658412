import { useState, useEffect } from "react";
import { Census_func } from "../../../api/devices/census_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from "react-csv";
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, MessageBar, Card } from "@fluentui/react-components";
import { LinkSquare20Regular, BookTemplate20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Save20Regular, Info20Filled, ArrowMaximize20Regular, DismissSquare24Filled } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { CheckBox } from "../../../components/checkbox/checkbox_component";
import { ToastComponent } from "../../../components/toast/toast.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, secondaryIcon, exitIcon } from "../../../styles/customStyles";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById("csv-export-link");
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const Compliance_Policies = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
 
  const [messagebar1, setmessagebar1] = useState(false);
  const [messagebar2, setmessagebar2] = useState(false);
  const [isSaveEnabled, setSaveEnabled] = useState(false);
 
  //console.log("checking messagebar2",messagebar2)
  //console.log("checking messagebar1 value", messagebar1)
 
  const handleBothSetMessage = () => {
    setmessagebar1(false);
    setmessagebar2(true);
  };
  const handleSaveActive: any = (data: any) => {
    setSaveEnabled(data.target.checked);
    //console.log("setsaveactive called",data.target.checked);
  };
  const [showMessage, setShowMessage] = useState(false);
 
  const handleButtonClick = () => {
    setShowMessage(true);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
 
  var menuItems: any = [];

  menuItems.push
  (
      <FluentProvider theme={lightTheme}>
      <MenuItem
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <LinkSquare20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Assignment </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
      </FluentProvider>
  );
 
  menuItems.push
  (
      <MenuItem
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <BookTemplate20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Audit Log </Caption1> </StackItem>
        </Stack>
      </MenuItem>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "device_name", label: <Body1Strong > Group Name </Body1Strong> },
    {
      key: "manufacturer",
      label: <Body1Strong> Count of Devices </Body1Strong>,
    },
  ];
  //data table column name code ends here
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button appearance="transparent" size="small" onClickCapture={() => setCurrentPageNo(1)}  disabled={ isSaveEnabled ? false :true} onClick={() => { setmessagebar1(true); }}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                 {/* <Save20Regular {...primaryIcon} />  */}
                 { isSaveEnabled 
                                ? ( <Save20Regular {...primaryIcon}/> ) 
                                : ( <Save20Regular {...secondaryIcon}/> )
                            }
              </StackItem>
              <StackItem> <Caption1> Save </Caption1> </StackItem>
              </Stack>
          </Button>
        </StackItem>
 
        <StackItem><Divider vertical/> </StackItem>
       
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="compliance_Policieslist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}

        <CheckBox onClick={(e) => handleSaveActive(e)}></CheckBox>
      </>
    ),
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
 
  useEffect(() => {
    setmessagebar2(false);
 
    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
          menuLists.push();
 
          tempExportData.push({
            device_name: val.host,
            manufacturer: val.site,
            os: val.os,
            osversion: val.osversion,
            nanoheal_version: val.clientversion,
            installation_date: new Date(val.born * 1000).toLocaleString(),
            last_event: new Date(val.last * 1000).toLocaleString(),
          });
 
          tempTableData.push({
            device_name: { label: <Caption1> {val.host} </Caption1>,  icon: ( <Badge size="extra-small" appearance="filled" color="success"/> ), id: `${i}`, },
            manufacturer: { label: <Caption1> {val.site} </Caption1>, icon: "", status: "", },
            os: { label: <Caption1> {val.os} </Caption1>, icon: "" },
            osversion: { label: <Caption1> {val.clientversion} </Caption1>, icon: "", },
            nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1>, icon: "", },
            installation_date: { label: <Caption1> {val.born} </Caption1>, icon: "", },
            last_event: { label: <Caption1> {val.last} </Caption1>, icon: "" },
          });
        })
      : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0
      ? setTableData(tempTableData)
      : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here
 
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{childrenGap:10}} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="Compliance Policies" subPageLink="/manage/devices/compliancepolicies"/> </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink> 
                <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <Stack tokens={{ childrenGap: 25 }} horizontal>
            <StackItem> <Text></Text> </StackItem>
            <StackItem> 
              <FluentProvider theme={webLightTheme}> 
                <Title3> Bit Locker | Assignment </Title3>  
              </FluentProvider> 
            </StackItem>
          </Stack>
 
          <Stack tokens={{ childrenGap: 20 }}>
            <StackItem> <Text></Text>  </StackItem>
            <StackItem> <Text></Text> </StackItem>
          </Stack>
 
          <FluentProvider theme={webLightTheme}>
            <Stack horizontal>
              {/* SPACING BETWEEN INLINE AND PAGE */}
              <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
              <StackItem>
                <StackItem> <Text></Text> </StackItem>
                <Stack tokens={{ childrenGap: 1 }}>
                  <StackItem> <Text></Text> </StackItem>
                  <StackItem> <Text></Text> </StackItem>
                </Stack>
                <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                    <Divider />
                  </StackItem>
                  <StackItem>
                    <StackItem>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small" />
                        <StackItem>
                          <MenuDropdown
                            btn={
                              <MenuButton shape="circular" size="small">
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            }
                            itemVal={
                              <>
                                <Body1Strong> Add a Filter </Body1Strong>
                                <Divider />
                                <MenuList>
                                  <Stack tokens={{ childrenGap: 5 }}>
                                    <StackItem> <Text></Text> </StackItem>
                                    <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                    <DropdownComponent optionData={columns} />
                                    <StackItem> <Divider /> </StackItem>
                                    <StackItem> <Caption1> Value </Caption1> </StackItem>
                                    <DropdownComponent optionData={columns} />
                                    <StackItem> <Divider /> </StackItem>
                                    <Stack tokens={{ childrenGap: 10 }}  horizontal >
                                      <StackItem>
                                        <Button appearance="primary">
                                          <Caption1> Apply </Caption1>
                                        </Button>
                                      </StackItem>
                                      <StackItem>
                                        <Button>
                                          <Caption1> Cancel </Caption1>
                                        </Button>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                </MenuList>
                              </>
                            }
                          />
                        </StackItem>
                        <StackItem>
                          <Button appearance="subtle" size="small">
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                              <StackItem> <FilterDismiss20Filled /> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem> <Text></Text> </StackItem>
                      <StackItem> <Text></Text> </StackItem>
                    </Stack>
                    <Stack horizontal>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{childrenGap:10}} horizontal>
                                <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                                <StackItem> <Caption1> If you choose the option 'All devices' The policy will be applied universally to all devices and will become detached from Devices groups. </Caption1>  </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem>
                       <StackItem></StackItem>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <StackItem> <Text></Text> </StackItem>
                      <StackItem> <Text></Text> </StackItem>
                    </Stack>
                    <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                      <StackItem>
                        <Caption1> Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of {totalRecords} Records </Caption1>
                      </StackItem>
                      <StackItem>
                       
                        <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <Census_func  data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 10} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} />
                  {tableData.length > 0 && (
                    <DataTable tableData={tableData} columns={columns} />
                  )}
                </Stack>
              </StackItem>
            </Stack>
          </FluentProvider>
        </Stack>
        <StackItem>
          {messagebar1 && (
            <ToastComponent setmessagebar={setmessagebar1}  toastIntent="warning" position="top" title="Please confirm your decision to proceed with change. the policy will be assigned to 'All devices'."
              footer={
                <>
                  <Button appearance="primary" onClick={handleBothSetMessage}>
                    Confirm
                  </Button>
                  <Button onClick={() => { setmessagebar1(false); }} >
                    Cancel
                  </Button>
                </>
              }
            />
          )}
        </StackItem>
       
        <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census" />
      </StackItem>
      
        {messagebar2 && (
            <ToastComponent setmessagebar={setmessagebar2}  toastIntent="success" position="top-end" title="Success" message="Assignment Details have been revised" footer="" />
        )}
        <StackItem><Text></Text></StackItem>
      </Stack>
      </FullScreen>
    </>
  );
};