import { useState, useEffect, useContext } from "react";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Card, ButtonProps } from "@fluentui/react-components";
import { BookTemplate20Regular, ChatMultipleHeart20Regular, CalendarClock20Regular, Wand20Regular, SettingsCogMultiple20Regular,Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowMaximize20Regular, DismissSquare24Filled, Info20Filled, CheckmarkLock20Regular, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { Cards } from "../../../components/card/card.component";
import { All_automation_func } from "../../../api/automation/all_automation_func";
import { GlobalContext } from "../../../hooks/globalContext";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { useTracking } from "react-tracking";
import { ToastComponent } from "../../../components/toast/toast.component";
import { fullscreenIcon, primaryIcon, exitIcon, lightTheme, messageTheme } from "../../../styles/customStyles";
import { Compliance_policies_func } from "../../../api/automation/compliance_policies_func";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) { csvExportLink.click(); }
};

export const Compliance_policies = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [cardTemplate, setCardTemplate]: any = useState([]);
  const[isRefresh,setIsRefresh]:any =useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [isToast, setIsToast] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];
 
  menuItems.push
  ( 
    <MenuItem onClick={() => navigate("/automate/automation/all_automations")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > All&nbsp;Automations </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
    </MenuItem>
  );

  menuItems.push
  ( 
    <MenuItem onClick={() => navigate("/automate/automation/all_automations/configuration")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Automations </Caption1> </StackItem>
        <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
      </Stack>
    </MenuItem>
  );

  menuItems.push
  ( 
    
      <MenuItem onClick={() => {localStorage.setItem('dartNo','304'); contextValue.setDartNo(304); navigate("/automate/automation/selfhelp")}}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 >Self-Help </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );

  menuItems.push
  ( 
    <MenuItem key={1} onClick={() => {localStorage.setItem('dartNo','69'); contextValue.setDartNo(69); navigate("/automate/automation/autoheal")}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Wand20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1  > Autoheal </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  ( 
    <MenuItem key={2} onClick={() => {localStorage.setItem('dartNo','304'); contextValue.setDartNo(304); navigate("/automate/automation/automationschedule")}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <CalendarClock20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Automation Schedules </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  (
    <MenuItem key={3} onClick={()=> {localStorage.setItem('dartNo','241'); contextValue.setDartNo(241); navigate("/automate/automation/usersurvey");}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ChatMultipleHeart20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> User Surveys </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  (
    <FluentProvider theme={lightTheme}>
    <MenuItem key={3} onClick={()=> {navigate("/automate/automation/compliancepolices");}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <CheckmarkLock20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
      </Stack>
    </MenuItem>
    </FluentProvider>
  );

  menuItems.push
  (
    <MenuItem key={4} onClick={()=>navigate("/automate/automation/activitylog")} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: "device_name", label: <Body1Strong> Device Name </Body1Strong>  },
    { key: "manufacturer", label: <Body1Strong> Manufacturer </Body1Strong> },
    { key: "os", label: <Body1Strong> Operating System </Body1Strong> },
    { key: "installation_date", label: <Body1Strong> Installation Date </Body1Strong> },
    { key: "nanoheal_version", label: <Body1Strong> Nanoheal Version </Body1Strong> },
    { key: "last_event", label: <Body1Strong> Last Event </Body1Strong> },
  ];
  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: 
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setIsRefresh(true); setSearchVal("");}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
      </>
    ,
  };
  // overflow set contents code ends here

  var menuLists: any = [];
  var tempExportData: any = [];
  
  
  useEffect(() => {
    console.log("final_data=",final_data);
    
    var rowCnt = (final_data !== undefined && final_data !== null && final_data.length > 0) 
      ? Math.ceil(final_data.length/1) 
      : 0;
    var rowCntArr = rowCnt > 0 
      ? Array.from({ length: rowCnt }, (_, index) => index + 1) 
      : [];
 
    setCardTemplate(rowCntArr.map((val: any, i: any) => 
      <Stack horizontal tokens={{childrenGap: 10, padding: 10}}>
        {final_data.slice(i * 1, (i + 1) * 1).map((val: any, j: any) =>
          <StackItem>
            {(j + (1 * i)) < ((i + 1) * 1) && ((j + (1 * i)) < final_data.length) && 
              <Cards 
                title={final_data[(j + (1 * i))]?.templateName} 
                description={final_data[(j + (1 * i))]?.description} 
                os={(final_data[(j + (1 * i))]?final_data[(j + (1 * i))]?.supportingOS:'').split(",")} 
                btn={
                  <Button 
                    appearance="primary" 
                    size="small" 
                    onClick={() => {localStorage.setItem('dartNo',final_data[(j + (1 * i))]?.dartNo); 
                    contextValue.setDartNo(final_data[(j + (1 * i))]?.dartNo);
                    if (final_data[(j + (1 * i))]?.templateName === "Self-Help/Proactive") { navigate("/automate/automation/selfhelp"); } 
                    else if (final_data[(j + (1 * i))]?.templateName === "69 - Auto Heal") { navigate("/automate/automation/autoheal"); }
                    else if (final_data[(j + (1 * i))]?.templateName === "Automation Scheduler") { navigate("/automate/automation/automationschedule"); }
                    else { navigate("/automate/automation/compliance_policies/policy_configuration"); }
                  }} 
                  > 
                    <Caption1> Select </Caption1>  
                  </Button>
                } 
              /> 
            }
          </StackItem>
        )}
     
      </Stack>)
    );
  }, [final_data]);

  // data table values append code ends here

  var timer: any;
  const searchHandle = (data: any) => {
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchVal(data.target.value);
    },500);
  };

  useEffect(() => {
    console.log("tracked page");
    // !tracker && setTracker(true);
    // trackUserActivity({pageName: 'All automation', moduleName: "Automation", action: 'view', time: Math.floor(Date.now() / 1000)});
  }, []);
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  
  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Automation" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button
                    style={fullscreenIcon} 
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{childrenGap:20}} horizontal>
          <StackItem>
            <StackItem>
              {/* pages code starts here */}
              <Stack verticalAlign="center">
                {/* <StackItem> <Text> <Breadcrumbs pageName="Automation" subPageName="All Automations" subPageLink="manage/automation/all_automations" /> </Text> </StackItem> */}
                <Stack tokens={{childrenGap:25}} horizontal>
                    <StackItem><Text></Text></StackItem>
                    <StackItem>
                      <FluentProvider theme={webLightTheme}>
                        <Title3> Automation | Compliance Policies </Title3> 
                      </FluentProvider>
                    </StackItem>
                    {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
                </Stack>
                <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                <FluentProvider theme={webLightTheme}>
                  <Stack horizontal>
                    {/* SPACING BETWEEN INLINE AND PAGE */}
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
                    <StackItem>
                      <StackItem><Text></Text></StackItem>
                      <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                          <Divider/>
                        </StackItem>
                        <StackItem>
                          <StackItem>
                            <Stack horizontal tokens={{childrenGap:20}}>
                            <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                              <StackItem>
                                {/* <MenuDropdown 
                                  btn={ 
                                    <MenuButton shape="circular" size="small">
                                      <Stack tokens={{childrenGap:5}} horizontal>
                                        <Filter20Filled {...primaryIcon}/>
                                        <Body1> Filter </Body1> 
                                      </Stack>
                                    </MenuButton>
                                  } 
                                  itemVal={
                                    <>
                                      <Body1Strong> Add a Filter </Body1Strong>
                                      <Divider/>
                                      <MenuList>
                                        <Stack tokens={{childrenGap:5}}>
                                          <StackItem><Text></Text></StackItem>
                                          <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                          <DropdownComponent optionData={columns}/>
                                          <StackItem> <Divider/> </StackItem>
                                          <StackItem> <Caption1> Value </Caption1> </StackItem>
                                          <DropdownComponent optionData={columns}/>
                                          <StackItem> <Divider /> </StackItem>
                                          <Stack tokens={{childrenGap:10}} horizontal>
                                            <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                                            <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                          </Stack>
                                        </Stack>
                                      </MenuList>
                                    </>
                                  }
                                /> */}
                              </StackItem>
                              {/* <StackItem>
                                <Button appearance="subtle" size="small">
                                  <Stack tokens={{childrenGap:5}} horizontal>
                                    <StackItem> <FilterDismiss20Filled/> </StackItem>
                                    <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem> */}
                            </Stack>
                          </StackItem>
                          <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem>
                            <StackItem>
                              <FluentProvider theme={messageTheme}>
                                <Card appearance="subtle" size="small">  
                                  <Stack tokens={{childrenGap:10}} horizontal>
                                    <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                                    <StackItem> <Caption1> Explore our troubleshooting snippets for a smooth start. Pick a setup scenario, and we'll guide you through each step, handling assignments and other 
                                    configurations automatically. </Caption1> </StackItem>
                                  </Stack>
                                </Card>
                              </FluentProvider>
                            </StackItem>
                          </Stack>
                          {/* <Stack horizontal enableScopedSelectors horizontalAlign="space-between"> <StackItem> <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem> <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem> </Stack> */}
                        </StackItem>
                        
                        <Stack horizontal wrap> {cardTemplate} </Stack>

                        <Compliance_policies_func 
                          set_final_data={set_final_data} 
                          searchVal={searchVal} 
                          isRefresh={isRefresh} 
                          setIsRefresh={setIsRefresh} 
                          setRefreshToast={setRefreshToast} 
                          cardTemplate={cardTemplate}
                        />

                        {
                          tracker && 
                          <Activity_log_func 
                            data={{pageName: 'all automation', 
                            moduleName: "automation", 
                            action: 'view', 
                            time: Math.floor(Date.now() / 1000)}} 
                            setTracker={setTracker} 
                          />
                        }
                        {
                          refreshToast && 
                          <ToastComponent 
                            setmessagebar={setIsToast} 
                            isToastEnabled={refreshToast} 
                            toastIntent="success" 
                            position="top-end" 
                            title="Success" 
                            message={'Automation Refreshed'} 
                            footer="" 
                          />
                        }
                      </Stack>
                    </StackItem>
                  </Stack>
                </FluentProvider> 
              </Stack>
              <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census"/>
            </StackItem>
          </StackItem>
          <StackItem><Text></Text></StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};