import { useState } from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { FluentProvider, webDarkTheme } from '@fluentui/react-components';
import { GlobalContextProvider } from './hooks/globalContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SuperTokenConfig } from './Authentication/supertokenconfig';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import Header from './components/header/header.component';
import { Sidebar } from './components/side_menu/sidebar.component';
import { Signin } from './pages/signin/signin';
import { Signup } from './pages/signin/signup';
import PasswordReset from './pages/signin/passwordreset';
import { Forbidden } from './Forbidden';
import { PrivateRoutes } from './privateroute';
import { Home } from './pages/home/home';
import { Census } from './pages/devices/census/census';
import { Groups } from './pages/devices/groups/groups';
import { DeviceClassificationDevices } from './pages/devices/groups/device_classification_devices';
import { DeviceClassificationScores } from './pages/devices/groups/device_classification_scores';
import { DeviceClassificationAuditlog } from './pages/devices/groups/device_classification_auditlog';
import { Monitor } from './pages/devices/monitors/monitor';
import { Monitors } from './pages/devices/monitors/monitors';
import { Remediation } from './pages/devices/remediations/remediation';
import { Remediations } from './pages/devices/remediations/remediations';
import { CompliancePolicies } from './pages/devices/compliancepolicies/compliancepolicies';
import { Compliance_Policies } from './pages/devices/compliancepolicies/compliance_policies';
import { Compliance_Push } from './pages/devices/compliancepolicies/compliance_push';
import { ConditionalAccess } from './pages/devices/conditionalaccess/conditionalaccess';
import { ConditionalAccesses } from './pages/devices/conditionalaccess/conditionalaccesses';
import { Usersurvey } from './pages/devices/usersurvey/usersurvey';
import { Usersurveys } from './pages/devices/usersurvey/usersurveys';
import { DataCollection } from './pages/devices/datacollection/datacollection';
import { DataCollections } from './pages/devices/datacollection/datacollections';
import { Activity_log } from './pages/devices/activitylog/activity_log';
import { All_automation_cards } from './pages/automation/all_automation/all_automation_card';
import { All_automation } from './pages/automation/all_automation/all_automation';
import { Automation_config } from './pages/automation/all_automation/configuration';
import { Autoheal } from './pages/automation/autoheal/autoheal';
import { Selfhelp } from './pages/automation/selfhelp/selfhelp';
import { AutomationSchedule } from './pages/automation/automationSchedule/automationSchedule';
import { UserSurvey } from './pages/automation/userSurvey/usersurvey';
import { Compliance_policies } from './pages/automation/compliance_policies/compliance_policies';
import { Policy_config } from './pages/automation/compliance_policies/policy_configuration';
import { ActivityLog } from './pages/automation/activityLog/activitylog';
import { Appmonitored } from './pages/apps/appMonitored/appmonitored';
import { Appinstallstatus } from './pages/apps/appinstallstatus/appinstallstatus';
import { Appuninstallstatus } from './pages/apps/appuninstallstatus/appuninstallstatus';
import { Discoveredapps } from './pages/apps/discoveredapps/discoveredapps';
import { Discoveredapps_list } from './pages/apps/discoveredapps/discoveredapps_list';
import { Activitylogg } from './pages/apps/activitylogg/activitylogg';
import { Appinstallation } from './pages/apps/appInstallationProfile/appinstallation';
import { Appuninstallprofile } from './pages/apps/appuninstallprofile/appuninstallprofile';
import { Appprotectionpolicies } from './pages/apps/appprotectionpolicies/appprotectionpolicies';
import { Alert } from './pages/troubleshoot/alert';
import { TroubleShoot } from './pages/troubleshoot/troubleshoot';
import { Status } from './pages/troubleshoot/status';
import { AllUsers } from './pages/users/user/allusers';
import { ActivityLogs } from './pages/users/activityLog/activityLog';
import { Administration } from './pages/administrations/administration';
import { Compliance_Policy } from './pages/troubleshoot/compliance_Policy';
import { DashboardLists } from './pages/investigate/dashboardlists';
import { ChangePassword } from './pages/signin/changepassword';
// import getSuperTokensRoutesForReactRouterDom from 'supertokens-auth-react';
import { lightTheme, appHeader, appBody } from './styles/customStyles';
// import getSuperTokensRoutesForReactRouterDom from 'supertokens-auth-react';
// import { Activity_log_func } from "./api/activity_log/activity_log_func"

SuperTokens.init(SuperTokenConfig); // Supertoken init functions

function App() {
  const [layoutCondition, setLayoutCondition] = useState(false);
  const [leftOpen, setLeftOpen] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);

  const handleFixedLayout = (value: any) => {
    setLayoutCondition(value);
  };

  /* const App = track ( {app:"React App"}, { dispatch: (data: any) => { console.log("tracked ele=",data); // Activity_log_func(data); localStorage.setItem('activityLogData', JSON.stringify(data)); }} ), (TrackedApp); (localStorage.getItem('activityLogData') == undefined || localStorage.getItem('activityLogData') == null) && localStorage.setItem('activityLogData', JSON.stringify({})); const insertActivityLogFunc = async () => { var logs: any = localStorage.getItem('activityLogData'); logs = JSON.parse(logs); console.log("log=",logs); if (logs != undefined && logs != '') { var userId = "1"; var pageName = logs?.pageName; var moduleName = logs?.moduleName; var tenant = contextValue?.tenantName; var activity = logs?.activity; var time = logs?.time; var initiatedBy = contextValue?.userName; var status = logs?.action; const {data} = await client.mutate({ mutation: insertActivityLog, variables: {userId, pageName, moduleName, tenant, activity, time, initiatedBy, status}, fetchPolicy: 'network-only', }); if (data.insert_audit_ActivityLog.affected_rows > 0) { localStorage.setItem('activityLogData', JSON.stringify({})); return true; } } } useEffect(() => { console.log("insertActivityLogFunc() called"); insertActivityLogFunc(); }, []); function getSuperTokensRoutesForReactRouterDom(reactRouterDom:any): import("react").ReactNode { throw new Error("Function not implemented."); } */

  return (
    <GlobalContextProvider>
      <Stack className="App">
        <SuperTokensWrapper>
          <BrowserRouter basename="/visualization/v2">
            <Stack className="App">
              <Stack className="header" style={appHeader}>
                {/* Fixed Header */}
                <FluentProvider theme={webDarkTheme}> {layoutCondition && <Header pageName="Notifications" />} </FluentProvider>
              </Stack>
              <Stack className="Container" style={appBody}>
                {/* Fixed Sidebar and Content */}
                <Stack horizontal>
                  <FluentProvider theme={lightTheme}>
                    {/* Fixed Sidebar */}
                    {showSidebar ? (
                      <Stack>
                        {layoutCondition && <Sidebar leftOpen={leftOpen} setLeftOpen={setLeftOpen} />}
                      </Stack> /* <Stack> {layoutCondition &&<SidebarAutomate leftOpen={leftOpen} setLeftOpen={setLeftOpen}/>} <StackItem styles={stackStyles}> <Text></Text> </StackItem> </Stack> */
                    ) : (
                      <Navigate to="/" />
                    )}
                  </FluentProvider>
                  <StackItem>
                    {/* Dynamic Content based on routes */}
                    <Routes>
                      {/* {getSuperTokensRoutesForReactRouterDom(reactRouterDom)} */}
                      <Route path="/" element={<Signin layoutCondition={layoutCondition} />} />
                      <Route path="/signup" element={<Signup />} />
                      <Route path="/auth/reset-password" element={<PasswordReset />} />
                      <Route path="/forbidden" element={<Forbidden />} /> {/* <Navigate replace to={"/forbidden"} /> */}
                      <Route element={<PrivateRoutes handleFixedLayout={handleFixedLayout} />}>
                        <>
                          <Route path="/auth/change-password" element={<ChangePassword />} />
                          <Route path="/Dashboard" element={<Home />} />

                          {/* DEVICES PAGES */}
                          <Route path="/manage/devices/*">
                            <Route path="overview" element={<Census />} />
                            <Route path="device_classification" element={<Groups />} />
                            <Route path="device_classification/devices" element={<DeviceClassificationDevices />} />
                            <Route path="device_classification/scores" element={<DeviceClassificationScores />} />
                            <Route path="device_classification/audit_log" element={<DeviceClassificationAuditlog />} />
                            <Route path="monitor" element={<Monitor />} />
                            <Route path="monitors" element={<Monitors />} />
                            <Route path="remediation" element={<Remediation />} />
                            <Route path="remediations" element={<Remediations />} />
                            <Route path="compliancepolicies" element={<CompliancePolicies />} />
                            <Route path="compliance_policies" element={<Compliance_Policies />} />
                            <Route path="compliance_push" element={<Compliance_Push />} />
                            <Route path="conditionalaccess" element={<ConditionalAccess />} />
                            <Route path="conditionalaccesses" element={<ConditionalAccesses />} />
                            <Route path="usersurvey" element={<Usersurvey />} />
                            <Route path="usersurveys" element={<Usersurveys />} />
                            <Route path="datacollection" element={<DataCollection />} />
                            <Route path="datacollections" element={<DataCollections />} />
                            <Route path="activitylog" element={<Activity_log />} />
                          </Route>
                          {/* AUTOMATION PAGES */}
                          <Route path="/automate/automation/*">
                            <Route path="all_automations/" element={<All_automation />} />
                            <Route path="all_automations/cards" element={<All_automation_cards />} />
                            <Route path="all_automations/" element={<All_automation />} />
                            <Route path="all_automations/configuration" element={<Automation_config />} />
                            <Route path="autoheal" element={<Autoheal />} />
                            <Route path="selfhelp" element={<Selfhelp />} />
                            <Route path="automationschedule" element={<AutomationSchedule />} />
                            <Route path="usersurvey" element={<UserSurvey />} />
                            <Route path="compliancepolices" element={<Compliance_policies />} />
                            <Route path="compliance_policies/policy_configuration" element={<Policy_config />} />
                            <Route path="activitylog" element={<ActivityLog />} />
                          </Route>
                          {/* APPS PAGES */}
                          <Route path="/manage/apps/*">
                            <Route path="appmonitor" element={<Appmonitored />} />
                            <Route path="appmonitored" element={<Appmonitored />} />
                            <Route path="appinstallstatus" element={<Appinstallstatus />} />
                            <Route path="appuninstallstatus" element={<Appuninstallstatus />} />
                            <Route path="discoveredapps" element={<Discoveredapps />} />
                            <Route path="discoveredapps/discoveredapps_list" element={<Discoveredapps_list />} />
                            <Route path="activitylogg" element={<Activitylogg />} />
                          </Route>
                          <Route path="/automate/apps/*">
                            <Route path="appinstallation" element={<Appinstallation />} />
                            <Route path="appuninstallprofile" element={<Appuninstallprofile />} />
                            <Route path="appprotectionpolicies" element={<Appprotectionpolicies />} />
                          </Route>
                          {/* TROUBLESHOOTER PAGES */}
                          <Route path="/manage/troubleshoot/*">
                            <Route path="alert" element={<Alert />} />
                            <Route path="troubleshoot" element={<TroubleShoot />} />
                            <Route path="status" element={<Status />} />
                            <Route path="compliance_policy" element={<Compliance_Policy />} />
                          </Route>
                          {/* USERS PAGES */}
                          <Route path="/manage/user/*">
                            <Route path="alluser" element={<AllUsers />} />
                            <Route path="activitylog" element={<ActivityLogs />} />
                          </Route>
                          {/* ADMINISTARTION PAGES */}
                          <Route path="/manage/administration/administration" element={<Administration />} />
                          {/* INVESTIGATE PAGE */}
                          <Route path="/investigate/dashboardlists" element={<DashboardLists />} />
                          {/* <Route path="/login" component={Login} /> */}
                        </>
                      </Route>
                    </Routes>
                  </StackItem>
                </Stack>
              </Stack>
            </Stack>
          </BrowserRouter>
        </SuperTokensWrapper>
      </Stack>
    </GlobalContextProvider>
  );
}

export default App;
