import { useState, useEffect, JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useContext } from "react";
// import { Census_func } from "../../api/devices/census_func";
import { SideMenu } from "../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../components/data_table/data_table.component";
import OverLay from "../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../components/side_menu/overFlowSet.component";
import { Stack, StackItem} from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, Card, Textarea, Body1Stronger, ButtonProps, } from "@fluentui/react-components";
import { Info20Regular, BookTemplate20Regular, Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular, SlideEraser20Regular, AddSquare20Regular, Info20Filled, CheckmarkFilled} from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../components/dropdown/menu.component";
import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { accordionStyle, fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, lightTheme, overflowDivider } from "../../styles/customStyles";
import DialogBox from "../../components/dialog/dialog.component";
import { AccordionComponent } from "../../components/accordion/accordion.component";
import { RadioAccordion } from "../../components/radio_group/radioAccordion";
import { ToastComponent } from "../../components/toast/toast.component";
import { Admin_func} from "../../api/administration/admin_func";
import { shouldLoadRoute } from "../../Authentication/RouteProtection";
import { Form, Widgets } from "@rjsf/fluent-ui";
import  validator  from '@rjsf/validator-ajv8';
import { GlobalContext } from "../../hooks/globalContext";


export const Administration = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [formDataAdmin, setFormDataAdmin] = useState({});
  const [formDataRole, setFormDataRole] = useState({});
  const [final_data, set_final_data]: any = useState(null);
  const [saveRoleDetails,setSaveRoleDetails]:any = useState(false)
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [isdeletedisabled,setDeleteDisabled] = useState(true);
  const [messagebar2, setmessagebar2] = useState(false);
  const [isProgressLoading, setProgressLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [routeFlag,setRouteFlag] = useState(false)
  const [isDeleteRole, setIsDeleteRole] = useState(false);
  const [isDeleteRoleToast, setIsDeleteRoleToast] = useState(false);
  const [createNewRole, setNewRole] = useState(false);
  const [isRefresh,setIsRefresh]:any =useState(false);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [refreshToast,setRefreshToast]:any=useState(false);
  const [overLayData, setOverLayData] = useState({});
  const [handlePermission,setHandlePermission]:any =useState();


  // const [routeFlag,setRouteFlag] = useState(false);
  const [editRole,setEditRole]=useState('');
  const [overlayName, setOverlayName] = useState('');
  const [rowID, setRowID]: any = useState(null);
  // const [rowID, setRowID] = useState<string | null>(null);
  const [editRoleDetail,setEditRoleDetail] = useState(false)


  


  //check weather proper roles and permissions are there
  async function loadRoute() {
    const routeAllowed = await shouldLoadRoute('Nanoheal-admin');
    
    if (routeAllowed) {
        setRouteFlag(true)
    } else {
      // Redirect or handle unauthorized access
        setRouteFlag(false)
    }
  }


  //save button
  const saveBtnFunc = () => { 
    
    console.log("BUTTON CLICKED",formDataRole,'  --------   ',formDataAdmin)
    overlayName == 'edit'? setEditRoleDetail(true) : setSaveRoleDetails(true) 
    console.log('editRoleDetail --',editRoleDetail)
    setIsToast(true) 
    setOverlayOpen(false)
  }

  useEffect(() => {
    console.log("EditRoleDetail=",editRoleDetail);
  }, [editRoleDetail])

  const CustomRadioWidget = (props: { options: any; value: any; required: any; disabled: any; onChange: any; label:any}) => {
    const { options, value, required, disabled, onChange,label} = props;
    
    return (
      <div>
        <Stack horizontal styles={{root:{justifyContent: 'space-between'}}}>
        <StackItem>  
        <Body1Strong><label>{label}</label></Body1Strong>
        </StackItem>
        <StackItem>
        {options.enumOptions.map((option: { value: string | number | readonly string[] | undefined; label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
          <label key={index} style={{ marginRight: '10px' }}>
            
            <input
              type="radio"
              value={option.value}
              checked={value === option.value}
              required={required}
              disabled={disabled}
              onChange={() => onChange(option.value)}
            />
            {option.label}
          </label>
        ))}
        </StackItem>
        </Stack>
        <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
      </div>
    );
  };

  const jsonSchemaPermisssion :any = {
    "type": "object",
    "properties": {
        "devices": {
            "type": "object",
            "title": "Devices"
        },
        "overview": {
            "type": "string",
            "title": "Overview",
            "enum":[
                "overview_Read",
                "overview_ReadandExport",
                "overview_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "deviceClassification": {
            "type": "string",
            "title": "Device Classification",
            "enum":[
                "deviceClassification_Read",
                "deviceClassification_ReadandExport",
                "deviceClassification_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "monitor": {
            "type": "string",
            "title": "Monitor",
            "enum":[
                "monitor_Read",
                "monitor_ReadandExport",
                "monitor_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "remediation": {
            "type": "string",
            "title": "Remediation",
            "enum":[
                "remediation_Read",
                "remediation_ReadandExport",
                "remediation_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "compPolicies": {
            "type": "string",
            "title": "Compliance Policies",
            "enum":[
                "compPolicies_Read",
                "compPolicies_ReadandExport",
                "compPolicies_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "conditionalAccess": {
            "type": "string",
            "title": "Conditional Access",
            "enum":[
                "conditionalAccess_Read",
                "conditionalAccess_ReadandExport",
                "conditionalAccess_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "userSurveys": {
            "type": "string",
            "title": "User Surveys",
            "enum":[
                "userSurveys_Read",
                "userSurveys_ReadandExport",
                "userSurveys_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "dataCollection": {
            "type": "string",
            "title": "Data Collection",
            "enum":[
                "dataCollection_Read",
                "dataCollection_ReadandExport",
                "dataCollection_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "activityLog": {
            "type": "string",
            "title": "Activity Log",
            "enum":[
                "activityLog_Read",
                "activityLog_ReadandExport",
                "activityLog_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "automation": {
            "type": "object",
            "title": "Automation"
        },
        "allAutomation": {
            "type": "string",
            "title": "All Automation",
            "enum":[
                "allAutomation_Read",
                "allAutomation_ReadandExport",
                "allAutomation_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "autoheal": {
            "type": "string",
            "title": "Autoheal",
            "enum":[
                "autoheal_Read",
                "autoheal_ReadandExport",
                "autoheal_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "automationSchedule": {
            "type": "string",
            "title": "Automation Schedules",
            "enum":[
                "automationSchedule_Read",
                "automationSchedule_ReadandExport",
                "automationSchedule_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "automationUserSurveys": {
            "type": "string",
            "title": "User Surveys",
            "enum":[
                "automationUserSurveys_Read",
                "automationUserSurveys_ReadandExport",
                "automationUserSurveys_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "automationLog": {
            "type": "string",
            "title": "Activity Log",
            "enum":[
                "automationLog_Read",
                "automationLog_ReadandExport",
                "automationLog_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "selfHelp": {
            "type": "string",
            "title": "Self-Help",
            "enum":[
                "selfHelp_Read",
                "selfHelp_ReadandExport",
                "selfHelp_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "configuration": {
            "type": "string",
            "title": "Configuration",
            "enum":[
                "configuration_Read",
                "configuration_ReadandExport",
                "configuration_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "apps": {
            "type": "object",
            "title": "Apps"
        },
        "appMonitored": {
            "type": "string",
            "title": "App Monitored",
            "enum":[
                "appMonitored_Read",
                "appMonitored_ReadandExport",
                "appMonitored_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "appInstall": {
            "type": "string",
            "title": "App Install Status",
            "enum":[
                "appInstall_Read",
                "appInstall_ReadandExport",
                "appInstall_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "appUnInstall": {
            "type": "string",
            "title": "App Uninstall Status",
            "enum":[
                "appUnInstall_Read",
                "appUnInstall_ReadandExport",
                "appUnInstall_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "discoverApps": {
            "type": "string",
            "title": "Discovered Apps",
            "enum":[
                "discoverApps_Read",
                "discoverApps_ReadandExport",
                "discoverApps_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "appLog": {
            "type": "string",
            "title": "Activity Log",
            "enum":[
                "appLog_Read",
                "appLog_ReadandExport",
                "appLog_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "appInstallation": {
            "type": "string",
            "title": "App Installation",
            "enum":[
                "appInstallation_Read",
                "appInstallation_ReadandExport",
                "appInstallation_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "appUnInstallProfile": {
            "type": "string",
            "title": "App Uninstall Profile",
            "enum":[
                "appUnInstallProfile_Read",
                "appUnInstallProfile_ReadandExport",
                "appUnInstallProfile_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        }, 
        "appProtectProfile": {
            "type": "string",
            "title": "App Protection Profile",
            "enum":[
                "appProtectProfile_Read",
                "appProtectProfile_ReadandExport",
                "appProtectProfile_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "troubleshoot": {
            "type": "object",
            "title": "Troubleshoot"
        },
        "tshoot": {
            "type": "string",
            "title": "Troubleshoot",
            "enum":[
                "tshoot_Read",
                "tshoot_ReadandExport",
                "tshoot_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "status": {
            "type": "string",
            "title": "Status",
            "enum":[
                "status_Read",
                "status_ReadandExport",
                "status_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "users": {
            "type": "object",
            "title": "Users"
        },
        "allUsers": {
            "type": "string",
            "title": "All Users",
            "enum":[
                "allUsers_Read",
                "allUsers_ReadandExport",
                "allUsers_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "userLog": {
            "type": "string",
            "title": "Activity Log",
            "enum":[
                "userLog_Read",
                "userLog_ReadandExport",
                "userLog_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "administrationTitle": {
            "type": "object",
            "title": "Administration"
        },
        "administration": {
            "type": "string",
            "title": "Administration",
            "enum":[
                "administration_Read",
                "administration_ReadandExport",
                "administration_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        },
        "administrationLog": {
            "type": "string",
            "title": "Activity Log",
            "enum":[
                "administrationLog_Read",
                "administrationLog_ReadandExport",
                "administrationLog_Fullaccess"
            ],
            "enumNames":[
                "Read",
                "Read and export",
                "Full Access"
            ]
        }
    }
}

const uiSchemaPermission :any  = {
  "roleName": {
      "ui:placeholder": "Placeholder text"
  },
  "overview": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "deviceClassification": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "monitor": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "remediation": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "compPolicies": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "conditionalAccess": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "userSurveys": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "dataCollection": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "activityLog": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "allAutomation": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "autoheal": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "automationSchedule": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "automationUserSurveys": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "automationLog": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "selfHelp": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "configuration": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appMonitored": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appInstall": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appUnInstall": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "discoverApps": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appLog": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appInstallation": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appUnInstallProfile": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "appProtectProfile": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "tshoot": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "status": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "allUsers": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "userLog": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "administration": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  "administrationLog": {
      "ui:widget": CustomRadioWidget,
      "ui:inline":true
  },
  'ui:submitButtonOptions': {
          norender: true,
        }
}

const jsonSchemaRole:any = {
  "type": "object",
  "properties": {
      "roleName": {
          "type": "string",
          "title": "Name"
      },
      // "benefit": {
      //     "type": "string",
      //     "title": "What is the benefit?",
      //     "default": "Creating roles involves defining designated permissions and access levels for a specific role"
      // },
      // "impact": {
      //     "type": "string",
      //     "title": "What is the User impact?",
      //     "default": "Defining roles with designated permissions and access levels ensures that users have appropriate access to resources, enhancing security and efficiency while minimizing unauthorized actions."
      // }
  }
}

const uiSchemaRole:any = {
  "roleName": {
      "ui:placeholder": "Placeholder text"
  },
  "benefit": {
      "ui:widget": "textarea",
      "ui:options": {
          "rows": 4
      }
  },
  "impact": {
      "ui:widget": "textarea",
      "ui:options": {
          "rows": 4
      }
  },
  'ui:submitButtonOptions': {
    norender: true,
  }
}
  const handleBothSetMessage = () => {
    // setmessagebar1(false);
    setmessagebar2(true);
    setProgressLoading(true);
  };

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
}

  const handleCloseFilter = (index:any):any  => {
    console.log("clicked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
 
  useEffect(() => {
   if(filterArray.length > 0)
   setResetDisabled(false)
  }, [filterArray])

  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }
 
   // Function to render RadioAccordion components based on submodule names
   const renderRadioAccordions = (title:any) => {
    return title.map((title:any, index: any) => (
      <RadioAccordion key={index} radioValues={["read", "readAndExport", "fullAccess"]} title={title} />
    ));
  };
  const handleOnCLickEdit = (val:any) => {
    console.log("role name for edit",val.role);
    setRowID(val.role);
    setOverlayName('edit');
    setOverlayOpen(true);
  }
  
  const [han, handleEditData]: any = useState({});
  // const handleEditData = (data:any) => {
    useEffect(() => {
      if(Object.keys(han).length > 0){
        console.log("inside handleedit")
        console.log("this is data ->",han)
        console.log("han?.supertokens_roles[0]=",han);
        // console.log("han?.supertokens_roles[0].roleSchema=",JSON.parse(han?.supertokens_roles[0].roleSchema));
        
        var roleDetails = JSON.parse(han)
        // var permissionDetails= JSON.parse(han?.permissionsSchema)
        console.log('roleDetails ->',roleDetails)
        if(roleDetails != null || roleDetails != undefined)
        setFormDataRole(roleDetails)
       if(handlePermission != null || handlePermission != undefined)
        setFormDataAdmin(JSON.parse(handlePermission));
        // setFormDataAdmin(permissionDetails);
      }
    },[han,handlePermission])

  var menuItems: any = [];
 
  //inline side menu content code starts here
  const selectedTheme: BrandVariants = {
    10: "#010404",
    20: "#0F1A1D",
    30: "#142B32",
    40: "#163842",
    50: "#164652",
    60: "#165464",
    70: "#136275",
    80: "#0C7187",
    90: "#038099",
   100: "#388DA4",
   110: "#d6d6d6",
   120: "#6FA8B9",
   130: "#86B5C4",
   140: "#9DC3CF",
   150: "#B4D1DA",
   160: "#CADFE5",
  };

  const lightTheme: Theme = {...createLightTheme(selectedTheme)};
  const darkTheme: Theme = {...createDarkTheme(selectedTheme)};
  const messageTheme: Theme = {...createLightTheme(selectedTheme)};
 
  // lightTheme.colorNeutralBackground4 = '#0a0a0a';
  // darkTheme.colorNeutralBackground1
  darkTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground4 = "#0a0a0a";
  darkTheme.colorNeutralForeground1 = "#ffff00";
  messageTheme.colorNeutralBackground1 = "#EBF3FC";
 
  menuItems.push
  (
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/manage/administration/administration")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text/></StackItem>
          <StackItem> <Info20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text/></StackItem>
          <StackItem> <Caption1> Roles </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text/></StackItem> <StackItem><Text/></StackItem> </Stack> </StackItem>
        </Stack>
      </MenuItem>
      </FluentProvider>
  );
 
  menuItems.push
  (
    <MenuItem  onClick={() => navigate("/manage/administration/administration")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text/></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text/></StackItem>
        <StackItem> <Caption1> Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  ); // inline side menu content code ends here
 
  var columns = [ // data table column name code starts here
    { key: "role_name", label: <Body1Strong> Role Name </Body1Strong>  },
    { key: "createdOn", label: <Body1Strong> Created On </Body1Strong> },
    { key: "modifed_on", label: <Body1Strong> Modified On </Body1Strong> },
    { key: "createdBy", label: <Body1Strong> Created By </Body1Strong> },
  
  ]; // data table column name code ends here

  var columns1 = [ // data table for filter
    { key: "role_name", label: <Caption1> Role Name </Caption1>  },
    { key: "createdOn", label: <Caption1> Created On  </Caption1> },
    { key: "last_modified", label: <Caption1> Modified on </Caption1> },
    { key: "createdBy", label: <Caption1> Created By  </Caption1> },
  ];

  useEffect(()=>
  {
    console.log("FORM DATA",formDataRole);
  }, [formDataRole]
)
 
  var overFlowComponent = { // overflow set contents code starts here
    data:
      <>
      <StackItem>
        <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
          <Stack tokens={{childrenGap:5}} horizontal>
            <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
            <StackItem> <Caption1 onClick={()=>{setFormDataRole({});setFormDataAdmin({});setOverlayOpen(true);setOverlayName('')}}> Add Role </Caption1> </StackItem>
          </Stack>
        </Button>
      </StackItem> 
      <StackItem><Divider vertical/></StackItem>
      <StackItem>
        <DialogBox
        headerButton={
          <Button appearance="transparent" size="small" onClickCapture={() => setCurrentPageNo(1)}  
            disabled={ isdeletedisabled ? true :false}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                  { isdeletedisabled 
                    ? ( <SlideEraser20Regular {...secondaryIcon}/> ) 
                    : ( <SlideEraser20Regular {...primaryIcon}/> )
                  }
                  </StackItem>
              <StackItem> <Caption1> Delete </Caption1> </StackItem>
            </Stack>
          </Button>
        }
         
        content= "Are you sure you want to delete this role"
         
        footer={
          <Stack horizontal tokens={{childrenGap:10}}>
            <StackItem> <Button appearance="primary" onClick={() => setIsDeleteRole(true)} size="small"> Confirm </Button> </StackItem>
            <StackItem> <Button size="small"> Cancel </Button> </StackItem>
          </Stack>
        }
        />
      </StackItem>
      <StackItem><Divider vertical/></StackItem>
      <StackItem>
        <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setIsRefresh(true);}}>
          <Stack tokens={{childrenGap:5}} horizontal>
            <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
            <StackItem> <Caption1> Refresh </Caption1> </StackItem>
          </Stack>
        </Button>
      </StackItem>
      <StackItem>
        <CSVLink id="csv-export-link" data={exportData} filename="Administration_assignmentlist.csv">
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Export </Caption1> </StackItem>
            </Stack>
          </Button>
        </CSVLink>
      </StackItem>
    </>
  }; // overflow set contents code ends here
 
  var tempTableData: any = [];
  var menuLists: any = [];
  
  useEffect(() => {
    var tempExportData: any = [];
    
    loadRoute()
    setmessagebar2(false);
    // data table values append code starts here
    console.log("final_data=",final_data);
    final_data !== undefined && final_data !== null
      ? final_data.supertokens_roles.map((val: any, i: any) => {
          menuLists.push();
        
          tempExportData.push({
            role_name: val.role,
            createdOn: val.born,
            modifed_on: new Date(val.last *1000).toLocaleString(),
            createdBy: val.createdBy
          });
 
          tempTableData.push({
            // checkbox:'hide',
            id:val.role,
            // role_name: { label: <Caption1> {val.role} </Caption1>, id: `${i}` },
            role_name: { label: <Caption1 onClick={(e: any)=>{handleOnCLickEdit(val)}}> {val.role} </Caption1>, id: `${i}` },
            createdOn: { label: <Caption1> {val.born} </Caption1>},
            modifed_on: { label: <Caption1>{'\u00A0'.repeat(7)} {val.last === null ? val.last : '-'} </Caption1>},
            createdBy: { label: <Caption1> {val.createdBy} </Caption1>},
          });
        }) : (tempTableData = []);
    setExportData(tempExportData);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data,formDataRole]);
  // data table values append code ends here

  const deviceFeatures = [
    'Read and Export device list in overview.',
    'Create, delete, and edit device classifications using manual and CSV methods.',
    'Convert device classification to configuration group.',
    'Export the device classification list and the devices under each device classification.',
    'Attach / De-attach automations to all devices and device classifications.',
    'Export remediation details.'
  ];

  const automationFeatures = [
    'Read and Export Automations.',
    'Read and Export Autoheal.',
    'Create user survey.',
    'Read activity log.',
  ];
  
  const appFeatures = [
    'Create, edit and delete installation profiles.',
    'Create, edit and delete app for monitoring.',
    'Read and export deploy status.',
  ]

  useEffect(() => {
    console.log("useeee=", formDataRole);
    
    setOverLayData({
      headerTitle:<> {overlayName == 'edit' ? <Title3>Edit Role</Title3>:<Title3>Add Role</Title3>}</>,
    size: "large",
    isTabs: true,
    tabItems: {
      Introduction: 
        <>
          <Form 
            // ref = {formRef}  
            formData={formDataRole}  
            schema={jsonSchemaRole}
            uiSchema={uiSchemaRole} 
            validator={validator}
            // widgets={CustomWidgets} 
            onChange={(e) => setFormDataRole(e.formData)}
            />
          <Stack tokens={{childrenGap:15}}>
            <Stack> 
              <StackItem>
                <FluentProvider theme={messageTheme}>
                  <Card appearance="subtle" size="small">
                    <Stack tokens={{childrenGap:10}} horizontal>
                      <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                      <StackItem> <Caption1> These are the basic details  associated with this Roles. You can retain them or modify them to suit your specific use case. </Caption1>  </StackItem>
                    </Stack>
                  </Card>
                </FluentProvider>
              </StackItem>
            </Stack>
            <StackItem> <Body1Strong> What is the benefit? </Body1Strong> </StackItem>
            <StackItem><Body1 align='justify'>Creating roles involves defining sets of permissions and access levels that determine what actions users with that role can perform within a system or organization. These permissions can include tasks like viewing, creating, editing, or deleting certain data or functionalities. Roles help enforce security, streamline workflows, and ensure that users only have access to the resources necessary for their responsibilities, reducing the risk of unauthorized access or misuse of sensitive information.</Body1></StackItem>
          </Stack>
          
        </>
        ,

      Permission: 
      <>
         <div>
         <Form 
              // ref = {formRef}  
              formData={formDataAdmin}  
              schema={jsonSchemaPermisssion}
              uiSchema={uiSchemaPermission} 
              validator={validator}
              // widgets={CustomWidgets} 
              onChange={(e) => setFormDataAdmin(e.formData)}
              />
         </div>
      </>,
      "Review Data": 
      <>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
         <Body1Stronger> Devices </Body1Stronger>
           <Stack tokens={{ childrenGap: 5 }}>
            {deviceFeatures.map((feature, index) => (
             <StackItem key={index}>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
                  <StackItem>{feature}</StackItem>
                </Stack>
             </StackItem>
            ))}
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
         <Body1Stronger>Automation</Body1Stronger>
           <Stack tokens={{ childrenGap: 5 }}>
            {automationFeatures.map((feature, index) => (
             <StackItem key={index}>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
                  <StackItem>{feature}</StackItem>
                </Stack>
             </StackItem>
            ))}
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
         <Body1Stronger>Automation</Body1Stronger>
           <Stack tokens={{ childrenGap: 5 }}>
            {appFeatures.map((feature, index) => (
             <StackItem key={index}>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
                  <StackItem>{feature}</StackItem>
                </Stack>
             </StackItem>
            ))}
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <Body1Stronger>Troubleshoot</Body1Stronger>
          <Stack tokens={{childrenGap:5}} horizontal>
            <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
            <StackItem>Read and export deploy status.</StackItem>
          </Stack>
        </Stack>
      </>,
    }
    })
  }, [formDataRole]);

  // var overLayData: any = {
  //   headerTitle:<> {overlayName == 'edit' ? <Title3>Edit Role</Title3>:<Title3>Add Role</Title3>}</>,
  //   size: "large",
  //   isTabs: true,
  //   tabItems: {
  //     Introduction: 
  //       <>
  //         <Form 
  //           // ref = {formRef}  
  //           formData={formDataRole}  
  //           schema={jsonSchemaRole}
  //           uiSchema={uiSchemaRole} 
  //           validator={validator}
  //           // widgets={CustomWidgets} 
  //           onChange={(e) => setFormDataRole(e.formData)}
  //           />
  //         <Stack tokens={{childrenGap:15}}>
  //           <Stack> 
  //             <StackItem>
  //               <FluentProvider theme={messageTheme}>
  //                 <Card appearance="subtle" size="small">
  //                   <Stack tokens={{childrenGap:10}} horizontal>
  //                     <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
  //                     <StackItem> <Caption1> These are the basic details  associated with this Roles. You can retain them or modify them to suit your specific use case. </Caption1>  </StackItem>
  //                   </Stack>
  //                 </Card>
  //               </FluentProvider>
  //             </StackItem>
  //           </Stack>
  //           <StackItem> <Body1Strong> What is the benefit? </Body1Strong> </StackItem>
  //           <StackItem><Body1 align='justify'>Creating roles involves defining sets of permissions and access levels that determine what actions users with that role can perform within a system or organization. These permissions can include tasks like viewing, creating, editing, or deleting certain data or functionalities. Roles help enforce security, streamline workflows, and ensure that users only have access to the resources necessary for their responsibilities, reducing the risk of unauthorized access or misuse of sensitive information.</Body1></StackItem>
  //         </Stack>
          
  //       </>
  //       ,

  //     Permission: 
  //     <>
  //        <div>
  //        <Form 
  //             // ref = {formRef}  
  //             formData={formDataAdmin}  
  //             schema={jsonSchemaPermisssion}
  //             uiSchema={uiSchemaPermission} 
  //             validator={validator}
  //             // widgets={CustomWidgets} 
  //             onChange={(e) => setFormDataAdmin(e.formData)}
  //             />
  //        </div>
  //     </>,
  //     "Review Data": 
  //     <>
  //       <Stack tokens={{ childrenGap: 15, padding: 10 }}>
  //        <Body1Stronger> Devices </Body1Stronger>
  //          <Stack tokens={{ childrenGap: 5 }}>
  //           {deviceFeatures.map((feature, index) => (
  //            <StackItem key={index}>
  //               <Stack horizontal tokens={{ childrenGap: 5 }}>
  //                 <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
  //                 <StackItem>{feature}</StackItem>
  //               </Stack>
  //            </StackItem>
  //           ))}
  //         </Stack>
  //       </Stack>
  //       <Stack tokens={{ childrenGap: 15, padding: 10 }}>
  //        <Body1Stronger>Automation</Body1Stronger>
  //          <Stack tokens={{ childrenGap: 5 }}>
  //           {automationFeatures.map((feature, index) => (
  //            <StackItem key={index}>
  //               <Stack horizontal tokens={{ childrenGap: 5 }}>
  //                 <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
  //                 <StackItem>{feature}</StackItem>
  //               </Stack>
  //            </StackItem>
  //           ))}
  //         </Stack>
  //       </Stack>
  //       <Stack tokens={{ childrenGap: 15, padding: 10 }}>
  //        <Body1Stronger>Automation</Body1Stronger>
  //          <Stack tokens={{ childrenGap: 5 }}>
  //           {appFeatures.map((feature, index) => (
  //            <StackItem key={index}>
  //               <Stack horizontal tokens={{ childrenGap: 5 }}>
  //                 <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
  //                 <StackItem>{feature}</StackItem>
  //               </Stack>
  //            </StackItem>
  //           ))}
  //         </Stack>
  //       </Stack>
  //       <Stack tokens={{ childrenGap: 15, padding: 10 }}>
  //         <Body1Stronger>Troubleshoot</Body1Stronger>
  //         <Stack tokens={{childrenGap:5}} horizontal>
  //           <StackItem><CheckmarkFilled {...primaryIcon} /></StackItem>
  //           <StackItem>Read and export deploy status.</StackItem>
  //         </Stack>
  //       </Stack>
  //     </>,
  //   },
  // };

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  },[]);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);  
  },[contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);
  useEffect(() => { 
    if (saveBtnClicked)
     {
      setOverlayOpen(false);
      handleBothSetMessage();
    }
    }, [saveBtnClicked]);
 
    const DismissButton: React.FC<ButtonProps> = (props) => {
      return (
        <Button
          onClick={()=>setSearchVal('')}
          {...props}
          appearance="transparent"
          icon={<Dismiss16Regular />}
          size="small"
        />
      );
    };
    
  return (
    ((true) ?
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Administration" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button 
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
      <Stack tokens={{childrenGap:20}} horizontal>
        <StackItem>
          {/* pages code starts here */}
          <FluentProvider theme={webLightTheme}>
          <Stack verticalAlign="center">
            <Stack tokens={{childrenGap:25}} horizontal>
                <StackItem> <Text/> </StackItem>
                <StackItem> 
                    <Title3> Administation | Roles </Title3>  
                </StackItem>
            </Stack>
  
            <Stack tokens={{childrenGap:20}}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
          
            <Stack horizontal>
              {/* SPACING BETWEEN INLINE AND PAGE */}
              <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
              <StackItem>
                <StackItem> <Text/> </StackItem>
                <Stack tokens={{childrenGap:1}}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
                <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                  <StackItem>
                    <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                    { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                  </StackItem>
                  <StackItem>
                    <StackItem>
                      <Stack horizontal tokens={{childrenGap:20}}>
                         <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn=
                            { 
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <Filter20Filled {...primaryIcon}/>
                                  <Body1> Filter </Body1> 
                                </Stack>
                              </MenuButton>
                            } itemVal = {
                            <>
                              <Body1Strong> Add a Filter </Body1Strong>
                              <Divider/>
                              <MenuList>
                                <Stack tokens={{childrenGap:5}}>
                                  <StackItem> <Text/> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                                  <StackItem> <Divider/> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{childrenGap:10}} horizontal>
                                    <StackItem> <Button appearance="primary"  onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>
                            }
                            />
                          </StackItem>

                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index);setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>

                         <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                           <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                             <Stack tokens={{childrenGap:5}} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                             </Stack>
                           </Button>
                         </StackItem>
                          
                      </Stack>
                    </StackItem>
                    <Stack tokens={{childrenGap:10}}><StackItem><Text/></StackItem><StackItem><Text/></StackItem></Stack>
                    {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> {totalRecords > 0 && <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1>} </StackItem> 
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                      </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>}
                  </StackItem>

                  <Admin_func  data={final_data} func={set_final_data}searchVal={searchVal} pageNo={(currentPageNo - 1) * 10} rowID={rowID} setRowID={setRowID} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} formDataRole={formDataRole} setFormDataRole={setFormDataRole} formDataAdmin={formDataAdmin} setFormDataAdmin={setFormDataAdmin} saveRoleDetails={saveRoleDetails} 
                  setDeleteDisabled={setDeleteDisabled} setSaveRoleDetails={setSaveRoleDetails} isFilter={isfilter} 
                  dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} editRoleDetail={editRoleDetail} setEditRoleDetail={setEditRoleDetail} isDeleteRole={isDeleteRole} setIsDeleteRole={setIsDeleteRole}
                  handleEditData={handleEditData} setExportDetails={setExportDetails}
                  setIsRefresh={setIsRefresh}setIsToast = {setIsToast} isRefresh={isRefresh} setRefreshToast={setRefreshToast}
                  setIsDeleteRoleToast={setIsDeleteRoleToast} isDeleteRoleToast={isDeleteRoleToast} setNewRole={setNewRole} createNewRole={createNewRole} setHandlePermission={setHandlePermission} handlePermission={handlePermission} />
                  {tableData.length > 0 && <DataTable tableData={tableData} columns={columns}/> }
                </Stack>
              </StackItem>
            </Stack>
            <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} isNavBtn={true} saveBtnFunc = {saveBtnFunc}/>
          </Stack>
          </FluentProvider>
        </StackItem>
   
          {createNewRole && ( <ToastComponent setmessagebar={setmessagebar2}isToastEnabled={messagebar2} toastIntent="success" position="top-end" title="Success" message="New Role Created Successfully" footer="" setProgressLoading={setProgressLoading} /> )}
          {isDeleteRoleToast && ( <ToastComponent setmessagebar={setmessagebar2}isToastEnabled={messagebar2}toastIntent="success" position="top-end"title="Success" message="Role Deleted Successfully" footer="" setProgressLoading={setProgressLoading} /> )}
          {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
          {overlayName == "edit" && editRoleDetail && <ToastComponent setmessagebar={setmessagebar2} isToastEnabled={messagebar2} toastIntent="success" position="top-end" title="Success" message="Role Edited Successfully" footer="" />}
        
        <StackItem><Text/></StackItem>
      </Stack>
    </FullScreen> :
    <Stack>Sorry No permission</Stack>)
  );
};