
import { Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme, SkeletonItem, Text } from '@fluentui/react-components';
import { skeleton10, skeleton4, skeleton7, skeleton8, skeleton9 } from '../../styles/customStyles';

export const SkeletonContent = () => {
    return (
        <>
            <FluentProvider theme={webLightTheme}>
                <Stack style={{ marginTop: 10, marginLeft: 28 }} tokens={{ childrenGap: 10 }}>
                    <SkeletonItem size={24} style={skeleton4} />
                    <SkeletonItem size={36} style={skeleton9} />
                    <Text />
                    <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <SkeletonItem size={24} style={skeleton10} />
                        <Stack tokens={{ childrenGap: 10 }}>
                            <SkeletonItem size={20} style={skeleton7} />
                            <SkeletonItem size={20} style={skeleton7} />
                            <SkeletonItem size={20} style={skeleton7} />
                            <SkeletonItem size={24} style={skeleton7} />
                            <SkeletonItem size={24} style={skeleton8} />
                        </Stack>
                    </Stack>
                </Stack>
            </FluentProvider>
        </>
    );
};