import { useState, useEffect, useContext, useRef } from 'react';
import { Configuration_func } from '../../../api/automation/configuration_func';
import { Selfhelp_func } from '../../../api/automation/selfhelp_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import DialogBox from '../../../components/dialog/dialog.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Option,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  createTableColumn,
  TableCellLayout,
  Input,
  Textarea,
  Switch,
  Card,
  Image,
} from '@fluentui/react-components';
import {
  SaveCopy20Regular,
  AddSquare20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  AddSquare20Filled,
  Delete20Regular,
  ReOrderDotsVertical16Regular,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  ChatMultipleHeart20Regular,
  BookTemplate20Regular,
  CalendarClock20Regular,
  SettingsCogMultiple20Regular,
  Wand20Regular,
  Info20Filled,
  Dismiss16Regular,
  CheckmarkLock20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { Inputs } from '../../../components/textarea/input.component';
import { Intent } from '../../../components/messagebar/messagebar.component';
import { Form } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../../components/form-components';
import { GlobalContext } from '../../../hooks/globalContext';
import { MultipleSelectToString } from '../../../components/form-components/MultipleSelectToString';
import { MultiselectWithTags } from '../../../components/dropdown/multiselect.component';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DragStart,
  DragUpdate,
  Droppable,
  DroppableStateSnapshot,
  DropResult,
  resetServerContext,
  ResponderProvided,
  useKeyboardSensor,
  useMouseSensor,
  useTouchSensor,
} from 'react-beautiful-dnd';
import { Dropdown } from '@fluentui/react-components';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, secondaryIcon } from '../../../styles/customStyles';
import type { TextareaProps } from '@fluentui/react-components';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

const iconStyleProps: any = {
  primaryFill: '#008099',
  className: 'iconClass',
};

const iconExitStyleProps: any = { primaryFill: '#d13438' };

export const Selfhelp = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('Intro');
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [automationOverLayData, setAutomationOverLayData]: any = useState([]);
  const [jsonSchema, setJsonData]: any = useState({});
  const [uiSchema, setUiData]: any = useState({});
  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [configFormData, setConfigFormData]: any = useState({
    clientEnabled: false,
    automation_name: '',
    description: '',
    category: '',
    category_description: '',
    sub_category: '',
    sub_category_description: '',
    os: '*',
    automation_sequence: '',
  });
  const [deleteAutomation, setDeleteAutomation]: any = useState(false);
  const [isRefresh, setRefresh]: any = useState(false);
  const [cloneForms, setCloneForms] = useState<any[]>([]);
  const [isToast, setIsToast] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [saveBtnClicked1, setSaveBtnClicked1]: any = useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [inputBoxes, setInputBoxes] = useState<any>([{ id: '1', value: `Input Box 1` }]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [automationSearchVal, setAutomationSearchVal] = useState('');
  const [automationSearchData, setAutomationSearchData] = useState([]);
  const [automationSearchDataObj, setAutomationSearchDataObj] = useState({});

  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<any[]>([]);
  const [categorySearchVal, setCategorySearchVal] = useState('');
  const [categorySearchData, setCategorySearchData] = useState([]);
  const [categoryData, setCategoryData] = useState<any>({});
  const [disableCatInput, setDisableCatInput] = useState<any>(true);

  const [selectedSubCategoryOptions, setSelectedSubCategoryOptions] = useState<any[]>([]);
  const [subCategorySearchVal, setSubCategorySearchVal] = useState('');
  const [subCategorySearchData, setSubCategorySearchData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState<any>({});
  const [disableSubCatInput, setDisableSubCatInput] = useState<any>(true);

  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [userActivityData, setUserActivityData] = useState({});
  const [tracker, setTracker] = useState(false);
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [automationStatus, setAutomationStatus] = useState('not attached');

  //drag states
  const handleDragEnd = (result: any) => {
    // Check if the drag operation was successful
    if (!result.destination) {
      return;
    }
    console.log('result', result);

    // Reorder the input boxes based on the drag result
    const reorderedBoxes = Array.from(inputBoxes);
    const [movedBox] = reorderedBoxes.splice(result.source.index, 1);
    reorderedBoxes.splice(result.destination.index, 0, movedBox);
    console.log('result', result.destination.index);
    console.log('reorder', reorderedBoxes);
    console.log('inputbox on drag', inputBoxes);
    console.log('cloneform on deag', cloneForms);

    setCloneForms(reorderedBoxes);
    // Update the state with the reordered input boxes
    setInputBoxes(reorderedBoxes);
    let id: any;
    setCloneForms(cloneForms.filter(form => form.id !== id));
  };

  // const handleNameInput: TextareaProps["onChange"] = (ev, data: any) => {
  //   var inputVal = data.value.trim();

  //   if(/^[a-zA-Z0-9]*$/.test(inputVal)){
  //     var flag = true;
  //     exportDetails.map((val: any) => {
  //       if(val.classificationName == inputVal){
  //         flag = false;
  //       }
  //     });
  //     setClassificationNameValidation(flag);
  //     setClassificationName(inputVal);
  //   }
  // }

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name) {
        flag = false;
      }
    });

    if (overlayName == 'editAutomation' && formData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, selectedAutomation]);

  var timer: any;
  const handleUserSearchInput = (e: any) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setAutomationSearchVal(e.target.value);
    }, 700);
  };

  //clone logic
  interface FormItem {
    id: number;
    data: {};
  }

  const handleAddClone = (e: any) => {
    if (cloneForms.length < 10) {
      setCloneForms([...cloneForms, { id: Date.now(), data: {} }]);
    }
  };

  console.log('automation search value', automationSearchVal);
  const handleRemoveClone = (id: any) => {
    console.log('id=', id);
    setCloneForms(cloneForms.filter(form => form.id !== id));
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const handlenextbutton = () => {
    setActiveTab('Configure');
    console.log('clicked but not worked');
  };
  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...iconStyleProps} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations/configuration')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => {
          localStorage.setItem('dartNo', '304');
          contextValue.setDartNo(304);
          navigate('/automate/automation/selfhelp');
        }}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <SettingsCogMultiple20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1>Self-Help </Caption1>
          </StackItem>
          <StackItem>
            <Stack tokens={{ childrenGap: 44 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>
          </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '69');
        contextValue.setDartNo(69);
        navigate('/automate/automation/autoheal');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Wand20Regular {...iconStyleProps} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Autoheal </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/automationschedule');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CalendarClock20Regular {...iconStyleProps} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automation Schedules </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '241');
        contextValue.setDartNo(241);
        navigate('/automate/automation/usersurvey');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ChatMultipleHeart20Regular {...iconStyleProps} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> User Surveys </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key={3}
      onClick={() => {
        navigate('/automate/automation/compliance_policies/policy_configuration');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckmarkLock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...iconStyleProps} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    {
      key: 'name',
      label: (
        <Body1Strong
          onClick={() => {
            setOverlayOpen(true);
          }}
        >
          Name
        </Body1Strong>
      ),
    },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  //data table present in the filter dropdown starts here
  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setOverlayOpen(true);
              setOverlayName('addAutomation');
              setAutomationStatus('not attached');
              setFormData({ name: '', description: '', jsonData: '' });
              setConfigFormData({
                clientEnabled: false,
                automation_name: '',
                description: '',
                category: '',
                category_description: '',
                sub_category: '',
                sub_category_description: '',
                os: '*',
                automation_sequence: '',
              });
              setSelectedCategoryOptions([]);
              setSelectedSubCategoryOptions([]);
              setSelectedOptions([]);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <AddSquare20Regular {...iconStyleProps} />
              </StackItem>
              <StackItem>
                <Caption1> Add Automation </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>
        <StackItem>
          <Divider vertical />
        </StackItem>
        {/* <StackItem>
        <Button appearance="subtle" size="small">
          <Stack tokens={{childrenGap:5}} horizontal>
            <StackItem> <SaveCopy20Regular {...iconStyleProps}/> </StackItem>
            <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
          </Stack>
        </Button>
      </StackItem>
      <StackItem>
        <Divider vertical/>
      </StackItem> */}
        <StackItem>
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {/* <Delete20Regular {...iconStyleProps} />  */}
                      {isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      <Caption1> Delete </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Kindly verify your intent to delete the automation."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setDeleteAutomation(true);
                        userActivity(
                          'Delete',
                          `{"automationNames":"${contextValue.selectedRowIdInTable}","summary":"${contextValue.selectedRowIdInTable.length} selfhelp automations deleted"}`,
                        );
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>
        <StackItem>
          <Divider vertical />
        </StackItem>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...iconStyleProps} />
              </StackItem>
              <StackItem>
                <Caption1
                  onClick={() => {
                    setRefresh(true);
                  }}
                >
                  Refresh
                </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="selfh-help/proactive_automations.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"Self help automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...iconStyleProps} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
        {/* <StackItem>
        <Button appearance="subtle" size="small">
          <Stack tokens={{childrenGap:5}} horizontal>
            <StackItem> <ArrowDownload20Regular {...iconStyleProps}/> </StackItem>
            <StackItem> <Caption1> Export </Caption1> </StackItem>
          </Stack>
        </Button>
      </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  var tempTableData: any = [];
  var menuLists: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modified: val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-',
          status: status,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    var status = '';

    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map(async (val: any, i: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'Not attached' ? 'show' : 'hide',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={() => {
                    setOverlayOpen(true);
                    setOverlayName('editAutomation');
                    setRowID(val.id);
                    val.status.includes(`${contextValue.tenantName}:`) ? setAutomationStatus('attached') : setAutomationStatus('not attached');
                    setSelectedAutomation(val.automationName);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {val.createdOn ? new Date(val.createdOn * 1000).toLocaleString() : '-'} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    console.log('disableCatInput=', disableCatInput);
    console.log('disableSubCatInput=', disableSubCatInput);
  }, [disableCatInput, disableSubCatInput]);

  useEffect(() => {
    if (saveBtnClicked) {
      setFormData({
        ...formData,
        jsonData: {
          clientEnabled: configFormData.clientEnabled,
          automation_name: configFormData.automation_name,
          description: configFormData.description,
          category: selectedCategoryOptions.length > 0 ? selectedCategoryOptions[0].trim() : '',
          category_description: configFormData.category_description,
          sub_category: selectedSubCategoryOptions.length > 0 ? selectedSubCategoryOptions[0].trim() : '',
          sub_category_description: configFormData.sub_category_description,
          os: configFormData.os,
          automation_sequence: configFormData.automation_sequence,
        },
      });

      setSaveBtnClicked1(true);
      setTimeout(() => {
        setSaveBtnClicked(false);
      }, 1000);
    } else {
      setSaveBtnClicked1(false);
      setFormData({ name: '', description: '', jsonData: '' });
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    console.log('CategoryOptions=', selectedCategoryOptions);
    console.log('Object.keys(categoryData)=', categoryData);

    if (selectedCategoryOptions?.length > 0 && Object.keys(categoryData).length == 0) {
      console.log('cat iff');

      setConfigFormData({ ...configFormData, category_description: '' });
    } else {
      console.log('cat else');
      setConfigFormData({ ...configFormData, category_description: categoryData[`${selectedCategoryOptions[0]}`]?.desc });
    }
  }, [selectedCategoryOptions]);

  useEffect(() => {
    console.log('selectedSubCategoryOptions=', selectedSubCategoryOptions);
    console.log('Object.keys(subCategoryData)=', subCategoryData);

    if (selectedSubCategoryOptions?.length > 0 && Object.keys(subCategoryData).length == 0) {
      console.log('new iff');

      setConfigFormData({ ...configFormData, sub_category_description: '' });
    } else {
      console.log('new else');
      setConfigFormData({ ...configFormData, sub_category_description: subCategoryData[`${selectedSubCategoryOptions[0]}`]?.desc });
    }
  }, [selectedSubCategoryOptions]);

  useEffect(() => {
    console.log('configFormData=', configFormData);
  }, [configFormData]);

  useEffect(() => {
    if (dartSpecs?.profile_DartTemplateDetails?.length > 0) {
      var details = dartSpecs.profile_DartTemplateDetails.length > 0 ? dartSpecs.profile_DartTemplateDetails[0].details : '{}';
      details = JSON.parse(details);

      overlayName == 'addAutomation' &&
        setAutomationOverLayData({
          headerTitle: 'Add a New Automation',
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myFormSelfhelp" onSubmit={handleSubmit}>
                <Stack tokens={{ childrenGap: 10, padding: 12 }}>
                  <StackItem>
                    <p>
                      <b>
                        Name
                        <Image src="/visualization/v2/image/required.svg" />
                      </b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                    </p>
                    {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>What does this Selfhelp Do?</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormData} inputValue={formData} />
                    </p>
                  </StackItem>

                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...iconStyleProps} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These are the basic details associated with this automation. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configure: (
              <form id="myFormSelfhelp" onSubmit={handleSubmit}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem>
                        <Body1Strong>Should this automation be visible on the end user's device? </Body1Strong>
                      </StackItem>

                      <StackItem>
                        <Switch
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, clientEnabled: e.target.checked });
                          }}
                          checked={configFormData.clientEnabled ? true : false}
                          label={configFormData.clientEnabled ? 'Enabled' : 'Disabled'}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Automation Name to be showcased on the client interface <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Input
                      value={configFormData.automation_name}
                      onChange={(e: any) => {
                        setConfigFormData({ ...configFormData, automation_name: e.target.value });
                      }}
                      required
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Description for Automation to be showcased on the client interface
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      resize="both"
                      value={configFormData.description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, description: e.target.value })}
                      required
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Category
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <MultiselectWithTags
                      onChange={(e: any) => setCategorySearchVal(e.target.value)}
                      options={categorySearchData}
                      setSelectedOptions={setSelectedCategoryOptions}
                      selectedOptions={selectedCategoryOptions}
                      multi={false}
                      tag={true}
                      placeholder="Please Search"
                      required={selectedCategoryOptions.length > 0 ? false : true}
                    />

                    {selectedCategoryOptions?.length > 0 &&
                    categoryData[`${selectedSubCategoryOptions[0]}`]?.types == 'proactive' &&
                    configFormData.clientEnabled ? (
                      <Text>type mismatch</Text>
                    ) : (
                      ''
                    )}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Category Description
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      disabled={disableCatInput ? true : false}
                      resize="both"
                      value={configFormData.category_description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, category_description: e.target.value })}
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>Sub-Category</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <MultiselectWithTags
                      onChange={(e: any) => setSubCategorySearchVal(e.target.value)}
                      options={subCategorySearchData}
                      setSelectedOptions={setSelectedSubCategoryOptions}
                      selectedOptions={selectedSubCategoryOptions}
                      multi={false}
                      tag={true}
                      placeholder="Please Search"
                    />

                    {selectedSubCategoryOptions?.length > 0 &&
                    subCategoryData[`${selectedSubCategoryOptions[0]}`]?.types == 'proactive' &&
                    configFormData.clientEnabled ? (
                      <Text>Hi</Text>
                    ) : (
                      ''
                    )}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>Sub-Category Description</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      disabled={disableSubCatInput}
                      resize="both"
                      value={configFormData.sub_category_description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, sub_category_description: e.target.value })}
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      OS
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>

                  <StackItem>
                    <Dropdown
                      placeholder="Select"
                      defaultValue={
                        configFormData.os == 7
                          ? 'Windows 7'
                          : configFormData.os == 8
                          ? 'Windows 8'
                          : configFormData.os == 10
                          ? 'Windows 10'
                          : configFormData.os == 11
                          ? 'Windows 11'
                          : 'Common'
                      }
                      defaultSelectedOptions={[configFormData.os]}
                    >
                      <Option value="7" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '7' })}>
                        Windows 7
                      </Option>
                      <Option value="8" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '8' })}>
                        Windows 8
                      </Option>
                      <Option value="10" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '10' })}>
                        Windows 10
                      </Option>
                      <Option value="11" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '11' })}>
                        Windows 11
                      </Option>
                      <Option value="*" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '*' })}>
                        Common
                      </Option>
                    </Dropdown>
                  </StackItem>

                  <StackItem>
                    {/* <Intent title="" message="Choose the automations to incorporate into the SelfHelp solution. Please select them in the exact order of execution for a smooth 
                 operation, ensuring both efficiency and effectiveness">
              </Intent> */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...iconStyleProps} />
                          </StackItem>
                          <StackItem>
                            <Caption1>
                              Choose the automations to incorporate into the SelfHelp solution. Please select them in the exact order of execution for
                              a smooth operation, ensuring both efficiency and effectiveness.
                            </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Automations
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <StackItem>{/* <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>}/> */}</StackItem>
                    <StackItem>
                      <MultiselectWithTags
                        onChange={(e: any) => handleUserSearchInput(e)}
                        options={automationSearchData}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions}
                        multi={true}
                        tag={true}
                        placeholder="Please Search"
                        required={configFormData.automation_sequence != '' ? false : true}
                      />
                    </StackItem>
                    <StackItem>
                      {/* <Button appearance="subtle" aria-label="Close" icon={<AddSquare20Filled/>} onClick={handleAddClone}></Button> */}
                    </StackItem>
                  </Stack>

                  <Stack tokens={{ childrenGap: 15 }}>
                    {cloneForms.map((val: any) => (
                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <StackItem>
                          <MultiselectWithTags
                            options={automationSearchData}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}
                            multi={false}
                            tag={false}
                          />
                        </StackItem>
                        <StackItem>
                          <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Delete20Regular />}
                            onClick={() => handleRemoveClone(val.id)}
                          ></Button>
                        </StackItem>
                      </Stack>
                    ))}
                  </Stack>

                  {/* drag and drop code for automation starts here*/}
                  {/* <div className="container">
              <div className="card">
                <DragDropContext onDragEnd = {(result)=>handleDragEnd(result)}>
                  <Droppable droppableId="input-boxes" direction="vertical">
                    {(provided) => 
                    (
                      <div {...provided.droppableProps} ref={provided.innerRef} >
                        <Stack tokens={{childrenGap:10}}>
                          {inputBoxes.map((inputBox: any, index: any) => (   
                          <Draggable
                          key={inputBox.id} draggableId={inputBox.id} index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Stack tokens={{childrenGap:10}} >
                                  <Stack key={inputBox.id}>
                                    <Stack horizontal tokens={{childrenGap:5}}>
                                      <StackItem>
                                        <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>} {...provided.dragHandleProps}/>
                                      </StackItem>
                                      <StackItem>
                                        <MultiselectWithTags options={array} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={false} tag={false} /> 
                                      </StackItem>
                                      <StackItem>
                                        {index === 0 ? <Button  appearance="subtle" aria-label="Close" icon={<AddSquare20Filled/>} onClick={handleAddClone}></Button> :  <Button appearance="subtle" aria-label="Close" icon={<Delete20Regular/>} onClick={()=>handleRemoveClone(inputBox.id)}/>}
                            
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </div>
                            )}
                          </Draggable>
                          ))}
                          {provided.placeholder}
                        </Stack>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div> */}
                  {/* drag and drop code for automation ends here*/}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Body1Strong>What does this Selfhelp Do?</Body1Strong>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem>
                      <Body1Strong>Should this automation be visible onthe end user's device in Microsoft environment?</Body1Strong>
                    </StackItem>

                    <StackItem>
                      <Switch
                        disabled={true}
                        checked={configFormData.clientEnabled ? true : false}
                        label={configFormData.clientEnabled ? 'Enabled' : 'Disabled'}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automation Name to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Input disabled={true} value={configFormData.automation_name} />
                </StackItem>

                <StackItem>
                  <Body1Strong>Description for Automation to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.description} size="small" />
                </StackItem>

                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Category</Body1Strong>
                  </StackItem>
                )}
                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.category_description} size="small" />
                </StackItem>

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Sub-Category</Body1Strong>
                  </StackItem>
                )}

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedSubCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Sub-Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.sub_category_description} size="small" />
                </StackItem>

                <StackItem>
                  <Body1Strong>OS</Body1Strong>
                </StackItem>
                <StackItem>
                  <Dropdown
                    disabled={true}
                    placeholder="Select"
                    defaultValue={
                      configFormData.os == 7
                        ? 'Windows 7'
                        : configFormData.os == 8
                        ? 'Windows 8'
                        : configFormData.os == 10
                        ? 'Windows 10'
                        : configFormData.os == 11
                        ? 'Windows 11'
                        : 'Common'
                    }
                    defaultSelectedOptions={[configFormData.os]}
                  >
                    <Option value="7">Windows 7</Option>
                    <Option value="8">Windows 8</Option>
                    <Option value="10">Windows 10</Option>
                    <Option value="11">Windows 11</Option>
                    <Option value="*">Common</Option>
                  </Dropdown>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automations</Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem>{/* <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>}/> */}</StackItem>
                  <StackItem>
                    {/* <MultiselectWithTags disabled={true} options={automationSearchData} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={true} tag={true} />  */}
                    {selectedOptions?.map((val: any) => (
                      <Badge color="informative">{val}</Badge>
                    ))}
                  </StackItem>
                </Stack>

                {/* <Stack tokens={{childrenGap: 15}}>
                {cloneForms.map((val: any) => (
                  <Stack horizontal tokens={{childrenGap:5}}>
                    <StackItem>
                      <MultiselectWithTags disabled={true} options={automationSearchData} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={false} tag={false} /> 
                    </StackItem>
                  </Stack>
                ))}
              </Stack> */}
              </Stack>
            ),
          },
        });

      overlayName == 'editAutomation' &&
        automationStatus === 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `Edit ${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myFormSelfhelp" onSubmit={handleSubmit}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <p>
                      <b>
                        Name
                        <Image src="/visualization/v2/image/required.svg" />
                      </b>
                    </p>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                    </p>
                    {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>What does this Selfhelp Do?</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <p>
                      <Resize inputChange={setFormData} inputValue={formData} />
                    </p>
                  </StackItem>
                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configure: (
              <form id="myFormSelfhelp" onSubmit={handleSubmit}>
                <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                  <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <StackItem>
                        <Body1Strong>Should this automation be visible onthe end user's device in Microsoft environment?</Body1Strong>
                      </StackItem>

                      <StackItem>
                        <Switch
                          onChange={(e: any) => {
                            setConfigFormData({ ...configFormData, clientEnabled: e.target.checked });
                          }}
                          checked={configFormData.clientEnabled ? true : false}
                          label={configFormData.clientEnabled ? 'Enabled' : 'Disabled'}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Automation Name to be showcased on the client interface <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Input
                      value={configFormData.automation_name}
                      onChange={(e: any) => {
                        setConfigFormData({ ...configFormData, automation_name: e.target.value });
                      }}
                      required
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Description for Automation to be showcased on the client interface
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      resize="vertical"
                      value={configFormData.description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, description: e.target.value })}
                      required
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Category
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <MultiselectWithTags
                      onChange={(e: any) => setCategorySearchVal(e.target.value)}
                      options={categorySearchData}
                      setSelectedOptions={setSelectedCategoryOptions}
                      selectedOptions={selectedCategoryOptions}
                      multi={false}
                      tag={true}
                      placeholder="Please Search"
                      required
                    />

                    {selectedCategoryOptions?.length > 0 &&
                    categoryData[`${selectedSubCategoryOptions[0]}`]?.types == 'proactive' &&
                    configFormData.clientEnabled ? (
                      <Text>Hi</Text>
                    ) : (
                      ''
                    )}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Category Description <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      disabled={disableCatInput}
                      resize="vertical"
                      value={configFormData.category_description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, category_description: e.target.value })}
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>Sub-Category</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <MultiselectWithTags
                      onChange={(e: any) => setSubCategorySearchVal(e.target.value)}
                      options={subCategorySearchData}
                      setSelectedOptions={setSelectedSubCategoryOptions}
                      selectedOptions={selectedSubCategoryOptions}
                      multi={false}
                      tag={true}
                      placeholder="Please Search"
                    />

                    {selectedSubCategoryOptions?.length > 0 &&
                    subCategoryData[`${selectedSubCategoryOptions[0]}`]?.types == 'proactive' &&
                    configFormData.clientEnabled ? (
                      <Text>Hi</Text>
                    ) : (
                      ''
                    )}
                  </StackItem>

                  <StackItem>
                    <Body1Strong>Sub-Category Description</Body1Strong>
                  </StackItem>
                  <StackItem>
                    <Textarea
                      disabled={disableSubCatInput}
                      resize="vertical"
                      value={configFormData.sub_category_description}
                      size="small"
                      onChange={(e: any) => setConfigFormData({ ...configFormData, sub_category_description: e.target.value })}
                    />
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      OS
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>

                  <StackItem>
                    <Dropdown
                      placeholder="Select"
                      defaultValue={
                        configFormData.os == 7
                          ? 'Windows 7'
                          : configFormData.os == 8
                          ? 'Windows 8'
                          : configFormData.os == 10
                          ? 'Windows 10'
                          : configFormData.os == 11
                          ? 'Windows 11'
                          : 'Common'
                      }
                      defaultSelectedOptions={[configFormData.os]}
                    >
                      <Option value="7" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '7' })}>
                        Windows 7
                      </Option>
                      <Option value="8" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '8' })}>
                        Windows 8
                      </Option>
                      <Option value="10" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '10' })}>
                        Windows 10
                      </Option>
                      <Option value="11" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '11' })}>
                        Windows 11
                      </Option>
                      <Option value="*" onClick={(e: any) => setConfigFormData({ ...configFormData, os: '*' })}>
                        Common
                      </Option>
                    </Dropdown>
                  </StackItem>

                  <StackItem>
                    {/* <Intent title="" message="Choose the automations to incorporate into the SelfHelp solution. Please select them in the exact order of execution for a smooth 
               operation, ensuring both efficiency and effectiveness">
            </Intent> */}
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...iconStyleProps} />
                          </StackItem>
                          <StackItem>
                            <Caption1>
                              Choose the automations to incorporate into the SelfHelp solution. Please select them in the exact order of execution for
                              a smooth operation, ensuring both efficiency and effectiveness
                            </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  <StackItem>
                    <Body1Strong>
                      Automations
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1Strong>
                  </StackItem>

                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <StackItem>{/* <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>}/> */}</StackItem>
                    <StackItem>
                      <MultiselectWithTags
                        onChange={(e: any) => handleUserSearchInput(e)}
                        options={automationSearchData}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions}
                        multi={true}
                        tag={true}
                        placeholder="Please Search"
                      />
                    </StackItem>
                    <StackItem>
                      {/* <Button appearance="subtle" aria-label="Close" icon={<AddSquare20Filled/>} onClick={handleAddClone}></Button> */}
                    </StackItem>
                  </Stack>

                  <Stack tokens={{ childrenGap: 15 }}>
                    {cloneForms.map((val: any) => (
                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <StackItem>
                          <MultiselectWithTags
                            options={automationSearchData}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}
                            multi={false}
                            tag={false}
                          />
                        </StackItem>
                        <StackItem>
                          <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Delete20Regular />}
                            onClick={() => handleRemoveClone(val.id)}
                          ></Button>
                        </StackItem>
                      </Stack>
                    ))}
                  </Stack>

                  {/* drag and drop code for automation starts here*/}
                  {/* <div className="container">
            <div className="card">
              <DragDropContext onDragEnd = {(result)=>handleDragEnd(result)}>
                <Droppable droppableId="input-boxes" direction="vertical">
                  {(provided) => 
                  (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                      <Stack tokens={{childrenGap:10}}>
                        {inputBoxes.map((inputBox: any, index: any) => (   
                        <Draggable
                        key={inputBox.id} draggableId={inputBox.id} index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Stack tokens={{childrenGap:10}} >
                                <Stack key={inputBox.id}>
                                  <Stack horizontal tokens={{childrenGap:5}}>
                                    <StackItem>
                                      <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>} {...provided.dragHandleProps}/>
                                    </StackItem>
                                    <StackItem>
                                      <MultiselectWithTags options={array} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={false} tag={false} /> 
                                    </StackItem>
                                    <StackItem>
                                      {index === 0 ? <Button  appearance="subtle" aria-label="Close" icon={<AddSquare20Filled/>} onClick={handleAddClone}></Button> :  <Button appearance="subtle" aria-label="Close" icon={<Delete20Regular/>} onClick={()=>handleRemoveClone(inputBox.id)}/>}
                          
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </div>
                          )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div> */}
                  {/* drag and drop code for automation ends here*/}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Body1Strong>What does this Selfhelp Do?</Body1Strong>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem>
                      <Body1Strong>Should this automation be visible onthe end user's device in Microsoft environment?</Body1Strong>
                    </StackItem>

                    <StackItem>
                      <Switch
                        disabled={true}
                        checked={configFormData.clientEnabled ? true : false}
                        label={configFormData.clientEnabled ? 'Enabled' : 'Disabled'}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automation Name to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Input disabled={true} value={configFormData.automation_name} />
                </StackItem>

                <StackItem>
                  <Body1Strong>Description for Automation to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.description} size="small" />
                </StackItem>

                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Category</Body1Strong>
                  </StackItem>
                )}
                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.category_description} size="small" />
                </StackItem>

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Sub-Category</Body1Strong>
                  </StackItem>
                )}

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedSubCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Sub-Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.sub_category_description} size="small" />
                </StackItem>

                <StackItem>
                  <Body1Strong>OS</Body1Strong>
                </StackItem>
                <StackItem>
                  <Dropdown
                    disabled={true}
                    placeholder="Select"
                    defaultValue={
                      configFormData.os == 7
                        ? 'Windows 7'
                        : configFormData.os == 8
                        ? 'Windows 8'
                        : configFormData.os == 10
                        ? 'Windows 10'
                        : configFormData.os == 11
                        ? 'Windows 11'
                        : 'Common'
                    }
                    defaultSelectedOptions={[configFormData.os]}
                  >
                    <Option value="7">Windows 7</Option>
                    <Option value="8">Windows 8</Option>
                    <Option value="10">Windows 10</Option>
                    <Option value="11">Windows 11</Option>
                    <Option value="*">Common</Option>
                  </Dropdown>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automations</Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem>{/* <Button appearance="subtle" aria-label="Close" icon={<ReOrderDotsVertical16Regular/>}/> */}</StackItem>
                  <StackItem>
                    {/* <MultiselectWithTags disabled={true} options={automationSearchData} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={true} tag={true} />  */}
                    {selectedOptions?.map((val: any) => (
                      <Badge color="informative">{val}</Badge>
                    ))}
                  </StackItem>
                </Stack>

                {/* <Stack tokens={{childrenGap: 15}}>
              {cloneForms.map((val: any) => (
                <Stack horizontal tokens={{childrenGap:5}}>
                  <StackItem>
                    <MultiselectWithTags disabled={true} options={automationSearchData} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} multi={false} tag={false} /> 
                  </StackItem>
                </Stack>
              ))}
            </Stack> */}
              </Stack>
            ),
          },
        });

      overlayName === 'editAutomation' &&
        automationStatus !== 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Configuration: (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Body1Strong>What does this Selfhelp Do?</Body1Strong>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <StackItem>
                      <Body1Strong>Should this automation be visible onthe end user's device in Microsoft environment?</Body1Strong>
                    </StackItem>

                    <StackItem>
                      <Switch
                        disabled={true}
                        checked={configFormData.clientEnabled ? true : false}
                        label={configFormData.clientEnabled ? 'Enabled' : 'Disabled'}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automation Name to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Input disabled={true} value={configFormData.automation_name} />
                </StackItem>

                <StackItem>
                  <Body1Strong>Description for Automation to be showcased on the client interface</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.description} size="small" />
                </StackItem>

                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Category</Body1Strong>
                  </StackItem>
                )}
                {selectedCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.category_description} size="small" />
                </StackItem>

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Body1Strong>Sub-Category</Body1Strong>
                  </StackItem>
                )}

                {selectedSubCategoryOptions.length > 0 && (
                  <StackItem>
                    <Badge color="informative">{selectedSubCategoryOptions[0]}</Badge>
                  </StackItem>
                )}

                <StackItem>
                  <Body1Strong>Sub-Category Description</Body1Strong>
                </StackItem>
                <StackItem>
                  <Textarea disabled={true} resize="vertical" value={configFormData.sub_category_description} size="small" />
                </StackItem>

                <StackItem>
                  <Body1Strong>OS</Body1Strong>
                </StackItem>
                <StackItem>
                  <Dropdown
                    disabled={true}
                    placeholder="Select"
                    defaultValue={
                      configFormData.os == 7
                        ? 'Windows 7'
                        : configFormData.os == 8
                        ? 'Windows 8'
                        : configFormData.os == 10
                        ? 'Windows 10'
                        : configFormData.os == 11
                        ? 'Windows 11'
                        : 'Common'
                    }
                    defaultSelectedOptions={[configFormData.os]}
                  >
                    <Option value="7">Windows 7</Option>
                    <Option value="8">Windows 8</Option>
                    <Option value="10">Windows 10</Option>
                    <Option value="11">Windows 11</Option>
                    <Option value="*">Common</Option>
                  </Dropdown>
                </StackItem>

                <StackItem>
                  <Body1Strong>Automations</Body1Strong>
                </StackItem>

                <Stack horizontal tokens={{ childrenGap: 5 }}>
                  <StackItem>
                    {selectedOptions?.map((val: any) => (
                      <Badge color="informative">{val}</Badge>
                    ))}
                  </StackItem>
                </Stack>
              </Stack>
            ),
          },
        });
    }
  }, [
    dartSpecs,
    overlayName,
    cloneForms,
    formData,
    configFormData,
    automationSearchData,
    categorySearchData,
    subCategorySearchData,
    disableCatInput,
    disableSubCatInput,
    classificationNameValidation,
  ]);

  useEffect(() => {
    !overlayOpen && setOverlayName('');

    if (selectedOptions.length > 0) {
      var sequenceIdsArr: any = [];

      Object.keys(automationSearchDataObj).length > 0 &&
        selectedOptions.map(val => {
          if (!sequenceIdsArr.includes(automationSearchDataObj[`${val}`])) {
            sequenceIdsArr.push(automationSearchDataObj[`${val}`]);
          }
        });

      setConfigFormData({ ...configFormData, automation_sequence: sequenceIdsArr.join(',') });
    } else {
      setConfigFormData({ ...configFormData, automation_sequence: '' });
    }
  }, [overlayOpen, selectedOptions]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Selfhelp',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  useEffect(() => {
    if (saveBtnClicked1) {
      overlayName == 'addAutomation' &&
        userActivity(
          'Add',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(formData.jsonData)},"type":"Selfhelp","summary":"${
            formData.name
          } automation is created"}`,
        );

      overlayName == 'editAutomation' &&
        userActivity(
          'Edit',
          `{"automationName":"${formData.name}","configuration":${JSON.stringify(formData.jsonData)},"type":"Selfhelp","summary":"${
            formData.name
          } automation is edited"}`,
        );
    }
  }, [saveBtnClicked1]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Automation" subPageName="All Automation" subPageLink="/automate/automation/all_automations" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...iconExitStyleProps} /> : <ArrowMaximize20Regular {...iconStyleProps} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Selfhelp" /> </Text> */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Automation | Selfhelp </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      <Divider />
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              setSearchVal(e.target.value);
                            }}
                            placeholder="Search"
                            size="small"
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...iconStyleProps} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>

                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Badge appearance="filled" color="informative">
                                  <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Dismiss16Regular
                                        onClick={() => {
                                          handleCloseFilter(index);
                                        }}
                                      />
                                    </StackItem>
                                  </Stack>
                                </Badge>
                              ))}
                          </StackItem>

                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem>
                          <Caption1>
                            Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of
                            {totalRecords} Records
                          </Caption1>
                        </StackItem>
                        <StackItem>
                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack>
                    </StackItem>

                    {!saveBtnClicked && overlayName == '' && (
                      <Selfhelp_func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        deleteAutomation={deleteAutomation}
                        setDeleteAutomation={setDeleteAutomation}
                        setDeleteDisabled={setDeleteDisabled}
                        isRefresh={isRefresh}
                        setRefresh={setRefresh}
                        overlayName={overlayName}
                        setIsToast={setIsToast}
                        setDartSpecs={setDartSpecs}
                        setJsonData={setJsonData}
                        setUiData={setUiData}
                        setExportDetails={setExportDetails}
                        setAutomationSearchDataObj={setAutomationSearchDataObj}
                        automationSearchDataObj={automationSearchDataObj}
                      />
                    )}

                    {!saveBtnClicked && overlayName == 'editAutomation' && (
                      <Selfhelp_func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        deleteAutomation={deleteAutomation}
                        setDeleteAutomation={setDeleteAutomation}
                        setDeleteDisabled={setDeleteDisabled}
                        isRefresh={isRefresh}
                        setRefresh={setRefresh}
                        overlayName={overlayName}
                        setIsToast={setIsToast}
                        setDartSpecs={setDartSpecs}
                        rowID={rowID}
                        setFormData={setFormData}
                        setConfigFormData={setConfigFormData}
                        setSelectedCategoryOptions={setSelectedCategoryOptions}
                        setSelectedSubCategoryOptions={setSelectedSubCategoryOptions}
                        setSelectedOptions={setSelectedOptions}
                        setAutomationSearchDataObj={setAutomationSearchDataObj}
                        automationSearchDataObj={automationSearchDataObj}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {automationSearchVal.length > 0 && (
                      <Selfhelp_func
                        automationSearchVal={automationSearchVal}
                        setAutomationSearchData={setAutomationSearchData}
                        data={final_data}
                        func={set_final_data}
                        setAutomationSearchDataObj={setAutomationSearchDataObj}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        automationSearchDataObj={automationSearchDataObj}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {categorySearchVal.length > 0 && (
                      <Selfhelp_func
                        categorySearchVal={categorySearchVal}
                        setCategorySearchData={setCategorySearchData}
                        data={final_data}
                        func={set_final_data}
                        setCategoryData={setCategoryData}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        automationSearchDataObj={automationSearchDataObj}
                        setDisableCatInput={setDisableCatInput}
                        setDisableSubCatInput={setDisableSubCatInput}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {subCategorySearchVal.length > 0 && (
                      <Selfhelp_func
                        subCategorySearchVal={subCategorySearchVal}
                        setSubCategorySearchData={setSubCategorySearchData}
                        data={final_data}
                        func={set_final_data}
                        setSubCategoryData={setSubCategoryData}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        automationSearchDataObj={automationSearchDataObj}
                        setDisableCatInput={setDisableCatInput}
                        setDisableSubCatInput={setDisableSubCatInput}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {saveBtnClicked1 && overlayName != '' && (
                      <Selfhelp_func
                        newData={formData}
                        func={set_final_data}
                        saveBtnClicked={saveBtnClicked}
                        setSaveBtnClicked={setSaveBtnClicked1}
                        overlayName={overlayName}
                        setOverlayOpen={setOverlayOpen}
                        rowID={rowID}
                        setIsToast={setIsToast}
                        setOverlayName={setOverlayName}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        setFormData={setFormData}
                        formData={formData}
                        setConfigFormData={setConfigFormData}
                        setSelectedCategoryOptions={setSelectedCategoryOptions}
                        setSelectedSubCategoryOptions={setSelectedSubCategoryOptions}
                        setSelectedOptions={setSelectedOptions}
                        setAutomationSearchDataObj={setAutomationSearchDataObj}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} setOverlayOpen={setOverlayOpen}></DataTable>}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={automationOverLayData}
            saveBtnFunc={setSaveBtnClicked}
            isNavBtn={automationStatus !== 'not attached' ? false : true}
            handleOutsideButton={handleOutsideButton}
            handleSubmit={handleSubmit}
            classificationNameValidation={classificationNameValidation}
          />

          {isToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={isToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={
                !saveBtnClicked1 && overlayName == ''
                  ? 'Automation Deleted Successfully'
                  : overlayName == 'addAutomation'
                  ? 'New Automation Created Successfully'
                  : 'Automation Edited Successfully'
              }
              footer=""
            />
          )}

          {errorToast && (
            <ToastComponent setmessagebar={setErrorToast} toastIntent="error" position="top-end" title="Failed" message={toastMessage} footer="" />
          )}
        </StackItem>
      </FullScreen>
    </>
  );
};
