import { gql } from '@apollo/client';
const getPermisionListsQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int, $offset: Int) {
    supertokens_roles(where: $whereCondition, limit: $limit, offset: $offset) {
      role
      born
      last
      createdBy
      app_id
      roleSchema
      permissionSchema
    }
  }
`;

const getPermisionListCntsQuery = gql`
  query MyQuery($whereCondition: JSON) {
    supertokens_roles(where: $whereCondition) {
      role
      born
      last
      createdBy
      app_id
    }
  }
`;
const addPermission = gql`
  mutation MyMutation(
    $app_id: String = ""
    $benifit: String = ""
    $born: String = ""
    $impact: String = ""
    $role: String = ""
    $createdBy: String = ""
  ) {
    insert_supertokens_roles(objects: { app_id: $app_id, benifit: $benifit, impact: $impact, role: $role, born: $born, createdBy: $createdBy }) {
      returning {
        role
        born
        createdBy
      }
      affected_rows
    }
  }
`;

const addSchema = gql`
  mutation MyMutation(
    $permissionSchema: String = ""
    $roleSchema: String = ""
    $role: String = ""
    $creationTime: String = ""
    $benifit: String = ""
    $impact: String = ""
  ) {
    update_supertokens_roles(
      where: { role: { _eq: $role } }
      _set: { permissionSchema: $permissionSchema, roleSchema: $roleSchema, born: $creationTime, benifit: $benifit, impact: $impact }
    ) {
      affected_rows
      returning {
        role
      }
    }
  }
`;
const getSchema = gql`
  query MyQuery($roleId: String!) {
    supertokens_roles(where: { role: { _eq: $roleId } }) {
      role
      permissionSchema
      roleSchema
    }
  }
`;
const updateRole = gql`
  mutation Updateroles($role: String, $last: Int, $createdBy: String, $permissionSchema: String) {
    update_supertokens_roles(
      where: { role: { _eq: $role } }
      _set: { app_id: "public", last: $last, createdBy: $createdBy, permissionSchema: $permissionSchema }
    ) {
      affected_rows
      returning {
        last
        createdBy
        permissionSchema
      }
    }
  }
`;

const updateRolePermission = gql`
  mutation update_supertokens_role_permissions($role: String!, $old_permission: String!, $permission: String) {
    update_supertokens_role_permissions(
      where: { role: { _eq: $role }, permission: { _eq: $old_permission } }
      _set: { role: $role, permission: $permission }
    ) {
      affected_rows
      returning {
        role
        permission
      }
    }
  }
`;

const addNewRolePermission = gql`
  mutation insert_supertokens_role_permissions($role: String, $permission: String) {
    insert_supertokens_role_permissions(objects: { role: $role, permission: $permission }) {
      affected_rows
      returning {
        role
        permission
      }
    }
  }
`;

const getRolePermission = gql`
  query rolelists {
    supertokens_roles {
      role
    }
  }
`;
export {
  getPermisionListsQuery,
  addPermission,
  addSchema,
  getSchema,
  getPermisionListCntsQuery,
  updateRole,
  getRolePermission,
  updateRolePermission,
  addNewRolePermission,
};
