import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { Field, Textarea } from "@fluentui/react-components";

export const Resize = (props: any) => {
  var isDisabled = props.disabled != undefined ? props.disabled : false;
  const [value, setValue] = useState(props?.inputValue?.description);

  var timer: any;
  const handleInput = (data: any) => {
    setValue(data.target.value);
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      props.inputChange({...props?.inputValue, description: data.target.value});
    },100);
  };

  useEffect(() => {
    setValue(props?.inputValue?.description);
  }, [props?.inputValue?.description])

  return (
    <Stack>
      <Field>
        <Textarea disabled={isDisabled} resize="both" value={value} size="small" onChange={handleInput} required={props.required}/>
      </Field>
    </Stack>
  );
};