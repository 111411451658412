import React from "react";
import { Dropdown, Option, useId } from "@fluentui/react-components";
import { Stack, StackItem } from "@fluentui/react";

export const DropdownComponent = (props: any) => {
  const dropdownId = useId("dropdown-default");
  const [selectedVal, setSelectedVal] = React.useState(props?.defaultSelected ? props?.defaultSelected : '');
  
  return (
    <Stack>
      <StackItem>
        <Dropdown
          size="small"
          value={selectedVal}
          aria-labelledby={dropdownId}
          placeholder={props?.placeholder ? props.placeholder : "Select"}
          onOptionSelect ={(e: any) => {setSelectedVal(e.currentTarget.innerText); props.setDropDownValue(e.currentTarget.innerText)}}
          aria-required={props?.required ? true : false}
        >
          { props.optionData != undefined && props.optionData.map((option: any) => (
            <Option key={option.key} value={option.key}>
              {option.label}
            </Option>
          ))}
        </Dropdown>
      </StackItem>
    </Stack>
  );
};