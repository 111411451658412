import { useLocation } from "react-router-dom";
import { Stack, StackItem } from "@fluentui/react";
import { Calendar20Regular } from "@fluentui/react-icons";
import { Tab, TabList } from "@fluentui/react-components";
import type { SelectTabData, SelectTabEvent } from "@fluentui/react-components";

export const TablistComponent = (props: any) => {
  // const [selectedValue, setSelectedValue] = React.useState<TabValue>("tab1");
  // const [activeTab, setActiveTab] = React.useState<TabValue>("tab1");
 
  const location = useLocation();
  var tabKeys = Object.keys(props.tabListData);
  var tabValues = Object.values(props.tabListData);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    props.setSelectedValue(data.value);
    props.setActiveTab(data.value);
  };
 
  function disableTablistClick() {
    var Tablist = document.getElementById("11");
    Tablist?.addEventListener("click", function(event) { event.preventDefault(); event.stopPropagation(); });
    Tablist?.addEventListener("mouseenter", function() {if(Tablist) {Tablist.style.pointerEvents = 'none';}});
  }

  const excludedPaths = [ "/manage/troubleshoot/troubleshoot", "/manage/apps/appinstallstatus", "/manage/devices/activitylog", "/manage/apps/activitylogg", "/automate/automation/activitylog", "/manage/devices/device_classification/audit_log", "/manage/user/activitylog", "/manage/troubleshoot/status", "/manage/troubleshoot/alert","/manage/troubleshoot/compliance_policy"];
  if (!excludedPaths.includes(location.pathname)) { disableTablistClick(); }
  
  return (
    <Stack>
      <TabList id="11" selectedValue={props.selectedValue} onTabSelect={onTabSelect} defaultSelectedValue="tab1">
        {tabKeys.map((val: any, i: number) => {
          return (
            <Tab value={`tab${i + 1}`}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <Calendar20Regular/> </StackItem>
                <StackItem> {val} </StackItem>
              </Stack>
            </Tab>
          );
        })}
      </TabList>
      <Stack> {tabValues.map( (val: any, i: number) => props.selectedValue === `tab${i + 1}` && val )} </Stack>

      <Stack horizontal tokens={{ childrenGap: 5,padding:5 }}>
        {/* {props.navBtn &&  <> <StackItem> {props.activeTab !== "tab1" && <Button appearance="primary" size="small" onClick={handlePrevBtnClick}> Prev </Button>} </StackItem> <StackItem> {props.activeTab !== `tab${tabKeys.length}` && <Button appearance="primary" size="small" onClick={handleNextBtnClick}> Next </Button>} </StackItem> <StackItem> {props.activeTab == `tab${tabKeys.length}` && <Button appearance="primary" size="small"> Save </Button>} </StackItem> </> } */}
      </Stack>
    </Stack>
  );
};