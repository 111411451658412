import { gql } from '@apollo/client';

const getTroubleshooterQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int, $offset: Int = 0, $order: JSON) {
    core_AutomationJSON(where: $whereCondition, limit: $limit, offset: $offset, order_by: $order) {
      automationName
      dartid
      description
      subCategory
      category
      jsonValue
    }
  }
`;

const getTroubleshooterCntQuery = gql`
  query MyQuery($whereCondition: JSON) {
    core_AutomationJSON(where: $whereCondition) {
      automationName
      description
      category
      subCategory
      jsonValue
    }
  }
`;

const setExecuteTroubleShooter = gql`
  mutation AddRemoteJobs(
    $userId: Int = 0
    $OS: String! = "unknown"
    $type: String!
    $name: String!
    $Dart: Int = 286
    $variable: String!
    $shortDesc: String!
    $Jobtype: String! = "Interactive"
    $ProfileName: String!
    $NotificationWindow: Int = 0
    $ProfileOS: String! = "common"
  ) {
    AddRemoteJobs(
      userId: $userId
      OS: $OS
      type: $type
      name: $name
      Dart: $Dart
      variable: $variable
      shortDesc: $shortDesc
      Jobtype: $Jobtype
      ProfileName: $ProfileName
      NotificationWindow: $NotificationWindow
      ProfileOS: $ProfileOS
    ) {
      code
      Duplicates
      ProgServiceTag
      ServiceTagNotSupported
      ServiceTagSupported
      bid
      notifyRes
      onlineOffline
      searchtype
    }
  }
`;

export { getTroubleshooterQuery, getTroubleshooterCntQuery, setExecuteTroubleShooter };
