// import { Message } from './messagebar';
import React, { ReactNode, useEffect, useState } from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { Info20Filled, Eye20Filled, EyeOff20Filled, Checkmark16Filled, Dismiss16Filled } from '@fluentui/react-icons';
import {
  Button,
  Input,
  FluentProvider,
  webLightTheme,
  LargeTitle,
  Subtitle2Stronger,
  Body1,
  Body2,
  Subtitle2,
  Body1Strong,
  Image,
  PresenceBadge,
  Title1,
  Caption1Strong,
  Body1Stronger,
  Badge,
  Tooltip,
  Text,
  Link,
  Spinner,
} from '@fluentui/react-components';
import { getUsersListsCntQuery, userActiveQuery } from '../../graphql/users_queries';
import { sendPasswordResetEmail, submitNewPassword } from 'supertokens-web-js/recipe/emailpassword';
import { useNavigate } from 'react-router-dom';
import { ToastComponent } from '../../components/toast/toast.component';
import client from '../../api/apollo';
import { SkeletonPasswordReset } from '../../components/skeleton/skeletonPasswordReset';
import { SkeletonMailSent } from '../../components/skeleton/skeletonMailSent';
import { SkeletonNewPassword } from '../../components/skeleton/skeletonNewPassword';
import { backgroundPosition, formPositions, signLogo } from '../../styles/customStyles';

const iconStyleProps: any = { primaryFill: '#008099' };

interface User {
  [x: string]: ReactNode;
  email: string;
  password: string;
  reenteredPassword: string;
  magicLinkEmail: string;
  otp: string;
  phone: string;
}

const PasswordReset = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState<User>({ email: '', password: '', reenteredPassword: '', magicLinkEmail: '', otp: '', phone: '' });
  const [passwordResetMailSent, setpasswordResetMailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messagebar2, setmessagebar2] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [formerror, setFormError] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [emailError, setEmailError]: any = useState();
  const [exportDetails, setExportDetails]: any = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  let resetEmail = searchParams.get('email');
  console.log('resetemail', resetEmail);

  // const searchUser = (data: any) => {
  //   var searchData = exportDetails.filter((val: any) => val.user_email  == (data));
  //   console.log(data)

  //   console.log("searchData",searchData);
  //   console.log("searchdata length",searchData.length);
  //   if(searchData.length > 0){
  //     setEmailError(false);
  //     console.log("existing email", exportDetails);
  //   }
  //   else{
  //     setEmailError(true);
  //     setpasswordResetMailSent(false)
  //     // console.log( "no existing email",contextValue.existingUser);
  //   }
  // };

  const getUserList = async (whereCondition: any) => {
    try {
      const { data, loading, error } = await client.query({
        query: getUsersListsCntQuery,
        variables: { whereCondition },
        fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        setExportDetails(data.core_Users);
        // setTotalRecords(data.core_Users.length);
        // props.setTotalPages(Math.ceil((data.core_Users.length)/10));
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };
  getUserList(null);
  var menuLists: any = [];
  var tempExportData: any = [];
  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        tempExportData.push({
          id: val.userid,
          firstname: val.firstName,
          lastname: val.lastName,
          email: val.user_email,
          usertype: val.userType,
          createdon: val.Created_on,
          status: val.status,
        });
      });
    setExportData(tempExportData);
  }, [exportDetails]);

  const handleBothSetMessage = () => {
    setmessagebar2(true);
  };
  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };
  const handleConfirmPasswordFocus = () => {
    setConfirmPasswordFocused(true);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setShowPassword(!showPassword);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     [name]: value,
  //     passwordStrength: checkPasswordStrength(value)
  //     // Add password strength check
  //   }));
  // };

  const checkPasswordMatch = (user: any) => {
    const reenteredPassword = user.reenteredPassword;
    const password = user.password;
    console.log('checking password = ', password);
    console.log('checking re enter password = ', reenteredPassword);

    if (reenteredPassword === password && reenteredPassword !== '' && password !== '') {
      return (
        <Badge appearance="ghost" color="success" icon={<Checkmark16Filled />} iconPosition="after">
          <Caption1Strong> Matches </Caption1Strong>
        </Badge>
      );
    } else if ((reenteredPassword === '' || password === '') && reenteredPassword !== password) {
      return null; // Hide the message when both fields are empty or when one field was cleared
    } else {
      return (
        <Badge appearance="ghost" color="danger" icon={<Dismiss16Filled />} iconPosition="after">
          <Caption1Strong> Does not match </Caption1Strong>
        </Badge>
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // searchUser(e.target.value);
    setUser(prevUser => ({
      ...prevUser,
      [name]: value.trim(),
      passwordStrength: checkPasswordStrength(value.trim()),
      passwordMatch: checkPasswordMatch({ ...prevUser, [name]: value.trim() }),
    }));
  };

  const [pswStrengthFlag, setPswStrengthFlag] = useState(true);
  const checkPasswordStrength = (password: string) => {
    const strength = [/[A-Z]/.test(password), /[\W_]/.test(password), (password.match(/\d/g) || []).length >= 3, password.length >= 8].reduce(
      (acc, condition) => acc + (condition ? 1 : 0),
      0,
    );
    setPswStrengthFlag(false);
    if (strength === 0)
      return (
        <Badge appearance="ghost" color="severe">
          <Caption1Strong> Very Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 1)
      return (
        <Badge appearance="ghost" color="danger">
          <Caption1Strong> Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 2)
      return (
        <Badge appearance="ghost" color="warning">
          <Caption1Strong> Moderate </Caption1Strong>
        </Badge>
      );
    if (strength === 3)
      return (
        <Badge appearance="ghost" color="success">
          <Caption1Strong> Strong </Caption1Strong>
        </Badge>
      );
    setPswStrengthFlag(true);
    return (
      <Badge appearance="ghost" color="success">
        <Caption1Strong> Very Strong </Caption1Strong>
      </Badge>
    );
  };

  const sendEmailClicked = async () => {
    setIsLoading(true);
    setEmailError(false);
    setFormError('');
    console.log('Sending password email');

    try {
      const response = await sendPasswordResetEmail({ formFields: [{ id: 'email', value: user.email }] });
      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField: any) => {
          if (formField.id === 'email') {
            // window.alert(formField.error);
            setFormError(formField.error);
            setIsLoading(false);
          }
        });
      } else if (response.status === 'PASSWORD_RESET_NOT_ALLOWED') {
        // setErrorMessage('Password reset not allowed. Please check conditions.');
        setIsLoading(false);
        window.alert('Password reset not allowed. Please check conditions.');
      } else {
        // Check for existing user before setting passwordResetMailSent
        const searchData = exportDetails.filter((val: any) => val.user_email === user.email);
        if (searchData.length > 0) {
          setEmailError(false);
          console.log('existing email', exportDetails);
          setIsLoading(true);
          setpasswordResetMailSent(true);
        } else {
          setEmailError(true);
          setIsLoading(false);
        }
      }
    } catch (err) {
      // handleSuperTokensError(err);
      console.log('checking the error at forgot password email', err);
    }
  };

  const newPasswordEntered = async () => {
    if (user.password.length < 8) {
      setErrorMessage('Password must be atleast 8 characters long.');
    }
    if (user.reenteredPassword !== user.password) {
      return; // Return to prevent further execution
    }
    try {
      const response = await submitNewPassword({ formFields: [{ id: 'password', value: user.password }] });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField: any) => {
          if (formField.id === 'password') {
            // window.alert(formField.error);
            setErrorMessage(formField.error);
          }
        });
      } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        setTokenError(true);
        setmessagebar2(false);
        console.log('status =', 'checking -->', response.status);
        // window.alert('Password reset failed. Invalid token or expired link.');
        // window.location.assign('/');
        Navigate('/');
        // Redirect to login screen
      } else {
        // window.alert('Password reset successful!');
        // when password set make user as active
        // Redirect to login screen
        console.log('if all fine', 'success');
        setmessagebar2(true);
        userActive();
        // window.location.assign('/');
        Navigate('/');
      }
    } catch (err: any) {
      console.log('catch -->', err);
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        // window.alert(err.message);
        setErrorMessage(err.message);
      } else {
        // window.alert("Oops! Something went wrong with SSO.");
        setErrorMessage('Oops! Something went wrong with SSO.');
      }
    }
  };

  const userActive = async () => {
    const email = resetEmail;
    try {
      const { data } = await client.mutate({
        // fetchPolicy: "network-only"
        mutation: userActiveQuery,
        variables: { email },
      });
      if (data) {
      }
    } catch (error) {
      // Handle unexpected errors, log them, and potentially notify the user
      console.error('An unexpected error occurred:', (error as Error).message);
    }
  };

  useEffect(() => {
    const checkConnection = () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
    };
    checkConnection();
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <>
          {!token ? (
            <FluentProvider theme={webLightTheme}>
              <Stack>
                <StackItem>
                  <Stack horizontalAlign="start">
                    <StackItem grow shrink>
                      <Stack style={signLogo} tokens={{ padding: 10 }}>
                        <Image src="/visualization/v2/image/signinLogo.svg" />
                      </Stack>
                    </StackItem>

                    <StackItem grow shrink style={formPositions}>
                      <Stack tokens={{ childrenGap: 150 }}>
                        {!passwordResetMailSent ? (
                          <Stack tokens={{ childrenGap: 15 }}>
                            <StackItem>
                              <Stack wrap>
                                <Subtitle2Stronger align="justify">
                                  If you've forgotten your password, don't worry - we've got you covered.
                                </Subtitle2Stronger>
                              </Stack>
                            </StackItem>

                            <StackItem>
                              <Stack wrap>
                                <Body1 align="justify">
                                  Please enter your Email Address below, and we'll send you a secure link to reset your Password.
                                </Body1>
                              </Stack>
                            </StackItem>

                            <StackItem>
                              <Subtitle2> Email </Subtitle2>
                            </StackItem>
                            <Stack>
                              <Input
                                placeholder="Enter Email Here"
                                type="text"
                                id="email"
                                name="email"
                                value={user.email}
                                onChange={handleInputChange}
                              />
                            </Stack>
                            {formerror && (
                              <Badge appearance="ghost" color="danger">
                                <Body1> {formerror} </Body1>
                              </Badge>
                            )}
                            {emailError && (
                              <Badge appearance="ghost" color="danger">
                                <Body1> Email not found</Body1>
                              </Badge>
                            )}

                            <StackItem grow shrink>
                              <Button type="button" size="medium" appearance="primary" onClick={sendEmailClicked}>
                                Email Me
                              </Button>
                            </StackItem>
                            {isLoading && <Spinner delay={1000} size="extra-tiny" label="Please wait for a while...." />}
                            {/* {messagebar2 && (
                                            <ToastComponent
                                              // showProgressBar={true}
                                              setmessagebar={setmessagebar2}
                                              isToastEnabled={messagebar2}  
                                              position="top-end"
                                              footer=""
                                              toastIntent={
                                                user.email === '' 
                                                  ? "Warning" 
                                                  : user.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) 
                                                      ? "success" 
                                                      : "error"
                                              }
                                              title={
                                                user.email === '' 
                                                  ? "Warning" 
                                                  : user.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) 
                                                      ? "Success" 
                                                      : "Error"
                                              }
                                              message={
                                                user.email === '' 
                                                  ? "Field cannot be empty...!" 
                                                  : user.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) 
                                                      ? " " 
                                                      : "Invalid Email address...!"
                                              }
                                            />
                                          )} */}
                          </Stack>
                        ) : (
                          // <Title2> <PresenceBadge size="extra-large"/> Password reset link sent <br/> to your mail successfully.</Title2>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <Stack wrap tokens={{ childrenGap: 20 }}>
                              <StackItem>
                                <PresenceBadge size="extra-large" />
                                <Title1> Thank you. </Title1>
                              </StackItem>
                              <StackItem>
                                <Stack wrap tokens={{ childrenGap: 10 }}>
                                  {/* <StackItem>  */}
                                  <Body2 align="justify">
                                    We will send you a Password reset link, If the Email ID provided <b>{user.email}</b> matches the one we have on
                                    records. Kindly check your Email for the next steps. If the Email Address is incorrect,
                                    <Link
                                      onClick={() => {
                                        setpasswordResetMailSent(false);
                                      }}
                                    >
                                      <Body2> Click here </Body2>
                                    </Link>
                                    to make the necessary changes.
                                  </Body2>
                                  {/* </StackItem> */}

                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                      <StackItem> 1. </StackItem>
                                      <StackItem>
                                        <Body2 align="justify">
                                          Check your Junk/Spam message folder for an Email from <b>noreply@nanoheal.com</b>
                                        </Body2>
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                      <StackItem> 2. </StackItem>
                                      {/* <StackItem>  */}
                                      <Body2 align="justify">
                                        Make sure that the Email ID that you have entered matches the one used while registering.
                                      </Body2>
                                      {/* </StackItem> */}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Stack horizontalAlign="end">
                    <StackItem grow shrink style={backgroundPosition}>
                      <Image block src="/visualization/v2/signin_background.png" height={`100%`} />
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          ) : (
            <FluentProvider theme={webLightTheme}>
              <Stack>
                <StackItem>
                  <Stack horizontalAlign="start">
                    <StackItem grow shrink>
                      <Stack style={signLogo} tokens={{ padding: 10 }}>
                        <Image src="/visualization/v2/image/signinLogo.svg" />
                      </Stack>
                    </StackItem>
                    <StackItem grow shrink style={formPositions}>
                      <Stack tokens={{ childrenGap: 80 }}>
                        <StackItem>
                          <Stack tokens={{ childrenGap: 15 }}>
                            {tokenError && (
                              <Badge appearance="ghost" color="warning">
                                <Caption1Strong> Password reset failed. Invalid token or expired link. </Caption1Strong>
                              </Badge>
                            )}
                            <LargeTitle> Congratulations! </LargeTitle>
                            <StackItem>
                              <Stack wrap>
                                <StackItem>
                                  <Body1> Please proceed to set your new password. </Body1>
                                </StackItem>
                              </Stack>
                            </StackItem>

                            <StackItem>
                              <Body1Stronger> New Password </Body1Stronger>
                            </StackItem>
                            <Input
                              placeholder="Enter Password Here"
                              type={showPassword ? 'text' : 'password'}
                              id="password"
                              name="password"
                              value={user.password}
                              onChange={handleInputChange}
                              onFocus={handlePasswordFocus}
                              required
                              contentAfter={
                                <>
                                  {passwordFocused && (
                                    <Button
                                      size="small"
                                      appearance="transparent"
                                      onClick={handleTogglePassword}
                                      icon={
                                        <>
                                          {showPassword ? (
                                            <Tooltip withArrow content="Hide Password" relationship="label">
                                              <EyeOff20Filled />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip withArrow content="Show Password" relationship="label">
                                              <Eye20Filled />
                                            </Tooltip>
                                          )}
                                        </>
                                      }
                                    />
                                  )}
                                </>
                              }
                            />
                            <Body1Strong> Password Strength: {user.passwordStrength} </Body1Strong>

                            <StackItem>
                              <Body1Stronger> Re-enter Password </Body1Stronger>
                            </StackItem>
                            <Input
                              // pattern="^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9].*[0-9].*[0-9]).{8,}$"
                              placeholder="Re-Enter Password Here"
                              type={showConfirmPassword ? 'text' : 'password'}
                              id="reenteredPassword"
                              name="reenteredPassword"
                              value={user.reenteredPassword}
                              onChange={handleInputChange}
                              onFocus={handleConfirmPasswordFocus}
                              contentAfter={
                                <>
                                  {confirmPasswordFocused && (
                                    <Button
                                      size="small"
                                      appearance="transparent"
                                      onClick={handleToggleConfirmPassword}
                                      icon={
                                        <>
                                          {showConfirmPassword ? (
                                            <Tooltip withArrow content="Hide Password" relationship="label">
                                              <EyeOff20Filled />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip withArrow content="Show Password" relationship="label">
                                              <Eye20Filled />
                                            </Tooltip>
                                          )}
                                        </>
                                      }
                                    />
                                  )}
                                </>
                              }
                            />
                            <Body1Strong> Password Status: {user.passwordMatch} </Body1Strong>
                            {/* <StackItem> <u>Please read the below Instructions.</u></StackItem> */}

                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                              <StackItem>
                                <Info20Filled {...iconStyleProps} />
                              </StackItem>
                              {/* <StackItem>  */}
                              <Caption1Strong align="justify">
                                Create a password with at least 8 characters. Including a mix of uppercase, lowecase, special characters and at least
                                3 numbers . Avoid a common words and personal information. Keep your account safe by choosing a unique password.
                              </Caption1Strong>
                              {/* </StackItem> */}
                            </Stack>

                            <StackItem>
                              <Text></Text>
                            </StackItem>

                            <StackItem>
                              <Button type="button" appearance="primary" onClick={newPasswordEntered} onClickCapture={handleBothSetMessage}>
                                Continue
                              </Button>
                            </StackItem>

                            <FluentProvider theme={webLightTheme}>
                              {messagebar2 && (
                                <ToastComponent
                                  setmessagebar={setmessagebar2}
                                  isToastEnabled={messagebar2}
                                  footer=""
                                  toastIntent={
                                    user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                      ? 'warning'
                                      : 'success'
                                  }
                                  position="top-end"
                                  title={
                                    user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                      ? 'Warning'
                                      : 'Success'
                                  }
                                  message={
                                    user.password === '' || user.reenteredPassword === ''
                                      ? 'Field cannot be empty'
                                      : user.password.length < 8
                                      ? 'Password must be at least 8 characters long.'
                                      : user.reenteredPassword !== user.password || !pswStrengthFlag
                                      ? 'Please check the password'
                                      : 'Password reset successful!'
                                  }
                                />
                              )}
                            </FluentProvider>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Stack horizontalAlign="end">
                    <StackItem grow shrink style={backgroundPosition}>
                      <Image block src="/visualization/v2/signin_background.png" height={`100%`} />
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          )}
        </>
      ) : (
        <>{!token ? <> ( {!passwordResetMailSent ? <SkeletonPasswordReset /> : <SkeletonMailSent />} ) </> : <SkeletonNewPassword />}</>
      )}
    </>
  );
};

export default PasswordReset;
