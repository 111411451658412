import { Stack, StackItem } from '@fluentui/react';
import { MenuItem, MenuList, MenuGroup } from '@fluentui/react-components';
import { ChevronDoubleRight16Filled, ChevronDoubleLeft16Filled } from '@fluentui/react-icons';
import { primaryIcon } from '../../styles/customStyles';

export const SideMenu = (props: any) => {
    return(
    <>
      <Stack>
        { props.leftOpenSubMenu 
            ? 
              <MenuList>
                <MenuGroup>
                  <Stack horizontal>
                    <StackItem> {props.menuItems} </StackItem>
                    <Stack tokens={{padding:8}}>
                      <StackItem title="Hide the menu"> 
                        <ChevronDoubleLeft16Filled {...primaryIcon} onClick={() => props.setleftOpenSubMenu(!props.leftOpenSubMenu)}/> 
                      </StackItem>
                    </Stack>
                  </Stack>
              </MenuGroup>
            </MenuList>
          : 
            <MenuList>
              <StackItem>
                <MenuItem>
                  <Stack horizontal>
                    <StackItem title="Show the menu"> 
                      <ChevronDoubleRight16Filled onClick={() => props.setleftOpenSubMenu(!props.leftOpenSubMenu)}/> 
                    </StackItem>
                  </Stack>
                </MenuItem>
              </StackItem>
            </MenuList>
      }
    </Stack>
  </>
  )
}