import { useState, useEffect, useContext } from "react";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, ButtonProps } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular,Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, ArrowMaximize20Regular, DismissSquare24Filled } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { GlobalContext } from "../../../hooks/globalContext";
import { ToastComponent } from "../../../components/toast/toast.component";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { fullscreenIcon, lightTheme, primaryIcon, exitIcon } from "../../../styles/customStyles";
import { Policy_func } from "../../../api/devices/policy_func";
import { Remediations_func } from "../../../api/devices/remediations_func";
 
const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const CompliancePolicies = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  var tenantName = contextValue.tenantName;

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isRefresh, setRefresh]: any = useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [isToast, setIsToast] = useState(false);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);

  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }

   const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  useEffect(() => {
    if(filterArray.length > 0)
    setResetDisabled(false)
   
   }, [filterArray])

  // const handleEscapeKey = (event: KeyboardEvent) => {
  //   if (event.key === 'Escape') {
  //     setIsFullScreen(false);
  //     handle.exit();
  //   }
  // };

  // const handleCombinedClick = () => {
  //   handleFullScreen();
  //   // Assuming you want to manually trigger the escape logic as well
  //   // If not, you can remove the following line
  //   handleEscapeKey(new KeyboardEvent('keydown', { key: 'Escape' }));
  // };

  // useEffect(() => {
  //   // Attach the event listener when the component mounts
  //   window.addEventListener('keydown', handleEscapeKey);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('keydown', handleEscapeKey);
  //   };
  // }, [handle]);

  const [automationName, setAutomationName]: any = useState('');

  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }
 
  var menuItems: any = [];
 
  menuItems.push
  (
      <MenuItem onClick={() => navigate("/manage/devices/overview")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Info20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Overview </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification")} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <GroupList20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Device Classification </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/monitor")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <DesktopPulse20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Monitors </Caption1> </StackItem>
        </Stack>
      </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/remediation")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Wrench20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Remediations </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  ( 
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/manage/devices/compliancepolicies")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <ShieldQuestion20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/conditionalaccess")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <LockClosedKey20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Conditional Access </Caption1> </StackItem>
      </Stack>
    </MenuItem>  
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/usersurvey")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ChatMultipleHeart20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> User Survey </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/datacollection")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookDatabase20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Data Collection </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem  onClick={() => navigate("/manage/devices/activitylog")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "compliance_name", label: <Body1Strong> Name </Body1Strong>  },
    { key: "type", label: <Body1Strong> Type </Body1Strong> },
    { key: "created_by", label: <Body1Strong> Created By </Body1Strong> },
    { key: "created_on", label: <Body1Strong> Created On </Body1Strong> },
    { key: "last_modified", label: <Body1Strong> last Modified </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here
  //data table for filter
    var columns1 = [
      { key: "name", label: <Caption1> Name </Caption1>  },
      { key: "type", label: <Caption1> Type </Caption1> },
      { key: "created_by", label: <Caption1> Created By </Caption1> },
      { key: "created_on", label: <Caption1> Created On </Caption1> },
      { key: "last_modified", label: <Caption1> last Modified </Caption1> },
      { key: "status", label: <Caption1> Status </Caption1> },
      { key: "category", label: <Caption1> Category </Caption1> },
      { key: "sub_category", label: <Caption1> Sub Category </Caption1> },

      
    ];
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setRefresh(true); setCurrentPageNo(1)}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="remediationslist.csv">
            <Button appearance="subtle" size="small" onClick={() => {userActivity('Export', `{"summary":"Remediation list exported"}`); }}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];

  useEffect(() => {    
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      if(val.status == 'not attached'){
        status = 'Not attached';  
      }else{
        var curStatus = val.status?.split("|").filter((val: any) => val.includes(`${contextValue.tenantName}:`));

        if(curStatus.length == 0){
          status = 'Not attached';  
        }else{
          curStatus = curStatus[0]?.split(":")[1];
        
          if(curStatus == 'all'){
            status = 'Enabled for All Devices';
          }else{
            status = curStatus?.split(",").length == 1 ? `Enable for ${curStatus?.split(",").length} Device Classification` : `Enable for ${curStatus?.split(",").length} Device Classifications`;
          }
        }
      }

      tempExportData.push({
        compliance_name: val.automationName,
        type: val.types.charAt(0).toLocaleUpperCase() + val.types.slice(1),
        created_by: val.createdBy,
        created_on: new Date(val.createdOn * 1000).toLocaleString(),
        last_modified: new Date(val.lastModified * 1000).toLocaleString(),
        status: status,
      });
    });

    setExportData(tempExportData);
  }, [exportDetails]);
 
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=",final_data);
    var status = '';
    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any, i: any) => {
        if(val.status == 'not attached'){
          status = 'Not attached';  
        }else{
          var curStatus = val.status?.split("|").filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if(curStatus.length == 0){
            status = 'Not attached';  
          }else{
            curStatus = curStatus[0]?.split(":")[1];
          
            if(curStatus == 'all'){
              status = 'Enabled for All Devices';
            }else{
              status = curStatus?.split(",").length == 1 ? `Enable for ${curStatus?.split(",").length} Device Classification` : `Enable for ${curStatus?.split(",").length} Device Classifications`;
            }
          }
        }

        tempTableData.push({
          id: val.id,
          checkbox: 'hide',
          compliance_name: { label: <Button appearance="transparent" size="small" onClick={() => { navigate("/manage/devices/compliance_push");sessionStorage.setItem('policyid',val.dartid );sessionStorage.setItem('policyname',val.automationName); localStorage.setItem('automationDetails', JSON.stringify({ automationName: val.automationName, automationType: val.types })); contextValue.setDartNo(val.dartid); contextValue.setAutomationDetails({ ...contextValue.automationDetails, automationName: val.automationName, automationType: val.types });} }> {val.automationName} </Button >, id: `${i}` } ,
          type: { label: <Caption1> {val.types.charAt(0).toLocaleUpperCase() + val.types.slice(1)} </Caption1>, status: "" },
          created_by: { label: <Caption1> {val.createdBy} </Caption1>},
          created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1>},
          last_modified: { label: <Caption1> {val.lastModified? new Date(val.lastModified * 1000).toLocaleString():"-"} </Caption1>},
          status: { label: <Caption1> {status} </Caption1>, status: "" },
        });
      }) : (tempTableData = []);
    
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  const searchHandle = (data: any) => {
    var searchData = exportDetails.filter((val: any) => val.automationName.includes(data));

    var searchedDataObj: any = {core_AutomationJSON: []};
    searchedDataObj.core_AutomationJSON = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil((searchData.length)/50));
    setCurrentPageNo(1);
  };

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({pageName: 'Remediation list', moduleName: "Devices", action: action, time: Math.floor(Date.now() / 1000), activity: activity, status: status});
    
    setTracker(true);
  }
 
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
  <StackItem grow shrink>
    <Stack tokens={{childrenGap:10}} horizontal>
      <StackItem grow shrink>
        <Stack horizontalAlign="start">
          <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName=""/> </StackItem>
        </Stack>
      </StackItem>
      <StackItem grow shrink>
        <Stack horizontalAlign="end">
          <StackItem grow shrink> 
            <Button
              style={fullscreenIcon} 
              appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
              title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
              icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
            />
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  </StackItem>
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <StackItem>
            {/* <Text> <Breadcrumbs pageName="Devices" subPageName="Remediations" /> </Text> */}
          </StackItem>
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem> 
                <FluentProvider theme={webLightTheme}> 
                  <Title3> Devices | Compliance Policies </Title3> 
                </FluentProvider> 
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
          </Stack>
 
          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
         
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                  <Divider/>
                </StackItem>
                <StackItem>


                  <StackItem>
                      <Stack horizontal tokens={{childrenGap:20}}>
                      <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                        <StackItem>
                          <MenuDropdown open={filterMenuOpen} btn=
                          { 
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <Filter20Filled {...primaryIcon}/>
                                <Body1> Filter </Body1> 
                              </Stack>
                            </MenuButton>
                          } itemVal = {
                          <>
                            <Body1Strong> Add a Filter </Body1Strong>
                            <Divider/>
                            <MenuList>
                              <Stack tokens={{childrenGap:5}}>
                                <StackItem> <Text></Text> </StackItem>
                                <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                                <StackItem> <Divider/> </StackItem>
                                <StackItem> <Caption1> Value </Caption1> </StackItem>
                                {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                <StackItem> <Divider /> </StackItem>
                                <Stack tokens={{childrenGap:10}} horizontal>
                                  <StackItem> <Button appearance="primary" onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                  <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                </Stack>
                              </Stack>
                            </MenuList>
                          </>
                          }
                          />
                        </StackItem>
                         <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                        <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                          <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                            <Stack tokens={{childrenGap:5}} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>


                  <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                    <StackItem> <Caption1>  Showing {((currentPageNo-1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1> </StackItem>
                    <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                  </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>}
                </StackItem>

                <Policy_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo = {(currentPageNo - 1) * 50} setTotalPages = {setTotalPages} setTotalRecords = {setTotalRecords} isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} isRefresh={isRefresh} setRefresh={setRefresh} setExportDetails={setExportDetails} setRefreshToast={setRefreshToast} />
                {/* <Remediations_func 
                          data={final_data} 
                          func={set_final_data} 
                          pageNo={(currentPageNo - 1) * 50} 
                          setTotalPages={setTotalPages} 
                          setTotalRecords={setTotalRecords} 
                          isRefresh={isRefresh} 
                          setRefresh={setRefresh} 
                          setRefreshToast={setRefreshToast} 
                          setDeviceCnt={''} 
                          saveBtnClicked={''} 
                          setSaveBtnClicked={''} 
                          groupDeviceCnt={''} 
                          setGroupDeviceCnt={''} 
                          setPreviousSelectedGroups={''} 
                          setExportDetails={setExportDetails}
                          setPolicyPatch={setPolicyPatch}
                          policyPatch={policyPatch}
                        />  */}
                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
                {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}

                {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider>
        </Stack>
        <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census"/>
      </StackItem>
      
      <StackItem><Text></Text></StackItem>
      </Stack>
      </FullScreen>
    </>
  );
};