import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupsListQuery, attachRemediationQuery, getRemediationListQuery } from "../../graphql/remediation_queries";
import { getCensusListsQuery, getCensusListsCntQuery } from "../../graphql/census_queries";
import { getGroupIdOfTenantQuery, insertValueOfComponentQuery, updateValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getGroupListsQuery } from "../../graphql/groups_queries";
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { getGroupedDevicesListQuery } from "../../graphql/device_classification_devices_queries";
import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";
import { getAppMonitorBinaries } from '../../graphql/app_monitored_queries';
import { getMachineGroupUniq,updateVarvalue } from '../../graphql/data_collection_queries';

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    setBinaries?:any
    isBinaries?:any
    isSaveClicked?:any
    setSaveClicked?:any
    tableSelected?:any
}

const Data_Collection_func = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    var selectedapp = localStorage.getItem("selectedapp");
    const selectedIds=props.tableSelected;
    let parsedData:any = null; // Initialize parsedData as null initially
    let pairs:any;
    let formatedString:any;
    if (selectedapp !== null && selectedapp !== "") { // Check if selectedapp is not null or empty
        parsedData = JSON.parse(selectedapp); // Parse the JSON string
    }

    function generatePairs(desktopBinary:any, urlPattern:any) {
      const urlPatterns = urlPattern?.split(',').map((pattern: any) => pattern?.trim());
       pairs = urlPatterns?.map((pattern: any) => `${desktopBinary},${pattern +"*"}`);
       formatedString=pairs?.join("\n");
      if(urlPattern === "" || pairs === undefined){
        pairs= desktopBinary;
        formatedString=pairs
      }
      console.log("this is the required pairs",pairs);
      console.log("formated string",formatedString)
      return formatedString;
    }

    const nameValue = parsedData?.name;
    const urlPattern=parsedData?.url_pattern
    const binaries =parsedData?.desktop_binaries;
    const selectedId= parsedData?.rowid;
    console.log(selectedId);

    generatePairs(binaries,urlPattern);


    const groupsAttachedToRemediation = async (whereCondition: any, limit: any = null, offset: any = null) => {
      const  { data, loading, error } = await client.query({
        query: getRemediationListQuery,
        variables: {whereCondition, limit, offset},
        fetchPolicy: "network-only",
      });

      if(!loading && data){
        return data.core_AutomationJSON[0]?.status;
      }
    }

    const getGroupsList = async (whereCondition: any, limit: number, offset: number) => {
      var attachedConfigGroups: any = await groupsAttachedToRemediation({automationName: {_eq: contextValue.automationDetails.automationName}});

      if(attachedConfigGroups != 'not attached'){
        attachedConfigGroups = attachedConfigGroups?.split("|").filter((val: any) => val.includes(`${contextValue.tenantName}:`));
        
        if(attachedConfigGroups?.length > 0){
          attachedConfigGroups = attachedConfigGroups[0]?.split(":")[1];

          if(attachedConfigGroups.includes('all')){
            props.setPreviousSelectedGroups(['All Devices']);
          }else{
            props.setPreviousSelectedGroups(attachedConfigGroups.split(","));
          }
        }else{
          props.setPreviousSelectedGroups([]);
        }
      }else{
        props.setPreviousSelectedGroups([]);
      }

      const { data, loading, error } = await client.query({
        query: getGroupListsQuery,
        //variables: {limit, offest, whereCondition, order_by, username, mcatid}
        variables: {whereCondition, limit, offset},
        fetchPolicy: "network-only",
      });
  
      if(!loading && data){
        await getTenantDeviceCnt({site: {_eq: tenantName}});
          
        var defaultSeleted:any = [];

        data.core_DeviceClassification.map(async (val: any, i: any) => {
          await getGroupDeviceCnt({site: {_eq: tenantName}, deviceClassifications: {contains: val.classificationName}}, val.classificationName);
        });

        props.func(data);
      }
    };
    
    const getGroupsListCnt = async (whereCondition: any, limit: any, offest: any) => {
      const { data, loading, error } = await client.query({
        query: getGroupListsQuery,
        // variables: {limit, offest, whereCondition, order_by, username, mcatid}
        variables: {whereCondition, limit, offest},
        fetchPolicy: "network-only"
      });
    
      if(!loading && data){
        // props.setTotalRecords(data.core_DeviceClassification.length);
        // props.setTotalPages(Math.ceil((data.core_DeviceClassification.length)/50));
      }
    };

  //   const getBinaries=async(whereCondition:any)=>{
  //     const {data,loading,error}=await client.query({
  //       query : getAppMonitorBinaries,
  //       variables: {whereCondition},
  //       fetchPolicy: "network-only"
  //   });
  //   if(!loading && data){
  //     console.log(data,"data fetched");
  //     props.setBinaries(false);
  //   }
  // }

    const getTenantDeviceCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
      const { data, loading, error } = await client.query({
        query: getCensusListsCntQuery,
        variables: {whereCondition, limit, offset},
        fetchPolicy: 'network-only',
      });

      if(!loading && data){
        // props.setDeviceCnt(data.core_Census.length);
      }
    }

    const getGroupDeviceCnt = async (whereCondition: any, name: String, limit: any = null, offset: any = null) => {
      const { data, loading, error } = await client.query({
          query: getGroupedDevicesListQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
      });

      if(!loading && data){
        props.setGroupDeviceCnt((prev: any) => ({
          ...prev,
          [`${name}`]: data.core_Census.length,
        }));
      }
    }
  
    const getMgrpUniq= async()=>{
      
      const{data,loading,error}=await client.query({
        query:getMachineGroupUniq,
        variables:{selectedIds},
        fetchPolicy:'network-only'
      });

      if(!loading && data){
        console.log("mgroupuiq",data);
        props.setSaveClicked(false);
        const mgroupuniqArray = data?.core_MachineGroups?.map((group: any) => group.mgroupuniq);
        updateVarV(mgroupuniqArray,formatedString);
      }
      
    }

    const updateVarV=async(mgroupuniqArray: any[], formatedString:any)=>{
      mgroupuniqArray.forEach(async (mgroupuniq) => {
      const{data,loading,error}=await client.query({
        query:updateVarvalue,
        variables:{mgroupuniq,formatedString},
        fetchPolicy:'network-only'
      });
      
      if(!loading && data){
        console.log("mgroupuiq",data);
        // props.setSaveClicked(false);
      }
    });
    }

    useEffect(() => {    
        if(props.searchVal?.length > 0 && props.searchVal !== ''){
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}}, 50, props.pageNo);
         
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}}, null, 0);
        }else if(props.isRefresh){
          props.setRefresh(false);
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, 50, props.pageNo);
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, null, 0);
          props.setRefreshToast(true);  
          setTimeout(() => {
            props.setRefreshToast(false);  
          }, 3000);
        }
        else{
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, 50, props.pageNo);
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, null, 0);

          // props.isRefresh && props.setRefresh(false);
          
        }
        if(props.isSaveClicked){
          getMgrpUniq();

        }

        // if(props.isBinaries === true){
        //   console.log("i am inside git Binaries function call");
          
        //   getBinaries(selectedId);
        // }

    },[props.isRefresh, props.searchVal, props.saveBtnClicked, props.pageNo,props.isSaveClicked]);

    return (
        <></>
    )
}

export { Data_Collection_func }