import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery, deleteUnattachedSingleAutomationQuery } from "../../graphql/configuration_queries";
import { getdartTemplateQuery } from "../../graphql/all_automation_queries";
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    pageName?:any
}

const Configuration_func = (props: any) => {
  console.log("props new data=",props?.newData);
  
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;

  const getdartTemplateList = async (whereCondition: any) => {
    const { data, loading, error } = await client.query({
      query: getdartTemplateQuery,
      variables: {whereCondition}
    });

    if(!loading && data){
      props.setDartTemplatesList(data.profile_DartTemplateDetails);
    }
  };

  const deleteUnattachedAutomation = async (ids: any) => {
    // var dartid = parseInt(dartNo);

    const { data } = await client.mutate({
      mutation: deleteUnattachedSingleAutomationQuery,
      variables: { ids },
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteAutomation(false);
      props.setIsToast(true);
      getAutomationList({types: {_eq: 'automation'}}, 50, parseInt(props.pageNo));
    }
  }

  const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });
    
    if(!loading && data){
      if(props.overlayName == 'editAutomation'){
        console.log("edited value=",data.core_AutomationJSON[0]);
        
        props.setFormData({...props.formData,
          name: data.core_AutomationJSON[0]?.automationName,
          description: data.core_AutomationJSON[0]?.description,
          jsonData: JSON.parse(data.core_AutomationJSON[0]?.jsonValue)
        });
      }else {
        props.func(data);
      }
    }
  };

  const getAutomationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON?.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON?.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };

  const getJsonSchema = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
     // console.log("jsonSchemaData=",jsonSchemaData);

      data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
    }
  };

  const addAutomation = async (automationName: String, description: String, jsonVal: String, createdBy: String, dartid: number) => {
    const createdOn =  Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);
    var types: any = 'automation';
    var varValueObj: any = {};
    var varValues = '';

    varValueObj = jsonVal;
    if(dartid == 270){
      var compVal = varValueObj[`S00270InputParameters`] + ":" + varValueObj[`CleanApp`] + ":" + varValueObj[`Cleaner`];
       
      varValueObj = {...varValueObj, [`S00270InputParameters`]: compVal};

      delete varValueObj['CleanApp'];
      delete varValueObj['Cleaner'];
    }
    
    varValues = JSON.stringify(varValueObj);
    
    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid, varValues}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
      getAutomationList({dartid: {_eq: dartid}, types: {_eq: 'automation'}}, 50, parseInt(props.pageNo));
    }
  };

  const editAutomation = async (automationName: String, description: String, jsonVal: String, rowID: Number, dartid: Number) => {
    const lastModified: any = Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);
    var varValueObj: any = {};
    var varValues = '';

    varValueObj = jsonVal;
    if(dartid == 270){
      var compVal = varValueObj[`S00270InputParameters`] + ":" + varValueObj[`CleanApp`] + ":" + varValueObj[`Cleaner`];
       
      varValueObj = {...varValueObj, [`S00270InputParameters`]: compVal};

      delete varValueObj['CleanApp'];
      delete varValueObj['Cleaner'];
    }
    varValues = JSON.stringify(varValueObj);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid, varValues}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
      getAutomationList({dartid: {_eq: dartid}, types: {_eq: 'automation'}}, 50, parseInt(props.pageNo));
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };
    
  useEffect(() => {    
    var dartid = parseInt(dartNo);

    if(!(props.saveBtnClicked) && props.dropdownData === null){
      getdartTemplateList({display: {_eq: "yes"}, category: {_eq: "automation"}});
    }

    if(!(props.saveBtnClicked) && props.isRefresh == false && props.deleteAutomation == false && props.dropdownData !== null && parseInt(dartNo) !== 0){
      getJsonSchema(parseInt(dartNo));
      getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
      props.setRefresh(false); 
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }
    
    if(props.pageName == 'all-automation' && props.searchVal?.length == 0 ){
      getAutomationList({dartid: {}, types:{}}, 50, parseInt(props.pageNo));
      getAutomationListCnt({dartid: {}, types:{}}, 50, parseInt(props.pageNo));
    }
    
    if(props?.pageName == 'all-automation' && props.searchVal?.length > 0){
      getAutomationList({dartid: {}, automationName: {contains: props.searchVal}}, 50);
      getAutomationListCnt({dartid: {}, automationName: {contains: props.searchVal}}, 50);
    }
    
    if(props?.pageName !== 'all-automation' && props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
      getAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
      getAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    }
    // else if(props.isFilter == true){
    //  if(props.dropDownValue1 == 'Name'){
    //   getAutomationList({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, automationName: {contains: props.searchVal}});
    //  }else if(props.dropDownValue1 == 'Created by'){
    //   getAutomationList({dartid: {_eq: dartid}, createdBy: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, createdBy: {contains: props.searchVal}});
    //  }else if(props.dropDownValue1 == 'Status'){
    //   getAutomationList({dartid: {_eq: dartid}, status: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
    //   getAutomationListCnt({dartid: {_eq: dartid}, status: {contains: props.searchVal}});
    //  }
    // }
    
    else if(props.deleteAutomation && contextValue.selectedRowIdInTable.length > 0){
      deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
      console.log("props.newData=",props.newData);
      
      props.overlayName == 'addAutomation' && addAutomation(props.newData.name, props.newData.description, props.newData.jsonData, userName, dartNo);

      props.overlayName == 'editAutomation' && editAutomation(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID, dartNo);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editAutomation'){
      getAutomationList({dartid: {_eq: dartNo}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else{
      props.overlayName != 'addAutomation' && getAutomationList({types: {_eq: 'automation'}}, 50, parseInt(props.pageNo));

      props.overlayName == '' && getAutomationListCnt({types: {_eq: 'automation'}});
    }
    
  },[props.deleteAutomation, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo,props.isFilter, props.selectedValue]);

  return (
    <></>
  )
}

export {Configuration_func}