// import { Stack, StackItem } from '@fluentui/react'
// import { Button, Text, Input, FluentProvider, webLightTheme, SkeletonProps, Badge, Body1Stronger, Caption1Strong, LargeTitle, Tooltip, Body1Strong } from '@fluentui/react-components'
// import { Info20Filled, Eye20Filled, EyeOff20Filled,  Checkmark16Filled, Dismiss16Filled } from "@fluentui/react-icons";
// import { Image } from "@fluentui/react-components";
// import { submitNewPassword,} from 'supertokens-web-js/recipe/emailpassword';
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { SkeletonSignin } from '../../components/skeleton/skeletonSignin';
// import { backgroundPosition, formPositionss, primaryIcon, signLogo } from '../../styles/customStyles';
// import { ToastComponent } from '../../components/toast/toast.component';
// import client from '../../api/apollo';
// import { userActiveQuery } from '../../graphql/users_queries';

// interface User {
//   username: string;
//   password: string;
//   reenteredPassword:String;
// }

// export const ChangePassword = (props: Partial<SkeletonProps>) => {
//   const Navigate = useNavigate();

//   const [user, setUser] = useState<User>({ username: '', password: '',reenteredPassword: '' });
//   const [isOnline, setIsOnline] = useState(true);
//   const [tokenError, setTokenError] = useState(false);
//   const [messagebar2, setmessagebar2] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [passwordFocused, setPasswordFocused] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
//   const [showSuccess, setShowSuccess] = useState(false);
//   const [forgetPass, setForgetPass] = useState(false)
//   const [pswStrengthFlag, setPswStrengthFlag] = useState(true);
//   const [error, setError] = useState('');

//   const handleButtons = ()=> setShowSuccess(true);
//   const HandlePassword = () => setForgetPass(true);
//   const handlePasswordFocus = () => { setPasswordFocused(true); };
//   const handleBothSetMessage = () => { setmessagebar2(true) };
//   const handleConfirmPasswordFocus = () => { setConfirmPasswordFocused(true); };

//   const handleTogglePassword = () => {
//     setShowPassword(!showPassword);
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   const handleToggleConfirmPassword = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//     setShowPassword(!showPassword);
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setUser((prevUser) => ({
//         ...prevUser,
//         [name]: value.trim(),
//         passwordStrength: checkPasswordStrength(value.trim()),
//         passwordMatch: checkPasswordMatch({ ...prevUser, [name]: value.trim() }),
//     }));
//   };

//   useEffect(() => {
//     const checkConnection = () => {
//     const hasInternetConnection = navigator.onLine;
//       setIsOnline(hasInternetConnection);
//     };
//     checkConnection();
//     window.addEventListener('online', checkConnection);
//     window.addEventListener('offline', checkConnection);
//     return () => {
//       window.removeEventListener('online', checkConnection);
//       window.removeEventListener('offline', checkConnection);
//     };
//   }, []);

//   const checkPasswordMatch = (user: any) => {
//     const reenteredPassword = user.reenteredPassword;
//     const password = user.password;
//         console.log('checking password = ',password)
//         console.log('checking re enter password = ',reenteredPassword)
//         if (reenteredPassword === password && reenteredPassword !== "" && password !== "") {
//           return (
//             <Badge appearance="ghost" color="success" icon={<Checkmark16Filled />} iconPosition='after'>
//               <Caption1Strong> Matches </Caption1Strong>
//             </Badge>
//           );
//         }
//         else if ((reenteredPassword === "" || password === "") && (reenteredPassword !== password)) {
//           return null; // Hide the message when both fields are empty or when one field was cleared
//         }
//         else {
//           return (
//             <Badge appearance="ghost" color="danger" icon={<Dismiss16Filled />} iconPosition='after'>
//               <Caption1Strong> Does not match </Caption1Strong>
//             </Badge>
//           );
//         }
//     };
//   const checkPasswordStrength = (password: string) => {
//     const strength = [/[A-Z]/.test(password), /[\W_]/.test(password),
//     (password.match(/\d/g) || []).length >= 3, password.length >= 8].reduce((acc, condition) => acc + (condition ? 1 : 0), 0);
//     setPswStrengthFlag(false);
//     if (strength === 0) return ( <Badge appearance="ghost" color="severe"> <Caption1Strong> Very Weak </Caption1Strong> </Badge> )
//     if (strength === 1) return ( <Badge appearance="ghost" color="danger"> <Caption1Strong> Weak </Caption1Strong> </Badge> )
//     if (strength === 2) return ( <Badge appearance="ghost" color="warning"> <Caption1Strong> Moderate </Caption1Strong> </Badge> );
//     if (strength === 3) return ( <Badge appearance="ghost" color="success"> <Caption1Strong> Strong </Caption1Strong> </Badge> );
//     setPswStrengthFlag(true);
//     return ( <Badge appearance="ghost" color="success"> <Caption1Strong> Very Strong </Caption1Strong> </Badge> );
//   };

//   const userActive = async() => {
//     try {
//       const { data} = await client.mutate({
//         // fetchPolicy: "network-only"
//         mutation: userActiveQuery,
//         // variables: {email}
//       });
//       if (data) { }
//     }
//     catch (error) {
//       // Handle unexpected errors, log them, and potentially notify the user
//       console.error("An unexpected error occurred:", (error as Error).message);
//     }
//   }
//   const newPasswordEntered = async () => {
//     if (user.password.length < 8) { setErrorMessage("Password must be atleast 8 characters long."); }
//     if (user.reenteredPassword !== user.password) {
//       return; // Return to prevent further execution
//     }
//     try {
//       const response = await submitNewPassword({ formFields: [{ id: 'password', value: user.password }] });

//       if (response.status === 'FIELD_ERROR') {
//         response.formFields.forEach((formField:any) => {
//           if (formField.id === 'password') {
//             setErrorMessage(formField.error);
//           }
//         });
//       }
//       else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
//         setTokenError(true);
//         setmessagebar2(false);
//         console.log("status =","checking")
//         // window.alert('Password reset failed. Invalid token or expired link.');
//         window.location.assign('/');
//         // Redirect to login screen
//       }
//       else {
//         // when password set make user as active
//         // Redirect to login screen
//         console.log("if all fine","success")
//         setmessagebar2(true);
//         userActive()
//         window.location.assign('/');
//       }
//     }
//     catch (err: any) {
//       if (err.isSuperTokensGeneralError === true) {
//         // this may be a custom error message sent from the API by you.
//         // window.alert(err.message);
//         setErrorMessage(err.message);
//       }
//       else {
//         // window.alert("Oops! Something went wrong with SSO.");
//         setErrorMessage("Oops! Something went wrong with SSO.");
//       }
//     }
//   };

//   // const handleSubmit = (e: React.FormEvent) => {
//   //   e.preventDefault();
//   //   if (user.username === 'admin@nanoheal.com' && user.password === 'nanoheal@123') { Navigate ("/") }
//   //   else { alert ('Invalid credentials. Please try again.'); }
//   // };

//   return (
//     <>
//       { isOnline
//           ?
//               <FluentProvider theme={webLightTheme}>
//                  <Stack>
//                   <StackItem>
//                     <Stack horizontalAlign='start'>
//                       <StackItem grow shrink>
//                         <Stack  style={signLogo} tokens={{padding:10}}>
//                           <Image src="/visualization/v2/image/signinLogo.svg"/>
//                         </Stack>
//                       </StackItem>
//                       <StackItem grow shrink style={formPositionss}>
//                         <Stack tokens={{childrenGap:80}}>
//                           <StackItem>
//                             <Stack tokens={{childrenGap:15}}>
//                               {tokenError && (<Badge appearance="ghost" color="warning"> <Caption1Strong> Password reset failed. Invalid token or expired link. </Caption1Strong> </Badge>)}
//                               <LargeTitle> Change Password </LargeTitle>
//                                 <StackItem>
//                                   <Stack wrap>
//                                     {/* <StackItem> <Body1> Please proceed to set your new password. </Body1> </StackItem> */}
//                                   </Stack>
//                                 </StackItem>

//                                 <StackItem> <Body1Stronger> New Password </Body1Stronger> </StackItem>
//                                 <Input
//                                   placeholder='Enter Password Here'
//                                   type={showPassword ? 'text' : 'password'}
//                                   id='password'
//                                   name='password'
//                                   value={user.password}
//                                   onChange={handleInputChange}
//                                   onFocus={handlePasswordFocus}
//                                   required
//                                   contentAfter={
//                                     <>
//                                       {passwordFocused && (
//                                         <Button
//                                           size="small"
//                                           appearance='transparent'
//                                           onClick={handleTogglePassword}
//                                           icon={
//                                             <>
//                                               {showPassword
//                                                 ?
//                                                 <Tooltip withArrow content="Hide Password" relationship="label">
//                                                   <EyeOff20Filled/>
//                                                 </Tooltip>
//                                                 :
//                                                 <Tooltip withArrow content="Show Password" relationship="label">
//                                                   <Eye20Filled/>
//                                                 </Tooltip>
//                                               }
//                                             </>
//                                           }
//                                         />
//                                       )}
//                                     </>
//                                   }
//                                 />
//                                 {/* <Body1Strong> Password Strength: {user.passwordStrength} </Body1Strong>   */}

//                                 <StackItem> <Body1Stronger> Re-enter Password </Body1Stronger> </StackItem>
//                                   <Input
//                                     // pattern="^(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9].*[0-9].*[0-9]).{8,}$"
//                                     placeholder='Re-Enter Password Here'
//                                     type={showConfirmPassword ? 'text' : 'password'}
//                                     id='reenteredPassword'
//                                     name='reenteredPassword'
//                                     // value={user.reenteredPassword}
//                                     onChange={handleInputChange}
//                                     onFocus={handleConfirmPasswordFocus}
//                                     contentAfter={
//                                       <>
//                                         {confirmPasswordFocused && (
//                                           <Button
//                                             size="small"
//                                             appearance='transparent'
//                                             onClick={handleToggleConfirmPassword}
//                                             icon={
//                                               <>
//                                                 {showConfirmPassword
//                                                   ?
//                                                   <Tooltip withArrow content="Hide Password" relationship="label">
//                                                     <EyeOff20Filled/>
//                                                   </Tooltip>
//                                                   :
//                                                   <Tooltip withArrow content="Show Password" relationship="label">
//                                                     <Eye20Filled/>
//                                                   </Tooltip>
//                                                 }
//                                               </>
//                                             }
//                                           />
//                                         )}
//                                       </>
//                                     }
//                                   />
//                                 {/* <Body1Strong> Password Status: {user.passwordMatch} </Body1Strong> */}

//                                 <Stack tokens={{childrenGap:5}} horizontal>
//                                   <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
//                                   {/* <StackItem>  */}
//                                     <Caption1Strong align='justify'>
//                                       Create a password with at least 8 characters. Including a mix of uppercase, lowecase, special characters and at least 3 numbers . Avoid a common words and personal information. Keep your account safe by choosing a unique password.
//                                     </Caption1Strong>
//                                   {/* </StackItem> */}
//                                 </Stack>

//                                 <StackItem><Text></Text></StackItem>

//                                 <StackItem>
//                                   <Button
//                                     type="button"
//                                     appearance='primary'
//                                     onClick={newPasswordEntered}
//                                     onClickCapture={handleBothSetMessage}
//                                   >
//                                     Continue
//                                   </Button>
//                                 </StackItem>

//                                 <FluentProvider theme={webLightTheme}>
//                                   {messagebar2 &&
//                                     <ToastComponent
//                                       setmessagebar={setmessagebar2}
//                                       isToastEnabled={messagebar2}
//                                       footer=""
//                                       toastIntent={
//                                           user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password
//                                           ? "warning"
//                                           : "success"
//                                       }
//                                       position="top-end"
//                                       title={
//                                           user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password
//                                           ? "Warning"
//                                           : "Success"
//                                       }
//                                       message={
//                                           user.password === '' || user.reenteredPassword === ''
//                                           ? "Field cannot be empty"
//                                           : user.password.length < 8
//                                           ? "Password must be at least 8 characters long."
//                                           : user.reenteredPassword !== user.password
//                                           ? "Please check the password  or read the password instruciton"
//                                           : "Password reset successful!"
//                                       }
//                                     />
//                                   }
//                                 </FluentProvider>
//                               </Stack>
//                             </StackItem>
//                           </Stack>
//                         </StackItem>
//                       </Stack>
//                     </StackItem>
//                     <StackItem>
//                       <Stack horizontalAlign='end'>
//                         <StackItem grow shrink style={backgroundPosition}> <Image block src="/signin_background.png" height={`100%`}/>  </StackItem>
//                       </Stack>
//                   </StackItem>
//                  </Stack>
//                 </FluentProvider>
//           : <SkeletonSignin/>
//       }
//     </>
//   );
// };

// function setErrorMessage(arg0: string) {
//   throw new Error('Function not implemented.');
// }

import React, { ReactNode, useEffect, useState } from 'react';
import { Stack, StackItem } from '@fluentui/react';
import { Info20Filled, Eye20Filled, EyeOff20Filled, Checkmark16Filled, Dismiss16Filled } from '@fluentui/react-icons';
import {
  Button,
  Input,
  FluentProvider,
  webLightTheme,
  LargeTitle,
  Subtitle2Stronger,
  Body1,
  Body2,
  Subtitle2,
  Body1Strong,
  Image,
  PresenceBadge,
  Title1,
  Caption1Strong,
  Body1Stronger,
  Badge,
  Tooltip,
  Text,
  Link,
} from '@fluentui/react-components';
import { getUsersListsCntQuery, userActiveQuery } from '../../graphql/users_queries';
import { sendPasswordResetEmail, submitNewPassword } from 'supertokens-web-js/recipe/emailpassword';
import { useNavigate } from 'react-router-dom';
import { ToastComponent } from '../../components/toast/toast.component';
import client from '../../api/apollo';
import { SkeletonPasswordReset } from '../../components/skeleton/skeletonPasswordReset';
import { SkeletonMailSent } from '../../components/skeleton/skeletonMailSent';
import { SkeletonNewPassword } from '../../components/skeleton/skeletonNewPassword';
import { backgroundPosition, formPositions, primaryIcon, signLogo } from '../../styles/customStyles';
import Session from 'supertokens-web-js/recipe/session';

interface User {
  [x: string]: ReactNode;
  email: string;
  password: string;
  reenteredPassword: string;
  currentPassword: string;
}

export const ChangePassword = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState<User>({ email: '', password: '', reenteredPassword: '', currentPassword: '' });
  const [passwordResetMailSent, setpasswordResetMailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messagebar2, setmessagebar2] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [formerror, setFormError] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [emailError, setEmailError]: any = useState();
  const [exportDetails, setExportDetails]: any = useState([]);
  const [exportData, setExportData] = useState([]);

  const handleBothSetMessage = () => {
    setmessagebar2(true);
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };
  const handleConfirmPasswordFocus = () => {
    setConfirmPasswordFocused(true);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setShowPassword(!showPassword);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const checkPasswordMatch = (user: any) => {
    const reenteredPassword = user.reenteredPassword;
    const password = user.password;
    console.log('checking password = ', password);
    console.log('checking re enter password = ', reenteredPassword);
    if (reenteredPassword === password && reenteredPassword !== '' && password !== '') {
      return (
        <Badge appearance="ghost" color="success" icon={<Checkmark16Filled />} iconPosition="after">
          <Caption1Strong> Matches </Caption1Strong>
        </Badge>
      );
    } else if ((reenteredPassword === '' || password === '') && reenteredPassword !== password) {
      return null; // Hide the message when both fields are empty or when one field was cleared
    } else {
      return (
        <Badge appearance="ghost" color="danger" icon={<Dismiss16Filled />} iconPosition="after">
          <Caption1Strong> Does not match </Caption1Strong>
        </Badge>
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser(prevUser => ({
      ...prevUser,
      [name]: value.trim(),
      passwordStrength: checkPasswordStrength(value.trim()),
      passwordMatch: checkPasswordMatch({ ...prevUser, [name]: value.trim() }),
    }));
  };

  const [pswStrengthFlag, setPswStrengthFlag] = useState(true);
  const checkPasswordStrength = (password: string) => {
    const strength = [/[A-Z]/.test(password), /[\W_]/.test(password), (password.match(/\d/g) || []).length >= 3, password.length >= 8].reduce(
      (acc, condition) => acc + (condition ? 1 : 0),
      0,
    );
    setPswStrengthFlag(false);
    if (strength === 0)
      return (
        <Badge appearance="ghost" color="severe">
          <Caption1Strong> Very Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 1)
      return (
        <Badge appearance="ghost" color="danger">
          <Caption1Strong> Weak </Caption1Strong>
        </Badge>
      );
    if (strength === 2)
      return (
        <Badge appearance="ghost" color="warning">
          <Caption1Strong> Moderate </Caption1Strong>
        </Badge>
      );
    if (strength === 3)
      return (
        <Badge appearance="ghost" color="success">
          <Caption1Strong> Strong </Caption1Strong>
        </Badge>
      );
    setPswStrengthFlag(true);
    return (
      <Badge appearance="ghost" color="success">
        <Caption1Strong> Very Strong </Caption1Strong>
      </Badge>
    );
  };

  const newPasswordEntered = async () => {
    if (user.password.length < 8) {
      setErrorMessage('Password must be atleast 8 characters long.');
    }
    if (user.reenteredPassword !== user.password) {
      return; // Return to prevent further execution
    }
    try {
      const response = await submitNewPassword({ formFields: [{ id: 'password', value: user.password }] });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField: any) => {
          if (formField.id === 'password') {
            setErrorMessage(formField.error);
          }
        });
      } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        setTokenError(true);
        setmessagebar2(false);
        console.log('status =', 'checking');
        window.location.assign('/auth/change-password');
      } else {
        console.log('if all fine', 'success');
        setmessagebar2(true);
        // userActive()
        await Session.signOut();
        window.location.assign('/');
      }
    } catch (err: any) {
      console.log('catch -->', err);
      if (err.isSuperTokensGeneralError === true) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('Oops! Something went wrong with SSO.');
      }
    }
  };

  const userActive = async () => {
    const email = user.email;
    try {
      const { data } = await client.mutate({
        // fetchPolicy: "network-only"
        mutation: userActiveQuery,
        variables: { email },
      });
      if (data) {
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
    }
  };

  useEffect(() => {
    const checkConnection = () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
    };
    checkConnection();
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <FluentProvider theme={webLightTheme}>
          <Stack>
            <StackItem>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Stack style={signLogo} tokens={{ padding: 10 }}>
                    <Image src="/visualization/v2/image/signinLogo.svg" />
                  </Stack>
                </StackItem>
                <StackItem grow shrink style={formPositions}>
                  <Stack tokens={{ childrenGap: 80 }}>
                    <StackItem>
                      <Stack tokens={{ childrenGap: 15 }}>
                        {tokenError && (
                          <Badge appearance="ghost" color="warning">
                            <Caption1Strong> Password reset failed. Invalid token or expired link. </Caption1Strong>
                          </Badge>
                        )}
                        <LargeTitle> Change Password </LargeTitle>
                        <StackItem>
                          <Stack wrap>
                            <StackItem>
                              <Body1> Please proceed to set your new password. </Body1>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        {/* <StackItem> <Body1Stronger>Current Password </Body1Stronger> </StackItem>
                              <Input 
                                placeholder='Enter Password Here' 
                                type={showPassword ? 'text' : 'password'} 
                                id='password' 
                                name='password' 
                                value={user.password} 
                                onChange={handleInputChange} 
                                onFocus={handlePasswordFocus} 
                                required
                                contentAfter={
                                  <> 
                                    {passwordFocused && (
                                      <Button 
                                        size="small" 
                                        appearance='transparent'
                                        onClick={handleTogglePassword} 
                                        icon={
                                          <> 
                                            {showPassword 
                                              ? 
                                              <Tooltip withArrow content="Hide Password" relationship="label">
                                                <EyeOff20Filled/>
                                              </Tooltip>
                                              : 
                                              <Tooltip withArrow content="Show Password" relationship="label">
                                                <Eye20Filled/>
                                              </Tooltip>
                                            } 
                                          </>
                                        } 
                                      />
                                    )}
                                  </>
                                }
                              />  */}
                        <StackItem>
                          <Body1Stronger> New Password </Body1Stronger>
                        </StackItem>
                        <Input
                          placeholder="Enter Password Here"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          name="password"
                          value={user.password}
                          onChange={handleInputChange}
                          onFocus={handlePasswordFocus}
                          required
                          contentAfter={
                            <>
                              {passwordFocused && (
                                <Button
                                  size="small"
                                  appearance="transparent"
                                  onClick={handleTogglePassword}
                                  icon={
                                    <>
                                      {showPassword ? (
                                        <Tooltip withArrow content="Hide Password" relationship="label">
                                          <EyeOff20Filled />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip withArrow content="Show Password" relationship="label">
                                          <Eye20Filled />
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </>
                          }
                        />
                        <Body1Strong> Password Strength: {user.passwordStrength} </Body1Strong>

                        <StackItem>
                          <Body1Stronger> Re-enter Password </Body1Stronger>
                        </StackItem>
                        <Input
                          placeholder="Re-Enter Password Here"
                          type={showConfirmPassword ? 'text' : 'password'}
                          id="reenteredPassword"
                          name="reenteredPassword"
                          value={user.reenteredPassword}
                          onChange={handleInputChange}
                          onFocus={handleConfirmPasswordFocus}
                          contentAfter={
                            <>
                              {confirmPasswordFocused && (
                                <Button
                                  size="small"
                                  appearance="transparent"
                                  onClick={handleToggleConfirmPassword}
                                  icon={
                                    <>
                                      {showConfirmPassword ? (
                                        <Tooltip withArrow content="Hide Password" relationship="label">
                                          <EyeOff20Filled />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip withArrow content="Show Password" relationship="label">
                                          <Eye20Filled />
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </>
                          }
                        />
                        <Body1Strong> Password Status: {user.passwordMatch} </Body1Strong>

                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <Caption1Strong align="justify">
                            Create a password with at least 8 characters. Including a mix of uppercase, lowecase, special characters and at least 3
                            numbers . Avoid a common words and personal information. Keep your account safe by choosing a unique password.
                          </Caption1Strong>
                        </Stack>

                        <StackItem>
                          <Text></Text>
                        </StackItem>

                        <StackItem>
                          <Button type="button" appearance="primary" onClick={newPasswordEntered} onClickCapture={handleBothSetMessage}>
                            Continue
                          </Button>
                        </StackItem>

                        {messagebar2 && (
                          <ToastComponent
                            setmessagebar={setmessagebar2}
                            isToastEnabled={messagebar2}
                            footer=""
                            toastIntent={
                              user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                ? 'warning'
                                : 'success'
                            }
                            position="top-end"
                            title={
                              user.password === '' || user.password.length < 8 || user.reenteredPassword !== user.password || !pswStrengthFlag
                                ? 'Warning'
                                : 'Success'
                            }
                            message={
                              user.password === '' || user.reenteredPassword === ''
                                ? 'Field cannot be empty'
                                : user.password.length < 8
                                ? 'Password must be at least 8 characters long.'
                                : user.reenteredPassword !== user.password || !pswStrengthFlag
                                ? 'Please check the password  or read the password instruciton'
                                : 'Password reset successful!'
                            }
                          />
                        )}
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem>
              <Stack horizontalAlign="end">
                <StackItem grow shrink style={backgroundPosition}>
                  <Image block src="/visualization/v2/signin_background.png" height={`100%`} />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider>
      ) : (
        <>{!token ? <> ( {!passwordResetMailSent ? <SkeletonPasswordReset /> : <SkeletonMailSent />} ) </> : <SkeletonNewPassword />}</>
      )}
    </>
  );
};
