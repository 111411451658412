import React, { useEffect ,useContext} from 'react'
import { useQuery, useApolloClient } from '@apollo/client';
import { GlobalContext } from '../hooks/globalContext';
import { getAppMonitored,getAppMonitoredCntQuery,addAppMonitoredQuery,deleteAppMonitoredQuery,updateAppMonitoreduery } from '../graphql/app_monitored_queries';
import { stringify } from 'querystring';

type dataProps = {
    overlayName?:string;
    setOverlayOpen?:any
    data?:any;
    func?:any;
    func1?:any;
    searchVal?: any
    pageNo?: any
    setTotalPages?: any
    setTotalRecords?: any
    isFilter?:any
    dropDownValue1?:any
    dropDownValue2?:any
    setIsFilter?:any
    // savePackageDetails?: any;
    // setSavePackageDetails? :any
    deleteAppMonitored?:any
    setDeleteAppMonitored?:any
    setDeleteDisabled?:any
    setFormData?:any
    formdata?:any
    setIsToast?:any
    saveBtnClicked?:any
    setsaveBtnClicked?:any
    rowID?:any
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
    setExportDetails?:any
    deviceName?:any
  }

export const App_Monitored_Func= (props:dataProps) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    var tenantName = contextValue.tenantName;
    console.log("checkingprops",props);
    console.log("checkingprops22",props.formdata.cpuUtilisation[0].cpuFrom);   


    const getAppMonitoredList = async (whereCondition: any,limit: any, offset: any) => {
        console.log("inside getAppMonitoredList func");
        try {
          const { data, loading, error } = await client.query({
            query:getAppMonitored,
            variables: {whereCondition,limit,offset},
            fetchPolicy:'network-only'
          });
            
          console.log("Hi from app monitored",data.analytics_test_Scores)  
          if(!loading && data){
            console.log("main table data appMntr=", data);
            
            if(props.overlayName == 'editMonitoredApp' && data.analytics_test_Scores.length == 1){
              console.log("AppMonitored data inside=",data.analytics_test_Scores);
              var cpuUtilisationArr = [];
              var memUtilisationArr = [];
              var netUtilisationArr = [];
              var res = data.analytics_test_Scores[0];

              if(res.MetricName == "Process CPU Usage"){
                props.setFormData({
                  Category: res.Category,
                  Subcategory: res.subcategory,
                  ApplicationName: res.MetricDesc,
                  ApplicationType: res.ApplicationType,
                  UrlPatterns: res.UrlPatters.split(",").map((val: any) => {return ({urlpatterns: val})}),
                  ApplicationBinaries: res.SpecificInfo,
                  cpuUtilisation: res.Scores.range.map((val: any) => {
                    return (
                      {
                        cpuFrom: val.from,
                        cpuTo: val.to,
                        cpuScore: val.sc,
                        cpuRank: val.rank,
                      }
                    )
                  }),
                  memUtilisation: [{
                    memFrom:'',
                    memTo:'',
                    memScore:'',
                    memRank:'',
                  }],
                  netUtilisation: [{
                    netFrom:'',
                    netTo:'',
                    netScore:'',
                    netRank:'',
                  }],
                });
              }else if(res.MetricName == "Process Memory Usage"){
                props.setFormData({
                  Category: res.Category,
                  Subcategory: res.subcategory,
                  ApplicationName: res.MetricDesc,
                  ApplicationType: res.ApplicationType,
                  UrlPatterns: res.UrlPatters.split(",").map((val: any) => {return ({urlpattern: val})}),
                  ApplicationBinaries: res.SpecificInfo,
                  cpuUtilisation: [{
                    cpuFrom:'',
                    cpuTo:'',
                    cpuScore:'',
                    cpuRank:'',
                  }],
                  memUtilisation: res.Scores.range.map((val: any) => {
                    return (
                      {
                        memFrom: val.from,
                        memTo: val.to,
                        memScore: val.sc,
                        memRank: val.rank,
                      }
                    )
                  }),
                  netUtilisation: [{
                    netFrom:'',
                    netTo:'',
                    netScore:'',
                    netRank:'',
                  }],
                });
              }else if(res.MetricName == "Network Response"){
                props.setFormData({
                  Category: res.Category,
                  Subcategory: res.subcategory,
                  ApplicationName: res.MetricDesc,
                  ApplicationType: res.ApplicationType,
                  UrlPatterns: res.UrlPatters.split(",").map((val: any) => {return ({urlpattern: val})}),
                  ApplicationBinaries: res.SpecificInfo,
                  cpuUtilisation: [{
                    cpuFrom:'',
                    cpuTo:'',
                    cpuScore:'',
                    cpuRank:'',
                  }],
                  memUtilisation: [{
                    memFrom:'',
                    memTo:'',
                    memScore:'',
                    memRank:'',
                  }],
                  netUtilisation: res.Scores.range.map((val: any) => {
                    return (
                      {
                        netFrom: val.from,
                        netTo: val.to,
                        netScore: val.sc,
                        netRank: val.rank,
                      }
                    )
                  }),
                });
              }
              else{
                props.setFormData({
                  Category: '',
                  Subcategory: '',
                  ApplicationName: '',
                  ApplicationType: '',
                  UrlPatterns: [{
                    // urlpatterns:''
                  }],
                  ApplicationBinaries: '',
                  cpuUtilisation: [{
                    cpuFrom:'',
                    cpuTo:'',
                    cpuScore:'',
                    cpuRank:'',
                  }],
                  memUtilisation: [{
                    memFrom:'',
                    memTo:'',
                    memScore:'',
                    memRank:'',
                  }],
                  netUtilisation: [{
                    netFrom:'',
                    netTo:'',
                    netScore:'',
                    netRank:'',
                  }],
                })
              }
            }else{
              props.func(data);
            }
          }
        }catch (error) {
            console.error("An unexpected error occurred:", (error as Error).message);
            // Handle unexpected errors, log them, and potentially notify the user
        }
    };

    const getAppMonitoredCnt = async (whereCondition: any ) => {
        try{
          const { data, loading, error } = await client.query({
              query: getAppMonitoredCntQuery,
              variables: {whereCondition},
              fetchPolicy:"network-only"
          });
 
          if(!loading && data){
            props?.setExportDetails && props?.setExportDetails(data.analytics_test_Scores);
            props.setTotalRecords(data.analytics_test_Scores.length);
            props.setTotalPages(Math.ceil((data.analytics_test_Scores.length)/50));
          }
        }catch (error){
            console.error("An unexpected error occurred:", (error as Error).message);
            // Handle unexpected errors, log them, and potentially notify the user
        }
      };

      const deleteAppMonitored = async (_eq:any)=>{
        {
            try { 
              const { data } = await client.mutate({
                mutation: deleteAppMonitoredQuery,
                variables: { _eq },
                fetchPolicy:"network-only"
              });
          
              if (data.delete_analytics_test_Scores?.affected_rows > 0) {
                console.log( "affected rows",data.analytics_test_Scores?.affected_rows);
                setTimeout(() => {
                  props.setDeleteAppMonitored(false);
                },2000);
                
                props.setDeleteDisabled(true);
                
                getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
                getAppMonitoredCnt({ Category: {contains: props.searchVal}});
                // props.setIsToast(true);
              }
            } catch (error) {
              console.error("An error occurred:", error);
            }

        
      }
    };

  const addAppMonitor = async () => {
    const Category=props.formdata.Category ? props.formdata.Category : null ;
    const subcategory=props.formdata.Subcategory ? props. formdata.Subcategory : null;
    const ApplicationType= props.formdata.ApplicationType ? props. formdata.ApplicationType : null;
    const SpecificInfo=props.formdata.ApplicationBinaries ? props. formdata.ApplicationBinaries : null;
    let Scope= props.deviceName;
    console.log("props.deviceName",props.deviceName);
    
    const Site = localStorage.getItem("tenant");
    
    
    const UrlPatters=props.formdata.UrlPatterns.map((item: { urlpatterns
      : any; }) => item.urlpatterns
      ).join(',');
    ///////////////////////////////////////////////////////////
    const cpuFrom= props.formdata.cpuUtilisation[0].cpuFrom;
    console.log('cpuFrom',cpuFrom);
    const memFrom=props.formdata.memUtilisation[0].memFrom;
    const netFrom=props.formdata.netUtilisation[0].netFrom;


    
    //////////////////////////////////////////////////////////
    const convertedDataCpu={
      range: props.formdata.cpuUtilisation.map((item:any, index:any) => ({
        cw: "1", 
        mw: "1", 
        sc: item.cpuScore,
        to: item.cpuTo,
        scw: "1",
        from: item.cpuFrom,
        rank: item.cpuRank,
      })),
    };
    const convertedDataMem={
      range: props.formdata.memUtilisation.map((item:any, index:any) => ({
        cw: "1", 
        mw: "1", 
        sc: item.memScore,
        to: item.cpuTo,
        scw: "1",
        from: item.memFrom,
        rank: item.memRank,
      })),
    };
    const convertedDataNet={
      range: props.formdata.netUtilisation.map((item:any, index:any) => ({
        cw: "1", 
        mw: "1", 
        sc: item.netScore,
        to: item.netTo,
        scw: "1",
        from: item.netFrom,
        rank: item.netRank,
      })),
    };
    const cpuUtilisation= JSON.stringify(convertedDataCpu, null,2);
    const memUtilisation= JSON.stringify(convertedDataMem, null,2);
    const netUtilisation= JSON.stringify(convertedDataNet, null,2);
    

    const MetricDesc = props.formdata.ApplicationName ? props.formdata.ApplicationName : null ;
    var Scores;
    var MetricName;

      try {
        if (props.formdata.cpuUtilisation.length > 0 && cpuFrom !== '') {
          Scores = JSON.stringify(convertedDataCpu, null,2);
          MetricName='Process CPU Usage';

          await client.mutate({
            mutation: addAppMonitoredQuery,
            variables: {
              Category,
              subcategory,
              ApplicationType,
              SpecificInfo,
              MetricName,
              UrlPatters,
              Scores,
              MetricDesc,
              Scope,
              Site
            },
          });
        }
        if ( props.formdata.memUtilisation.length > 0 && memFrom !== '') {
          Scores = JSON.stringify(convertedDataMem, null,2);
          MetricName='Process Memory Usage';

          await client.mutate({
            mutation: addAppMonitoredQuery,
            variables: {
              Category,
              subcategory,
              ApplicationType,
              SpecificInfo,
              MetricName,
              UrlPatters,
              Scores,
              MetricDesc,
              Scope,
              Site
            },
          });
        }
    
        if (props.formdata.netUtilisation.length > 0 && netFrom !== '') {
          Scores = JSON.stringify(convertedDataNet, null,2);
          MetricName='Network Response';
          const { data } = await client.mutate({
            mutation: addAppMonitoredQuery,
            variables: {
              Category,
              subcategory,
              ApplicationType,
              SpecificInfo,
              MetricName,
              UrlPatters,
              Scores,
              MetricDesc,
              Scope,
              Site
            },
          });

          if(data.insert_analytics_test_Scores.affected_rows > 0){

          }
        }
    
      }catch (error) {
      console.error("An error occurred:", error);
    }
    };

    const editMonitoredApp = async ()=>{
      const Category=props.formdata.Category ? props.formdata.Category : "null" ;
      const subcategory=props.formdata.Subcategory ? props. formdata.Subcategory : "null";
      const ApplicationType= props.formdata.ApplicationType ? props. formdata.ApplicationType : "null";
      const SpecificInfo=props.formdata.ApplicationBinaries ? props. formdata.ApplicationBinaries : "null";
      // const MetricName=props.formdata.ApplicationName ? props. formdata.ApplicationName : null;
      const UrlPatters=props.formdata.UrlPatterns.map((item: { urlpatterns: any; }) => item.urlpatterns).join(',');
      
    
      ///////////////////////////////////////////////////////////
      const cpuFrom= props.formdata.cpuUtilisation[0].cpuFrom;
      console.log('cpuFrom',cpuFrom);
      const memFrom=props.formdata.memUtilisation[0].memFrom;
      const netFrom=props.formdata.netUtilisation[0].netFrom;
      //////////////////////////////////////////////////////////
      const convertedDataCpu={
        range: props.formdata.cpuUtilisation.map((item:any, index:any) => ({
          cw: "1", 
          mw: "1", 
          sc: item.cpuScore,
          to: item.cpuTo,
          scw: "1",
          from: item.cpuFrom,
          rank: item.cpuRank,
        })),
      };
      const convertedDataMem={
        range: props.formdata.memUtilisation.map((item:any, index:any) => ({
          cw: "1", 
          mw: "1", 
          sc: item.memScore,
          to: item.cpuTo,
          scw: "1",
          from: item.memFrom,
          rank: item.memRank,
        })),
      };
      const convertedDataNet={
        range: props.formdata.netUtilisation.map((item:any, index:any) => ({
          cw: "1", 
          mw: "1", 
          sc: item.netScore,
          to: item.netTo,
          scw: "1",
          from: item.netFrom,
          rank: item.netRank,
        })),
      };
      var Scores;
      const cpuUtilisation= JSON.stringify(convertedDataCpu);
      const memUtilisation= JSON.stringify(convertedDataMem);
      const netUtilisation= JSON.stringify(convertedDataNet);

      const MetricDesc = props.formdata.ApplicationName ? props.formdata.ApplicationName : null ;
      const id=props.rowID;
      if(props.formdata.cpuUtilisation.length > 0 && cpuFrom !== ''){
      Scores=JSON.stringify(convertedDataCpu);
      const { data } = await client.mutate({
        mutation: updateAppMonitoreduery,
        variables: {Category,
          subcategory,
          ApplicationType,
          SpecificInfo,
          // MetricName,
          UrlPatters,
          Scores,
          MetricDesc,
          id
        }
      });

    }
    else if(props.formdata.memUtilisation.length > 0 && memFrom !== ''){
      Scores=JSON.stringify(convertedDataMem);
      const { data } = await client.mutate({
        mutation: updateAppMonitoreduery,
        variables: {Category,
          subcategory,
          ApplicationType,
          SpecificInfo,
          // MetricName,
          UrlPatters,
          Scores,
          MetricDesc,
          id
        }
      });

    }
    if(props.formdata.netUtilisation.length > 0 && netFrom !== ''){
      Scores=JSON.stringify(convertedDataNet);
      const { data } = await client.mutate({
        mutation: updateAppMonitoreduery,
        variables: {Category,
          subcategory,
          ApplicationType,
          SpecificInfo,
          // MetricName,
          UrlPatters,
          Scores,
          MetricDesc,
          id
        }
      });

    }
    }
    

     useEffect(() => {   
           
       if( contextValue.selectedRowIdInTable.length > 0 && props.deleteAppMonitored) {
          deleteAppMonitored(contextValue.selectedRowIdInTable);
          getAppMonitoredList(null,50,parseInt(props.pageNo))
          getAppMonitoredCnt(null)
       }
        

        if(props.searchVal.length > 0 && props.searchVal !== ''){
          getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
          getAppMonitoredCnt({ Category: {contains: props.searchVal}});
        } else if(props.isFilter == true){
          console.log("I'AM CHECKING FOR DATA")
          if(props.dropDownValue1 == 'Category'){
            getAppMonitoredList({ Category: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ Category: {contains: props.dropDownValue2}});
          }else if(props.dropDownValue1 == 'Subcategory'){
            getAppMonitoredList({ subcategory: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ subcategory: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 === 'Name'){
            getAppMonitoredList({ MetricDesc: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ MetricDesc: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 === 'URL'){
            getAppMonitoredList({ UrlPatters: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ UrlPatters: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 == 'Desktop Binaries'){
            getAppMonitoredList({ SpecificInfo: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ SpecificInfo: {contains: props.dropDownValue2}});
          }
        }
        else if(!props.saveBtnClicked && props.overlayName == 'editMonitoredApp'){
          console.log("props.rowID=",props.rowID);   
          props.rowID != undefined && getAppMonitoredList({ id: {_eq: props.rowID}},null, null)
        }else if(props.isRefresh){
          props.setIsRefresh(false);
          props.setRefreshToast(true);
          getAppMonitoredList(null,50,parseInt(props.pageNo));
          getAppMonitoredCnt(null); 
          setTimeout(() => {
            props.setRefreshToast(false);
          }, 3000);
        }else{
          getAppMonitoredList(null,50,parseInt(props.pageNo));
          getAppMonitoredCnt(null);
        }

        if(props.saveBtnClicked && props.overlayName == 'editMonitoredApp'){
            editMonitoredApp();
            props.setOverlayOpen(false);
            getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ Category: {contains: props.searchVal}});
            setTimeout(() => {
              props.setsaveBtnClicked(false);
            }, 2000);
            getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
            getAppMonitoredCnt({ Category: {contains: props.searchVal}});
        }else if(props.saveBtnClicked && props.overlayName == 'addMonitoredApp'){
          addAppMonitor();
          props.setOverlayOpen(false);
          getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
          getAppMonitoredCnt({ Category: {contains: props.searchVal}});
          setTimeout(() => {
            props.setsaveBtnClicked(false);
          }, 2000);   
        }
        // else{
        // getAppMonitoredList(null,50,parseInt(props.pageNo));
        // getAppMonitoredCnt(null);
        // }
        
    },[props.searchVal, props.pageNo, props.deleteAppMonitored,props.overlayName, props.rowID, props.isRefresh,props.isFilter]);
    // useEffect(()=>{

    //   getAppMonitoredList({ Category: {contains: props.searchVal}},50,parseInt(props.pageNo))
    //   getAppMonitoredCnt({ Category: {contains: props.searchVal}});
    // }
    // ,[])
    

  return (
    <>
    </>
  )
  
}