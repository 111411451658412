import React, { useEffect } from "react";
import { useId, Toaster, useToastController, ToastPosition, ToastTitle, Toast, ToastFooter, ToastBody, Body1, ProgressBar, ToastTrigger, Link, FluentProvider, webLightTheme, Body1Stronger } from "@fluentui/react-components";
import { Dismiss20Filled } from "@fluentui/react-icons";
import { toastStyles } from "../../styles/customStyles";

const progressBarDuration = 5000; // 5 seconds in milliseconds
const intervalDelay = 100;
const intervalIncrement = (intervalDelay / progressBarDuration) * 100;

const DownloadProgressBar: React.FC<{ onDownloadEnd: () => void }> = ({onDownloadEnd}) => {
  const [value, setValue] = React.useState(100);
  // useEffect(() => { const timeout = setTimeout(() => { onDownloadEnd(); }, 5000); return () => clearTimeout(timeout); }, [onDownloadEnd]);  // Set timeout for 5 seconds
  useEffect(() => {
    const interval = setInterval(() => { setValue((v) => Math.max(v - intervalIncrement, 0)); }, intervalDelay);
    return () => clearInterval(interval);
  }, []);
  return <ProgressBar value={value} max={100} />;
};

export const ToastComponent = (props: any) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  var [position, setPosition] = React.useState<ToastPosition>(props.position);
 
  const notify = () => {
    dispatchToast(
      <FluentProvider theme={webLightTheme}>
        <Toast style={toastStyles}>
          <ToastTitle> <Body1Stronger> {props.title} </Body1Stronger> </ToastTitle>
          <ToastTrigger>
            <Link> <Dismiss20Filled/> </Link>
          </ToastTrigger>

          <ToastBody> 
            <Body1> {props.message} </Body1>
            {props.showProgressBar && (<DownloadProgressBar onDownloadEnd={() => {}}/>)}
          </ToastBody>
          <ToastFooter> {props.footer} </ToastFooter>
        </Toast>
    </FluentProvider>,
    { position, intent: (props.toastIntent != '') ? props.toastIntent : "info", onStatusChange: (e, data) => {data.status == 'dismissed' && props.setmessagebar(false)}, timeout: 5000 }
    );
  }

  useEffect(() => {notify();}, [props.isToastEnabled]);

  return <Toaster toasterId={toasterId}/>;
};

// import React, { useEffect } from "react"; import { useId, Button, Toaster, useToastController, ToastPosition, ToastTitle, Toast, ToastFooter, ToastBody, Body1 } from "@fluentui/react-components"; export const ToastComponent = (props: any) => { const toasterId = useId("toaster"); const { dispatchToast } = useToastController(toasterId); var [position, setPosition] = React.useState<ToastPosition>(props.position); const notify = () => { dispatchToast( <Toast> <ToastTitle> {props.title} </ToastTitle> <ToastBody> <Body1> {props.message} </Body1> </ToastBody> <ToastFooter> {props.footer} </ToastFooter> </Toast>, { position, intent: (props.toastIntent != '') ? props.toastIntent : "info", onStatusChange: (e, data) => {data.status == 'dismissed' && props.setmessagebar(false)}, timeout: 5000 } ); } useEffect(() => { notify(); },[props.isToastEnabled]); return ( <> <Toaster toasterId={toasterId}/> </> ); };