import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupIdOfTenantQuery, getComponentsDetailsOfDartQuery, insertValueOfComponentQuery, updateValueOfComponentQuery, updateAutomationStautsQuery, deleteValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { useNavigate } from "react-router-dom";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
}

const Remediations_scheduler_func = (props: any) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const contextValue: any = useContext(GlobalContext);
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    // var mgroupuniq = '';
    var insertCounter = 0;
    var totalGrpDataCnt = 0;
    var failedAttachmentGroups: any = [];
    var failedDetachmentGroups: any = [];
    var mcatuniq = '';
    var varnameuniq = '';
    var selctedValue = '';
    var automationData = {
        id: '',
        automation_name: '',
        automation_description: '',
        dartNo: '',
        schedulerType: '',
        scheduledAutomations: '',
        executionTime: [],
        recommendedAction: '',
        weekend: false,
        days: [],
        months: [],
        date: [],
        status: ''
    };

    var subAutomationData = {
        id: '',
        automation_name: '',
        automation_description: '',
        dartNo: '',
        type: '',
        varValue: ''
    }

    var baseProfilesData : any = {};
    var profileSchedData : any = {};

    const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data){
            var jsonVal = JSON.parse(data.core_AutomationJSON[0].jsonValue);
            
            if(data.core_AutomationJSON[0].types == 'scheduler'){
                automationData.id = data.core_AutomationJSON[0].id;
                automationData.automation_name = data.core_AutomationJSON[0].automationName;
                automationData.automation_description = data.core_AutomationJSON[0].description;
                automationData.dartNo = data.core_AutomationJSON[0].dartid;
                automationData.status = data.core_AutomationJSON[0].status;
                automationData.schedulerType = jsonVal.type;
                automationData.scheduledAutomations = jsonVal.scheduledAutomations.join(",");
                automationData.recommendedAction = jsonVal.recommendedAction;
                automationData.executionTime = jsonVal.executionTime;

                if(jsonVal.type == 'daily'){
                    automationData.weekend = jsonVal.weekend;
                }else if(jsonVal.type == 'weekly'){
                    automationData.days = jsonVal.days;
                }else{
                    automationData.months = jsonVal.months;
                    automationData.date = jsonVal.date;
                }

                await getAutomationList({dartid: {_eq: 304}, automationName: {_eq: jsonVal.scheduledAutomations[0]}});

            }else{
                subAutomationData.id = data.core_AutomationJSON[0].id;
                subAutomationData.automation_name = data.core_AutomationJSON[0].automation_name;
                subAutomationData.varValue = jsonVal.automation_name.split(" ").join("");
                subAutomationData.automation_description = jsonVal.description;
                subAutomationData.dartNo = data.core_AutomationJSON[0].dartid;
                subAutomationData.type = data.core_AutomationJSON[0].types;
            }
        }
    }

    const getGroupIdOfTenant = async (whereCondition: any, action: any) => {
        const { data, loading, error } = await client.query({
            query: getGroupIdOfTenantQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            totalGrpDataCnt = data.core_MachineGroups.length;
            data.core_MachineGroups.map(async (val: any) => {
                insertCounter++;
                mcatuniq = val.mcatuniq;
                
                await getComponentsDetailsOfDart([304], val.mgroupuniq, val.name);

                if(action == 'detach'){
                    console.log("deleteing...........");
                    
                    setTimeout(async () => {
                        deleteValueOfComponent([profileSchedData[`${val.name}`].id]);
                    }, 1000);

                    setTimeout(async () => {
                        removeProfileScheduler(val.mgroupuniq, val.name);
                    }, 2000);
                } 
                
                if(action == 'attach'){
                    console.log("inserting...........");

                    if(profileSchedData[`${val.name}`].componentValue != undefined){
                        setTimeout( () => {
                            deleteValueOfComponent([profileSchedData[`${val.name}`].id]);
                        }, 1000);
                    }
    
                    setTimeout( () => {
                        getValueOfBaseProfileComponent(val.mgroupuniq, val.name);
                    }, 2000);

                }
            });
        }else{
            console.log("Site has not configured");

            props.setmessagebar1(false);
            props.setmessagebar2(true);
            props.setToastMessage("Assignment failed");
        }
    }

    //to fetch the name, varnameuniq, varscopuniq, varuniq of components in dart 304
    const getComponentsDetailsOfDart = async (dartId: any, groupId: any, name: String) => {
        console.log("getComponentsDetailsOfDart func called");
        
        const { data, loading, error } = await client.query({
            query: getComponentsDetailsOfDartQuery,
            variables: { dartId, groupId },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            data.core_Variables.map((val: any) => {
                if(val.name == 'S00304_BaseProfiles'){
                    var baseProfilesIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    baseProfilesData[`${name}`] = baseProfilesIds;
                }else if (val.name == 'S00304_ProfileSched'){
                    var profileSchedIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    profileSchedData[`${name}`] = profileSchedIds;
                }
            });
        }
    }

    const getValueOfBaseProfileComponent = async (mgroupuniq: any, name: String) => {
        var varValue: any = subAutomationData.varValue;
        var baseProfileVal = '';
    
        console.log("baseProfile component loop entered");
        if(baseProfilesData[`${name}`].varuniq?.length > 0 && baseProfilesData[`${name}`].componentValue != undefined){ // to check if baseprofile is present for particluar site/group in varvalues table 
            var res = baseProfilesData[`${name}`].componentValue;
            baseProfileVal = res?.trim();
                
            console.log("varValue=",varValue);
            
            if(baseProfileVal.includes(varValue)){
                await setProfileScheduler(mgroupuniq, name);
            }else{
                failedAttachmentGroups.push(name);
                props.setToastMessage(`Selected automation is not configured with ${name} group`);
                props.setErrorToast(true);
            }
        }else{
            failedAttachmentGroups.push(name);
            props.setToastMessage(`Selected automation is not configured with ${name} group`);
            props.setErrorToast(true);
        }
    };

    const setProfileScheduler = async (mgroupuniq: any, name: String) => {
        var dartId = automationData.dartNo;
        var schedulerDetails: any = '';
        var status: any = false;
        
        //get the data from AutomationJSon
        var varValue: any = subAutomationData.varValue;
        var hours: any = [];
        var minutes: any = [];
        var week: any = [];
        var date: any = [];
        var month: any = [];

        automationData.executionTime.map((val: any) => {
            hours.push(val.split(":")[0]);
            minutes.push(val.split(":")[1]);
        });

        automationData.date.map((val: any) => {
            date.push(val);
        });

        automationData?.months.map((val :any) => {
            month.push( val == 'Jan' ? 1 : val == 'Feb' ? 2 : val == 'Mar' ? 3 : val == 'Apr' ? 4 : val == 'May' ? 5 : val == 'Jun' ? 6 : val == 'Jul' ? 7 : val == 'Aug' ? 8 : val == 'Sep' ? 9 : val == 'Oct' ? 10 : val == 'Nov' ? 11 : 12);
        });

        var randomMinute: any = "\n" + Math.floor(Math.random() * (60)) + 1; //randamize the no b/w 1 to 60
        var conditionalExecution: any = "\n" + automationData.recommendedAction; //either 1 or 2
        var failedRunLimit: any = "\n" + "0";
        
        if(profileSchedData[`${name}`].varuniq?.length > 0 && profileSchedData[`${name}`].componentValue?.length > 0){ 
            var res =  profileSchedData[`${name}`].componentValue;
            var revised = profileSchedData[`${name}`].revised;
            //var minutes: any = "\n" + minutes; //value should be b/w 1 to 60
            
            if(automationData.schedulerType == 'daily'){
                date = '*';
                month = "*";
                week = automationData.weekend ? '*' : "1,2,3,4,5";

                schedulerDetails =  res + "\n" + "#NXT#" + "\n" + varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date + "\n" + month + "\n" + week + randomMinute + conditionalExecution + failedRunLimit;
            }else if(automationData.schedulerType == 'weekly'){
                date = '*';
                month = "*";
                automationData.days.map((val: any) => {
                    week.push(val == 'Sun' ? '0' : val == 'Mon' ? '1' : val == 'Tue' ? '2' : val == 'Wed' ? '3' : val == 'Thu' ? '4' : val == 'Fri' ? '5' : '6');
                });
                if(week.length == 7){
                    week = ["*"];
                }

                schedulerDetails =  res + "\n" + `#NXT#` + "\n" + varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date + "\n" + month + "\n" + week.join(",") + randomMinute + conditionalExecution + failedRunLimit;
            }else{
                week = "*";
                if(month.length == 12){
                    month = ["*"];
                } 

                schedulerDetails =  res + "\n" + `#NXT#` + "\n" + varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date.join(',') + "\n" + month.join(',') + "\n" + week + randomMinute + conditionalExecution + failedRunLimit;
            }

            // status = await updateValueOfComponent(schedulerDetails, (revised + 1), profileSchedData[`${name}`].varnameuniq, profileSchedData[`${name}`].varscopuniq, profileSchedData[`${name}`].varuniq, mgroupuniq, profileSchedData[`${name}`].id);

            status = await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSchedData[`${name}`].varuniq}, varscopuniq: {_eq: profileSchedData[`${name}`].varscopuniq}}, schedulerDetails, (revised + 1));
        }else{
            // var minutes: any = "\n" + minutes;

            if(automationData.schedulerType == 'daily'){
                date = '*';
                month = "*";
                week = automationData.weekend ? '*' : "1,2,3,4,5";

                schedulerDetails =  varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date + "\n" + month + "\n" + week + randomMinute + conditionalExecution + failedRunLimit;
            }else if(automationData.schedulerType == 'weekly'){
                date = '*';
                month = "*";
                automationData.days.map((val: any) => {
                    week.push(val == 'Sun' ? '0' : val == 'Mon' ? '1' : val == 'Tue' ? '2' : val == 'Wed' ? '3' : val == 'Thu' ? '4' : val == 'Fri' ? '5' : '6');
                });
                if(week.length == 7){
                    week = ["*"];
                }

                schedulerDetails =  varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date + "\n" + month + "\n" + week.join(",") + randomMinute + conditionalExecution + failedRunLimit;
            }else{
                week = "*";
                if(month.length == 12){
                    month = ["*"];
                } 

                schedulerDetails =  varValue + "\n" + minutes.join(",") + "\n" + hours.join(",") + "\n" + date.join(',') + "\n" + month.join(',') + "\n" + week + randomMinute + conditionalExecution + failedRunLimit;
            }

            status = await insertValueOfComponent(schedulerDetails, 0, profileSchedData[`${name}`].varnameuniq, profileSchedData[`${name}`].varscopuniq, profileSchedData[`${name}`].varuniq, mgroupuniq);
        }
    
        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;
            
            if(selctedValue == 'tenant'){
        
                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":all";
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:all`);

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `${tenantName}:all`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:all}`;
                }
            }else{
                var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');
                console.log("attached selectedGroups=",selectedGroups);
                selectedGroups = selectedGroups.filter((val: any) => !(failedAttachmentGroups.includes(val)));
                console.log("filtered attached selectedGroups=",selectedGroups);

                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":" + selectedGroups.join(",");
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1];

                        console.log("attachment curSelectedGRp=",curSelectedGRp);

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp = automationStatus.split(":")[1];

                        selctedValue = `${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`;
                    }
                }else{

                    selctedValue = `${automationStatus}|${tenantName}:${selectedGroups.join(",")}`; 
                }
            }
            console.log("selctedValue=",selctedValue);
            
            
            await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, 0, 'attach');
        }
    };

    const removeProfileScheduler = async(mgroupuniq: any, name: String) => {
        var res = profileSchedData[`${name}`].componentValue;
        var revised = profileSchedData[`${name}`].revised;
        var dartId = automationData.dartNo;
        var status = false;
       
        if(res != undefined && res.length > 0){
            var varValue = subAutomationData.varValue;
        
            var schedulerDetails: any = res.split("#NXT#").filter((val: any) => !val.includes(varValue)).join("#NXT#");

            // status = await updateValueOfComponent(schedulerDetails, (revised + 1), profileSchedData[`${name}`].varnameuniq, profileSchedData[`${name}`].varscopuniq, profileSchedData[`${name}`].varuniq, mgroupuniq, profileSchedData[`${name}`].id);

            status = await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSchedData[`${name}`].varuniq}, varscopuniq: {_eq: profileSchedData[`${name}`].varscopuniq}}, schedulerDetails, (revised + 1));
        }

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;

            if(selctedValue == 'tenant'){
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `not attached`;
                    }
                }else{
                    selctedValue = automationStatus;
                }
            }else{
                var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedDetachmentGroups.includes(val)));

                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1].split(",");

                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        curSelectedGRp.length > 0 && statusArr.push(`${tenantName}:${curSelectedGRp.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp: any = automationStatus.split(":")[1].split(",");
                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        if(curSelectedGRp.length == 0){
                            selctedValue = 'not attached';
                        }else{
                            selctedValue = `${tenantName}:${curSelectedGRp.join(",")}`;
                        }
                    }
                }else{
                    selctedValue = automationStatus; 
                }
            }

            let flag = props.groupAttachment?.length ? props.groupAttachment?.length : 0;
            
           await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, flag, 'detach');
            
            // if(props.groupAttachment.length > 0){
            //     automationData.status = selctedValue;
            //     setTimeout(() => {
            //         attachRemediation();
            //     }, 1000);
            // }
        }
    }

    const updateAutomationStauts = async (whereCondition: any, status: any, flag: any, type: String = '') => { 
        const { data } = await client.mutate({
            mutation: updateAutomationStautsQuery,
            variables: { whereCondition, status },
            fetchPolicy: 'network-only',
        });

        if(data.update_core_AutomationJSON.affected_rows > 0){
            if(flag == 0){
                if(type == 'attach'){
                    props.setToastMessage(`Automation attached to ${props.groupAttachment[0]} ${props.groupAttachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);
                    
                    props.setAllAttachmentGrp((prev: any) => [...prev.slice(1)]);
                }else if(type == 'detach'){
                    props.setToastMessage(`Automation detached from ${props.groupDetachment[0]} ${props.groupDetachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);

                    props.setAllDetachmentGrp((prev: any) => [...prev.slice(1)]);
                } 

                props.setToastMessage("Automation attached to selected devices successfully.");
                props.setmessagebar1(false);
                props.setmessagebar2(true);

                // setTimeout(() => {
                //     navigate("/manage/devices/remediation");
                // }, 5000);
            }
        }
    }

    const insertValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any ) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: insertValueOfComponentQuery,
          variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
    
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any, valueid: any) => {
        console.log("valueid=",valueid);
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
            mutation: insertValueOfComponentQuery,
            variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
        
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponentss = async (whereCondition: any, valu: String, revl: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: updateValueOfComponentQuery,
          variables: {whereCondition, valu, revl, lastchange}
        });
    
        if(data.update_core_VarValues.affected_rows > 0){
            console.log("component value updated");
            return true;
        }else{
            return false;
        }
    };

    const deleteValueOfComponent = async (valueid: any) => {
        const { data } = await client.mutate({
            mutation: deleteValueOfComponentQuery,
            variables: {valueid}
        });

        if(data.delete_core_VarValues.affected_rows > 0){
            return true
        }
    }

    const attachRemediation = async () => {
        var selected = contextValue.selectedRowIdInTable;
        var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

        if(selected.includes('All Devices')){
            selctedValue = 'tenant';
            await getGroupIdOfTenant({name: {_eq: tenantName}}, 'attach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification'; 
            await getGroupIdOfTenant({name: {_in: selectedGroups}}, 'attach');
        }
    };

    const detachRemediation = () => {
        var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');

        if(props.groupDetachment.includes('All Devices')){
            selctedValue = 'tenant';
            getGroupIdOfTenant({name: {_eq: tenantName}}, 'detach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification';
            getGroupIdOfTenant({name: {_in: selectedGroups}}, 'detach');
        }
    };
  
    const modifyRemediation = async () => {
        await getAutomationList({dartid: {_eq: 304}, automationName: {_eq: automationName}});

        if(props.groupDetachment.length > 0){
            detachRemediation();
        }else if(props.groupAttachment.length > 0){
            attachRemediation();
        }
    }
  
    useEffect(() => {   
        modifyRemediation();
    },[props.saveBtnClicked, props.groupAttachment, props.groupDetachment]);

    return (
        <></>
    )
}

export { Remediations_scheduler_func }
