import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getAutomationListsQuery, getDartDetailsQuery, getJsonSchemaQuery, addAutomationQuery, deleteUnattachedAutomationQuery, updateAutomationQuery } from "../../graphql/configuration_queries";

import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setIsRefresh?:any
    setRefreshToast?:any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
}

const Automaiton_schedule_func = (props: any) => {
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;
  var userName = contextValue.userSessionDetails.userName;

  const deleteUnattachedAutomation = async (ids: any) => {
    var dartid = parseInt(dartNo);
    const { data } = await client.mutate({
      mutation: deleteUnattachedAutomationQuery,
      variables: {ids, dartid},
      fetchPolicy: 'network-only',
    });
    
    if(data.delete_core_AutomationJSON.affected_rows > 0){
      props.setDeleteDisabled(true);
      props.setDeleteAutomation(false);
      props.setIsToast(true);
      getAutomationList({types: {_eq: 'scheduler'}}, 50, parseInt(props.pageNo));
    }
  }

  const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      if(props.overlayName == 'editAutomation'){
        console.log("edited value func=",data.core_AutomationJSON[0]);
        
        props.setFormData({...props.formData,
          name: data.core_AutomationJSON[0]?.automationName,
          description: data.core_AutomationJSON[0]?.description,
          jsonData: JSON.parse(data.core_AutomationJSON[0]?.jsonValue)
        });
      }else {
        props.func(data);
      }
    }
  };

  const getAutomationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON?.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON.length)/50));
    }
  }

  const getDartDetails = async (dartid: number) => {
    const { data, loading, error } = await client.query({
      query: getDartDetailsQuery,
      variables: {dartid},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.setDartSpecs(data);
    }
  };

  // const getJsonSchema = async (dartid: number) => {
  //   const { data, loading, error } = await client.query({
  //     query: getJsonSchemaQuery,
  //     variables: {dartid},
  //     fetchPolicy: 'network-only',
  //   });

  //   if(!loading && data){
  //     var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
  //     uiSchema["ui:submitButtonOptions"] = {
  //      "norender": true,
  //     };

  //     var jsonSchema = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);

  //     data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchema);
  //     data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
  //   }
  // };

  const addAutomation = async (automationName: String, description: String, jsonVal: String, createdBy: String, dartid: number) => {

    console.log("addAutomation");
    
    const createdOn =  Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);
    var types: any = 'scheduler';

    const { data } = await client.mutate({
      mutation: addAutomationQuery,
      variables: {automationName, description, jsonValue, types, createdBy, createdOn, dartid}
    });

    if(data.insert_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
        props.setOverlayName(false);
      }, 2000);
      getAutomationList({types: {_eq: 'scheduler'}}, 50, parseInt(props.pageNo));
    }
  };

  const editAutomation = async (automationName: String, description: String, jsonVal: String, rowID: Number, dartid: Number) => {
    console.log("editAutomation called=",rowID);
    
    const lastModified: any = Math.floor(Date.now() / 1000);
    var jsonValue = JSON.stringify(jsonVal);

    const { data } = await client.mutate({
      mutation: updateAutomationQuery,
      variables: {automationName, description, jsonValue, lastModified, rowID, dartid}
    });

    if(data.update_core_AutomationJSON.affected_rows > 0){
      props.setOverlayOpen(false);
      props.setIsToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
        props.setOverlayName('');
        getAutomationList({types: {_eq: 'scheduler'}}, 50, parseInt(props.pageNo));
      }, 2000);
    }else{
      props.setOverlayOpen(false);
      props.setToastMessage("This automation can't be edited as it is already configured");
      props.setErrorToast(true);
      setTimeout(() => {
        props.setSaveBtnClicked(false);
      }, 1000);
    }
  };

  const automationSearch = async (whereCondition: any, limit: any, offset: any) => {
    const { data, loading, error } = await client.query({
      query: getAutomationListsQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var automationArr: any = [];
      
      data.core_AutomationJSON.forEach(async (val: any) => {
       // console.log("jsonVal.properties?.AutomationExecute?.properties.AutomationExecute.examples.includes(val.automationName)=",jsonVal.properties?.AutomationExecute?.properties.AutomationExecute.examples.includes(val.automationName));
        
        // !(jsonVal.properties?.AutomationExecute?.properties.AutomationExecute.examples.includes(val.automationName)) && jsonVal.properties?.AutomationExecute?.properties.AutomationExecute.examples.push(val.automationName);
        console.log("search val=",val);
        await automationArr.push(val.automationName);
      });
      console.log("automationArr=",automationArr);
      
      props.setAutomationSearchData(automationArr);
    }
  }
  
  useEffect(() => {    
    var dartid = parseInt(dartNo);
    console.log("entering func=",props.newData);

    if(props.overlayName == '' && props.isRefresh == false && props.deleteAutomation == false ){
     // getJsonSchema(parseInt(dartNo));
      getDartDetails(parseInt(dartNo));
    }else if(props.isRefresh){
      props.setRefresh(false); 
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }

    if(props.searchVal?.length > 0 && props.searchVal !== '' && props.overlayName == ''){
      getAutomationList({automationName: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
      getAutomationListCnt({automationName: {contains: props.searchVal}});
    }else if(props.isFilter == true){
      if(props.dropDownValue1 == 'Name'){
        getAutomationList({automationName: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutomationListCnt({automationName: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Created by'){
        getAutomationList({createdBy: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutomationListCnt({createdBy: {contains: props.dropDownValue2}});
      }else if(props.dropDownValue1 == 'Status'){
        getAutomationList({status: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
        getAutomationListCnt({status: {contains: props.dropDownValue2}});
      }
    }
    
    else if(props.deleteAutomation && contextValue.selectedRowIdInTable.length > 0){
      deleteUnattachedAutomation(contextValue.selectedRowIdInTable);
    }else if(props.saveBtnClicked && props.newData != undefined && Object.keys(props.newData).length > 0){
      console.log("overlayName=",props.overlayName);
      
      props.overlayName == 'addAutomation' && addAutomation(props.newData.name, props.newData.description, props.newData.jsonData, userName, dartNo);
      
      props.overlayName == 'editAutomation' && editAutomation(props.newData.name, props.newData.description, props.newData.jsonData, props.rowID, dartNo);
    }else if(!(props.saveBtnClicked) && props.overlayName == 'editAutomation' && props.automationSearchVal?.length < 1){
      getAutomationList({dartid: {_eq: dartNo}, id: {_eq: props.rowID}}, 50, parseInt(props.pageNo));
    }else if(props.automationSearchVal?.length > 0 && props.automationSearchVal !== ''){
      automationSearch({types: {_in: ['selfhelp','proactive']}, dartid: {_eq: 304}, status: {contains: `${tenantName}:`}, automationName: {contains: props.automationSearchVal}}, null, null);
    }else{
      props.overlayName != 'addAutomation' && getAutomationList({types: {_eq: 'scheduler'}}, 50, parseInt(props.pageNo));
      props.overlayName == '' && getAutomationListCnt({types: {_eq: 'scheduler'}});
    } 
  },[props.deleteAutomation, props.isRefresh, props.searchVal, props.saveBtnClicked, props.overlayName, props.rowID, props.pageNo, props.automationSearchVal, props.formData, props.isFilter]);

  return (
    <></>
  )
}

export { Automaiton_schedule_func }
