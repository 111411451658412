import { BrandVariants, createLightTheme, createDarkTheme, Theme, shorthands } from '@fluentui/react-components';

// THEMES: This allows to implement Themes all over the app
export const apptheme: BrandVariants = {
  10: '#010404',
  20: '#0F1A1D',
  30: '#142B32',
  40: '#163842',
  50: '#164652',
  60: '#165464',
  70: '#136275',
  80: '#0C7187',
  90: '#038099',
  100: '#388DA4',
  110: '#d6d6d6',
  120: '#6FA8B9',
  130: '#86B5C4',
  140: '#9DC3CF',
  150: '#B4D1DA',
  160: '#CADFE5',
};

export const darkTheme: Theme = { ...createDarkTheme(apptheme) };
darkTheme.colorNeutralBackground1 = '#e9e9e9';
darkTheme.colorNeutralForeground1 = '#ffff00';

export const lightTheme: Theme = { ...createLightTheme(apptheme) };
lightTheme.colorNeutralBackground1 = '#e9e9e9';
lightTheme.colorNeutralBackground4 = '#0a0a0a';

export const breadcrumbTheme: Theme = { ...createLightTheme(apptheme) };
breadcrumbTheme.colorNeutralForeground2 = '#115ea3';

export const messageTheme: Theme = { ...createLightTheme(apptheme) };
messageTheme.colorNeutralBackground1 = '#EBF3FC';

export const warningTheme: Theme = { ...createLightTheme(apptheme) };
warningTheme.colorNeutralForeground1 = '#c50f1f';

export const mildTheme: Theme = { ...createLightTheme(apptheme) };
mildTheme.colorNeutralForeground1 = '#008099';

// APP
export const appHeader: any = { position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 1000 }; // This allows to fix the header on the top
export const appBody: any = { position: 'relative', top: 47, overflowY: 'auto', height: '{calc(100vh - 60px)}' }; // This allows to make the body go behind header when scrolled

// SKELETON
export const skeleton1: any = { width: '40vw' };
export const skeleton2: any = { width: '18vw' };
export const skeleton3: any = { width: '26vw' };
export const skeleton4: any = { width: '10vw' };
export const skeleton5: any = { width: '7vw' };
export const skeleton6: any = { width: '14vw' };
export const skeleton7: any = { width: '65vw' };
export const skeleton8: any = { width: '65vw', height: '60vh' };
export const skeleton9: any = { width: '20vw' };
export const skeleton10: any = { width: '14vw', height: '40vh' };

// SIGNIN: This helps in positioning
export const backgroundPosition: any = { position: 'fixed', right: 0, top: 0, width: '50%', height: '100%' };
export const formPosition: any = { position: 'fixed', left: 80, top: '20%', width: '20%' };
export const formPositions: any = { position: 'fixed', left: 120, top: '22%', width: '30%' };
export const signinFormPosition: any = { position: 'fixed', left: 180, top: '20%', width: '20%' };
export const signFormPosition: any = { position: 'fixed', left: 120, top: '22%', width: '20%' };

// HOME

export const scrollerhide: any = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
};
export const homeImage: any = {
  backgroundImage: "url('/visualization/v2/image/homeBackground.svg')",
  backgroundSize: 'cover',
  width: '95vw' /* height: '80vh' */,
}; // This allows to add background image
export const parentHome: any = { height: '94%' }; // This allows to set the layout
export const childHome: any = { width: '100vh' }; // This allows to set the cards
export const dividerStyle: any = { height: '100%' }; // This allows to strech the divider
export const HomeHome: any = { height: '94vh' };

// ICON COLORS
export const fullscreenIcon: any = { position: 'absolute', right: 5 };
export const primaryIcon: any = { primaryFill: '#008099' };
export const secondaryIcon: any = { primaryFill: '#bdbdbd' };
export const exitIcon: any = { primaryFill: '#d13438' };
export const deleteIcon: any = { primaryFill: '#d13438' };
export const dialogIcon: any = { primaryFill: '#8A3707' };

// COMMON STYLE
export const overflowDivider: any = { width: '68vw' };
export const dataTableScroll: any = { position: 'relative', height: '70vh', width: '65vw' }; // This allows to let only datatable to scroll
export const overlayHeight: any = { marginTop: 48 }; // This allows show overlay below header
export const headerstablestyle: any = { position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000, backgroundColor: '#1a1818' };
export const contentStyle: any = { paddingTop: '4rem', zIndex: 1 };
export const inputsizestyle: any = { width: 250 };
export const primaryWidth: any = { width: 350 };
export const secondaryWidth: any = { width: 200 };
export const accordionMargin: any = { marginLeft: -13 };
export const accordionStyle: any = { marginLeft: '2.5vw' };
export const dialogBoxStyles: any = {
  height: '110px',
  width: '900px',
  top: '4px',
  left: '59px',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '160px',
};
export const popoverStyles: any = {
  left: '500px',
  width: '63%',
  height: '11%',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '168px',
  position: 'fixed',
};
export const toastStyles: any = { marginTop: '16px', right: '-17px', position: 'absolute' };
export const messageBarStyle: any = { display: 'flex', flexDirection: 'column', ...shorthands.gap('10px') };
export const signLogo: any = { position: 'relative', top: -32, left: 10 };
export const columnDataAlign = { textAlign: 'left', justifyContent: 'flex-start' }; // This will help us to fit the column data alignment.
export const sidebarHeight: any = { height: '93.5vh' };
export const iframeStyle: any = { position: 'relative', width: '83vw', height: '85vh', border: '1px solid transparent' };
// export const toastMessage: any = { position: 'absolute', right: -20, top: 35 };
