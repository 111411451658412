import React, { useEffect, useContext, useState } from 'react';
import Session from 'supertokens-web-js/recipe/session';
import client from '../apollo';
import {
  getPermisionListsQuery,
  getPermisionListCntsQuery,
  addSchema,
  getSchema,
  updateRole,
  updateRolePermission,
  addNewRolePermission,
} from '../../graphql/admin_queries';
import { GlobalContext } from '../../hooks/globalContext';
import AppConfig from '../../utility/AppConfig';

type dataProps = {
  formDataRole: any;
  formDataAdmin: any;
  saveRoleDetails?: any;
  setSaveRoleDetails?: any;
  setFormDataRole?: any;
  setFormDataAdmin?: any;
  data: any;
  func: any;
  searchVal: any;
  pageNo: any;
  setTotalPages: any;
  setTotalRecords: any;
  isFilter?: any;
  dropDownValue1?: any;
  setIsFilter?: any;
  dropDownValue2?: any;
  rowID?: any;
  setRowID?: any;
  handleEditData?: any;
  setIsToast?: any;
  isRefresh?: any;
  setIsRefresh?: any;
  setRefreshToast?: any;
  setExportDetails?: any;
  setDeleteDisabled?: any;
  deleteRole?: any;
  isDeleteRole?: any;
  setIsDeleteRole?: any;
  setIsDeleteRoleToast?: any;
  isDeleteRoleToast?: any;
  createNewRole?: any;
  setNewRole?: any;
  setEditRoleDetail?: any;
  editRoleDetail?: any;
  setHandlePermission?: any;
  handlePermission?: any;
};

export const Admin_func = (props: dataProps) => {
  const contextValue: any = useContext(GlobalContext);
  const delRoleList = contextValue.selectedRowIdInTable;
  const rolesObject = { roles: delRoleList };
  console.log('this is the del-List', delRoleList);
  const role: any = props.formDataRole?.roleName;
  // const permisiions:any = props
  //getting the persmissions before sending it to the API
  const roleSchema = JSON.stringify(props.formDataRole);
  const permissionSchema = JSON.stringify(props.formDataAdmin);
  const permissionsObject: any = props.formDataAdmin;
  console.log('object array test', permissionsObject);
  const permissionsArray: any = [];
  const born = Object.keys(permissionsObject).forEach(key => {
    // Access each key and value here

    permissionsArray.push(permissionsObject[key]);
  });

  const addschema = async () => {
    try {
      const { data } = await client.mutate({
        mutation: addSchema,
        variables: {
          roleSchema,
          permissionSchema,
          role,
          creationTime: new Date().toLocaleString(),
          benifit: props.formDataRole?.benefit,
          impact: props.formDataRole?.impact,
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const addRole = async () => {
    //calling the API to create a new Role supertoken
    const accessToken = await Session.getAccessToken();
    fetch(`${AppConfig.DASHBOARD_V2_API_URL}/createRole`, {
      method: 'POST',
      body: JSON.stringify({
        role: role,
        permissions: permissionsArray,
      }),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('API data for create NEW user called', data);
        addschema();
        props.setNewRole(true);
        // Handle data
      })
      .catch((err: any) => {
        console.log('API ERROR create user', err.message);
      });
  };

  const updateRoleDetails = async () => {
    console.log('calling update function=======>', props.formDataRole?.role);
    const role = props.formDataRole?.roleName ?? null;
    const last = Math.floor(Date.now() / 1000);
    const createdBy = contextValue.userSessionDetails.userName;
    const permissionSchema = JSON.stringify(props.formDataAdmin) ?? null;

    try {
      console.log('inside try', props.formDataRole?.role);

      var { data } = await client.mutate({
        mutation: updateRole,
        variables: {
          role,
          last,
          createdBy,
          permissionSchema,
        },
        fetchPolicy: 'network-only',
      });

      if (data.update_supertokens_roles.affected_rows > 0) {
        const oldPermissionObj = JSON.parse(props.handlePermission);
        const oldModuleNamesArr = Object.keys(oldPermissionObj);

        const newPermissionArr = Object.keys(props.formDataAdmin);
        const newModuleNamesArr = newPermissionArr.filter((val: any) => !oldModuleNamesArr.includes(val));

        try {
          // Execute all mutations concurrently
          const updateResults = await Promise.all(
            oldModuleNamesArr.map(async item => {
              const old_permission = oldPermissionObj[`${item}`];
              const permission = props.formDataAdmin[`${item}`];

              if (old_permission !== permission) {
                return client.mutate({
                  mutation: updateRolePermission,
                  variables: {
                    role,
                    old_permission,
                    permission,
                  },
                  fetchPolicy: 'network-only',
                });
              }
            }),
          );

          const insertResults = await Promise.all(
            newModuleNamesArr.map(async item => {
              const permission = props.formDataAdmin[`${item}`];

              return client.mutate({
                mutation: addNewRolePermission,
                variables: {
                  role,
                  permission,
                },
                fetchPolicy: 'network-only',
              });
            }),
          );

          // console.log('Mutation results:', results);
        } catch (error) {
          // Handle unexpected errors, log them, and potentially notify the user
          console.error('An unexpected error occurred during mutation:', (error as Error).message);
        }
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const deleteRole = async () => {
    // calling the API to delete a role by name
    const accessToken = await Session.getAccessToken();
    fetch(`${AppConfig.DASHBOARD_V2_API_URL}/deleteRoles`, {
      method: 'DELETE',
      body: JSON.stringify(rolesObject),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('API data for delete role called', data);
        props.setIsDeleteRole(false);
        // Handle data
      })
      .catch(err => {
        console.log('API ERROR delete role', err.message);
      });
  };

  const getPermissionList = async (whereCondition: any, limit: Number, offset: Number) => {
    try {
      const { data, loading, error } = await client.query({
        query: getPermisionListsQuery,
        variables: { whereCondition, limit, offset },
        fetchPolicy: 'network-only',
      });
      if (!loading && data) {
        console.log('data avai', data);

        props.func(data);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };
  const getPermissionListCnt = async (whereCondition: any) => {
    try {
      const { data, loading, error } = await client.query({
        query: getPermisionListCntsQuery,
        variables: { whereCondition },
        fetchPolicy: 'network-only',
      });
      if (!loading && data) {
        props?.setExportDetails && props?.setExportDetails(data.supertokens_roles);
        props.setTotalRecords(data.supertokens_roles.length);
        props.setTotalPages(Math.ceil(data.supertokens_roles.length / 10));
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
    }
  };
  const getRoleDetails = async () => {
    const roleId = props.rowID;
    console.log(roleId, 'this is row id');
    const { data, loading, error } = await client.query({
      query: getSchema,
      variables: { roleId },
      fetchPolicy: 'network-only',
    });
    if (!loading && data?.supertokens_roles?.length > 0) {
      console.log('get Role & permission Details : ', data);
      if (data?.supertokens_roles[0].roleSchema != null || data?.supertokens_roles[0].roleSchema != undefined)
        props.handleEditData(data?.supertokens_roles[0].roleSchema);
      if (data?.supertokens_roles[0].permissionSchema != null || data?.supertokens_roles[0].permissionSchema != undefined)
        props.setHandlePermission(data?.supertokens_roles[0].permissionSchema);
      // console.log("poiuhgyt",data?.supertokens_roles[0].permissionSchema);
    }
  };

  useEffect(() => {
    if (props.searchVal?.length > 0 && props.searchVal !== '') {
      getPermissionList({ role: { contains: props.searchVal } }, 10, parseInt(props.pageNo));
      getPermissionListCnt({ role: { contains: props.searchVal } });
    } else if (props.isFilter === true) {
      if (props.dropDownValue1 === 'Role Name') {
        getPermissionList({ role: { contains: props.dropDownValue2 } }, 10, parseInt(props.pageNo));
        getPermissionListCnt({ role: { contains: props.dropDownValue2 } });
      }
    } else if (props.isRefresh) {
      props.setIsRefresh(false);
      getPermissionList(null, 10, parseInt(props.pageNo));
      getPermissionListCnt(null);
      props.setRefreshToast(true);
      setTimeout(() => {
        props.setRefreshToast(false);
      }, 3000);
    } else {
      getPermissionList(null, 10, parseInt(props.pageNo));
      getPermissionListCnt(null);
    }
    if (props.isDeleteRole) {
      deleteRole();
      props.setDeleteDisabled(true);
      setTimeout(() => {
        props.setIsDeleteRoleToast(true);
      }, 2000);
      setTimeout(() => {
        props.setIsDeleteRole(false);
        props.setIsDeleteRoleToast(false);
      }, 1000);
      getPermissionList(null, 10, parseInt(props.pageNo));
    } else if (props.editRoleDetail == true) {
      console.log('calling the upadte function');
      updateRoleDetails();
      props?.setFormDataRole({});
      props?.setFormDataAdmin({});
      // props.setRowID({})
      props.setRowID(null);
      setTimeout(() => {
        props.setEditRoleDetail(false);
      }, 5000);
    }
    //getPermissionList(null,10,parseInt(props.pageNo));
  }, [props.searchVal, props.isDeleteRole, props.pageNo, props.isRefresh, props.isFilter, props.editRoleDetail, props.setRowID]);

  useEffect(() => {
    if (props.saveRoleDetails == true) {
      addRole();
      getPermissionListCnt(null);
      // deleteRole(role);
      // deleteRole();
      // getSwdList(null,10,parseInt(props.pageNo));
      // getSwdListCnt(null);
      props?.setSaveRoleDetails(false);
      props?.setFormDataRole({});
      props?.setFormDataAdmin({});
    }
    if (props.rowID) {
      getRoleDetails();
    }
  }, [props.rowID, props.saveRoleDetails]);
  return <></>;
};
