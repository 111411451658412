import { useState, useEffect, useContext, useRef } from 'react';
import { Configuration_func } from '../../../api/automation/configuration_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
//import { DataTable } from "../../../components/data_table/dataTable.component";
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import handleNextBtnClick from '../../../components/side_menu/overlay_side_menu.component';

import DialogBox from '../../../components/dialog/dialog.component';
import { ToastComponent } from '../../../components/toast/toast.component';

import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  createTableColumn,
  TableCellLayout,
  Card,
  Image,
  Input,
  CardHeader,
} from '@fluentui/react-components';
import {
  AddSquare20Regular,
  SaveCopy20Regular,
  Delete20Regular,
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  CalendarClock20Regular,
  Wand20Regular,
  SettingsCogMultiple20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  Save20Regular,
  Info20Filled,
  Dismiss16Regular,
  CheckmarkLock20Regular,
  MoreHorizontal20Regular,
  DismissSquareRegular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { Inputs } from '../../../components/textarea/input.component';
import { Intent } from '../../../components/messagebar/messagebar.component';
import { Form } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../../components/form-components';
import { GlobalContext } from '../../../hooks/globalContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, messageTheme, lightTheme } from '../../../styles/customStyles';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Automation_config = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const formRef = useRef(null);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('Introduction');
  const [dartSpecs, setDartSpecs]: any = useState([]);
  const [automationOverLayData, setAutomationOverLayData]: any = useState([]);
  const [jsonSchema, setJsonData]: any = useState({});
  const [uiSchema, setUiData]: any = useState({});
  const [formData, setFormData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [formEditedData, setFormEditedData]: any = useState({
    name: '',
    description: '',
    jsonData: '',
  });
  const [deleteAutomation, setDeleteAutomation]: any = useState(false);
  const [isRefresh, setRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [isToast, setIsToast] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [automationStatus, setAutomationStatus] = useState('not attached');
  const [errorToast, setErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };
  // const [error,setError]:any = useState(true);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [selectedValue, setSelectedValue] = useState(0);
  const [dropdownData, setDropdownData] = useState(null);
  const [dropDownValue, setDropDownValue] = useState<any>();
  const [dartTemplatesList, setDartTemplatesList] = useState<any>([]);

  // console.log("CHECKING THE ERROR HOOK",error)
  const handlenextbutton = () => {
    setActiveTab('Configure');
  };
  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const buttonref: any = useRef(null);
  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  // const somefunction =()=>{
  //   const form = document.getElementById("myForm");
  //   form?.addEventListener("submit", function(event) {
  //   const requiredFields = form?.querySelectorAll("[required]");
  //   let isEmpty = false;
  //   for (let i = 0; i < requiredFields.length; i++) {
  //     const field = requiredFields[i];
  //     alert("here")
  //     if ((field as HTMLInputElement)?.value?.trim() === "") {
  //       isEmpty = true;
  //       alert("fill the form")
  //       event.preventDefault();
  //       // break;
  //     }
  //     return true;
  //   }
  //   if (!isEmpty) {
  //    alert("form filled");
  //   }
  //   return false;
  // });
  // }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // handleNextBtnClick(e);
  };

  // const validate=()=>{
  //   console.log(formData.name);
  //   setError(false);
  //   if(formData.name === '')
  //   { console.log("CODE CHECK WHEN DATA EMPTY")
  //     setError(true);
  //   }
  //   if(formData?.name)
  //   { console.log("CODE CHECK WHEN DATA NOT EMPTY")
  //     setError(false)
  //   }

  // }

  useEffect(() => {
    var dropDownDataObj = dartTemplatesList.map((val: any, i: any) => {
      return {
        key: `op_${i + 1}`,
        label: (
          <Body1
            onClick={() => {
              setSelectedValue(parseInt(val.dartNo));
              contextValue.setDartNo(val.dartNo);
            }}
          >
            {val.templateName}
          </Body1>
        ),
      };
    });

    setDropdownData(dropDownDataObj);
  }, [dartTemplatesList]);

  useEffect(() => {
    var flag = true;
    exportDetails.map((val: any) => {
      if (val.automationName === formData.name || (overlayName == 'editAutomation' && val.automationName === formEditedData.name)) {
        flag = false;
      }
    });

    if (overlayName == 'editAutomation' && formEditedData.name === selectedAutomation) {
      flag = true;
    }

    setClassificationNameValidation(flag);
  }, [formData, formEditedData, selectedAutomation]);

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/all_automations')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> All&nbsp;Automations </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/automate/automation/all_automations/configuration')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <SettingsCogMultiple20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Automations </Caption1>
          </StackItem>
          <StackItem>
            <Stack tokens={{ childrenGap: 44 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/selfhelp');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <SettingsCogMultiple20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1>Self-Help </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 44 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '69');
        contextValue.setDartNo(69);
        navigate('/automate/automation/autoheal');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Wand20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Autoheal </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        localStorage.setItem('dartNo', '304');
        contextValue.setDartNo(304);
        navigate('/automate/automation/automationschedule');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CalendarClock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Automation Schedules </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        contextValue.setDartNo(241);
        navigate('/automate/automation/usersurvey');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ChatMultipleHeart20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> User Surveys </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key={3}
      onClick={() => {
        navigate('/automate/automation/compliance_policies/policy_configuration');
      }}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <CheckmarkLock20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/automate/automation/activitylog')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookTemplate20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Activity Log </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created by </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modified', label: <Body1Strong> Last Modified </Body1Strong> },
    { key: 'status', label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  var columns1 = [
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'created_by', label: <Caption1> Created by </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modified', label: <Caption1> Last Modified </Caption1> },
    { key: 'status', label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <AddSquare20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1
                  onClick={() => {
                    setOverlayOpen(true);
                    setOverlayName('addAutomation');
                    contextValue.setDartNo(0);
                    setSelectedValue(0);
                    setAutomationStatus('not attached');
                    setFormData({ ...formData, name: '', description: '' });
                  }}
                >
                  Add Automation
                </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
        <StackItem>
        <Divider vertical/>
        </StackItem> */}
        <StackItem>
          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {/* <Delete20Regular {...primaryIcon} />  */}
                      {isdeletedisabled ? <Delete20Regular {...secondaryIcon} /> : <Delete20Regular {...primaryIcon} />}
                    </StackItem>
                    <StackItem>
                      <Caption1> Delete </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content="Kindly verify your intent to delete the automation."
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setDeleteAutomation(true);
                        userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} automations deleted"}`);
                      }}
                      size="small"
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>
        <StackItem>
          <Divider vertical />
        </StackItem>
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setRefresh(true);
              setCurrentPageNo(1);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="configurationlist.csv">
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                userActivity('Export', `{"summary":"Automation list exported"}`);
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  const handleNewFormChange = (e: any) => {
    setFormData({ ...formData, jsonData: e.formData });
  };

  const handleEditFormChange = (e: any) => {
    setFormEditedData({ ...formEditedData, jsonData: e.formData });
  };

  const searchHandle = (data: any) => {
    console.log('exportDetails=', exportDetails);

    var data = data.toLowerCase();
    var searchData = exportDetails.filter((val: any) => val.automationName.toLowerCase().includes(data));

    var searchedDataObj: any = { core_AutomationJSON: [] };
    searchedDataObj.core_AutomationJSON = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil(searchData.length / 50));
    setCurrentPageNo(1);
  };

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    var tempExportData: any = [];
    var status = '';

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        if (val.status == 'not attached') {
          status = 'Not attached';
        } else {
          var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

          if (curStatus.length == 0) {
            status = 'Not attached';
          } else {
            curStatus = curStatus[0]?.split(':')[1];

            if (curStatus == 'all') {
              status = 'Enabled for All Devices';
            } else {
              status =
                curStatus?.split(',').length == 1
                  ? `Enable for ${curStatus?.split(',').length} Device Classification`
                  : `Enable for ${curStatus?.split(',').length} Device Classifications`;
            }
          }
        }

        tempExportData.push({
          name: val.automationName,
          description: val.description,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modified: new Date(val.lastModified * 1000).toLocaleString(),
          status: status,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);
    var status = '';
    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any, i: any) => {
          if (val.status == 'not attached') {
            status = 'Not attached';
          } else {
            var curStatus = val.status?.split('|').filter((val: any) => val.includes(`${contextValue.tenantName}:`));

            if (curStatus.length == 0) {
              status = 'Not attached';
            } else {
              curStatus = curStatus[0]?.split(':')[1];

              if (curStatus == 'all') {
                status = 'Enabled for All Devices';
              } else {
                status =
                  curStatus?.split(',').length == 1
                    ? `Enable for ${curStatus?.split(',').length} Device Classification`
                    : `Enable for ${curStatus?.split(',').length} Device Classifications`;
              }
            }
          }

          tempTableData.push({
            id: val.id,
            checkbox: status == 'Not attached' ? 'show' : 'hide',
            name: {
              label: (
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={() => {
                    setRowID(val.id);
                    contextValue.setDartNo(val.dartid);
                    setOverlayOpen(true);
                    setOverlayName('editAutomation');
                    val.status.includes(`${contextValue.tenantName}:`) ? setAutomationStatus('attached') : setAutomationStatus('not attached');
                    setSelectedAutomation(val.automationName);
                  }}
                >
                  {val.automationName}
                </Button>
              ),
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'} </Caption1> },
            status: { label: <Caption1> {status} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    if (Object.keys(jsonSchema).length > 0) {
      var varValues = Object.keys(jsonSchema?.properties);

      var tempObj: any = {};
      varValues.map((ele: any) => {
        tempObj[`${ele}`] = jsonSchema.properties[`${ele}`]?.default;
      });

      setFormData({ ...formData, jsonData: tempObj });
    }
  }, [jsonSchema]);

  useEffect(() => {
    if (dartSpecs?.profile_DartTemplateDetails?.length > 0 || selectedValue === 0) {
      var details = dartSpecs?.profile_DartTemplateDetails?.length > 0 ? dartSpecs?.profile_DartTemplateDetails[0]?.details : '{}';
      details = JSON.parse(details);

      var os: any = dartSpecs?.profile_DartTemplateDetails?.length > 0 ? dartSpecs?.profile_DartTemplateDetails[0]?.supportingOS : [];

      var templateName = dartSpecs?.profile_DartTemplateDetails?.length > 0 ? dartSpecs?.profile_DartTemplateDetails[0]?.templateName : '';

      overlayName == 'addAutomation' &&
        setAutomationOverLayData({
          headerTitle: 'Add a New Automation',
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction:
              selectedValue == 0 ? (
                <form id="myForm" onSubmit={e => handleSubmit(e)}>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    <StackItem>
                      <Body1Strong>
                        Choose the template <Image src="/visualization/v2/image/required.svg" />
                      </Body1Strong>
                    </StackItem>
                    <StackItem>
                      <DropdownComponent
                        optionData={dropdownData}
                        setDropDownValue={setDropDownValue}
                        dropDownvalue={dropDownValue}
                        required={true}
                      />
                    </StackItem>
                  </Stack>
                </form>
              ) : (
                <form id="myForm" onSubmit={e => handleSubmit(e)}>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    <StackItem>
                      <Body1Strong>
                        Choose the template <Image src="/visualization/v2/image/required.svg" />
                      </Body1Strong>
                    </StackItem>

                    <StackItem>
                      <DropdownComponent
                        defaultSelected={dropDownValue}
                        optionData={dropdownData}
                        setDropDownValue={setDropDownValue}
                        dropDownvalue={dropDownValue}
                        required
                      />
                    </StackItem>

                    {dropDownValue != '' && (
                      <Stack tokens={{ childrenGap: 15 }}>
                        <StackItem>
                          <p>
                            <b>
                              Name <Image src="/visualization/v2/image/required.svg" />
                            </b>
                          </p>
                        </StackItem>
                        <StackItem>
                          <p>
                            <Inputs inputChange={setFormData} inputVal={formData} required={true} />
                          </p>
                          {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                        </StackItem>

                        <StackItem>
                          <p>
                            <b>What does this Automation Do?</b>
                          </p>
                        </StackItem>
                        <StackItem>
                          <p>
                            <Resize inputChange={setFormData} inputValue={formData} />
                          </p>
                        </StackItem>
                      </Stack>
                    )}

                    <StackItem>
                      <FluentProvider theme={messageTheme}>
                        <Card appearance="subtle" size="small">
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Info20Filled {...primaryIcon} />
                            </StackItem>
                            <StackItem>
                              <Caption1> These are the basic details associated with this automation.</Caption1>
                            </StackItem>
                          </Stack>
                        </Card>
                      </FluentProvider>
                    </StackItem>

                    <StackItem>
                      <Stack horizontal horizontalAlign="space-between">
                        <StackItem>
                          <Stack horizontal>
                            <Body1Strong>Operating System - &nbsp;</Body1Strong>
                            {os.includes('windows') && (
                              <StackItem>
                                <Image src="/visualization/v2/image/cardWindows.svg" alt="Windows" />
                              </StackItem>
                            )}
                            {os.includes('android') && (
                              <StackItem>
                                <Image src="/visualization/v2/image/cardAndroid.svg" alt="Android" />
                              </StackItem>
                            )}
                            {os.includes('ios') && (
                              <StackItem>
                                <Image src="/visualization/v2/image/cardIos.svg" alt="Ios" />
                              </StackItem>
                            )}
                          </Stack>
                        </StackItem>
                      </Stack>
                    </StackItem>

                    {Object.keys(details).map(key => (
                      <>
                        <StackItem>
                          <p>
                            <b>{key}</b>
                          </p>
                        </StackItem>
                        <StackItem>
                          <p>{details[key]}</p>
                        </StackItem>
                      </>
                    ))}
                  </Stack>
                  <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                    Validate
                  </Button>
                </form>
              ),
            Configure: (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <Form
                    ref={formRef}
                    formData={formData.jsonData}
                    schema={jsonSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    widgets={CustomWidgets}
                    onChange={e => handleNewFormChange(e)}
                  />
                </StackItem>
              </Stack>
            ),
            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormData} inputVal={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormData} inputValue={formData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Form formData={formData.jsonData} schema={jsonSchema} uiSchema={uiSchema} validator={validator} widgets={CustomWidgets} disabled />
                </StackItem>
              </Stack>
            ),
          },
        });

      Object.keys(formData).length > 0 &&
        overlayName === 'editAutomation' &&
        automationStatus === 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `Edit ${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Introduction: (
              <form id="myForm" onSubmit={e => handleSubmit(e)}>
                <Stack tokens={{ childrenGap: 2 }}>
                  <Stack tokens={{ childrenGap: 5, padding: 6 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Body1Strong>Template name - </Body1Strong> <Body1Strong>{templateName}</Body1Strong>
                    </StackItem>
                  </Stack>
                  <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                    <StackItem>
                      <p>
                        <b>
                          Name <Image src="/visualization/v2/image/required.svg" />
                        </b>
                      </p>
                    </StackItem>
                    <StackItem>
                      <p>
                        <Inputs inputChange={setFormEditedData} inputVal={formEditedData} required={true} />
                      </p>
                      {!classificationNameValidation && <Body1>This automation name already exists</Body1>}
                    </StackItem>

                    <StackItem>
                      <p>
                        <b>What does this Automation Do?</b>
                      </p>
                    </StackItem>
                    <StackItem>
                      <p>
                        <Resize inputChange={setFormEditedData} inputValue={formEditedData} />
                      </p>
                    </StackItem>
                  </Stack>

                  {/* <StackItem>
                <DropdownComponent defaultSelected={dropDownValue} optionData={dropdownData} setDropDownValue={setDropDownValue} dropDownvalue={dropDownValue} required />
              </StackItem> */}

                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These are the basic details associated with this automation.</Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>

                  <StackItem>
                    <Stack horizontal horizontalAlign="space-between">
                      <StackItem>
                        <Stack horizontal>
                          <Body1Strong>Operating System - &nbsp;</Body1Strong>
                          {os.includes('windows') && (
                            <StackItem>
                              <Image src="/visualization/v2/image/cardWindows.svg" alt="Windows" />
                            </StackItem>
                          )}
                          {os.includes('android') && (
                            <StackItem>
                              <Image src="/visualization/v2/image/cardAndroid.svg" alt="Android" />
                            </StackItem>
                          )}
                          {os.includes('ios') && (
                            <StackItem>
                              <Image src="/visualization/v2/image/cardIos.svg" alt="Ios" />
                            </StackItem>
                          )}
                        </Stack>
                      </StackItem>
                    </Stack>
                  </StackItem>

                  {Object.keys(details).map(key => (
                    <>
                      <StackItem>
                        <p>
                          <b>{key}</b>
                        </p>
                      </StackItem>
                      <StackItem>
                        <p>{details[key]}</p>
                      </StackItem>
                    </>
                  ))}
                </Stack>
                <Button ref={buttonref} type="submit" style={{ display: 'none' }}>
                  Validate
                </Button>
              </form>
            ),

            Configure: (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <Form
                  ref={formRef}
                  formData={formEditedData.jsonData}
                  schema={jsonSchema}
                  uiSchema={uiSchema}
                  validator={validator}
                  widgets={CustomWidgets}
                  onChange={e => handleEditFormChange(e)}
                />
              </Stack>
            ),

            'Review Data': (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Form
                    formData={formEditedData.jsonData}
                    schema={jsonSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    widgets={CustomWidgets}
                    disabled
                  />
                </StackItem>
              </Stack>
            ),
          },
        });

      Object.keys(formData).length > 0 &&
        overlayName === 'editAutomation' &&
        automationStatus !== 'not attached' &&
        setAutomationOverLayData({
          headerTitle: `${selectedAutomation} automation`,
          size: 'large',
          isTabs: true,
          tabItems: {
            Configuration: (
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <p>
                    <b>Name</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Inputs inputChange={setFormEditedData} inputVal={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <p>
                    <b>What does this Automation Do?</b>
                  </p>
                </StackItem>
                <StackItem>
                  <p>
                    <Resize inputChange={setFormEditedData} inputValue={formEditedData} disabled={true} />
                  </p>
                </StackItem>

                <StackItem>
                  <Form
                    formData={formEditedData.jsonData}
                    schema={jsonSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    widgets={CustomWidgets}
                    disabled
                  />
                </StackItem>
              </Stack>
            ),
          },
        });
    }
  }, [overlayName, formData, formEditedData, classificationNameValidation]);

  useEffect(() => {
    !overlayOpen && setOverlayName('');
  }, [overlayOpen]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Automation',
      moduleName: 'Automation',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  useEffect(() => {
    if (saveBtnClicked) {
      overlayName == 'addAutomation' &&
        userActivity(
          'Add',
          `{"automationName":"${formData.name}","dartNo":"${contextValue.dartNo}","configuration":${JSON.stringify(
            formData.jsonData,
          )},"type":"Automation","summary":"${formData.name} automation is created"}`,
        );

      overlayName == 'editAutomation' &&
        userActivity(
          'Edit',
          `{"automationName":"${formData.name}","dartNo":"${contextValue.dartNo}","configuration":${JSON.stringify(
            formEditedData.jsonData,
          )},"type":"Automation","summary":"${formEditedData.name} automation is edited"}`,
        );
    }
  }, [saveBtnClicked]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  useEffect(() => {
    errorToast && userActivity('Edit', '{"summary":"Tried to edit the automation that is already configured"}', 'failure');
  }, [errorToast]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Automation" subPageName="All Automation" subPageLink="/automate/automation/all_automations" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Configuration" /> </Text>  */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Automation | Configuration </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      <Divider />
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              searchHandle(e.target.value);
                            }}
                            placeholder="Search"
                            size="small"
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Badge appearance="filled" color="informative">
                                  <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem>{item}</StackItem>
                                    <StackItem>
                                      <Dismiss16Regular
                                        onClick={() => {
                                          handleCloseFilter(index);
                                        }}
                                      ></Dismiss16Regular>
                                    </StackItem>
                                  </Stack>
                                </Badge>
                              ))}
                          </StackItem>
                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10, padding: 5 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              {/* <CardHeader action={
                        <Button
                          appearance="transparent"
                          icon={<DismissSquareRegular />}
                          aria-label="More options"
                        />
                      }></CardHeader> */}
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem>
                                  <Info20Filled {...primaryIcon} />
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Explore the Nanoheal Database for an in-depth understanding of this Automation Template. Learn more about its
                                    capabilities, features, and recommended practices to enhance your utilization of this automation solution within
                                    your ecosystem.
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </StackItem>
                      </Stack>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      {tableData.length > 0 ? (
                        <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                          <StackItem>
                            {totalRecords > 0 && (
                              <Caption1>
                                Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                                {totalRecords} Records
                              </Caption1>
                            )}
                          </StackItem>
                          <StackItem>
                            <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                          </StackItem>
                        </Stack>
                      ) : (
                        <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Caption1> No Results.</Caption1>
                          </StackItem>
                        </Stack>
                      )}
                    </StackItem>

                    {!saveBtnClicked && overlayName == '' && (
                      <Configuration_func
                        data={final_data}
                        func={set_final_data}
                        setDartSpecs={setDartSpecs}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setJsonData={setJsonData}
                        setUiData={setUiData}
                        deleteAutomation={deleteAutomation}
                        setDeleteAutomation={setDeleteAutomation}
                        setDeleteDisabled={setDeleteDisabled}
                        isRefresh={isRefresh}
                        setRefresh={setRefresh}
                        overlayName={overlayName}
                        setIsToast={setIsToast}
                        setRefreshToast={setRefreshToast}
                        dropdownData={dropdownData}
                        setDartTemplatesList={setDartTemplatesList}
                        selectedValue={selectedValue}
                        saveBtnClicked={saveBtnClicked}
                        setExportDetails={setExportDetails}
                      />
                    )}

                    {saveBtnClicked && (
                      <Configuration_func
                        newData={overlayName == 'addAutomation' ? formData : formEditedData}
                        saveBtnClicked={saveBtnClicked}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setSaveBtnClicked={setSaveBtnClicked}
                        overlayName={overlayName}
                        rowID={rowID}
                        setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                        func={set_final_data}
                        setIsToast={setIsToast}
                        setOverlayOpen={setOverlayOpen}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        dropdownData={dropdownData}
                        selectedValue={selectedValue}
                      />
                    )}

                    {overlayName != '' && (
                      <Configuration_func
                        rowID={rowID}
                        overlayName={overlayName}
                        setFormData={overlayName == 'addAutomation' ? setFormData : setFormEditedData}
                        formData={overlayName == 'addAutomation' ? formData : formEditedData}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setJsonData={setJsonData}
                        setUiData={setUiData}
                        setDartSpecs={setDartSpecs}
                        setErrorToast={setErrorToast}
                        setToastMessage={setToastMessage}
                        dropdownData={dropdownData}
                        selectedValue={selectedValue}
                        saveBtnClicked={saveBtnClicked}
                        isRefresh={isRefresh}
                        deleteAutomation={deleteAutomation}
                      />
                    )}

                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} setOverlayOpen={setOverlayOpen}></DataTable>}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            overlayData={automationOverLayData}
            saveBtnFunc={setSaveBtnClicked}
            isNavBtn={automationStatus !== 'not attached' ? false : true}
            formRef={formRef}
            handleOutsideButton={handleOutsideButton}
            handleSubmit={handleSubmit}
            classificationNameValidation={classificationNameValidation}
          />
          {isToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={isToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={
                !saveBtnClicked && overlayName == ''
                  ? 'Automation Deleted Successfully'
                  : overlayName == 'addAutomation'
                  ? 'New Automation Created Successfully'
                  : 'Automation Edited Successfully'
              }
              footer=""
            />
          )}

          {errorToast && (
            <ToastComponent setmessagebar={setErrorToast} toastIntent="error" position="top-end" title="Failed" message={toastMessage} footer="" />
          )}
          {refreshToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={refreshToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={'Data Table Refreshed'}
              footer=""
            />
          )}
        </StackItem>
      </FullScreen>
    </>
  );
};
