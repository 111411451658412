import React, { useEffect ,useContext, useState} from 'react'
import { useQuery, useApolloClient } from '@apollo/client';
import { GlobalContext } from '../hooks/globalContext';

// import { getStatusListsCntQuery,getStatusListsQuery } from '../graphql/status_queries';
// import { getInstallStatus,getCensusList,getInstallStatusCnt,getEdconfigQuery, setExecuteTroubleShooter} from '../graphql/app_install_status_queries';
import { getInstallStatus,getCensusList,getInstallStatusCnt,getswdForm,getJsonSchemaQueryPackageDetails,getJsonSchemaQueryAdvanceConfiguration,getJsonSchemaQueryAppConfiguration,getEdconfigQuery,deployPackageJob,getStatus} from '../graphql/app_install_status_queries';
import { matchPath } from 'react-router-dom';


type dataProps = {
    data?:any;
    func?:any;
    func1?:any;
    searchVal: any
    pageNo: any
    setTotalPages: any
    setTotalRecords: any
    savePackageDetails?: any; // Making it optional
    setSavePackageDetails? :any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropDownValue2?:any
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
    deployPackageDetails?:any
    setDeployPackageDetails?:any
    setRowId?:any
    rowId?:any
    handleEditData?:any
    setJsonDataPkg?:any  
    setUiDataPkg?:any  
    setJsonDataApp:any
    setUiDataApp:any
    setJsonDataAdv:any
    setUiDataAdv:any
    rowDesc?:any
}

export const Install_Status_func = (props:dataProps) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    const [edconfig, setEdconfig] = useState(null);
    var tenantName = contextValue.tenantName;
    console.log("CHECKING THE EDCONFIG FROM PACKAGEID",edconfig)
    const getInstallList = async (whereCondition: any,limit:Number, offset:Number ) => {
        console.log("inside getInstalllist func");
        try {
            const { data, loading, error } = await client.query({
                query:getInstallStatus,
                variables: {whereCondition,limit,offset},
                fetchPolicy: "network-only",

            });
              console.log("asasasasa",getInstallStatus)  
            if(!loading && data){
                console.log("main table data=", data);
                console.log("Swd data=",data.softinst_Packages);
                if (props.func) {
                    props.func(data);
                    getStatusList(null);
                  }
            }
        }catch (error) {
            console.error("An unexpected error occurred:", (error as Error).message);
            // Handle unexpected errors, log them, and potentially notify the user
        }
    };
    const getSwdListCnt = async (whereCondition: any ) => {
        try{
          const { data, loading, error } = await client.query({
              query: getInstallStatusCnt,
              variables: {whereCondition},
              fetchPolicy: "network-only",
          });
 
          if(!loading && data){
              props.setTotalRecords(data.softinst_Packages.length);
              props.setTotalPages(Math.ceil((data.softinst_Packages.length)/50));
          }
        }catch (error){
            console.error("An unexpected error occurred:", (error as Error).message);
            // Handle unexpected errors, log them, and potentially notify the user
        }
      };

      
      const getStatusList = async (whereCondition: any) => {
        console.log("inside getInstalllist func");
        try {
            const { data, loading, error } = await client.query({
                query:getStatus,
                variables: {whereCondition},
                fetchPolicy: "network-only",

            });
            if(!loading && data){
                if(props.func1)
                {
                  props.func1(data)
                }
                console.log("successful query")
            }
        }catch (error) {
            console.error("An unexpected error occurred:", (error as Error).message);
            // Handle unexpected errors, log them, and potentially notify the user
        }
    };

    const getCensusLists = async (whereCondition: any, limit: number, offset: number, order: any) => {
        const { data, loading, error } = await client.query({
            query: getCensusList,
            variables: {whereCondition, limit, offset, order}
        });

        if(!loading && data){
            console.log("Census data=",data.core_Census); 
            if (props.func) {
                props.func(data);
              }
        }
    };

    const getEdconfig = async () => {
        const packageId = props.rowId
        const { data, loading, error } = await client.query({
            query: getEdconfigQuery,
            variables: {packageId}
        });

        if(!loading && data){
            console.log("Census data=",data); 
            setEdconfig(data.softinst_PackagesConfiguration_new[0].edConfig)
        }
    };
    

    const deploy_exec = async(machineName:any,packageIdPush:any,packageDescPush:any) => {
        // const variable = edconfig
        // const Name = contextValue.selectedRowIdInTable
          try {
            const {data} = await client.mutate({
                mutation : deployPackageJob,
                variables : {packageIdPush,machineName,packageDescPush},
                // variables: {OS,type,Name,Dart, variable, shortDesc, Jobtype, ProfileName, NotificationWindow, ProfileOS},
                fetchPolicy: "network-only",
            })
            //adding the configuration for the above package and its applications to the
            if (data){
                // console.log("number of affected rows",data);
                console.log(
                  "rows affected:",
                  data
                );
              }
            
          } catch (error) {
            console.error(
              "An unexpected error occurred:",
              (error as Error).message
            );
          }
    }
    const getForm = async () => {
      const app_packageiD=props.rowId;
      try {
          const { data, loading, error } = await client.query({
              query: getswdForm,
              variables: {app_packageiD},
              fetchPolicy: "network-only",
              // fetchPolicy: "network-only"
          });
          // const dataa=data.softinst_PackagesConfiguration_new.formDataApp;
          if(!loading && data){
            props.handleEditData(data)
            console.log("YESSSSSSS")
        }
      }catch (error) {
          console.error("An unexpected error occurred:", (error as Error).message);
          // Handle unexpected errors, log them, and potentially notify the user
      }
  };

  const getJsonSchemaQueryPkg= async () => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQueryPackageDetails,
    });

    if(!loading && data){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
      console.log("jsonSchemaData=",jsonSchemaData);
      (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataPkg(jsonSchemaData);
      // (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setUiData(jsonSchemaData);
      // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataPkg(uiSchema);
    }
  };

  const getJsonSchemaQueryApp= async () => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQueryAppConfiguration,
      // variables: {dartid},
      // fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
      console.log("jsonSchemaData=",jsonSchemaData);
      (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataApp(jsonSchemaData);
      // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataApp(uiSchema);
    }
  };

  const getJsonSchemaQueryAdv= async () => {
    const { data, loading, error } = await client.query({
      query: getJsonSchemaQueryAdvanceConfiguration,
      // variables: {dartid},
      // fetchPolicy: 'network-only',
    });

    if(!loading && data){
      var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
      uiSchema["ui:submitButtonOptions"] = {
       "norender": true,
      };

      var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
      console.log("jsonSchemaData=",jsonSchemaData);
      (data?.core_DartJsonSchema?.[0]?.jsonSchema ?? null) !== null && props.setJsonDataAdv(jsonSchemaData);
      // data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
      data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiDataAdv(uiSchema);
    }
  };
    
    const deployPackage = () => {
        console.log("length",contextValue.selectedRowIdInTable.length)
        console.log("fhrekjd",contextValue.selectedRowIdInTable);
        const packageIdPush :any = props?.rowId;
        const packageDescPush:any = props.rowDesc
        console.log("packageId is here",packageIdPush)
        // getEdconfig()
        let machineList :any = contextValue.selectedRowIdInTable
        machineList.map((machineName:any) => {
          deploy_exec(machineName,packageIdPush,packageDescPush)

        })
        
      
    }

    // const getDeviceClassificationListQueries = async (whereCondition: any, limit: number, offset: number, order: any) => {
    //     const { data, loading, error } = await client.query({
    //         query: getDeviceClassificationList,
    //         variables: {whereCondition, limit, offset, order}
    //     });

    //     if(!loading && data){
    //         console.log("Census data=",data.core_Census); 
    //         props.func(data);
    //     }
    // };

    

    useEffect(() => {
        // getList();
        if(props.deployPackageDetails == true){
            deployPackage()
            props.setDeployPackageDetails(false)
        }
        if(props.searchVal.length > 0 && props.searchVal !== ''){
            getInstallList({ packageName: {contains: props.searchVal}},50,parseInt(props.pageNo))
            getSwdListCnt({ packageName: {contains: props.searchVal}});
        }else if(props.isFilter == true){
          if(props.dropDownValue1 == 'Name'){
            getInstallList({ packageName: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getSwdListCnt({ packageName: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 == 'Description'){
            getInstallList({ packageDesc: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getSwdListCnt({ packageDesc: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 == 'Version'){
            getInstallList({ version: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getSwdListCnt({ version: {contains: props.dropDownValue2}});
          }
          else if(props.dropDownValue1 == 'Operating System'){
            getInstallList({ platform: {contains: props.dropDownValue2}},50,parseInt(props.pageNo))
            getSwdListCnt({ platform: {contains: props.dropDownValue2}});
          }
        }
          
        else if(props.isRefresh){
          props.setIsRefresh(false);
          getInstallList(null,50,parseInt(props.pageNo));
          getSwdListCnt(null);
          props.setRefreshToast(true);  
          setTimeout(() => {
            props.setRefreshToast(false);  
          }, 3000);
        }  
        else{
            getInstallList(null,50,parseInt(props.pageNo));
            getSwdListCnt(null);
            getJsonSchemaQueryPkg();
            getJsonSchemaQueryApp();
            getJsonSchemaQueryAdv();
        }

    },[props.searchVal,props.pageNo,props.isRefresh,props.deployPackageDetails, props.isFilter]);
    useEffect(() => {
      if(props.rowId){
        getForm();    
       }
    }, [props.rowId])

  return (
    <>
    </>
  )
}