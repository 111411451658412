import { gql } from '@apollo/client';

const insertActivityLog = gql`
  mutation MyMutation(
    $userId: String = ""
    $pageName: String = ""
    $moduleName: String = ""
    $tenant: String = ""
    $activity: String = ""
    $time: Int = 10
    $initiatedBy: String = ""
    $action: String
    $status: String = ""
  ) {
    insert_audit_ActivityLog(
      objects: {
        activity: $activity
        initiatedBy: $initiatedBy
        moduleName: $moduleName
        pageName: $pageName
        status: $status
        action: $action
        tenant: $tenant
        time: $time
        userId: $userId
      }
    ) {
      affected_rows
    }
  }
`;

const getActivityLogQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int, $offset: Int) {
    audit_ActivityLog(where: $whereCondition, limit: $limit, offset: $offset, order_by: { id: desc }) {
      id
      action
      activity
      initiatedBy
      moduleName
      pageName
      status
      tenant
      time
      userId
    }
  }
`;

export { insertActivityLog, getActivityLogQuery };
