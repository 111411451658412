import { useState, useEffect, SetStateAction, useContext } from "react";
import { Swd_func } from "../../../api/swd_func";
// import { Census_func } from "../../../api/devices/census_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { Card, MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, Dropdown, Popover, PopoverTrigger, PopoverSurface, ButtonProps, Tooltip } from "@fluentui/react-components";
import { AddSquare20Regular, SaveCopy20Regular, Info20Filled, Filter20Filled, FilterDismiss20Filled,SlideEraser20Regular,Warning20Filled,ArrowMaximize20Regular, Desktop20Filled,Delete24Regular,AddSquare20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular,DismissSquare24Filled, AppsAddIn20Regular, BookTemplate20Regular, BoxCheckmark20Regular, CheckboxCheckedSync20Regular, PulseSquare20Regular, ShieldLock20Regular, SquareHintApps20Regular, UninstallApp20Regular,Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { Resize } from "../../../components/textarea/textarea.component";
import { Checked } from "../../../components/switch/switch.component";
import { Intent } from "../../../components/messagebar/messagebar.component";
import { Placeholder } from "../../../components/textarea/placeholder.component";
import { Inputs } from "../../../components/textarea/input.component";
import { AccordionComponent } from "../../../components/accordion/accordion.component";
import { RadioGroups } from "../../../components/radio_group/radioGroup.component";
import FileUploadComponent from "../../../components/fileImport/file.import.component";
import { CloneForm } from "../../../components/bit_confi_form/cloneform";
import { ToastComponent } from "../../../components/toast/toast.component";
import { Form, Widgets } from "@rjsf/fluent-ui";
import React from "react";
import { CustomWidgets } from '../../../components/form-components';
import validator from '@rjsf/validator-ajv8';
//////////////////////////////////////////////////////////
import { GlobalContext } from "../../../hooks/globalContext";
import DialogBox from "../../../components/dialog/dialog.component";
import { RJSFSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, messageTheme, lightTheme, overflowDivider } from "../../../styles/customStyles";
import { useLocation } from "react-router-dom";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";










declare module 'json-schema' {
  export interface JSONSchema7 {
    enumNames?: Array<string>;
  }
}


function handleExportCSV() {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
} 

export const Appuninstallprofile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [rowID,setRowID]:any = useState(null);
  const [installationOverLayData, setInstallationOverLayData]: any = useState([]);

  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isdeletedisabled,setDeleteDisabled]=useState(false);
  const [itemArr, setItemArr]: any = useState([]);
  const [remDiv,setRemDiv]: any =useState(2);
  const [preCheck,setPreCheck]= useState(false);
  const [selectedOption, setSelectedOption] = useState(null || '');
  const [selectUpload,setUpload] =useState('existing');
  const[selectCommon,setCommon] =useState('');
  const[savePackageDetails, setSavePackageDetails] = useState(false);
  const [isToast, setIsToast] = useState(false);  
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter,setIsFilter]=useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [jsonSchemaPkg, setJsonDataPkg]: any = useState({});
  const [uiSchemaPkg, setUiDataPkg]: any = useState({});
  const [jsonSchemaApp, setJsonDataApp]: any = useState({});
  const [uiSchemaApp, setUiDataApp]: any = useState({});
  const [jsonSchemaAdv, setJsonDataAdv]: any = useState({});
  const [uiSchemaAdv, setUiDataAdv]: any = useState({});
  const [formDataUser, setFormDataUser] = useState(null);

  // const [formDataPkg, setFormDataPkg] = React.useState(null);
  const [formDataPkg, setFormDataPkg] = React.useState({});
  const [formDataApp, setFormDataApp] = React.useState({});
  const [formDataAdv, setFormDataAdv] = React.useState({});
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)
  
  const[status,setStatus]=useState(false);

// console.log("checking the formdataAPP",formDataApp)
console.log("checking the formData of pkg configuration",formDataPkg)
console.log("checking the formData of App configuration",formDataApp)
console.log("checking the formData of Adv configuration",formDataAdv)
console.log("checking rowID here ",rowID)

// console.log("checking the formData of ADVANCECONFIGURATION configuration",formDataAdv)

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
console.log("checking the formdatAdv",formDataAdv)
// call the save package here
const saveBtnFunc = () => {
    //   if(formDataPkg || formDataApp || formDataAdv == null){
    //     alert("alert form is empty")
    //   }
    //   else{
       setSavePackageDetails(true) 
      setIsToast(true) 
      // } 
      setOverlayOpen(false)
}
const handleFilterArray = ():any => {
  const value1 = dropDownValue1
  const value2 = dropDownValue2;
  if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
    const result = value1 + " : " + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  }
}

const handleCloseFilter = (index:any):any  => {
  console.log("clicked");
  const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
  setFilterArray(updatedArray);
}

useEffect(() => {
 if(filterArray.length > 0)
 setResetDisabled(false)

}, [filterArray])

const inputChange=(e:any)=>{
  const enteredValue =e.target.value;
  console.log("entered value",enteredValue)
  let name =e.target.name;
  if (name === 'filter') {
    setDropDownValue2(enteredValue);
  } 
}

const handleEditData = (data:any) => {
  const pkgDetails = JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataPkg)
  const appDetail=JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataApp)
  const advDetail=JSON.parse(data.softinst_PackagesConfiguration_new[0].formDataAdv)
  setFormDataPkg(pkgDetails)
  setFormDataApp(appDetail);
  setFormDataAdv(advDetail);
}
console.log('datapkg',formDataUser)

  const [deleteprofile, setDeleteprofile]: any = useState(false);
  const[deleteId,SetDeleteId]:any= useState([]);

  //advance config start

  const[readfile,setReadFile]=useState(false);
  const[postvalidation,setPostvalidaation]=useState(false);

  //advance config end
// logic for clone form starts here
interface FormItem {
  id: number;
  data: {};
}

const [selectedFrs, setSelectedFrs] = React.useState('files');
const handleFrs=(e:any)=>
    {   
        var optionFrs = e.target.value;
        setSelectedFrs(optionFrs);
    }
  const [cloneForms, setCloneForms] = useState<FormItem[]>([]);

  const handleAddClone = () => {
    if (cloneForms.length < 3) {
      setCloneForms([...cloneForms, { id: Date.now(), data: {} }]);
      
    }
    
  };
  const handleRemoveClone = (id:any) => {
    setCloneForms(cloneForms.filter((form) => form.id !== id));
  };

  //will remove later after checking
  const handleOptionChange = (value :any) => {
    var optionValue = value.target.value;
    setSelectedOption(optionValue);

  if(optionValue ==='yes' && cloneForms.length != 4)
  {
    handleAddClone();
    setSelectedOption('');
  }
  else{
    setSelectedOption('no');
  }
  }

  //package
  // const jsonSchema1: RJSFSchema = 
  //   { 
  //     "type": "object",
  //     "properties": {
  //         "Package_name": {
  //             "type": "string",
  //             "title": "Package Name"
  //         },
  //         "Package_version": {
  //             "type": "string",
  //             "title": "Package Version"
  //         },
  //         "Package_description": {
  //             "type": "string",
  //             "title": "Package Description"
  //         },
  //         "OS": {
  //             "type": "string",
  //             "title": "Target Operating System",
  //             "enum": [
  //                 "1",
  //                 "2"
  //             ],
  //             "enumNames": [
  //                 "Windows",
  //                 "Android"
  //             ],
  //             "default": "Windows"
  //         }, 
  //     }
  // }
  ;
  // const jsonui1 = {
  //     "Package_name": {
  //         "ui:widget": "text"
  //     },
  //     "Package_version": {
  //         "ui:widget": "text"
  //     },
  //     "Package_description": {
  //         "ui:widget": "textarea",
  //         "ui:options": {
  //             "rows": 3,
  //         }
  //     },
  //     'ui:submitButtonOptions': {
  //       norender: true,
  //     }
  // }

  // App configuration
  const jsonSchema2:RJSFSchema = {
    "type": "array",
    "minItems": 1,
    "maxItems": 4,
    "items": {
      "type": "object",
      "allOf": [
        {
          "type": "object",
          "properties": {
            "Application_name": {
              "type": "string",
              "title": "Application Name"
            },
            "Application_details": {
              "title": "Application Details",
              "type": "string",
              "enum": [
                "Upload the app",
                "Select a pre-existing application"
              ],
              "default": "Select a pre-existing application"
            }
          },
          "dependencies": {
            "Application_details": {
              "oneOf": [
                {
                  "properties": {
                    "Application_details": {
                      "enum": [
                        "Upload the app"
                      ]
                    },
                    "commonFilesOption": {
                      "type": "string",
                      "title": "Are the files common for 32 and 64 bit OS",
                      "enum": [
                        "Yes",
                        "No"
                      ]
                    }
                  }
                },
                {
                  "properties": {
                    "Application_details": {
                      "enum": [
                        "Select a pre-existing application"
                      ]
                    },
                    "uploadApplication1": {
                      "type": "object",
                      "title": "",
                      "properties": {
                        "files": {
                          "type": "array",
                          "title": "Upload Application",
                          "items": {
                            "type": "string",
                            "format": "data-url"
                          }
                        }
                      }
                    }
                  }
                }
              ]
            },
            "commonFilesOption": {
              "oneOf": [
                {
                  "properties": {
                    "commonFilesOption": {
                      "enum": [
                        "Yes"
                      ]
                    },
                    "uploadApplication2": {
                      "type": "object",
                      "title": "",
                      "properties": {
                        "files": {
                          "type": "array",
                          "title": "Upload Application",
                          "items": {
                            "type": "string",
                            "format": "data-url"
                          }
                        }
                      }
                    }
                  }
                },
                {
                  "properties": {
                    "commonFilesOption": {
                      "enum": [
                        "No"
                      ]
                    },
                    "uploadApplication32Bit": {
                      "type": "object",
                      "title": "",
                      "properties": {
                        "files": {
                          "type": "array",
                          "title": "Upload Application 32 bit",
                          "items": {
                            "type": "string",
                            "format": "data-url"
                          }
                        }
                      }
                    },
                    "uploadApplication64Bit": {
                      "type": "object",
                      "title": "",
                      "properties": {
                        "files": {
                          "type": "array",
                          "title": "Upload Application 64 bit",
                          "items": {
                            "type": "string",
                            "format": "data-url"
                          }
                        }
                      }
                    }
                  }
                }
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "configuration32": {
              "type": "object",
              "title": "32 bit Configuration: Download"
            },
            "config32bit": {
              "type": "string",
              "title": "Perform a Pre-download check"
            },
            "Download_location32bit": {
              "title": "Download Location",
              "type": "string",
              "enum": [
                "Default",
                "Specify download location"
              ],
              "default": "Default"
            },
            "Executein32bitd": {
              "title": "Execute in",
              "type": "string",
              "enum": [
                "Fore Ground",
                "Background"
              ]
            },
            "Operation_mode32bit": {
              "type": "string",
              "title": "Download operation mode",
              "enum": [
                "Device Name",
                "Manufacturer",
                "Operating System",
                "Installation Date",
                "Nanoheal Version",
                "Last Event"
              ],
              "enumNames": [
                "Device Name",
                "Manufacturer",
                "Operating System",
                "Installation Date",
                "Nanoheal Version",
                "Last Event"
              ]
            },
            "Messagebox": {
              "type": "string",
              "title": "Message during download"
            },
            "Processterminate": {
              "type": "string",
              "title": "Process to terminate before this action"
            }
          },
          "dependencies": {
            "config32bit": {
              "oneOf": [
                {
                  "properties": {
                    "config32bit": {
                      "enum": [
                        "1"
                      ],
                      "enumNames": [
                        "Enable"
                      ]
                    },
                    "preCheck32bitd": {
                      "title": "What action should be taken when the pre-check value exists?",
                      "type": "string",
                      "enum": [
                        "Execute when pre-check value doesn't exists",
                        "Execute when pre-check value exists"
                      ]
                    },
                    "selectType32bitd": {
                      "type": "string",
                      "title": "Define the condition to be checked",
                      "enum": [
                        "File",
                        "Registry",
                        "Software Name"
                      ]
                    }
                  }
                }
              ]
            },
            "selectType32bitd": {
              "oneOf": [
                {
                  "properties": {
                    "selectType32bitd": {
                      "enum": [
                        "File"
                      ]
                    },
                    "filePath32bitd": {
                      "title": "Specify the file path",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType32bitd": {
                      "enum": [
                        "Registry"
                      ]
                    },
                    "RegrootKey32bitd": {
                      "title": "Registry root key:",
                      "enum": [
                        "HKEY_CLASSES_ROOT",
                        "HKEY_CURRENT_USER",
                        "HKEY_LOCAL_MACHINE",
                        "HKEY_USERS",
                        "HKEY_PERFORMANCE_DATA",
                        "HKEY_PERFORMANCE_TEXT",
                        "HKEY_PERFORMANCE_NLSTEXT",
                        "HKEY_CURRENT_CONFIG",
                        "HKEY_DYN_DATA"
                      ]
                    },
                    "Registrypath32bitd": {
                      "title": "Registry path",
                      "type": "string"
                    },
                    "Regtype32bitd": {
                      "title": "Registry type",
                      "type": "string",
                      "enum": [
                        "REG_SZ",
                        "REG_DWORD",
                        "REG_QWORD",
                        "REG_BINARY",
                        "REG_MULTI_SZ",
                        "REG_EXPAND_SZ"
                      ]
                    },
                    "Registryvalue32bitd": {
                      "title": "Registry value",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType32bitd": {
                      "enum": [
                        "Software Name"
                      ]
                    },
                    "selectType_softName32bitd": {
                      "title": "Software Name ",
                      "type": "string"
                    },
                    "selectType_softVersion32bitd": {
                      "title": "Software Version ",
                      "type": "string"
                    }
                  }
                }
              ]
            },
            "Download_location32bit": {
              "oneOf": [
                {
                  "properties": {
                    "Download_location32bit": {
                      "enum": [
                        "Specify download location"
                      ]
                    },
                    "download_location32bit": {
                      "title": "Specify download location",
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "configuration32Install": {
              "type": "object",
              "title": "32 bit Configuration: Install"
            },
            "config32bitInstall": {
              "type": "string",
              "title": "Perform a Pre-download check"
            },
            "Executein32bitI": {
              "title": "Execute in",
              "type": "string",
              "enum": [
                "Fore Ground",
                "Background"
              ]
            },
            "Installationmode32bit": {
              "type": "string",
              "title": "Choose installation mode:",
              "enum": [
                "CMD",
                "Silent"
              ],
              "enumNames": [
                "CMD",
                "Silent"
              ]
            },
            "CmdInstr32bit": {
              "type": "string",
              "title": "Command line installation instruction:"
            },
            "Max_time32bit": {
              "type": "string",
              "title": "Max execution time for a patch:"
            },
            "Terminate32bit": {
              "type": "string",
              "title": "Terminate process before patching:"
            },
            "MessageBox": {
              "type": "string",
              "title": "Message during installation:"
            }
          },
          "dependencies": {
            "config32bitInstall": {
              "oneOf": [
                {
                  "properties": {
                    "config32bitInstall": {
                      "enum": [
                        "1"
                      ],
                      "enumNames": [
                        "Enable"
                      ]
                    },
                    "preCheck32bitI": {
                      "title": "What action should be taken when the pre-check value exists?",
                      "type": "string",
                      "enum": [
                        "Execute when pre-check value doesn't exists",
                        "Execute when pre-check value exists"
                      ]
                    },
                    "selectType32bitI": {
                      "type": "string",
                      "title": "Define the condition to be checked",
                      "enum": [
                        "File",
                        "Registry",
                        "Software Name"
                      ]
                    }
                  }
                }
              ]
            },
            "selectType32bitI": {
              "oneOf": [
                {
                  "properties": {
                    "selectType32bitI": {
                      "enum": [
                        "File"
                      ]
                    },
                    "filePath32bitI": {
                      "title": "Specify the file path",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType32bitI": {
                      "enum": [
                        "Registry"
                      ]
                    },
                    "RegrootKey32bitI": {
                      "title": "Registry root key:",
                      "enum": [
                        "HKEY_CLASSES_ROOT",
                        "HKEY_CURRENT_USER",
                        "HKEY_LOCAL_MACHINE",
                        "HKEY_USERS",
                        "HKEY_PERFORMANCE_DATA",
                        "HKEY_PERFORMANCE_TEXT",
                        "HKEY_PERFORMANCE_NLSTEXT",
                        "HKEY_CURRENT_CONFIG",
                        "HKEY_DYN_DATA"
                      ]
                    },
                    "Registrypath32bitI": {
                      "title": "Registry path",
                      "type": "string"
                    },
                    "Regtype32bitI": {
                      "title": "Registry type",
                      "type": "string",
                      "enum": [
                        "REG_SZ",
                        "REG_DWORD",
                        "REG_QWORD",
                        "REG_BINARY",
                        "REG_MULTI_SZ",
                        "REG_EXPAND_SZ"
                      ]
                    },
                    "Registryvalue32bitI": {
                      "title": "Registry value",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType32bitI": {
                      "enum": [
                        "Software Name"
                      ]
                    },
                    "selectType_softName32bitI": {
                      "title": "Software Name ",
                      "type": "string"
                    },
                    "selectType_softVersion32bitI": {
                      "title": "Software Version ",
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "configuration64": {
              "type": "object",
              "title": "64 bit Configuration: Download"
            },
            "config64bit": {
              "type": "string",
              "title": "Perform a Pre-download check"
            },
            "Download_location64bit": {
              "title": "Download Location",
              "type": "string",
              "enum": [
                "Default",
                "Specify download location"
              ],
              "default": "Default"
            },
            "Executein64bitd": {
              "title": "Execute in",
              "type": "string",
              "enum": [
                "Fore Ground",
                "Background"
              ]
            },
            "Operation_mode64bit": {
              "type": "string",
              "title": "Download operation mode",
              "enum": [
                "Device Name",
                "Manufacturer",
                "Operating System",
                "Installation Date",
                "Nanoheal Version",
                "Last Event"
              ],
              "enumNames": [
                "Device Name",
                "Manufacturer",
                "Operating System",
                "Installation Date",
                "Nanoheal Version",
                "Last Event"
              ]
            },
            "Message_box": {
              "type": "string",
              "title": "Message during download"
            },
            "Process_terminate": {
              "type": "string",
              "title": "Process to terminate before this action"
            }
          },
          "dependencies": {
            "config64bit": {
              "oneOf": [
                {
                  "properties": {
                    "config64bit": {
                      "enum": [
                        "1"
                      ],
                      "enumNames": [
                        "Enable"
                      ]
                    },
                    "preCheck64bitd": {
                      "title": "What action should be taken when the pre-check value exists?",
                      "type": "string",
                      "enum": [
                        "Execute when pre-check value doesn't exists",
                        "Execute when pre-check value exists"
                      ]
                    },
                    "selectType64bitd": {
                      "type": "string",
                      "title": "Define the condition to be checked",
                      "enum": [
                        "File",
                        "Registry",
                        "Software Name"
                      ]
                    }
                  }
                }
              ]
            },
            "selectType64bitd": {
              "oneOf": [
                {
                  "properties": {
                    "selectType64bitd": {
                      "enum": [
                        "File"
                      ]
                    },
                    "filePath64bitd": {
                      "title": "Specify the file path",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType64bitd": {
                      "enum": [
                        "Registry"
                      ]
                    },
                    "RegrootKey64bitd": {
                      "title": "Registry root key:",
                      "enum": [
                        "HKEY_CLASSES_ROOT",
                        "HKEY_CURRENT_USER",
                        "HKEY_LOCAL_MACHINE",
                        "HKEY_USERS",
                        "HKEY_PERFORMANCE_DATA",
                        "HKEY_PERFORMANCE_TEXT",
                        "HKEY_PERFORMANCE_NLSTEXT",
                        "HKEY_CURRENT_CONFIG",
                        "HKEY_DYN_DATA"
                      ]
                    },
                    "Registrypath64bitd": {
                      "title": "Registry path",
                      "type": "string"
                    },
                    "Regtype64bitd": {
                      "title": "Registry type",
                      "type": "string",
                      "enum": [
                        "REG_SZ",
                        "REG_DWORD",
                        "REG_QWORD",
                        "REG_BINARY",
                        "REG_MULTI_SZ",
                        "REG_EXPAND_SZ"
                      ]
                    },
                    "Registryvalue64bit": {
                      "title": "Registry value",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType64bitd": {
                      "enum": [
                        "Software Name"
                      ]
                    },
                    "selectType_softName64bitd": {
                      "title": "Software Name ",
                      "type": "string"
                    },
                    "selectType_softVersion64bitd": {
                      "title": "Software Version ",
                      "type": "string"
                    }
                  }
                }
              ]
            },
            "Download_location64bit": {
              "oneOf": [
                {
                  "properties": {
                    "Download_location64bit": {
                      "enum": [
                        "Specify download location"
                      ]
                    },
                    "download_location64bit": {
                      "title": "Specify download location",
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        },
        {
          "type": "object",
          "properties": {
            "configuration64Install": {
              "type": "object",
              "title": "64 bit Configuration: Install"
            },
            "config64bitInstall": {
              "type": "string",
              "title": "Perform a Pre-download check"
            },
            "Executein64bitI": {
              "title": "Execute in",
              "type": "string",
              "enum": [
                "Fore Ground",
                "Background"
              ]
            },
            "Installationmode64bit": {
              "type": "string",
              "title": "Choose installation mode:",
              "enum": [
                "CMD",
                "Silent"
              ],
              "enumNames": [
                "CMD",
                "Silent"
              ]
            },
            "CmdInstr64bit": {
              "type": "string",
              "title": "Command line installation instruction:"
            },
            "Max_time64bit": {
              "type": "string",
              "title": "Max execution time for a patch:"
            },
            "Terminate64bit": {
              "type": "string",
              "title": "Terminate process before patching:"
            },
            "Message_Box": {
              "type": "string",
              "title": "Message during installation:"
            }
          },
          "dependencies": {
            "config64bitInstall": {
              "oneOf": [
                {
                  "properties": {
                    "config64bitInstall": {
                      "enum": [
                          "1"
                        ],
                        "enumNames": [
                          "Enable"
                        ]
                    },
                    "preCheck64bitI": {
                      "title": "What action should be taken when the pre-check value exists?",
                      "type": "string",
                      "enum": [
                        "Execute when pre-check value doesn't exists",
                        "Execute when pre-check value exists"
                      ]
                    },
                    "selectType64bitI": {
                      "type": "string",
                      "title": "Define the condition to be checked",
                      "enum": [
                        "File",
                        "Registry",
                        "Software Name"
                      ]
                    }
                  }
                }
              ]
            },
            "selectType64bitI": {
              "oneOf": [
                {
                  "properties": {
                    "selectType64bitI": {
                      "enum": [
                        "File"
                      ]
                    },
                    "filePath64bitI": {
                      "title": "Specify the file path",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType64bitI": {
                      "enum": [
                        "1"
                      ],
                      "enumNames": [
                        "Enable"
                      ]
                    },
                    "RegrootKey64bitI": {
                      "title": "Registry root key:",
                      "enum": [
                        "HKEY_CLASSES_ROOT",
                        "HKEY_CURRENT_USER",
                        "HKEY_LOCAL_MACHINE",
                        "HKEY_USERS",
                        "HKEY_PERFORMANCE_DATA",
                        "HKEY_PERFORMANCE_TEXT",
                        "HKEY_PERFORMANCE_NLSTEXT",
                        "HKEY_CURRENT_CONFIG",
                        "HKEY_DYN_DATA"
                      ]
                    },
                    "Registrypath64bitI": {
                      "title": "Registry path",
                      "type": "string"
                    },
                    "Regtype64bitI": {
                      "title": "Registry type",
                      "type": "string",
                      "enum": [
                        "REG_SZ",
                        "REG_DWORD",
                        "REG_QWORD",
                        "REG_BINARY",
                        "REG_MULTI_SZ",
                        "REG_EXPAND_SZ"
                      ]
                    },
                    "Registryvalue64bit": {
                      "title": "Registry value",
                      "type": "string"
                    }
                  }
                },
                {
                  "properties": {
                    "selectType64bitI": {
                      "enum": [
                        "Software Name"
                      ]
                    },
                    "selectType_softName64bitI": {
                      "title": "Software Name ",
                      "type": "string"
                    },
                    "selectType_softVersion64bitI": {
                      "title": "Software Version ",
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        }
      ]
    }
  }

  const jsonui2 = {
    "items": {
      "Application_name": {
        "ui:widget": "text"
      },
      "Application_details": {
        "ui:widget": "radio",
        "ui:options": {
          "inline": true
        }
      },
      "commonFilesOption": {
        "ui:widget": "radio",
        "ui:options": {
          "inline": true
        }
      },
      "files": {
        "ui:widget": "file"
      },
      "uploadApplication1": {
        "ui:widget": "file"
      },
      "uploadApplication2": {
        "ui:widget": "file"
      },
      "uploadApplication32Bit": {
        "ui:widget": "file"
      },
      "uploadApplication64Bit": {
        "ui:widget": "file"
      },
      "config32bit": {
        "ui:widget": "Togglebutton"
      },
      "preCheck32bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "selectType32bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Download_location32bit": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Executein32bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Operation_mode32bit": {
        "ui:placeholder": "select"
      },
      "Messagebox": {
        "ui:widget": "text"
      },
      "Processterminate": {
        "ui:widget": "text"
      },
      "config32bitInstall": {
        "ui:widget": "Togglebutton"
      },
      "preCheck32bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "selectType32bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Executein32bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Installationmode32bit": {
        "ui:placeholder": "Execute in System Privilage (Silent Operation)"
      },
      "CmdInstr32bit": {
        "ui:widget": "text"
      },
      "Max_time32bit": {
        "ui:widget": "text"
      },
      "Terminate32bit": {
        "ui:widget": "text"
      },
      "MessageBox": {
        "ui:widget": "text"
      },
      "config64bit": {
        "ui:widget": "Togglebutton"
      },
      "preCheck64bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "selectType64bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Download_location64bit": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Executein64bitd": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Operation_mode64bit": {
        "ui:placeholder": "select"
      },
      "Message_box": {
        "ui:widget": "text"
      },
      "Process_terminate": {
        "ui:widget": "text"
      },
      "config64bitInstall": {
        "ui:widget": "Togglebutton"
      },
      "preCheck64bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "selectType64bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Executein64bitI": {
        "ui:widget": "radio",
        "ui:inline": true
      },
      "Installationmode64bit": {
        "ui:placeholder": "Execute in System Privilage (Silent Operation)"
      },
      "CmdInstr64bit": {
        "ui:widget": "text"
      },
      "Max_time64bit": {
        "ui:widget": "text"
      },
      "Terminate64bit": {
        "ui:widget": "text"
      },
      "Message_Box": {
        "ui:widget": "text"
      },
      "ui:order": [
        "Application_name",
        "Application_details",
        "commonFilesOption",
        "uploadApplication1",
        "uploadApplication2",
        "uploadApplication32Bit",
        "uploadApplication64Bit",
        "configuration32",
        "config32bit",
        "preCheck32bitd",
        "selectType32bitd",
        "filePath32bitd",
        "RegrootKey32bitd",
        "Registrypath32bitd",
        "Regtype32bitd",
        "Registryvalue32bitd",
        "selectType_softName32bitd",
        "selectType_softVersion32bitd",
        "Download_location32bit",
        "download_location32bit",
        "Executein32bitd",
        "Operation_mode32bit",
        "Messagebox",
        "Processterminate",
        "configuration32Install",
        "config32bitInstall",
        "preCheck32bitI",
        "selectType32bitI",
        "filePath32bitI",
        "RegrootKey32bitI",
        "Registrypath32bitI",
        "Regtype32bitI",
        "Registryvalue32bitI",
        "selectType_softName32bitI",
        "selectType_softVersion32bitI",
        "Executein32bitI",
        "Installationmode32bit",
        "CmdInstr32bit",
        "Max_time32bit",
        "Terminate32bit",
        "MessageBox",
        "configuration64",
        "config64bit",
        "preCheck64bitd",
        "selectType64bitd",
        "filePath64bitd",
        "RegrootKey64bitd",
        "Registrypath64bitd",
        "Regtype64bitd",
        "Registryvalue64bit",
        "selectType_softName64bitd",
        "selectType_softVersion64bitd",
        "Download_location64bit",
        "download_location64bit",
        "Executein64bitd",
        "Operation_mode64bit",
        "Message_box",
        "Process_terminate",
        "configuration64Install",
        "config64bitInstall",
        "preCheck64bitI",
        "selectType64bitI",
        "filePath64bitI",
        "RegrootKey64bitI",
        "Registrypath64bitI",
        "Regtype64bitI",
        "Registryvalue64bit",
        "selectType_softName64bitI",
        "selectType_softVersion64bitI",
        "Executein64bitI",
        "Installationmode64bit",
        "CmdInstr64bit",
        "Max_time64bit",
        "Terminate64bit",
        "Message_Box"
      ]
    },
    'ui:submitButtonOptions': {
      "norender": true,
    }
  }

  //advance configuration
  const jsonSchema3:RJSFSchema = {
    "type": "object",
    "properties": {
      "Logfile": {
        "type": "string",
        "title": "Read a log file"
      },
      "Validation": {
        "type": "string",
        "title": "Perform a Post Validation"
      }
    },
    "dependencies": {
      "Logfile": {
        "oneOf": [
          {
            "properties": {
              "Logfile": {
                "enum": [
                  "1"
                ],
                "enumNames": [
                  "Enable"
                ]
              },
              "Log_file": {
                "title": "Path of the log file",
                "type": "string"
              }
            }
          }
        ]
      },
      "Validation": {
        "oneOf": [
          {
            "properties": {
              "Validation": {
                "enum": [
                  "1"
                ],
                "enumNames": [
                  "Enable"
                ]
              },
              "Validation_selectType": {
                "type": "string",
                "title": "Define the condition to be checked",
                "enum": [
                  "File",
                  "Registry"
                ]
              }
            }
          }
        ]
      },
      "Validation_selectType": {
        "oneOf": [
          {
            "properties": {
              "Validation_selectType": {
                "enum": [
                  "File"
                ]
              },
              "Validation_filePath": {
                "title": "Specify the file path",
                "type": "string"
              }
            }
          },
          {
            "properties": {
              "Validation_selectType": {
                "enum": [
                  "Registry"
                ]
              },
              "Validation_Reg_rootKey": {
                "title": "Registry root key:",
                "enum": [
                  "HKEY_CLASSES_ROOT",
                  "HKEY_CURRENT_USER",
                  "HKEY_LOCAL_MACHINE",
                  "HKEY_USERS",
                  "HKEY_PERFORMANCE_DATA",
                  "HKEY_PERFORMANCE_TEXT",
                  "HKEY_PERFORMANCE_NLSTEXT",
                  "HKEY_CURRENT_CONFIG",
                  "HKEY_DYN_DATA"
                ]
              },
              "Validation_Registry_path": {
                "title": "Registry path",
                "type": "string"
              },
              "Validation_Reg_type": {
                "title": "Registry type",
                "type": "string",
                "enum": [
                  "REG_SZ",
                  "REG_DWORD",
                  "REG_QWORD",
                  "REG_BINARY",
                  "REG_MULTI_SZ",
                  "REG_EXPAND_SZ"
                ]
              },
              "Validation_Registry_value": {
                "title": "Registry value",
                "type": "string"
              }
            }
          }
        ]
      }
    }
  }
   
  const jsonUi3={
    "Logfile": {
      "ui:widget": "Togglebutton"
    },
    "Validation": {
      "ui:widget": "Togglebutton"
    },
    "Validation_selectType": {
      "ui:widget": "radio",
      "ui:inline": true
    },
    "ui:order": [
      "Logfile",
      "Log_file",
      "Validation",
      "Validation_selectType",
      "Validation_filePath",
      "Validation_Reg_rootKey",
      "Validation_Registry_path",
      "Validation_Reg_type",
      "Validation_Registry_value"
    ],
    'ui:submitButtonOptions': {
        norender: true,
      }
  }
   
   
//logic for clone form end here

  const handleCommon =(value:any)=>{
    var commonOption= value.target.value;
    setCommon(commonOption);
  }

  const handleUpload =(value:any)=>{
    var uploadOption =value.target.value;
    setUpload(uploadOption);
  }

  


  var menuItems: any = [];
 
  {!(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  ( 
    <MenuItem
    onClick={() => navigate("/manage/apps/appmonitor")} icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <PulseSquare20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > App&nbsp;Monitored</Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:48}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );}
  
  
  {(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  ( 
    
    <MenuItem onClick={() => navigate("/automate/apps/appinstallation")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem><AppsAddIn20Regular {...primaryIcon}  /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> App&nbsp;Installation</Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:48}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
      </Stack>
    </MenuItem>
    
  );}
  
 { !(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  ( 
    <MenuItem onClick={() => navigate("/manage/apps/appinstallstatus")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BoxCheckmark20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1  > App Install Status </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  }
  
  {(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  ( <FluentProvider theme={lightTheme}>
    <MenuItem onClick={()=>navigate("/automate/apps/appuninstallprofile")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <UninstallApp20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > App Uninstall Profiles </Caption1> </StackItem>
      </Stack>
    </MenuItem>
    </FluentProvider>
  );
  }

  {!(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  (
    <MenuItem onClick={()=>navigate("/manage/apps/appuninstallstatus")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <CheckboxCheckedSync20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > App Uninstall Status </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  }
  {!(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  (
    <MenuItem onClick={()=>navigate("/manage/apps/discoveredapps")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <SquareHintApps20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Discovered Apps </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  }

  {(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  (
    <MenuItem onClick={()=>navigate("/automate/apps/appprotectionpolicies")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ShieldLock20Regular {...primaryIcon}/></StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > App Protection Policies </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  }
  {!(["/automate/apps/appinstallation","/automate/apps/appuninstallprofile","/automate/apps/appprotectionpolicies"].includes(location.pathname)) && menuItems.push
  (
    
    <MenuItem onClick={()=>navigate("/manage/apps/activitylogg")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
      
  );
  }
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    // { key: "pkgId", label: <Body1Strong> pkgName </Body1Strong> },
    { key: "names", label: <Body1Strong> Name </Body1Strong> },
    { key: "description", label: <Body1Strong>Description </Body1Strong>},
    { key: "version", label: <Body1Strong>Version</Body1Strong> },
    { key: "os", label: <Body1Strong>Operating System</Body1Strong> },
    { key: "created_on", label: <Body1Strong>Created On</Body1Strong> },
    { key: "modified_on", label: <Body1Strong>Modified On</Body1Strong> },
    { key: "status", label: <Body1Strong>Status</Body1Strong> },
 
  ];

  //data table for filter starts here
  var columns1 = [
    { key: "name", label: <Caption1> Name </Caption1> },
    { key: "version", label: <Caption1>Version</Caption1> },
    { key: "os", label: <Caption1>Operating System</Caption1> },
    { key: "status", label: <Caption1>Status</Caption1> },
    
  ];


  var radioData = {
    "Upload the app" : "upload",
    "Select a pre-existing application" : "existing",
  };
  var radioData2 = {
    "Yes" : "yes",
    "No" : "no",
  };
  var radioData3={
    "Proceed only if pre-check value exist" : "yes",
    "Proceed only if pre-check value absent": "no"
  };

      var radioData4={
        "File" : "files",
        "Registry": "registry",
      };
  var radioData5={
    "Default" : "yes",
    "Specify the fil path": "no",
  };
  var radioData6={
    "Default" : "yes",
    "Specify download location": "no",
  };
  var radioData7={
    "Fore Ground" : "yes",
    "Background": "no",
  };
  var radioData8={
    "Yes" : "yes",
    "No": "no",
  };

  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: 
      <>
      
      <Stack tokens={{childrenGap:10}} horizontal>
       <StackItem>
        {permissions.includes("appUnInstall_Fullaccess") ?
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem><Text></Text></StackItem>
              <StackItem>
                <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
              <StackItem> 
                <Caption1 onClick={() => {
                                    setFormDataPkg({});
                                    setOverlayOpen(true); 
                                    setOverlayName('addInstallation');
                                    setStatus(true);
                                    //  setFormDataPkg({});
                                    }}>Uninstallation Profile 
                </Caption1>
              </StackItem>
              </Stack>
              </StackItem>
            </Stack>
          </Button>
        :
          <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
            <Button appearance="subtle" size="small" disabled>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem><Text></Text></StackItem>
                <StackItem>
                  <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <AddSquare20Regular {...secondaryIcon} /> </StackItem>
                    <StackItem><Caption1> Uninstallation Profile </Caption1></StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </Button>
          </Tooltip> 
        } 
        </StackItem>

        <StackItem>
        <Divider vertical/>
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:6}} horizontal>
             <StackItem><Text></Text></StackItem>
             <StackItem>
              <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
              </Stack>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
        <Divider vertical/>
        </StackItem> */}
        
        <StackItem>
          <DialogBox
            headerButton={
              <>
              <Button appearance="transparent" size="small"
                disabled={isdeletedisabled ? false: true} >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                     {/* <SlideEraser20Regular {...primaryIcon} />  */}.
                     { isdeletedisabled 
                        ? (<SlideEraser20Regular {...primaryIcon}/> ) 
                        : (  <SlideEraser20Regular {...secondaryIcon}/> )
                    }
                     </StackItem>
                  <StackItem> <Caption1> Delete </Caption1> </StackItem>
                </Stack>
              </Button>
            </>
            }
          
            content= "Kindly verify your intent to delete the automation."
          
            footer =
            {
              <>
                <Stack horizontal tokens={{childrenGap:10}}>
                  <StackItem>
                  <Button appearance="primary" onClick={() => {setDeleteprofile(true)}} size="small">Confirm</Button>
                  </StackItem>
                  <StackItem>
                  <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>

       <StackItem>
        <Divider vertical/>
        </StackItem>
        
        {/* <Stack tokens={{childrenGap:6}}> */}
            <StackItem><Text></Text></StackItem>
            <StackItem>
              <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Refresh </Caption1> </StackItem>
                </Stack>
              </Button>

              { permissions.includes("appUnInstall_Fullaccess") || permissions.includes("appUnInstall_ReadandExport")?
                <CSVLink id="csv-export-link" data={exportData} filename="uninstallprofile_list.csv">
                  <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                    <Stack tokens={{childrenGap:5}} horizontal>
                      <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                      <StackItem> <Caption1> Export </Caption1> </StackItem>
                    </Stack>
                  </Button>
                </CSVLink>
              :
                <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
                  <Button appearance="subtle" size="small" disabled>
                    <Stack tokens={{childrenGap:5}} horizontal>
                      <StackItem><Text></Text></StackItem>
                      <StackItem>
                        <Stack tokens={{childrenGap:5}} horizontal>
                        <StackItem> <ArrowDownload20Regular {...secondaryIcon} /> </StackItem>
                          <StackItem><Caption1> Export </Caption1></StackItem>
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Button>
                </Tooltip>
              }
              
              {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
            </StackItem>
          {/* </Stack> */}
        </Stack>
      </>
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];
  useEffect(() => {    
    var tempExportData: any = [];

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        names:val.packageName,
        description: val.packageDesc,
        version: val.version,
        os: val.platform,
        created_on: val.creationTime ,
        modified_on: new Date(val.lastModified *1000).toLocaleString(),
        status: val.status
      });
    });
    setExportData(tempExportData);
  }, [exportDetails]);
  

  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=",final_data);
    async function loadRoute() {
      console.log("role:-",sessionStorage.getItem('role'))
      const role = sessionStorage.getItem('role');
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();
    final_data !== undefined && final_data !== null
      ? final_data.softinst_Packages.map((val: any, i: any) => {
  
        {permissions.includes("appUnInstall_Fullaccess") ?
          tempTableData.push({
            id: val.packageId,
          
            // checkbox: "show",
            names: { label: <Caption1 onClick={(e: any) => { setRowID(val.packageId); setOverlayOpen(true);setOverlayName('editInstallation');}}> {val.packageName !== null ? val.packageName : 'N/A'} </Caption1>, icon: "", id: `${i}` },
            description: { label: <Caption1 > {val.packageDesc != "" ? val.packageDesc : 'N/A'} </Caption1>, status: "" },
            version: { label: <Caption1> {val.version} </Caption1>},
            os: { label: <Caption1> {val.platform} </Caption1>},
            created_on: { label: <Caption1> {val.creationTime == null ? val.creationTime : '-'} </Caption1>},
            modified_on: { label: <Caption1>{new Date(val.lastModified *1000).toLocaleString()}</Caption1>},
            status: { label: <Caption1> {val.status} </Caption1>},
          })
          :
          tempTableData.push({
            id: val.packageId,
            checkbox: "hide",
            names: { label: <Caption1> {val.packageName !== null ? val.packageName : 'N/A'} </Caption1>, icon: "", id: `${i}` },
            description: { label: <Caption1 > {val.packageDesc != "" ? val.packageDesc : 'N/A'} </Caption1>, status: "" },
            version: { label: <Caption1> {val.version} </Caption1>},
            os: { label: <Caption1> {val.platform} </Caption1>},
            created_on: { label: <Caption1> {val.creationTime == null ? val.creationTime : '-'} </Caption1>},
            modified_on: { label: <Caption1>{new Date(val.lastModified *1000).toLocaleString()}</Caption1>},
            status: { label: <Caption1> {val.status} </Caption1>},
          });
        }
        }) : (tempTableData = []);
    // setExportData(tempExportData);
    console.log("tempTableData=",tempTableData);
    
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data,rowID]);

  // useEffect(() => {
  //   console.log("TableData=",tableData);
    
  // }, [tableData])
  
  
  // data table values append code ends here
//   var overLayData: any = {
//     headerTitle: "Add a New Installation Profile ",
//     size: "large",
//     isTabs: true,
//     tabItems: {
//       Basic_Details:<Stack tokens={{padding:5}}>
//        <Form  formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
//         </Stack>,


//       App_Configuration: 
//       <Stack>
//       <Form formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>

//       </Stack>,
//       "Advance Configuration":
//        <>
//             <Stack horizontal tokens={{childrenGap:157, padding:5}}>
//               <Form   formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
//             </Stack>
//         </>,

// "Review Data":<Stack>
//     <Stack tokens={{padding:5}}>
//         <Text as = "h4" >Package Details</Text>
//        <Form   disabled = {true} formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
//     </Stack>
//     <Stack>
//         <Text>App Configurations</Text>
//         <Form  disabled = {true} formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>
//     </Stack>
//         <Text>Advance Configurations</Text>
//         <Stack horizontal tokens={{childrenGap:157, padding:5}}>
//         <Form   disabled = {true}  formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
//     </Stack>
//    </Stack>
//     },
//   };
     
  useEffect(() => {
    console.log("hiaaaaaaaaaaaaaaaaa");
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  },[]);

  useEffect(() => {
    console.log("main Table=",contextValue.selectedRowIdInTable);
    console.log("sub Table=",contextValue.selectedRowIdInSubTable);
    
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(true) : setDeleteDisabled(false);
    console.log("length",contextValue.selectedRowIdInTable.length)


  },[contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);


  useEffect(() => {
    if(formDataPkg != undefined){
    
    overlayName == 'addInstallation' && setInstallationOverLayData({
      headerTitle: "Uninstall Profile",
      size: "large",
      isTabs: true,
      tabItems: {

        "Basic Details":<Stack tokens={{padding:5}}>
          <StackItem>
          <FluentProvider theme={messageTheme}>
            <Card appearance="subtle" size="small">  
              <Stack tokens={{childrenGap:10}} horizontal>
                <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit.  </Caption1>  </StackItem>
              </Stack>
            </Card>
          </FluentProvider>
          </StackItem>
          <StackItem>
        <Form  formData={formDataPkg}  
            schema={jsonSchemaPkg} 
            uiSchema={uiSchemaPkg} 
            validator={validator}
              widgets={CustomWidgets} 
              onChange={(e) => setFormDataPkg(e.formData)}/>
        </StackItem>
          </Stack>,
  
        "App Configuration":<Stack tokens={{padding:5}}>
          <StackItem>
          <FluentProvider theme={messageTheme}>
            <Card appearance="subtle" size="small">  
              <Stack tokens={{childrenGap:10}} horizontal>
                <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
              </Stack>
            </Card>
          </FluentProvider>
          </StackItem>
          <StackItem>
        <Form 
        formData={formDataApp}  
        schema={jsonSchemaApp} 
        uiSchema={uiSchemaApp} 
        validator={validator} 
        widgets={CustomWidgets} 
        onChange={(e) => setFormDataApp(e.formData)}/>
        </StackItem>
        </Stack>,


      "Advance Configuration":
        <>
            <Stack tokens={{padding:5}}>
            <StackItem>
        <FluentProvider theme={messageTheme}>
          <Card appearance="subtle" size="small">  
            <Stack tokens={{childrenGap:10}} horizontal>
              <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
            </Stack>
          </Card>
        </FluentProvider>
        </StackItem>
              <Form   
              formData={formDataAdv}  
              schema={jsonSchemaAdv} 
              uiSchema={uiSchemaAdv} 
              validator={validator} 
              widgets={CustomWidgets} 
              onChange={(e) => setFormDataAdv(e.formData)}/>
            </Stack>
        </>,

        "Review Data":
        <>
        <Stack>
          <StackItem>
            <FluentProvider theme={messageTheme}>
              <Card appearance="subtle" size="small">  
                <Stack tokens={{childrenGap:10}} horizontal>
                  <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
                </Stack>
              </Card>
            </FluentProvider>
            </StackItem>
     <Stack tokens={{padding:5}}>
         {/* <Text as = "h4" >Package Details</Text> */}
         <Body1Strong>Package Details</Body1Strong>
        <Form   disabled = {true} formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
     </Stack>
     <Stack>
         {/* <Text>App Configurations</Text> */}
         <Body1Strong>App Configurations</Body1Strong>
        <Form  disabled = {true} formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>
     </Stack>
        {/* <Text>Advance Configurations</Text> */}
        <Body1Strong>Advance Configurations</Body1Strong>
         <Stack horizontal tokens={{childrenGap:157, padding:5}}>
         <Form   disabled = {true}  formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
     </Stack>    </Stack>
        </>
      }
    });

  
    // Object.keys(formDataPkg)?.length > 0 &&
    overlayName == 'editInstallation' && setInstallationOverLayData({
    headerTitle: "Edit Installation Profile",
    size: "large",
    isTabs: true,
    tabItems: {

      "Basic Details":<Stack tokens={{padding:5}}>
        <StackItem>
        <FluentProvider theme={messageTheme}>
          <Card appearance="subtle" size="small">  
            <Stack tokens={{childrenGap:10}} horizontal>
              <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit.  </Caption1>  </StackItem>
            </Stack>
          </Card>
        </FluentProvider>
        </StackItem>
        <StackItem>
      <Form formData={formDataPkg}  
          schema={jsonSchemaPkg} 
          uiSchema={uiSchemaPkg} 
          validator={validator}
            widgets={CustomWidgets} 
            onChange={(e) => setFormDataPkg(e.formData)}/>
      </StackItem>
        </Stack>,

      "App Configuration":<Stack tokens={{padding:5}}>
        <StackItem>
        <FluentProvider theme={messageTheme}>
          <Card appearance="subtle" size="small">  
            <Stack tokens={{childrenGap:10}} horizontal>
              <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
            </Stack>
          </Card>
        </FluentProvider>
        </StackItem>
        <StackItem>
      <Form 
      formData={formDataApp}  
      schema={jsonSchemaApp} 
      uiSchema={uiSchemaApp} 
      validator={validator} 
      widgets={CustomWidgets} 
      onChange={(e) => setFormDataApp(e.formData)}/>
      </StackItem>
      </Stack>,


    "Advance Configuration":
      <>
          <Stack tokens={{padding:5}}>
          <StackItem>
      <FluentProvider theme={messageTheme}>
        <Card appearance="subtle" size="small">  
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
            <StackItem> <Caption1> Sorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>  </StackItem>
          </Stack>
        </Card>
      </FluentProvider>
      </StackItem>
            <Form   
            formData={formDataAdv}  
            schema={jsonSchemaAdv} 
            uiSchema={uiSchemaAdv} 
            validator={validator} 
            widgets={CustomWidgets} 
            onChange={(e) => setFormDataAdv(e.formData)}/>
          </Stack>
      </>,

      "Review Data":
      <>
      <Stack>
   <Stack tokens={{padding:5}}>
       <Text as = "h4" >Package Details</Text>
      <Form   disabled = {true} formData={formDataPkg}  schema={jsonSchemaPkg} uiSchema={uiSchemaPkg} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataPkg(e.formData)}/>
   </Stack>
   <Stack>
       <Text>App Configurations</Text>
      <Form  disabled = {true} formData={formDataApp}  schema={jsonSchemaApp} uiSchema={uiSchemaApp} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataApp(e.formData)}/>
   </Stack>
      <Text>Advance Configurations</Text>
       <Stack horizontal tokens={{childrenGap:157, padding:5}}>
       <Form   disabled = {true}  formData={formDataAdv}  schema={jsonSchemaAdv} uiSchema={uiSchemaAdv} validator={validator} widgets={CustomWidgets} onChange={(e) => setFormDataAdv(e.formData)}/>
   </Stack>    </Stack>
      </>
    },
  });
}
},[overlayName, formDataPkg, formDataApp, formDataAdv]);
const DismissButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      onClick={()=>setSearchVal('')}
      {...props}
      appearance="transparent"
      icon={<Dismiss16Regular />}
      size="small"
    />
  );
};

    
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{childrenGap:10}} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" /> </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink> 
                <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <StackItem>
            {/* <Text> <Breadcrumbs pageName="Apps" /> </Text> */}
          </StackItem>
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
                <StackItem>
                 <FluentProvider theme={webLightTheme}>
                   <Title3> Apps | App Uninstallation Profile </Title3> 
                 </FluentProvider>
               </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
          </Stack>

          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
          
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                  { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                </StackItem>
                <StackItem>
                  <StackItem>
                    <Stack horizontal tokens={{childrenGap:20}}>
                    <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                      <StackItem>
                        <MenuDropdown open={filterMenuOpen} btn={ 
                          <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                            <Stack tokens={{childrenGap:5}} horizontal>
                              <Filter20Filled {...primaryIcon}/>
                              <Body1> Filter </Body1> 
                            </Stack>
                          </MenuButton>
                        } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                          <Divider/>
                          <MenuList>
                            <Stack tokens={{childrenGap:5}}>
                              <StackItem> <Text></Text> </StackItem>
                              <StackItem> <Caption1> Filter </Caption1> </StackItem>
                              {/* <DropdownComponent optionData={columns}/> */}
                              <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                              <StackItem> <Divider /> </StackItem>
                              <StackItem> <Caption1> Value </Caption1> </StackItem>
                              {/* <DropdownComponent optionData={columns}/> */}
                              <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                              <StackItem> <Divider /> </StackItem>
                              <Stack tokens={{childrenGap:10}} horizontal>
                                <StackItem> <Button appearance="primary" onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                              </Stack>
                            </Stack>
                          </MenuList>
                        </>}/>
                      </StackItem>

                        <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>

                      <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                        <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                          <Stack tokens={{childrenGap:5}} horizontal>
                            <StackItem> <FilterDismiss20Filled/> </StackItem>
                            <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                          </Stack>
                        </Button>
                      </StackItem>
                    </Stack>
                  </StackItem>
                  <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                    <StackItem> <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem>
                    <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                  </Stack>
                </StackItem >
                <Swd_func  data={final_data} 
                func={set_final_data} 
                searchVal={searchVal} 
                pageNo = {(currentPageNo - 1) * 10} 
                setTotalPages = {setTotalPages} 
                setTotalRecords = {setTotalRecords}
                isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} 
                savePackageDetails = {savePackageDetails} 
                setSavePackageDetails = {setSavePackageDetails} 
                setDeleteDisabled={setDeleteDisabled} 
                deleteprofile={deleteprofile} 
                setDeleteprofile={setDeleteprofile}
                pkgId={deleteId} 
                setJsonDataPkg = {setJsonDataPkg}  
                setUiDataPkg = {setUiDataPkg}  
                setJsonDataApp = {setJsonDataApp}  
                setUiDataApp = {setUiDataApp} 
                setJsonDataAdv = {setJsonDataAdv} 
                setUiDataAdv = {setUiDataAdv} 
                formDataPkg = {formDataPkg} 
                formDataApp = {formDataApp} 
                formDataAdv = {formDataAdv} 
                setFormDataPkg = {setFormDataPkg}
                setFormDataApp = {setFormDataApp}
                setFormDataAdv = {setFormDataAdv}
                rowID={rowID}
                overlayName={overlayName}
                handleEditData={handleEditData}
                overlayOpen={overlayOpen}
                setOverlayOpen={setOverlayOpen}
                setRowId={setRowID}
                status={status}
                setStatus={setStatus}
                setExportDetails={setExportDetails}
                />
                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns} ></DataTable> )}
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider> 
        </Stack>
        <OverLay
          overlayopen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          overlayData={installationOverLayData}
          saveBtnFunc = {saveBtnFunc}
          isNavBtn={true}
        />
        {/* {savePackageDetails && <ToastComponent  setMess  toastIntent="success" position="top-end" title="Success" message="Package  Details have been saved Successfully" footer="" /> }   */}
        {isToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={isToast} toastIntent="success" position="top-end" title="Success" message={"Package Saved Successfully"} footer="" />}
      </StackItem>
      </FullScreen>
    </>
  );
};

// function useFullScreenHandle() {
//   throw new Error("Function not implemented.");
// }