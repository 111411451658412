import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { useId, Input, Label, InputProps } from "@fluentui/react-components";

export const Inputs = (props: any) => {
  var isDisabled = props.disabled != undefined ? props.disabled : false;
  const smallId = useId("input-small");
  const [value, setValue] = useState(props?.inputVal?.name);
  
  var timer: any;
  const handleInput = (data: any) => {
    setValue(data.target.value);
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      props.inputChange({...props?.inputVal, name: data.target.value});
    },100);
  };

  useEffect(() => {
    setValue(props?.inputVal?.name);
  }, [props?.inputVal?.name])
  

  return (
    <Stack>
        <Label size="small" htmlFor={smallId}>
        </Label>
        <Input size="small" value={value} id={smallId} maxLength={20} disabled={isDisabled} onChange={handleInput} required={props?.required} />
    </Stack>
  );
};