import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim, PermissionClaim } from 'supertokens-web-js/recipe/userroles';
import AppConfig from '../utility/AppConfig';

export async function shouldLoadRoute(role: any): Promise<any> {
  if (await Session.doesSessionExist()) {
    let validationErrors = await Session.validateClaims({
      overrideGlobalClaimValidators: globalValidators => [...globalValidators, UserRoleClaim.validators.includes(role)],
    });
    console.log('checking the validation errors', validationErrors);
    if (validationErrors.length === 0) {
      // user is an admin
      // role exists get all roles associated with that user
      try {
        const response = await fetch(`${AppConfig.DASHBOARD_V2_API_URL}/getPermissions/${role}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status !== 200) {
          console.log('checking the permisssions in API CALL');

          // Handle the error
          console.error('Error:', response.status, response.statusText);
        } else {
          const responseData = await response.json();
          // Process the response data
          const permissions: any[] = responseData?.permissions;
          console.log('permissions=', permissions);

          return [true, permissions];
        }
      } catch (error: any) {
        // Handle network or other errors
        console.error('Error:', error.message);
      }
    }

    for (const err of validationErrors) {
      if (err) {
        // user roles claim check failed
      } else {
        // some other claim check failed (from the global validators list)
      }
    }
  }
  // either a session does not exist, or one of the validators failed.
  // so we do not allow access to this page.
  return false;
}
