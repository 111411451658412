import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { insertActivityLog, getActivityLogQuery } from "../../graphql/activity_log_queries";
import { getJsonSchemaQuery } from "../../graphql/configuration_queries";
import { getAutomationListsQuery } from "../../graphql/configuration_queries";

import { GlobalContext } from "../../hooks/globalContext";

const Automation_activity_log_func = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    var tenantName = contextValue.tenantName;
    var userId = contextValue.userSessionDetails.userId.toString();

    const getJsonSchema = async (dartid: number) => {
        const { data, loading, error } = await client.query({
          query: getJsonSchemaQuery,
          variables: {dartid},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data){
          var uiSchema = JSON.parse(data?.core_DartJsonSchema[0]?.uiSchema);
          uiSchema["ui:submitButtonOptions"] = {
           "norender": true,
          };
    
          var jsonSchemaData = JSON.parse(data?.core_DartJsonSchema[0]?.jsonSchema);
         // console.log("jsonSchemaData=",jsonSchemaData);
    
          data?.core_DartJsonSchema[0]?.jsonSchema != undefined && props.setJsonData(jsonSchemaData);
          data?.core_DartJsonSchema[0]?.uiSchema != undefined && props.setUiData(uiSchema);
        }
    };

    const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data){
            var subAutomationNames: any = [];
            var subAutoamtionDataObj: any = {};
    
            data.core_AutomationJSON.map((val: any) => {
                subAutomationNames.push(val.automationName);
                subAutoamtionDataObj[`${val.automationName}`] = val.id;
            })
            
            props.setSelectedOptions(subAutomationNames); 
        }
    };
  
    useEffect(() => {   
        console.log("getJsonSchema=",props.dartNo);

        if(props.remediationObj.type === 'Selfhelp'){
            console.log("props?.remediationObj=",props?.remediationObj);
            
            getAutomationList({id: {_in: props?.remediationObj?.automation_sequence?.split(",").map(Number)}}, null, null);
        }
            
        props.dartNo !== 0 && getJsonSchema(parseInt(props.dartNo));         
    }, [props.dartNo, props.remediationObj]);

    return (<></>);
}

export { Automation_activity_log_func }