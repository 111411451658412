import React from "react";
import { DrawerBody, DrawerHeader, DrawerHeaderTitle, OverlayDrawer } from "@fluentui/react-components/unstable";
import { Dismiss20Regular } from "@fluentui/react-icons";
import { Button, Divider, DrawerFooter, FluentProvider, TabValue, webLightTheme } from "@fluentui/react-components";
import { TablistComponent } from "../tab_list/tablist.component";
import { Stack, StackItem } from "@fluentui/react"
import { validation } from "../../utility/validation";
import { overlayHeight } from "../../styles/customStyles";

type dataProps = {
  overlayopen: boolean | undefined;
  setOverlayOpen: (arg0: boolean) => void;
  overlayData: any;
  saveBtnFunc?: any;
  isNavBtn?: any;
  formRef?:any
  handleOutsideButton?:any
  handleSubmit?:any
  classificationNameValidation?:any
}

const OverLay = (props: dataProps) => {
  // var tabItems: any = ["Intro", "Basic"];
  const [activeTab, setActiveTab]=React.useState<TabValue>("tab1");
  const [selectedValue, setSelectedValue] = React.useState<TabValue>("tab1");

  function handleFormSubmit(formId: string, e: React.FormEvent<HTMLButtonElement>) {
    const form = document.getElementById(formId);
    if (!form) return false;
    const requiredFields = form.querySelectorAll("[required]");
    const requiredDropdownFields = form.querySelectorAll("[aria-required]");
    var isEmpty = Array.from(requiredFields).some(field => (field as HTMLInputElement).value.trim() === "");
    isEmpty = isEmpty !== true ? Array.from(requiredDropdownFields).some(field => (field as HTMLInputElement).value.trim() === "") : isEmpty;

    return isEmpty ? false : (handleNextBtnClick(e), true);
  }
  
  // Usage example: // var isFormValid = somefunction();
  function somefunction(e: React.FormEvent<HTMLButtonElement>) { return handleFormSubmit("myForm", e) }
  function somefunction1(e: React.FormEvent<HTMLButtonElement>) { return handleFormSubmit("myFormAppmonitored", e) }
  function somefunction2(e: React.FormEvent<HTMLButtonElement>) { return handleFormSubmit("myFormSelfhelp", e) }
  function somefunction3(e: React.FormEvent<HTMLButtonElement>) { return handleFormSubmit("myFormAutoheal", e) }
  function somefunction4(e: React.FormEvent<HTMLButtonElement>) { handleFormSubmit("myFormAutomationschedule", e); return handleFormSubmit("myFormAutomationschedule", e)}
  function somefunction5(e: React.FormEvent<HTMLButtonElement>) { return handleFormSubmit("myFormUsersurvey", e) }

  const handleNextBtnClick=async (e: React.FormEvent)=>{
    var val: any = selectedValue;
    val = "tab"+ (parseInt(val.charAt(val.length - 1)) + 1);
    console.log("hi nanna")
    e.preventDefault();
    
    
    console.log("props.formRef=",props.formRef);
  
    
    
    if(!props.formRef){
      setSelectedValue(val);
      setActiveTab(val);
    }else if(validation(props.formRef) || props.formRef?.current == null){
      setSelectedValue(val);
      setActiveTab(val);
      props.formRef?.current?.submit();
    }else{ 
      props.formRef?.current?.submit();
    }
  }

  const handlePrevBtnClick = () => {
    var val: any =selectedValue;
    val = "tab"+ (parseInt(val.charAt(val.length-1))-1)
    setSelectedValue(val);
    setActiveTab(val);
  }

  return (  
    <FluentProvider theme={webLightTheme}>
      <OverlayDrawer
        style={overlayHeight}
        modalType="modal"
        position={"end"}
        open={props.overlayopen}
        size={props.overlayData.size} // onOpenChange={(_, { open }) => setIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss20Regular/>} onClick={() => {props.setOverlayOpen(false); setTimeout(() =>{setSelectedValue("tab1");setActiveTab("tab1") }, 2000);}}/>}>
            {props.overlayData.headerTitle}
          </DrawerHeaderTitle>
          <Divider/>
        </DrawerHeader>
 
        <DrawerBody>
          { props.overlayData.isTabs && 
            <TablistComponent tabListData={props.overlayData.tabItems} saveBtnFuncs={props.saveBtnFunc} setSelectedValue={setSelectedValue} selectedValue={selectedValue} setActiveTab={setActiveTab} activeTab={activeTab}/>
          }
          {props.overlayData.bodyData}
        </DrawerBody>
      
        <DrawerFooter>
          <>
            { props.isNavBtn &&
              <Stack horizontal tokens={{childrenGap:5, padding:5}}>
                  <StackItem> 
                    { activeTab !== "tab1" && 
                      <Button appearance="primary" size="small" onClick={handlePrevBtnClick}> 
                        Prev 
                      </Button>
                    } 
                  </StackItem>

                  <StackItem>
                    { activeTab !== `tab${props.overlayData.tabItems ? Object.keys(props.overlayData.tabItems).length : []}` && 
                      <Button 
                        appearance="primary" 
                        size="small"
                        onClick={(e) => {
                          (props.formRef?.current)
                            ? !(somefunction(e) && !somefunction3(e))
                              ? <> {(handleNextBtnClick(e), console.log("2nd Conf"))} </>
                              : (handleNextBtnClick(e))
                            : ((props.classificationNameValidation === true && somefunction(e)) || (props.classificationNameValidation ===true && somefunction3(e)))
                              ? <> {(handleNextBtnClick(e), console.log("1st Conf"))} </>
                              : (props.handleOutsideButton)
                                ? <> {(props.handleOutsideButton(), console.log("Handle Outside"))} </>
                                : <> {(handleNextBtnClick(e), console.log("Else Here"))} </>
                        
                            props.classificationNameValidation === true && somefunction2(e) && handleNextBtnClick(e);
                            props.classificationNameValidation === true && somefunction4(e) && handleNextBtnClick(e);
                            props.classificationNameValidation === true && somefunction5(e) && handleNextBtnClick(e);
                            somefunction1(e) && handleNextBtnClick(e);
                        }}
                      >
                        Next
                      </Button>
                    }
                  </StackItem>
                            
                  <StackItem> 
                    { activeTab == `tab${props.overlayData.tabItems ? Object.keys(props.overlayData.tabItems).length : []}` && 
                      <Button appearance="primary" size="small" onClick={() => {props.saveBtnFunc(true); setTimeout(() =>{setSelectedValue("tab1");setActiveTab("tab1") }, 2000);}}> 
                        Save 
                      </Button>
                    } 
                  </StackItem>    
              </Stack>
            }
            {!(props?.isNavBtn) && props.overlayData.footerData}
          </>
        </DrawerFooter>
      </OverlayDrawer>
    </FluentProvider>
  );
};

export default OverLay;