import { gql } from '@apollo/client';

//get the mgroupid from the above query
const getGroupedDevicesListQuery = gql`
  query MyQuery($whereCondition: JSON!, $limit: Int, $offset: Int) {
    core_Census(where: $whereCondition, limit: $limit, offset: $offset) {
      host
      site
      last
      id
      os
      clientversion
      born
      Census_AssestData(where: { dataid: { _eq: 5 } }) {
        value
      }
    }
  }
`;

const updateLastModifiedQuery = gql`
  mutation UpdateDeviceClassification($classificationName: String!, $lastModified: Int!) {
    update_core_DeviceClassification(where: { classificationName: { _eq: $classificationName } }, _set: { lastModified: $lastModified }) {
      affected_rows
    }
  }
`;

export { getGroupedDevicesListQuery, updateLastModifiedQuery };
