import React, { useState, useEffect, useContext } from "react";
import { Device_classification_devices_func } from "../../../api/devices/device_classification_devices_func";
import { Groups_func } from "../../../api/devices/groups_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Modal, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Popover, PopoverTrigger, PopoverSurface, Toast, ToastTitle, Toaster, useId, ToastPosition, useToastController, ToastBody, ToastTrigger, Link, ToastFooter, Field, ProgressBar, Dropdown, Option, Display, Select, MessageBar, MessageBarBody, Label, Card, Input, ButtonProps, Tooltip } from "@fluentui/react-components";
import { PhoneLaptop20Regular, BookNumber20Regular, BookTemplate20Regular, Filter20Filled,Delete20Regular, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, AddSquare20Regular, SlideEraser20Regular, Warning20Filled, Dismiss16Regular, Open20Regular, ArrowMaximize20Regular, DismissSquare24Filled , Info20Filled} from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { CheckBox } from "../../../components/checkbox/checkbox_component";
import { GlobalContext } from "../../../hooks/globalContext";
import { ToastComponent } from "../../../components/toast/toast.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import DialogBox from "../../../components/dialog/dialog.component";
import { FileDownloadComponent } from "../../../components/fileExport/file.export.component";
import FileUploadComponent from "../../../components/fileImport/file.import.component";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, secondaryIcon, exitIcon, overflowDivider } from "../../../styles/customStyles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";

export const DeviceClassificationDevices = () => {
  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive:any = (data: any) => {
    setIconEnabled(data.target.checked);
  }
 
  const [isModalIconEnabled, setModalIconEnabled] = useState(false);
  const handleModalIconActive:any = (data: any) => {
    setModalIconEnabled(data.target.checked);
  }
 
  const [messagebar, setmessagebar]= useState(false);
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [deviceSearchVal, setDeviceSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [deleteDevices, setDeleteDevices] = useState(false);
  const [overLayData, setOverLayData] = useState<any>({});
  const [devicesTableData, setDevicesTableData] = useState([]);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [addDevices, setAddDevices] = useState<any>(false);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [isToast, setIsToast] = useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const[isRefresh,setIsRefresh]:any =useState(false);
  const [devicesOfTenant, setDevicesOfTenant] = useState<any>(null);
  const [devicesData, setDevicesData]: any = useState(null);
  const [selectedValue, setSelectedValue] = useState(0);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isfilter,setIsFilter]=useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);

  const [csvDevicesList, setCsvDevicesList] = useState<any>([]);
  const [downloadFileDeviceList, setDownloadFileDeviceList] = useState<any>(['"Machines"']);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [exportDetails, setExportDetails]: any = useState([]);


  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleFilterValueSelect = (event: { target: { value: Event; }; }):void => {
    alert(event.target.value);
  }
  const handleCloseFilter = (index:any):any  => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x,itemIndex) => itemIndex !== index);
  }
  useEffect(() => {
    if(filterArray.length > 0)
    setResetDisabled(false)
   
   }, [filterArray])
 
  const [messagebar2, setmessagebar2] = useState(false);
  const handleBothSetMessage = () => {
    // setmessagebar1(false);
    setmessagebar2(true);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }
 
  var menuItems: any = [];

  const toasterId = useId();
  const { dispatchToast } = useToastController(toasterId);
  const [position, setPosition] = React.useState<ToastPosition>("top-end");
 
  const deleteToast = () =>
  dispatchToast(
    <Toast>
      <ToastTitle
        action={
          <ToastTrigger>
            <Link> <Dismiss16Regular/> </Link>
          </ToastTrigger> }
      > Sucess </ToastTitle>
      <ToastBody> Device Classification Chem Factory Worker - 001 is deleted succesfully </ToastBody>
    </Toast>,
    { position, intent: "success" }
  );
 
  menuItems.push
  (
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/manage/devices/device_classification/devices")}
        icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <PhoneLaptop20Regular {...primaryIcon}/> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Devices </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
     </FluentProvider>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification/scores")}
      icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookNumber20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Scores </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
 
  menuItems.push
  (
    <MenuItem onClick={() => navigate("/manage/devices/device_classification/audit_log")}
      icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Audit Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "device_names", label: <Body1Strong>&nbsp; Device Name </Body1Strong>  },
    { key: "manufacturer", label: <Body1Strong> Manufacturer </Body1Strong> },
    { key: "os", label: <Body1Strong> Operating System </Body1Strong> },
    { key: "installation_date", label: <Body1Strong> Installation Date </Body1Strong> },
    { key: "nanoheal_version", label: <Body1Strong> Nanoheal Version </Body1Strong> },
    { key: "last_event", label: <Body1Strong> Last Event </Body1Strong> },
  ];
  var columns1 = [
    { key: "device_name", label: <Caption1> Device Name </Caption1>  },
    // { key: "", label: <Caption1> Device belongs to mutliple classification </Caption1>  },
    // { key: "manufacturer", label: <Caption1> Manufacturer </Caption1> },
    { key: "os", label: <Caption1> Operating System </Caption1> },
    { key: "nanoheal_version", label: <Caption1> Nanoheal Version </Caption1> },
    // { key: "os", label: <Caption1> Last check-in 24 hours </Caption1> },
    // { key: "os", label: <Caption1> Last-check in 1 week </Caption1> },
    { key: "installation_date", label: <Caption1> Installation Date </Caption1> },
  ];
 
  var overlayColumns = [
    { key: "device_name", label: <Body1Strong> Device Name</Body1Strong> },
    { key: "os", label: <Body1Strong> Operating System  </Body1Strong> },
  ];
 
  var dropdownData = [
    { key: "op_1", label: <Body1 onClick={() => setSelectedValue(1)}> Choose devices manually </Body1>  },
    { key: "op_2", label: <Body1 onClick={() => setSelectedValue(2)}> Upload a CSV containing the Devices </Body1> },
  ];
 
  var ddData = [
    { key: "op_csv", label: <Body1Strong> CSv File </Body1Strong>  },
  ];
  //data table column name code ends here
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        {/* <Stack tokens={{childrenGap:10}} horizontal> */}
        <StackItem>
          {
            permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_ReadandExport") ? (
              <TooltipHost content="You do not have access">
                <Button appearance="subtle" size="small" disabled>
                  <Stack tokens={{ childrenGap: 5 }}>
                    <StackItem>
                    <Stack tokens={{childrenGap:5}} horizontal>
                        <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                        <StackItem> <Caption1> Add Devices </Caption1> </StackItem>
                    </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>
            ) : permissions.includes("deviceClassification_Fullaccess") ? (

              <Button appearance="subtle" size="small" onClick={() => { setOverlayOpen(true) }}>
                <Stack tokens={{ childrenGap: 5 }}>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem> <AddSquare20Regular {...primaryIcon} /> </StackItem>
                      <StackItem> <Caption1> Add Devices </Caption1> </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            ) : 
              <TooltipHost content="You do not have access">
                  <Button appearance="subtle" size="small" disabled>
                    <Stack tokens={{ childrenGap: 5 }}>
                      <StackItem>
                      <Stack tokens={{childrenGap:5}} horizontal>
                          <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                          <StackItem> <Caption1> Add Devices </Caption1> </StackItem>
                      </Stack>
                      </StackItem>
                    </Stack>
                  </Button>
                </TooltipHost>
          }


          {/* <Button appearance="subtle" size="small" onClick={() => { setOverlayOpen(true) }}>
            <Stack tokens={{ childrenGap: 5 }}>
              <StackItem>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <AddSquare20Regular {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Add Devices </Caption1> </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          </Button> */}
        </StackItem>

        <StackItem> <Divider vertical /> </StackItem>

        <StackItem>

          <DialogBox
            headerButton={
              <>
                <Button appearance="transparent" size="small"
                  disabled={isIconEnabled ? false: true} >
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> 
                    { isIconEnabled 
                                ? ( <SlideEraser20Regular {...primaryIcon}/> ) 
                                : ( <SlideEraser20Regular {...secondaryIcon}/> )
                            } 
                    </StackItem>
                    <StackItem> <Caption1> Remove Devices </Caption1> </StackItem>
                  </Stack>
                </Button>
              </>
              }
            
              content= "Are you sure you wish to remove the selected devices from the Device Classification?"
            
              footer =
              {
                <>
                  <Stack horizontal tokens={{childrenGap:10}}>
                    <StackItem>
                    <Button appearance="primary" onClick={() => {setDeleteDevices(true); userActivity('Edit', `{"name":"${contextValue.selectedRowDetails.name}","deviceCnt":"${contextValue.selectedRowIdInTable.length}","deviceList":"${contextValue.selectedRowIdInTable}","summary":"${contextValue.selectedRowIdInTable.length} ${contextValue.selectedRowIdInTable.length > 1 ? 'devices': 'device'} removed from ${contextValue.selectedRowDetails.name} classification"}`); }} size="small">Confirm</Button>
                    </StackItem>
                    <StackItem>
                    <Button size="small">Cancel</Button>
                    </StackItem>
                  </Stack>
                </>
              }
            />
          </StackItem>
 
          <StackItem> <Divider vertical/> </StackItem>
 
          {/* <Stack tokens={{childrenGap:6}}> */}
            <StackItem><Text></Text></StackItem>
            <StackItem>
              <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setIsRefresh(true);}}>
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Refresh </Caption1> </StackItem>
                </Stack>
              </Button>
           {
            permissions.includes("deviceClassification_Read") ? (
              <TooltipHost content="This feature is not available for you!">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>

            ) : permissions.includes("deviceClassification_Fullaccess") || permissions.includes("deviceClassification_ReadandExport") ? (
            
              <CSVLink id="csv-export-link" data={exportData} filename={`${contextValue.selectedRowDetails.name}_Details.csv`}>
                <Button appearance="subtle" size="small" onClick={() => {userActivity('Export', `{"summary":"Devices list exported"`); }}>
                  <Stack tokens={{childrenGap:5}} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>
            ) : 
            <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Export </Caption1> </StackItem>
                </Stack>
             </Button>
            </Tooltip>
          }

          {/* <Button appearance="subtle" size="small" onClick={() => { userActivity('Export', `{"summary":"Devices list exported"`); }}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button> */}


          {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
            </StackItem>
          {/* </Stack> */}
        {/* </Stack> */}
 
      </>
    ,
  };
  // overflow set contents code ends here

  useEffect(() => {    
    console.log("exportDetails exportDetails=",exportDetails);
    
    var tempExportData: any = [];

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        device_names: val.host,
        manufacturer: val.Census_AssestData.length > 0 ? val.Census_AssestData[0].value.chassismanufacturer: "-",
        os: val.os,
        osversion: val.osversion,
        nanoheal_version: val.clientversion ,
        installation_date: new Date(val.born *1000).toLocaleString(),
        last_event: new Date(val.last *1000).toLocaleString()
      });
    });

    setExportData(tempExportData);
  }, [exportDetails]);
 
  var tempTableData: any = [];
  var tempExportData: any = [];
 
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data device----=", final_data);
    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        console.log("role ------------>>", role);
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions);
          console.log('Route loaded successfully.', permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();



    var tempVal: any = '"Machines"';
    var tempArr: any = [];
    final_data !== undefined && final_data !== null
      ? final_data.core_Census.map((val: any, i: any) => {
        tempVal += "\n" + val.host;
        // tempArr.push(val.host);

        // tempTableData.push({
        //   id: val.host,
        //   device_names: { label: <Caption1> {val.host} </Caption1>, icon: (<Badge size="extra-small" appearance="filled" color="success" > </Badge>), id: `${i}` },
        //   manufacturer: { label: <Caption1> {val.Census_AssestData.length > 0 ? val.Census_AssestData[0].value.chassismanufacturer : "-"} </Caption1>, status: "" },
        //   os: { label: <Caption1> {val.os} </Caption1> },
        //   osversion: { label: <Caption1> {val.clientversion} </Caption1> },
        //   nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1> },
        //   installation_date: { label: <Caption1> {new Date(val.born * 1000).toLocaleString()} </Caption1> },
        //   last_event: { label: <Caption1> {new Date(val.last * 1000).toLocaleString()} </Caption1> },
        // });

        const deviceData = {
          id: val.host,
          device_names: { label: <Caption1>{val.host}</Caption1>, icon: (<Badge size="extra-small" appearance="filled" color="success"></Badge>), id: `${i}` },
          manufacturer: { label: <Caption1>{val.Census_AssestData.length > 0 ? val.Census_AssestData[0].value.chassismanufacturer : "-"}</Caption1>, status: "" },
          os: { label: <Caption1>{val.os}</Caption1> },
          osversion: { label: <Caption1>{val.clientversion}</Caption1> },
          nanoheal_version: { label: <Caption1>{val.clientversion}</Caption1> },
          installation_date: { label: <Caption1>{new Date(val.born * 1000).toLocaleString()}</Caption1> },
          last_event: { label: <Caption1>{new Date(val.last * 1000).toLocaleString()}</Caption1> },
        };
        
        if (permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_ReadandExport")) {
          tempTableData.push({ checkbox: 'hide', ...deviceData });
        } else if (permissions.includes("deviceClassification_Fullaccess")) {
          tempTableData.push(deviceData);
        }
        

        

      }) : (tempTableData = []);

// setDownloadFileDeviceList(tempVal);
//setDevicesOfTenant(tempArr);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  var tempTableData1: any = [];
  useEffect(() => {
    // devices data table values append code starts here
    devicesData !== undefined && devicesData !== null
    ? devicesData.core_Census?.map((val: any, i: any) => {
      if(!(selectedDevices.includes(val.host)) && !(contextValue.selectedRowIdInSubTable.includes(val.host)) ){
        tempTableData1.push({
          id: val.host,
          device_name: { label: <Caption1> {val.host} </Caption1>, id: `${i}` },
          os: { label: <Caption1> {val.os} </Caption1>, status: "" },
        });
      }
    }) : (tempTableData1 = []);

    tempTableData1.length > 0 ? setDevicesTableData(tempTableData1) : setDevicesTableData([]);
  }, [devicesData, selectedDevices]);
  // devices data table values append code ends here

  //overlay code starts here
  useEffect(() => {
    var defaultSelected: any = [];

    setOverLayData({
      size: "large",
      isTabs: false,

      headerTitle:
      (
      <>
        <FluentProvider theme={webLightTheme}>
          <Title3> Add Devices </Title3>
        </FluentProvider>
      </>
      ),
    
      tabItems:{},

      bodyData:
      (
        <>
          <FluentProvider theme={webLightTheme}>
            <Stack horizontal>
              <StackItem>
              <Stack tokens={{childrenGap:20}}>
              <StackItem> <Body1> How you would like to add machines to this Device Classification? </Body1> </StackItem>
              <StackItem>
                <DropdownComponent optionData={dropdownData} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/>
              </StackItem>
              <StackItem>
                { selectedValue === 1 && 
                  <>
                    <FluentProvider theme={webLightTheme}>
                      <Stack tokens={{childrenGap:5}} horizontal>
                          <StackItem>
                              <Stack tokens={{childrenGap:10}}>
                                  <StackItem>
                                      {/* <MessageBar shape="rounded">
                                          <MessageBarBody> <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1> </MessageBarBody>
                                      </MessageBar> */}
                                        <FluentProvider theme={messageTheme}>
                                          <Card appearance="subtle" size="small">  
                                            <Stack tokens={{childrenGap:10}} horizontal>
                                              <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                                              <StackItem> <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>  </StackItem>
                                            </Stack>
                                          </Card>
                                        </FluentProvider>
                                  </StackItem>

                                  <StackItem>
                                      <Stack horizontal tokens={{childrenGap:20}}>
                                      <SearchBox onChange={(e: any) => { setDeviceSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                                      <StackItem>
                                          <MenuDropdown btn={ 
                                              <MenuButton shape="circular" size="small">
                                              <Stack tokens={{childrenGap:5}} horizontal>
                                              <Filter20Filled {...primaryIcon}/>
                                              <Body1> Filter </Body1> 
                                              </Stack>
                                          </MenuButton>
                                          } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                                          <Divider/>
                                          <MenuList>
                                              <Stack tokens={{childrenGap:5}}>
                                              <StackItem> <Text></Text> </StackItem>
                                              <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                              <DropdownComponent optionData={columns}/>
                                              <StackItem> <Divider /> </StackItem>
                                              <StackItem> <Caption1> Value </Caption1> </StackItem>
                                              <DropdownComponent optionData={columns}/>
                                              <StackItem> <Divider /> </StackItem>
                                              <Stack tokens={{childrenGap:10}} horizontal>
                                                  <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                                                  <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                              </Stack>
                                              </Stack>
                                          </MenuList>
                                          </>}/>
                                      </StackItem>
                                      <StackItem>
                                          <Button appearance="subtle" size="small">
                                          <Stack tokens={{childrenGap:5}} horizontal>
                                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                          </Stack>
                                          </Button>
                                      </StackItem>
                                      </Stack>
                                  </StackItem>

                                  <StackItem>
                                      <Stack tokens={{childrenGap:10}}>
                                          <FluentProvider theme={webLightTheme}>
                                              {/* {tableData.length > 0 && ( <DataTable tableData={tableData} columns={overlayColumns}/>)} */}
                                              {devicesTableData.length > 0 ? ( <DataTable tableData={devicesTableData} columns={overlayColumns} isSubTable={true} defaultSelected={defaultSelected} />) : <Stack><StackItem><Caption1> No new devices found.</Caption1></StackItem></Stack>}
                                          </FluentProvider>
                                      </Stack>
                                  </StackItem>
                              </Stack>
                          </StackItem>

                          {/* {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) && <Divider vertical />}

                          <StackItem>
                            <StackItem>
                                {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) &&  <DataTable tableData={selectedTableData} columns={[{ key: "selected", label: <Body1Strong> Selected ({contextValue.selectedRowIdInSubTable.length})</Body1Strong>}]} />}
                            </StackItem>
                          </StackItem>*/}
                      </Stack>
                    </FluentProvider>
                  </>
                }

                { selectedValue === 2 && 
                  <>
                    <Stack tokens={{childrenGap:15}}>
                      <StackItem><Text></Text></StackItem>
                      {/* <StackItem>
                        <Stack tokens={{childrenGap:0}} >
                          <StackItem> <Body1> Start by downloading a file with the list of devices from the sites you want. </Body1> </StackItem>
                          <StackItem> <Body1> You can choose devices from the same tenant to create groups. </Body1> <FileDownloadComponent data={downloadFileDeviceList} fileName="devices_list" /> </StackItem>
                        </Stack>
                      </StackItem> */}

                      {/* <StackItem><Text></Text></StackItem> */}
                      <StackItem> <Body1Strong> Steps to create the CSV group </Body1Strong> </StackItem>
                      <StackItem> <Body1> 1. Download the file that contains the list of devices </Body1> </StackItem>
                      <Stack tokens={{childrenGap:10}} horizontal>
                      <StackItem><Text></Text></StackItem>
                      <StackItem><FileDownloadComponent data={downloadFileDeviceList} fileName="devices_list" /> </StackItem>
                      </Stack>
                      <Stack>
                        <StackItem> <Body1> 2. Revise device grouping in the CSV. </Body1> </StackItem>
                        <StackItem> 
                          <Stack tokens={{childrenGap:15}} horizontal>
                            <StackItem><Text></Text></StackItem>
                            <StackItem> 
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <StackItem> <Caption1> Watch the instructional video for guidance </Caption1> </StackItem>
                                <StackItem> <Open20Regular/> </StackItem>
                              </Stack>  
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>

                      <StackItem> <Body1> 3. Upload your .csv file </Body1> </StackItem>
                      <Stack horizontal tokens={{childrenGap:10}}>
                        <StackItem><Text></Text></StackItem>
                      <StackItem>
                          {/* <DropdownComponent optionData={ddData} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/> */}
                          <FileUploadComponent setDeviceList={setCsvDevicesList} />
                      </StackItem>
                      </Stack>

                      {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length > 0 && <><StackItem><Text>{selectedTableData.length} unique machine found.</Text> </StackItem>
                      <StackItem><Text>Kindly check the group names and confirm that everything is exactly the way you need them. If you have made any mistake,Kindly make the changes and upload the CSV with the corrections</Text></StackItem></>}

                      {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length == 0 && <StackItem><Text>No unique machine found</Text></StackItem>}
                    </Stack>
                  </>
                }
              </StackItem>
            </Stack>
            </StackItem>

            {selectedValue === 1  &&
              <>
              <Divider vertical />
              <StackItem>
                {(contextValue.selectedRowIdInSubTable.length >= 0 || selectedTableData.length >= 0) && <StackItem>
                  <Stack horizontal>
                    {/* <Label> <Body1Strong> Selected </Body1Strong> </Label> */}
                    <StackItem>
                      <DataTable tableData={selectedTableData} columns={[{ key: "selected", label: <Body1Strong> Selected ({contextValue.selectedRowIdInSubTable.length})</Body1Strong> }]} />
                    </StackItem>
                  </Stack>
                </StackItem>}
              </StackItem>
              </>
             
            }
          </Stack>
        </FluentProvider>
          {/* THIS SHOULD BE FETCHED FROM THE BACKEND */}
        </>
      ),

      footerData:
      (
        <>
        {selectedValue == 1 ? 
          <Button appearance="primary" disabled={(contextValue.selectedRowIdInSubTable?.length > 0) ? false : true} onClick={() => {setAddDevices(true); userActivity('Edit', `{"name":"${contextValue.selectedRowDetails.name}","deviceCnt":"${selectedTableData.length}","type":"Manual","deviceList":"${contextValue.selectedRowIdInSubTable}","summary":"${contextValue.selectedRowIdInSubTable?.length} ${contextValue.selectedRowIdInSubTable?.length == 1 ? 'device' :'devices'} added manually to ${contextValue.selectedRowDetails.name} classification"}`); }} >Submit</Button> 
        : 
          <Button appearance="primary" disabled={(selectedTableData.length > 0) ? false : true} onClick={() => {setAddDevices(true); userActivity('Edit', `{"name":"${contextValue.selectedRowDetails.name}","deviceCnt":"${selectedTableData.length}","type":"CSV","deviceList":"${contextValue.selectedRowIdInSubTable}","summary":"${selectedTableData.length} ${contextValue.selectedRowIdInSubTable?.length == 1 ? 'device' :'devices'} added through csv to ${contextValue.selectedRowDetails.name} classification"}`); }} >Submit</Button>}
        </>

        // <>
        //     <Button appearance="primary" disabled={(selectedTableData.length == 0) ? true : false } size="small" onClick={() => {setAddDevices(true);}}> Submit </Button>
        // </>
      ),
    });
  },[devicesTableData,selectedValue, selectedTableData]);
  //overlay code ends here

  var validDevicesList: any = [];
  var inValidDevicesList: any = [];
  var alldDevicesList: any = [];
  useEffect(() => {
    if(csvDevicesList.length > 0){
      validDevicesList = [];
      inValidDevicesList = [];
      alldDevicesList = [];

      devicesOfTenant.core_Census.map((val: any) => {
        alldDevicesList.push(val.host);
      });

      csvDevicesList.map((val: any) => {
        var deviceName = val.replace(/[\r\n]/g, '');
        alldDevicesList.includes(deviceName) ? validDevicesList.push(deviceName) : inValidDevicesList.push(deviceName);
      });

      validDevicesList.length > 0 ? setSelectedTableData(validDevicesList) : setSelectedTableData([]);

      contextValue.setSelectedRowIdInSubTable(validDevicesList);
    }
  },[devicesOfTenant, csvDevicesList]);

  useEffect(() => {
    !overlayOpen && setSelectedValue(0);
  },[overlayOpen]);

  useEffect(() => {
    console.log("after deletion=",deleteDevices);
    
    !deleteDevices && contextValue.setSelectedRowIdInTable([]);
    !deleteDevices && contextValue.setSelectedRowIdInSubTable([]);
  },[deleteDevices]);

  useEffect(() => {
    console.log("SelectedRowIdInTable=",contextValue.selectedRowIdInTable);
    
  }, [contextValue.selectedRowIdInTable]);
  
  
  useEffect(() => {
    // contextValue.setSelectedRowIdInSubTable(selectedDevices);

    var newSelectedValues = selectedDevices.concat(contextValue.selectedRowIdInSubTable)
    const uniqueSet = new Set(newSelectedValues);
    const resultArray = Array.from(uniqueSet);

    contextValue.setSelectedRowIdInSubTable(resultArray);
  },[selectedDevices]);

  var dataArr: any = [];
  useEffect(() => {
    console.log("sub table=",contextValue.selectedRowIdInSubTable);

    contextValue.selectedRowIdInSubTable.map((val: any, i: any) => {
      dataArr.push({
        id: i,
        checkbox: "hide",
        selected: { label: <Caption1> {val} </Caption1>, id: `${i}` },
      });
    });

    dataArr.length > 0 ? setSelectedTableData(dataArr) : setSelectedTableData([]);

    contextValue.selectedRowIdInTable.length > 0 ? setIconEnabled(true) : setIconEnabled(false);
  },[contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({pageName: 'Devices', moduleName: "Devices", action: action, time: Math.floor(Date.now() / 1000), activity: activity, status: status});
    
    setTracker(true);
  }
  
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
              <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="Device Classification" subPageLink="/manage/devices/device_classification" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button 
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
      <Stack tokens={{childrenGap:10}} horizontal>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <Stack tokens={{childrenGap:25}} horizontal>
                <StackItem><Text></Text></StackItem>
                <StackItem>
                  <FluentProvider theme={webLightTheme}>
                    <Title3> {contextValue.selectedRowDetails.name} | Devices </Title3>
                  </FluentProvider>
                </StackItem>
            </Stack>
            <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
                <StackItem>
                  <StackItem><Text></Text></StackItem>
                  <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                  <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                      { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{childrenGap:20}}>
                        <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                          <StackItem>
                            <MenuDropdown open={filterMenuOpen} btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <Filter20Filled {...primaryIcon}/>
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            } itemVal={<><Body1Strong> Add a Filter </Body1Strong>
                              <Divider/>
                              <MenuList>
                                <Stack tokens={{childrenGap:5}}>
                                  <StackItem> <Text></Text> </StackItem>
                                  <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                  <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <StackItem> <Caption1> Value </Caption1> </StackItem>
                                  {/* <DropdownComponent optionData={columns}/> */}
                                  <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                  <StackItem> <Divider /> </StackItem>
                                  <Stack tokens={{childrenGap:10}} horizontal>
                                    <StackItem> <Button appearance="primary" onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                    <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                  </Stack>
                                </Stack>
                              </MenuList>
                            </>}/>
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                          <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <StackItem> <FilterDismiss20Filled/> </StackItem>
                                <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                    
                      <Stack tokens={{childrenGap:10}}>
                        <StackItem><Text></Text></StackItem>
                        <StackItem><Text></Text></StackItem>
                      </Stack>

                      {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem> <Caption1> Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem>
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                      </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack> }
                    </StackItem>

                    <Device_classification_devices_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo = {(currentPageNo - 1) * 10} setTotalPages = {setTotalPages} setTotalRecords = {setTotalRecords}isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} setDeleteDevices={setDeleteDevices} deleteDevices={deleteDevices} setDevicesData={setDevicesData} setSelectedDevices={setSelectedDevices} addDevices={addDevices} setAddDevices={setAddDevices} setIsToast={setIsToast} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setRefreshToast={setRefreshToast} setOverlayOpen={setOverlayOpen} setExportDetails={setExportDetails} deviceSearchVal={deviceSearchVal} />

                    {devicesOfTenant == null && csvDevicesList.length > 0 && <Groups_func setDevicesOfTenant={setDevicesOfTenant} csvDevicesList={csvDevicesList} />}

                    {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData}/>
        </StackItem>
        <StackItem><Text></Text></StackItem>
      </Stack>
      </StackItem>
      {isToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={isToast} toastIntent="success" position="top-end" title="Success" message={addDevices ? "New Devices Added Successfully" : "Devices Removed Successfully"} footer="" />}
      {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
      <StackItem><Text></Text></StackItem>
    </Stack>
    </FullScreen>
    </>
  );
};
