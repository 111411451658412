import validator from '@rjsf/validator-ajv8';
import { Form } from '@rjsf/fluent-ui';
import { CustomWidgets } from './index';
import { WidgetProps } from '@rjsf/utils';
import { Subtitle2Stronger } from '@fluentui/react-components';
// import { CustomWidgets } from '../../../components/form-components';

/**
 * 
 * @param props 
 * @returns 
 * 
 * @example uiConfig can be like this
 * uiConfig = {
   "ui:options" : {
      columnSeparator: '#NXT#', 
      lineSeparator: '\n',
      ignoreFirstLine: false, // Allow to ignore first line in data table
      title: `Main title`,
      description: `Main description`,
      subTitle: `sub title`,
      subDescription: `sub description`,
      UISchema: {
        col3: {
          'ui:widget': 'stringNoYes',
        },
      },
      columns: [
        { title: 'c1', type: 'number' },
        { title: 'c2', type: 'number' },
        { title: 'c3', type: 'string' },
        { title: 'c4', type: 'string' },
      ],
    }
  }
 */

export const CustomTableWidget = (props: WidgetProps) => {
  let options: any = {};
  options = props?.uiSchema && props.uiSchema['ui:options'] ? props.uiSchema['ui:options'] : {};

  const uiConfig = {
    columnSeparator: options.columnSeparator || '#NXT#',
    lineSeparator: options.lineSeparator || '\n',
    ignoreFirstLine: options.ignoreFirstLine || false,
    UISchema: options.UISchema || {},
    columns: options.columns || null,
    title: options.title || undefined,
    description: options.description || undefined,
    subTitle: options.subTitle || undefined,
    subDescription: options.subDescription || undefined,
  };

  // if (!uiConfig.columns || Array.isArray(uiConfig.columns) === false) { Modal.confirm({ title: 'Error', content: ( <div> Columns should be array in path `uiSchema['ui:options'].columns`<pre>{JSON.stringify(uiConfig?.columns)}</pre> </div> ), okText: 'Yes', }); return <div> Columns not defined </div>; }

  let firstLine: any = null;
  const dataTable = String(props.value || '')
    .split(uiConfig.lineSeparator)
    .filter((row, index) => (uiConfig.ignoreFirstLine && index === 0 ? ((firstLine = row), false) : true))
    .map((line, index) => {
      const arr = line.split(uiConfig.columnSeparator);
      const objValues: any = {};
      uiConfig.columns.forEach((col: any, index: number) => {
        objValues[`col${index}`] = arr[index];
      });
      return objValues;
    });

  const subObjectProperties: any = {};
  uiConfig.columns.forEach((col: any, index: number) => {
    subObjectProperties[`col${index}`] = col;
  });

  const subObjectUISchema: any = {};
  uiConfig.columns.forEach((col: any, index: number) => {
    subObjectUISchema[`col${index}`] = col;
  });

  const schema: any = {
    title: uiConfig.title,
    description: uiConfig.description,
    type: 'array',
    items: {
      title: uiConfig.subTitle,
      description: uiConfig.subDescription,
      type: 'object',
      properties: subObjectProperties, //required: ["col0","col1","col2","col3"]
    }, // required: ["S00060TargetStr","S00060FileGroupsStr","S00060FileOld","col0"]
  };
  console.log(props);

  /**
   * Save data to string and call props.onChange function
   * @param formData
   */
  const setNewStringData = (formData: any) => {
    console.log('custom formData=', formData);
    formData = uiConfig.ignoreFirstLine && firstLine !== null ? [firstLine, ...formData] : formData;
    let strResult = formData
      .map((row: any) => {
        const arr: any[] = [];
        row = !row ? {} : row;
        uiConfig.columns.forEach((col: any, index: any) => {
          arr.push(row[`col${index}`] || null);
        });
        return arr.join(uiConfig.columnSeparator);
      })
      .join(uiConfig.lineSeparator);
    props.onChange(strResult);
  };

  let tId: any = null; // onChange
  const onSubmit = (e: any) => {
    if (tId) {
      clearTimeout(tId);
    } // setNewStringData(e.formData);
    tId = setTimeout(setNewStringData, 300, e.formData);
  };
  console.log('schema: ', schema);

  return (
    <>
      <Subtitle2Stronger> {props.schema.title} </Subtitle2Stronger>
      <Form
        widgets={CustomWidgets}
        formData={dataTable}
        schema={schema}
        uiSchema={uiConfig.UISchema}
        validator={validator}
        onChange={onSubmit}
        onSubmit={onSubmit}
        disabled={props?.disabled ? true : false}
      ></Form>
    </>
  );
};
