import { useState, useEffect, useContext } from 'react';
import { Census_func } from '../../../api/devices/census_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
} from '@fluentui/react-components';
import {
  PulseSquare20Regular,
  AppsAddIn20Regular,
  BoxCheckmark20Regular,
  UninstallApp20Regular,
  CheckboxCheckedSync20Regular,
  SquareHintApps20Regular,
  ShieldLock20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  SlideEraser20Regular,
  Copy20Regular,
  AddSquare20Regular,
  Dismiss16Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { fullscreenIcon, primaryIcon, secondaryIcon, exitIcon, lightTheme, overflowDivider } from '../../../styles/customStyles';
import DialogBox from '../../../components/dialog/dialog.component';
import { GlobalContext } from '../../../hooks/globalContext';
import { SkeletonContent } from '../../../components/skeleton/skeletonContent';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';
import client from '../../../api/apollo';
import { getPinot_d312_singleAsset_v1_installedsoftwarenames } from '../../../graphql/pinot';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Discoveredapps = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const tenantName = contextValue.tenantName;
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isdeletedisabled, setDeleteDisabled] = useState(false);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState(['']);
  console.log('checking the permissions------>', permissions);

  const [refreshing, setRefreshing] = useState(false);
  const handleRefresh = () => {
    setRefreshing(true);
  };

  const [response, setresponse] = useState('');
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appmonitor')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <PulseSquare20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App&nbsp;Monitored</Caption1>{' '}
        </StackItem>
        <StackItem>
          {' '}
          <Stack tokens={{ childrenGap: 48 }} horizontal>
            {' '}
            <StackItem>
              <Text></Text>
            </StackItem>{' '}
            <StackItem>
              <Text></Text>
            </StackItem>{' '}
          </Stack>{' '}
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appinstallstatus')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <BoxCheckmark20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Install Status </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appuninstallstatus')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <CheckboxCheckedSync20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Uninstall Status </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/manage/apps/discoveredapps')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              {' '}
              <SquareHintApps20Regular {...primaryIcon} />{' '}
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <Caption1> Discovered Apps </Caption1>{' '}
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/activitylogg')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {' '}
            <BookTemplate20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> Activity Log </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'app_name', label: <Body1Strong> Application Name </Body1Strong> },
    { key: 'app_ver', label: <Body1Strong> Application Version </Body1Strong> },
    //{ key: "totalDev", label: <Body1Strong> Installed Date </Body1Strong> },
    { key: 'sizekb', label: <Body1Strong> Size in kb </Body1Strong> },
  ];

  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Button appearance="subtle" size="small" onClick={handleRefresh}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  {' '}
                  <ArrowClockwise20Regular {...primaryIcon} />{' '}
                </StackItem>
                <StackItem>
                  {' '}
                  <Caption1> Refresh </Caption1>{' '}
                </StackItem>
              </Stack>
            </Button>
            {/* <CSVLink id="csv-export-link" data={exportData} filename="discoceredappslist.csv">
              <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Export </Caption1> </StackItem>
                </Stack>
              </Button>
            </CSVLink> */}
            {permissions.includes('discoverApps_Read') ? (
              <TooltipHost content="This feature is not available for you!">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {' '}
                      <ArrowDownload20Regular {...primaryIcon} />{' '}
                    </StackItem>
                    <StackItem>
                      {' '}
                      <Caption1> Export </Caption1>{' '}
                    </StackItem>
                  </Stack>
                </Button>
              </TooltipHost>
            ) : permissions.includes('discoverApps_Fullaccess') || permissions.includes('discoverApps_ReadandExport') ? (
              <CSVLink id="csv-export-link" data={exportData} filename="discoceredappslist.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      {' '}
                      <ArrowDownload20Regular {...primaryIcon} />{' '}
                    </StackItem>
                    <StackItem>
                      {' '}
                      <Caption1> Export </Caption1>{' '}
                    </StackItem>
                  </Stack>
                </Button>
              </CSVLink>
            ) : null}
          </StackItem>
          {/* </Stack> */}
        </Stack>
      </>
    ),
  };
  // overflow set contents code ends here
  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  //export pinotAsset data
  useEffect(() => {
    const fetchData = async () => {
      const { data, loading, error } = await client.query({
        query: getPinot_d312_singleAsset_v1_installedsoftwarenames,
        variables: {
          params: {
            searchVal: searchVal,
            tenantName: tenantName,
            limit: 1000,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (error) {
        throw new Error(`Error result:` + error);
      }

      if (!loading && data) {
        if (data?.getPinot_d312_singleAsset_v1_installedsoftwarenames?.error) {
          throw new Error(`No result`);
        }

        const uniqueData = data?.getPinot_d312_singleAsset_v1_installedsoftwarenames?.answer;
        uniqueData.map((data: any, i: any) => {
          menuLists.push();
          tempExportData.push({
            app_name: data[0],
            app_ver: data[2],
            totalDev: data[5],
            sizekb: data[4],
          });
        });

        setExportData(tempExportData);
      }
    };
    fetchData();
  }, [response, searchVal, setExportData]);

  //Pinot table data
  useEffect(() => {
    //roles and permissions
    async function loadRoute() {
      const role = 'Nano_test';
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    const fetchData = async () => {
      try {
        const { data, loading, error } = await client.query({
          query: getPinot_d312_singleAsset_v1_installedsoftwarenames,
          variables: {
            params: {
              searchVal: searchVal,
              tenantName: tenantName,
              limit: 1000,
            },
          },
          fetchPolicy: 'network-only',
        });

        if (error) {
          throw new Error(`Error result:` + error);
        }
        debugger;
        if (!loading && data) {
          if (data?.getPinot_d312_singleAsset_v1_installedsoftwarenames?.error) {
            throw new Error(`No result`);
          }
          const uniqueData = data?.getPinot_d312_singleAsset_v1_installedsoftwarenames?.answer;

          const startIndex = (currentPageNo - 1) * 50 + 1;
          const endIndex = currentPageNo * 50;
          const currentPageData = uniqueData.slice(startIndex, endIndex);

          currentPageData.forEach((data: any, i: any) => {
            tempTableData.push({
              checkbox: 'hide',
              app_name: {
                label: (
                  <Button
                    size="small"
                    appearance="transparent"
                    onClick={() => {
                      navigate('/manage/apps/discoveredapps/discoveredapps_list');
                      localStorage.setItem(
                        'devName',
                        JSON.stringify({
                          devName: data[6],
                          appName: data[0],
                        }),
                      );
                      contextValue.setSelectedRowDetails({
                        ...contextValue.selectedRowDetails,
                        name: data[6],
                      });
                    }}
                  >
                    {data[0]}
                  </Button>
                ),
                id: `${i}`,
              },
              app_ver: { label: <Caption1> {data[2] === '' ? '-' : data[2]} </Caption1>, status: '' },
              sizekb: { label: <Caption1> {data[5] === '' ? '-' : data[5]} </Caption1> },
            });
          });

          setTotalRecords(uniqueData.length);
          setTotalPages(Math.ceil(uniqueData.length / 50));
          setTableData(tempTableData);

          setLoading(false);
          setRefreshing(false); // Reset refreshing state after data is fetched
        }
      } catch (error) {
        console.log('catch -->', error);
        setError('No results!');
        setLoading(false);
        setRefreshing(false); // Reset refreshing state in case of error
      }
    };

    fetchData();
  }, [response, searchVal, refreshing, currentPageNo]);

  // if (loading) return <p>Loading... </p>;
  if (loading) return <SkeletonContent />;
  if (error) return <p>Error: {error}</p>;

  // data table values append code ends here
  var overLayData: any = {
    headerTitle: 'Temp File Clean Up',
    size: 'large',
    isTabs: true,
    tabItems: {
      Introduction: (
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>Introduction</Stack>
        </>
      ),

      Configuration: (
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>Configuration</Stack>
        </>
      ),
    },
  };

  //console.log("tableData.length=======??", tableData.length);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  {' '}
                  <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" />{' '}
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <StackItem>
              <FluentProvider theme={webLightTheme}>
                <Stack verticalAlign="center">
                  <Stack tokens={{ childrenGap: 25 }} horizontal>
                    <StackItem>
                      {' '}
                      <Text></Text>{' '}
                    </StackItem>
                    <StackItem>
                      <Title3> Apps | Discovered Apps </Title3>
                    </StackItem>
                  </Stack>

                  <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>

                  <Stack horizontal>
                    {/* SPACING BETWEEN INLINE AND PAGE */}
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                    <StackItem>
                      <StackItem>
                        {' '}
                        <Text></Text>{' '}
                      </StackItem>
                      <Stack tokens={{ childrenGap: 1 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                          {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                        </StackItem>
                        <StackItem>
                          <StackItem>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                              <SearchBox
                                dismiss={null}
                                onChange={(e: any) => {
                                  setSearchVal(e.target.value);
                                }}
                                placeholder="Search"
                                size="small"
                              />
                              <StackItem>
                                <MenuDropdown
                                  btn={
                                    <MenuButton shape="circular" size="small">
                                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                                        <Filter20Filled {...primaryIcon} />
                                        <Body1> Filter </Body1>
                                      </Stack>
                                    </MenuButton>
                                  }
                                  itemVal={
                                    <>
                                      <Body1Strong> Add a Filter </Body1Strong>
                                      <Divider />
                                      <MenuList>
                                        <Stack tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            {' '}
                                            <Text></Text>{' '}
                                          </StackItem>
                                          <StackItem>
                                            {' '}
                                            <Caption1> Filter </Caption1>{' '}
                                          </StackItem>
                                          <DropdownComponent
                                            optionData={columns}
                                            setDropDownValue={setDropDownValue1}
                                            dropDownvalue={dropDownValue1}
                                          />
                                          {/* <DropdownComponent optionData={columns}/> */}
                                          <StackItem>
                                            {' '}
                                            <Divider />{' '}
                                          </StackItem>
                                          <StackItem>
                                            {' '}
                                            <Caption1> Value </Caption1>{' '}
                                          </StackItem>
                                          <DropdownComponent
                                            optionData={columns}
                                            setDropDownValue={setDropDownValue2}
                                            dropDownvalue={dropDownValue2}
                                          />
                                          {/* <DropdownComponent optionData={columns}/> */}
                                          <StackItem>
                                            {' '}
                                            <Divider />{' '}
                                          </StackItem>
                                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <StackItem>
                                              {' '}
                                              <Button appearance="primary" onClick={handleFilterArray}>
                                                {' '}
                                                <Caption1> Apply </Caption1>{' '}
                                              </Button>{' '}
                                            </StackItem>
                                            <StackItem>
                                              {' '}
                                              <Button>
                                                {' '}
                                                <Caption1> Cancel </Caption1>{' '}
                                              </Button>{' '}
                                            </StackItem>
                                          </Stack>
                                        </Stack>
                                      </MenuList>
                                    </>
                                  }
                                />
                              </StackItem>
                              <StackItem>
                                {filterArray.length > 0 &&
                                  filterArray.map((item, index) => (
                                    <Badge appearance="filled" color="informative">
                                      <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                        <StackItem> {item} </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                          {/* <ArrowPrevious20Filled {...primaryIcon} onClick={() => {handleCloseFilter(index)}}/> */}
                                        </StackItem>
                                      </Stack>
                                    </Badge>
                                  ))}
                              </StackItem>
                              <StackItem onClick={() => setFilterArray([])}>
                                <Button appearance="subtle" size="small">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem>
                                      {' '}
                                      <FilterDismiss20Filled />{' '}
                                    </StackItem>
                                    <StackItem>
                                      {' '}
                                      <Caption1> Reset Filters </Caption1>{' '}
                                    </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem>
                            </Stack>
                          </StackItem>

                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                          </Stack>
                          <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                            {tableData.length > 0 ? (
                              <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                                <StackItem>
                                  {totalRecords > 0 && (
                                    <Caption1>
                                      Showing {(currentPageNo - 1) * 50 + 1} to{' '}
                                      {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records
                                    </Caption1>
                                  )}
                                </StackItem>
                                <StackItem>
                                  <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                                </StackItem>
                              </Stack>
                            ) : (
                              <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                                <StackItem>
                                  {' '}
                                  <Caption1> </Caption1>{' '}
                                </StackItem>
                              </Stack>
                            )}

                            {/* <StackItem> <Caption1>  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1> </StackItem>
                            <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} /> </StackItem> */}
                          </Stack>
                        </StackItem>
                        {tableData.length > 0 ? <DataTable tableData={tableData} columns={columns}></DataTable> : <p>No results</p>}
                      </Stack>
                    </StackItem>
                  </Stack>
                  <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />
                </Stack>
              </FluentProvider>
            </StackItem>
          </StackItem>
          <StackItem>
            <Text></Text>
          </StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};

function fetchData() {
  throw new Error('Function not implemented.');
}
