import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getCensusListsQuery, getCensusListsCntQuery, getStatusOfdevices, deleteFromMachineQuery, deleteFromEventsQuery, deleteFromUpdateMachinesQuery, deleteFromSoftInstMachineQuery, deleteFromPatchStatusOuery, deleteFromMachineGroupMapQuery, deleteFromMachineGroupsOuery, deleteCensusRowQuery, getMachineIdQuery, deleteFromAssetDataQuery } from "../../graphql/census_queries";
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data?: any;
    func?: any;
    searchVal?: any
    pageNo?: any
    setTotalPages?: any
    setTotalRecords?: any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    isRefresh?:any
    setIsRefresh?:any
    setRefreshToast?:any
    setMachineStatus?: any
}

export const Census_func = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    
    // const tenantName: String = "MBAG_EMEA_Prod";
    const tenantName: String = contextValue.tenantName;
    
    var hostname: string[] = contextValue.selectedRowIdInSubTable.map((val: String) => val.split("-")[1]);
    var censusIds: number[] = contextValue.selectedRowIdInSubTable.map((val: String) => parseInt(val.split("-")[0]));

    const getCensusList = async (whereCondition: any, limit: number, offset: number) => {
        const { data, loading, error } = await client.query({
            query: getCensusListsQuery,
            variables: {whereCondition, limit, offset},
            fetchPolicy: "network-only"
        });

        if(!loading && data){
            console.log("Census data=",data.core_Census); 
            props.func(data);

           getStatusOfdevicesList(tenantName, "Sites", "yes");
        }
    };

    const getCensusListsCnt = async (whereCondition: any) => {
        const { data, loading, error } = await client.query({
            query: getCensusListsCntQuery,
            variables: {whereCondition},
            fetchPolicy:"network-only"
        });

        if(!loading && data){
            props?.setExportDetails && props?.setExportDetails(data.core_Census);

            props?.setTotalRecords(data.core_Census?.length);
            props?.setTotalPages(Math.ceil((data.core_Census?.length)/50));


            // props?.setTotalRecords(data.core_Census_aggregate.aggregate.count);
            // props?.setTotalPages(Math.ceil((data.core_Census_aggregate.aggregate.count)/50));
        }
    }

    const getStatusOfdevicesList = async (name: String, type: String, deviceStatus: String) => {
        const { data, loading, error } = await client.query({
            query: getStatusOfdevices,
            variables: {name, type, deviceStatus},
            fetchPolicy:"network-only"
        });

        if(!loading && data.getMachineSites?.machines?.length > 0){
            var statusObj: any = {};
            data.getMachineSites.machines.map((val: any) => {
                statusObj[`${val.id}`] = JSON.parse(val.name);
            });

            props?.setMachineStatus(statusObj);
        }
    } 

    const getMachineIds = async () => {
        const { data, loading, error } = await client.query({
            query: getMachineIdQuery,
            variables: {hostname, tenantName},
            fetchPolicy:"network-only"
        });

        if(!loading && data.asset_Machine?.length > 0){
            var machineIds = data.asset_Machine.map((val: any) => val.machineid);
            var censusUniqIds = data.asset_Machine.map((val: any) => val.censusuniq);

            if(props.isChecked.expungeAsset){
                expungeValueMapDataFunc(machineIds); //asset_AssetData
            }

            if(props.isChecked.expungePatch){
                expungeMachineGroupsDataFunc(censusUniqIds); //core_MachineGroups
            }
        }
    } 

    const expungeValueMapDataFunc = async (machineid: any) => {
        const { data } = await client.mutate({
            mutation: deleteFromAssetDataQuery,
            variables: {machineid}
        });

        if(data.delete_asset_AssetData.affected_rows > 0){
            return true;
        }
    }

    const expungeMachineDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromMachineQuery,
            variables: {tenantName, hostname}
        });

        if(data.delete_asset_Machine.affected_rows > 0){
            return true;
        }
    }

    const expungeEventsDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromEventsQuery,
            variables: {hostname, tenantName}
        });

        if(data.delete_event_Events.affected_rows > 0){
            return true;
        }
    }

    const expungeUpdateMachinesDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromUpdateMachinesQuery,
            variables: {hostname, tenantName}
        });

        if(data.delete_swupdate_UpdateMachines.affected_rows > 0){
            return true;
        }
    }

    const expungeSoftInstMachineDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromSoftInstMachineQuery,
            variables: {censusIds}
        });

        if(data.delete_softinst_Machine.affected_rows > 0){
            return true;
        }
    }
    
    const expungePatchStatusDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromPatchStatusOuery,
            variables: {censusIds}
        });

        if(data.delete_softinst_PatchStatus.affected_rows > 0){
            return true;
        }
    }

    const expungeMachineGroupMapDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteFromMachineGroupMapQuery,
            variables: {censusIds}
        });

        if(data.delete_core_MachineGroupMap.affected_rows > 0){
            return true;
        }
    }

    const expungeMachineGroupsDataFunc = async (censusuniq: String) => {
        const { data } = await client.mutate({
            mutation: deleteFromMachineGroupsOuery,
            variables: {censusuniq}
        });

        if(data.delete_core_MachineGroups.affected_rows > 0){
            return true;
        }
    }

    const expungeCensusDataFunc = async () => {
        const { data } = await client.mutate({
            mutation: deleteCensusRowQuery,
            variables: {censusIds}
        });

        if(data.delete_core_Census.affected_rows > 0){
            return true;
        }
    }


    useEffect(() => {
        if(props.searchVal?.length > 0 && props.searchVal !== ''){
            getCensusList({site: {_eq: tenantName}, host: {contains: props.searchVal}}, 50, parseInt(props.pageNo));
            getCensusListsCnt({site: {_eq: tenantName}, host: {contains: props.searchVal}});
        }else if(props.isExpungeMachineData){
            console.log("rops.isExpungeMachineData=",props.isExpungeMachineData);
            
            getMachineIds();
            
            if(props.isChecked.expungeAsset){
                expungeMachineDataFunc(); //asset_Machine
            }

            if(props.isChecked.expungeEvent){
                expungeEventsDataFunc(); //event_Events
            }

            if(props.isChecked.expungePatch){
                expungeSoftInstMachineDataFunc(); //softinst_Machine
                expungePatchStatusDataFunc(); //softinst_PatchStatus
                
                expungeUpdateMachinesDataFunc(); //swupdate_UpdateMachines
                expungeMachineGroupMapDataFunc(); //core_MachineGroupMap
            }
            
            expungeCensusDataFunc(); //core_census
            
            props.setIsExpungeMachineData(false);
        }else if(props.isFilter == true){
            if(props.dropDownValue1 == 'Device Name'){
            getCensusList({site: {_eq: tenantName}, host: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
            getCensusListsCnt({site: {_eq: tenantName}, host: {contains: props.dropDownValue2},});
            }
            // else if(props.dropDownValue1 == 'Manufacturer'){
            //     getCensusList({site: {_eq: tenantName}, chassismanufacturer : {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
            //     getCensusListsCnt({site: {_eq: tenantName}, chassismanufacturer : {contains: props.dropDownValue2}});
            // }
            else if(props.dropDownValue1 == 'Operating System'){
                getCensusList({site: {_eq: tenantName}, os: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
                getCensusListsCnt({site: {_eq: tenantName}, os: {contains: props.dropDownValue2}});
            }
            else if(props.dropDownValue1 == 'Nanoheal Version'){
                getCensusList({site: {_eq: tenantName}, clientversion: {contains: props.dropDownValue2}}, 50, parseInt(props.pageNo));
                getCensusListsCnt({site: {_eq: tenantName}, clientversion: {contains: props.dropDownValue2}});
            }
            // else if(props.dropDownValue1 == 'Install Date'){
            //     const [day, month, year] = props.dropDownValue2.split('/'); 
            //     var previousDate = new Date(year, month - 1, day - 1);
            //     var nextDate = new Date(year, month - 1, day + 1);

            //     previousDate = new Date(`'${previousDate}'`);
            //     var bornPreviousTimestamp = previousDate.getTime();

            //     nextDate = new Date(`'${nextDate}'`);
            //     var bornNextTimestamp = nextDate.getTime();
            //     getCensusList({site: {_eq: tenantName}, born: {_gt: bornPreviousTimestamp / 1000, _lt: bornNextTimestamp / 1000}}, 50, parseInt(props.pageNo));
            //     getCensusListsCnt({site: {_eq: tenantName}, born: {_gt: bornPreviousTimestamp / 1000, _lt: bornNextTimestamp / 1000}});
            // }

            else if(props.dropDownValue1 == 'Last Check-in in 1 Week')
            {
                const [ day, month, year] = props.dropDownValue2.split('/')
                const startDate = new Date(`${year}-${month}-${day}T00:00:00`);
                const endDate =  new Date(`${year}-${month}-${day}T23:59:59`);
                const startTimestamp = Math.floor(startDate.getTime() / 1000)
                const endTimestamp = Math.floor(endDate.getTime() /1000);

                getCensusList({
                    site: { _eq: tenantName },
                    last: { _gte: startTimestamp, _lte: endTimestamp }
                }, 50, parseInt(props.pageNo));
            
                // Fetch the count of data with the same condition
                getCensusListsCnt({
                    site: { _eq: tenantName },
                    last: { _gte: startTimestamp, _lte: endTimestamp }
                });
            }
            else if (props.dropDownValue1 == 'Install Date') {
                // Split the selected date into day, month, and year
                const [day, month, year] = props.dropDownValue2.split('/');
            
                // Create Date objects for the start and end of the selected day
                const startDate = new Date(`${year}-${month}-${day}T00:00:00`);
                const endDate = new Date(`${year}-${month}-${day}T23:59:59`);
            
                // Convert the Date objects to Unix timestamps
                const startTimestamp = Math.floor(startDate.getTime() / 1000);
                const endTimestamp = Math.floor(endDate.getTime() / 1000);
            
                // Fetch data with the condition that the 'born' field is within the selected day
                getCensusList({
                    site: { _eq: tenantName },
                    born: { _gte: startTimestamp, _lte: endTimestamp }
                }, 50, parseInt(props.pageNo));
            
                // Fetch the count of data with the same condition
                getCensusListsCnt({
                    site: { _eq: tenantName },
                    born: { _gte: startTimestamp, _lte: endTimestamp }
                });
            }
            

        }else if(props.isRefresh){
            props.setRefreshToast(true);  
            getCensusList({site: {_eq: tenantName}}, 50, parseInt(props.pageNo));
            getCensusListsCnt({site: {_eq: tenantName}});
            setTimeout(() => {
                props.setRefreshToast(false);  
                props.setIsRefresh(false);
            }, 3000);
        }else{
            getCensusList({site: {_eq: tenantName}}, 50, parseInt(props.pageNo));
            getCensusListsCnt({site: {_eq: tenantName}});
        }
    }, [props.searchVal, props.pageNo,props.isFilter, props.isRefresh, props.isExpungeMachineData]);

    return (
        <></>
    )
}