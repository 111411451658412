import { useState } from 'react';
import { Stack } from "@fluentui/react";
import { Button, Text } from '@fluentui/react-components';

const FileUploadComponent = (props: any) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event:any) => {
    const file = event.target.files[0];
    setFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const csvContent = e.target.result;
        const rows = csvContent.split('\n');
        console.log("rows=",rows);
        const data = rows.map((row: any) => row.split(','));
        props?.setDeviceList(rows);
      }; reader.readAsText(file);
    }; setSelectedFile(file);
  };

  const handleUpload = () => { const fileUpload = document.getElementById('fileUpload'); fileUpload?.click(); };

  return (
    <>
      <Stack>
        <input style={{"display": "none"}} type="file" accept='.csv,.xlsx' id="fileUpload" onChange={handleFileChange} />
        {fileName.length > 0 && <Text> File selected: {fileName} </Text>}
      </Stack>
      <Button appearance='secondary' size='small' onClick={handleUpload}> Select a file </Button>
    </>
  );
};

export default FileUploadComponent;