import { Stack } from "@fluentui/react";
import { Overflow } from "@fluentui/react-components";

export const OverflowSetCustomExample = (props: any) => {

  return (
    <Overflow>
      <Stack tokens={{childrenGap:10}} horizontal>
        {props.component.data}
      </Stack>
    </Overflow>
  );
};