import { gql } from '@apollo/client';

const getTDeviceListsQuery = gql`
  query MyQuery($whereCondition: JSON!, $limit: Int!, $offset: Int! = 0, $order: JSON) {
    core_Census(where: $whereCondition, limit: $limit, offset: $offset, order_by: $order) {
      host
      site
      id
      os
    }
  }
`;

const getTDeviceListsCntQuery = gql`
  query MyQuery($whereCondition: JSON!) {
    core_Census(where: $whereCondition) {
      id
    }
  }
`;

const getDeviceClassification = gql`
  query MyQuery($whereCondition: JSON!) {
    core_DeviceClassification(where: $whereCondition) {
      classificationName
      isGroupConfigured
    }
  }
`;

// Online/Offline status of devices
const getStatusOfdevices = gql`
  query getMachineSites($name: String!, $type: String!, $deviceStatus: String = "no") {
    getMachineSites(name: $name, type: $type, deviceStatus: $deviceStatus) {
      machines {
        id
        name
      }
    }
  }
`;

export { getTDeviceListsQuery, getTDeviceListsCntQuery, getDeviceClassification, getStatusOfdevices };
