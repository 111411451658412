import { useState, useEffect, useContext } from 'react';
import { Stack, StackItem } from '@fluentui/react';
import {
  FluentProvider,
  webLightTheme,
  Card,
  Dropdown,
  Option,
  Divider,
  Button,
  Tooltip,
  Title3,
  Subtitle2Stronger,
  Body1,
  Body1Strong,
  Caption1,
  Subtitle2,
  Image,
  useId,
} from '@fluentui/react-components';
import { CommentMultiple20Regular, ArrowRight20Regular } from '@fluentui/react-icons';
import { useNavigate } from 'react-router-dom';
import { Tenant_func } from '../../api/home/tenant_func';
import { Tenant_dev } from '../../api/home/tenant_dev';
import { GlobalContext } from '../../hooks/globalContext';
import { SkeletonHome } from '../../components/skeleton/skeletonHome';
import { ToastComponent } from '../../components/toast/toast.component';
import { homeImage, parentHome, childHome, dividerStyle } from '../../styles/customStyles';
import type { DropdownProps } from '@fluentui/react-components';

export const Home = (props: Partial<DropdownProps>) => {
  const [options, setOptions]: any = useState([]);
  const [deviceCnt, setdeviceCnt] = useState(0);
  const [oneDevicecnt, setoneDevicecnt] = useState(0);
  const [thirtyDevicecnt, setthirtyDevicecnt] = useState(0);
  const [ninetyDeviceCnt, setninetyDeviceCnt] = useState(0);
  const [isOnline, setIsOnline] = useState(true);
  const [isToast, setIsToast] = useState(false);
  const [isAnalyze, setAnalyze] = useState(false);

  const contextValue: any = useContext(GlobalContext);
  const navigate = useNavigate();
  const tenantName = contextValue.tenantName;
  const setTenantName = contextValue.setTenantName;
  const dropdownId = useId('dropdown-default');
  const newdata: any = Tenant_func(setOptions);

  console.log('New Data Count is: ', deviceCnt);

  const handleManageNavigation = () => {
    tenantName ? navigate('/manage/devices/overview') : setIsToast(true);
  };
  const handleAutomateNavigation = () => {
    tenantName ? navigate('/automate/automation/all_automations') : setIsToast(true);
  };
  const handleInvestigateNavigation = () => {
    tenantName ? navigate('/investigate/dashboardlists?name=Application Health') : setIsToast(true);
  };

  useEffect(() => {
    // console.log("Pptions: ", options);
    if (options?.length > 0 && tenantName?.length < 1) {
      setTenantName(options[0]?.sitename);
      localStorage.setItem('tenant', options[0]?.sitename);
    }
  }, [options]);

  useEffect(() => {
    const checkConnection = () => {
      const hasInternetConnection = navigator.onLine;
      setIsOnline(hasInternetConnection);
    };
    checkConnection();
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  useEffect(() => {
    if (options?.length > 0 && tenantName?.length < 1) {
      setTenantName(options[0]?.sitename);
      localStorage.setItem('tenant', options[0]?.sitename);
    }
  }, [options]);

  return (
    <>
      {isOnline ? (
        <FluentProvider theme={webLightTheme} style={parentHome}>
          <Stack tokens={{ padding: 15 }}>
            <Title3> Nanoheal </Title3>
          </Stack>
          <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center" horizontal style={parentHome}>
            <Card size="large" style={homeImage}>
              <Stack tokens={{ childrenGap: 10, padding: 10 }} style={parentHome} verticalAlign="space-between">
                <StackItem>
                  <Stack tokens={{ childrenGap: 15 }}>
                    <StackItem grow shrink>
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem grow shrink>
                          <Stack horizontalAlign="start">
                            <StackItem>
                              <Title3> Welcome to Nanoheal's Device Management Portal </Title3>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        <StackItem grow shrink>
                          <Stack horizontalAlign="end">
                            <StackItem grow shrink>
                              <Tooltip withArrow content="Click here to submit your feedback" relationship="label">
                                <Button icon={<CommentMultiple20Regular />} iconPosition="before">
                                  <Body1Strong> Share Your Feedback with Us </Body1Strong>
                                </Button>
                              </Tooltip>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem>
                      Effortlessly streamline your operations, guaranteed top-notch performance and security to all your connected devices. Immerse
                      yourself in the seamless world of device management and elevate your operational efficiency with Nanoheal.
                    </StackItem>
                    <StackItem>
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem>
                          <Body1> Choose the Tenant that you wish to access : </Body1>
                        </StackItem>
                        <StackItem>
                          <label id={dropdownId} />
                          <Tooltip withArrow content="Click here to select a Tenant" relationship="label">
                            <Dropdown
                              size="small"
                              placeholder="Select a Tenant"
                              value={tenantName}
                              aria-labelledby={dropdownId}
                              {...props}
                              defaultChecked
                            >
                              {options && Array.isArray(options) ? (
                                <>
                                  {options.map((data: any) => (
                                    <Option
                                      key={data}
                                      value={data && data.sitename}
                                      onClick={(e: any) => {
                                        setTenantName(data && data.sitename);
                                        localStorage.setItem('tenant', data.sitename);
                                      }}
                                    >
                                      {data.sitename}
                                    </Option>
                                  ))}
                                </>
                              ) : null}
                            </Dropdown>
                          </Tooltip>
                          {tenantName !== '' ? (
                            <Tenant_dev
                              propTname={tenantName}
                              propdeviceCnt={setdeviceCnt}
                              proponedeviceCnt={setoneDevicecnt}
                              propthirtyDeviceCnt={setthirtyDevicecnt}
                              propninetyDeviceCnt={setninetyDeviceCnt}
                            />
                          ) : null}
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center" horizontal>
                    <Card style={childHome}>
                      <Stack>
                        <Subtitle2> Status </Subtitle2>
                      </Stack>
                      <Stack tokens={{ childrenGap: 20 }} horizontal>
                        <StackItem grow shrink>
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <StackItem>
                                      <Stack horizontal>
                                        <StackItem>
                                          <Image src="/visualization/v2/image/homeOnline.svg" alt="Online Icon" />
                                        </StackItem>
                                        <StackItem>
                                          <Stack>
                                            <StackItem>
                                              <Caption1> Total number of connected Devices </Caption1>
                                            </StackItem>
                                            <StackItem>
                                              <Subtitle2Stronger> {deviceCnt} </Subtitle2Stronger>
                                            </StackItem>
                                            {/* <StackItem> <Subtitle2Stronger> {newdata && Array.isArray(newdata) ? <> {newdata.length + 1} </> : ''} </Subtitle2Stronger> </StackItem> */}
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack horizontal>
                                        <StackItem>
                                          <Image src="/visualization/v2/image/homeOnline.svg" alt="Online Icon" />
                                        </StackItem>
                                        <StackItem>
                                          <Stack>
                                            <StackItem>
                                              <Caption1> Devices that Reported Last 1 Day </Caption1>
                                            </StackItem>
                                            <StackItem>
                                              <Subtitle2Stronger> {oneDevicecnt} </Subtitle2Stronger>
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack horizontal>
                                        <StackItem>
                                          <Image src="/visualization/v2/image/homeOnline.svg" alt="Online Icon" />
                                        </StackItem>
                                        <StackItem>
                                          <Stack>
                                            <StackItem>
                                              <Caption1> Devices that Reported Last 30 Days </Caption1>
                                            </StackItem>
                                            <StackItem>
                                              <Subtitle2Stronger> {thirtyDevicecnt} </Subtitle2Stronger>
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <StackItem>
                                      <Stack horizontal>
                                        <StackItem>
                                          <Image src="/visualization/v2/image/homeOffline.svg" alt="Offline Icon" />
                                        </StackItem>
                                        <StackItem>
                                          <Stack>
                                            <StackItem>
                                              <Caption1> Devices that did not Report in Last 90 days </Caption1>
                                            </StackItem>
                                            <StackItem>
                                              <Subtitle2Stronger> {deviceCnt ? <> {deviceCnt - ninetyDeviceCnt} </> : ''} </Subtitle2Stronger>
                                            </StackItem>
                                            {/* <StackItem> <Subtitle2Stronger> {newdata && Array.isArray(newdata) ? <> {newdata.length + 1 - ninetyDeviceCnt} </> : ''} </Subtitle2Stronger> </StackItem> */}
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem>
                                      <Stack horizontal>
                                        <StackItem>
                                          <Image src="/visualization/v2/image/homeOnline.svg" alt="Online Icon" />
                                        </StackItem>
                                        <StackItem>
                                          <Stack>
                                            <StackItem>
                                              <Caption1> Devices that Reported Last 90 days </Caption1>
                                            </StackItem>
                                            <StackItem>
                                              <Subtitle2Stronger> {ninetyDeviceCnt} </Subtitle2Stronger>
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Card>
                    <Card style={childHome}>
                      <Stack>
                        <Subtitle2> Spotlight </Subtitle2>
                      </Stack>
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem grow shrink>
                          <Stack tokens={{ childrenGap: 30 }} horizontal>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <StackItem>
                                      <Subtitle2Stronger> Nanoheal Help </Subtitle2Stronger>
                                    </StackItem>
                                    <StackItem>
                                      <Caption1>
                                        Explore the Nanoheal Help Portal - Your dedicated source of expert guidance and swift solutions, ensuring you
                                        make the most of our advanced device management platform with ease
                                      </Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem grow shrink>
                                          <Stack horizontalAlign="start">
                                            <StackItem grow shrink>
                                              <Body1> Explore </Body1>
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                        <StackItem grow shrink>
                                          <Stack horizontalAlign="end">
                                            <StackItem grow shrink>
                                              <ArrowRight20Regular />
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                            <StackItem>
                              <Stack horizontal style={dividerStyle} horizontalAlign="center">
                                <Divider vertical />
                                <Divider vertical />
                                <Divider vertical />
                              </Stack>
                            </StackItem>
                            <StackItem grow shrink>
                              <Stack horizontalAlign="start">
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <StackItem>
                                      <Subtitle2Stronger> FAQ </Subtitle2Stronger>
                                    </StackItem>
                                    <StackItem>
                                      <Caption1>
                                        Find answers to common queries effortlessly, empowering you to navigate our platform seamlessly and make the
                                        most of your device management experience
                                      </Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem grow shrink>
                                          <Stack horizontalAlign="start">
                                            <StackItem grow shrink>
                                              <Body1> Explore </Body1>
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                        <StackItem grow shrink>
                                          <Stack horizontalAlign="end">
                                            <StackItem grow shrink>
                                              <ArrowRight20Regular />
                                            </StackItem>
                                          </Stack>
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Card>
                  </Stack>
                </StackItem>
                <StackItem>
                  <Card>
                    <Subtitle2> Quick Links </Subtitle2>
                    <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center" horizontal>
                      <Tooltip withArrow content="Click here to view Automate" relationship="label">
                        <Card
                          style={childHome}
                          onClick={() => {
                            handleAutomateNavigation();
                            setAnalyze(true);
                          }} /* onContextMenu={(e) => { e.preventDefault();  window.open('/automate/automation/all_automations', '_blank')}} */
                        >
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Image src="/visualization/v2/image/homeAutomateIcon.svg" alt="Automate Icon" />
                            </StackItem>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Subtitle2> Automate </Subtitle2>
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Your dedicated source for expert guidance and swift solutions, ensuring you make the most of our advanced device
                                    management platform with ease
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </Card>
                      </Tooltip>
                      <Tooltip withArrow content="Click here to Investigate the Dashboard" relationship="label">
                        <Card
                          style={childHome}
                          onClick={() => {
                            handleInvestigateNavigation();
                          }} /* onContextMenu={(e) => { e.preventDefault();  window.open('/investigate/dashboardlists?name=Application Health', '_blank')}} */
                        >
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Image src="/visualization/v2/image/homeInvestigateIcon.svg" alt="Investigate Icon" />
                            </StackItem>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Subtitle2> Investigate </Subtitle2>
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Your dedicated source for expert guidance and swift solutions, ensuring you make the most of our advanced device
                                    management platform with ease
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                        </Card>
                      </Tooltip>
                      <Tooltip withArrow content="Click here to Manage Tenant" relationship="label">
                        <Card
                          style={childHome}
                          onClick={() => {
                            handleManageNavigation();
                          }} /* onContextMenu={(e) => { e.preventDefault();  window.open('/manage/devices/overview', '_blank')}} */
                        >
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Image src="/visualization/v2/image/homeManageIcon.svg" alt="Manage Icon" />
                            </StackItem>
                            <StackItem>
                              <Stack>
                                <StackItem>
                                  <Subtitle2> Manage </Subtitle2>
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Your dedicated source for expert guidance and swift solutions, ensuring you make the most of our advanced device
                                    management platform with ease
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>
                          {isToast && (
                            <ToastComponent
                              setmessagebar={setIsToast}
                              toastIntent="warning"
                              position="top"
                              title="Select a Tenant before Navigating"
                            />
                          )}
                        </Card>
                      </Tooltip>
                    </Stack>
                  </Card>
                </StackItem>
              </Stack>
            </Card>
          </Stack>
          <Card size="small" appearance="subtle" style={{ width: '98.9vw' }} />
        </FluentProvider>
      ) : (
        <SkeletonHome />
      )}
    </>
  );
};
