import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupIdOfTenantQuery, getComponentsDetailsOfDartQuery, insertValueOfComponentQuery, updateValueOfComponentQuery, updateAutomationStautsQuery } from "../../graphql/remediations_queries"
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { useNavigate } from "react-router-dom";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    groupAttachment: any;
    groupDetachment: any;
}

const Remediations_selfhelp_func = (props: any) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const contextValue: any = useContext(GlobalContext);
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    // var mgroupuniq = '';
    var insertCounter = 0;
    var totalGrpDataCnt = 0;
    var failedAttachmentGroups: any = [];
    var failedDetachmentGroups: any = [];
    var mcatuniq = '';
    var selctedValue = '';
    var automationData = {
        id: '',
        automation_name : '',
        automation_description : '',
        dartNo : '',
        category : '',
        category_description : '',
        subCategory : '',
        subCategory_description : '',
        os : '',
        automationSequence : '',
        clientEnabled: '',
        status: '',
        sequenceIDs: [],
    };

    var baseProfilesData: any = {};
    var profileSequenceData: any = {};
    var sequenceDetailsData: any = {};
    var variableConfigData: any = {};
    var profileSchedData: any = {};
    var serviceLogMasterData: any = {};

    var subAutomation: any = [];

    const countOccurrences = (inputString: any, subString: any) => {
        let count = 0;
        let index = inputString.indexOf(subString);
      
        while (index !== -1) {
          count++;
          index = inputString.indexOf(subString, index + 1);
        }
      
        return count;
    }

    const getAutomationList = async(whereCondition: any, limit: any = null, offset: any = null, name: String = '') => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data){
            if(name == 'sequenceConfig'){
                console.log("inside sequenceConfig");

                data.core_AutomationJSON.map((val: any) => {
                    // var jsonVal = JSON.parse(val.jsonValue);
                    
                    if(val.dartid == 270){
                        subAutomation = [...subAutomation,
                            {
                                id: val.id,
                                dartId: val.dartid,
                                automationName: val.automationName,
                                jsonVal: JSON.parse(val.varValues),
                            }
                        ]
                    }else{
                        subAutomation = [...subAutomation,
                            {
                                id: val.id,
                                dartId: val.dartid,
                                automationName: val.automationName,
                                jsonVal: JSON.parse(val.jsonValue),
                            }
                        ]
                    }
                });

            }else{
                var jsonVal = JSON.parse(data.core_AutomationJSON[0]?.jsonValue);

                automationData.id = data.core_AutomationJSON[0].id;
                automationData.automation_name = jsonVal.automation_name;
                automationData.automation_description = jsonVal.description;
                automationData.dartNo = data.core_AutomationJSON[0].dartid;
                automationData.status = data.core_AutomationJSON[0].status;
                automationData.category = jsonVal.category?.split(' ').map((val: any) => val.charAt(0).toUpperCase()+val.slice(1)).join(' ');
                automationData.category_description = jsonVal.category_description;
                automationData.subCategory = jsonVal.sub_category?.split(' ').map((val: any) => val.charAt(0).toUpperCase()+val.slice(1)).join(' ');
                automationData.subCategory_description = jsonVal.sub_category_description;
                automationData.os = jsonVal.os == '*' ? 'common' : jsonVal.os;
                automationData.automationSequence = jsonVal.automation_sequence;
                automationData.clientEnabled = jsonVal.clientEnabled;
            }
        }
    }
    
    //to check site/group is configured or not
    const getGroupIdOfTenant = async (whereCondition: any, action: any) => {
        const { data, loading, error } = await client.query({
            query: getGroupIdOfTenantQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            totalGrpDataCnt = data.core_MachineGroups.length;
            data.core_MachineGroups.map(async (val: any) => {
                insertCounter++;
                mcatuniq = val.mcatuniq;
                await getComponentsDetailsOfDart([304,306], val.mgroupuniq, val.name);

                if(action == 'detach'){
                    console.log("detach updating...........");

                    var automation_name = automationData.automation_name;
                    var varValue = automation_name?.split(" ").join("");
                    var profileScheduledVal = profileSchedData[`${val.name}`].componentValue;
                    
                    if(profileScheduledVal == undefined || !(profileScheduledVal.includes(`${varValue}\n`))){
                        setTimeout(async () => {
                            removeValueInServiceLogComponent(val.mgroupuniq, val.name);
                        }, 2000);
                    }else{
                        failedDetachmentGroups.push(val.name);
                        props.setToastMessage(`Can't detach this remediation from ${val.name} as it is already scheduled`);

                        props.setmessagebar1(false);
                        props.setErrorToast(true);
                    }
                } 
                
                if(action == 'attach'){
                    console.log("attach updating...........");

                    setTimeout( () => {
                        setValueOfServiceLogComponent(val.mgroupuniq, val.name);
                    }, 2000);
                }
            });
        }else{
            console.log("Site has not configured");

            props.setmessagebar1(false);
            props.setmessagebar2(true);
            props.setToastMessage("Assignment failed");
        }
    }

    //to fetch the name, varnameuniq, varscopuniq, varuniq of components in dart 304
    const getComponentsDetailsOfDart = async (dartId: any, groupId: any, name: String) => {
        const { data, loading, error } = await client.query({
            query: getComponentsDetailsOfDartQuery,
            variables: { dartId, groupId },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            data.core_Variables.map((val: any) => {
                if(val.name === 'S00304_BaseProfiles'){
                    var baseProfilesIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    baseProfilesData[`${name}`] = baseProfilesIds;
                }else if (val.name === 'S00304_ProfileSequence'){
                    var profileSequenceIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    profileSequenceData[`${name}`] = profileSequenceIds;
                }else if(val.name === 'S00304_VariableIdConfig'){
                    var variableConfigIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    variableConfigData[`${name}`] = variableConfigIds;
                }else if (val.name === 'S00304_SequenceDetails'){
                    var sequenceDetailsIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    sequenceDetailsData[`${name}`] = sequenceDetailsIds;
                }else if (val.name === 'S00304_ProfileSched'){
                    var profileSchedIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    profileSchedData[`${name}`] = profileSchedIds;
                }else if(val.name === 'S00306_ServiceLogMaster'){
                    var serviceLogMasterIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    serviceLogMasterData[`${name}`] = serviceLogMasterIds;
                }
            });
        }
    }

    const setValueOfServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var serviceLogMasterVal = '';
        var newVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`].componentValue != undefined){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');
                serviceLogMasterArr = serviceLogMasterArr.filter((val: any) => val !== '');
    
                var serviceLogMasterLastVal = serviceLogMasterArr[(serviceLogMasterArr?.length - 1)];
    
                var lastSequenceId: any = serviceLogMasterLastVal?.split("#NXT#")[0];
                lastSequenceId = parseInt(lastSequenceId);
                lastSequenceId = isNaN(lastSequenceId) ? 0 : lastSequenceId;
    
                lastSequenceId++;
    
                newVal = `\n${lastSequenceId}#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
    
                newVal = serviceLogMasterVal + newVal;
            }else{
                var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
                newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

                newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

                newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

                newVal += `5#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
            }

            // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: serviceLogMasterData[`${name}`].id}}, newVal, (revised + 1));
        }else{
            var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
            newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

            newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

            newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

            newVal += `5#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;

            await insertValueOfComponent(newVal, 0, serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq);
        }
        
        setValueOfBaseProfileComponent(mgroupuniq, name);
    }

    const setValueOfBaseProfileComponent = async (mgroupuniq: any, name: String) => {
        console.log("baseProfile component loop entered");    
        
        var automation_name = automationData.automation_name;
        var automation_description = automationData.automation_description;
        var category = automationData.category;
        var category_description = automationData.category_description;
        var subCategory = automationData.subCategory;
        var subCategory_description = automationData.subCategory_description;
        var os = automationData.os;
        var automationSequence = automationData.automationSequence;
        var varValue = automation_name?.split(" ").join("");
        var clientEnabled = automationData.clientEnabled ? 1 : 3;
    
        var newVal: String = '';
        var baseProfileVal = '';
        var page = '';
        var categoryPage: any = '';
        var subCategoryPage: any = '';
        var catL1 = '';
        var catL2 = '';
        var subCatL1 = '';
        var subCatL2 = '';
        var L3 = '';
        var mid: any = 0;

        //structuring value for baseprofile component starts here
        if(baseProfilesData[`${name}`].varuniq?.length > 0 && baseProfilesData[`${name}`].componentValue != undefined){ // to check if baseprofile is present for particluar site/group in varvalues table 
            var res = baseProfilesData[`${name}`].componentValue;
            var revised = baseProfilesData[`${name}`].revised;
                
            baseProfileVal = res?.trim();
            var baseProfileArr = baseProfileVal?.split('#F#NXT#1#NXT#0');
                
            baseProfileArr = baseProfileArr.filter(element => element !== '');
            var baseProfileArr1 = baseProfileArr[(baseProfileArr?.length - 1)];
            mid = parseInt(baseProfileArr1?.split('#NXT#')[1]);
            mid = isNaN(mid) ? 0 : mid;

            var pageArr: any = [];
            if(baseProfileVal.includes(category + '#NXT#L1')){ // if user defined category is present in baseprofile of varvalues table
                pageArr = baseProfileArr.filter((val) => val.includes(category + '#NXT#L1'));
                categoryPage = pageArr[0]?.split('#NXT#')[13];

                if(subCategory != undefined && subCategory != ''){ // if user has defined the sub category
                    
                    if(baseProfileVal.includes(subCategory + '#NXT#L1')){ // if user defined sub category is present in baseprofile of varvalues table
                        pageArr = baseProfileArr.filter((val) => val.includes(subCategory + '#NXT#L1'));
                        subCategoryPage = parseInt(pageArr[0]?.split('#NXT#')[13]);

                        mid++;
                        L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${subCategoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${subCategoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;
                    }else{ // category is present, user defined sub category is present, but user defined sub category is not present in varvalue table
                        subCategoryPage = countOccurrences(baseProfileVal,'L1');
                        pageArr = baseProfileArr.filter((val) => val.includes('#NXT#L1#NXT#1#'));
                        subCategoryPage = parseInt(pageArr[`${parseInt(subCategoryPage) - 1}`]?.split('#NXT#')[13]);

                        mid++;
                        subCatL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#${subCategoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                        mid++;
                        subCatL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${subCategoryPage + 1}#NXT#${subCategory}#NXT#null#NXT#null#NXT#null#NXT#${subCategory}#NXT#NA#NXT#${subCategory_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                        mid++;
                        L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${subCategoryPage + 1}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${subCategoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`;
                    }
                }else{ // if user has not defined the sub category
                    console.log("else of user has defined the sub category");
                    
                    mid++;
                    L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;
                }

                newVal = baseProfileVal + "\n" + subCatL1 + subCatL2 + L3;
            }else{ //if user defined category is not present in baseprofile of varvalues table
                console.log("new cat");
                
                categoryPage = countOccurrences(baseProfileVal,'L1');

                if(categoryPage > 1){
                    pageArr = baseProfileArr.filter((val) => val.includes('#NXT#L1#NXT#1#'));
                    categoryPage = parseInt(pageArr[`${parseInt(categoryPage) - 1}`]?.split('#NXT#')[13]);
                }else{
                    categoryPage = 2;
                }

                console.log("total L1s=",categoryPage);
                
                mid++;
                catL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#${categoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                mid++;
                catL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${categoryPage + 1}#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";
                console.log("catL1=",catL1);

                if(subCategory != undefined && subCategory != ''){ // if user has defined the sub category
                    // user defined category present,  user defined sub category present,  but user defined sub category is not present in varvalues table
                    // pageArr = baseProfileArr.filter((val) => val.includes(subCategory + '#NXT#L1'));
                    // subCategoryPage = parseInt(pageArr[0]?.split('#NXT#')[13]);

                    subCategoryPage = countOccurrences(baseProfileVal,'L1');
                    console.log("total subcat L1s=",categoryPage);
                    mid++;
                    subCatL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#${categoryPage + 2}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                    mid++;
                    subCatL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${categoryPage + 2}#NXT#${subCategory}#NXT#null#NXT#null#NXT#null#NXT#${subCategory}#NXT#NA#NXT#${subCategory_description}#NXT#${os}#NXT#${categoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                    mid++;
                    L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage + 2}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage + 2}#NXT#enable#NXT#F#NXT#1#NXT#0`;
                    
                }else{ //user defined category present,  user defined sub category not present
                    mid++;
                    L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage + 1}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`;
                }

                if(baseProfileVal?.length > 0){
                    newVal = baseProfileVal + "\n" + catL1 + catL2 + subCatL1 + subCatL2 + L3;
                }else{
                    newVal = catL1 + catL2 + subCatL1 + subCatL2 + L3;
                }
            }
            
            // await updateValueOfComponent(newVal, (revised + 1), baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq, baseProfilesData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: baseProfilesData[`${name}`].varuniq}, varscopuniq: {_eq: baseProfilesData[`${name}`].varscopuniq}}, newVal, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: baseProfilesData[`${name}`].id}}, newVal, (revised + 1));
                
        }else{ //if baseprofile is not present for particluar site/group in varvalues table
            console.log("else");

            baseProfileVal = `Enable/Disable#NXT#mid#NXT#menuItem#NXT#type#NXT#parentId#NXT#profile#NXT#dart#NXT#variable#NXT#varValue#NXT#shortDesc#NXT#description#NXT#tileDesc#NXT#OS#NXT#page#NXT#status#NXT#authFalg#NXT#usageType#NXT#dynamic\n1#NXT#1#NXT#home#NXT#L0#NXT#0#NXT#Profile#NXT#null#NXT#null#NXT#null#NXT#Profile#NXT#NA#NXT#Profile#NXT#common#NXT#0#NXT#disable#NXT#F#NXT#1#NXT#0\n1#NXT#2#NXT#system info#NXT#L0#NXT#0#NXT#system info#NXT#null#NXT#null#NXT#null#NXT#system info#NXT#NA#NXT#system info#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#3#NXT#settings#NXT#L0#NXT#0#NXT#settings#NXT#null#NXT#null#NXT#null#NXT#settings#NXT#NA#NXT#settings#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#4#NXT#toolbox#NXT#L0#NXT#0#NXT#TOOLBOX#NXT#null#NXT#null#NXT#null#NXT#toolbox#NXT#NA#NXT#toolbox#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#5#NXT#service log#NXT#L0#NXT#0#NXT#service log#NXT#null#NXT#null#NXT#null#NXT#Service Log#NXT#NA#NXT#Service Log#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#6#NXT#Troubleshooters#NXT#L1#NXT#1#NXT#Troubleshooters#NXT#null#NXT#null#NXT#null#NXT#Troubleshooters#NXT#NA#NXT#You can fix many common issues easily with these powerful troubleshooting tools. Choose a category and then select the fix that matches the symptoms of the problem.#NXT#common#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            categoryPage = 3;
            mid = 8;

            catL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            mid++;
            catL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${categoryPage}#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            if(subCategory != undefined && subCategory != ''){ //user defined sub category is present
                subCategoryPage = 4;
                mid++;
                subCatL1 = `${clientEnabled}#NXT#${mid}#NXT#${subCategory}#NXT#L1#NXT#1#NXT#${subCategory}#NXT#null#NXT#null#NXT#null#NXT#${subCategory}#NXT#NA#NXT#${subCategory_description}#NXT#${os}#NXT#${subCategoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

                mid++;
                subCatL2 = `${clientEnabled}#NXT#${mid}#NXT#${subCategory}#NXT#L2#NXT#${subCategoryPage}#NXT#${subCategory}#NXT#null#NXT#null#NXT#null#NXT#${subCategory}#NXT#NA#NXT#${subCategory_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

                mid++;
                L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${subCategoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${subCategoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;
            }else{ // user defined sub category is not present
                mid++;

                L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;
            }

            baseProfileVal = baseProfileVal + catL1 + catL2 + subCatL1 + subCatL2 + L3;

            await insertValueOfComponent(baseProfileVal, 0, baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq);
        }
        //structuring value for baseprofile component ends here
        
        setValueOfProfileSequenceComponent(mgroupuniq, name);
    };

    const setValueOfProfileSequenceComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var automationSequence = automationData.automationSequence;
        var varValue = automation_name?.split(" ").join("");
        var profileSequence = '';
        var newVal: String = '';
        var newSequenceIDArr: any = [];
        
        //structuring value for profilesequence component ends here
        if(profileSequenceData[`${name}`].varuniq?.length > 0 && profileSequenceData[`${name}`].componentValue != undefined){
            console.log("profile seq updated");

            var res = profileSequenceData[`${name}`].componentValue;
            var revised = profileSequenceData[`${name}`].revised;

            profileSequence = res?.trim();
            if(profileSequence?.length > 0){
                var profileSequenceArr = profileSequence.split("\r\n");
                var lastSequenceArr = profileSequenceArr[profileSequenceArr.length - 1]?.split(":");
                var sequenceIDsArr: any = lastSequenceArr[lastSequenceArr.length - 1]?.split(",");
                var lastSequenceId = sequenceIDsArr[sequenceIDsArr.length - 1];
                lastSequenceId = parseInt(lastSequenceId);

                console.log("lastSequenceId=",lastSequenceId);
                
                automationSequence.split(",").map((val: any) => {
                    newSequenceIDArr.push(++lastSequenceId);
                });

                console.log("newSequenceIDArr=",newSequenceIDArr);     

                automationData.sequenceIDs = newSequenceIDArr;
                
                newVal = profileSequence + "\r\n" + `${varValue}:${newSequenceIDArr.join(",")}`;
                // newVal = profileSequence + "\r\n" + `${varValue}:${automationSequence}`;
            }else{
                lastSequenceId = 0;
                automationSequence.split(",").map((val: any) => {
                    newSequenceIDArr.push(++lastSequenceId);
                });

                automationData.sequenceIDs = newSequenceIDArr;

                newVal = `${varValue}:${newSequenceIDArr.join(",")}`;
                // newVal = `${varValue}:${automationSequence}`;
            }

            console.log("profile seq val=",newVal);

            // await updateValueOfComponent(newVal, (revised + 1), profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq, profileSequenceData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSequenceData[`${name}`].varuniq}, varscopuniq: {_eq: profileSequenceData[`${name}`].varscopuniq}}, newVal, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: profileSequenceData[`${name}`].id}}, newVal, (revised + 1));
        }else{
            console.log("profile seq inserted");

            lastSequenceId = 0;
            automationSequence.split(",").map((val: any) => {
                newSequenceIDArr.push(++lastSequenceId);
            });

            automationData.sequenceIDs = newSequenceIDArr;

            await insertValueOfComponent(`${varValue}:${newSequenceIDArr.join(",")}`, 0, profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq);
        }
        //structuring value for profilesequence component ends here

        setValueOfVariableConfigComponent(mgroupuniq, name);
    }

    var vidArr: any = [];
    const setValueOfVariableConfigComponent = async(mgroupuniq: any, name: String) => {
        console.log("inside setValueOfVariableConfigComponent");

        var automation_name = automationData.automation_name;
        var automationSequence = automationData.automationSequence;
        var newVal: String = '';
        var res: any = '';
        var revised: any = variableConfigData[`${name}`].revised;
        var lastVid: any = 0;

        await getAutomationList({id: {_in: automationSequence?.split(',').map(Number)}}, null, null, 'sequenceConfig'); //get the jsonVal of autmation sequence ids

        if(variableConfigData[`${name}`].componentValue != undefined && variableConfigData[`${name}`].componentValue?.length > 0){ 
            res = variableConfigData[`${name}`].componentValue;

            var variableConfigArr = res?.split('#NXTVAR#');
            var arrLastVal: any = variableConfigArr[variableConfigArr?.length - 1];
            arrLastVal = arrLastVal?.split("\n").filter((val: any) => val.includes('VID'))[0];

            lastVid = parseInt(arrLastVal?.split(':')[1]);
            newVal =  res;
        } 
           
        subAutomation.map((ele: any) => {
        console.log("dart no in ele.id :-",ele.dartId)
        console.log("ELE", ele);
        
                vidArr = [];
                var items = ele.dartId === 288 ? Object.keys(ele.jsonVal.form1) : Object.keys(ele.jsonVal);
                items.map((val: any) => {
                    lastVid++;
                    vidArr.push(lastVid);
                    
                    var vid = `VID:${lastVid}\n`;
                    var vn = ele.jsonVal.form1 ? `VN:S00288PatchesAvailableNew5 \n`:`VN:${val}\n`; 
                    var value = `VALUE:${ele.jsonVal[val]}`;
                    if(ele.dartId === 288){
                        var value = `VALUE:${config288(ele,newVal)}`;
                    }else{
                        var value = `VALUE:${ele.jsonVal[val]}`;
                    }
        
                    if(newVal?.length > 0){
                        newVal +=  "\n#NXTVAR#\n" + vid + vn + value;
                    }else{
                        newVal += vid + vn + value;
                    }
                });
                ele.vids = vidArr;
            
        });
           
        if(variableConfigData[`${name}`].varuniq?.length > 0 && variableConfigData[`${name}`].componentValue != undefined){     
            console.log("var config=",newVal);
                   
            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: variableConfigData[`${name}`].varuniq}, varscopuniq: {_eq: variableConfigData[`${name}`].varscopuniq}}, newVal, (revised + 1));
        }else{
            await insertValueOfComponent(newVal, 0, variableConfigData[`${name}`].varnameuniq, variableConfigData[`${name}`].varscopuniq, variableConfigData[`${name}`].varuniq, mgroupuniq);
        }

        setValueOfSequenceDetailsComponent(mgroupuniq, name);
    }

    const setValueOfSequenceDetailsComponent = async(mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var dartId = automationData.dartNo;
        var os = automationData.os;
        var varValue = automation_name?.split(" ")?.join("");
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var status = false;

        if(sequenceDetailsData[`${name}`].varuniq?.length > 0 && sequenceDetailsData[`${name}`].componentValue != undefined){ 
            var res =  sequenceDetailsData[`${name}`].componentValue;
            var revised = sequenceDetailsData[`${name}`].revised;
            var SequenceDetails = '';

            if(res?.length > 0){
                var sequenceDetailsArr = res?.split('\n');
                SequenceDetails = res;
                
                var sequenceIdArr = sequenceDetailsArr.map((val: any) => val.split(",")[0]);

                //syntax: sequenceNo,dartNo,Automation name,vid:vid:vid...
                console.log("sub subAutomation=",subAutomation);
                
                subAutomation.map((val: any, i: any) => {
                    // if(!(sequenceIdArr.map(Number).includes(parseInt(val.id)))){
                        SequenceDetails += "\n" + `${automationData.sequenceIDs[i]},${val.dartId},${val.automationName},${(val.vids).join(":")}`;
                    // } 
                });
            }else{
                console.log("subAutomation==",subAutomation);
                
                subAutomation.map((val: any, i: any) => {
                    SequenceDetails += `${automationData.sequenceIDs[i]},${val.dartId},${val.automationName},${(val.vids).join(":")}`;
                });
            }

            console.log("SequenceDetails=",SequenceDetails);

            // status = await updateValueOfComponent(SequenceDetails, (revised + 1), sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq, sequenceDetailsData[`${name}`].id);

            status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: sequenceDetailsData[`${name}`].varuniq}, varscopuniq: {_eq: sequenceDetailsData[`${name}`].varscopuniq}}, SequenceDetails, (revised + 1));

            // status = await updateValueOfComponents({valueid: {_eq: sequenceDetailsData[`${name}`].id}}, SequenceDetails, (revised + 1));
        }else{
            var SequenceDetails = '';
            console.log("subAutomation insert=",subAutomation);
            
            subAutomation.map((val: any, i: any) => {
               // val.id++;
                SequenceDetails += i == 0 ? `${automationData.sequenceIDs[i]},${val.dartId},${val.automationName},${(val.vids).join(":")}` : "\n" + `${automationData.sequenceIDs[i]},${val.dartId},${val.automationName},${(val.vids).join(":")}`;
            });

            status = await insertValueOfComponent(SequenceDetails, 0, sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq);
        }

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;
            
            if(selctedValue == 'tenant'){
        
                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":all";
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:all`);

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `${tenantName}:all`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:all}`;
                }
            }else{
                var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":" + selectedGroups.join(",");
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1];

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp = automationStatus.split(":")[1];

                        selctedValue = `${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:${selectedGroups.join(",")}`; 
                }
            }

            var ids = subAutomation.map((val : any) => val.id);
            ids.push(automationData.id);
            
            await updateAutomationStauts({id: {_in: ids}}, selctedValue, 0, 'attach');
            // await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, 0, 'attach');
        }
    }

    const config288 = (ele:any,newVal:any)=>{
        const packageName = ele.automationName? ele.automationName :null
    
        const platform =  ele.jsonVal.form1.OS ? ele.jsonVal.form1.OS : null
        console.log(platform,"Platform")
        //Advance Configuration  starts 
        let pathLogFile = ''
        const readLogFileCheck = ele.jsonVal.form3.Logfile //1 -readlogfilebutton enabled  or 0 readlogFilebutton disabled 
        if(readLogFileCheck == 1){
            pathLogFile = ele.jsonVal.form3.Log_file //path of log file
        }
        else{
           pathLogFile = ''
        }

        let config32bit = ''
        let config64bit = ''
        const messageBoxArray: string[] = []
        let edConfig=''
        ele.jsonVal.form2.map((App:any,indexValue:number) => {
        let uploadedApplication = App?.uploadApplication1 ?? "";
        
        const path = getDownloadableLink(uploadedApplication)
        // APP details  
        // const path = "";
        //app details  section end
        const download_installCheck32=App?.Download_Execute32bit ?? "";
        const download_installCheck64=App?.Download_Execute64bit ?? "";

        //----------------DONWLOAD SECTION 32bit ---------------
        const executeIn_download_32 =  App?.Executein32bitd ? App?.Executein32bitd: null;
        console.log("app dowlload",App?.config32bit)
        const downloadOperationMode_download_32  = App?.config32bit
        ? App?.config32bit
        : null 
        let downloadLocation_bit_32 = '' 
        if(App.Download_location32bit == 'Default'){
           downloadLocation_bit_32 = 'C:\\ProgramData'
        }
        else{
           downloadLocation_bit_32  = App?.download_location32bit ? App?.download_location32bit : ""
        }
        const preCheckMode_download_32 = App?.selectType32bitd ? App?.selectType32bitd : null// 0 for file ,  
        const preCheckValue_download_32 = App?.
        preCheck32bitd ? App?.
        preCheck32bitd : null 
        const filepath_download_32 = App?.filePath32bitd ? App?.filePath32bitd : " "
        const Registry_rootkey_download_32 = App?.RegrootKey32bitd ? App?.RegrootKey32bitd : null 
        const Registry_path_download_32 = App?.Registrypath32bitd ? App?.Registrypath32bitd : null
        const Registry_type_download_32 = App?.Regtype32bitd ? App?.Regtype32bitd : null 
        const Registry_value_download_32 = App?.Registryvalue32bitd ? App?.Registryvalue32bitd : null
        const softwareName_download_32 = App?.selectType_softName32bitd ? App?.selectType_softName32bitd : null 
        const softwareVersion_download_32 = App?.selectType_softVersion32bitd ? App?.selectType_softVersion32bitd : null 
        // -----------DONWLOAD SECTION PARAMATERS 32bit END-----
        
        //-----------INSTALL SECTION PARAMERTES  32bit START
        const executeIn_install_32 = App?.Executein32bitI ? App?.Executein32bitI : null 
        const installationMode_install_32 = App?.Installationmode32bit ? App?.Installationmode32bit : "" 
        const commandLineInstallationInstruction_install_32  = App?.CmdInstr32bit ? App?.CmdInstr32bit : ""
   
        const preCheckMode_install_32 = App?.selectType32bitI ? App?.selectType32bitI : null //file, registry, software
        const preCheckValue_install_32 = App?.preCheck32bitI ? App?.preCheck32bitI : null 

        const filepath_install_32 = App?.filePath32bitI ? App?.filePath32bitI : null
        const Registry_rootkey_install_32 = App?.RegrootKey32bitI ? App?.RegrootKey32bitI : null
        const Registry_path_install_32 = App?.Registrypath32bitI ? App?.Registrypath32bitI : null 
        const Registry_type_install_32 = App?.Regtype32bitI ? App?.Regtype32bitI : null
        const Registry_value_install_32 = App?.Registryvalue32bitI ?  App?.Registryvalue32bitI : null
        const softwareName_install_32 = App?.selectType_softName32bitI ? App?.selectType_softName32bitI : null 
        const softwareVersion_install_32 = App?.selectType_softVersion32bitI ? App?.selectType_softVersion32bitI : null
        //-----------INSTALL SECTION PARAMETERS 32bit END---------

        // 64 bit configuration Download STARTS
        const executeIn_download_64 =  App?.Executein64bitd ? App?.Executein64bitd: null;
        const downloadOperationMode_download_64 = App?.config64bit
        ? App?.config64bit
        : null 
        let downloadLocation_bit_64 = '' 
        if(App.Download_location64bit == 'Default'){
           downloadLocation_bit_64 = 'C:\\ProgramData'
        }
        else{
           downloadLocation_bit_64  = App?.download_location64bit ? App?.download_location64bit : ""
        }
        const preCheckMode_download_64 = App?.selectType64bitd ? App?.selectType64bitd : null// 0 for file ,  1 for software, 2 for registry
        const preCheckValue_download_64 = App?.preCheck64bitd ? App?.preCheck64bitd :null 
        console.log("app prechecked",App?.Validation_selectType64bit)
        const filepath_download_64 = App?.filePath64bitd ? App?.filePath64bitd : ""
        const Registry_rootkey_download_64 = App?.RegrootKey64bitd ? App?.RegrootKey64bitd : null 
        const Registry_path_download_64 = App?.Registrypath64bitd ? App?.Registrypath64bitd : null
        const Registry_type_download_64 = App?.Regtype64bitd ? App?.Regtype64bitd : null 
        const Registry_value_download_64 = App?.Registryvalue64bitd ? App?.Registryvalue64bitd : null

        const softwareName_download_64 = App?.selectType_softName64bitd ? App?.selectType_softName64bitd : null 
        const softwareVersion_download_64 = App?.selectType_softVersion64bitd ? App?.selectType_softVersion64bitd : null 
        // 64 bit configuration Download ENDS

        // 64 bit configurations install starts
        const executeIn_install_64 = App?.Executein64bitI ? App?.Executein64bitI : "" 
        const installationMode_install_64 = App?.Installationmode64bit ? App?.Installationmode64bit : null 
        const commandLineInstallationInstruction_install_64  = App?.CmdInstr64bit ? App?.CmdInstr64bit : ""
        const preCheckMode_install_64 = App.selectType64bitI ? App.selectType64bitI : null //file, registry, software
        const preCheckValue_install_64 = App.preCheck64bitI ? App.preCheck64bitI : null 
        const filepath_install_64 = App.filePath64bitI ? App.filePath64bitI : null
        const Registry_rootkey_install_64 = App.RegrootKey64bitI ? App.RegrootKey64bitI : null
        
        const Registry_path_install_64 = App.Registrypath64bitI ? App.Registrypath64bitI : null 
        const Registry_type_install_64 = App.Regtype64bitI ? App.Regtype64bitI : null
        const Registry_value_install_64 = App.Registryvalue64bitI ?  App.Registryvalue64bitI : null
        const softwareName_install_64 = App.selectType_softName64bitI ? App.selectType_softName64bitI : null 
        const softwareVersion_install_64 = App.selectType_softVersion64bitI ? App.selectType_softVersion64bitI : null
        const maxpatchtime =App.Max_time64bit?App.Max_time64bit:"";
        const processtokill =App.Terminate64bit?App.Terminate64bit:"";
        let messagestatus ="";
        messagestatus = App?.MessageBox != "" ? "1" : "0";       // 64 bit configuration install ends
        //Generic parameters for each application
        //messages
        const messageDuringDownload = App.Message_Box ? App.Message_Box: null
        if(messageDuringDownload != null){
        messageBoxArray.push(messageDuringDownload)
        }
        const messageDuringInstall = App.Message_Box ? App.Message_Box: null
        messageBoxArray.push(messageDuringInstall)
        const configuration_download = (
            platform:any,
            path:any,
            executeIn_download:any,
            downloadOperationMode_download:any,
            downloadLocation_bit:any,
            preCheckMode_download: String ,
            preCheckValue_download: any ,
            filepath_download: any ,
            Registry_rootkey_download: any ,
            Registry_path_download: any,
            Registry_type_download: any,
            Registry_value_download: any,
            softwareName_download: any,
            softwareVersion_download: any,
          ) => {
          //   START OF CONFIGURATION_32/64 BIT DOWNLOAD
                  let confStrDownload = "1,"; //enabled mode
                  // 1 appending platform
                  if (platform == 1){
                    confStrDownload+= "NT,";    //configuration appending for OS
                  }
                  // 2. configuration append URL/path
                  let pathDownload = path + ','
                  confStrDownload+=pathDownload
        
                  // 3 .configuration appending for session mode
                  if(executeIn_download == 0){
                      confStrDownload+='0,'
                  }
                  else if(executeIn_download == 1){
                      confStrDownload+="1,"
                  }
                  
                  if(downloadOperationMode_download == 1){
                    confStrDownload+="1,"
                  }
                  if(downloadOperationMode_download ==  4){
                    confStrDownload+="4,"
                  }
                  if(downloadOperationMode_download === 6){
                    confStrDownload+="6,"
                  }
                  
                  confStrDownload+="4,"
      
                  //6.download location for download the package
                  if(downloadLocation_bit){
                      const  downloadLocation = downloadLocation_bit + ","
                      confStrDownload+=downloadLocation 
                  }
                  //7.pre check mode 0-file, 1-software, 2-Registry
                  if(preCheckMode_download === 'File'){
                    if(preCheckValue_download == 0){ //check condition for proceed
                      confStrDownload+="0," + `${filepath_download}`
                    }
                    else{
                      confStrDownload+="!0," + `${filepath_download}`
                    }
                  }
      
                  
                  if(preCheckMode_download === 'Registry'){
                    let Registry_rootkey_downloadValue:string = ''
                    if(Registry_rootkey_download == "3"){
                       Registry_rootkey_downloadValue = '3'
                    }
                    if(Registry_rootkey_download == "4"){
                       Registry_rootkey_downloadValue = '4'
                    }
                    if(Registry_rootkey_download == "1"){
                       Registry_rootkey_downloadValue = '1' 
                    }
                    if(Registry_rootkey_download == "5"){
                       Registry_rootkey_downloadValue = '5'
      
                    }
                    if(Registry_rootkey_download == "7"){
                       Registry_rootkey_downloadValue = '7'
      
                    }
                    if(Registry_rootkey_download == "8"){
                       Registry_rootkey_downloadValue = '8'
                    }
                    if(Registry_rootkey_download == "9"){
                       Registry_rootkey_downloadValue = '9'
                    }
                    if(Registry_rootkey_download == "2"){
                       Registry_rootkey_downloadValue = '2'
                    }
                    if(Registry_rootkey_download == "6"){
                       Registry_rootkey_downloadValue = '6'
                    }
                    if(preCheckValue_download == 0){
                      // ,2,MainKey#SubKey#ValueName#ValueType#Value
                      confStrDownload+="2," +  `${Registry_rootkey_downloadValue}` + `#` + `${Registry_path_download}` +  `#` +`${Registry_type_download}` + `#` +`${Registry_value_download}`
                    }else{
                      confStrDownload+="!2," +  `${Registry_rootkey_downloadValue}` + `#` + `${Registry_path_download}` +  `#` +`${Registry_type_download}` + `#` + `${Registry_value_download}`
                    }
                  }
                  if(preCheckMode_download === 'Software Name'){
                      confStrDownload+="1," + `${softwareName_download}` + `#` + `${softwareVersion_download}`
                  }
                  confStrDownload = confStrDownload.replace(/,\s*$/, ""); //remove commas at the end of configuriation
      
                  return confStrDownload;
          //   END OF CONFIGURATION_BIT DOWNLOAD
        }
      
        // START OF CONFIGURATION FOR 32/64BIT INSTALL
          const configuration_install = (
            platform: any ,
            path: any ,
            executeIn_install: any ,
            installationMode_install:any ,
            downloadLocation_download:any,
            commandLineInstallationInstruction_install:any ,
            preCheckMode_install :any ,
            preCheckValue_install:any,
            filepath_install :any,
            Registry_rootkey_install: any ,
            Registry_path_install: any ,
            Registry_type_install: any ,
            Registry_value_install: any ,
            softwareName_install: any,
            softwareVersion_install: any,
          ) => {
                let confStrInstall = "1,"; //enabled mode
                  // 1 appending platform
                  if (platform == "1"){
                  confStrInstall+= "NT,";    //configuration appending for OS
                }
      
                // 2. configuration append URL/path
                let pathInstall_32 = '%WINDIR%\\System32\\cmd.exe' + ',' // this will be default value for installation
                confStrInstall+=pathInstall_32
                
                // 3 .configuration appending for session mode
                if(executeIn_install == 0){
                    confStrInstall+='0,'
                }
                else if(executeIn_install == 1){
                    confStrInstall+="1,"
                }

                if(installationMode_install == 1){
                  confStrInstall+="1,"
                }
                if(installationMode_install == 4){
                  confStrInstall+="4,"
                }
                if(installationMode_install == 6){
                  confStrInstall+="6,"
                }
      
                //5. Append Command since its a install add 1 value to confany meaning the package for distribution
                confStrInstall+="1,"
      
                //6 command line installation instruction , append values from Url path end and downloadLocation_32download to create configuration for this 
                const urlParts = path.split('/');
                // Get the last element of the array
                const lastElement = urlParts[urlParts.length - 1];
                
                const finalCommandLineInstallationInstruction = downloadLocation_download.length > 0 ? `/k("${downloadLocation_download}${lastElement}" ${commandLineInstallationInstruction_install} &exit),` :  `/k(&exit),`;
                confStrInstall+=finalCommandLineInstallationInstruction
      
                //7.pre check mode 0-file, 1-software, 2-Registry
                if(preCheckMode_install == 'File'){
                  if(preCheckValue_install == '0'){ //check condition for proceed
                    confStrInstall+="0," + `${filepath_install}`
                  }
                  else{
                    confStrInstall+="!0," + `${filepath_install}`
                  }
                }
                if(preCheckMode_install == 'Registry'){
                  let Registry_rootkey_installValue:string = ''
                  if(Registry_rootkey_install == "3"){
                     Registry_rootkey_installValue = '3'
                  }
                  if(Registry_rootkey_install == "4"){
                     Registry_rootkey_installValue = '4'
                  }
                  if(Registry_rootkey_install == "5"){
                     Registry_rootkey_installValue = '1' 
                  }
                  if(Registry_rootkey_install == "6"){
                     Registry_rootkey_installValue = '5'
      
                  }
                  if(Registry_rootkey_install == "7"){
                     Registry_rootkey_installValue = '7'
      
                  }
                  if(Registry_rootkey_install == "8"){
                     Registry_rootkey_installValue = '8'
                  }
                  if(Registry_rootkey_install == "9"){
                     Registry_rootkey_installValue = '9'
                  }
                  if(Registry_rootkey_install == "2"){
                     Registry_rootkey_installValue = '2'
                  }
                  if(Registry_rootkey_install == "6"){
                     Registry_rootkey_installValue = '6'
                  }
                  if(preCheckValue_install == '0'){
                    // ,2,MainKey#SubKey#ValueName#ValueType#Value
                    confStrInstall+="2," + `${Registry_rootkey_installValue}` + `#` + `${Registry_path_install}` + `#` + `${Registry_type_install}` + `#` + `${Registry_value_install}`
                  }else{
                    confStrInstall+="!2," +  `${Registry_rootkey_installValue}` + `#` + `${Registry_path_install}` +  `#` +`${Registry_type_install}` + `#` + `${Registry_value_install}`
                  }
                }
                if(preCheckMode_install === 'Software Name'){
                    confStrInstall+="1," + `${softwareName_install}` + `#` + `${softwareVersion_install}`
                }
                confStrInstall = confStrInstall.replace(/,\s*$/, "");
      
                return confStrInstall
            }

        const confStrDownload_32:any = configuration_download(
            platform,
            path,
            executeIn_download_32,
            downloadOperationMode_download_32,
            downloadLocation_bit_32,
            preCheckMode_download_32,
            preCheckValue_download_32,
            filepath_download_32,
            Registry_rootkey_download_32,
            Registry_path_download_32,
            Registry_type_download_32,
            Registry_value_download_32,
            softwareName_download_32,
            softwareVersion_download_32
          )

          const confStrInstall_32:any = configuration_install(
            platform,
            path,
            executeIn_install_32,
            installationMode_install_32,
            downloadLocation_bit_32,
            commandLineInstallationInstruction_install_32,
            preCheckMode_install_32,
            preCheckValue_install_32,
            filepath_install_32,
            Registry_rootkey_install_32,
            Registry_path_install_32,
            Registry_type_install_32,
            Registry_value_install_32,
            softwareName_install_32,
            softwareVersion_install_32
  
          )

          const confStrDownload_64:any = configuration_download(
            platform,
            path,
            executeIn_download_64,
            downloadOperationMode_download_64,
            downloadLocation_bit_64,
            preCheckMode_download_64,
            preCheckValue_download_64,
            filepath_download_64,
            Registry_rootkey_download_64,
            Registry_path_download_64,
            Registry_type_download_64,
            Registry_value_download_64,
            softwareName_download_64,
            softwareVersion_download_64
            )

          const confStrInstall_64:any = configuration_install( 
            platform,
            path,
            executeIn_install_64,
            installationMode_install_64,
            downloadLocation_bit_64,
            commandLineInstallationInstruction_install_64,
            preCheckMode_install_64,
            preCheckValue_install_64,
            filepath_install_64,
            Registry_rootkey_install_64 ,
            Registry_path_install_64,  
            Registry_type_install_64,
            Registry_value_install_64,
            softwareName_install_64 , 
            softwareVersion_install_64
          )
          const packagePromiseArray = []
          packagePromiseArray.push(confStrDownload_32)
          packagePromiseArray.push(confStrInstall_32)
          packagePromiseArray.push(confStrDownload_64)
          packagePromiseArray.push(confStrInstall_64)
          
          if(indexValue == 0){
            config32bit =  config32bit  + ((download_installCheck32 === '1') ? `${confStrDownload_32}` : `${confStrInstall_32}`)
            config64bit  =  config64bit  + ((download_installCheck64 === '1') ? `${confStrDownload_64}`:`${confStrInstall_64}`)
          }
          else{
            config32bit =  config32bit + "\n" + ((download_installCheck32 === '1') ? `${confStrDownload_32}` : `${confStrInstall_32}`)
            config64bit  =  config64bit + "\n" + ((download_installCheck64 === '1') ? `${confStrDownload_64}`:`${confStrInstall_64}`) 
          }
      const  config32bitFinal = config32bit
      const  config64bitFinal = config64bit 
      // genrating final messagebox configuration
      let finalMessageBoxText = ''
      if(messageBoxArray != null){
      messageBoxArray.map((msg,msgindex) => {
            msgindex == 0 ? finalMessageBoxText+=`[${msgindex+1}]-${msg?? ''}` : finalMessageBoxText+=`#[${msgindex+1}]-${msg?? ''}` 
      })}
      //generating final processToterminate time
      // generating edconfig here
      edConfig = `1~[${packageName}]` +"\n"+ "32Link:" +"\n" + config32bitFinal +"\n" + "64Link:" + "\n" + config64bitFinal + "\n" + "Positive:" + "\n" + "Negative:" + "\n" + 
      "Special:" + "\n" + "LogFile:" + "\n" + "1,0," + `${pathLogFile}` + "\n" + "Default:" +"\n"+ "DeleteLogFile:" +"\n"+ `StatusMessageBox:${messagestatus}` +"\n"+ `MessageBoxText:${finalMessageBoxText}` +"\n"+ `MaxTimePerPatch:${maxpatchtime}` +"\n"+ 
      `ProcessToKill:${processtokill}` 
      
        }   
          
    )
    console.log("edconfiggg :-",edConfig)
    return edConfig;
    }
    const getDownloadableLink = (filepath:string) => {
        return `${process.env.REACT_APP_STORAGE_URL}`
    }

    const removeValueInServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var serviceLogMasterVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`]?.componentValue?.length > 0){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');

                var newVal = serviceLogMasterArr.filter((val: any) => !(val.includes('#NXT#286#NXT#') && val.includes(`#NXT#[${varValue}]#NXT#`))).join('#NXT#Tile');

                // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

                await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));

                // await updateValueOfComponents({valueid: {_eq: serviceLogMasterData[`${name}`].id}}, newVal, (revised + 1));
            }
        }

        removeValueInBaseProfileComponent(mgroupuniq, name);
    }

    const removeValueInBaseProfileComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var baseProfileVal = '';
        console.log("remove baseProfile component entered");

        if(baseProfilesData[`${name}`].varuniq?.length > 0 && baseProfilesData[`${name}`]?.componentValue?.length > 0){ // to check if baseprofile is present for particluar site/group in varvalues table 
            var res = baseProfilesData[`${name}`].componentValue;
            var revised = baseProfilesData[`${name}`].revised;

            baseProfileVal = res?.trim();
            var baseProfileArr: any = baseProfileVal?.split('#F#NXT#1#NXT#0');

            var currentL3Val = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L3') && ele.includes(varValue)))[0];

            var l3pageArr = currentL3Val.split("#NXT#enable#")[0].split("#NXT#");
            var l3pageNo = l3pageArr[(l3pageArr.length - 1)];

            //to remove L3
            baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L3') && ele.includes(varValue)));

            var otherL3Val = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L3') && ele.includes(`${l3pageNo}#NXT#enable#`)));

            var subCatL1Val = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L1') && ele.includes(`${l3pageNo}#NXT#enable#`)));

            if(otherL3Val?.length === 0 && subCatL1Val?.length > 0){
                var subCatL1PageArr = subCatL1Val[0].split('#NXT#enable#NXT')[0].split("#NXT#");
                var subCatL1PageNo = subCatL1PageArr[(subCatL1PageArr.length - 1)];

                var subCatL2Val =  baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L2') && ele.includes(`#NXT#L2#NXT#${l3pageNo}#NXT#`)));

                if(subCatL2Val.length > 0){
                    console.log("f2");
                    var subCatL2PageArr = subCatL2Val[0].split('#NXT#enable#NXT')[0].split("#NXT#");
                    var subCatL2PageNo = subCatL2PageArr[(subCatL2PageArr.length - 1)];

                    if(subCatL2PageNo != 2){
                        //if subCatL2PageNo is other than 2, then it is a sub-category
                        console.log("f3");
                        baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L1') && ele.includes(`${l3pageNo}#NXT#enable#NXT`)));

                        baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L2') && ele.includes(`L2#NXT#${l3pageNo}#NXT#`)));

                        var otherSubCatVal = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L2') && ele.includes(`${subCatL2PageNo}#NXT#enable#NXT`)));

                        if(otherSubCatVal?.length === 0){
                            console.log("f4");
                            var catL2Val = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L2') && ele.includes(`L2#NXT#${subCatL2PageNo}#NXT#`) && ele.includes(`#NXT#2#NXT#enable#NXT`) ));

                            var otherL3UnderCategory = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L3') && ele.includes(`#NXT#${subCatL2PageNo}#NXT#enable#`) ));

                            console.log("otherL3UnderCategory=",otherL3UnderCategory,"catL2Val=",catL2Val);
                            
                            if(otherL3UnderCategory?.length === 0 && catL2Val?.length > 0){
                                console.log("f5");
                                baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L1') && ele.includes(`${subCatL2PageNo}#NXT#enable#NXT`)));

                                baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L2') && ele.includes(`L2#NXT#${subCatL2PageNo}#NXT#`) && ele.includes(`#NXT#2#NXT#enable#NXT`) ));
                            }
                        }
                    }else{
                        console.log("f6");
                        //if subCatL2PageNo is 2, then it is a category
                        var otherSubCatVal = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L2') && ele.includes(`${subCatL2PageNo}x#NXT#enable#NXT`)));

                        var otherL3UnderCategory = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => (ele.includes('L3') && ele.includes(`#NXT#${subCatL2PageNo}#NXT#enable#`) ));

                        if(otherL3UnderCategory?.length === 0 && otherSubCatVal?.length === 0){
                            console.log("f7");
                            baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L1') && ele.includes(`${l3pageNo}#NXT#enable#NXT`)));

                            baseProfileArr = baseProfileArr.filter((element: any) => element !== '').filter((ele: any) => !(ele.includes('L2') && ele.includes(`L2#NXT#${l3pageNo}#NXT#`) && ele.includes(`#NXT#2#NXT#enable#NXT`) ));
                        }
                    }
                }
            }

            // var pageArr = baseProfileArr.filter((val) => val.includes(category + '#NXT#L1'));
            // var categoryPage = pageArr[0]?.split('#NXT#')[13];

            //         pageArr = baseProfileArr.filter((val) => val.includes(subCategory + '#NXT#L1'));
            //         subCategoryPage = pageArr[0]?.split('#NXT#')[13];

            baseProfileArr = baseProfileArr.join("#F#NXT#1#NXT#0") + "#F#NXT#1#NXT#0";
            
            console.log("basep=",baseProfileArr);

            // await updateValueOfComponent(baseProfileArr, (revised + 1), baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq, baseProfilesData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: baseProfilesData[`${name}`].varuniq}, varscopuniq: {_eq: baseProfilesData[`${name}`].varscopuniq}}, baseProfileArr, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: baseProfilesData[`${name}`].id}}, baseProfileArr, (revised + 1));
        }
        removeValueInProfileSequenceComponent(mgroupuniq, name);
    }

    const removeValueInProfileSequenceComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        
        var varValue = automation_name?.split(" ").join("");
        var profileSequence = '';
        var newVal: String = '';

        //structuring value for profilesequence component ends here
        if(profileSequenceData[`${name}`].varuniq?.length > 0 && profileSequenceData[`${name}`].componentValue?.length > 0){
            var res = profileSequenceData[`${name}`].componentValue;
            var revised = profileSequenceData[`${name}`].revised;

            profileSequence = res?.trim();
            if(profileSequence?.length > 0){
                var tempArr: any = profileSequence.split("\r\n").filter((val: any) => val.includes(`${varValue}:`))[0].split(":");
                automationData.sequenceIDs = tempArr[tempArr.length - 1].split(",");

                newVal = profileSequence.split("\r\n").filter((val: any) => !(val.includes(`${varValue}:`))).join("\r\n");
            }

            // await updateValueOfComponent(newVal, (revised + 1), profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq, profileSequenceData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSequenceData[`${name}`].varuniq}, varscopuniq: {_eq: profileSequenceData[`${name}`].varscopuniq}}, newVal, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: profileSequenceData[`${name}`].id}}, newVal, (revised + 1));
        }
        //structuring value for profilesequence component ends here
        removeValueInSequenceDetailsComponent(mgroupuniq, name);
    }

    var varIds: any = [];
    const removeValueInSequenceDetailsComponent = async (mgroupuniq: any, name: String) => {
        var automationSequence = automationData.automationSequence;
        var automationSequenceArr = automationSequence.split(","); 

        if(sequenceDetailsData[`${name}`].varuniq?.length > 0 && sequenceDetailsData[`${name}`].componentValue?.length > 0){ 
            await getAutomationList({id: {_in: automationSequence?.split(',').map(Number)}}, null, null, 'sequenceConfig'); 

            var res = sequenceDetailsData[`${name}`].componentValue;
            var revised = sequenceDetailsData[`${name}`].revised;
            var SequenceDetails = '';
            var sequenceDetailsArr = res?.split('\n');
            var tempVal: any = [];

            console.log("sub automation=", subAutomation);
            

            subAutomation.map((val: any, i: any) => {
                tempVal = sequenceDetailsArr.filter((data: any) => data.includes(`${automationData.sequenceIDs[i]},${val.dartId},${val.automationName}`))[0];
                
                tempVal = tempVal?.split(",");
                tempVal[tempVal?.length - 1]?.split(':').map((val: any) => {
                    varIds.push(`VID:${val}`);
                })

                sequenceDetailsArr = sequenceDetailsArr.filter((data: any) => !data.includes(`${automationData.sequenceIDs[i]},${val.dartId},${val.automationName}`));
            });

            SequenceDetails = sequenceDetailsArr.join("\n");
            
            // await updateValueOfComponent(SequenceDetails, (revised + 1), sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq, sequenceDetailsData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: sequenceDetailsData[`${name}`].varuniq}, varscopuniq: {_eq: sequenceDetailsData[`${name}`].varscopuniq}}, SequenceDetails, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: sequenceDetailsData[`${name}`].id}}, SequenceDetails, (revised + 1));
        }

        removeValueInVariableConfigComponent(mgroupuniq, name);
    }

    const removeValueInVariableConfigComponent = async (mgroupuniq: any, name: String) => {
        var dartId = automationData.dartNo;
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var status = false;

        if(variableConfigData[`${name}`].componentValue?.length > 0){ 
            var res = variableConfigData[`${name}`].componentValue;
            var revised = variableConfigData[`${name}`].revised;
            var newVal: any = '';
            var variableConfigArr: any = res?.split('\n#NXTVAR#');

            console.log("variableConfigArr=",variableConfigArr);
            console.log("varIds=",varIds);
            
            varIds.map((str: any) => str.replace(/\r/g, '')).map((ids: any) => {
                variableConfigArr = variableConfigArr.filter((val: any) => !val.includes(`${ids}\r`)).filter((val: any) => !val.includes(`${ids}\n`));
            })
            newVal = variableConfigArr.join("\n#NXTVAR#");

            console.log("newVal of ValueInVariableConfigComponent=",newVal);
            

            // status = await updateValueOfComponent(newVal.trim(), (revised + 1), variableConfigData[`${name}`].varnameuniq, variableConfigData[`${name}`].varscopuniq, variableConfigData[`${name}`].varuniq, mgroupuniq, variableConfigData[`${name}`].id);

            status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: variableConfigData[`${name}`].varuniq}, varscopuniq: {_eq: variableConfigData[`${name}`].varscopuniq}}, newVal.trim(), (revised + 1));

            // status = await updateValueOfComponents({valueid: {_eq: variableConfigData[`${name}`].id}}, newVal.trim(), (revised + 1));
        } 

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;

            if(selctedValue == 'tenant'){
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `not attached`;
                    }
                }else{
                    selctedValue = automationStatus;
                }
            }else{
                var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedDetachmentGroups.includes(val)));
                
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1].split(",");

                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        curSelectedGRp.length > 0 && statusArr.push(`${tenantName}:${curSelectedGRp.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp: any = automationStatus.split(":")[1].split(",");
                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        if(curSelectedGRp.length == 0){
                            selctedValue = 'not attached';
                        }else{
                            selctedValue = `${tenantName}:${curSelectedGRp.join(",")}`;
                        }
                    }
                }else{
                    selctedValue = automationStatus; 
                }
            }
            // console.log("selctedValue=",selctedValue);

            let flag = props.groupAttachment?.length ? props.groupAttachment?.length : 0;

            var ids = subAutomation.map((val : any) => val.id);
            ids.push(automationData.id);
            
           await updateAutomationStauts({id: {_in: ids}}, selctedValue, flag, 'detach');
            
            // if(props.groupAttachment.length > 0){
            //     automationData.status = selctedValue;
            //     setTimeout(() => {
            //         attachRemediation();
            //     }, 1000);
            // }
        }
    }

    const updateAutomationStauts = async (whereCondition: any, status: any, flag: any, type: String) => { 
        const { data } = await client.mutate({
            mutation: updateAutomationStautsQuery,
            variables: { whereCondition, status },
            fetchPolicy: 'network-only',
        });

        if(data.update_core_AutomationJSON.affected_rows > 0){
            if(flag == 0){
                if(type == 'attach'){
                    props.setToastMessage(`Automation attached to ${props.groupAttachment[0]} ${props.groupAttachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);
                    
                    props.setAllAttachmentGrp((prev: any) => [...prev.slice(1)]);
                }else if(type == 'detach'){
                    props.setToastMessage(`Automation detached from ${props.groupDetachment[0]} ${props.groupDetachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);

                    props.setAllDetachmentGrp((prev: any) => [...prev.slice(1)]);
                } 

                props.setmessagebar1(false);
                props.setmessagebar2(true);

                // setTimeout(() => {
                //     navigate("/manage/devices/remediation");
                // }, 5000);
            }
        }
    }

    const insertValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: insertValueOfComponentQuery,
          variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
    
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any, valueid: any) => {
        console.log("valueid=",valueid);
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
            mutation: insertValueOfComponentQuery,
            variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
        
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponents = async (whereCondition: any, valu: String, revl: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: updateValueOfComponentQuery,
          variables: {whereCondition, valu, revl, lastchange}
        });
    
        if(data.update_core_VarValues.affected_rows > 0){
            console.log("component value updated");
            return true;
        }else{
            return false;
        }
    };

    const attachRemediation = async () => {
        var selected = contextValue.selectedRowIdInTable;
        var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

        console.log("selectedGroups=",selectedGroups);
        

        if(selected.includes('All Devices')){
            selctedValue = 'tenant';
            await getGroupIdOfTenant({name: {_eq: tenantName}}, 'attach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification'; 
            await getGroupIdOfTenant({name: {_in: selectedGroups}}, 'attach');
        }
    };

    const detachRemediation = () => {
        var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');

        if(props.groupDetachment.includes('All Devices')){
            selctedValue = 'tenant';
            getGroupIdOfTenant({name: {_eq: tenantName}}, 'detach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification';
            getGroupIdOfTenant({name: {_in: selectedGroups}}, 'detach');
        }
    };
  
    const modifyRemediation = async () => {
        await getAutomationList({dartid: {_eq: 304}, automationName: {_eq: automationName}});

        console.log("groupDetachment=",props.groupDetachment);
        console.log("groupAttachment=",props.groupAttachment);

        if(props.groupDetachment.length > 0){
            console.log("detachment called");
            
            detachRemediation();
        }else if(props.groupAttachment.length > 0){
            console.log("attachment called");

            attachRemediation();
        }
    }
  
    useEffect(() => { 
        console.log("reched funcccc");
          
        modifyRemediation();
    },[props.saveBtnClicked, props.groupAttachment, props.groupDetachment]);

    return (
        <></>
    )
}

export { Remediations_selfhelp_func }