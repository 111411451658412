import { useState, useEffect, useContext } from "react";
import { Remediations_func } from "../../../api/devices/remediations_func";
import { Remediations_selfhelp_func } from "../../../api/devices/remediations_selfhelp_func";
import { Remediations_autoheal_func } from "../../../api/devices/remediations_autoheal_func";
import { Remediations_scheduler_func } from "../../../api/devices/remediations_scheduler_func";
import { useTracking } from "react-tracking";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from "react-csv";
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Card, ProgressBar, ButtonProps } from "@fluentui/react-components";
import { BookTemplate20Regular, LinkSquare20Regular, Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Save20Regular, Info20Filled, ArrowMaximize20Regular, DismissSquare24Filled, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { ToastComponent } from "../../../components/toast/toast.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import DialogBox from "../../../components/dialog/dialog.component";
import { GlobalContext } from "../../../hooks/globalContext";
//import PopOver from "../../../components/popover/popover.component";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, exitIcon, secondaryIcon, overflowDivider } from "../../../styles/customStyles";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById("csv-export-link");
  if (csvExportLink) { csvExportLink.click(); }
};
 
export const Remediations = () => {
  const { trackEvent } = useTracking();
  const contextValue: any = useContext(GlobalContext);
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [errorToast, setErrorToast] = useState(false);
  const [messagebar1, setmessagebar1] = useState(false);
  const [messagebar2, setmessagebar2] = useState(false);
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [isRefresh, setRefresh]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [rowID, setRowID]: any = useState(null);
  const [deviceCnt, setDeviceCnt]: any = useState(0); 
  const [groupDeviceCnt, setGroupDeviceCnt]: any = useState({}); 
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [defaultSelectedRows, setDefaultSelectedRows]: any = useState([]);
  const [previousSelectedGroups, setPreviousSelectedGroups]: any = useState([]);
  const [attachmentGrp, setAttachmentGrp]: any = useState([]);
  const [detachmentGrp, setDetachmentGrp]: any = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [isProgressLoading, setProgressLoading] = useState(false);

  var automationType = contextValue.automationDetails.automationType;

  var attachmentArr: any = [];
  var detachmentArr: any = [];

  const [allAttachmentGrp, setAllAttachmentGrp] = useState(['']);
  const [allDetachmentGrp, setAllDetachmentGrp] = useState(['']);
  
  const handleBothSetMessage = () => {
    if(!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) {
      if(previousSelectedGroups.length == 0) {
        detachmentArr = [];
        // setDetachmentGrp(detachmentArr);
        setAllDetachmentGrp(detachmentArr);
      }else {
        detachmentArr = contextValue.selectedRowIdInTable.filter((val: any) => val != 'All Devices')
        // setDetachmentGrp(detachmentArr);
        setAllDetachmentGrp(detachmentArr);
      }
      attachmentArr = ['All Devices'];
      // setAttachmentGrp(attachmentArr);
      setAllAttachmentGrp(attachmentArr);
    }else if (previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) {
      detachmentArr = ['All Devices'];
      // setDetachmentGrp(detachmentArr);
      setAllDetachmentGrp(detachmentArr);

      attachmentArr = contextValue.selectedRowIdInTable.filter((val: any) => !previousSelectedGroups.includes(val));
      // setAttachmentGrp(attachmentArr);
      setAllAttachmentGrp(attachmentArr);

    }else {
      detachmentArr = previousSelectedGroups.filter((val: any) => !contextValue.selectedRowIdInTable.includes(val));
      // setDetachmentGrp(detachmentArr);
      setAllDetachmentGrp(detachmentArr);

      attachmentArr = contextValue.selectedRowIdInTable.filter((val: any) => !previousSelectedGroups.includes(val));
      // setAttachmentGrp(attachmentArr);
      setAllAttachmentGrp(attachmentArr);
    }
    console.log("previousSelectedGroups=",previousSelectedGroups," checked=",contextValue.selectedRowIdInTable," attached=",attachmentArr," unattached=",detachmentArr);
    
    // setSaveBtnClicked(true);
  };

  useEffect(() => {
    console.log("tempGrp=",allAttachmentGrp);

    var detachmentVal = allDetachmentGrp.length > 0 ? allDetachmentGrp[0] : 0;
    var attachmentVal = allAttachmentGrp.length > 0 ? allAttachmentGrp[0] : 0;    
    
    if(attachmentVal === 0 && detachmentVal === 0){
      setTimeout(() => {
        navigate("/manage/devices/remediation");
      }, 4000);
    }else if(allDetachmentGrp.length > 0 && allDetachmentGrp[0] !== ''){
      console.log("d1=",allDetachmentGrp);
      
      setDetachmentGrp([detachmentVal]);

      setSaveBtnClicked(true);
    }else if(allAttachmentGrp.length > 0 && allAttachmentGrp[0] !== ''){
      console.log("d2=",allAttachmentGrp);
      setDetachmentGrp([]);
      setAttachmentGrp([attachmentVal]);

      contextValue.selectedRowIdInTable.length > 0 && setSaveBtnClicked(true);
    }
  },[allAttachmentGrp, allDetachmentGrp]);
 
  const handleButtonClick = () => { setShowMessage(true); };
  const [isPopoverOpenEnable, setisPopoverOpenEnable] = useState(false);
  const HandleScreen = () =>{ console.log('pop over', isPopoverOpenEnable); setisPopoverOpenEnable(!isPopoverOpenEnable)};
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const truncateSelectedDevices = (selectedDevices: string[]) => {
    const MAX_DISPLAY = 3;
    if (selectedDevices.length > MAX_DISPLAY) {
      const truncatedList = selectedDevices.slice(0, MAX_DISPLAY);
      truncatedList.push(`${selectedDevices.length - MAX_DISPLAY}+ more`);
      return truncatedList;
    }
    return selectedDevices;
  };

  var menuItems: any = [];

  menuItems.push
  (
      <FluentProvider theme={lightTheme}>
        <MenuItem
        icon={
          <Stack horizontal tokens={{childrenGap:40}}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <LinkSquare20Regular {...primaryIcon} /> </StackItem>
          </Stack>}>
          <Stack tokens={{childrenGap:30}} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1> Assignment </Caption1> </StackItem>
            <StackItem> <Stack tokens={{childrenGap:80}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
            {/* SPACING IN INLINE SIDEBAR */}
          </Stack>
        </MenuItem>
      </FluentProvider>
  );
 
  menuItems.push
  (
      <MenuItem
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <BookTemplate20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Audit Log </Caption1> </StackItem>
        </Stack>
      </MenuItem>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "group_name", label: <Body1Strong> Group Name </Body1Strong> },
    { key: "device_cnt", label: <Body1Strong> Count of Devices </Body1Strong>},
  ];
  //data table column name code ends here
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <DialogBox
            headerButton={
              <Button appearance="transparent" size="small"
                disabled={isSaveEnabled ? false : true} >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> { isSaveEnabled ? ( <Save20Regular {...primaryIcon}/> ): ( <Save20Regular {...secondaryIcon}/> )} </StackItem>
                  <StackItem> <Caption1> Save </Caption1> </StackItem>
                </Stack>
              </Button>
            }

            // console.log("previousSelectedGroups=",previousSelectedGroups," checked=",contextValue.selectedRowIdInTable," attached=",attachmentArr," unattached=",detachmentArr);
          
            // content= {(!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) ?  "Please confirm your decision to proceed with this change. The policy will be assigned to 'All devices'." : (previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.length == 0) ?  "Please confirm your decision to proceed with this change. The policy will be removed from 'All devices'." : `This change will be pushed to all connected devices that belong to the following groups:\r\n${contextValue.selectedRowIdInTable.join(",")}`}
            
            content= {
              <>
                <Stack tokens={{childrenGap:5}}>
                  <StackItem> 
                    {
                      (!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) 
                        ? "Are you sure you want to enable the automation for all devices?" 
                        : 
                        (previousSelectedGroups.length > 0 && contextValue.selectedRowIdInTable.length === 0) ? `Are you sure you want to detach the automation?`
                        : `Are you sure you want to enable the automation for the selected device classifications
                        :\r\n${truncateSelectedDevices(contextValue.selectedRowIdInTable).join(', ')}`
                    }
                  </StackItem>
                </Stack>
                
              </>
          }
          
            footer={
              <>
                <Stack horizontal tokens={{childrenGap:10}}>
                  <StackItem> <Button appearance="primary" onClick={handleBothSetMessage} size="small"> Confirm </Button> </StackItem>
                  <StackItem> <Button size="small" onClick={() => { setmessagebar1(false); }}> Cancel </Button> </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>
 
        <StackItem><Divider vertical/></StackItem>
       
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setRefresh(true); trackEvent({btn: 'refresh'});}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="remediations_assignmentlist.csv">
            <Button appearance="subtle" size="small" onClick={() => {userActivity('Export', `{"summary":"Configured groups list exported"}`); }}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
        
        <StackItem>
          <PopOver
            headerButton=
            {
              <>
                <Button appearance="transparent" size="small" onClickCapture={() => setCurrentPageNo(1)} onClick={HandleScreen}  
                  disabled={ isSaveEnabled ? false :true}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem> <Save20Regular {...primaryIcon} /> </StackItem>
                  <StackItem> <Caption1> Save </Caption1> </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content={ <> Please confirm your decision to proceed with this change. The policy will be assigned to ‘All devices’. </> }
            footer={
              <>
              <Stack horizontal tokens={{childrenGap:10}}>
                  <StackItem><Button appearance="primary" onClick={handleBothSetMessage} onClickCapture={HandleScreen} size="small">Confirm</Button> </StackItem>
                  <StackItem><Button size="small" onClick={() => { setmessagebar1(false); }} onClickCapture={HandleScreen}>Cancel</Button></StackItem>
                </Stack>
              </>
            }
          />
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];
  var tempExportData: any = [];

  useEffect(() => {    
    var tempExportData: any = [];

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        group_name: val.classificationName,
        device_cnt: groupDeviceCnt[`${val.classificationName}`]
      });
    });

    setExportData(tempExportData);
  }, [exportDetails]);
 
  useEffect(() => {
    setmessagebar2(false);
    var defaultSelectedIds: any = [];

    deviceCnt > 0 && tempTableData.push({
      id: 'All Devices',
      group_name: { label: <Caption1> All Devices </Caption1>,  icon: "", id: 1, },
      device_cnt: { label: <Caption1> {deviceCnt} </Caption1>, icon: "", status: "", },
    });

    previousSelectedGroups.includes('All Devices') && defaultSelectedIds.push(0);
    console.log("tempTableData=",tempTableData);

    final_data !== undefined && final_data !== null
      ? final_data.core_DeviceClassification.map((val: any, i: any) => { 
          previousSelectedGroups.includes(val.classificationName) && defaultSelectedIds.push(i+1);
          console.log("defaultSelectedIds=",previousSelectedGroups);

          tempTableData.push({
            id: val.classificationName,
            group_name: { label: <Caption1> {val.classificationName} </Caption1>,  icon: "", id: `${i}`},
            device_cnt: { label: <Caption1> {groupDeviceCnt[`${val.classificationName}`]} </Caption1>, icon: "", status: "" }
          });
      }) : (tempTableData = []);

    setDefaultSelectedRows(defaultSelectedIds);
    
    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data, groupDeviceCnt, previousSelectedGroups]);
  // data table values append code ends here

  const searchHandle = (data: any) => {
    var searchData = exportDetails.filter((val: any) => val.classificationName.includes(data));

    var searchedDataObj: any = {core_DeviceClassification: []};
    searchedDataObj.core_DeviceClassification = searchData;

    set_final_data(searchedDataObj);
    setSearchVal(data);
    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil((searchData.length)/50));
    setCurrentPageNo(1);
  };

  useEffect(() => { 
    contextValue.setSelectedRowIdInTable([]); 
  },[]);

  useEffect(() => { 
    contextValue.setSelectedRowIdInTable(previousSelectedGroups); 
  }, [previousSelectedGroups]);

  useEffect(() => {
    var newCheckedVal = contextValue.selectedRowIdInTable.filter((val: any) => !previousSelectedGroups.includes(val));
    
    console.log("checked=",contextValue.selectedRowIdInTable, "previousSelectedGroups=",previousSelectedGroups, "newCheckedVal",newCheckedVal);
    
    (newCheckedVal?.length > 0 || (previousSelectedGroups?.length > 0 && contextValue.selectedRowIdInTable?.length >= 0 && JSON.stringify(previousSelectedGroups) !== JSON.stringify(contextValue.selectedRowIdInTable))) ? setSaveEnabled(true) : setSaveEnabled(false);
  },[contextValue.selectedRowIdInTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({pageName: 'Remediation assignment', moduleName: "Devices", action: action, time: Math.floor(Date.now() / 1000), activity: activity, status: status});
    
    setTracker(true);
  }

  useEffect(() => {
    var detachmentExportArr = [];
    var attachmentExportArr = [];

    if(!previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) {
      if (previousSelectedGroups.length == 0) {
        detachmentExportArr = [];
      }
      else {
        detachmentExportArr = contextValue.selectedRowIdInTable.filter((val: any) => val != 'All Devices')
      }
      attachmentExportArr = ['All Devices'];
    }
    else if (previousSelectedGroups.includes('All Devices') && contextValue.selectedRowIdInTable.includes('All Devices')) {
      detachmentExportArr = ['All Devices'];

      attachmentExportArr = contextValue.selectedRowIdInTable.filter((val: any) => !previousSelectedGroups.includes(val));
    }
    else {
      detachmentExportArr = previousSelectedGroups.filter((val: any) => !contextValue.selectedRowIdInTable.includes(val));

      attachmentExportArr = contextValue.selectedRowIdInTable.filter((val: any) => !previousSelectedGroups.includes(val));
    }

    var summary = '';
    if(attachmentExportArr.length > 0 && detachmentExportArr.length > 0){
      summary = `${contextValue.automationDetails.automationName} remediation has attached to ${attachmentExportArr.length} configured ${attachmentExportArr.length > 1 ? 'groups' : 'group'} and detached from ${detachmentExportArr.length} configured ${detachmentExportArr.length > 1 ? 'groups' : 'group'}`;
    }else if(attachmentExportArr.length > 0 && detachmentExportArr.length < 1){
      summary = `${contextValue.automationDetails.automationName} remediation has attached to ${attachmentExportArr.length} configured ${attachmentExportArr.length > 1 ? 'groups' : 'group'}`;
    }else if(attachmentExportArr.length < 1 && detachmentExportArr.length > 0){
      summary = `${contextValue.automationDetails.automationName} remediation has detached from ${detachmentExportArr.length} configured ${detachmentExportArr.length > 1 ? 'groups' : 'group'}`;
    }

    userActivity('Add', `{"remediationName":"${contextValue.automationDetails.automationName}","attachedGroups":"${attachmentExportArr}","detachedGroups":"${detachmentExportArr}","summary":"${summary}"}`);

    saveBtnClicked && setProgressLoading(true);
  }, [saveBtnClicked]);

  useEffect(() => {
    var detachmentVal = allDetachmentGrp.length > 0 ? allDetachmentGrp[0] : 0;
    var attachmentVal = allAttachmentGrp.length > 0 ? allAttachmentGrp[0] : 0; 

    (messagebar2 || errorToast) && detachmentVal === 0 && attachmentVal === 0 && setProgressLoading(false);
  }, [messagebar2, errorToast]);
 
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  
  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="Remediations" subPageLink="/manage/devices/remediation" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button 
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{childrenGap:20}} horizontal>
          <StackItem>
            {/* pages code starts here */}
            <Stack verticalAlign="center">
              {/* <StackItem> <Text> <Breadcrumbs pageName="Devices" subPageName="Remediations" subPageLink="/manage/devices/remediation" /> </Text> </StackItem> */}
              <Stack tokens={{childrenGap:25}} horizontal>
                <StackItem><Text></Text></StackItem>
                <StackItem> 
                  <FluentProvider theme={webLightTheme}> 
                    <Title3> {contextValue.automationDetails.automationName} | Assignment </Title3> 
                  </FluentProvider> 
                </StackItem>
                {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular /> </Button> */}
              </Stack>
              <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <FluentProvider theme={webLightTheme}>
                <Stack horizontal>
                  {/* SPACING BETWEEN INLINE AND PAGE */}
                  <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                  <StackItem>
                    <StackItem><Text></Text></StackItem>
                    <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>

                    <Stack>{isProgressLoading && <ProgressBar/>}</Stack>

                    <Stack className={isProgressLoading ? 'disabled-opacity' : ''} verticalAlign="center" tokens={{childrenGap:10}}>
                      <StackItem>
                        <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                        { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                      </StackItem>
                      <StackItem>
                        <StackItem>
                          <Stack horizontal tokens={{childrenGap:20}}>
                          <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>
                            <StackItem>
                              <MenuDropdown
                                btn=
                                {
                                  <MenuButton shape="circular" size="small">
                                    <Stack tokens={{childrenGap:5}} horizontal>
                                      <Filter20Filled {...primaryIcon}/>
                                      <Body1> Filter </Body1>
                                    </Stack>
                                  </MenuButton>
                                }
                                itemVal=
                                {
                                  <>
                                    <Body1Strong> Add a Filter </Body1Strong>
                                    <Divider/>
                                    <MenuList>
                                      <Stack tokens={{childrenGap:5}}>
                                        <StackItem><Text></Text></StackItem>
                                        <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                        <DropdownComponent optionData={columns}/>
                                        <StackItem> <Divider /> </StackItem>
                                        <StackItem> <Caption1> Value </Caption1> </StackItem>
                                        <DropdownComponent optionData={columns}/>
                                        <StackItem> <Divider /> </StackItem>
                                        <Stack tokens={{childrenGap:10}} horizontal>
                                          <StackItem>
                                            <Button appearance="primary">
                                              <Caption1> Apply </Caption1>
                                            </Button>
                                          </StackItem>
                                          <StackItem>
                                            <Button>
                                              <Caption1> Cancel </Caption1>
                                            </Button>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    </MenuList>
                                  </>
                                }
                              />
                            </StackItem>
                            <StackItem>
                              <Button appearance="subtle" size="small">
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem> <FilterDismiss20Filled/> </StackItem>
                                  <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                </Stack>
                              </Button>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <StackItem><Text></Text></StackItem>
                          <StackItem><Text></Text></StackItem>
                        </Stack>
                        <Stack horizontal>
                          <StackItem>
                            <FluentProvider theme={messageTheme}>
                              <Card appearance="subtle" size="small">
                                <Stack tokens={{childrenGap:10}} horizontal>
                                  <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                                  <StackItem> <Caption1> If you choose the option 'All devices' The policy will be applied universally to all devices and will become detached from Devices groups. </Caption1>  </StackItem>
                                </Stack>
                              </Card>
                            </FluentProvider>
                          </StackItem>
                          <StackItem></StackItem>
                        </Stack>
                        <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                        {tableData.length > 0 ? 
                          <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                            <StackItem>
                              <Caption1> Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records </Caption1>
                            </StackItem>
                            <StackItem>
                              <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                            </StackItem>
                          </Stack> : 
                          <Stack horizontalAlign="center" tokens={{childrenGap: 15}}>
                            <StackItem><Text></Text></StackItem>
                            <StackItem> <Text size={500}> No data available </Text> </StackItem>
                          </Stack>
                        }
                      </StackItem>
                      
                      {!saveBtnClicked && 
                        <Remediations_func 
                          data={final_data} 
                          func={set_final_data} 
                          pageNo={(currentPageNo - 1) * 50} 
                          setTotalPages={setTotalPages} 
                          setTotalRecords={setTotalRecords} 
                          isRefresh={isRefresh} 
                          setRefresh={setRefresh} 
                          setRefreshToast={setRefreshToast} 
                          setDeviceCnt={setDeviceCnt} 
                          saveBtnClicked={saveBtnClicked} 
                          setSaveBtnClicked={setSaveBtnClicked} 
                          groupDeviceCnt={groupDeviceCnt} 
                          setGroupDeviceCnt={setGroupDeviceCnt} 
                          setPreviousSelectedGroups={setPreviousSelectedGroups} 
                          setExportDetails={setExportDetails}
                        /> 
                      }
                      
                      {saveBtnClicked && automationType == 'autoheal' && 
                        <Remediations_autoheal_func
                          saveBtnClicked={saveBtnClicked}
                          setSaveBtnClicked={setSaveBtnClicked}
                          setmessagebar1={setmessagebar1}
                          setmessagebar2={setmessagebar2}
                          setToastMessage={setToastMessage}
                          groupAttachment={attachmentGrp}
                          groupDetachment={detachmentGrp}
                          setErrorToast={setErrorToast}
                          setAllAttachmentGrp={setAllAttachmentGrp}
                          setAllDetachmentGrp={setAllDetachmentGrp}
                        />
                      }
                      
                      {saveBtnClicked && (automationType == 'selfhelp' || automationType == 'proactive') && 
                        <Remediations_selfhelp_func
                          saveBtnClicked={saveBtnClicked}
                          setSaveBtnClicked={setSaveBtnClicked}
                          setmessagebar1={setmessagebar1}
                          setmessagebar2={setmessagebar2}
                          setToastMessage={setToastMessage}
                          groupAttachment={attachmentGrp}
                          groupDetachment={detachmentGrp}
                          setErrorToast={setErrorToast}
                          setAllAttachmentGrp={setAllAttachmentGrp}
                          setAllDetachmentGrp={setAllDetachmentGrp}
                        />
                      }
                      
                      {saveBtnClicked && automationType == 'scheduler' && 
                        <Remediations_scheduler_func
                          saveBtnClicked={saveBtnClicked}
                          setSaveBtnClicked={setSaveBtnClicked}
                          setmessagebar1={setmessagebar1}
                          setmessagebar2={setmessagebar2}
                          setToastMessage={setToastMessage}
                          groupAttachment={attachmentGrp}
                          groupDetachment={detachmentGrp}
                          setErrorToast={setErrorToast}
                          setAllAttachmentGrp={setAllAttachmentGrp}
                          setAllDetachmentGrp={setAllDetachmentGrp}
                        />
                      }

                      {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} defaultSelected={defaultSelectedRows} />}

                      {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                    </Stack>
                  </StackItem>
                </Stack>
              </FluentProvider>
            </Stack>
            <StackItem>
              {
                messagebar1 && 
                <ToastComponent 
                  setmessagebar={setmessagebar1}  
                  toastIntent="warning" 
                  position="top" 
                  title="Please confirm your decision to proceed with change. the policy will be assigned to 'All devices'."
                  footer={
                    <>
                      <Button appearance="primary" onClick={handleBothSetMessage}> Confirm </Button>
                      <Button onClick={() => { setmessagebar1(false); }}> Cancel </Button>
                    </>
                  }
                />
              }
            </StackItem>
            <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census" />
          </StackItem>
          
          {messagebar2 && 
            <ToastComponent 
              setmessagebar={setmessagebar2}  
              toastIntent="success" 
              position="top-end" 
              title="Success" 
              message={toastMessage} 
              footer="" 
            />
          }
          
          {refreshToast && 
            <ToastComponent 
              setmessagebar={setIsToast} 
              isToastEnabled={refreshToast} 
              toastIntent="success" 
              position="top-end" 
              title="Success" 
              message={'Data Table Refreshed'} 
              footer="" 
            />
          }
          
          {errorToast && 
            <ToastComponent 
              setmessagebar={setErrorToast}  
              toastIntent="error" 
              position="top-end" 
              title="Failed" 
              message={toastMessage} 
              footer="" 
              setProgressLoading={setProgressLoading}
            />
          }
          <StackItem><Text></Text></StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};