import { gql } from '@apollo/client';

 
const getMachineGroupUniq = gql`
query MyQuery($selectedIds: [String!]) {
  core_MachineGroups(where: {name: {_in: $selectedIds}}) {
    mgroupuniq
    # mcatuniq
  }
}
`;

const updateVarvalue = gql`
mutation MyMutation($valu: String, $mgroupuniq: String, $formatedString: String) {
  update_core_VarValues(where: {varuniq: {_eq: "417e1c3ae58b6de7db16f97398a8b393"}, mgroupuniq: {_eq: $mgroupuniq}}, _set: {valu: $formatedString}) {
    affected_rows
  }
}
`




export {getMachineGroupUniq,updateVarvalue};

