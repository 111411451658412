import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { insertActivityLog, getActivityLogQuery } from "../../graphql/activity_log_queries";
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: any
    pageNo: any
    setTotalPages: any
    setTotalRecords: any
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    deleteDevices: any
    addDevices: any
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
}

const Activity_log_func = (props: any) => {
    console.log("Activity_log_func called ----------------");
    
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    var tenantName = contextValue.tenantName;
    var userId = contextValue.userSessionDetails.userId.toString();

    const insertActivityLogFunc = async () => {
        // var logs: any = localStorage.getItem('activityLogData');
        var logs = props.data;
        var tenant = contextValue.tenantName;
    
        if(logs != undefined && logs != '' && tenant !== '' && logs?.activity != ''){
            var pageName = logs?.pageName;
            var moduleName = logs?.moduleName;
           
            var activity = logs?.activity;
            var time = logs?.time;
            var initiatedBy = contextValue.userSessionDetails.userName;
            var status = logs?.status;
            var action = logs?.action;

            const { data } = await client.mutate({
                mutation: insertActivityLog,
                variables: {userId, pageName, moduleName, tenant, activity, time, initiatedBy, status, action},
                fetchPolicy: 'network-only',
            });

            if(data.insert_audit_ActivityLog.affected_rows > 0){
                localStorage.setItem('activityLogData', JSON.stringify({}));
                props.setTracker(false);
            }
        }
    }

    const getActivityLogFunc = async (whereCondition: any, limit: any, offset: any) => {
        const { data, loading, error } = await client.query({
            query: getActivityLogQuery,
            variables: {whereCondition, limit, offset},
            fetchPolicy: 'network-only',
          });
      
          if(!loading && data.audit_ActivityLog.length > 0){
            props.func(data);
        }
    }

    const getActivityLogCntFunc = async (whereCondition: any, limit: any, offset: any) => {
        const { data, loading, error } = await client.query({
            query: getActivityLogQuery,
            variables: {whereCondition, limit, offset},
            fetchPolicy: 'network-only',
          });
      
          if(!loading && data.audit_ActivityLog?.length > 0){
            props?.setExportDetails && props?.setExportDetails(data.audit_ActivityLog);
            props.setTotalRecords(data.audit_ActivityLog.length);
            props.setTotalPages(Math.ceil((data.audit_ActivityLog.length)/50));
        }
    }
  
    useEffect(() => {
        if(props.type === 'getLog' || props.isRefresh || parseInt(props.pageNo) > 0){
            getActivityLogFunc({userId: {_eq: userId}, tenant: {_eq: tenantName}, moduleName: {_eq: props.moduleName}}, 50, parseInt(props.pageNo));
           
            getActivityLogCntFunc({userId: {_eq: userId}, tenant: {_eq: tenantName}, pageName: {_eq: props.pageName}, moduleName: {_eq: props.moduleName}}, null, null);

            if(props.isRefresh){
                props.setRefresh(false);
                props.setRefreshToast(true);  
                setTimeout(() => {
                    props.setRefreshToast(false);  
                }, 3000);
            } 
        }else{
            insertActivityLogFunc();
        }    
    }, [props.data, props.pageNo, props.isRefresh, props.searchVal]);

    return (<><div></div></>);
}

export { Activity_log_func }