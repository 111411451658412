import { Button } from '@fluentui/react-components';
import { ChevronDown16Regular } from "@fluentui/react-icons";

const FileDownloadComponent = (props: any) => {
    const handleDownload = () => {
        const content = props.data;
        const blob = new Blob([content], { type: 'text/plain' });
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${props.fileName}.csv`;
        // Trigger a click on the link to start the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return <Button appearance='secondary' size='small' onClick={handleDownload} icon={<ChevronDown16Regular/>} iconPosition='after'> Download </Button>
}

export { FileDownloadComponent }