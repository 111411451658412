import { Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogBody, DialogActions, DialogContent,Text } from "@fluentui/react-components";
import { Stack, StackItem } from "@fluentui/react";
import { Warning20Filled } from "@fluentui/react-icons"
import { dialogBoxStyles, dialogIcon } from "../../styles/customStyles";
 
const DialogBox = (props:any) => {
  return (
    <Dialog modalType='alert'>
      <DialogTrigger disableButtonEnhancement>
        <Stack> {props.headerButton} </Stack>
      </DialogTrigger>
      <DialogSurface style={dialogBoxStyles}>
        <DialogBody>
          <DialogTitle> {props.title} </DialogTitle>
          <DialogContent>
            <Stack horizontal tokens={{childrenGap:10}}>
              <StackItem> <Warning20Filled {...dialogIcon}/> </StackItem>
              <StackItem> {props.content} </StackItem>
            </Stack>   
          </DialogContent>
          <DialogActions position="start">
            <DialogTrigger disableButtonEnhancement>
              <Stack horizontal tokens={{childrenGap:30}}>
                <StackItem><Text></Text></StackItem>
                <StackItem> {props.footer} </StackItem>
              </Stack>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
 
export default DialogBox;