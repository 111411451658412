import { Stack, StackItem } from "@fluentui/react";
import { Body1, Button, Caption1, MenuButton, Text, MenuList, MenuItem } from "@fluentui/react-components";
import { Stack as FluentStack } from "@fluentui/react/lib/Stack";
import { ArrowPrevious20Filled, ArrowNext20Filled } from "@fluentui/react-icons";
import { MenuDropdown } from "../dropdown/menu.component"

export const Pagination = (props: any) => {
  var setCurrentPage = props.setCurrentPageNo;
  var currentPage = props.currentPageNo;
  var pageNumbers = Array.from({ length: props.totalPages }, (_, index) => index + 1);

  // const [currentPage, setCurrentPage]: any = useState(1);
  const dummyData = Array.from({ length: 100 }, (_, index) => index + 1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = dummyData.slice(indexOfFirstItem, indexOfLastItem);
  const paginatedData = dummyData.slice(startIndex, endIndex);

  return (
    <>
      <Stack tokens={{childrenGap:15}}>
        <StackItem>
          <Stack tokens={{childrenGap:10}}>
            <FluentStack horizontal horizontalAlign="end" verticalAlign="end">
              <Stack horizontal tokens={{childrenGap:10}}>
                <Button size="small" disabled={currentPage === 1} onClick={() => props.setCurrentPageNo(currentPage-1)} icon={<ArrowPrevious20Filled/>} iconPosition="before">
                  <Caption1> Previous </Caption1>
                </Button>
                <Stack horizontal>
                  <StackItem>
                    <Stack tokens={{childrenGap:10}} horizontal>
                      <StackItem>
                        <Stack tokens={{childrenGap:3}}>
                          <StackItem><Text></Text></StackItem>
                          <StackItem><Caption1> Page </Caption1></StackItem>
                        </Stack>
                      </StackItem>
                      <StackItem>
                        {/* <DropdownComponent /> */}
                        <MenuDropdown 
                          btn={ 
                            <MenuButton size="small">
                              <Stack tokens={{childrenGap:5}} horizontal> <StackItem> <Body1> {currentPage} </Body1> </StackItem> </Stack>
                            </MenuButton>
                          } 
                          itemVal={
                            <MenuList>
                              {pageNumbers.map((val: any, i: any) => <MenuItem key={i} onClick={() => setCurrentPage(val)}>
                                <Caption1> {val} </Caption1>
                              </MenuItem>)}
                            </MenuList>
                          }
                        />
                      </StackItem>
                      <StackItem>
                        <Stack tokens={{childrenGap:3}}>
                          <StackItem><Text></Text></StackItem>
                          <StackItem> <Caption1> out of {pageNumbers.length} </Caption1>  </StackItem>
                        </Stack>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
                <Button size="small" disabled={currentPage === pageNumbers.length} onClick={() => props.setCurrentPageNo(currentPage+1)} icon={<ArrowNext20Filled/>} iconPosition="after">
                  <Caption1> Next </Caption1> 
                </Button>
                <Stack tokens={{childrenGap:30}} horizontal><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              </Stack>
            </FluentStack>
          </Stack>
        </StackItem>
      </Stack>
    </>
  );
};