import { ApolloClient, InMemoryCache, HttpLink, NormalizedCacheObject } from '@apollo/client';

import AppConfig from '../utility/AppConfig';

const link = new HttpLink({
  uri: AppConfig.API_GRAPHQL_V2,
  // @ts-ignore
  fetch: async (uri, options: any = { headers: {} }) => {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['x-auth-token'] = String(window.localStorage.getItem('adminAuthToken'));
    try {
      const res = await fetch(uri, options);
      if (res.status === 400) {
        console.warn('fetch 400 - check graphql query');
      } else if (res.status === 401) {
        console.log('fetch  ', res);
        debugger;
        window.location.replace(window.location.origin + `/visualization/v2/`);
      }
      return res;
    } catch (e) {
      console.log('fetch error', e);
    }

    return null;
  },
});

const client = new ApolloClient<NormalizedCacheObject>({
  cache: new InMemoryCache(),
  link: link as any,
});

export default client;
