import { gql } from '@apollo/client';

const getGroupIdOfTenantQuery = gql`
  query MyQuery($whereCondition: JSON) {
    core_MachineGroups(where: $whereCondition) {
      mgroupuniq
      mcatuniq
      name
    }
  }
`;

// const getIdsOfDartQuery = gql  `
//     query MyQuery($whereCondition: JSON) {
//         core_Variables(where: $whereCondition) {
//             name
//             varnameuniq
//             varscopuniq
//             varuniq
//         }
//     }
// `;

const getComponentsDetailsOfDartQuery = gql`
  query MyQuery($dartId: [Int!], $groupId: String!) {
    core_Variables(where: { scop: { _in: $dartId } }) {
      varnameuniq
      varscopuniq
      varuniq
      scop
      name
      Variables_VarValues(where: { mgroupuniq: { _eq: $groupId } }) {
        valueid
        valu
        revl
      }
    }
  }
`;

const insertValueOfComponentQuery = gql`
  mutation MyMutation(
    $valu: String
    $revl: Int
    $varnameuniq: String
    $varscopuniq: String
    $varuniq: String
    $mcatuniq: String
    $mgroupuniq: String
    $lastchange: Int!
  ) {
    insert_core_VarValues(
      objects: {
        valu: $valu
        revl: $revl
        varnameuniq: $varnameuniq
        varscopuniq: $varscopuniq
        varuniq: $varuniq
        mcatuniq: $mcatuniq
        mgroupuniq: $mgroupuniq
        lastchange: $lastchange
      }
    ) {
      affected_rows
    }
  }
`;

const deleteValueOfComponentQuery = gql`
  mutation MyMutation($valueid: [Int]) {
    delete_core_VarValues(where: { valueid: { _in: $valueid } }) {
      affected_rows
    }
  }
`;

const updateValueOfComponentQuery = gql`
  mutation MyMutation($whereCondition: JSON!, $valu: String, $revl: Int, $lastchange: Int!) {
    update_core_VarValues(where: $whereCondition, _set: { valu: $valu, revl: $revl, lastchange: $lastchange }) {
      affected_rows
    }
  }
`;

const updateAutomationStautsQuery = gql`
  mutation MyMutation($whereCondition: JSON!, $status: String!) {
    update_core_AutomationJSON(where: $whereCondition, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export {
  getGroupIdOfTenantQuery,
  getComponentsDetailsOfDartQuery,
  insertValueOfComponentQuery,
  updateValueOfComponentQuery,
  updateAutomationStautsQuery,
  deleteValueOfComponentQuery,
};
