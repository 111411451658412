import { WidgetProps } from '@rjsf/utils';
import { Body1Strong, Switch } from "@fluentui/react-components";
import { Stack, StackItem, Text } from '@fluentui/react';
 
const trueExamples = ['Yes', 'yes', 1, '1', 'true', 'True', 'y', 'Y', 'enable', 'Enable'];

export const ToggleSwitch = (props: WidgetProps) => {
  const onChanges = (checked: boolean) => {
    console.log("props.value: ", props);
    props.onChange(checked ? '1' : '0');
  };
 
  return(
    <Stack horizontal tokens={{childrenGap:8}}>
      <StackItem>
        <Stack tokens={{childrenGap:10}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Body1Strong> {props.label} </Body1Strong> </StackItem>
        </Stack>
      </StackItem>
     <StackItem align='end'>
        <Stack tokens={{childrenGap:20}}>
          <StackItem align='end'>
            <Switch checked={trueExamples.includes(props.value)} onChange={(e) => onChanges(e.target.checked)} disabled={props?.disabled ? true : false}/>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};