import { RegistryWidgetsType } from '@rjsf/utils';
import { CustomTableWidget } from './CustomTableWidget';
import { Checked } from '../switch/switch.component';
import { ToggleSwitch } from './toggleSwitch.component';
import { MultipleSelectToString } from './MultipleSelectToString';
// import { DartScheduleWidget } from './dartSchedule';
// import { stringNoYes } from './stringNoYes';
// import { stringEnableDisable } from './stringEnableDisable';
// import { CustomObjectsWidget } from './CustomObjectsWidget';
// import { CustomObjectsList } from './CustomObjectsList';

export const CustomWidgets: RegistryWidgetsType = {
    CustomTableWidget: CustomTableWidget,
    MultipleSelectToString: MultipleSelectToString,
    string01: Checked, // old name
    Togglebutton: ToggleSwitch, // new name

    // DartScheduleWidget: DartScheduleWidget,
    // CustomObjectsWidget: CustomObjectsWidget,
    // CustomObjectsList: CustomObjectsList,
    // stringNoYes: stringNoYes,  
    // stringEnableDisable: stringEnableDisable,
    // Togglebutton: Checked, // new name
};