import { useState, useEffect, useContext } from "react";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem, TooltipHost } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input, Tooltip } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { ToastComponent } from "../../../components/toast/toast.component";
import { GlobalContext } from "../../../hooks/globalContext";
import { FileDownloadComponent } from "../../../components/fileExport/file.export.component";
import { fullscreenIcon } from "../../../styles/customStyles";
import { shouldLoadRoute } from "../../../Authentication/RouteProtection";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

const iconStyleProps: any = {
  primaryFill: "#008099",
  className: "iconClass",
};
const iconExitStyleProps: any = { primaryFill: "#d13438" };


export const Activity_log = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isRefresh, setRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [overlayData, setOverlayData] = useState<any>(false);
  const [activityExportData, setActivityExportData] = useState<any>('No data available');

  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [isfilter, setIsFilter] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [permissions, setPermissions] = useState(['']);
  console.log("checking the permissions------>", permissions)

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  }
  const handleCloseFilter = (index: any): any => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  useEffect(() => {
    if (filterArray.length > 0)
      setResetDisabled(false)

  }, [filterArray])

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log("entered value", enteredValue)
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  }

  var menuItems: any = [];

  //inline side menu content code starts here
  const selectedTheme: BrandVariants = {
    10: "#010404",
    20: "#0F1A1D",
    30: "#142B32",
    40: "#163842",
    50: "#164652",
    60: "#165464",
    70: "#136275",
    80: "#0C7187",
    90: "#038099",
    100: "#388DA4",
    110: "#d6d6d6",
    120: "#6FA8B9",
    130: "#86B5C4",
    140: "#9DC3CF",
    150: "#B4D1DA",
    160: "#CADFE5",
  };
  const lightTheme: Theme = {
    ...createLightTheme(selectedTheme)
  };
  const darkTheme: Theme = {
    ...createDarkTheme(selectedTheme)
  };

  // lightTheme.colorNeutralBackground4 = '#0a0a0a';
  // darkTheme.colorNeutralBackground1
  darkTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground1 = "#e9e9e9";
  lightTheme.colorNeutralBackground4 = "#0a0a0a";
  darkTheme.colorNeutralForeground1 = "#ffff00";
  
  if(permissions.includes("overview_Read") || permissions.includes("overview_ReadandExport") || permissions.includes("overview_Fullaccess")) 
{
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/overview")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Info20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Overview </Caption1> </StackItem>
          <StackItem> <Stack tokens={{ childrenGap: 80 }} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem>
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
    );
}
if(permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_ReadandExport") || permissions.includes("deviceClassification_Fullaccess")) 
{
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/device_classification")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <GroupList20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Device Classification </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
}

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/monitor")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <DesktopPulse20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Monitors </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );

if(permissions.includes("remediation_Read") || permissions.includes("remediation_ReadandExport") || permissions.includes("remediation_Fullaccess")) 
 {
   menuItems.push
     (
 
       <MenuItem onClick={() => navigate("/manage/devices/remediation")}
         icon={
           <Stack horizontal tokens={{ childrenGap: 40 }}>
             <StackItem><Text></Text></StackItem>
             <StackItem> <Wrench20Regular {...iconStyleProps} /> </StackItem>
           </Stack>}>
         <Stack tokens={{ childrenGap: 30 }} horizontal>
           <StackItem><Text></Text></StackItem>
           <StackItem> <Caption1> Remediations </Caption1> </StackItem>
         </Stack>
       </MenuItem>
 
     );
 }

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/compliancepolicies")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <ShieldQuestion20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/conditionalaccess")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <LockClosedKey20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Conditional Access </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
  
if(permissions.includes("userSurveys_Read") || permissions.includes("userSurveys_ReadandExport") || permissions.includes("userSurveys_Fullaccess")) 
{
  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/usersurvey")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <ChatMultipleHeart20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> User Survey </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );
}

  menuItems.push
    (
      <MenuItem onClick={() => navigate("/manage/devices/datacollection")}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem><Text></Text></StackItem>
            <StackItem> <BookDatabase20Regular {...iconStyleProps} /> </StackItem>
          </Stack>}>
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1> Data Collection </Caption1> </StackItem>
        </Stack>
      </MenuItem>
    );

if(permissions.includes("activityLog_Read") || permissions.includes("activityLog_ReadandExport") || permissions.includes("activityLog_Fullaccess")) 
{
  menuItems.push
    (
      <FluentProvider theme={lightTheme}>
        <MenuItem onClick={() => navigate("/manage/devices/activitylog")}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem><Text></Text></StackItem>
              <StackItem> <BookTemplate20Regular {...iconStyleProps} /> </StackItem>
            </Stack>}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem><Text></Text></StackItem>
            <StackItem> <Caption1> Activity Log </Caption1> </StackItem>
          </Stack>
        </MenuItem>
      </FluentProvider>
    );
}
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: "date", label: <Body1Strong>&nbsp; Date </Body1Strong>  },
    // { key: "date", label: <Body1Strong> Date </Body1Strong> },
    { key: "page", label: <Body1Strong> Module </Body1Strong> },
    // { key: "os", label: <Body1Strong> Scope </Body1Strong> },
    { key: "activity", label: <Body1Strong> Activity </Body1Strong> },
    { key: "initiated_by", label: <Body1Strong> Initiated by </Body1Strong> },
    { key: "action", label: <Body1Strong> Action </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here
  //data table for filter
  var columns1 = [
    { key: "module", label: <Caption1> Module </Caption1> },
    { key: "initiated_by", label: <Caption1> Initiated by </Caption1> },
    { key: "action", label: <Caption1> Action </Caption1> },
    { key: "status", label: <Caption1> Status </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => { setCurrentPageNo(1); setRefresh(true); }}>
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem> <ArrowClockwise20Regular {...iconStyleProps} /> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          { permissions.includes("activityLog_Fullaccess") || permissions.includes("activityLog_ReadandExport") ? (
              <CSVLink id="csv-export-link" data={exportData} filename="devices_activitylog.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...iconStyleProps} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink>

            ) : 
              <Tooltip withArrow content={"You do not have access"} relationship="description" positioning="above">
                <Button appearance="subtle" size="small">
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...iconStyleProps} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </Tooltip>
          }
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...iconStyleProps}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var menuLists: any = [];

  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      if (val.pageName !== 'Devices') {
        tempExportData.push({
          date: new Date(val.time * 1000).toLocaleString(),
          page: val.pageName,
          activity: val.activity,
          initiated_by: val.initiatedBy,
          action: val.action,
          status: val.status,
        });
      }
    });

    setExportData(tempExportData);
  }, [exportDetails]);

  const [activity, setActivity]: any = useState('{}');
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=", final_data);

    //roles and permissions
    async function loadRoute() {
      const role = "Nano_test";
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions)
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data.audit_ActivityLog.map((val: any, i: any) => {
        var activityVal = JSON.parse(val.activity).summary;

        if (val.pageName !== 'Devices') {
          // tempTableData.push({
          //   id: val.ID,
          //   checkbox: "hide",
          //   date: { label: <Button appearance="transparent" size="small" onClick={()=>{setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}> {val.time ? new Date(val.time *1000).toLocaleString() : ''} </Button>, id: `${i}` },
          //   page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
          //   activity: { label: <Caption1> {activityVal} </Caption1> },
          //   initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
          //   action: { label: <Caption1> {val.action} </Caption1> },
          //   status: { label: <Caption1> {val.status} </Caption1> },
          // });

          if (permissions.includes("dataCollection_Read") || permissions.includes("dataCollection_ReadandExport")) {
           tempTableData.push({
            id: val.ID,
            checkbox: "hide",
            date: { label:            
            <TooltipHost content="This feature is not available for you!">
              <Button appearance="transparent" size="small" > {val.time ? new Date(val.time *1000).toLocaleString() : ''} </Button>
              </TooltipHost>, id: `${i}` },
            page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
            activity: { label: <Caption1> {activityVal} </Caption1> },
            initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
            action: { label: <Caption1> {val.action} </Caption1> },
            status: { label: <Caption1> {val.status} </Caption1> },
          });

          } else if (permissions.includes("dataCollection_Fullaccess")) {
            tempTableData.push({
              id: val.ID,
              checkbox: "hide",
              date: { label: <Button appearance="transparent" size="small" onClick={()=>{setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}> {val.time ? new Date(val.time *1000).toLocaleString() : ''} </Button>, id: `${i}` },
              page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
              activity: { label: <Caption1> {activityVal} </Caption1> },
              initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1> },
              action: { label: <Caption1> {val.action} </Caption1> },
              status: { label: <Caption1> {val.status} </Caption1> },
            });
          }

          // const hasFullAccess = permissions.includes("activityLog_Fullaccess");
          // const readOrExport = permissions.includes("activityLog_Read") || permissions.includes("activityLog_ReadandExport");

          // const dateLabelContent = hasFullAccess ?
          //   <Button appearance="transparent" size="small" onClick={() => { setOverlayOpen(true); setOverlayData(val); setActivity(JSON.parse(val.activity)); }}>
          //     {val.time ? new Date(val.time * 1000).toLocaleString() : ''}
          //   </Button> :
          //   readOrExport ?
          //     <TooltipHost content="This feature is not available for you!">
          //       <Button appearance="transparent" size="small">
          //         {val.time ? new Date(val.time * 1000).toLocaleString() : ''}
          //       </Button>
          //     </TooltipHost> :
          //     null; // Adjust according to your logic if there's another case

          // tempTableData.push({
          //   id: val.ID,
          //   checkbox: "hide", // Since both conditions have "hide", this is set directly. Adjust if this changes.
          //   date: { label: dateLabelContent, id: `${i}` },
          //   page: { label: <Caption1>{val.pageName}</Caption1>, status: "" },
          //   activity: { label: <Caption1>{activityVal}</Caption1> },
          //   initiated_by: { label: <Caption1>{val.initiatedBy}</Caption1> },
          //   action: { label: <Caption1>{val.action}</Caption1> },
          //   status: { label: <Caption1>{val.status}</Caption1> },
          // });



        }
      }) : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    var data = '';

    data = `Activity
            \nDate and time of activity - ${overlayData?.time ? new Date(overlayData?.time * 1000).toLocaleString() : ''}
            \nModule where the activity was performed - ${overlayData?.pageName}
            \nActivity - ${activity?.summary}
            \nInitiated by - ${overlayData?.initiatedBy}
            \nAction - ${overlayData?.action}
            \nStatus - ${overlayData?.status}`;

    if (overlayData?.pageName === "Overview") {
      if (overlayData?.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified properties
                \nNot applicalble`;
      }
    } else if (overlayData?.pageName === "Device Classification") {
      if (overlayData?.action === 'Add') {
        data += `\n----------------------------------------
                \nTarget
                \nClassification name - ${activity.name}
                \nOperation type - ${activity.type}
                \nTotal number of devices added - ${activity.deviceCnt}`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      } else if (overlayData?.action === 'Delete') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nClassification name - ${activity.classificationNames}`;
      } else if (overlayData?.action === 'Create') {
        data += `\n----------------------------------------
        \nTarget
        \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nClassification name - ${activity.classificationNames}`;
      } else if (overlayData?.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      }
    } else if (overlayData?.pageName === "Remediation list") {
      if (overlayData?.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      }
    } else if (overlayData?.pageName === "Remediation assignment") {
      if (overlayData?.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      } else if (overlayData?.action === 'Add') {
        data += `\n----------------------------------------
                \nTarget
                \nRemediation name - ${activity.remediationName}
                \nAttached scope - ${activity.attachedGroups}
                \nDetached scope - ${activity.detachedGroups}`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      }
    } else if (overlayData?.pageName === "User Survey list") {
      if (overlayData.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      }
    } else if (overlayData?.pageName === "User Survey assignment") {
      if (overlayData.action === 'Export') {
        data += `\n----------------------------------------
                \nTarget
                \nNot applicalble`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      } else if (overlayData.action === 'Add') {
        data += `\n----------------------------------------
                \nTarget
                \nSurvey name - ${activity.surveyName}
                \nAttached devices list - ${activity.attachedGroups}
                \nDetached devices list - ${activity.detachedGroups}`;

        data += `\n----------------------------------------
                \nModified Properties
                \nNot applicalble`;
      }
    }

    setActivityExportData(data);
  }, [overlayData]);


  //overlay code starts here
  var overLayData: any = {
    headerTitle:
      (
        <>
          <Stack>
            <Text size={600} weight="semibold"> Audit Log Details <FileDownloadComponent data={activityExportData} fileName="Activity_log" /></Text>
          </Stack>
        </>
      ),
    size: "large",
    isTabs: true,
    tabItems: {
      Activity:
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Date and time of activity </Body1Strong> - <Caption1> {overlayData?.time ? new Date(overlayData?.time * 1000).toLocaleString() : ''} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Module where the activity was performed  </Body1Strong> - <Caption1>{overlayData?.pageName}</Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Activity </Body1Strong> - <Caption1>{activity?.summary}</Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Initiated By </Body1Strong> - <Caption1>{overlayData?.initiatedBy}</Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Action </Body1Strong> - <Caption1>{overlayData?.action}</Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Status </Body1Strong> - <Caption1>{overlayData?.status}</Caption1>
            </StackItem>

          </Stack>
        </FluentProvider>
    },
    Targets: '',
    "Modified Properties": ''
  };
  //overlay code ends here

  if (overlayData?.pageName === "Overview") {
    overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
      <Stack tokens={{ childrenGap: 15, padding: 10 }}>
        <StackItem>
          Not applicalble
        </StackItem>
      </Stack>
    </FluentProvider>

    overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
      <Stack tokens={{ childrenGap: 15, padding: 10 }}>
        <StackItem>
          Not applicalble
        </StackItem>
      </Stack>
    </FluentProvider>

  } else if (overlayData?.pageName === "Device Classification") {
    if (overlayData?.action === 'Add') {
      overLayData.tabItems.Targets =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Classification name </Body1Strong> - <Caption1> {activity.name} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Operation type </Body1Strong> - <Caption1> {activity.type} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Total number of devices added </Body1Strong> - <Caption1> {activity.deviceCnt} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> List of devices added </Body1Strong> - <Button size="small" onClick={() => { localStorage.setItem('classificationName', activity.name); navigate("/manage/devices/device_classification/devices") }}> Click here </Button>
            </StackItem>
          </Stack>
        </FluentProvider>

      // if(overlayData?.action === 'Delete'){
      //   overLayData.tabItems['Modified Properties'] = 
      //   <FluentProvider theme={webLightTheme}>
      //     <Stack tokens={{childrenGap:15, padding:10}}>
      //       <DataTable tableData={JSON.parse(overlayData.activity).deviceList.split(",").map((val: any, i: any) => {return (
      //         { id: i,
      //           checkbox: "hide",
      //           deviceName: {label: <Caption1> {val} </Caption1>, icon: "", status: ""}
      //         }
      //       )})}
      //       columns={[{ key: "deviceName", label: <Body1Strong> Device Name </Body1Strong>}]} />
      //     </Stack> 
      //   </FluentProvider>
      // }
      overLayData.tabItems['Modified Properties'] =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              Not applicalble
            </StackItem>
          </Stack>
        </FluentProvider>
    } else if (overlayData?.action === 'Delete') {
      overLayData.tabItems.Targets =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Classification name </Body1Strong> - <Caption1> {activity.classificationNames} </Caption1>
            </StackItem>
          </Stack>
        </FluentProvider>

      overLayData.tabItems['Modified Properties'] =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              Not applicalble
            </StackItem>
          </Stack>
        </FluentProvider>
    } else {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  } else if (overlayData?.pageName === "Devices") {
    if (overlayData.action === 'Edit') {
      overLayData.tabItems.Targets =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Classification name </Body1Strong> - <Caption1> {activity.name} </Caption1>
            </StackItem>

            {activity.type && <StackItem>
              <Body1Strong> Operation type </Body1Strong> - <Caption1> {activity.type} </Caption1>
            </StackItem>}

            <StackItem>
              <Body1Strong> Total number of devices modified </Body1Strong> - <Caption1> {activity.deviceCnt} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> List of devices </Body1Strong> - <Button size="small" onClick={() => { localStorage.setItem('classificationName', activity.name); contextValue.setSelectedRowDetails({ ...contextValue.selectedRowDetails, name: activity.name }); navigate("/manage/devices/device_classification/devices") }}> Click here </Button>
            </StackItem>
          </Stack>
        </FluentProvider>

      overLayData.tabItems['Modified Properties'] =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <DataTable tableData={JSON.parse(overlayData.activity).deviceList.split(",").map((val: any, i: any) => {
              return (
                {
                  id: i,
                  checkbox: "hide",
                  deviceName: { label: <Caption1> {val} </Caption1>, icon: "", status: "" }
                }
              )
            })}
              columns={[{ key: "deviceName", label: <Body1Strong> Device Name </Body1Strong> }]} />
          </Stack>
        </FluentProvider>
    } else {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems['Modified Properties'] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  } else if (overlayData?.pageName === "Remediation list") {
    if (overlayData.action === 'Export') {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  } else if (overlayData?.pageName === "Remediation assignment") {
    if (overlayData.action === 'Export') {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    } else if (overlayData.action === 'Add') {
      overLayData.tabItems.Targets =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Remediation name </Body1Strong> - <Caption1> {activity.remediationName} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Attached scope </Body1Strong> - <Caption1> {activity.attachedGroups} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Detached scope </Body1Strong> - <Caption1> {activity.detachedGroups} </Caption1>
            </StackItem>
          </Stack>
        </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  } else if (overlayData?.pageName === "User Survey list") {
    if (overlayData.action === 'Export') {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  } else if (overlayData?.pageName === "User Survey assignment") {
    if (overlayData.action === 'Export') {
      overLayData.tabItems.Targets = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    } else if (overlayData.action === 'Add') {
      overLayData.tabItems.Targets =
        <FluentProvider theme={webLightTheme}>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>
            <StackItem>
              <Body1Strong> Survey name </Body1Strong> - <Caption1> {activity.surveyName} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Attached devices list </Body1Strong> - <Caption1> {activity.attachedGroups} </Caption1>
            </StackItem>

            <StackItem>
              <Body1Strong> Detached devices list </Body1Strong> - <Caption1> {activity.detachedGroups} </Caption1>
            </StackItem>
          </Stack>
        </FluentProvider>

      overLayData.tabItems[`Modified Properties`] = <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 15, padding: 10 }}>
          <StackItem>
            Not applicalble
          </StackItem>
        </Stack>
      </FluentProvider>
    }
  }

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Devices" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...iconExitStyleProps} /> : <ArrowMaximize20Regular {...iconStyleProps} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            {/* pages code starts here */}
            <Stack verticalAlign="center">
              <Stack tokens={{ childrenGap: 25 }} horizontal>
                <StackItem> <Text></Text> </StackItem>
                <StackItem>
                  <FluentProvider theme={webLightTheme}>
                    <Title3> Devices | Activity Log </Title3>
                  </FluentProvider>
                </StackItem>
              </Stack>

              <Stack tokens={{ childrenGap: 20 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>

              <FluentProvider theme={webLightTheme}>
                <Stack horizontal>
                  {/* SPACING BETWEEN INLINE AND PAGE */}
                  <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                  <StackItem>
                    <StackItem> <Text></Text> </StackItem>
                    <Stack tokens={{ childrenGap: 1 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                    <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                        <Divider />
                      </StackItem>
                      <StackItem>
                        <StackItem>
                          <Stack horizontal tokens={{ childrenGap: 20 }}>

                            <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small" />
                            <StackItem>
                              <MenuDropdown open={filterMenuOpen} btn=
                                {
                                  <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <Filter20Filled {...iconStyleProps} />
                                      <Body1> Filter </Body1>
                                    </Stack>
                                  </MenuButton>
                                } itemVal={
                                  <>
                                    <Body1Strong> Add a Filter </Body1Strong>
                                    <Divider />
                                    <MenuList>
                                      <Stack tokens={{ childrenGap: 5 }}>
                                        <StackItem> <Text></Text> </StackItem>
                                        <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                        <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                        <StackItem> <Divider /> </StackItem>
                                        <StackItem> <Caption1> Value </Caption1> </StackItem>
                                        {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                        <Input size='small' onChange={inputChange} name="filter" value={dropDownValue2} />
                                        <StackItem> <Divider /> </StackItem>
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                          <StackItem> <Button appearance="primary" onClick={() => { handleFilterArray(); setFilterMenuOpen(false) }}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                          <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                        </Stack>
                                      </Stack>
                                    </MenuList>
                                  </>
                                }
                              />
                            </StackItem>
                            <StackItem>
                              {filterArray.length > 0 && filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem><Text></Text></StackItem>
                                        <StackItem><Dismiss16Regular onClick={() => { handleCloseFilter(index) }} /></StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                            </StackItem>
                            <StackItem onClick={() => { setFilterArray([]); setIsFilter(false); setDropDownValue2(''); setResetDisabled(true); }}>
                              <Button appearance="subtle" size="small" disabled={isResetDisabled} >
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem> <FilterDismiss20Filled /> </StackItem>
                                  <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                </Stack>
                              </Button>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 10 }}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                        {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                          <StackItem> {totalRecords > 0 && <Caption1>  Showing {((currentPageNo - 1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1>} </StackItem>
                          <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} /> </StackItem>
                        </Stack> : <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}><StackItem><Text></Text></StackItem><StackItem><Text size={400}>No Results</Text></StackItem></Stack>}
                      </StackItem>

                      <Activity_log_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} isFilter={isfilter} dropDownValue1={dropDownValue1} setIsFilter={setIsFilter} dropDownValue2={dropDownValue2} type="getLog" moduleName="Devices" setExportDetails={setExportDetails} setRefresh={setRefresh} isRefresh={isRefresh} setRefreshToast={setRefreshToast} />

                      {tableData.length > 0 && (<DataTable tableData={tableData} columns={columns} />)}
                      {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
                    </Stack>
                  </StackItem>
                </Stack>
              </FluentProvider>
            </Stack>
            <OverLay
              overlayopen={overlayOpen}
              setOverlayOpen={setOverlayOpen}
              overlayData={overLayData} />
          </StackItem>
          <StackItem><Text></Text></StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};