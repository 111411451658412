import React from "react";
import { Switch } from "@fluentui/react-components";

interface CheckedProps {
  [x: string]: boolean | undefined | any; // onChange?: (checked: boolean) => void;
  onChange?: any;
}

export const Checked: React.FC<CheckedProps> = (props,{ onChange }) => {
  const [checked, setChecked] = React.useState(false);
  console.log("props: ", props);
  
  const onSwitchChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      console.log("onChange=",props.value);
      const isChecked = ev.currentTarget.checked;
      setChecked(isChecked); // Call the onChange prop if it is provided
      onChange && onChange(isChecked);
    }, [onChange, setChecked]
  );

  return (
    <Switch
      onChange={onSwitchChange}
      checked={checked ? true : false}
      label={checked ? "Enabled" : "Disabled"}
      disabled={props?.disabled ? true : false}
    />
  );
};