import { gql } from '@apollo/client';

const getRemediationListQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int = null, $offset: Int = null) {
    core_AutomationJSON(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      createdBy
      createdOn
      dartid
      description
      status
      automationName
      lastModified
      varValues
      types
    }
  }
`;

const getGroupsListQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int = null, $offset: Int = null) {
    core_AutomationJSON(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      createdBy
      createdOn
      dartid
      description
      status
      automationName
      lastModified
      types
    }
  }
`;

const attachRemediationQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int = null, $offset: Int = null) {
    core_AutomationJSON(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      createdBy
      createdOn
      dartid
      description
      status
      automationName
      lastModified
      types
    }
  }
`;
const setPolicyPatch = gql`
  mutation MyMutation($policy288: String = "", $policyid: Int) {
    update_mdm_AdmxAdml(where: { policy_id: { _eq: $policyid } }, _set: { policy_patch: $policy288 }) {
      affected_rows
      returning {
        policy_patch
      }
    }
  }
`;

export { getRemediationListQuery, getGroupsListQuery, attachRemediationQuery, setPolicyPatch };
