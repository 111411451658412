import { Menu, MenuTrigger, MenuPopover } from "@fluentui/react-components";

export const MenuDropdown = (props: any) => (
  <Menu open={props.open}>
    <MenuTrigger disableButtonEnhancement>
      {props.btn}
    </MenuTrigger>
    <MenuPopover> {props.itemVal} </MenuPopover>
    {/* <MenuPopover> <MenuList> {props.menuList.map((val: any) => <MenuItem><Caption2>{val}</Caption2></MenuItem>)} </MenuList>  </MenuPopover> */}
  </Menu>
);