import { gql } from '@apollo/client';

const getAppMonitored = gql`
  query MyQuery($whereCondition: JSON, $limit: Int, $offset: Int = 0) {
    analytics_test_Scores(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      Category
      subcategory
      MetricName
      MetricDesc
      SpecificInfo
      Scores
      UrlPatters
      ApplicationType
    }
  }
`;

const getAppMonitorBinaries = gql`
  query MyQuery($id: Int!) {
    analytics_test_Scores(where: { id: { _eq: $id } }) {
      id
      UrlPatters
      SpecificInfo
      Site
      Scope
      Scores
      MetricName
    }
  }
`;

const getAppMonitoredCntQuery = gql`
  query MyQuery($whereCondition: JSON) {
    analytics_test_Scores(where: $whereCondition) {
      Category
      subcategory
      MetricName
      MetricDesc
      SpecificInfo
      Scores
      id
      UrlPatters
      ApplicationType
    }
  }
`;

// const addAppMonitoredQuery = gql`
// mutation MyMutation($Category: String , $MetricDesc: String, $MetricName: String , $MetricWeightage: Int, $SCategoryWeightage: Int, $Scores: String!, $SpecificInfo: String , $id: Int, $subcategory: String  $CategoryWeightage: Int) {
//   insert_analytics_test_Scores(objects: {Category: $Category, MetricName: $MetricName, Scores: $Scores, SpecificInfo: $SpecificInfo, id: $id, subcategory: $subcategory, MetricDesc: $MetricDesc, CategoryWeightage: "1", MetricWeightage: "1", SCategoryWeightage: "1"}) {
//     returning {
//       id
//       subcategory
//       MetricName
//     }
//     affected_rows
//   }
// }

// `
// ;
const addAppMonitoredQuery = gql`
  mutation MyMutation(
    $Category: String = ""
    $MetricDesc: String = ""
    $MetricName: String = ""
    $Scores: String = ""
    $SpecificInfo: String = ""
    $subcategory: String = ""
    $UrlPatters: String
    $ApplicationType: String = ""
    $Scope: String = ""
    $Site: String = ""
  ) {
    insert_analytics_test_Scores(
      objects: {
        Category: $Category
        MetricName: $MetricName
        Scores: $Scores
        SpecificInfo: $SpecificInfo
        subcategory: $subcategory
        MetricDesc: $MetricDesc
        UrlPatters: $UrlPatters
        ApplicationType: $ApplicationType
        SCategoryWeightage: 1
        MetricWeightage: 1
        CategoryWeightage: 1
        Scope: $Scope
        Site: $Site
      }
    ) {
      returning {
        id
        subcategory
        MetricName
      }
      affected_rows
    }
  }
`;

const deleteAppMonitoredQuery = gql`
  mutation MyMutation($_eq: [Int!] = []) {
    delete_analytics_test_Scores(where: { id: { _in: $_eq } }) {
      affected_rows
      returning {
        Category
        MetricName
        subcategory
      }
    }
  }
`;
const updateAppMonitoreduery = gql`
  mutation MyMutation(
    $ApplicationType: String = ""
    $Category: String = ""
    $MetricDesc: String = ""
    $Scores: String = ""
    $SpecificInfo: String = ""
    $UrlPatters: String = ""
    $subcategory: String = ""
    $id: Int
  ) {
    update_analytics_test_Scores(
      where: { id: { _eq: $id } }
      _set: {
        ApplicationType: $ApplicationType
        Category: $Category
        MetricDesc: $MetricDesc
        Scores: $Scores
        SpecificInfo: $SpecificInfo
        UrlPatters: $UrlPatters
        subcategory: $subcategory
      }
    ) {
      affected_rows
      returning {
        MetricName
        Scores
      }
    }
  }
`;
export { getAppMonitored, getAppMonitoredCntQuery, addAppMonitoredQuery, deleteAppMonitoredQuery, updateAppMonitoreduery, getAppMonitorBinaries };
