import { useState, useEffect, useContext } from "react";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Card, ButtonProps } from "@fluentui/react-components";
import { BookTemplate20Regular, ChatMultipleHeart20Regular, CalendarClock20Regular, Wand20Regular, SettingsCogMultiple20Regular,Filter20Filled, FilterDismiss20Filled, ArrowClockwise20Regular, ArrowMaximize20Regular, DismissSquare24Filled, Info20Filled, CheckmarkLock20Regular, Dismiss16Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { Cards } from "../../../components/card/card.component";
import { All_automation_func } from "../../../api/automation/all_automation_func";
import { GlobalContext } from "../../../hooks/globalContext";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { useTracking } from "react-tracking";
import { ToastComponent } from "../../../components/toast/toast.component";
import { fullscreenIcon, primaryIcon, exitIcon, lightTheme, messageTheme, overflowDivider } from "../../../styles/customStyles";
import { DataTable } from "../../../components/data_table/data_table.component";
import { Configuration_func } from "../../../api/automation/configuration_func";
import { Pagination } from "../../../components/pagination/pagination.component";

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) { csvExportLink.click(); }
};

export const All_automation = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [cardTemplate, setCardTemplate]: any = useState([]);
  const[isRefresh,setIsRefresh]:any =useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [isToast, setIsToast] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pageName,setPageName] =useState('all-automation')
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];
 
  menuItems.push
  ( 
    <FluentProvider theme={lightTheme}>
      <MenuItem onClick={() => navigate("/automate/automation/all_automations")}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > All&nbsp;Automations </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
    </FluentProvider>
  );

  menuItems.push
  ( 
    <MenuItem onClick={() => navigate("/automate/automation/all_automations/configuration")}
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Automations </Caption1> </StackItem>
        <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
      </Stack>
    </MenuItem>
  );

  menuItems.push
  ( 
      <MenuItem onClick={() => {localStorage.setItem('dartNo','304'); contextValue.setDartNo(304); navigate("/automate/automation/selfhelp")}}
      icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <SettingsCogMultiple20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 >Self-Help </Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:44}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );

  menuItems.push
  ( 
    <MenuItem key={1} onClick={() => {localStorage.setItem('dartNo','69'); contextValue.setDartNo(69); navigate("/automate/automation/autoheal")}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Wand20Regular {...primaryIcon} /> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1  > Autoheal </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  ( 
    <MenuItem key={2} onClick={() => {localStorage.setItem('dartNo','304'); contextValue.setDartNo(304); navigate("/automate/automation/automationschedule")}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <CalendarClock20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Automation Schedules </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  (
    <MenuItem key={3} onClick={()=> {localStorage.setItem('dartNo','241'); contextValue.setDartNo(241); navigate("/automate/automation/usersurvey");}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <ChatMultipleHeart20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> User Surveys </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  (
    <MenuItem key={3} onClick={()=> {localStorage.setItem('dartNo','241'); contextValue.setDartNo(241); navigate("/automate/automation/compliance_policies/policy_configuration");}} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <CheckmarkLock20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1> Compliance Policies </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  menuItems.push
  (
    <MenuItem key={4} onClick={()=>navigate("/automate/automation/activitylog")} 
    icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BookTemplate20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1 > Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
  );

  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: "name", label: <Body1Strong> Name </Body1Strong>  },
    { key: "type", label: <Body1Strong> Type </Body1Strong> },
    { key: "description", label: <Body1Strong> Description </Body1Strong> },
    { key: "created_by", label: <Body1Strong> Created By </Body1Strong> },
    { key: "created_on", label: <Body1Strong> Created On</Body1Strong> },
    { key: "last_modified", label: <Body1Strong> Last Modified </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: 
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setIsRefresh(true); setSearchVal("");}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
      </>
    ,
  };
  // overflow set contents code ends here

  var menuLists: any = [];
  var tempExportData: any = [];
  
  // data table values append code ends here

  var timer: any;
  const searchHandle = (data: any) => {
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchVal(data.target.value);
    },500);
  };
  var tempTableData: any = [];
  useEffect(() => {
    var status =''
    console.log("final_data=",final_data);
    final_data !== undefined && final_data !== null
      ? final_data?.core_AutomationJSON.map((val: any, i: any) => {
        if(val.status == 'not attached'){
            status = 'Not attached';  
          }else{
            var curStatus = val.status?.split("|").filter((val: any) => val.includes(`${contextValue.tenantName}:`));
   
            if(curStatus.length == 0){
              status = 'Not attached';  
            }else{
              curStatus = curStatus[0]?.split(":")[1];
           
              if(curStatus == 'all'){
                status = 'Enabled for All Devices';
              }else{
                status = curStatus?.split(",").length == 1 ? `Enable for ${curStatus?.split(",").length} Device Classification` : `Enable for ${curStatus?.split(",").length} Device Classifications`;
              }
            }
          }
    
          tempTableData.push({
            checkbox:"hide",
            name: { label: <Caption1>{val.automationName}</Caption1>},
            type: { label: <Caption1> {val.types.charAt(0).toLocaleUpperCase() + val.types.slice(1)} </Caption1>, status: "" },
            description: { label: <Caption1> {val.description}</Caption1> },
            created_by: { label: <Caption1> {val.createdBy} </Caption1> },
            created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
            last_modified: { label: <Caption1> {val.lastModified? new Date(val.lastModified * 1000).toLocaleString():"-"} </Caption1> },
            status: { label: <Caption1>{status} </Caption1> },
          });
        }) : (tempTableData = []);
        

        tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
        console.log(tableData,"table data");
  }, [final_data])
  

  useEffect(() => {
    console.log("tracked page");
    // !tracker && setTracker(true);
    // trackUserActivity({pageName: 'All automation', moduleName: "Automation", action: 'view', time: Math.floor(Date.now() / 1000)});
  }, []);
  const DismissButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        onClick={()=>setSearchVal('')}
        {...props}
        appearance="transparent"
        icon={<Dismiss16Regular />}
        size="small"
      />
    );
  };
  

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Automation" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink> 
                  <Button
                    style={fullscreenIcon} 
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <Stack tokens={{childrenGap:20}} horizontal>
          <StackItem>
            <StackItem>
              {/* pages code starts here */}
              <Stack verticalAlign="center">
                {/* <StackItem> <Text> <Breadcrumbs pageName="Automation" subPageName="All Automations" subPageLink="manage/automation/all_automations" /> </Text> </StackItem> */}
                <Stack tokens={{childrenGap:25}} horizontal>
                    <StackItem><Text></Text></StackItem>
                    <StackItem>
                      <FluentProvider theme={webLightTheme}>
                        <Title3> Automation | All Automations </Title3> 
                      </FluentProvider>
                    </StackItem>
                    {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
                </Stack>
                <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                <FluentProvider theme={webLightTheme}>
                  <Stack horizontal>
                    {/* SPACING BETWEEN INLINE AND PAGE */}
                    <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
                    <StackItem>
                      <StackItem><Text></Text></StackItem>
                      <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                      <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                        <StackItem>
                          <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                          { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                        </StackItem>
                        <StackItem>
                          <StackItem>
                            <Stack horizontal tokens={{childrenGap:20}}>
                            <SearchBox onChange={(e: any) => { setSearchVal(e.target.value); }} value={searchVal} placeholder="Search" size="small" dismiss={null} contentAfter={<DismissButton aria-label="Clear"/>}/>

                              <StackItem>
                                {/* <MenuDropdown 
                                  btn={ 
                                    <MenuButton shape="circular" size="small">
                                      <Stack tokens={{childrenGap:5}} horizontal>
                                        <Filter20Filled {...primaryIcon}/>
                                        <Body1> Filter </Body1> 
                                      </Stack>
                                    </MenuButton>
                                  } 
                                  itemVal={
                                    <>
                                      <Body1Strong> Add a Filter </Body1Strong>
                                      <Divider/>
                                      <MenuList>
                                        <Stack tokens={{childrenGap:5}}>
                                          <StackItem><Text></Text></StackItem>
                                          <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                          <DropdownComponent optionData={columns}/>
                                          <StackItem> <Divider/> </StackItem>
                                          <StackItem> <Caption1> Value </Caption1> </StackItem>
                                          <DropdownComponent optionData={columns}/>
                                          <StackItem> <Divider /> </StackItem>
                                          <Stack tokens={{childrenGap:10}} horizontal>
                                            <StackItem> <Button appearance="primary"> <Caption1> Apply </Caption1> </Button> </StackItem>
                                            <StackItem> <Button> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                          </Stack>
                                        </Stack>
                                      </MenuList>
                                    </>
                                  }
                                /> */}
                              </StackItem>
                              {/* <StackItem>
                                <Button appearance="subtle" size="small">
                                  <Stack tokens={{childrenGap:5}} horizontal>
                                    <StackItem> <FilterDismiss20Filled/> </StackItem>
                                    <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                                  </Stack>
                                </Button>
                              </StackItem> */}
                            </Stack>
                          </StackItem>
                          <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem>
                            <StackItem>
                              <FluentProvider theme={messageTheme}>
                                <Card appearance="subtle" size="small">  
                                  <Stack tokens={{childrenGap:10}} horizontal>
                                    <StackItem> <Info20Filled {...primaryIcon}/> </StackItem>
                                    <StackItem> <Caption1> Explore our troubleshooting snippets for a smooth start. Pick a setup scenario, and we'll guide you through each step, handling assignments and other 
                                    configurations automatically. </Caption1> </StackItem>
                                  </Stack>
                                </Card>
                              </FluentProvider>
                            </StackItem>
                          </Stack>
                          {/* <Stack horizontal enableScopedSelectors horizontalAlign="space-between"> <StackItem> <Caption1>  Showing {((currentPageNo-1) * 10) + 1} to {(currentPageNo * 10) > totalRecords ? totalRecords : (currentPageNo * 10)} of {totalRecords} Records </Caption1> </StackItem> <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem> </Stack> */}
                        </StackItem>
                        
                        <Stack horizontal wrap> {cardTemplate} </Stack>
                      
                        {/* <All_automation_func 
                          set_final_data={set_final_data} 
                          searchVal={searchVal} 
                          isRefresh={isRefresh} 
                          setIsRefresh={setIsRefresh} 
                          setRefreshToast={setRefreshToast} 
                        /> */}

                      {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> {totalRecords > 0 && <Caption1>  Showing {((currentPageNo-1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1>} </StackItem>
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                      </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Caption1> No Results.</Caption1></StackItem></Stack>}

                        <Configuration_func pageName={pageName} data={final_data} func={set_final_data} setTotalRecords={setTotalRecords} setTotalPages={setTotalPages} pageNo={(currentPageNo - 1) * 50} searchVal={searchVal} isRefresh={isRefresh}  setRefresh={setIsRefresh} setRefreshToast={setRefreshToast}  />

                        <DataTable tableData={tableData} columns={columns} ></DataTable>
                        
                        {
                          tracker && 
                          <Activity_log_func 
                            data={{pageName: 'all automation', 
                            moduleName: "automation", 
                            action: 'view', 
                            time: Math.floor(Date.now() / 1000)}} 
                            setTracker={setTracker} 
                          />
                        }
                        {
                          refreshToast && 
                          <ToastComponent 
                            setmessagebar={setIsToast} 
                            isToastEnabled={refreshToast} 
                            toastIntent="success" 
                            position="top-end" 
                            title="Success" 
                            message={'Automation Refreshed'} 
                            footer="" 
                          />
                        }
                      </Stack>
                    </StackItem>
                  </Stack>
                </FluentProvider> 
              </Stack>
              {/* <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData="Census"/> */}
            </StackItem>
          </StackItem>
          <StackItem><Text></Text></StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};