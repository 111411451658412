import { gql } from '@apollo/client';

const getInstallStatus = gql`
  query MyQuery($whereCondition: JSON, $limit: Int!, $offset: Int! = 0) {
    softinst_Packages(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      app_packageId
      packageName
      packageDesc
      version
      platform
      status
      user
      lastModified
      creationTime
    }
  }
`;

const getCensusList = gql`
  query MyQuery($whereCondition: JSON!, $limit: Int!, $offset: Int! = 0, $order: JSON) {
    core_Census(where: $whereCondition, limit: $limit, offset: $offset, order_by: $order) {
      host
      site
      last
      id
      os
      clientversion
      born
    }
  }
`;
const getInstallStatusCnt = gql`
  query MyQuery($whereCondition: JSON) {
    softinst_Packages(where: $whereCondition) {
      id
      packageName
      packageDesc
      version
      platform
      status
      user
      lastModified
      status
    }
  }
`;
const getswdForm = gql`
  query MyQuery($app_packageiD: Int) {
    softinst_PackagesConfiguration_new(where: { packageId: { _eq: $app_packageiD } }) {
      formDataAdv
      formDataApp
      formDataPkg
    }
  }
`;
const getJsonSchemaQueryPackageDetails = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1501 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getJsonSchemaQueryAppConfiguration = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1502 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getJsonSchemaQueryAdvanceConfiguration = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1503 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
// const getDeviceClassificationList = gql `
//     query MyQuery($whereCondition: JSON!) {
//         core_DeviceClassification(where: $whereCondition) {
//       classificationName
//       isGroupConfigured
//     }
//   }
// `;
//query to get the final edconfig
const getEdconfigQuery = gql`
  query MyQuery($packageId: String) {
    softinst_PackagesConfiguration_new(where: { packageId: { _eq: $packageId } }) {
      edConfig
    }
  }
`;

//query to push client
const setExecuteTroubleShooter = gql`
  mutation AddRemoteJobs(
    $OS: String! = "unknow"
    $type: String!
    $Name: String!
    $Dart: Int = 288
    $variable: String!
    $shortDesc: String!
    $Jobtype: String! = "Software Distribution"
    $ProfileName: String!
    $NotificationWindow: Int = 0
    $ProfileOS: String! = "common"
  ) {
    AddRemoteJobs(
      OS: $OS
      type: $type
      name: $ame
      Dart: $Dart
      variable: $variable
      shortDesc: $shortDesc
      Jobtype: $Jobtype
      ProfileName: $ProfileName
      NotificationWindow: $NotificationWindow
      ProfileOS: $ProfileOS
    ) {
      code
      Duplicates
      ProgServiceTag
      ServiceTagNotSupported
      ServiceTagSupported
      bid
      notifyRes
      onlineOffline
      searchtype
    }
  }
`;

// mutation to push swdpackage to the client
//$userId: Int, $dart: Int,$machineName: String ,$packageDesc: String
const deployPackageJob = gql`
  mutation AddRemoteJobsResult($packageIdPush: Int!, $machineName: String!, $packageDescPush: String!) {
    AddRemoteJobs(
      userId: 1

      Dart: $packageIdPush

      name: $machineName

      NotificationWindow: 0

      OS: "windows"

      ProfileName: $packageDescPush

      ProfileOS: "common"

      shortDesc: "short description for you app"

      type: "ServiceTag"

      Jobtype: "Software Distribution"

      variable: "OSYNC"
    ) {
      code
    }
  }
`;

const getStatus = gql`
  query MyQuery {
    communication_Audit(order_by: { JobCreatedTime: desc }) {
      JobStatus
      MachineTag
      ClientExecutedTime
    }
  }
`;

// mutation AddRemoteJobsResult {

//   AddRemoteJobs(
//      userId:235,
//     Dart: 863,

//     name: "5CD1284DFG",

//     NotificationWindow: 0,

//     OS: "windows",

//     ProfileName: "IOT5",

//     ProfileOS: "common",

//     shortDesc: "IOT5",

//     type: "ServiceTag",

//     Jobtype: "Software Distribution",

//     variable: "OSYNC") {

//     code

//   }
//   }

export {
  getInstallStatus,
  getCensusList,
  getInstallStatusCnt,
  getswdForm,
  getJsonSchemaQueryPackageDetails,
  getJsonSchemaQueryAppConfiguration,
  getJsonSchemaQueryAdvanceConfiguration,
  getEdconfigQuery,
  setExecuteTroubleShooter,
  deployPackageJob,
  getStatus,
};
