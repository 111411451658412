
import { Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme, SkeletonItem } from '@fluentui/react-components';
import { skeleton4, skeleton3, signFormPosition } from '../../styles/customStyles';

export const SkeletonNewPassword = () => {
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15}} style={signFormPosition}>
          <SkeletonItem size={56} style={skeleton3}/>
          <SkeletonItem size={40} style={skeleton3}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={40} style={skeleton3}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={40} style={skeleton3}/>
          <SkeletonItem size={24} style={skeleton4}/>
          <SkeletonItem size={56} style={skeleton3}/>
          <SkeletonItem size={40} style={skeleton4}/>
        </Stack>
      </FluentProvider>
    </>
  );
};