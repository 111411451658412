import { AnySoaRecord } from 'dns'
import React, {useState, useEffect, createContext} from 'react'

type GlobalContextProviderProps = {
    children: React.ReactNode
}
 
type TypeContextType = {
    isRightMenuOpen: any
    setRightMenu: React.Dispatch<any>
 
    tenantName: any
    setTenantName: React.Dispatch<any>
 
    dartNo: any
    setDartNo: React.Dispatch<any>

    selectedRowIdInTable: any
    setSelectedRowIdInTable: React.Dispatch<any>

    selectedRowIdInSubTable: any
    setSelectedRowIdInSubTable: any

    automationDetails: any
    setAutomationDetails: any

    selectedRowDetails: any
    setSelectedRowDetails: React.Dispatch<any>

    userSessionDetails:any
    setUserSessionDetails : React.Dispatch<any>
} 

export const GlobalContext = createContext<TypeContextType | null>(null);
 
export const GlobalContextProvider = ({children}: GlobalContextProviderProps) => {
    var userDetails: any = localStorage.getItem('userDetails');
    userDetails = userDetails!= undefined ? JSON.parse(userDetails) : 
    {
        userid: '',
        firstName: '',
        lastName: '',
        user_email: '',
        formDataEdit: '{}'
    };

    const tenant = localStorage.getItem('tenant');
    
    var tempAutomationDetails: any = localStorage.getItem('automationDetails');
    tempAutomationDetails = tempAutomationDetails != undefined ? JSON.parse(tempAutomationDetails) : '';
    
    var tempName: any = tempAutomationDetails?.automationName;
    var automationName = (tempName != undefined && tempName.length > 0) ? tempName : '';

    var tempType: any = tempAutomationDetails?.automationType;
    var automationType = (tempType != undefined && tempType.length > 0) ? tempType : '';

    var dartno: any = localStorage.getItem('dartNo'); 
    dartno = (dartno != undefined && dartno.length > 0) ? parseInt(dartno) : 0;

    const [isRightMenuOpen, setRightMenu] = useState<TypeContextType | any>(false);
    const [tenantName, setTenantName] = useState<TypeContextType | any>(tenant != undefined ? tenant : '');
    const [dartNo, setDartNo] = useState<TypeContextType | any>(dartno); // for all_automation page
    // const [userName, setUserName] = useState<TypeContextType | any>('admin'); //need to get it on sign page
    const [selectedRowIdInTable, setSelectedRowIdInTable] = useState<TypeContextType | any>([]);
    const [selectedRowIdInSubTable, setSelectedRowIdInSubTable] = useState<TypeContextType | any>([]);
    // const [userEmail, setUserEmail] = useState<TypeContextType | any>({
    const [userSessionDetails, setUserSessionDetails] = useState<TypeContextType | any>({
        userId: userDetails.userid,
        userName: userDetails.firstName,
        userLName: userDetails.lastName,
        userEmail: userDetails.user_email,
        otherDetails: JSON.parse(userDetails.formDataEdit)
    });
    const [automationDetails, setAutomationDetails] = useState<TypeContextType | any>({
        automationName: automationName,
        automationType: automationType,
    });
    const [selectedRowDetails, setSelectedRowDetails] = useState<TypeContextType | any>({
        id: '',
        name: '',
    });

    return (<GlobalContext.Provider value={{
        isRightMenuOpen,
        setRightMenu,
        tenantName,
        setTenantName,
        dartNo,
        setDartNo,
        selectedRowIdInTable, 
        setSelectedRowIdInTable,
        selectedRowIdInSubTable,
        setSelectedRowIdInSubTable,
        automationDetails,
        setAutomationDetails,
        selectedRowDetails,
        setSelectedRowDetails,
        userSessionDetails,
        setUserSessionDetails,
    }} >{children}</GlobalContext.Provider>)
}  