import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupIdOfTenantQuery, getComponentsDetailsOfDartQuery, insertValueOfComponentQuery, updateValueOfComponentQuery, updateAutomationStautsQuery, deleteValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { useNavigate } from "react-router-dom";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
}

const Policy_attach_func = (props: any) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const contextValue: any = useContext(GlobalContext);
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    // var mgroupuniq: any = {};
    var insertCounter = 0;
    var totalGrpDataCnt = 0;
    var failedAttachmentGroups: any = [];
    var failedDetachmentGroups: any = [];
    var mcatuniq = '';
    var selctedValue = '';
    
    var automationData = {
        id: '',
        automation_name : '',
        automation_description : '',
        dartNo : '',
        category : '',
        category_description : '',
        subCategory : '',
        subCategory_description : '',
        os : '',
        automationSequence : '',
        clientEnabled: false,
        status: '',
        sequenceIDs: '',
    };

    var baseProfilesData : any = {};
    var profileSequenceData : any = {};
    var sequenceDetailsData : any = {};
    var variableConfigData: any = {};
    var serviceLogMasterData: any = {};

    var subAutomation: any = [];

    const countOccurrences = (inputString: any, subString: any) => {
        let count = 0;
        let index = inputString.indexOf(subString);
      
        while (index !== -1) {
          count++;
          index = inputString.indexOf(subString, index + 1);
        }
      
        return count;
    }

    const getAutomationList = async(whereCondition: any, limit: any = null, offset: any = null, name: String = '') => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data){   
            subAutomation = [...subAutomation,
                {
                    id: data.core_AutomationJSON[0]?.id,
                    dartId: data.core_AutomationJSON[0]?.dartid,
                    automationName: data.core_AutomationJSON[0]?.automationName,
                    jsonVal: JSON.parse(data.core_AutomationJSON[0]?.varValues),
                }
            ];
            
            automationData.id = data.core_AutomationJSON[0]?.id;
            automationData.automation_name = data.core_AutomationJSON[0]?.automationName;
            automationData.automation_description = data.core_AutomationJSON[0]?.description;
            automationData.dartNo = data.core_AutomationJSON[0]?.dartid;
            automationData.status = data.core_AutomationJSON[0]?.status;
            automationData.category = data.core_AutomationJSON[0]?.category;
            automationData.subCategory = '';
            automationData.os = "common";
            automationData.automationSequence = data.core_AutomationJSON[0]?.id;
            automationData.clientEnabled = false;
        }
    }
    
    //to check site/group is configured or not
    const getGroupIdOfTenant = async (whereCondition: any, action: any) => {
        const { data, loading, error } = await client.query({
            query: getGroupIdOfTenantQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            totalGrpDataCnt = data.core_MachineGroups.length;
            data.core_MachineGroups.map(async (val: any) => {
                insertCounter++;
                mcatuniq = val.mcatuniq;
                await getComponentsDetailsOfDart([304, 306], val.mgroupuniq, val.name);

                if(action == 'detach'){
                    console.log("deleteing...........");
                    
                    setTimeout(async () => {
                        removeValueInServiceLogComponent(val.mgroupuniq, val.name);
                    }, 2000);
                } 
                
                if(action == 'attach'){
                    console.log("inserting...........");
    
                    setTimeout( () => {
                        setValueOfServiceLogComponent(val.mgroupuniq, val.name);
                    }, 2000);
                }
            });
        }else{
            console.log("Site has not configured");

            props.setmessagebar1(false);
            props.setmessagebar2(true);
            props.setToastMessage("Assignment failed");
        }
    }

    //to fetch the name, varnameuniq, varscopuniq, varuniq of components in dart 304
    var componentName = '';
    const getComponentsDetailsOfDart = async (dartId: any, groupId: any, name: String) => {
        
        const { data, loading, error } = await client.query({
            query: getComponentsDetailsOfDartQuery,
            variables: { dartId, groupId },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            data.core_Variables.map((val: any) => {
                if(val.name == 'S00304_BaseProfiles'){
                    componentName = 'baseProfile';

                    var baseProfilesIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    baseProfilesData[`${name}`] = baseProfilesIds;
                }else if (val.name == 'S00304_ProfileSequence'){
                    componentName = 'profileSequence';

                    var profileSequenceIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    profileSequenceData[`${name}`] = profileSequenceIds;
                }else if(val.name == 'S00304_VariableIdConfig'){
                    var variableConfigIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    variableConfigData[`${name}`] = variableConfigIds;
                }else if (val.name == 'S00304_SequenceDetails'){
                    var sequenceDetailsIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    sequenceDetailsData[`${name}`] = sequenceDetailsIds;
                }else if(val.name === 'S00306_ServiceLogMaster'){
                    var serviceLogMasterIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    serviceLogMasterData[`${name}`] = serviceLogMasterIds;
                }
            });
        }
    }

    const setValueOfServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var serviceLogMasterVal = '';
        var newVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`].componentValue != undefined){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');
                serviceLogMasterArr = serviceLogMasterArr.filter((val: any) => val !== '');
    
                var serviceLogMasterLastVal = serviceLogMasterArr[(serviceLogMasterArr?.length - 1)];
    
                var lastSequenceId: any = serviceLogMasterLastVal?.split("#NXT#")[0];
                lastSequenceId = parseInt(lastSequenceId);
                lastSequenceId = isNaN(lastSequenceId) ? 0 : lastSequenceId;
    
                lastSequenceId++;
    
                newVal = `\n${lastSequenceId}#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
    
                newVal = serviceLogMasterVal + newVal;
            }else{
                var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
                newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

                newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

                newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

                newVal += `5#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
            }

            // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));
        }else{
            var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
            newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

            newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

            newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

            newVal += `5#NXT#286#NXT#${automation_name}#NXT#[${varValue}]#NXT#${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;

            await insertValueOfComponent(newVal, 0, serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq);
        }
        
        setValueOfBaseProfileComponent(mgroupuniq, name);
    }

    const setValueOfBaseProfileComponent = async (mgroupuniq: any, name: String) => {   
        var automation_name = automationData.automation_name;
        var automation_description = automationData.automation_description;
        var category = automationData.category;
        var category_description = "This automation streamlines the process of configuring policies for device management, allowing for efficient and consistent deployment of settings policies across multiple devices";
        var os = automationData.os;
        var automationSequence = automationData.automationSequence;
        var varValue = automation_name?.split(" ").join("");
        var clientEnabled = automationData.clientEnabled ? 1 : 3;
    
        console.log("insert baseProfile component loop entered");
        console.log("namee=",name);
        console.log("mgroupuniqq=",mgroupuniq);
        
        var newVal: String = '';
        var baseProfileVal = '';
        var page = '';
        var categoryPage: any = '';
        var subCategoryPage: any = '';
        var catL1 = '';
        var catL2 = '';
        var subCatL1 = '';
        var subCatL2 = '';
        var L3 = '';
        var mid: any = 0;

        if(baseProfilesData[`${name}`].varuniq?.length > 0 && baseProfilesData[`${name}`]?.componentValue != undefined){ // to check if baseprofile is present for particluar site/group in varvalues table 
            var res = baseProfilesData[`${name}`].componentValue;
            var revised = baseProfilesData[`${name}`].revised;

            //structuring value for baseprofile component starts here
                
            baseProfileVal = res?.trim();
            var baseProfileArr = baseProfileVal?.split('#F#NXT#1#NXT#0');
                
            baseProfileArr = baseProfileArr.filter(element => element !== '');
            var baseProfileArr1 = baseProfileArr[(baseProfileArr.length - 1)];
            mid = parseInt(baseProfileArr1?.split('#NXT#')[1]);
            mid = isNaN(mid) ? 0 : mid;

            var pageArr: any = [];
            // console.log("cat=",category);
            // console.log("old val=",baseProfileVal);
            
            if(baseProfileVal.includes(category + '#NXT#L1')){ // if user defined category is present in baseprofile of varvalues table
                // console.log("inside cat");
                
                pageArr = baseProfileArr.filter((val) => val.includes(category + '#NXT#L1'));
                categoryPage = pageArr[0]?.split('#NXT#')[13];
                
                mid++;
                L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;

                newVal = baseProfileVal + "\n" + L3;
            }else{ //if user defined category is not present in baseprofile of varvalues table
                categoryPage = countOccurrences(baseProfileVal,'L1');
                // console.log("outside cat");

                mid++;
                catL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#common#NXT#${categoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`+ "\n";

                mid++;
                catL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${categoryPage + 1}#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

                mid++;
                L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage + 1}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage + 1}#NXT#enable#NXT#F#NXT#1#NXT#0`;

                if(baseProfileVal.length > 0){
                    newVal = baseProfileVal + "\n" + catL1 + catL2 + L3;
                }else{
                    newVal = catL1 + catL2 + L3;
                }
            }

            // console.log("basep=",catL1 + catL2 + L3);

            // await updateValueOfComponent(newVal, (revised + 1), baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq, baseProfilesData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: baseProfilesData[`${name}`].varuniq}, varscopuniq: {_eq: baseProfilesData[`${name}`].varscopuniq}}, newVal, (revised + 1));
                
        }else{ //if baseprofile is not present for particluar site/group in varvalues table
            // console.log("else");

            baseProfileVal = `Enable/Disable#NXT#mid#NXT#menuItem#NXT#type#NXT#parentId#NXT#profile#NXT#dart#NXT#variable#NXT#varValue#NXT#shortDesc#NXT#description#NXT#tileDesc#NXT#OS#NXT#page#NXT#status#NXT#authFalg#NXT#usageType#NXT#dynamic\n1#NXT#1#NXT#home#NXT#L0#NXT#0#NXT#Profile#NXT#null#NXT#null#NXT#null#NXT#Profile#NXT#NA#NXT#Profile#NXT#common#NXT#0#NXT#disable#NXT#F#NXT#1#NXT#0\n1#NXT#2#NXT#system info#NXT#L0#NXT#0#NXT#system info#NXT#null#NXT#null#NXT#null#NXT#system info#NXT#NA#NXT#system info#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#3#NXT#settings#NXT#L0#NXT#0#NXT#settings#NXT#null#NXT#null#NXT#null#NXT#settings#NXT#NA#NXT#settings#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#4#NXT#toolbox#NXT#L0#NXT#0#NXT#TOOLBOX#NXT#null#NXT#null#NXT#null#NXT#toolbox#NXT#NA#NXT#toolbox#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#5#NXT#service log#NXT#L0#NXT#0#NXT#service log#NXT#null#NXT#null#NXT#null#NXT#Service Log#NXT#NA#NXT#Service Log#NXT#common#NXT#0#NXT#enable#NXT#F#NXT#1#NXT#0\n1#NXT#6#NXT#Troubleshooters#NXT#L1#NXT#1#NXT#Troubleshooters#NXT#null#NXT#null#NXT#null#NXT#Troubleshooters#NXT#NA#NXT#You can fix many common issues easily with these powerful troubleshooting tools. Choose a category and then select the fix that matches the symptoms of the problem.#NXT#common#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            categoryPage = 3;
            mid = 8;

            catL1 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L1#NXT#1#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            mid++;
            catL2 = `${clientEnabled}#NXT#${mid}#NXT#${category}#NXT#L2#NXT#${categoryPage}#NXT#${category}#NXT#null#NXT#null#NXT#null#NXT#${category}#NXT#NA#NXT#${category_description}#NXT#${os}#NXT#2#NXT#enable#NXT#F#NXT#1#NXT#0` + "\n";

            mid++;

            L3 = `${clientEnabled}#NXT#${mid}#NXT#${automation_name}#NXT#L3#NXT#${categoryPage}#NXT#${automation_name}#NXT#286#NXT#S00286SeqRunNow#NXT#${varValue}#NXT#${automation_name}#NXT#NA#NXT#${automation_description}#NXT#${os}#NXT#${categoryPage}#NXT#enable#NXT#F#NXT#1#NXT#0`;

            baseProfileVal = baseProfileVal + catL1 + catL2 + subCatL1 + subCatL2 + L3;

            await insertValueOfComponent(baseProfileVal, 0, baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq);
        }
        //structuring value for baseprofile component ends here

        await setValueOfProfileSequenceComponent(mgroupuniq, name);
    };

    const setValueOfProfileSequenceComponent = async (mgroupuniq: any, name: String) => {
        
        console.log("insert ProfileSequenceComponent component");
        
        var automation_name = automationData.automation_name;
        var automationSequence = automationData.automationSequence;
        var varValue = automation_name?.split(" ").join("");
        var profileSequence = '';
        var newVal: String = '';
        var newSequenceID: any = '';
        
        //structuring value for profilesequence component ends here
        if(profileSequenceData[`${name}`].varuniq?.length > 0 && profileSequenceData[`${name}`].componentValue != undefined){
            // console.log("profile seq updated");

            var res = profileSequenceData[`${name}`].componentValue;
            var revised = profileSequenceData[`${name}`].revised;

            profileSequence = res?.trim();
            if(profileSequence?.length > 0){
                var profileSequenceArr = profileSequence.split("\r\n");
                var lastSequenceArr = profileSequenceArr[profileSequenceArr.length - 1]?.split(":");
                var sequenceIDsArr: any = lastSequenceArr[lastSequenceArr.length - 1]?.split(",");
                var lastSequenceId = sequenceIDsArr[sequenceIDsArr.length - 1];
                
                lastSequenceId = parseInt(lastSequenceId);
                newSequenceID = ++lastSequenceId;
                automationData.sequenceIDs = newSequenceID;
                
                newVal = profileSequence + "\r\n" + `${varValue}:${newSequenceID}`;
            }else{
                automationData.sequenceIDs = '1';
                newVal = `${varValue}:1`;
            }

            // console.log("profile seq val =",newVal);

            // await updateValueOfComponent(newVal, (revised + 1), profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq, profileSequenceData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSequenceData[`${name}`].varuniq}, varscopuniq: {_eq: profileSequenceData[`${name}`].varscopuniq}}, newVal, (revised + 1) );
        }else{
            // console.log("profile seq inserted");
            automationData.sequenceIDs = '1';

            await insertValueOfComponent(`${varValue}:1`, 0, profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq);
        }
        //structuring value for profilesequence component ends here

        await setValueOfVariableConfigComponent(mgroupuniq, name);
    }

    var vidArr: any = [];
    const setValueOfVariableConfigComponent = async(mgroupuniq: any, name: String) => {
        
        console.log("insert setValueOfVariableConfigComponent");

        var automationSequence = automationData.automationSequence;
        var varValue = "CustVal1";
        var newVal: String = '';
        var res: any = '';
        var revised: any = 0;
        var lastVid: any = 0;

        //await getAutomationList({id: {_in: automationSequence?.split(',').map(Number)}}, null, null, 'sequenceConfig'); //get the jsonVal of autmation sequence ids

        if(variableConfigData[`${name}`].componentValue != undefined && variableConfigData[`${name}`].componentValue?.length > 0){ 
            res = variableConfigData[`${name}`].componentValue;
            revised = variableConfigData[`${name}`].revised;

            var variableConfigArr = res?.split('#NXTVAR#');
            var arrLastVal: any = variableConfigArr[variableConfigArr.length - 1];
            arrLastVal = arrLastVal?.split("\n").filter((val: any) => val.includes('VID'))[0];

            lastVid = parseInt(arrLastVal?.split(':')[1]);
            newVal =  res;
        }   
           
        // subAutomation.map((ele: any) => {
        //     vidArr = [];
        //     var items = Object.keys(ele.jsonVal);
        //     var val = JSON.stringify(ele.jsonVal)
            
        //     lastVid++;
        //     vidArr.push(lastVid)

        //     var vid = `VID:${lastVid}\n`;
        //     var vn = `VN:${varValue}\n`; 
        //     var value = `VALUE:${val}`;
    
        //     if(newVal.length > 0){
        //         newVal +=  "\n#NXTVAR#\n" + vid + vn + value;
        //     }else{
        //         newVal += vid + vn + value;
        //     }

        //     lastVid++;
        //     vidArr.push(lastVid);
            
        //     vid = `VID:${lastVid}\n`;
        //     vn = `VN:AskAgain\n`; 
        //     value = `VALUE:2`;

        //     newVal +=  "\n#NXTVAR#\n" + vid + vn + value; //execute btn varvaule
        //     ele.vids = vidArr;
        // });

        subAutomation.map((ele: any) => {
            vidArr = [];
            var items = Object.keys(ele.jsonVal);
            items.map((val: any) => {
                lastVid++;
                vidArr.push(lastVid);
                
                var vid = `VID:${lastVid}\n`;
                var vn = `VN:${val}\n`; 
                var value = `VALUE:${ele.jsonVal[val]}`;
    
                if(newVal?.length > 0){
                    newVal +=  "\n#NXTVAR#\n" + vid + vn + value;
                }else{
                    newVal += vid + vn + value;
                }
            });
            ele.vids = vidArr;
        });
           
        if(variableConfigData[`${name}`].varuniq?.length > 0 && variableConfigData[`${name}`].componentValue != undefined){
           //await updateValueOfComponent(newVal, (revised + 1), variableConfigData[`${name}`].varnameuniq, variableConfigData[`${name}`].varscopuniq, variableConfigData[`${name}`].varuniq, mgroupuniq, variableConfigData[`${name}`].id);

           await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: variableConfigData[`${name}`].varuniq}, varscopuniq: {_eq: variableConfigData[`${name}`].varscopuniq}}, newVal, (revised + 1));
        }else{
            await insertValueOfComponent(newVal, 0, variableConfigData[`${name}`].varnameuniq, variableConfigData[`${name}`].varscopuniq, variableConfigData[`${name}`].varuniq, mgroupuniq);
        }

        await setValueOfSequenceDetailsComponent(mgroupuniq, name);
    }

    const setValueOfSequenceDetailsComponent = async(mgroupuniq: any, name: String) => {
        console.log("insert ProfileSequenceComponent component");

        var automation_name = automationData.automation_name;
        var dartId = automationData.dartNo;
        var os = automationData.os;
        var varValue = automation_name?.split(" ").join("");
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var status: any = false;

        if(sequenceDetailsData[`${name}`].varuniq?.length > 0 && sequenceDetailsData[`${name}`].componentValue != undefined){ 
            var res =  sequenceDetailsData[`${name}`].componentValue;
            var revised = sequenceDetailsData[`${name}`].revised;
            var SequenceDetails = res;

            if(SequenceDetails.length > 0){
                var sequenceDetailsArr = res?.split('\n');
                var sequenceIdArr = sequenceDetailsArr.map((val: any) => val.split(",")[0]);
                
                subAutomation.map((val: any) => {
                    if(!(sequenceIdArr.map(Number).includes(parseInt(val.id)))){
                        //syntax: sequenceNo,dartNo,Automation name,vid:vid:vid...
                        SequenceDetails += "\n" + `${automationData.sequenceIDs},288,${val.automationName},${(val.vids).join(":")}`;
                    }
                });
            }else{
                subAutomation.map((val: any) => {
                    SequenceDetails += `${automationData.sequenceIDs},288,${val.automationName},${(val.vids).join(":")}`;
                });
            }

            // status = await updateValueOfComponent(SequenceDetails, (revised + 1), sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq, sequenceDetailsData[`${name}`].id);

            status = await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: sequenceDetailsData[`${name}`].varuniq}, varscopuniq: {_eq: sequenceDetailsData[`${name}`].varscopuniq}}, SequenceDetails, (revised + 1));
        }else{
            var SequenceDetails: any = '';

            subAutomation.map((val: any, i: any) => {
                SequenceDetails += i == 0 ? `${automationData.sequenceIDs},288,${val.automationName},${(val.vids).join(":")}` : "\n" + `${automationData.sequenceIDs},288,${val.automationName},${(val.vids).join(":")}`;
            });

            status = await insertValueOfComponent(SequenceDetails, 0, sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq);
        }

        console.log("insert status=",status,"totalGrpDataCnt=",totalGrpDataCnt,"insertCounter=",insertCounter);
        
        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;
            
            if(selctedValue == 'tenant'){
        
                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":all";
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:all`);

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `${tenantName}:all`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:all}`;
                }
            }else{
                var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedAttachmentGroups.includes(val)));

                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":" + selectedGroups.join(",");
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1];

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp = automationStatus.split(":")[1];

                        selctedValue = `${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:${selectedGroups.join(",")}`; 
                }
            }
            
            await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, 0, 'attach');
        }
    }

    const removeValueInServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var serviceLogMasterVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`]?.componentValue?.length > 0){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');

                var newVal = serviceLogMasterArr.filter((val: any) => !(val.includes('#NXT#286#NXT#') && val.includes(`#NXT#[${varValue}]#NXT#`))).join('#NXT#Tile');

                // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

                await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));
            }
        }

        removeValueInBaseProfileComponent(mgroupuniq, name);
    }

    const removeValueInBaseProfileComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
    
        console.log("remove baseProfile component entered");
        var baseProfileVal = '';

        if(baseProfilesData[`${name}`].varuniq?.length > 0 && baseProfilesData[`${name}`]?.componentValue?.length > 0){ // to check if baseprofile is present for particluar site/group in varvalues table 
            var res = baseProfilesData[`${name}`].componentValue;
            var revised = baseProfilesData[`${name}`].revised;

            baseProfileVal = res?.trim();
            var baseProfileArr: any = baseProfileVal?.split('#F#NXT#1#NXT#0').filter((element: any) => element !== '');

            var detachedPageNo = baseProfileArr.filter((ele: any) => ele.includes('L3') && ele.includes(varValue))[0]?.split('#NXT#')[13];
            
            //detaching L3
            baseProfileArr = baseProfileArr.filter((ele: any) => !(ele.includes('L3') && ele.includes(varValue)));

            var otherL3s = baseProfileArr.filter((element: any) => element.includes('L3') && element.includes(`#NXT#${detachedPageNo}#NXT#enable#NXT`));

            if(otherL3s.length == 0){
                baseProfileArr = baseProfileArr.filter((ele: any) => !ele.includes('#NXT#User Survey#NXT'));
            } 

            baseProfileArr = baseProfileArr.join("#F#NXT#1#NXT#0");
            
            // await updateValueOfComponent(baseProfileArr, (revised + 1), baseProfilesData[`${name}`].varnameuniq, baseProfilesData[`${name}`].varscopuniq, baseProfilesData[`${name}`].varuniq, mgroupuniq, baseProfilesData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: baseProfilesData[`${name}`].varuniq}, varscopuniq: {_eq: baseProfilesData[`${name}`].varscopuniq}}, baseProfileArr, (revised + 1));
        }
        await removeValueInProfileSequenceComponent(mgroupuniq, name);
    }

    const removeValueInProfileSequenceComponent = async (mgroupuniq: any, name: String) => {
        console.log("remove ProfileSequenceComponent component entered");
        
        console.log("grped name=",name);
        console.log("mgroupuniq=",mgroupuniq);
        
        var automation_name = automationData.automation_name;
        
        var varValue = automation_name?.split(" ").join("");
        var profileSequence = '';
        var newVal: String = '';

        //structuring value for profilesequence component ends here
        if(profileSequenceData[`${name}`].varuniq?.length > 0 && profileSequenceData[`${name}`].componentValue?.length > 0){
            var res = profileSequenceData[`${name}`].componentValue;
            var revised = profileSequenceData[`${name}`].revised;

            profileSequence = res?.trim();
            if(profileSequence?.length > 0){
                var tempArr: any = profileSequence.split("\r\n").filter((val: any) => val.includes(`${varValue}:`))[0].split(":");
                automationData.sequenceIDs = tempArr[tempArr.length - 1];

                newVal = profileSequence.split("\r\n").filter((val: any) => !(val.includes(`${varValue}:`))).join("\r\n");
            }

            // await updateValueOfComponent(newVal, (revised + 1), profileSequenceData[`${name}`].varnameuniq, profileSequenceData[`${name}`].varscopuniq, profileSequenceData[`${name}`].varuniq, mgroupuniq, profileSequenceData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: profileSequenceData[`${name}`].varuniq}, varscopuniq: {_eq: profileSequenceData[`${name}`].varscopuniq}}, newVal, (revised + 1));
        }
        //structuring value for profilesequence component ends here
        
       await removeValueInSequenceDetailsComponent(mgroupuniq, name);
    }

    var varIds: any = [];
    const removeValueInSequenceDetailsComponent = async (mgroupuniq: any, name: String) => {
        console.log("remove Sequence DetailsComponent component entered");

        var automationSequence = automationData.automationSequence;

        if(sequenceDetailsData[`${name}`].varuniq?.length > 0 && sequenceDetailsData[`${name}`].componentValue?.length > 0){ 
           // await getAutomationList({id: {_in: automationSequence?.split(',').map(Number)}}, null, null, 'sequenceConfig'); 

            var res = sequenceDetailsData[`${name}`].componentValue;
            var revised = sequenceDetailsData[`${name}`].revised;
            var SequenceDetails = '';
            var sequenceDetailsArr = res?.split('\n');
            var tempVal: any = [];

            subAutomation.map((val: any, i: any) => {
                tempVal = sequenceDetailsArr.filter((data: any) => data.includes(`${automationData.sequenceIDs},${val.dartId},${val.automationName}`))[0];
                tempVal = tempVal?.split(",");
                tempVal[tempVal?.length - 1]?.split(':').map((val: any) => {
                    varIds.push(`VID:${val}`);
                })

                sequenceDetailsArr = sequenceDetailsArr.filter((data: any) => !data.includes(`${automationData.sequenceIDs},${val.dartId},${val.automationName}`));
            });

            SequenceDetails = sequenceDetailsArr.join("\n");
            
            // await updateValueOfComponent(SequenceDetails, (revised + 1), sequenceDetailsData[`${name}`].varnameuniq, sequenceDetailsData[`${name}`].varscopuniq, sequenceDetailsData[`${name}`].varuniq, mgroupuniq, sequenceDetailsData[`${name}`].id);

            await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: sequenceDetailsData[`${name}`].varuniq}, varscopuniq: {_eq: sequenceDetailsData[`${name}`].varscopuniq}}, SequenceDetails, (revised + 1));
        }

        await removeValueInVariableConfigComponent(mgroupuniq, name);
    }
    
    const removeValueInVariableConfigComponent = async (mgroupuniq: any, name: String) => {
        console.log("remove Variable ConfigComponent component entered");

        var dartId = automationData.dartNo;
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var status = false;

        if(variableConfigData[`${name}`].componentValue?.length > 0){ 
            var res = variableConfigData[`${name}`].componentValue;
            var revised = variableConfigData[`${name}`].revised;
            var newVal: any = '';
            var variableConfigArr: any = res?.split('\n#NXTVAR#');

            // console.log("variableConfigArr=",variableConfigArr);
            // console.log("varIds=",varIds);
            
            varIds.map((ids: any) => {
                variableConfigArr = variableConfigArr.filter((val: any) => !val.includes(ids));
            })
            newVal = variableConfigArr.join("\n#NXTVAR#");

            // console.log("newVal=",newVal);

            // status = await updateValueOfComponent(newVal, (revised + 1), variableConfigData[`${name}`].varnameuniq, variableConfigData[`${name}`].varscopuniq, variableConfigData[`${name}`].varuniq, mgroupuniq, variableConfigData[`${name}`].id);

            status =  await updateValueOfComponentss({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: variableConfigData[`${name}`].varuniq}, varscopuniq: {_eq: variableConfigData[`${name}`].varscopuniq}}, newVal.trim(), (revised + 1));
        } 

        console.log("remove status=",status,"totalGrpDataCnt=",totalGrpDataCnt,"insertCounter=",insertCounter);

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;

            if(selctedValue == 'tenant'){
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `not attached`;
                    }
                }else{
                    selctedValue = automationStatus;
                }
            }else{
                var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedDetachmentGroups.includes(val)));
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1].split(",");

                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        curSelectedGRp.length > 0 && statusArr.push(`${tenantName}:${curSelectedGRp.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp: any = automationStatus.split(":")[1].split(",");
                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        if(curSelectedGRp.length == 0){
                            selctedValue = 'not attached';
                        }else{
                            selctedValue = `${tenantName}:${curSelectedGRp.join(",")}`;
                        }
                    }
                }else{
                    selctedValue = automationStatus; 
                }
            }
            // console.log("selctedValue=",selctedValue);

            let flag = props.groupAttachment?.length ? props.groupAttachment?.length : 0;
            
           await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, flag, 'detach');
            
            // if(props.groupAttachment.length > 0){
            //     automationData.status = selctedValue;
            //     setTimeout(() => {
            //         attachRemediation();
            //     }, 1000);
            // }
        }
    }

    const insertValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any ) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: insertValueOfComponentQuery,
          variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
    
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const deleteValueOfComponent = async (valueid: any) => {
        const { data } = await client.mutate({
            mutation: deleteValueOfComponentQuery,
            variables: {valueid}
        });

        if(data.delete_core_VarValues.affected_rows > 0){
            return true
        }
    }

    const updateValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any, valueid: any) => {
        console.log("valueid=",valueid);
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
            mutation: insertValueOfComponentQuery,
            variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
        
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponentss = async (whereCondition: any, valu: String, revl: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: updateValueOfComponentQuery,
          variables: {whereCondition, valu, revl, lastchange}
        });
    
        if(data.update_core_VarValues.affected_rows > 0){
            console.log("component value updated");
            return true;
        }else{
            return false;
        }
    };

    const updateAutomationStauts = async (whereCondition: any, status: any, flag: any, type: String) => { 
        const { data } = await client.mutate({
            mutation: updateAutomationStautsQuery,
            variables: { whereCondition, status },
            fetchPolicy: 'network-only',
        });

        if(data.update_core_AutomationJSON.affected_rows > 0){
            if(flag == 0){
                if(type == 'attach'){
                    props.setToastMessage(`Automation attached to ${props.groupAttachment[0]} ${props.groupAttachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);
                    
                    props.setAllAttachmentGrp((prev: any) => [...prev.slice(1)]);
                }else if(type == 'detach'){
                    props.setToastMessage(`Automation detached from ${props.groupDetachment[0]} ${props.groupDetachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);

                    props.setAllDetachmentGrp((prev: any) => [...prev.slice(1)]);
                } 

                props.setToastMessage("Assignment Details have been revised");
                props.setmessagebar1(false);
                props.setmessagebar2(true);

                // setTimeout(() => {
                //     navigate("/manage/devices/usersurvey");
                // }, 5000);
            }
        }
    }

    const attachRemediation = async () => {
        var selected = contextValue.selectedRowIdInTable;
        var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

        if(selected.includes('All Devices')){
            selctedValue = 'tenant';
            await getGroupIdOfTenant({name: {_eq: tenantName}}, 'attach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification'; 
            await getGroupIdOfTenant({name: {_in: selectedGroups}}, 'attach');
        }
    };

    const detachRemediation = () => {
        var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');

        if(props.groupDetachment.includes('All Devices')){
            selctedValue = 'tenant';
            getGroupIdOfTenant({name: {_eq: tenantName}}, 'detach');
        }

        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification';
            getGroupIdOfTenant({name: {_in: selectedGroups}}, 'detach');
        }
    };

    const modifyRemediation = async () => {
        await getAutomationList({dartid: {_eq: dartNo}, automationName: {_eq: automationName}});

        if(props.groupDetachment.length > 0){
            detachRemediation();
        }else if(props.groupAttachment.length > 0){
            attachRemediation();
        }
    }
  
    useEffect(() => {   
        modifyRemediation();
    },[props.saveBtnClicked, props.groupAttachment, props.groupDetachment]);

    return (
        <></>
    )
}

export { Policy_attach_func }