import { useState, useEffect } from "react";
import { Text } from "@fluentui/react-components";
import { WidgetProps } from '@rjsf/utils';
import { MultiselectWithTags } from "../dropdown/multiselect.component";

export const MultipleSelectToString = (props: WidgetProps) => {
  let options: any = {};

  const [opt, setOpt] = useState(null);
  const [selectedOptions, setSelectedOptions]: any = useState([]);
  
  console.log("props: ", props);
  console.log("props?.uiSchema: ", props?.schema);
  const array = props?.schema?.enumNames;

  // if (props?.uiSchema && props?.uiSchema['ui:options']) {options = props.uiSchema['ui:options'] || {}}
  // const separator = options.lineSeparator || ',';
  // const array = options.values.split(',');

  useEffect(() => { console.log("Compo Selected Options: ", selectedOptions);}, [selectedOptions]);

  return (
    <>
      <Text> {props.label} </Text>
      <MultiselectWithTags options={array} multi={true} tag={true} disabled={props?.disabled ? true : false} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
    </>
  );
}