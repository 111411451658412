import { useState, useEffect, useContext } from 'react';
import { Census_func } from '../../../api/devices/census_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
} from '@fluentui/react-components';
import {
  PulseSquare20Regular,
  AppsAddIn20Regular,
  BoxCheckmark20Regular,
  UninstallApp20Regular,
  CheckboxCheckedSync20Regular,
  SquareHintApps20Regular,
  ShieldLock20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  SlideEraser20Regular,
  Copy20Regular,
  AddSquare20Regular,
  Dismiss16Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { exitIcon, fullscreenIcon, lightTheme, overflowDivider, primaryIcon } from '../../../styles/customStyles';
import { GlobalContext } from '../../../hooks/globalContext';
import { SkeletonContent } from '../../../components/skeleton/skeletonContent';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';
import client from '../../../api/apollo';
import { getPinot_d312_singleAsset_v1_machine } from '../../../graphql/pinot';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Discoveredapps_list = () => {
  const navigate = useNavigate();

  const contextValue: any = useContext(GlobalContext);
  const tenantName = contextValue.tenantName;

  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [response, setresponse] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [permissions, setPermissions] = useState(['']);
  console.log('checking the permissions------>', permissions);

  const handleRefresh = () => {
    setRefreshing(true);
  };

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };
  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  var menuItems: any = [];

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appmonitor')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <PulseSquare20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App&nbsp;Monitored</Caption1>{' '}
        </StackItem>
        <StackItem>
          {' '}
          <Stack tokens={{ childrenGap: 48 }} horizontal>
            {' '}
            <StackItem>
              <Text />
            </StackItem>{' '}
            <StackItem>
              <Text />
            </StackItem>{' '}
          </Stack>{' '}
        </StackItem>
        {/* SPACING IN INLINE SIDEBAR */}
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appinstallation')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <AppsAddIn20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Installation </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appinstallstatus')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <BoxCheckmark20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Install Status </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appuninstallprofile')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <UninstallApp20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Uninstall Profiles </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appuninstallstatus')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <CheckboxCheckedSync20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Uninstall Status </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/manage/apps/discoveredapps')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text />
            </StackItem>
            <StackItem>
              {' '}
              <SquareHintApps20Regular {...primaryIcon} />{' '}
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <Caption1> Discovered Apps </Caption1>{' '}
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/appprotectionpolicies')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <ShieldLock20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> App Protection Policies </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/apps/activitylogg')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text />
          </StackItem>
          <StackItem>
            {' '}
            <BookTemplate20Regular {...primaryIcon} />{' '}
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text />
        </StackItem>
        <StackItem>
          {' '}
          <Caption1> Activity Log </Caption1>{' '}
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'machine', label: <Body1Strong> Machine Name </Body1Strong> },
    { key: 'totalDev', label: <Body1Strong> Size in kb </Body1Strong> },
    { key: 'sizekb', label: <Body1Strong> Installed Date </Body1Strong> },
  ];
  //data table column name code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <Stack tokens={{ childrenGap: 6 }}>
            <StackItem>
              <Text />
            </StackItem>
            <StackItem>
              <Button appearance="subtle" size="small" onClick={handleRefresh}>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    {' '}
                    <ArrowClockwise20Regular {...primaryIcon} />{' '}
                  </StackItem>
                  <StackItem>
                    {' '}
                    <Caption1> Refresh </Caption1>{' '}
                  </StackItem>
                </Stack>
              </Button>
              {/* <CSVLink id="csv-export-link" data={exportData} filename="discoceredappslist.csv">
                <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink> */}

              {permissions.includes('discoverApps_Read') ? (
                <TooltipHost content="This feature is not available for you!">
                  <Button appearance="subtle" size="small">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {' '}
                        <ArrowDownload20Regular {...primaryIcon} />{' '}
                      </StackItem>
                      <StackItem>
                        {' '}
                        <Caption1> Export </Caption1>{' '}
                      </StackItem>
                    </Stack>
                  </Button>
                </TooltipHost>
              ) : permissions.includes('discoverApps_Fullaccess') || permissions.includes('discoverApps_ReadandExport') ? (
                <CSVLink id="csv-export-link" data={exportData} filename="discoceredappslist.csv">
                  <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {' '}
                        <ArrowDownload20Regular {...primaryIcon} />{' '}
                      </StackItem>
                      <StackItem>
                        {' '}
                        <Caption1> Export </Caption1>{' '}
                      </StackItem>
                    </Stack>
                  </Button>
                </CSVLink>
              ) : null}
            </StackItem>
          </Stack>
        </Stack>
      </>
    ),
  };

  // overflow set contents code ends here
  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  //export pinotAsset data
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('devName') || '{}');
    const DevName = storedData.devName;
    const appName = storedData.appName;

    const fetchData = async () => {
      debugger;

      const { data, loading, error } = await client.query({
        query: getPinot_d312_singleAsset_v1_machine,
        variables: {
          params: {
            searchVal: searchVal,
            tenantName: tenantName,
            appName: appName,
            DevName: DevName,
            limit: 1000,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (error) {
        throw new Error(`Error result:` + error);
      }

      if (!loading && data) {
        if (data?.getPinot_d312_singleAsset_v1_machine?.error) {
          throw new Error(`No result`);
        }

        const uniqueData = data?.getPinot_d312_singleAsset_v1_machine?.answer;

        uniqueData !== undefined && uniqueData !== null
          ? uniqueData.map((data: any, i: any) => {
              menuLists.push();
              tempExportData.push({
                machine: data[6],
                totalDev: data[5],
                sizekb: data[4],
              });
            })
          : (tempTableData = []);
        setExportData(tempExportData);
      }
    };
    fetchData();
  }, [response, searchVal, setExportData]);

  //pinotable data over here
  useEffect(() => {
    //roles and permissions
    async function loadRoute() {
      const role = 'Nano_test';
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    const storedData = JSON.parse(localStorage.getItem('devName') || '{}');
    const DevName = storedData.devName;
    const appName = storedData.appName;

    const fetchData = async () => {
      try {
        const { data, loading, error } = await client.query({
          query: getPinot_d312_singleAsset_v1_machine,
          variables: {
            params: {
              searchVal: searchVal,
              tenantName: tenantName,
              appName: appName,
              DevName: DevName,
              limit: 1000,
            },
          },
          fetchPolicy: 'network-only',
        });

        if (error) {
          throw new Error(`Error result:` + error);
        }

        if (!loading && data) {
          if (data?.getPinot_d312_singleAsset_v1_machine?.error) {
            throw new Error(`No result`);
          }

          const uniqueData = data?.getPinot_d312_singleAsset_v1_machine?.answer;
          const startIndex = (currentPageNo - 1) * 50 + 1;
          const endIndex = currentPageNo * 50;
          const currentPageData = uniqueData.slice(startIndex, endIndex);

          currentPageData.forEach((data: any, i: any) => {
            tempTableData.push({
              checkbox: 'hide',
              machine: { label: <Caption1> {data[6]} </Caption1>, id: `${i}` },
              sizekb: { label: <Caption1> {data[5]} </Caption1> },
              totalDev: { label: <Caption1> {data[4]} </Caption1> },
            });
          });

          setTotalRecords(uniqueData.length);
          setTotalPages(Math.ceil(uniqueData.length / 50));
          setTableData(tempTableData);
          setLoading(false);
          setRefreshing(false); // Reset refreshing state after data is fetched
        }
      } catch (error) {
        console.log('catch -->', error);
        setError('No result.');
        setLoading(false);
        setRefreshing(false); // Reset refreshing state in case of error
      }
    };
    fetchData();
  }, [response, searchVal, refreshing]);

  // if (loading) return <p>Loading... </p>;
  if (loading) return <SkeletonContent />;
  if (error) return <p>Error: {error}</p>;

  // data table values append code ends here
  var overLayData: any = {
    headerTitle: 'Temp File Clean Up',
    size: 'large',
    isTabs: true,
    tabItems: {
      Introduction: (
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>Introduction</Stack>
        </>
      ),

      Configuration: (
        <>
          <Stack tokens={{ childrenGap: 15, padding: 10 }}>Configuration</Stack>
        </>
      ),
    },
  };

  return (
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
      <StackItem grow shrink>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <StackItem grow shrink>
            <Stack horizontalAlign="start">
              <StackItem grow shrink>
                {' '}
                <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" />{' '}
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem grow shrink>
            <Stack horizontalAlign="end">
              <StackItem grow shrink>
                <Button
                  style={fullscreenIcon}
                  appearance="subtle"
                  size="small"
                  shape="square"
                  onClick={handleFullScreen}
                  title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                  icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            <StackItem>
              <Stack verticalAlign="center">
                <Stack tokens={{ childrenGap: 25 }} horizontal>
                  <StackItem>
                    {' '}
                    <Text />{' '}
                  </StackItem>
                  <StackItem>
                    <Title3> Apps | Discovered Apps Details </Title3>
                  </StackItem>
                </Stack>
                <Stack tokens={{ childrenGap: 20 }}>
                  <StackItem>
                    <Text />
                  </StackItem>
                  <StackItem>
                    <Text />
                  </StackItem>
                </Stack>
                <Stack horizontal>
                  {' '}
                  {/* SPACING BETWEEN INLINE AND PAGE */}
                  <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                  <StackItem>
                    <StackItem>
                      {' '}
                      <Text />{' '}
                    </StackItem>
                    <Stack tokens={{ childrenGap: 1 }}>
                      <StackItem>
                        <Text />
                      </StackItem>
                      <StackItem>
                        <Text />
                      </StackItem>
                    </Stack>
                    <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                        {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                      </StackItem>
                      <StackItem>
                        <StackItem>
                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <SearchBox
                              dismiss={null}
                              onChange={(e: any) => {
                                setSearchVal(e.target.value);
                              }}
                              placeholder="Search"
                              size="small"
                            />
                            <StackItem>
                              <MenuDropdown
                                btn={
                                  <MenuButton shape="circular" size="small">
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <Filter20Filled {...primaryIcon} />
                                      <Body1> Filter </Body1>
                                    </Stack>
                                  </MenuButton>
                                }
                                itemVal={
                                  <>
                                    <Body1Strong> Add a Filter </Body1Strong>
                                    <Divider />
                                    <MenuList>
                                      <Stack tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          {' '}
                                          <Text />{' '}
                                        </StackItem>
                                        <StackItem>
                                          {' '}
                                          <Caption1> Filter </Caption1>{' '}
                                        </StackItem>
                                        <DropdownComponent optionData={columns} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />{' '}
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem>
                                          {' '}
                                          <Divider />{' '}
                                        </StackItem>
                                        <StackItem>
                                          {' '}
                                          <Caption1> Value </Caption1>{' '}
                                        </StackItem>
                                        <DropdownComponent optionData={columns} setDropDownValue={setDropDownValue2} dropDownvalue={dropDownValue2} />{' '}
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem>
                                          {' '}
                                          <Divider />{' '}
                                        </StackItem>
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                          <StackItem>
                                            {' '}
                                            <Button appearance="primary" onClick={handleFilterArray}>
                                              {' '}
                                              <Caption1> Apply </Caption1>{' '}
                                            </Button>{' '}
                                          </StackItem>
                                          <StackItem>
                                            {' '}
                                            <Button>
                                              {' '}
                                              <Caption1> Cancel </Caption1>{' '}
                                            </Button>{' '}
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    </MenuList>
                                  </>
                                }
                              />
                            </StackItem>
                            <StackItem>
                              {filterArray.length > 0 &&
                                filterArray.map((item, index) => (
                                  <Badge appearance="filled" color="informative">
                                    <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem> {item} </StackItem>
                                      <StackItem>
                                        <Dismiss16Regular
                                          onClick={() => {
                                            handleCloseFilter(index);
                                          }}
                                        />{' '}
                                        {/* <ArrowPrevious20Filled {...primaryIcon} onClick={() => {handleCloseFilter(index)}}/> */}
                                      </StackItem>
                                    </Stack>
                                  </Badge>
                                ))}
                            </StackItem>
                            <StackItem onClick={() => setFilterArray([])}>
                              <Button appearance="subtle" size="small">
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem>
                                    {' '}
                                    <FilterDismiss20Filled />{' '}
                                  </StackItem>{' '}
                                  {/* {isResetDisabled ? ( <FilterDismiss20Filled {...secondaryIcon}/> ) : ( <FilterDismiss20Filled {...primaryIcon}/> ) } */}
                                  <StackItem>
                                    {' '}
                                    <Caption1> Reset Filters </Caption1>{' '}
                                  </StackItem>
                                </Stack>
                              </Button>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <Text />
                          </StackItem>
                          <StackItem>
                            <Text />
                          </StackItem>
                        </Stack>
                        <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                          <StackItem>
                            {' '}
                            <Caption1>
                              {' '}
                              Showing {(currentPageNo - 1) * 10 + 1} to {currentPageNo * 10 > totalRecords ? totalRecords : currentPageNo * 10} of{' '}
                              {totalRecords} Records{' '}
                            </Caption1>{' '}
                          </StackItem>
                          <StackItem>
                            {' '}
                            <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />{' '}
                          </StackItem>
                        </Stack>
                      </StackItem>
                      {tableData.length > 0 ? (
                        <DataTable tableData={tableData} columns={columns}></DataTable>
                      ) : (
                        <Stack>
                          {' '}
                          <StackItem> </StackItem>
                          <StackItem> No results found </StackItem>
                        </Stack>
                      )}
                    </Stack>
                  </StackItem>
                </Stack>
              </Stack>
              <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />
            </StackItem>
          </StackItem>
          <StackItem>
            <Text />
          </StackItem>
        </Stack>
      </FluentProvider>
    </FullScreen>
  );
};
