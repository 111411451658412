import { useEffect, useState, useContext } from 'react';
import {
  addUserQuery,
  getUsersListsCntQuery,
  getUsersListsQuery,
  updateUserQuery,
  getUserDetailsQuery,
  getUserQuery,
  userActiveQuery,
  deleteUserQuery,
  userEmailQuery,
} from '../graphql/users_queries';
import { useQuery, useApolloClient } from '@apollo/client';
import { GlobalContext } from '../hooks/globalContext';
import { exit } from 'process';
import { stringify } from 'querystring';
import { sendPasswordResetEmail, submitNewPassword } from 'supertokens-web-js/recipe/emailpassword';
// import Session from "supertokens-auth-react/lib/build/recipe/session/recipe";
import Session from 'supertokens-web-js/recipe/session';
import AppConfig from '../utility/AppConfig';

type dataProps = {
  data: any;
  func: any;
  searchVal: any;
  pageNo: any;
  setTotalPages: any;
  setTotalRecords: any;
  isFilter?: any;
  dropDownValue1?: any;
  setIsFilter?: any;
  dropDownValue2?: any;
  saveUserDetails?: any; // Making it optional
  setSaveUserDetails?: any;
  formDataUser: any;
  setFormDataUser: any;
  deleteUser: any;
  setDeleteUser: any;
  setDeleteDisabled: any;
  setEditUserDetails: any;
  editUserDetails: any;
  rowId?: any;
  setRowID?: any;
  handleEditData?: any;
  setIsToast?: any;
  isRefresh?: any;
  setIsRefresh?: any;
  setRefreshToast?: any;
  setExportDetails?: any;
  setdeleteUserToast?: any;
  deleteUserToast?: any;
  currentSelectedUserRole?: any;
  setCurrentSelectedUserRole?: any;
};

export const Users_func = (props: dataProps) => {
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);

  useEffect(() => {
    if (props.deleteUser && contextValue.selectedRowIdInTable.length > 0) {
      deleteUser(contextValue.selectedRowIdInTable);
      getUsersList(null, 10, parseInt(props.pageNo));
      getUserListCnt(null);
    }

    if (props?.searchVal?.length > 0 && props.searchVal !== '') {
      getUsersList(
        { firstName: { contains: props.searchVal } } || {
          firstName: { _ilike: props.searchVal },
        },
        50,
        parseInt(props.pageNo),
      );
      getUserListCnt(
        { firstName: { contains: props.searchVal } } || {
          firstName: { _ilike: props.searchVal },
        },
      );
    } else if (props.isFilter == true) {
      if (props.dropDownValue1 == 'First Name') {
        getUsersList(
          { firstName: { contains: props.dropDownValue2 } } || {
            firstName: { _ilike: props.dropDownValue2 },
          },
          50,
          parseInt(props.pageNo),
        );
        getUserListCnt(
          { firstName: { contains: props.dropDownValue2 } } || {
            firstName: { _ilike: props.dropDownValue2 },
          },
        );
      } else if (props.dropDownValue1 == 'Last Name') {
        getUsersList(
          { lastName: { contains: props.dropDownValue2 } } || {
            lastName: { _ilike: props.dropDownValue2 },
          },
          50,
          parseInt(props.pageNo),
        );
        getUserListCnt(
          { lastName: { contains: props.dropDownValue2 } } || {
            lastName: { _ilike: props.dropDownValue2 },
          },
        );
      } else if (props.dropDownValue1 == 'Email') {
        getUsersList(
          { user_email: { contains: props.dropDownValue2 } } || {
            user_email: { _ilike: props.dropDownValue2 },
          },
          50,
          parseInt(props.pageNo),
        );
        getUserListCnt(
          { user_email: { contains: props.dropDownValue2 } } || {
            user_email: { _ilike: props.dropDownValue2 },
          },
        );
      } else if (props.dropDownValue1 == 'User Type') {
        getUsersList(
          { userType: { contains: props.dropDownValue2 } } || {
            userType: { _ilike: props.dropDownValue2 },
          },
          50,
          parseInt(props.pageNo),
        );
        getUserListCnt(
          { userType: { contains: props.dropDownValue2 } } || {
            userType: { _ilike: props.dropDownValue2 },
          },
        );
      } else if (props.dropDownValue1 == 'Status') {
        getUsersList(
          { status: { contains: props.dropDownValue2 } } || {
            status: { _ilike: props.dropDownValue2 },
          },
          50,
          parseInt(props.pageNo),
        );
        getUserListCnt(
          { status: { contains: props.dropDownValue2 } } || {
            status: { _ilike: props.dropDownValue2 },
          },
        );
      }
    } else if (props.isRefresh) {
      props.setIsRefresh(false);
      getUsersList(null, 10, parseInt(props.pageNo));
      getUserListCnt(null);
      props.setRefreshToast(true);
      setTimeout(() => {
        props.setRefreshToast(false);
      }, 3000);
    } else {
      getUsersList(null, 10, parseInt(props.pageNo));
      getUserListCnt(null);
    }

    if (props.rowId) {
      getUserDetails();
    }

    if (props.saveUserDetails == true) {
      addUser();
      getUsersList(null, 10, parseInt(props.pageNo));
      getUserListCnt(null);
      props.setSaveUserDetails(false);
      props.setFormDataUser(null);
      props.setIsToast(false);
    } else if (props.editUserDetails == true) {
      updateUserDetails();
      props.setFormDataUser(null);
      props.setRowID(null);
      setTimeout(() => {
        props.setEditUserDetails(false);
      }, 10000);
    }
  }, [
    props.searchVal,
    props.searchVal.length,
    props.pageNo,
    props.saveUserDetails,
    props.editUserDetails,
    props.rowId,
    props.deleteUser,
    props.isRefresh,
    props.isFilter,
  ]);

  const getUsersList = async (whereCondition: any, limit: Number, offset: Number) => {
    try {
      const { data, loading, error } = await client.query({
        query: getUsersListsQuery,
        variables: { whereCondition, limit, offset },
        fetchPolicy: 'network-only',
      });
      if (!loading && data) {
        props.func(data);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const getUserListCnt = async (whereCondition: any) => {
    try {
      const { data, loading, error } = await client.query({
        query: getUsersListsCntQuery,
        variables: { whereCondition },
        fetchPolicy: 'network-only',
      });

      if (!loading && data) {
        props?.setExportDetails && props?.setExportDetails(data.core_Users);
        props.setTotalRecords(data.core_Users.length);
        props.setTotalPages(Math.ceil(data.core_Users.length / 10));
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const getUserDetails = async () => {
    const userId = props.rowId;
    try {
      const { data, loading, error } = await client.query({
        query: getUserDetailsQuery,
        variables: { userId },
        fetchPolicy: 'network-only',
      });
      if (!loading && data) {
        props.handleEditData(data);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const addUser = async () => {
    const firstName = props.formDataUser?.firstName ?? null;
    const lastName = props.formDataUser?.lastName ?? null;
    const userEmail = props.formDataUser?.emailid ?? null;
    const timeZone = props.formDataUser?.TimeZone ?? null;
    const securityType = props.formDataUser?.Security ?? null;
    const formDataEdit = JSON.stringify(props.formDataUser) ?? null;
    const role_assigned = props.formDataUser?.role ?? null;
    const Created_on = JSON.stringify(Math.floor(Date.now() / 1000));
    let userType = '';
    if (props.saveUserDetails) {
      userType = 'Local';
    } else {
      userType = 'SSO';
    }

    //check if user already exists in db before entering new user
    try {
      const { data, loading, error } = await client.query({
        query: getUserQuery,
        variables: { userEmail },
        fetchPolicy: 'network-only',
      });

      if (data?.core_Users.length > 0) {
        //if User already present
        alert('User already exists with this mail!');
        throw new Error('User already exists with this mail!');
      } else {
        try {
          const { data } = await client.mutate({
            mutation: addUserQuery,
            variables: { firstName, lastName, userEmail, timeZone, securityType, formDataEdit, Created_on, userType, role_assigned },
            fetchPolicy: 'network-only',
          });
          if (data) {
            props.setSaveUserDetails(false);
            props.setFormDataUser(null);
            getUsersList(null, 10, parseInt(props.pageNo));
            getUserListCnt(null);
          }
        } catch (error) {
          console.error('An unexpected error occurred:', (error as Error).message);
          // Handle unexpected errors, log them, and potentially notify the user
        }

        //adding to supertoken user db by calling signup functionality
        // const apiUrl = 'http://localhost:3001/auth/signup';
        // const requestBody = {
        //   formFields: [
        //     { id: 'email', value: userEmail },
        //     { id: 'password', value: 'nanoheal@123' },
        //   ],
        // };
        // try {
        //     const response = await fetch(apiUrl, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         // Add any additional headers if needed
        //     },
        //     body: JSON.stringify(requestBody),
        //     });

        //     if (!response.ok) {
        //     // Handle the error
        //     console.error('Error:', response.status, response.statusText);
        //     } else {
        //     const responseData = await response.json();
        //     // Process the response data

        //     // function to send mail to the new user
        //     setTimeout(() => sendEmailClicked(userEmail),2000);

        //     }
        // } catch (error:any) {
        //     // Handle network or other errors
        //     throw new Error(error.message)
        // }

        //create new user flow calling API
        const accessToken = await Session.getAccessToken();

        fetch(`${AppConfig.DASHBOARD_V2_API_URL}/create-user`, {
          method: 'POST',
          body: JSON.stringify({
            email: userEmail,
            role: role_assigned,
          }),
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            console.log('API data for create NEW user called', data);
            // Handle data
            props.setIsToast(true);
          })
          .catch((err: any) => {
            console.log('API ERROR create user', err.message);
          });
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
  };

  const sendEmailClicked = async (userEmail: any) => {
    console.log('Sending password email');
    try {
      const response = await sendPasswordResetEmail({
        formFields: [{ id: 'email', value: userEmail }],
      });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField: any) => {
          if (formField.id === 'email') {
            window.alert(formField.error);
          }
        });
      } else if (response.status === 'PASSWORD_RESET_NOT_ALLOWED') {
        window.alert('Password reset not allowed. Please check conditions.');
      } else {
        window.alert('Password reset link sent successfully.');
        // setpasswordResetMailSent(true);
      }
    } catch (err) {
      // handleSuperTokensError(err);
      console.log('checking the error at forgot password email', err);
    }
  };

  //const getUser = async(email:any) => {
  // try {
  //     const { data,loading,error} = await client.query({
  //         query: getUserQuery,
  //         variables: {email}
  //         // fetchPolicy: "network-only"
  //     });

  //     if(data){
  //         if(data.core_Users.length > 0){
  //             setUserExists(true)
  //         }
  //         else{
  //             setUserExists(false)
  //         }

  //     }
  // }catch (error){
  //     console.error("An unexpected error occurred:", (error as Error).message);
  //     // Handle unexpected errors, log them, and potentially notify the user
  // }

  //}

  const updateUserDetails = async () => {
    const rowId = props.rowId ?? null;
    const firstName = props.formDataUser?.firstName ?? null;
    const lastName = props.formDataUser?.lastName ?? null;
    const userEmail = props.formDataUser?.emailid ?? null;
    const timeZone = props.formDataUser?.TimeZone ?? null;
    const securityType = props.formDataUser?.Security ?? null;
    const formDataEdit = JSON.stringify(props.formDataUser) ?? null;
    const role_assigned = props.formDataUser?.role ?? null;
    const modifiedOn = JSON.stringify(Math.floor(Date.now() / 1000));
    console.log('formData ------------>', formDataEdit);
    console.log('currentSelectedUserRole ===========>', props.currentSelectedUserRole);

    try {
      const { data } = await client.mutate({
        mutation: updateUserQuery,
        variables: { rowId, firstName, lastName, userEmail, timeZone, securityType, formDataEdit, role_assigned, modifiedOn },
        fetchPolicy: 'network-only',
      });

      if (data) {
        // console.log( "rows affected for Updated Users from db:", data.insert_core_Users.returning);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', (error as Error).message);
      // Handle unexpected errors, log them, and potentially notify the user
    }
    if (props.currentSelectedUserRole !== role_assigned && role_assigned !== null) {
      try {
        // Send a POST request with the new role as JSON

        const response = await fetch(`${AppConfig.DASHBOARD_V2_API_URL}/update-user-role`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ role: role_assigned, email: userEmail, prevRole: props.currentSelectedUserRole }),
        });

        if (response.ok) {
          // If the response is successful, parse and return the success message
          const data = await response.json();
          return { success: true, message: data.message };
        }

        // Handle different types of errors based on response status
        const errorData = await response.json();
        return {
          success: false,
          error: errorData.error || 'Request failed',
        };
      } catch (error) {
        // Catch network or unexpected errors
        console.error('Error during fetch:', error);
        return { success: false, error: 'Unexpected error occurred' };
      }
    }
  };
  const deleteUser = async (_eq: any) => {
    {
      await _eq.map(async (item: any) => {
        let userEmail = '';
        let userIdSuperToken = '';
        const userid = item;
        try {
          const { data, loading, error } = await client.query({
            query: userEmailQuery,
            variables: { userid },
            fetchPolicy: 'network-only',
          });
          if (!loading && data) {
            userEmail = data.core_Users[0].user_email;
          }
        } catch (error) {
          console.error('An unexpected error occurred:', (error as Error).message);
          // Handle unexpected errors, log them, and potentially notify the user
        }

        //2. getting the userid through API CALL from supertokendb
        const apiurlEmail = `${AppConfig.DASHBOARD_V2_API_URL}/api/userId/${userEmail}`;
        try {
          const response = await fetch(apiurlEmail, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.status !== 200) {
            // Handle the error
            console.error('Error:', response.status, response.statusText);
          } else {
            const responseData = await response.json();
            // Process the response data
            userIdSuperToken = responseData.userid;
          }
        } catch (error: any) {
          // Handle network or other errors
          console.error('Error:', error.message);
        }
        //3 calling delete API to delete the particular user and all its related items based on userid
        const apiurlSupertokenDelete = `${AppConfig.DASHBOARD_V2_API_URL}/api/users/${userIdSuperToken}`;
        try {
          const response = await fetch(apiurlSupertokenDelete, {
            method: 'DELETE',
            headers: {
              'content-type': 'application/json',
            },
          });
          if (response.status !== 200) {
            console.log('Error:', response.status, response.statusText);
          } else {
            await response.json();
          }
        } catch (error: any) {
          console.log('ERROR', error);
        }
      });
      //loop ends

      // deleting from core.users
      try {
        const { data } = await client.mutate({
          mutation: deleteUserQuery,
          variables: { _eq },
          fetchPolicy: 'network-only',
        });

        if (data.delete_core_Users?.affected_rows > 0) {
          props.setDeleteDisabled(true);
          setTimeout(() => {
            props.setdeleteUserToast(true);
          }, 2000);
          setTimeout(() => {
            props.setDeleteUser(false);
            props.setdeleteUserToast(false);
          }, 1000);
          //   setTimeout(() => {
          //     getUsersList(null,50,parseInt(props.pageNo))
          //     getUserListCnt(null);
          //   }, 5000);

          // props.setIsToast(true);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };
  return <></>;
};
