import { useState, useEffect, useContext } from 'react';
import { SideMenu } from '../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../components/data_table/data_table.component';
import OverLay from '../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../components/side_menu/overFlowSet.component';
import { MessageBar, Stack, StackItem } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  webDarkTheme,
  Card,
  Title2,
  Subtitle2,
  Subtitle1,
  Image,
  Input,
  ButtonProps,
} from '@fluentui/react-components';
import {
  DesktopCheckmark20Regular,
  WrenchScrewdriver20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  Info16Filled,
  Info20Filled,
  Dismiss16Regular,
  AlertBadge20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../components/dropdown/menu.component';
import { DropdownComponent } from '../../components/dropdown/dropdown.component';
import { Troubleshooter_func } from '../../api/troubleshooter_func';
import { Compliance_Policy_func } from '../../api/compliance_policy_func';
import { ToastComponent } from '../../components/toast/toast.component';
import { TroubleshooterDevice_fun } from '../../api/troubleshooterDevice_fun';
import { Form } from '@rjsf/fluent-ui';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from '../../components/form-components';
import { GlobalContext } from '../../hooks/globalContext';
import { FullScreen } from 'react-full-screen';
import { DataTableDevice } from '../../components/data_table/data_table.Device';
import { useFullScreenHandle } from 'react-full-screen';
import { Activity_log_func } from '../../api/activity_log/activity_log_func';

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import DialogBox from '../../components/dialog/dialog.component';
import { fullscreenIcon, primaryIcon, exitIcon } from '../../styles/customStyles';

interface AccordionItem {
  heading: string;
  content: string;
}

const handleExportCSV = () => {
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Compliance_Policy = () => {
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [final_data1, set_final_data1]: any = useState(null);
  const [final_data2, set_final_data2]: any = useState(null);
  const [categoryData, setCategoryData]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [dropDownValue1, setDropDownValue1] = useState<any[]>([]);
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [searchDevVal, setSearchDevVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [messagebar1, setmessagebar1] = useState(false);
  const [messagebar2, setmessagebar2] = useState(false);
  const [subAutomationDetails, setSubAutomationDetails]: any = useState({});
  const [jsonSchema, setJsonData]: any = useState({});
  const [uiSchema, setUiData]: any = useState({});
  const [exportData, setExportData]: any = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);

  const handleBothSetMessage = () => {
    setmessagebar1(false);
    //setmessagebar2(true);
  };
  const [selectedData, setselectedData]: any = useState({ name: '', desc: '', displayName: '', subAutomation: '' });
  const [allDevice, setallDevice] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [overLayData, setOverLayData] = useState({});
  const contextValue: any = useContext(GlobalContext);
  const [executeBtnClicked, setExecuteBtnClicked] = useState(false);
  const [toastMessage, setToastMessage] = useState('Push Failed');
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);

  const [tracker, setTracker]: any = useState(false);
  const [userActivityData, setUserActivityData] = useState({});

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);
  const [machineStatus, setMachineStatus]: any = useState({});

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };

  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  var menuItems: any = [];

  //inline side menu content code starts here
  const selectedTheme: BrandVariants = {
    10: '#010404',
    20: '#0F1A1D',
    30: '#142B32',
    40: '#163842',
    50: '#164652',
    60: '#165464',
    70: '#136275',
    80: '#0C7187',
    90: '#038099',
    100: '#388DA4',
    110: '#d6d6d6',
    120: '#6FA8B9',
    130: '#86B5C4',
    140: '#9DC3CF',
    150: '#B4D1DA',
    160: '#CADFE5',
  };

  const lightTheme: Theme = {
    ...createLightTheme(selectedTheme),
  };
  const darkTheme: Theme = {
    ...createDarkTheme(selectedTheme),
  };
  darkTheme.colorNeutralBackground1 = '#e9e9e9';
  lightTheme.colorNeutralBackground1 = '#e9e9e9';
  lightTheme.colorNeutralBackground4 = '#0a0a0a';
  darkTheme.colorNeutralForeground1 = '#ffff00';

  // message bar starts here
  const messageTheme: Theme = {
    ...createLightTheme(selectedTheme),
  };
  messageTheme.colorNeutralBackground1 = '#EBF3FC';
  // message bar ends here
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/troubleshoot/alert')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <AlertBadge20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Alert </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/troubleshoot/troubleshoot')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <WrenchScrewdriver20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Troubleshoot </Caption1>
        </StackItem>
        <StackItem>
          <Stack tokens={{ childrenGap: 59 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/troubleshoot/status')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <DesktopCheckmark20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Status </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  menuItems.push(
    <FluentProvider theme={lightTheme}>
      <MenuItem
        onClick={() => navigate('/manage/troubleshoot/compliance_policy')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <DesktopCheckmark20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Compliance Policy </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>
    </FluentProvider>,
  );
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'automationName', label: <Body1Strong>&nbsp; Name </Body1Strong> },
    { key: 'description', label: <Body1Strong> Description </Body1Strong> },
    { key: 'category', label: <Body1Strong> Category </Body1Strong> },
    // { key: "category_description", label: <Body1Strong> Category description </Body1Strong> },
    // { key: "subCategory", label: <Body1Strong> Sub Category </Body1Strong> },
    // { key: "sub_category_description", label: <Body1Strong> Sub Category description </Body1Strong> },
  ];
  //data table column name code ends here
  //the data table for filter starts here
  var columns1 = [
    { key: 'automationName', label: <Caption1> Name </Caption1> },
    { key: 'description', label: <Caption1> Description </Caption1> },
    { key: 'category', label: <Caption1> Category </Caption1> },
    { key: 'subCategory', label: <Caption1> Sub Category </Caption1> },
  ];

  //overlay datatable code start here
  var overlayColumns = [
    { key: 'host', label: <Body1Strong> Device Name</Body1Strong> },
    { key: 'site', label: <Body1Strong> Device Classification </Body1Strong> },
    { key: 'os', label: <Body1Strong> Operating System </Body1Strong> },
  ];

  var overlayColumns2 = [{ key: 'classificationName', label: <Body1Strong> All Device Name</Body1Strong> }];
  //overlay datatable code ends here

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="troubleshootlist.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <ArrowDownload20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Export </Caption1>
                </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon} /> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var timer: any;
  const searchHandle = (data: any) => {
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchVal(data.target.value);
      setCurrentPageNo(1);
    }, 1500);
  };

  var timer: any;
  const searchDevHandle = (data: any) => {
    timer = clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchDevVal(data.target.value);
      setCurrentPageNo(1);
    }, 1500);
  };

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        //var NewValue = val && JSON.parse(val.jsonValue);
        //console.log("NewValue==============", val.jsonValue);

        //   tempExportData.push({
        //     automationName: NewValue.automationName,
        //     description: NewValue.description,
        //     category: NewValue.category,
        //     category_description: NewValue.category_description,
        //     subCategory: NewValue.sub_category,
        //     sub_category_description: NewValue.sub_category_description,
        //   });
        // });
        tempExportData.push({
          automationName: val.automationName,
          description: val.description,
          category: val.category,
          // category_description: val.category_description,
          // subCategory: val.sub_category,
          // sub_category_description: val.sub_category_description,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    final_data !== undefined && final_data !== null
      ? final_data.core_AutomationJSON.map((val: any, i: any) => {
          var NewValue = val && JSON.parse(val.jsonValue);

          tempTableData.push({
            id: NewValue.automationName,
            checkbox: 'hide',
            automationName: {
              label: (
                <Button
                  size="small"
                  appearance="transparent"
                  onClick={() => {
                    setOverlayOpen(true);
                    setallDevice(true);
                    setselectedData({ name: val.automationName, desc: val.description, displayName: val.automationName, subAutomation: val.id });
                  }}
                >
                  {val.automationName}
                </Button>
              ),
              icon: '',
              id: `${i}`,
            },
            description: { label: <Caption1> {val.description} </Caption1>, status: '' },
            category: {
              label: (
                <Caption1>
                  {val.dartid === '2001'
                    ? 'All'
                    : val.dartid === '2002'
                    ? 'Business'
                    : val.dartid === '2003'
                    ? 'Manoj'
                    : val.dartid === '2004'
                    ? 'Business'
                    : 'Business'}
                </Caption1>
              ),
            },
            jsonValue: { label: <Caption1> {val.jsonValue} </Caption1> },
          });
        })
      : (tempTableData = []);
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here

  useEffect(() => {
    // Overlay data table values append code starts here
    console.log('final_data1*************', final_data1);
    console.log('machineStatus***********', machineStatus);

    final_data1 !== undefined && final_data1 !== null
      ? final_data1.core_Census.map((val: any, i: any) => {
          var deviceStatus: any =
            Object.keys(machineStatus).length === 0
              ? 'offline'
              : machineStatus[`${val.host}`] !== undefined
              ? machineStatus[`${val.host}`][0]
              : 'offline';
          var deviceType: any =
            Object.keys(machineStatus).length === 0
              ? 'default'
              : machineStatus[`${val.host}`] !== undefined
              ? machineStatus[`${val.host}`][1]
              : 'default';

          var currentIcon = <Badge size="extra-small" appearance="filled" color={deviceStatus === 'online' ? 'success' : 'informative'}></Badge>;

          if (deviceStatus === 'online') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/G_Win.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/G_Android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/G_Apple.svg" alt="Analyze Icon" />;
            }
          } else if (deviceStatus === 'offline') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/Windows.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/apple.svg" alt="Analyze Icon" />;
            }
          }

          tempTableData.push({
            id: val.host,
            host: { label: <Caption1> {val.host} </Caption1>, icon: currentIcon, id: `${i}` },
            site: { label: <Caption1> {val.site} </Caption1>, icon: '', status: '' },
            os: { label: <Caption1> {val.os} </Caption1> },
          });
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData1(tempTableData) : setTableData1([]);
  }, [final_data1, machineStatus]);

  useEffect(() => {
    // Overlay device table values append code starts here
    final_data2 !== undefined && final_data2 !== null
      ? final_data2 &&
        final_data2.core_DeviceClassification.map((val: any, i: any) => {
          const deviceData = val && val.classificationName;

          return <Caption1>{deviceData} </Caption1>;
        })
      : console.log('null');
  }, [final_data2]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  // useEffect(() => {

  // }, [contextValue.selectedRowIdInTable]);

  //overlay code starts here
  useEffect(() => {
    setOverLayData({
      headerTitle: (
        <StackItem>
          <FluentProvider theme={webLightTheme}>
            <Title3>{selectedData.displayName.length > 0 ? selectedData.displayName : ''}</Title3>
          </FluentProvider>
        </StackItem>
      ),
      size: 'large',
      isTabs: true,

      tabItems: {
        Introduction: (
          <>
            <FluentProvider theme={webLightTheme}>
              <Stack tokens={{ childrenGap: 15, padding: 10 }}>
                <StackItem>
                  <Body1Strong> What does this Troubleshooter Do? </Body1Strong>
                </StackItem>
                <StackItem>
                  <Caption1>
                    Prevent data in Office mobile apps from being shared or leaked to unmanaged apps on iOS and Android devices. In this scenario,
                    you’ll apply data protection settings to your organization’s managed mobile apps.
                  </Caption1>
                </StackItem>
                <StackItem>
                  <Body1Strong> What is the benefit? </Body1Strong>
                </StackItem>
                <StackItem>
                  <Caption1>
                    Prevent data in Office mobile apps from being shared or leaked to unmanaged apps on iOS and Android devices. In this scenario,
                    you’ll apply data protection settings to your organization’s managed mobile apps.
                  </Caption1>
                </StackItem>
                <StackItem>
                  <Body1Strong> What is the User impact? </Body1Strong>
                </StackItem>
                <StackItem>
                  <Caption1>
                    Assigned iOS and Android users must enter a PIN or use biometrics to access work or school content after a period of inactivity.
                    After 5 failed PIN attempts, user must reset their PIN.
                  </Caption1>
                  <Caption1>
                    If advanced data protection controls are enforced, users will not be able to share work or school content with personal apps or
                    personal accounts and will be required to use Microsoft Edge.
                  </Caption1>
                </StackItem>
                <StackItem>
                  <Body1Strong> Prerequisites, if any</Body1Strong>
                </StackItem>
                <StackItem>
                  <Caption1> Managed Apps Create,Read,Assign,Delete Permission </Caption1>
                  <Caption1> Policy sets read, create, and assign permissions. </Caption1>
                </StackItem>
              </Stack>
            </FluentProvider>
          </>
        ),

        // Configuration:
        //   <>
        //     <Stack>
        //       {Object.keys(jsonSchema).length > 0 && Object.keys(uiSchema).length > 0 &&
        //         subAutomationDetails.core_AutomationJSON?.map((data: any, i: any) => {
        //           return (<Accordion collapsible><AccordionItem value="1">
        //             <AccordionHeader>{data.automationName}</AccordionHeader>
        //             <AccordionPanel>
        //               <Form formData={JSON.parse(data?.jsonValue)} schema={jsonSchema[`${data.dartid}`]} uiSchema={uiSchema[`${data.dartid}`]} validator={validator} widgets={CustomWidgets} disabled />
        //             </AccordionPanel>
        //           </AccordionItem> </Accordion>)
        //         })
        //       }
        //     </Stack>
        //   </>,

        Devices: (
          <>
            <Stack horizontalAlign="start">
              <StackItem grow shrink>
                <Card appearance="subtle">
                  <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <StackItem>
                      {/* LEFT SIDE DATA HERE */}
                      <Body1Strong> Data&nbsp;Classification </Body1Strong>
                      <Divider></Divider>
                      <Stack tokens={{ padding: 5 }} horizontal>
                        <StackItem> </StackItem>
                      </Stack>
                      <Stack tokens={{ padding: 5, childrenGap: 10 }} horizontalAlign="start" verticalAlign="start">
                        <StackItem
                          onClick={() => {
                            setallDevice(true);
                          }}
                        >
                          <Button size="medium" appearance="transparent">
                            All Devices
                          </Button>
                        </StackItem>

                        {final_data2 !== undefined && final_data2 !== null
                          ? final_data2 &&
                            final_data2.core_DeviceClassification.map((val: any, i: any) => {
                              const deviceData = val.classificationName;
                              return (
                                <StackItem
                                  onClick={() => {
                                    setGroupName(deviceData);
                                    setallDevice(false);
                                  }}
                                >
                                  <Button appearance="transparent" size="small">
                                    {deviceData}
                                  </Button>
                                </StackItem>
                              );
                            })
                          : console.log('null')}

                        {tableData2.length > 0 && groupName != '' && <DataTable tableData={tableData2} columns={overlayColumns2} />}
                      </Stack>
                    </StackItem>

                    <Divider inset vertical />

                    <StackItem>
                      {/* RIGHT SIDE DATA HERE */}
                      <FluentProvider theme={messageTheme}>
                        <Card appearance="subtle" size="small">
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Info20Filled {...primaryIcon} />
                            </StackItem>
                            <StackItem>
                              <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>
                            </StackItem>
                          </Stack>
                        </Card>
                      </FluentProvider>
                      <Stack tokens={{ padding: 5 }}></Stack>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        {/* <SearchBox onChange={(e: any) => { searchDevHandle(e) }} placeholder="Search" size="small" /> */}
                        <SearchBox
                          onChange={(e: any) => {
                            setSearchDevVal(e.target.value);
                          }}
                          placeholder="Search"
                          size="small"
                        />
                        <StackItem>
                          <MenuDropdown
                            open={filterMenuOpen}
                            btn={
                              <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <Filter20Filled {...primaryIcon} />
                                  <Body1> Filter </Body1>
                                </Stack>
                              </MenuButton>
                            }
                            itemVal={
                              <>
                                <Body1Strong> Add a Filter </Body1Strong>
                                <Divider />
                                <MenuList>
                                  <Stack tokens={{ childrenGap: 5 }}>
                                    <StackItem>
                                      <Text></Text>
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Filter </Caption1>
                                    </StackItem>
                                    {/* <DropdownComponent optionData={columns} /> */}
                                    <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                    <StackItem>
                                      <Divider />
                                    </StackItem>
                                    <StackItem>
                                      <Caption1> Value </Caption1>
                                    </StackItem>
                                    {/* <DropdownComponent optionData={columns} /> */}
                                    <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                    <StackItem>
                                      <Divider />
                                    </StackItem>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                      <StackItem>
                                        <Button
                                          appearance="primary"
                                          onClick={() => {
                                            handleFilterArray();
                                            setFilterMenuOpen(false);
                                          }}
                                        >
                                          <Caption1> Apply </Caption1>
                                        </Button>
                                      </StackItem>
                                      <StackItem>
                                        <Button onClick={() => setFilterMenuOpen(false)}>
                                          <Caption1> Cancel </Caption1>
                                        </Button>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                </MenuList>
                              </>
                            }
                          />
                        </StackItem>
                        <StackItem>
                          {filterArray.length > 0 &&
                            filterArray.map((item, index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 1 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Dismiss16Regular
                                          onClick={() => {
                                            handleCloseFilter(index);
                                          }}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Button>
                            ))}
                        </StackItem>
                        <StackItem
                          onClick={() => {
                            setFilterArray([]);
                            setIsFilter(false);
                            setDropDownValue2('');
                            setResetDisabled(true);
                          }}
                        >
                          <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                              <StackItem>
                                <FilterDismiss20Filled />
                              </StackItem>
                              <StackItem>
                                <Caption1> Reset Filters </Caption1>
                              </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>

                      <Stack tokens={{ childrenGap: 10 }}>
                        <FluentProvider theme={webLightTheme}>
                          {tableData1.length > 0 ? (
                            <DataTable tableData={tableData1} columns={overlayColumns} />
                          ) : (
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 15, padding: 10 }}>
                              <StackItem>
                                <Text size={500}>No data available</Text>
                              </StackItem>
                            </Stack>
                          )}
                        </FluentProvider>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Card>
              </StackItem>
            </Stack>
          </>
        ),
      },

      footerData: (
        <>
          {/* <Button appearance="primary" disabled={contextValue.selectedRowIdInTable.length > 0 ? false : true} onClick={() => { setmessagebar1(true); }}>
              Execute this Fix
            </Button> */}

          <DialogBox
            headerButton={
              <>
                <Button
                  appearance="primary"
                  size="small"
                  onClickCapture={() => setCurrentPageNo(1)}
                  disabled={contextValue.selectedRowIdInTable.length > 0 ? false : true}
                >
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      <Caption1> Execute this Fix </Caption1>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            content={`Are you sure you want to execute the solution: ${selectedData.displayName} solution on ${contextValue.selectedRowIdInTable.length} devices?`}
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      onClick={handleBothSetMessage}
                      size="small"
                      onClickCapture={() => {
                        setOverlayOpen(false);
                        setExecuteBtnClicked(true);
                        userActivity('add', `{"summary":"${selectedData.name}"}`);
                      }}
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small">Cancel</Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </>
      ),
    });
  }, [
    selectedData,
    groupName,
    final_data1,
    tableData1,
    contextValue.selectedRowIdInTable,
    searchDevVal,
    allDevice,
    subAutomationDetails,
    uiSchema,
    jsonSchema,
    final_data2,
  ]);
  //overlay code ends here

  // useEffect(() => {
  //   //console.log("allDevice====",allDevice);
  // }, [allDevice])

  // useEffect(() => {
  //   //console.log("JsonData=",jsonSchema);
  //   //console.log("uiSchema=",uiSchema);
  // }, [jsonSchema, uiSchema])

  const userActivity = (action: String, activity: String, status: any = 'success') => {
    setUserActivityData({
      pageName: 'Troubleshooter',
      moduleName: 'troubleshoot',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  let deviceButtons = null;
  if (final_data2 !== undefined && final_data2 !== null) {
    deviceButtons = final_data2.core_DeviceClassification.map((val: any, i: any) => {
      const deviceData = val.classificationName;

      return (
        <StackItem
          key={i}
          onClick={() => {
            setGroupName(deviceData);
            setallDevice(false);
          }}
        >
          <Button appearance="transparent" size="small">
            {deviceData}
          </Button>
        </StackItem>
      );
    });
  } else {
    console.log('null');
  }

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Troubleshooting" subPageName="" subPageLink="#" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <Stack verticalAlign="center">
            <StackItem></StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3>Troubleshooting | Compliance Policy</Title3>
                </FluentProvider>
              </StackItem>
            </Stack>

            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>

            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      <Divider />
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              setSearchVal(e.target.value);
                            }}
                            value={searchVal}
                            placeholder="Search"
                            size="small"
                            dismiss={null}
                            contentAfter={<DismissButton aria-label="Clear" />}
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns} /> */}
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns} /> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                          </StackItem>
                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5, padding: 5 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <FluentProvider theme={messageTheme}>
                            <Card appearance="subtle" size="small">
                              <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <StackItem>
                                  <Info20Filled {...primaryIcon} />
                                </StackItem>
                                <StackItem>
                                  <Caption1>
                                    Executing the following troubleshooters will assist you in devices management and provide a resolutions for any
                                    errors. Be mindful when executing the troubleshooter on multiple devices.
                                  </Caption1>
                                </StackItem>
                              </Stack>
                            </Card>
                          </FluentProvider>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      {tableData.length > 0 ? (
                        <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                          <StackItem>
                            <Caption1>
                              Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                              {totalRecords} Records
                            </Caption1>
                          </StackItem>
                          <StackItem>
                            <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                          </StackItem>
                        </Stack>
                      ) : (
                        <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Caption1> No Results.</Caption1>
                          </StackItem>
                        </Stack>
                      )}
                    </StackItem>

                    <Compliance_Policy_func
                      data={final_data}
                      func={set_final_data}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 50}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      setExecuteBtnClicked={setExecuteBtnClicked}
                      executeBtnClicked={executeBtnClicked}
                      selectedAuto={selectedData}
                      setmessagebar={setmessagebar2}
                      setmessage={setToastMessage}
                      deviceCnt={tableData1.length}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                      setRefreshToast={setRefreshToast}
                      setSubAutomationDetails={setSubAutomationDetails}
                      setJsonData={setJsonData}
                      setUiData={setUiData}
                      setExportDetails={setExportDetails}
                      isFilter={isfilter}
                      dropDownValue1={dropDownValue1}
                      setIsFilter={setIsFilter}
                      dropDownValue2={dropDownValue2}
                      setCategoryData={setCategoryData}
                    />

                    <TroubleshooterDevice_fun
                      groupData={final_data2}
                      groupFunc={set_final_data2}
                      data={final_data1}
                      func={set_final_data1}
                      searchDevVal={searchDevVal}
                      pageNo={(currentPageNo - 1) * 10}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      groupName={groupName}
                      setGroupName={setGroupName}
                      allDevice={allDevice}
                      overlayOpen={overlayOpen}
                      setallDevice={setallDevice}
                      setMachineStatus={setMachineStatus}
                    />

                    {refreshToast && (
                      <ToastComponent
                        setmessagebar={setIsToast}
                        isToastEnabled={refreshToast}
                        toastIntent="success"
                        position="top-end"
                        title="Success"
                        message={'Data Table Refreshed'}
                        footer=""
                      />
                    )}
                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>

          <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />
          <StackItem>
            {/* {messagebar1 && (
              <ToastComponent setmessagebar={setmessagebar1}
                toastIntent="warning"
                position="top"
                title={`Are you sure you want to execute the solution: ${selectedData.displayName} solution on ${setselectedData.length} devices?`}

                footer={
                  <>
                    <Button appearance="primary" onClick={handleBothSetMessage} onClickCapture={() => { setOverlayOpen(false); setExecuteBtnClicked(true); userActivity('add', selectedData.name); }}> Confirm </Button>
                    <Button onClick={() => { setmessagebar1(false); }} > Cancel </Button>
                  </>
                }
              />
            )} */}
          </StackItem>
        </StackItem>
      </FullScreen>

      <StackItem>
        {messagebar2 && (
          <ToastComponent setmessagebar={setmessagebar2} toastIntent="success" position="top-end" title="Success" message={toastMessage} />
        )}
      </StackItem>
    </>
  );
};
