import React, { useContext, useEffect, useState } from "react";
import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableSelectionCell, TableCellLayout, useTableFeatures, TableColumnDefinition, useTableSelection, createTableColumn, useTableSort, TableColumnId } from "@fluentui/react-components";
import { GlobalContext } from "../../hooks/globalContext";
import { SkeletonDataTable } from "../skeleton/skeletonDataTable";
import { ScrollablePane } from "@fluentui/react";
import { dataTableScroll } from "../../styles/customStyles";

export const DataTable = (props: any) => {
    console.log("Default Selected Rows: ", props.defaultSelected);
    const [isOnline, setIsOnline] = useState(true);
    const contextValue: any = useContext(GlobalContext);
    var tableColArr: any = [];
    const items: any[] = (props.tableData != undefined) ? props.tableData : [{}];
    var checkboxCnt = items.filter((val: any) => val.checkbox == 'hide').length;
    var totalRowCnt = items.length;
    var selectAllCheckbox = (totalRowCnt == checkboxCnt) ? 'hide' : 'show';
 
    const handleRowSelect = (data: any, selected: any) => {
        console.log("Checked Data: ", data);
        const tempArr = props.isSubTable ? contextValue.selectedRowIdInSubTable : contextValue.selectedRowIdInTable;
        
        const updatedSelectedRowId = tempArr.includes(data) && selected
            ? tempArr.filter((item: any) => item !== data)
            : [...tempArr, data];
    
        props.isSubTable
            ? contextValue.setSelectedRowIdInSubTable(updatedSelectedRowId)
            : contextValue.setSelectedRowIdInTable(updatedSelectedRowId);
    }
 
    const handleAllRow = (data: any) => {
        const items = data.target.checked
            ? props.tableData.filter((val: any) => val.checkbox !== 'hide').map((val: any) => val.id)
            : [];

        props.isSubTable 
            ? contextValue.setSelectedRowIdInSubTable(items) 
            : contextValue.setSelectedRowIdInTable(items);
    }
     
    props.columns.map((val: any) => {
        tableColArr.push(createTableColumn <any> ({
            columnId: val.key,
            compare: (a, b) => {return a[val.key]?.label?.props?.children[1]?.localeCompare(b[val.key]?.label.props.children[1])},
            renderHeaderCell: () => val?.label,
            renderCell: (item) => {
                return (
                    <TableCellLayout 
                        truncate 
                        media={item[val.key]?.icon}
                    >
                        {item[val.key]?.label}
                    </TableCellLayout>
                );
            },
        }));
    });
 
    const columns: TableColumnDefinition <any> [] = tableColArr;
 
    const {
        getRows,
        selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected },
        sort: { getSortDirection, toggleColumnSort, sort }
    } = useTableFeatures (
        { columns, items },
        [
            useTableSelection ({
                selectionMode: "multiselect",
                defaultSelectedItems: new Set(props?.defaultSelected != undefined ? props?.defaultSelected : [])
            }),
            useTableSort ({
                defaultSortState: { 
                    sortColumn: props.columns[0].key, 
                    sortDirection: "ascending" 
                }
            })
        ]
    );
 
    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {toggleColumnSort(e, columnId)},
        sortDirection: getSortDirection(columnId)
    });
 
    const rows = sort (getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
            onKeyDown: (e: React.KeyboardEvent) => (e.key === " " && (e.preventDefault(), toggleRow(e, row.rowId))),
            onClick: (e: React.MouseEvent) => { 
                toggleRow(e, row.rowId); 
                handleRowSelect(row.item.id, selected) 
            }
        };
    }));
 
    const toggleAllKeydown = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => ( e.key === " " ? (toggleAllRows(e), e.preventDefault()) : null), [toggleAllRows]);
    
    useEffect(() => {
        const checkConnection = () => {
            const hasInternetConnection = navigator.onLine;
            setIsOnline(hasInternetConnection);
        };
        checkConnection();
        window.addEventListener('online', checkConnection);
        window.addEventListener('offline', checkConnection);
        return () => {
            window.removeEventListener('online', checkConnection);
            window.removeEventListener('offline', checkConnection);
        };
    }, []);
 
    return (
        <>
            { isOnline 
                ? // <ScrollablePane style={dataTableScroll}>
                    <Table aria-label="Table with multiselect">
                        <TableHeader key={0}>
                            <TableRow key={0}>
                                { selectAllCheckbox == "hide"
                                    ?   '' 
                                    :   <TableSelectionCell
                                            key={0}
                                            onClick={toggleAllRows}
                                            onKeyDown={toggleAllKeydown}
                                            onChange={handleAllRow}
                                            checkboxIndicator={{ "aria-label" : "Select all rows" }}
                                            checked={ allRowsSelected ? true : someRowsSelected ? "mixed" : false }
                                        />
                                }
                                
                                { props.columns.map((val: any, i: any) =>
                                    <TableHeaderCell key={i+1} {...headerSortProps(val?.key)}>
                                        {val?.label}
                                    </TableHeaderCell>
                                )}
                            </TableRow>
                        </TableHeader>

                        <TableBody key={1}>
                            { rows.map(({ item, selected, onClick, onKeyDown, appearance }) => (
                                <TableRow
                                    key={item?.id}
                                    aria-selected={selected}
                                    appearance={appearance} // onClick={onClick} // onKeyDown={onKeyDown}
                                >
                                    { selectAllCheckbox == 'hide' 
                                        ?   '' 
                                        :   (item?.checkbox == 'hide') 
                                                ?   <TableHeaderCell/> 
                                                :   <TableSelectionCell
                                                        checked={selected}
                                                        onClick={onClick}
                                                        onKeyDown={onKeyDown}
                                                        checkboxIndicator={{ "aria-label": "Select row" }}
                                                    />
                                    }
            
                                    { props.columns.map((val: any) =>
                                        <TableCell>
                                            <TableCellLayout 
                                                truncate 
                                                media={item[val.key]?.icon}
                                            >
                                                {item[val.key]?.label}
                                            </TableCellLayout>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> // </ScrollablePane>   
                : <SkeletonDataTable/>
            }
      </>
    );
};