import gql from 'graphql-tag';

const getUserDetails = gql`
  query getUserDetails($user_email: String) {
    core_Users(where: { user_email: { _eq: $user_email } }) {
      firstName
      lastName
      userid
      user_email
      formDataEdit
    }
  }
`;

export { getUserDetails };
