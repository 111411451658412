import { useState, useEffect, useContext } from "react";
import { Activity_log_func } from "../../../api/activity_log/activity_log_func";
import { SideMenu } from "../../../components/side_menu/inline_side_menu.component";
import { DataTable } from "../../../components/data_table/data_table.component";
import OverLay from "../../../components/side_menu/overlay_side_menu.component";
import { OverflowSetCustomExample } from "../../../components/side_menu/overFlowSet.component";
import { Stack, StackItem } from "@fluentui/react";
import { CSVLink } from 'react-csv';
import { MenuItem, Button, MenuList, MenuButton, Divider, Text, Badge, Body1Strong, Title3, Body1, Caption1, FluentProvider, webLightTheme, Theme, createLightTheme, createDarkTheme, BrandVariants, Input } from "@fluentui/react-components";
import { Info20Regular, GroupList20Regular, DesktopPulse20Regular, Wrench20Regular, ShieldQuestion20Regular, LockClosedKey20Regular, BookDatabase20Regular, BookTemplate20Regular, ChatMultipleHeart20Regular, Filter20Filled, FilterDismiss20Filled, Desktop20Filled, ArrowClockwise20Regular, ArrowDownload20Regular, Settings20Regular, ArrowMaximize16Regular, Dismiss16Regular, DismissSquare24Filled, ArrowMaximize20Regular, SettingsCogMultiple20Regular, Wand20Regular, CalendarClock20Regular, CheckboxCheckedSync20Regular, PulseSquare20Regular, ShieldLock20Regular, SquareHintApps20Regular, UninstallApp20Regular, AppsAddIn20Regular, BoxCheckmark20Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react-search-preview";
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs.component";
import { Pagination } from "../../../components/pagination/pagination.component";
import { useNavigate } from "react-router-dom";
import { MenuDropdown } from "../../../components/dropdown/menu.component";
import { DropdownComponent } from "../../../components/dropdown/dropdown.component";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { GlobalContext } from "../../../hooks/globalContext";
import { ToastComponent } from "../../../components/toast/toast.component";
import { exitIcon, fullscreenIcon, lightTheme, overflowDivider, primaryIcon } from "../../../styles/customStyles";
import { FileDownloadComponent } from "../../../components/fileExport/file.export.component";
 
const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};
 
export const ActivityLogs = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
 
  const [final_data, set_final_data]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [isRefresh, setRefresh] = useState(false);
  const[refreshToast,setRefreshToast]:any=useState(false);
  const [isToast, setIsToast] = useState(false);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1,setDropDownValue1] = useState<any>();
  const [dropDownValue2,setDropDownValue2] = useState<any>();
  const [isfilter,setIsFilter]=useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled,setResetDisabled]= useState(true);
  const [date,isDate]:any=useState();
  const [module,isModule]:any=useState();
  const[activity,isActivity]:any=useState('{}');
  const[initiated,isInitiated]:any=useState();
  const[action,isAction]:any=useState();
  const[status,isStatus]:any=useState();
  const [activityExportData, setActivityExportData] = useState<any>('No data available');

  const handleFilterArray = ():any => {
    const value1 = dropDownValue1
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== "" && value2 !== undefined && value2 !== "") {
      const result = value1 + " : " + value2;
      setFilterArray([...filterArray, result]);
      // setIsFilter(true);
    }
  }
  const inputChange=(e:any)=>{
    const enteredValue =e.target.value;
    console.log("entered value",enteredValue)
    let name =e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    } 
  }
 
  useEffect(() => {
   if(filterArray.length > 0)
   setResetDisabled(false)
  
  }, [filterArray])

  const handleCloseFilter = (index:any):any  => {
    console.log("cllciked");
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  }
 
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
 
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
 
  var menuItems: any = [];
 
  menuItems.push
  ( 
    <MenuItem
    onClick={() => navigate("/manage/user/alluser")} icon={
        <Stack horizontal tokens={{childrenGap:40}}>
          <StackItem><Text></Text></StackItem>
          <StackItem> <PulseSquare20Regular {...primaryIcon} /> </StackItem>
        </Stack>}>
        <Stack tokens={{childrenGap:30}} horizontal>
          <StackItem><Text></Text></StackItem>
          <StackItem> <Caption1 > All&nbsp;Users</Caption1> </StackItem>
          <StackItem> <Stack tokens={{childrenGap:48}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> 
          {/* SPACING IN INLINE SIDEBAR */}
        </Stack>
      </MenuItem>
  );
  
  
  menuItems.push
  (   <FluentProvider theme={lightTheme}> 
    <MenuItem onClick={() => navigate("/manage/user/activitylog")} icon={
      <Stack horizontal tokens={{childrenGap:40}}>
        <StackItem><Text></Text></StackItem>
        <StackItem> <BoxCheckmark20Regular {...primaryIcon}/> </StackItem>
      </Stack>}>
      <Stack tokens={{childrenGap:30}} horizontal>
        <StackItem><Text></Text></StackItem>
        <StackItem> <Caption1  > Activity Log </Caption1> </StackItem>
      </Stack>
    </MenuItem>
    </FluentProvider>
  );
  //inline side menu content code ends here
 
  //data table column name code starts here
  var columns = [
    { key: "date", label: <Body1Strong> Date </Body1Strong>  },
    { key: "page", label: <Body1Strong> Module </Body1Strong> },
    { key: "activity", label: <Body1Strong> Activity </Body1Strong> },
    { key: "initiated_by", label: <Body1Strong> Initiated by </Body1Strong> },
    { key: "action", label: <Body1Strong> Action </Body1Strong> },
    { key: "status", label: <Body1Strong> Status </Body1Strong> },
  ];
  //data table column name code ends here
   //data table for filter column
   var columns1 = [
    { key: "module", label: <Caption1> Module </Caption1>  },
    { key: "initiated_by", label: <Caption1> Initiated by </Caption1> },
    { key: "action", label: <Caption1> Action </Caption1> },
    { key: "status", label: <Caption1> Status </Caption1> },
  ];
 
  // overflow set contents code starts here
  var overFlowComponent = {
    data:
      <>
        <StackItem>
          <Button appearance="subtle" size="small" onClick={() => {setCurrentPageNo(1); setRefresh(true);}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <ArrowClockwise20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Refresh </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem>
 
        <StackItem>
          <CSVLink id="csv-export-link" data={exportData} filename="users_activitylog.csv">
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack tokens={{childrenGap:5}} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink>
        </StackItem>
 
        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ,
  };
  // overflow set contents code ends here
 
  var tempTableData: any = [];
  var menuLists: any = [];
 
  useEffect(() => {    
    var tempExportData: any = [];
   
    exportDetails?.length > 0 && exportDetails.map((val: any) => {
      tempExportData.push({
        date: val.time ? new Date(val.time * 1000).toLocaleString() : "-",
        page: val.pageName,
        activity: val.activity,
        initiated_by: val.initiatedBy,
        action: val.action,
        status: val.status,
      });
    });
 
    setExportData(tempExportData);
  }, [exportDetails]);
 
  useEffect(() => {
    // data table values append code starts here
    console.log("final_data=",final_data);
   
    final_data !== undefined && final_data !== null
      ? final_data.audit_ActivityLog.map((val: any, i: any) => {
        var activityVal = JSON.parse(val.activity).summary;
        
        tempTableData.push({
          id: val.ID,
          checkbox: "hide",
          date: { label: <Button appearance="transparent" size="small" onClick={()=>{setOverlayOpen(true);isActivity(JSON.parse(val.activity));isAction(val.action);isStatus(val.status);isInitiated(val.initiatedBy);isDate(new Date(val.time*1000).toLocaleString());isModule(val.pageName)}} > {val.time ? new Date(val.time *1000).toLocaleString() : "-"} </Button>, icon: "", id: `${i}` },
          page: { label: <Caption1> {val.pageName} </Caption1>, status: "" },
          activity: { label: <Caption1> {activityVal} </Caption1>},
          initiated_by: { label: <Caption1> {val.initiatedBy} </Caption1>},
          action: { label: <Caption1> {val.action} </Caption1>},
          status: { label: <Caption1> {val.status} </Caption1>},
        });
      }) : (tempTableData = []);
 
    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here
 
  useEffect(() => {
    var data = '';

    data = `Activity
            \nDate and time of activity - ${date}
            \nModule where the activity was performed - ${module}
            \nActivity - ${activity?.summary}
            \nInitiated by - ${initiated}
            \nAction - ${action}
            \nStatus - ${status}`;
    
    if(module === "Users"){
      data += `\n----------------------------------------
              \nTarget
              \nNot applicalble`;

      data += `\n----------------------------------------
              \nModified properties
              \nNot applicalble`;
    }else if(module === "Users"){
      if(action === 'Add'){
        data += `\n----------------------------------------
                \nTarget
                \nClassification name - ${activity.name}
                \nOperation type - ${activity.type}
                \nTotal number of devices added - ${activity.deviceCnt}`;

        data += `\n----------------------------------------\nModified Properties
                \nNot applicalble`;
      }else if(action === 'Delete'){
        data += `\n----------------------------------------\nTarget
                \nClassification name - ${activity.classificationNames}`;

        data += `\n----------------------------------------\nModified Properties
                \nNot applicalble`;
      }
    }else if (module === "Devices"){
      if(action === 'Edit'){
        data += `\n----------------------------------------\nTarget
                \nClassification name - ${activity.name}
                \nOperation type - ${activity.type}
                \nTotal number of devices modified - ${activity.deviceCnt}`;

        data += `\n----------------------------------------\nModified Properties
                \nDevices Names
                ${JSON.parse(activity).deviceList.split(",").map((val: any) => `\n${val}` )}`;
      }else{
        data += `\n----------------------------------------\nTarget
              \nNot applicalble`;

        data += `\n----------------------------------------\nModified Properties
                \nNot applicalble`;
      }
    }

    setActivityExportData(data);
  }, [activity]);
  //overlay code starts here
  var overLayData: any = {
    headerTitle:
    (
     <>
      <Stack>
       <Text size={600} weight="semibold"> Audit Log Details <FileDownloadComponent data={activityExportData} fileName="Activity_logg" /> </Text>
      </Stack>
     </>
    ),
    size: "large",
    isTabs: true,
    tabItems: {Activity:
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15, padding:10}}>
          <StackItem> 
            <Body1Strong> Date and time of activity </Body1Strong> - <Caption1>{date}</Caption1> 
          </StackItem>

          <StackItem> 
            <Body1Strong> Module where the activity was performed  </Body1Strong> - <Caption1>{module}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Activity </Body1Strong> - <Caption1>{activity?.summary}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Initiated By </Body1Strong> - <Caption1>{initiated}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Action </Body1Strong> - <Caption1>{action}</Caption1>
          </StackItem>

          <StackItem> 
            <Body1Strong> Status </Body1Strong> - <Caption1>{status}</Caption1>
          </StackItem>

        </Stack>
      </FluentProvider>,
      Targets: <Stack> Not Applicable </Stack>,
      "Modified Properties": <Stack> Not Applicable</Stack>},
    }
  //overlay code ends here
 
  return (
    <>
    <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{childrenGap:10}} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink> <Breadcrumbs pageName="Users" subPageName="" subPageLink="#" /> </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                   style={fullscreenIcon}
                    appearance="subtle" size="small" shape="square" onClick={handleFullScreen}
                    title={isFullScreen ? "Close Full Screen View" : "Open Full Screen View"}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon}/> : <ArrowMaximize20Regular {...primaryIcon}/>}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
    <Stack tokens={{childrenGap:20}} horizontal>
      <StackItem>
        {/* pages code starts here */}
        <Stack verticalAlign="center">
          <Stack tokens={{childrenGap:25}} horizontal>
              <StackItem> <Text></Text> </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Users | Activity Log </Title3>  
                </FluentProvider>
              </StackItem>
          </Stack>
 
          <Stack tokens={{childrenGap:20}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
         
        <FluentProvider theme={webLightTheme}>
          <Stack horizontal>
            {/* SPACING BETWEEN INLINE AND PAGE */}
            <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems}/>
            <StackItem>
              <StackItem> <Text></Text> </StackItem>
              <Stack tokens={{childrenGap:1}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
              <Stack verticalAlign="center" tokens={{childrenGap:10}}>
                <StackItem>
                  <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent}/>
                  { tableData.length > 0 ? <Divider/> : <Divider style={overflowDivider}/> }
                </StackItem>
                <StackItem>
                <StackItem>
                      <Stack horizontal tokens={{childrenGap:20}}>
                        <SearchBox dismiss={null} onChange={(e: any) => { setSearchVal(e.target.value); }} placeholder="Search" size="small"/>
                        <StackItem>
                          <MenuDropdown open={filterMenuOpen} btn=
                          {
                            <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                              <Stack tokens={{childrenGap:5}} horizontal>
                                <Filter20Filled {...primaryIcon}/>
                                <Body1> Filter </Body1>
                              </Stack>
                            </MenuButton>
                          } itemVal = {
                          <>
                            <Body1Strong> Add a Filter </Body1Strong>
                            <Divider/>
                            <MenuList>
                              <Stack tokens={{childrenGap:5}}>
                                <StackItem> <Text></Text> </StackItem>
                                <StackItem> <Caption1> Filter </Caption1> </StackItem>
                                <DropdownComponent optionData={columns1} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} />
                                <StackItem> <Divider/> </StackItem>
                                <StackItem> <Caption1> Value </Caption1> </StackItem>
                                <Input size= 'small' onChange={inputChange} name="filter" value={dropDownValue2}/>
                                <StackItem> <Divider /> </StackItem>
                                <Stack tokens={{childrenGap:10}} horizontal>
                                  <StackItem> <Button appearance="primary"onClick={()=>{handleFilterArray();setFilterMenuOpen(false)}}> <Caption1> Apply </Caption1> </Button> </StackItem>
                                  <StackItem> <Button onClick={() => setFilterMenuOpen(false)}> <Caption1> Cancel </Caption1> </Button> </StackItem>
                                </Stack>
                              </Stack>
                            </MenuList>
                          </>
                          }
                          />
                        </StackItem>
                        <StackItem>
                            {filterArray.length > 0 && filterArray.map((item,index) => (
                              <Button size="small" shape="circular">
                                <Stack tokens={{childrenGap:5}} horizontal>
                                  <StackItem> {item} </StackItem>
                                  <StackItem>
                                    <Stack tokens={{childrenGap:1}}>
                                      <StackItem><Text></Text></StackItem>
                                      <StackItem><Dismiss16Regular onClick={() => {handleCloseFilter(index)}}/></StackItem>
                                    </Stack>
                                  </StackItem>      
                                </Stack>
                              </Button>
                            ))}
                         </StackItem>
                        <StackItem onClick={() => {setFilterArray([]); setIsFilter(false); setDropDownValue2('');setResetDisabled(true);}}>
                          <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                            <Stack tokens={{childrenGap:5}} horizontal>
                              <StackItem> <FilterDismiss20Filled/> </StackItem>
                              <StackItem> <Caption1> Reset Filters </Caption1> </StackItem>
                            </Stack>
                          </Button>
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <Stack tokens={{childrenGap:10}}><StackItem><Text></Text></StackItem><StackItem><Text></Text></StackItem></Stack>
                    {tableData.length > 0 ? <Stack horizontal enableScopedSelectors horizontalAlign="space-between" >
                        <StackItem> {totalRecords > 0 && <Caption1>  Showing {((currentPageNo-1) * 50) + 1} to {(currentPageNo * 50) > totalRecords ? totalRecords : (currentPageNo * 50)} of {totalRecords} Records </Caption1>} </StackItem>
                        <StackItem> <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo}/> </StackItem>
                      </Stack> : <Stack horizontalAlign="start"  tokens={{childrenGap: 15}}><StackItem><Text></Text></StackItem><StackItem><Caption1>No Results.</Caption1></StackItem></Stack>}
                </StackItem>
               
                <Activity_log_func data={final_data} func={set_final_data} searchVal={searchVal} pageNo={(currentPageNo - 1) * 50} setTotalPages={setTotalPages} setTotalRecords={setTotalRecords} type="getLog" moduleName="users" setExportDetails={setExportDetails} setRefresh={setRefresh} isRefresh={isRefresh} setRefreshToast={setRefreshToast}/>
                {tableData.length > 0 && ( <DataTable tableData={tableData} columns={columns}/> )}
                {refreshToast && <ToastComponent setmessagebar={setIsToast} isToastEnabled={refreshToast} toastIntent="success" position="top-end" title="Success" message={'Data Table Refreshed'} footer="" />}
              </Stack>
            </StackItem>
          </Stack>
        </FluentProvider>
        </Stack>
        <OverLay
        overlayopen={overlayOpen}
        setOverlayOpen={setOverlayOpen}
        overlayData={overLayData}/>
      </StackItem>
      <StackItem><Text></Text></StackItem>
      </Stack>
    </FullScreen>
    </>
  );
};