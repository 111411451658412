import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { getdartTemplateQuery } from "../../graphql/all_automation_queries";

type dataProps = {
    set_final_data: any;
    searchVal: any;
    isRefresh?:any,
    setIsRefresh?:any
    setRefreshToast?:any
}

export const All_automation_func = (props: dataProps) => {
  const client = useApolloClient();

  const getdartTemplateList = async (whereCondition: any) => {
    const { data, loading, error } = await client.query({
      query: getdartTemplateQuery,
      variables: {whereCondition}
    });

    if(!loading && data){
      props.set_final_data(data.profile_DartTemplateDetails);
    }
  };

  useEffect(() => {
    if(props.searchVal?.length > 0 && props.searchVal !== ''){
      getdartTemplateList({templateName: {contains: props.searchVal}, dartNo: {_lt: 2000}, display: {_eq: 'yes'}}); 
    }else if(props.isRefresh){
      props.setIsRefresh(false);
      getdartTemplateList({display: {_eq: 'yes'}});
      props.setRefreshToast(true);  
      setTimeout(() => {
        props.setRefreshToast(false);  
      }, 3000);
    }
    else{
      getdartTemplateList({display: {_eq: 'yes'}, dartNo: {_lt: 2000}});
    }
  }, [props.searchVal, props.isRefresh]);

  return (
    <></>
  )
}
