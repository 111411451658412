import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getRemediationListQuery } from "../../graphql/remediation_queries";

import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages: any;
    setTotalRecords: any;
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    setExportDetails?: any
}

const UserSurvey_func = (props: any) => {
  console.log("props new data=",props?.newData);
  
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  var tenantName = contextValue.tenantName;

  const getUserSurveyList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getRemediationListQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.func(data);
    }
  };

  const getUserSurveyListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getRemediationListQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON.length)/50));
    }
  }
  
  useEffect(() => {    
    var dartid = parseInt(dartNo);

    if(props.searchVal?.length > 0 && props.searchVal !== ''){
        getUserSurveyList({automationName: {contains: props.searchVal}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
        getUserSurveyListCnt({automationName: {contains: props.searchVal}, types: {_eq: 'usersurvey'}});
    }
    else if(props.isFilter == true){
      if(props.dropDownValue1 == 'Name'){
        getUserSurveyList({automationName: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
        getUserSurveyListCnt({automationName: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}});
       }
      else if(props.dropDownValue1 == 'Created By'){
        getUserSurveyList({createdBy: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
        getUserSurveyListCnt({createdBy: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}});
      }else if(props.dropDownValue1 == 'Status'){
        getUserSurveyList({status: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
        getUserSurveyListCnt({status: {contains: props.dropDownValue2}, types: {_eq: 'usersurvey'}});
      }

    }
    else{
      getUserSurveyList({types: {_eq: 'usersurvey'}}, 50, parseInt(props.pageNo));
      getUserSurveyListCnt({types: {_eq: 'usersurvey'}});
    }

    if(props.isRefresh){
        props.setRefresh(false);
        props.setRefreshToast(true);  
          setTimeout(() => {
            props.setRefreshToast(false);  
          }, 3000); 
    }
    
  },[props.isRefresh, props.searchVal, props.pageNo, props.isFilter]);

  return (
    <></>
  )
}

export { UserSurvey_func }
