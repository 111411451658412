import { useState, useEffect, useContext } from 'react';
import { Census_func } from '../../../api/devices/census_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Input,
  Image,
  Card,
  InfoLabel,
  ButtonProps,
  Tooltip,
} from '@fluentui/react-components';
import {
  Info20Regular,
  Open20Regular,
  GroupList20Regular,
  TrayItemRemove20Filled,
  DesktopPulse20Regular,
  Wrench20Regular,
  ShieldQuestion20Regular,
  LockClosedKey20Regular,
  BookDatabase20Regular,
  BookTemplate20Regular,
  ChatMultipleHeart20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize20Regular,
  Dismiss16Regular,
  DismissSquare24Filled,
  FilterAdd20Filled,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { GlobalContext } from '../../../hooks/globalContext';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import { ToastComponent } from '../../../components/toast/toast.component';
import { fullscreenIcon, lightTheme, messageTheme, primaryIcon, exitIcon, secondaryIcon, overflowDivider } from '../../../styles/customStyles';
import { FileDownloadComponent } from '../../../components/fileExport/file.export.component';
import FileUploadComponent from '../../../components/fileImport/file.import.component';
import { CensusData, CheckboxState } from '../../../interfaces/devices/census_interface';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';

const handleExportCSV = () => {
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Census = () => {
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  var tenantName = contextValue.tenantName;

  const [final_data, set_final_data]: any = useState<CensusData[] | null>(null);
  const [tableData, setTableData] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData]: any = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [isfilter, setIsFilter] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [machineStatus, setMachineStatus]: any = useState({});
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [overLayData, setOverLayData] = useState<any>({});
  const [selectedValue, setSelectedValue] = useState(0);
  const [deviceSearchVal, setDeviceSearchVal] = useState('');
  const [devicesTableData, setDevicesTableData] = useState([]);
  const [downloadFileDeviceList, setDownloadFileDeviceList] = useState<any>(['"Machines"']);
  const [csvDevicesList, setCsvDevicesList] = useState<any>([]);
  const [devicesOfTenant, setDevicesOfTenant] = useState<any>(null);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [isExpungeMachineData, setIsExpungeMachineData] = useState<boolean>(false);
  const [deleteDevices, setDeleteDevices] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);
  const [isChecked, setChecked] = useState<CheckboxState>({
    expungeAsset: true,
    expungeEvent: true,
    expungePatch: true,
  });
  const [permissions, setPermissions] = useState(['']);

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;

    setChecked(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value1 !== '' && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };
  const handleFilterValueSelect = (event: { target: { value: Event } }): void => {
    alert(event.target.value);
  };
  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  useEffect(() => {
    console.log('dropDownValue1=', dropDownValue1);

    dropDownValue1 === 'Choose devices manually' && setSelectedValue(1);
    dropDownValue1 === 'Upload a CSV containing the Devices' && setSelectedValue(2);
  }, [dropDownValue1]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  var dropdownData = [
    { key: 'op_1', label: <Body1 onClick={() => setSelectedValue(1)}> Choose devices manually </Body1> },
    { key: 'op_2', label: <Body1 onClick={() => setSelectedValue(2)}> Upload a CSV containing the Devices </Body1> },
  ];
  var overlayColumns = [
    { key: 'device_name', label: <Body1Strong> Device Name</Body1Strong> },
    { key: 'os', label: <Body1Strong> Operating System </Body1Strong> },
  ];

  var menuItems: any = [];

  if (permissions.includes('overview_Read') || permissions.includes('overview_ReadandExport') || permissions.includes('overview_Fullaccess')) {
    menuItems.push(
      <FluentProvider theme={lightTheme}>
        <MenuItem
          key={0}
          onClick={() => navigate('/manage/devices/overview')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Info20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> Overview </Caption1>
            </StackItem>
            <StackItem>
              <Stack tokens={{ childrenGap: 80 }} horizontal>
                <StackItem>
                  <Text></Text>
                </StackItem>
                <StackItem>
                  <Text></Text>
                </StackItem>
              </Stack>
            </StackItem>
            {/* SPACING IN INLINE SIDEBAR */}
          </Stack>
        </MenuItem>
      </FluentProvider>,
    );
  }

  if (
    permissions.includes('deviceClassification_Read') ||
    permissions.includes('deviceClassification_ReadandExport') ||
    permissions.includes('deviceClassification_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        key={1}
        onClick={() => navigate('/manage/devices/device_classification')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <GroupList20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Device Classification </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      key={2}
      onClick={() => navigate('/manage/devices/monitor')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <DesktopPulse20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Monitors </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  if (
    permissions.includes('remediation_Read') ||
    permissions.includes('remediation_ReadandExport') ||
    permissions.includes('remediation_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        key={3}
        onClick={() => navigate('/manage/devices/remediation')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Wrench20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Remediations </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      key={4}
      onClick={() => navigate('/manage/devices/compliancepolicies')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ShieldQuestion20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key={5}
      onClick={() => navigate('/manage/devices/conditionalaccess')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <LockClosedKey20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Conditional Access </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  if (
    permissions.includes('userSurveys_Read') ||
    permissions.includes('userSurveys_ReadandExport') ||
    permissions.includes('userSurveys_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        key={6}
        onClick={() => navigate('/manage/devices/usersurvey')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <ChatMultipleHeart20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> User Survey </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      key={7}
      onClick={() => navigate('/manage/devices/datacollection')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookDatabase20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Data Collection </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  if (
    permissions.includes('activityLog_Read') ||
    permissions.includes('activityLog_ReadandExport') ||
    permissions.includes('activityLog_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        key={8}
        onClick={() => navigate('/manage/devices/activitylog')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <BookTemplate20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Activity Log </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'device_name', label: <Body1Strong>&nbsp; Device Name </Body1Strong> },
    { key: 'manufacturer', label: <Body1Strong>&nbsp;&nbsp; Manufacturer </Body1Strong> },
    { key: 'os', label: <Body1Strong> Operating System </Body1Strong> },
    { key: 'installation_date', label: <Body1Strong> Installation Date </Body1Strong> },
    { key: 'nanoheal_version', label: <Body1Strong> Nanoheal Version </Body1Strong> },
    { key: 'last_event', label: <Body1Strong> Last Event </Body1Strong> },
  ];
  var columns1 = [
    { key: 'device_name', label: <Caption1> Device Name </Caption1> },
    { key: 'manufacturer', label: <Caption1> Manufacturer </Caption1> },
    { key: 'os', label: <Caption1> Operating System </Caption1> },
    { key: 'nanoheal_version', label: <Caption1> Nanoheal Version </Caption1> },
    { key: 'online_status', label: <Caption1> Online Status </Caption1> },
    { key: 'last_checkin_hours', label: <Caption1> Last Check-in in 24 Hours </Caption1> },
    { key: 'last_checkin_week', label: <Caption1> Last Check-in in 1 Week </Caption1> },
    { key: 'installation_date', label: <Caption1> Install Date </Caption1> },
  ];
  //data table column name code ends here
  const [isIconEnabled, setIconEnabled] = useState(false);
  const handleIconActive: any = (data: any) => {
    setIconEnabled(data.target.checked);
  };

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          {/* <Button appearance="subtle" size="small" onClick={() => {setOverlayOpen(true); setChecked({expungeAsset: true, expungeEvent: true, expungePatch: true})}}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <TrayItemRemove20Filled {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Expunge </Caption1> </StackItem>
            </Stack>
          </Button> */}

          {permissions.includes('overview_Read') || permissions.includes('overview_ReadandExport') ? (
            <TooltipHost content="You do not have access">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <TrayItemRemove20Filled {...secondaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Expunge </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('overview_Fullaccess') ? (
            <Button
              appearance="subtle"
              size="small"
              onClick={() => {
                setOverlayOpen(true);
                setChecked({ expungeAsset: true, expungeEvent: true, expungePatch: true });
              }}
            >
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem>
                  <TrayItemRemove20Filled {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Expunge </Caption1>
                </StackItem>
              </Stack>
            </Button>
          ) : (
            <StackItem>
              <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
                <Button appearance="subtle" size="small" disabled>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem>
                          <TrayItemRemove20Filled {...secondaryIcon} />
                        </StackItem>
                        <StackItem>
                          <Caption1> Expunge </Caption1>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              </Tooltip>
            </StackItem>
          )}
        </StackItem>
        <StackItem>{/* <Divider vertical></Divider> */}</StackItem>
        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>{/* <Divider vertical></Divider> */}</StackItem>

        <StackItem>
          {permissions.includes('overview_Read') ? (
            <TooltipHost content="This feature is not available for you!">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...secondaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('overview_Fullaccess') || permissions.includes('overview_ReadandExport') ? (
            <CSVLink id="csv-export-link" data={exportData} filename={`${tenantName}_overview.csv`}>
              <Button
                appearance="subtle"
                size="small"
                onClick={() => {
                  userActivity('Export', `{"summary":"Device list exported"}`);
                }}
              >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </CSVLink>
          ) : (
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <ArrowDownload20Regular {...secondaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          )}

          {/* <CSVLink id="csv-export-link" data={exportData} filename={`${tenantName}_overview.csv`}>
            <Button appearance="subtle" size="small" onClick={() => { userActivity('Export', `{"summary":"Device list exported"}`); }}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];
  var tempTableData1: any = [];
  var menuLists: any = [];

  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        tempExportData.push({
          site_name: tenantName,
          device_name: val.host,
          manufacturer: val.Census_AssestData.length > 0 ? val.Census_AssestData[0].value.chassismanufacturer : '-',
          os: val.os,
          osversion: val.osversion,
          nanoheal_version: val.clientversion,
          installation_date: new Date(val.born * 1000).toLocaleString(),
          last_event: new Date(val.last * 1000).toLocaleString(),
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here

    //roles and permissions
    async function loadRoute() {
      console.log('role:-', sessionStorage.getItem('role'));
      const role = sessionStorage.getItem('role');
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    final_data !== undefined && final_data !== null
      ? final_data?.core_Census.map((val: any, i: any) => {
          var deviceStatus: any =
            Object.keys(machineStatus)?.length > 0 && machineStatus[`${val.host}`]?.length > 0 ? machineStatus[`${val.host}`][0] : 'offline';
          var deviceType: any =
            Object.keys(machineStatus)?.length > 0 && machineStatus[`${val.host}`]?.length > 0 ? machineStatus[`${val.host}`][1] : 'default';

          var currentIcon = <Badge size="extra-small" appearance="filled" color={deviceStatus === 'online' ? 'success' : 'informative'}></Badge>;

          if (deviceStatus === 'online') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/G_Win.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/G_Android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/G_Apple.svg" alt="Analyze Icon" />;
            }
          } else if (deviceStatus === 'offline') {
            if (deviceType == 1) {
              currentIcon = <Image src="/visualization/v2/image/Windows.svg" alt="Analyze Icon" />;
            } else if (deviceType == 2) {
              currentIcon = <Image src="/visualization/v2/image/android.svg" alt="Analyze Icon" />;
            } else if (deviceType == 5) {
              currentIcon = <Image src="/visualization/v2/image/apple.svg" alt="Analyze Icon" />;
            }
          }

          tempTableData.push({
            checkbox: 'hide',
            device_name: { label: <Caption1> {val.host} </Caption1>, icon: currentIcon, id: `${i}` },
            manufacturer: {
              label: <Caption1>&nbsp;&nbsp; {val.Census_AssestData.length > 0 ? val.Census_AssestData[0].value.chassismanufacturer : '-'} </Caption1>,
              status: '',
            },
            os: { label: <Caption1> {val.os} </Caption1> },
            osversion: { label: <Caption1> {val.clientversion} </Caption1> },
            nanoheal_version: { label: <Caption1> {val.clientversion} </Caption1> },
            installation_date: {
              label: (
                <Caption1>
                  {new Date(val.born * 1000).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                  })}
                </Caption1>
              ),
            },
            last_event: {
              label: (
                <Caption1>
                  {new Date(val.last * 1000).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                  })}
                </Caption1>
              ),
            },
          });
        })
      : (tempTableData = []);
    tempTableData.length > 0 ? setTableData(tempTableData) : setTableData([]);
  }, [final_data, machineStatus]);
  // data table values append code ends here

  const searchHandle = (data: any) => {
    var data = data.toLowerCase();
    var searchData = exportDetails.filter((val: any) => val.host.toLowerCase().includes(data));

    var searchedDataObj: any = { core_Census: [] };
    searchedDataObj.core_Census = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil(searchData.length / 50));
    setCurrentPageNo(1);
  };

  useEffect(() => {
    var defaultSelected: any = [];

    setOverLayData({
      size: 'large',
      isTabs: false,

      headerTitle: (
        <>
          <FluentProvider theme={webLightTheme}>
            <Title3> Expunge </Title3>
          </FluentProvider>
        </>
      ),

      tabItems: {},

      bodyData: (
        <>
          <FluentProvider theme={webLightTheme}>
            <Stack horizontal>
              <StackItem>
                <Stack tokens={{ childrenGap: 20 }}>
                  <StackItem>
                    <Body1>How would you like to expunge the data? </Body1>
                  </StackItem>
                  <StackItem>
                    <DropdownComponent optionData={dropdownData} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                    {(selectedValue === 1 || selectedValue === 2) && (
                      <Stack horizontal tokens={{ childrenGap: 1 }}>
                        <StackItem>
                          <CheckBox
                            name="expungeAsset"
                            checked={isChecked.expungeAsset}
                            onChange={(e: any) => {
                              handleCheckboxChange(e);
                            }}
                            label={<Caption1>Expunge Asset</Caption1>}
                          ></CheckBox>
                        </StackItem>
                        <StackItem>
                          <CheckBox
                            name="expungeEvent"
                            checked={isChecked.expungeEvent}
                            onChange={(e: any) => {
                              handleCheckboxChange(e);
                            }}
                            label={<Caption1>Expunge Event</Caption1>}
                          ></CheckBox>
                        </StackItem>
                        <StackItem>
                          <CheckBox
                            name="expungePatch"
                            checked={isChecked.expungePatch}
                            onChange={(e: any) => {
                              handleCheckboxChange(e);
                            }}
                            label={<Caption1>Expunge Patch</Caption1>}
                          ></CheckBox>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <InfoLabel size="large" info="Here you can select the what things you want to expunge"></InfoLabel>
                        </StackItem>
                      </Stack>
                    )}
                  </StackItem>
                  <StackItem>
                    {selectedValue === 1 && (
                      <>
                        <FluentProvider theme={webLightTheme}>
                          <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <StackItem>
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  {/* <MessageBar shape="rounded">
                                          <MessageBarBody> <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1> </MessageBarBody>
                                      </MessageBar> */}
                                  <FluentProvider theme={messageTheme}>
                                    <Card appearance="subtle" size="small">
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        {/* <StackItem> <Info20Filled {...primaryIcon}/> </StackItem> */}
                                        <StackItem>
                                          <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>
                                        </StackItem>
                                      </Stack>
                                    </Card>
                                  </FluentProvider>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <SearchBox
                                      onChange={(e: any) => {
                                        setDeviceSearchVal(e.target.value);
                                      }}
                                      placeholder="Search"
                                      size="small"
                                    />
                                    <StackItem>
                                      <MenuDropdown
                                        btn={
                                          <MenuButton shape="circular" size="small">
                                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                                              <Filter20Filled {...primaryIcon} />
                                              <Body1> Filter </Body1>
                                            </Stack>
                                          </MenuButton>
                                        }
                                        itemVal={
                                          <>
                                            <Body1Strong> Add a Filter </Body1Strong>
                                            <Divider />
                                            <MenuList>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Caption1> Filter </Caption1>
                                                </StackItem>
                                                <DropdownComponent optionData={columns} />
                                                <StackItem>
                                                  <Divider />
                                                </StackItem>
                                                <StackItem>
                                                  <Caption1> Value </Caption1>
                                                </StackItem>
                                                <DropdownComponent optionData={columns} />
                                                <StackItem>
                                                  <Divider />
                                                </StackItem>
                                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                  <StackItem>
                                                    <Button appearance="primary">
                                                      <Caption1> Apply </Caption1>
                                                    </Button>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Button>
                                                      <Caption1> Cancel </Caption1>
                                                    </Button>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            </MenuList>
                                          </>
                                        }
                                      />
                                    </StackItem>
                                    <StackItem>
                                      <Button appearance="subtle" size="small">
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem>
                                            <FilterDismiss20Filled />
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Reset Filters </Caption1>
                                          </StackItem>
                                        </Stack>
                                      </Button>
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <FluentProvider theme={webLightTheme}>
                                      {/* {tableData.length > 0 && ( <DataTable tableData={tableData} columns={overlayColumns}/>)} */}
                                      {devicesTableData.length > 0 ? (
                                        <DataTable
                                          tableData={devicesTableData}
                                          columns={overlayColumns}
                                          isSubTable={true}
                                          defaultSelected={defaultSelected}
                                        />
                                      ) : (
                                        <Stack>
                                          <StackItem>
                                            <Caption1> No new devices found.</Caption1>
                                          </StackItem>
                                        </Stack>
                                      )}
                                    </FluentProvider>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>

                            {/* {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) && <Divider vertical />}

                          <StackItem>
                            <StackItem>
                                {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) &&  <DataTable tableData={selectedTableData} columns={[{ key: "selected", label: <Body1Strong> Selected ({contextValue.selectedRowIdInSubTable.length})</Body1Strong>}]} />}
                            </StackItem>
                          </StackItem>*/}
                          </Stack>
                        </FluentProvider>
                      </>
                    )}

                    {selectedValue === 2 && (
                      <>
                        <Stack tokens={{ childrenGap: 15 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          {/* <StackItem>
                        <Stack tokens={{childrenGap:0}} >
                          <StackItem> <Body1> Start by downloading a file with the list of devices from the sites you want. </Body1> </StackItem>
                          <StackItem> <Body1> You can choose devices from the same tenant to create groups. </Body1> <FileDownloadComponent data={downloadFileDeviceList} fileName="devices_list" /> </StackItem>
                        </Stack>
                      </StackItem> */}

                          {/* <StackItem><Text></Text></StackItem> */}
                          <StackItem>
                            <Body1Strong> Steps to create the CSV group </Body1Strong>
                          </StackItem>
                          <StackItem>
                            <Body1> 1. Download the file that contains the list of devices </Body1>
                          </StackItem>
                          <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <FileDownloadComponent data={downloadFileDeviceList} fileName="devices_list" />
                            </StackItem>
                          </Stack>
                          <Stack>
                            <StackItem>
                              <Body1> 2. Revise device grouping in the CSV. </Body1>
                            </StackItem>
                            <StackItem>
                              <Stack tokens={{ childrenGap: 15 }} horizontal>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                                <StackItem>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem>
                                      <Caption1> Watch the instructional video for guidance </Caption1>
                                    </StackItem>
                                    <StackItem>
                                      <Open20Regular />
                                    </StackItem>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>
                          </Stack>

                          <StackItem>
                            <Body1> 3. Upload your .csv file </Body1>
                          </StackItem>
                          <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              {/* <DropdownComponent optionData={ddData} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1}/> */}
                              <FileUploadComponent setDeviceList={setCsvDevicesList} />
                            </StackItem>
                          </Stack>

                          {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length > 0 && (
                            <>
                              <StackItem>
                                <Text>{selectedTableData.length} unique machine found.</Text>
                              </StackItem>
                              <StackItem>
                                <Text>
                                  Kindly check the group names and confirm that everything is exactly the way you need them. If you have made any
                                  mistake,Kindly make the changes and upload the CSV with the corrections
                                </Text>
                              </StackItem>
                            </>
                          )}

                          {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length == 0 && (
                            <StackItem>
                              <Text>No unique machine found</Text>
                            </StackItem>
                          )}
                        </Stack>
                      </>
                    )}
                  </StackItem>
                </Stack>
              </StackItem>

              {selectedValue === 1 && (
                <>
                  <Divider vertical />
                  <StackItem>
                    {(contextValue.selectedRowIdInSubTable.length >= 0 || selectedTableData.length >= 0) && (
                      <StackItem>
                        <Stack horizontal>
                          {/* <Label> <Body1Strong> Selected </Body1Strong> </Label> */}
                          <StackItem>
                            <DataTable
                              tableData={selectedTableData}
                              columns={[
                                {
                                  key: 'selected',
                                  label: (
                                    <Body1Strong>
                                      Selected ({contextValue.selectedRowIdInSubTable.length})
                                      <Button appearance="transparent" size="small" onClick={() => contextValue.setSelectedRowIdInSubTable([])}>
                                        Reset
                                      </Button>
                                    </Body1Strong>
                                  ),
                                },
                              ]}
                            />
                          </StackItem>
                        </Stack>
                      </StackItem>
                    )}
                  </StackItem>
                </>
              )}
            </Stack>
          </FluentProvider>
          {/* THIS SHOULD BE FETCHED FROM THE BACKEND */}
        </>
      ),

      footerData: (
        <>
          {selectedValue == 1 ? (
            <Button
              appearance="primary"
              disabled={contextValue.selectedRowIdInSubTable?.length > 0 ? false : true}
              onClick={() => {
                setIsExpungeMachineData(true);
                userActivity(
                  'Expunge',
                  `{"deviceCnt":"${selectedTableData.length}","type":"Manual","deviceList":"${contextValue.selectedRowIdInSubTable}","summary":"${
                    contextValue.selectedRowIdInSubTable?.length
                  } ${contextValue.selectedRowIdInSubTable?.length == 1 ? 'device' : 'devices'} expunged manually"}`,
                );
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              appearance="primary"
              disabled={selectedTableData.length > 0 ? false : true}
              onClick={() => {
                setIsExpungeMachineData(true);
                userActivity(
                  'Expunge',
                  `{"deviceCnt":"${selectedTableData.length}","type":"CSV","deviceList":"${contextValue.selectedRowIdInSubTable}","summary":"${
                    selectedTableData.length
                  } ${contextValue.selectedRowIdInSubTable?.length == 1 ? 'device' : 'devices'} expunged through csv"}`,
                );
              }}
            >
              Submit
            </Button>
          )}
        </>

        // <>
        //     <Button appearance="primary" disabled={(selectedTableData.length == 0) ? true : false } size="small" onClick={() => {setAddDevices(true);}}> Submit </Button>
        // </>
      ),
    });
  }, [devicesTableData, selectedValue, selectedTableData, isChecked]);

  var validDevicesList: any = [];
  var inValidDevicesList: any = [];
  var alldDevicesList: any = [];
  useEffect(() => {
    if (csvDevicesList.length > 0) {
      validDevicesList = [];
      inValidDevicesList = [];
      alldDevicesList = [];

      devicesOfTenant.core_Census.map((val: any) => {
        alldDevicesList.push(val.host);
      });

      csvDevicesList.map((val: any) => {
        var deviceName = val.replace(/[\r\n]/g, '');
        alldDevicesList.includes(deviceName) ? validDevicesList.push(deviceName) : inValidDevicesList.push(deviceName);
      });

      validDevicesList.length > 0 ? setSelectedTableData(validDevicesList) : setSelectedTableData([]);

      contextValue.setSelectedRowIdInSubTable(validDevicesList);
    }
  }, [devicesOfTenant, csvDevicesList]);

  useEffect(() => {
    !overlayOpen && setSelectedValue(0);
  }, [overlayOpen]);

  useEffect(() => {
    console.log('after deletion=', deleteDevices);

    !deleteDevices && contextValue.setSelectedRowIdInTable([]);
    !deleteDevices && contextValue.setSelectedRowIdInSubTable([]);
  }, [deleteDevices]);

  useEffect(() => {
    console.log('SelectedRowIdInTable=', contextValue.selectedRowIdInTable);
  }, [contextValue.selectedRowIdInTable]);

  useEffect(() => {
    // devices data table values append code starts here
    final_data !== undefined && final_data !== null
      ? final_data.core_Census?.map((val: any, i: any) => {
          if (!selectedDevices.includes(val.host) && !contextValue.selectedRowIdInSubTable.includes(val.host)) {
            tempTableData1.push({
              id: `${val.id}-${val.host}`,
              device_name: { label: <Caption1> {val.host} </Caption1>, id: `${i}` },
              os: { label: <Caption1> {val.os} </Caption1>, status: '' },
            });
          }
        })
      : (tempTableData1 = []);

    tempTableData1.length > 0 ? setDevicesTableData(tempTableData1) : setDevicesTableData([]);
  }, [final_data, selectedDevices]);
  useEffect(() => {
    // contextValue.setSelectedRowIdInSubTable(selectedDevices);

    var newSelectedValues = selectedDevices.concat(contextValue.selectedRowIdInSubTable);
    const uniqueSet = new Set(newSelectedValues);
    const resultArray = Array.from(uniqueSet);

    contextValue.setSelectedRowIdInSubTable(resultArray);
  }, [selectedDevices]);

  var dataArr: any = [];
  useEffect(() => {
    console.log('sub table=', contextValue.selectedRowIdInSubTable);

    contextValue.selectedRowIdInSubTable.map((val: any, i: any) => {
      dataArr.push({
        id: i,
        checkbox: 'hide',
        selected: { label: <Caption1> {val.split('-')[1]} </Caption1>, id: `${i}` },
      });
    });

    dataArr.length > 0 ? setSelectedTableData(dataArr) : setSelectedTableData([]);

    contextValue.selectedRowIdInTable.length > 0 ? setIconEnabled(true) : setIconEnabled(false);
  }, [contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Overview',
      moduleName: 'Devices',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Devices" subPageName="" subPageLink="#" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>

        <Stack tokens={{ childrenGap: 20 }} horizontal>
          <StackItem>
            {/* pages code starts here */}
            <Stack verticalAlign="center">
              <Stack tokens={{ childrenGap: 25 }} horizontal>
                <StackItem>
                  <Text></Text>
                </StackItem>
                <StackItem>
                  <FluentProvider theme={webLightTheme}>
                    <Title3> Devices | Overview </Title3>
                  </FluentProvider>
                </StackItem>
              </Stack>

              <Stack tokens={{ childrenGap: 20 }}>
                <StackItem>
                  <Text></Text>
                </StackItem>
                <StackItem>
                  <Text></Text>
                </StackItem>
              </Stack>

              <FluentProvider theme={webLightTheme}>
                <Stack horizontal>
                  {/* SPACING BETWEEN INLINE AND PAGE */}
                  <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                  <StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <Stack tokens={{ childrenGap: 1 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>
                    <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />

                        {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                      </StackItem>
                      <StackItem>
                        <StackItem>
                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <SearchBox
                              onChange={(e: any) => {
                                setSearchVal(e.target.value);
                              }}
                              value={searchVal}
                              placeholder="Search"
                              size="small"
                              dismiss={null}
                              contentAfter={<DismissButton aria-label="Clear" />}
                            />
                            <StackItem>
                              <MenuDropdown
                                open={filterMenuOpen}
                                btn={
                                  <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <FilterAdd20Filled {...primaryIcon} />
                                      <Body1> Filter </Body1>
                                    </Stack>
                                  </MenuButton>
                                }
                                itemVal={
                                  <>
                                    <Body1Strong> Add a Filter </Body1Strong>
                                    <Divider />
                                    <MenuList>
                                      <Stack tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Caption1> Filter </Caption1>
                                        </StackItem>
                                        <DropdownComponent
                                          optionData={columns1}
                                          setDropDownValue={setDropDownValue1}
                                          dropDownvalue={dropDownValue1}
                                        />
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem>
                                          <Divider />
                                        </StackItem>
                                        <StackItem>
                                          <Caption1> Value </Caption1>
                                        </StackItem>
                                        {/* <DropdownComponent optionData={columns}  setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                                        <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                        {/* <DropdownComponent optionData={columns}/> */}
                                        <StackItem>
                                          <Divider />
                                        </StackItem>
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                          <StackItem>
                                            <Button
                                              appearance="primary"
                                              onClick={() => {
                                                handleFilterArray();
                                                setFilterMenuOpen(false);
                                              }}
                                            >
                                              <Caption1> Apply </Caption1>
                                            </Button>
                                          </StackItem>
                                          <StackItem>
                                            <Button onClick={() => setFilterMenuOpen(false)}>
                                              <Caption1> Cancel </Caption1>
                                            </Button>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    </MenuList>
                                  </>
                                }
                              />
                            </StackItem>
                            <StackItem>
                              {filterArray.length > 0 &&
                                filterArray.map((item, index) => (
                                  <Button size="small" shape="circular">
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem> {item} </StackItem>
                                      <StackItem>
                                        <Stack tokens={{ childrenGap: 1 }}>
                                          <StackItem>
                                            <Text></Text>
                                          </StackItem>
                                          <StackItem>
                                            <Dismiss16Regular
                                              onClick={() => {
                                                handleCloseFilter(index);
                                              }}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </Button>
                                ))}
                            </StackItem>
                            <StackItem
                              onClick={() => {
                                setFilterArray([]);
                                setIsFilter(false);
                                setDropDownValue2('');
                                setResetDisabled(true);
                              }}
                            >
                              <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                  <StackItem>
                                    <FilterDismiss20Filled />
                                  </StackItem>
                                  <StackItem>
                                    <Caption1> Reset Filters </Caption1>
                                  </StackItem>
                                </Stack>
                              </Button>
                            </StackItem>
                          </Stack>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                        </Stack>
                        {tableData.length > 0 ? (
                          <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                            <StackItem>
                              {totalRecords > 0 && (
                                <Caption1>
                                  Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                                  {totalRecords} Records
                                </Caption1>
                              )}
                            </StackItem>
                            <StackItem>
                              <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                            </StackItem>
                          </Stack>
                        ) : (
                          <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Caption1> No Results.</Caption1>
                            </StackItem>
                          </Stack>
                        )}
                      </StackItem>

                      <Census_func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                        setExportDetails={setExportDetails}
                        setRefreshToast={setRefreshToast}
                        setIsRefresh={setIsRefresh}
                        refreshToast={refreshToast}
                        isRefresh={isRefresh}
                        setMachineStatus={setMachineStatus}
                        isExpungeMachineData={isExpungeMachineData}
                        setIsExpungeMachineData={setIsExpungeMachineData}
                        isChecked={isChecked}
                      />

                      {refreshToast && (
                        <ToastComponent
                          setmessagebar={setIsToast}
                          isToastEnabled={refreshToast}
                          toastIntent="success"
                          position="top-end"
                          title="Success"
                          message={'Data Table Refreshed'}
                          footer=""
                        />
                      )}

                      {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}

                      {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                    </Stack>
                  </StackItem>
                </Stack>
              </FluentProvider>
            </Stack>
            <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />
          </StackItem>
          <StackItem>
            <Text></Text>
          </StackItem>
        </Stack>
      </FullScreen>
    </>
  );
};
