import { gql } from '@apollo/client';

//  Apps| App Installation profiles
// list of all apps

const getSwdListsQuery = gql`
  query MyQuery($whereCondition: JSON, $limit: Int!, $offset: Int! = 0) {
    softinst_Packages(where: $whereCondition, limit: $limit, offset: $offset) {
      id
      app_packageId
      packageName
      packageDesc
      version
      platform
      status
      user
      lastModified
      creationTime
    }
  }
`;

const getSwdListsCntQuery = gql`
  query MyQuery($whereCondition: JSON) {
    softinst_Packages(where: $whereCondition) {
      id
      packageName
      packageDesc
      version
      platform
      status
      user
      lastModified
      status
    }
  }
`;

// Query to add new software package along with its application details
const addSwdPackageQuery = gql`
  mutation MyMutation(
    $app_packageId: String
    $packageName: String = "pakagegql"
    $PackageDesc: String = "packagedescgql"
    $version: String = "7.0"
    $platform: String = "windows"
    $applicationName: String = "microsoftnanoheal"
    $config3264type: String = "No"
    $path: String = null
    $path2: String = null
    $creationTime: String = null
    $status: String = null
  ) {
    insert_softinst_Packages(
      objects: {
        app_packageId: $app_packageId
        packageName: $packageName
        packageDesc: $PackageDesc
        version: $version
        platform: $platform
        applicationName: $applicationName
        config3264type: $config3264type
        path: $path
        path2: $path2
        creationTime: $creationTime
        status: $status
      }
    ) {
      returning {
        id
        app_packageId
        packageDesc
        packageName
        version
        platform
        applicationName
        path
        path2
        creationTime
      }
      affected_rows
    }
  }
`;
const addSwdPackageConfigurationQuery = gql`
  mutation MyMutation(
    $packageId: Int!
    $config32bitFinal: String
    $config64bitFinal: String
    $edConfig: String = "final-format here"
    $formDataPkgVal: String
    $formDataAppVal: String
    $formDataAdvVal: String
  ) {
    insert_softinst_PackagesConfiguration_new(
      objects: {
        packageId: $packageId
        Config32bit: $config32bitFinal
        Config64bit: $config64bitFinal
        edConfig: $edConfig
        formDataPkg: $formDataPkgVal
        formDataApp: $formDataAppVal
        formDataAdv: $formDataAdvVal
      }
    ) {
      affected_rows
    }
  }
`;
const getJsonSchemaQueryPackageDetails = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1501 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getJsonSchemaQueryAppConfiguration = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1502 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getJsonSchemaQueryAppConfigurationU = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1504 } }) {
      jsonSchema
      uiSchema
    }
  }
`;
const getJsonSchemaQueryAdvanceConfiguration = gql`
  query MyQuery {
    core_DartJsonSchema(where: { dartid: { _eq: 1503 } }) {
      jsonSchema
      uiSchema
    }
  }
`;

const deleteSwdPackage = gql`
  mutation MyMutation($_eq: [String!] = []) {
    delete_softinst_Packages(where: { app_packageId: { _in: $_eq } }) {
      affected_rows
      returning {
        id
        app_packageId
        packageName
        packageDesc
      }
    }
  }
`;

const deleteConfignew = gql`
  mutation MyMutation($isdeleteList: [Int] = []) {
    delete_softinst_PackagesConfiguration_new(where: { packageId: { _in: $isdeleteList } }) {
      affected_rows
    }
  }
`;

const getswdForm = gql`
  query MyQuery($app_packageiD: Int) {
    softinst_PackagesConfiguration_new(where: { packageId: { _eq: $app_packageiD } }) {
      formDataAdv
      formDataApp
      formDataPkg
    }
  }
`;

const editswdForm = gql`
  mutation MyMutation(
    $app_packageiD: String
    $packageName: String = "pakagegql"
    $PackageDesc: String = "packagedescgql"
    $version: String = "7.0"
    $platform: String = ""
    $path: String = ""
    $creationTime: String = ""
    $applicationName: String = ""
  ) {
    update_softinst_Packages(
      where: { app_packageId: { _eq: $app_packageiD } }
      _set: {
        packageName: $packageName
        packageDesc: $PackageDesc
        version: $version
        platform: $platform
        applicationName: $applicationName
        path: $path
        creationTime: $creationTime
      }
    ) {
      affected_rows
      returning {
        app_packageId
      }
    }
  }
`;

const editswdConf = gql`
  mutation MyMutation(
    $app_packageiD: Int!
    $config32bitFinal: String
    $config64bitFinal: String
    $edConfig: String = "final-format here"
    $formDataPkgVal: String
    $formDataAppVal: String
    $formDataAdvVal: String
  ) {
    update_softinst_PackagesConfiguration_new(
      where: { packageId: { _eq: $app_packageiD } }
      _set: {
        Config32bit: $config32bitFinal
        Config64bit: $config64bitFinal
        edConfig: $edConfig
        formDataPkg: $formDataPkgVal
        formDataApp: $formDataAppVal
        formDataAdv: $formDataAdvVal
      }
    ) {
      affected_rows
      returning {
        formDataPkg
        formDataApp
      }
    }
  }
`;

const getPackageID = gql`
  query MyQuery($app_packageId: String) {
    softinst_Packages(where: { app_packageId: { _eq: $app_packageId } }, limit: 1) {
      id
    }
  }
`;
const getId = gql`
  query MyQuery($selectedRowsID: String!) {
    softinst_Packages(limit: 1, where: { app_packageId: { _eq: $selectedRowsID } }) {
      id
    }
  }
`;

export {
  getSwdListsQuery,
  getSwdListsCntQuery,
  addSwdPackageQuery,
  addSwdPackageConfigurationQuery,
  deleteSwdPackage,
  getJsonSchemaQueryAdvanceConfiguration,
  getJsonSchemaQueryPackageDetails,
  getJsonSchemaQueryAppConfiguration,
  editswdForm,
  getswdForm,
  editswdConf,
  deleteConfignew,
  getJsonSchemaQueryAppConfigurationU,
  getPackageID,
  getId,
};
