
import { Stack } from '@fluentui/react';
import { FluentProvider, webLightTheme, SkeletonItem } from '@fluentui/react-components';
import { skeleton3, skeleton6, signFormPosition } from '../../styles/customStyles';

export const SkeletonMailSent = () => {
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <Stack tokens={{childrenGap:15}} style={signFormPosition}>
            <SkeletonItem size={48} style={skeleton6}/>
            <SkeletonItem size={128} style={skeleton3}/>
            <SkeletonItem size={36} style={skeleton3}/>
            <SkeletonItem size={36} style={skeleton3}/>
        </Stack>
      </FluentProvider>
    </>
  );
};