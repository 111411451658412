import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupsListQuery, attachRemediationQuery, getRemediationListQuery,setPolicyPatch } from "../../graphql/remediation_queries";
import { getCensusListsQuery, getCensusListsCntQuery } from "../../graphql/census_queries";
import { getGroupIdOfTenantQuery, insertValueOfComponentQuery, updateValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getGroupListsQuery } from "../../graphql/groups_queries";
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { getGroupedDevicesListQuery } from "../../graphql/device_classification_devices_queries";
import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data: any;
    func: any;
    searchVal: String;
    pageNo: any;
    setTotalPages?: any;
    setTotalRecords?: any;
    setDartSpecs: any;
    setJsonData: any;
    setUiData: any;
    deleteAutomation: any;
    setDeleteAutomation: any;
    setDeleteDisabled: any;
    isRefresh: any;
    setRefresh: any;
    setRefreshToast?:any
    setPolicyPatch?:any
    policyPatch?:any
}

const Remediations_func = (props: any) => {
    const client = useApolloClient();
    const contextValue: any = useContext(GlobalContext);
    var dartNo: any = contextValue.dartNo;//also i am passing varvalue in place of dart no. for compliace policy in same variable
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    let policyid=sessionStorage.getItem('policyid')

    const groupsAttachedToRemediation = async (whereCondition: any, limit: any = null, offset: any = null) => {
      const  { data, loading, error } = await client.query({
        query: getRemediationListQuery,
        variables: {whereCondition, limit, offset},
        fetchPolicy: "network-only",
      });

      if(!loading && data){
        return data.core_AutomationJSON[0]?.status;
      }
    }

    const getGroupsList = async (whereCondition: any, limit: number, offset: number) => {
      var attachedConfigGroups: any = await groupsAttachedToRemediation({automationName: {_eq: contextValue.automationDetails.automationName}, dartid: {_eq: dartNo}});

      if(attachedConfigGroups != 'not attached'){
        attachedConfigGroups = attachedConfigGroups?.split("|").filter((val: any) => val.includes(`${contextValue.tenantName}:`));
        
        if(attachedConfigGroups?.length > 0){
          console.log("attached config group",attachedConfigGroups)
          attachedConfigGroups = attachedConfigGroups[0]?.split(":")[1];

          if(attachedConfigGroups.includes('all')){
            props.setPreviousSelectedGroups(['All Devices']);
          }else{
            props.setPreviousSelectedGroups(attachedConfigGroups.split(","));
          }
        }else{
          props.setPreviousSelectedGroups([]);
        }
      }else{
        props.setPreviousSelectedGroups([]);
      }

      const { data, loading, error } = await client.query({
        query: getGroupListsQuery,
        //variables: {limit, offest, whereCondition, order_by, username, mcatid}
        variables: {whereCondition, limit, offset},
        fetchPolicy: "network-only",
      });
  
      if(!loading && data){
        await getTenantDeviceCnt({site: {_eq: tenantName}});
          
        var defaultSeleted:any = [];

        data.core_DeviceClassification.map(async (val: any, i: any) => {
          await getGroupDeviceCnt({site: {_eq: tenantName}, deviceClassifications: {contains: val.classificationName}}, val.classificationName);
        });

        props.func(data);
      }
    };
    
    const getGroupsListCnt = async (whereCondition: any, limit: any, offest: any) => {
      const { data, loading, error } = await client.query({
        query: getGroupListsQuery,
        // variables: {limit, offest, whereCondition, order_by, username, mcatid}
        variables: {whereCondition, limit, offest},
        fetchPolicy: "network-only"
      });
    
      if(!loading && data != undefined){
        props.setExportDetails && props.setExportDetails(data.core_DeviceClassification);
        props.setTotalRecords(data.core_DeviceClassification?.length);
        props.setTotalPages(Math.ceil((data.core_DeviceClassification?.length)/50));
      }
    };

    const getTenantDeviceCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
      const { data, loading, error } = await client.query({
        query: getCensusListsCntQuery,
        variables: {whereCondition, limit, offset},
        fetchPolicy: 'network-only',
      });

      if(!loading && data){
        props.setDeviceCnt(data.core_Census?.length);
      }
    }

    const getGroupDeviceCnt = async (whereCondition: any, name: String, limit: any = null, offset: any = null) => {
      const { data, loading, error } = await client.query({
          query: getGroupedDevicesListQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
      });

      if(!loading && data){
        props.setGroupDeviceCnt((prev: any) => ({
          ...prev,
          [`${name}`]: data.core_Census?.length,
        }));
      }
    }
    const setPolicy_Patch = async ()=>{
   
      const { data } = await client.mutate({
        mutation: setPolicyPatch,
        variables: {policyid,dartNo},
        fetchPolicy: 'network-only',
      });
      if(data){
       console.log("successful set of policy data");
       
      }

    }
    
   
  
    useEffect(() => {    
      if(props.policyPatch){
        setPolicy_Patch();
        props.setPolicyPatch(false);
      }
        if(props.searchVal?.length > 0 && props.searchVal !== ''){
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}}, 50, props.pageNo);
         
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}, classificationName: {contains: props.searchVal}}, null, 0);
        }else if(props.isRefresh){
          props.setRefresh(false);
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, 50, props.pageNo);
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, null, 0);
          props.setRefreshToast(true);  
          setTimeout(() => {
            props.setRefreshToast(false);  
          }, 3000);
        }
        else{
          getGroupsList({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, 50, props.pageNo);
          getGroupsListCnt({isGroupConfigured: {_eq: "Yes"}, mgroupuniq: {_is_null: false}, tenant: {_eq: tenantName}}, null, 0);

          // props.isRefresh && props.setRefresh(false);
          
        }
    },[props.isRefresh, props.searchVal, props.saveBtnClicked, props.pageNo, props.policyPatch]);

    return (
        <></>
    )
}

export { Remediations_func }