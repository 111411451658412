import { useState, useEffect, useContext } from 'react';
import { Groups_func } from '../../../api/devices/groups_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Popover,
  PopoverTrigger,
  useId,
  Field,
  ProgressBar,
  Card,
  Textarea,
  Image,
  Input,
  Tooltip,
} from '@fluentui/react-components';
import type { ButtonProps, TextareaProps } from '@fluentui/react-components';
import {
  Info20Regular,
  GroupList20Regular,
  DesktopPulse20Regular,
  Wrench20Regular,
  ShieldQuestion20Regular,
  LockClosedKey20Regular,
  ChatMultipleHeart20Regular,
  BookDatabase20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  AddSquare20Regular,
  SlideEraser20Regular,
  Dismiss16Regular,
  DismissSquare24Filled,
  ArrowMaximize20Regular,
  Info20Filled,
  Open20Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FileDownloadComponent } from '../../../components/fileExport/file.export.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { GlobalContext } from '../../../hooks/globalContext';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import FileUploadComponent from '../../../components/fileImport/file.import.component';
import DialogBox from '../../../components/dialog/dialog.component';
import {
  primaryIcon,
  secondaryIcon,
  exitIcon,
  fullscreenIcon,
  dividerStyle,
  overflowDivider,
  lightTheme,
  warningTheme,
  messageTheme,
  columnDataAlign,
} from '../../../styles/customStyles';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';
// import track, { useTracking } from 'react-tracking';

export const Groups = () => {
  //const { trackEvent }=useTracking();
  const toasterId = useId();
  const navigate = useNavigate();
  const contextValue: any = useContext(GlobalContext);
  var tenantName = contextValue.tenantName;

  const [isIconEnabled, setIconEnabled] = useState(false);
  const [isModalIconEnabled, setModalIconEnabled] = useState(false);
  const [final_data, set_final_data]: any = useState(null);
  const [devicesData, setDevicesData]: any = useState(null);
  const [tableData, setTableData] = useState([]);
  const [devicesTableData, setDevicesTableData] = useState([]);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayOpenNew, setOverlayOpenNew] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [deviceSearchVal, setDeviceSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [isfilter, setIsFilter] = useState(false);
  const [dropDownValue1, setDropDownValue1] = useState<any>();
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [overLayDataNew, setOverLayDataNew] = useState<any>({});
  const [isSubTableSelect, setIsSubTableSelect] = useState(false);
  const [configGroupBtnCliked, setConfigGroupBtnCliked] = useState(false);
  const [groupConfigCnt, setGroupConfigCnt] = useState(0);
  const [addClassification, setAddClassification] = useState(false);
  const [classificationName, setClassificationName] = useState<any>('');
  const [classificationNameValidation, setClassificationNameValidation]: any = useState(true);
  const [deleteClassification, setDeleteClassification]: any = useState(false);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [linkedDeviceCnt, setLinkedDeviceCnt] = useState<any>({});
  const [csvDevicesList, setCsvDevicesList] = useState<any>([]);
  const [devicesOfTenant, setDevicesOfTenant] = useState<any>(null);
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [isRefresh, setRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [operationType, setOperationType] = useState<any>({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const [permissions, setPermissions] = useState(['']);
  console.log('checking the permissions------>', permissions);

  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    const result = value1 + ':' + value2;
    setFilterArray([...filterArray, result]);
    setIsFilter(true);
  };

  const handleCloseFilter = (index: any): any => {
    console.log('cllciked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  const handleClick = () => {
    const eventData = { action: 'click' };
    console.log('Event Data:', eventData); // trackEvent(eventData); // Log the event data to the console
  };

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };

  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };

  var menuItems: any = [];

  const handleNameInput: TextareaProps['onChange'] = (ev, data: any) => {
    var inputVal = data.value;
    if (/^(?! )[ a-zA-Z0-9]*$/.test(inputVal)) {
      var flag = true;
      exportDetails.map((val: any) => {
        if (val.classificationName == inputVal) {
          flag = false;
        }
      });
      setClassificationNameValidation(flag);
      setClassificationName(inputVal);
    }
  };
  if (permissions.includes('overview_Read') || permissions.includes('overview_ReadandExport') || permissions.includes('overview_Fullaccess')) {
    menuItems.push(
      <MenuItem
        onClick={() => navigate('/manage/devices/overview')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Info20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Overview </Caption1>
          </StackItem>
          <StackItem>
            <Stack tokens={{ childrenGap: 80 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  if (
    permissions.includes('deviceClassification_Read') ||
    permissions.includes('deviceClassification_ReadandExport') ||
    permissions.includes('deviceClassification_Fullaccess')
  ) {
    menuItems.push(
      <FluentProvider theme={lightTheme}>
        <MenuItem
          onClick={() => navigate('/manage/devices/device_classification')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <GroupList20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> Device Classification </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>
      </FluentProvider>,
    );
  }

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/monitor')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <DesktopPulse20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Monitors </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  if (
    permissions.includes('remediation_Read') ||
    permissions.includes('remediation_ReadandExport') ||
    permissions.includes('remediation_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        onClick={() => navigate('/manage/devices/remediation')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Wrench20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Remediations </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/compliancepolicies')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <ShieldQuestion20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Compliance Policies </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/conditionalaccess')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <LockClosedKey20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Conditional Access </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  if (
    permissions.includes('userSurveys_Read') ||
    permissions.includes('userSurveys_ReadandExport') ||
    permissions.includes('userSurveys_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        onClick={() => navigate('/manage/devices/usersurvey')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <ChatMultipleHeart20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> User Survey </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      onClick={() => navigate('/manage/devices/datacollection')}
      icon={
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <BookDatabase20Regular {...primaryIcon} />
          </StackItem>
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 30 }} horizontal>
        <StackItem>
          <Text></Text>
        </StackItem>
        <StackItem>
          <Caption1> Data Collection </Caption1>
        </StackItem>
      </Stack>
    </MenuItem>,
  );
  if (
    permissions.includes('activityLog_Read') ||
    permissions.includes('activityLog_ReadandExport') ||
    permissions.includes('activityLog_Fullaccess')
  ) {
    menuItems.push(
      <MenuItem
        onClick={() => navigate('/manage/devices/activitylog')}
        icon={
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <BookTemplate20Regular {...primaryIcon} />
            </StackItem>
          </Stack>
        }
      >
        <Stack tokens={{ childrenGap: 30 }} horizontal>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            <Caption1> Activity Log </Caption1>
          </StackItem>
        </Stack>
      </MenuItem>,
    );
  }
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'classification_name', label: <Body1Strong> &nbsp;&nbsp;Classification&nbsp;Name </Body1Strong> },
    { key: 'created_by', label: <Body1Strong> Created By </Body1Strong> },
    { key: 'created_on', label: <Body1Strong> Created On </Body1Strong> },
    { key: 'last_modifed', label: <Body1Strong> Last Modifed </Body1Strong> },
    { key: 'linked_devices', label: <Body1Strong> Linked Devices </Body1Strong> },
    { key: 'config_group', label: <Body1Strong> Configuration Group </Body1Strong> },
  ];
  //data table column name code ends here

  //data table for filter dropdown
  var columns1 = [
    { key: 'classification_name', label: <Caption1> Classification&nbsp;Name </Caption1> },
    { key: 'created_by', label: <Caption1> Created By </Caption1> },
    { key: 'linked_devices', label: <Caption1> Classification with Devices more than one </Caption1> },
    { key: 'created_on', label: <Caption1> Created On </Caption1> },
    { key: 'last_modifed', label: <Caption1> Modifed On </Caption1> },
    { key: 'config_group', label: <Caption1> Configuration group </Caption1> },
    { key: 'mode_of_creation', label: <Caption1> Mode of Creation </Caption1> },
  ];

  var overlayColumns = [
    { key: 'device_name', label: <Body1Strong> Device Name</Body1Strong> },
    { key: 'os', label: <Body1Strong> Operating System </Body1Strong> },
  ];

  //dropdown data
  var dropdownData = [
    {
      key: 'op_1',
      label: (
        <Body1Strong
          onClick={() => {
            setSelectedValue(1);
            setIsSubTableSelect(true);
            contextValue.selectedRowIdInTable.length > 0 && contextValue.setSelectedRowIdInTable([]);
          }}
        >
          Choose devices manually
        </Body1Strong>
      ),
    },
    { key: 'op_2', label: <Body1Strong onClick={() => setSelectedValue(2)}> Upload a CSV containing the Devices </Body1Strong> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        {/* <Stack tokens={{childrenGap:10}} horizontal> */}
        <StackItem>
          {permissions.includes('deviceClassification_Read') || permissions.includes('deviceClassification_ReadandExport') ? (
            <TooltipHost content="You do not have access">
              <Button size="small" appearance="subtle" disabled>
                <Stack tokens={{ childrenGap: 6 }}>
                  {/* <StackItem><Text></Text></StackItem> */}
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <AddSquare20Regular {...primaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Add Classification </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('deviceClassification_Fullaccess') ? (
            <Button
              size="small"
              appearance="subtle"
              onClick={() => {
                setSelectedTableData([]);
                setOverlayOpenNew(true);
                setSelectedValue(0);
                contextValue.setSelectedRowIdInSubTable([]);
                setDevicesOfTenant(null);
                setCsvDevicesList([]);
                setClassificationName('');
              }}
            >
              <Stack tokens={{ childrenGap: 6 }}>
                {/* <StackItem><Text></Text></StackItem> */}
                <StackItem>
                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                    <StackItem>
                      <AddSquare20Regular {...primaryIcon} />
                    </StackItem>
                    <StackItem>
                      <Caption1> Add Classification </Caption1>
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </Button>
          ) : (
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <AddSquare20Regular {...secondaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Add Classification </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          )}

          {/* <Button 
              size="small" 
              appearance="subtle" 
              onClick={() => {
                setSelectedTableData([]);
                setOverlayOpenNew(true); 
                setSelectedValue(0); 
                contextValue.setSelectedRowIdInSubTable([]); 
                setDevicesOfTenant(null); 
                setCsvDevicesList([]); 
                setClassificationName('');
              }}
            >
              <Stack tokens={{childrenGap:6}}>
                <StackItem><Text></Text></StackItem>
                <StackItem>
                  <Stack tokens={{childrenGap:5}} horizontal>
                    <StackItem> <AddSquare20Regular {...primaryIcon}/> </StackItem>
                    <StackItem> <Caption1> Add Classification </Caption1> </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </Button> */}
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <DialogBox
            content="Are you sure you want to create a configuration group for the selected device classification?"
            headerButton={
              <>
                <Button size="small" appearance="subtle" disabled={isIconEnabled ? false : true}>
                  <Stack tokens={{ childrenGap: 6 }}>
                    {/* <StackItem><Text></Text></StackItem> */}
                    <StackItem>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem>{isIconEnabled ? <AddSquare20Regular {...primaryIcon} /> : <AddSquare20Regular {...secondaryIcon} />}</StackItem>
                        <StackItem>
                          <Caption1> Create Configuration Group </Caption1>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      size="small"
                      appearance="primary"
                      onClick={() => {
                        setOverlayOpen(true);
                        setConfigGroupBtnCliked(true);
                        setShowMessage(false);
                        userActivity(
                          'Create',
                          `{
                              "classificationNames":"${contextValue.selectedRowIdInTable.map((val: any) => val[1])}",
                              "summary":"${contextValue.selectedRowIdInTable.length} 
                                ${contextValue.selectedRowIdInTable.length > 1 ? 'classifications' : 'classification'} has been configured"
                            }`,
                        );
                      }}
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small"> Cancel </Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <DialogBox
            content="Kindly verify your intent to delete the classification."
            headerButton={
              <>
                <Button size="small" appearance="subtle" disabled={isIconEnabled ? false : true}>
                  <Stack tokens={{ childrenGap: 6 }}>
                    {/* <StackItem><Text></Text></StackItem> */}
                    <StackItem>
                      <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem>
                          {isIconEnabled ? <SlideEraser20Regular {...primaryIcon} /> : <SlideEraser20Regular {...secondaryIcon} />}
                        </StackItem>
                        <StackItem>
                          <Caption1> Delete </Caption1>
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </Button>
              </>
            }
            footer={
              <>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Button
                      appearance="primary"
                      size="small"
                      onClick={() => {
                        setDeleteClassification(true);
                        userActivity(
                          'Delete',
                          `{
                              "classificationNames":"${contextValue.selectedRowIdInTable.map((val: any) => val[1])}",
                              "summary":"${contextValue.selectedRowIdInTable.length} 
                                ${contextValue.selectedRowIdInTable.length > 1 ? 'classifications' : 'classification'} deleted"
                            }`,
                        );
                      }}
                    >
                      Confirm
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button size="small"> Cancel </Button>
                  </StackItem>
                </Stack>
              </>
            }
          />
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <Stack tokens={{childrenGap:6}}> */}
        {/* <StackItem><Text></Text></StackItem> */}
        <StackItem>
          <Button
            size="small"
            appearance="subtle"
            onClick={() => {
              setCurrentPageNo(1);
              setRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>

          {permissions.includes('deviceClassification_Read') ? (
            <TooltipHost content="This feature is not available for you!">
              <Button size="small" appearance="subtle">
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('deviceClassification_Fullaccess') || permissions.includes('deviceClassification_ReadandExport') ? (
            <CSVLink id="csv-export-link" data={exportData} filename={`${tenantName}_DeviceClassifications.csv`}>
              <Button
                size="small"
                appearance="subtle"
                onClick={() => {
                  userActivity('Export', `{"summary":"Device classifications list exported"}`);
                }}
              >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </CSVLink>
          ) : null}

          {/* <CSVLink
                id="csv-export-link"
                data={exportData}
                filename={`${tenantName}_DeviceClassifications.csv`}
              >
                <Button 
                  size="small" 
                  appearance="subtle" 
                  onClick={() => {
                    userActivity(
                      'Export', 
                      `{"summary":"Device classifications list exported"}`
                    ); 
                  }}
                >
                  <Stack tokens={{childrenGap:5}} horizontal>
                    <StackItem> <ArrowDownload20Regular {...primaryIcon}/> </StackItem>
                    <StackItem> <Caption1> Export </Caption1> </StackItem>
                  </Stack>
                </Button>
              </CSVLink> */}

          {/* <Button appearance="subtle" size="small">
                <Stack tokens={{childrenGap:5}} horizontal>
                  <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
                  <StackItem> <Caption1> Manage View </Caption1> </StackItem>
                </Stack>
              </Button> */}
        </StackItem>
        {/* </Stack> */}
        {/* <StackItem> <CheckBox onClick={(e) => handleIconActive(e)}></CheckBox> </StackItem> */}
        {/* </Stack> */}
      </>
    ),
  };
  // overflow set contents code ends here

  var tempTableData: any = [];

  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        tempExportData.push({
          classification_name: val.classificationName,
          created_by: val.createdBy,
          created_on: new Date(val.createdOn * 1000).toLocaleString(),
          last_modifed: val.lastModified != null ? new Date(val.lastModified * 1000).toLocaleString() : '-',
          linked_devices: linkedDeviceCnt[`${val.classificationName}`],
          config_group: val.isGroupConfigured,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails, linkedDeviceCnt]);

  useEffect(() => {
    dropDownValue1 === 'Choose devices manually' && setSelectedValue(1);
    dropDownValue1 === 'Upload a CSV containing the Devices' && setSelectedValue(2);
  }, [dropDownValue1]);

  useEffect(() => {
    //roles and permissions
    async function loadRoute() {
      const role = 'Nano_test';
      try {
        console.log('role ------------>>', role);
        const [routeAllowed, permissions] = await shouldLoadRoute(role);

        if (routeAllowed) {
          setPermissions(permissions);
          console.log('Route loaded successfully.', permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();

    // data table values append code starts here
    console.log('final_data=', final_data);
    var typeObj: any = {};

    final_data !== undefined && final_data !== null
      ? final_data.core_DeviceClassification?.map((val: any, i: any) => {
          typeObj[`${val.classificationName}`] = val.type;

          // tempTableData.push({
          //   id: [val.id, val.classificationName],
          //   checkbox:
          //     val.isGroupConfigured == 'Yes'
          //       ? 'hide'
          //       : 'show',
          //   classification_name: {
          //     id: `${i}`,
          //     label:
          //       <Button
          //         size="small"
          //         appearance="transparent"
          //         onClick={() => {
          //           navigate("/manage/devices/device_classification/devices")
          //           localStorage.setItem(
          //             'classificationName',
          //             val.classificationName
          //           );
          //           contextValue.setSelectedRowDetails({
          //             ...contextValue.selectedRowDetails,
          //             name: val.classificationName
          //           });
          //         }}
          //       >
          //         {val.classificationName}
          //       </Button>
          //   },
          //   created_by: { label: <Caption1 onClick={handleClick}> {val.createdBy} </Caption1>, status: "" },
          //   created_on: { label: <Caption1> {new Date(val.createdOn * 1000).toLocaleString()} </Caption1> },
          //   last_modifed: {
          //     label:
          //       <Caption1>
          //         {val.lastModified != null
          //           ? new Date(val.lastModified * 1000).toLocaleString()
          //           : '-'
          //         }
          //       </Caption1>
          //   },
          //   linked_devices: { label: <Caption1> {linkedDeviceCnt[`${val.classificationName}`]} </Caption1> },
          //   config_group: { label: <Caption1> {val.isGroupConfigured} </Caption1> },
          // });

          const commonData = {
            id: [val.id, val.classificationName],
            classification_name: {
              id: `${i}`,
              label: (
                <Button
                  size="small"
                  appearance="transparent"
                  onClick={() => {
                    navigate('/manage/devices/device_classification/devices');
                    localStorage.setItem('classificationName', val.classificationName);
                    contextValue.setSelectedRowDetails({
                      ...contextValue.selectedRowDetails,
                      name: val.classificationName,
                    });
                  }}
                >
                  {val.classificationName}
                </Button>
              ),
            },
            created_by: { label: <Caption1 onClick={handleClick}>{val.createdBy}</Caption1>, status: '' },
            created_on: { label: <Caption1>{new Date(val.createdOn * 1000).toLocaleString()}</Caption1> },
            last_modified: {
              label: <Caption1>{val.lastModified ? new Date(val.lastModified * 1000).toLocaleString() : '-'}</Caption1>,
            },
            linked_devices: { label: <Caption1>{linkedDeviceCnt[val.classificationName]}</Caption1> },
            config_group: { label: <Caption1>{val.isGroupConfigured}</Caption1> },
          };

          if (permissions.includes('deviceClassification_Read') || permissions.includes('deviceClassification_ReadandExport')) {
            tempTableData.push({
              ...commonData,
              checkbox: 'hide',
            });
          } else if (permissions.includes('deviceClassification_Fullaccess')) {
            tempTableData.push({
              ...commonData,
              checkbox: val.isGroupConfigured === 'Yes' ? 'hide' : 'show',
            });
          }
        })
      : (tempTableData = []);

    setOperationType(typeObj);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data, linkedDeviceCnt]);

  const searchHandle = (data: any) => {
    var data = data.toLowerCase();
    var searchData = exportDetails.filter((val: any) => val.classificationName.toLowerCase().includes(data));

    var searchedDataObj: any = { core_DeviceClassification: [] };
    searchedDataObj.core_DeviceClassification = searchData;

    set_final_data(searchedDataObj);

    setTotalRecords(searchData.length);
    setTotalPages(Math.ceil(searchData.length / 50));
    setCurrentPageNo(1);
  };

  var tempTableData1: any = [];

  useEffect(() => {
    // devices data table values append code starts here
    console.log('final_data123=', devicesData);

    devicesData !== undefined && devicesData !== null
      ? devicesData.core_Census?.map((val: any, i: any) => {
          tempTableData1.push({
            id: val.host,
            device_name: { label: <Caption1> {val.host} </Caption1>, id: `${i}` },
            os: { label: <Caption1> {val.os} </Caption1>, status: '' },
          });
        })
      : (tempTableData1 = []);

    tempTableData1.length > 0 ? setDevicesTableData(tempTableData1) : (tempTableData1 = []);
  }, [devicesData]);
  // devices data table values append code ends here

  //overlay code starts here
  var overLayData: any = {
    size: 'large',
    isTabs: false,

    headerTitle: (
      <>
        <Title3> Creating Configuration Group </Title3>
      </>
    ),

    bodyData: (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <StackItem>
            <Text></Text>
          </StackItem>
          <StackItem>
            {groupConfigCnt != contextValue.selectedRowIdInTable.length && (
              <Field validationState="none" validationMessage="Converting to a Configuration Group... Please wait...">
                <ProgressBar />
              </Field>
            )}

            {groupConfigCnt}

            <Text> / </Text>

            {contextValue.selectedRowIdInTable.length}

            {groupConfigCnt == contextValue.selectedRowIdInTable.length && (
              <Field validationState="success" validationMessage={`${groupConfigCnt} configuration groups is created succesfully`}>
                <ProgressBar value={1} color="success" />
              </Field>
            )}
          </StackItem>
        </Stack>
      </>
    ),

    footerData: (
      <>
        {isModalIconEnabled ? (
          <Popover trapFocus>
            <PopoverTrigger>
              <Button
                appearance="subtle"
                disabled={groupConfigCnt == contextValue.selectedRowIdInTable.length ? false : true}
                onClick={() => {
                  navigate('/manage/devices/device_classification');
                  setOverlayOpen(false);
                  setConfigGroupBtnCliked(false);
                  contextValue.setSelectedRowIdInTable([]);
                  setRefresh(true);
                }}
              >
                <StackItem>
                  <Caption1> Acknowledge </Caption1>
                </StackItem>
              </Button>
            </PopoverTrigger>
            <Stack verticalAlign="end"> </Stack>
          </Popover>
        ) : (
          <Button
            appearance="primary"
            disabled={groupConfigCnt == contextValue.selectedRowIdInTable.length ? false : true}
            onClick={() => {
              navigate('/manage/devices/device_classification');
              setOverlayOpen(false);
              setConfigGroupBtnCliked(false);
              contextValue.setSelectedRowIdInTable([]);
              setRefresh(true);
            }}
          >
            <StackItem>
              <Caption1> Acknowledge </Caption1>
            </StackItem>
          </Button>
        )}
      </>
    ),
  };

  useEffect(() => {
    setOverLayDataNew({
      size: 'large',
      isTabs: false,

      headerTitle: (
        <>
          <Title3>{selectedValue == 0 ? 'Add Classification' : 'Add Devices'}</Title3>
        </>
      ),

      bodyData: (
        <>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack tokens={{ childrenGap: 10 }} horizontal>
                <StackItem grow shrink>
                  <Stack tokens={{ childrenGap: 20 }} horizontalAlign="start">
                    <StackItem>
                      <Body1>
                        Classification name <Image src="/visualization/v2/image/required.svg" />
                      </Body1>
                    </StackItem>

                    <StackItem>
                      <Stack tokens={{ childrenGap: 5 }}>
                        <Field
                          validationState={classificationNameValidation ? 'none' : 'warning'}
                          validationMessage={classificationNameValidation ? '' : 'This name already exists'}
                        >
                          <Textarea
                            size="small"
                            placeholder="Classification name"
                            className="classfication_textarea"
                            value={classificationName}
                            onChange={handleNameInput}
                          />
                        </Field>

                        {/* <Textarea 
                          size="small"
                          placeholder="Classification name" 
                          className="classfication_textarea"
                          value={classificationName} 
                          onChange={handleNameInput} 
                        />

                        { !classificationNameValidation && 
                          <FluentProvider theme={warningTheme}> <Body1> This name already exists </Body1> </FluentProvider> 
                        } */}
                      </Stack>
                    </StackItem>

                    <StackItem>
                      <Body1> How you would like to add machines to this Device Classification? </Body1>
                    </StackItem>

                    <StackItem>
                      <DropdownComponent optionData={dropdownData} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                    </StackItem>

                    <StackItem>
                      {selectedValue == 1 && (
                        <>
                          <Stack tokens={{ childrenGap: 30 }} horizontal>
                            <StackItem>
                              <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                  <StackItem>
                                    <FluentProvider theme={messageTheme}>
                                      <Card appearance="subtle" size="small">
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                          <StackItem>
                                            <Info20Filled {...primaryIcon} />
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Try changing or adding filters if you don't see what you're looking for. </Caption1>
                                          </StackItem>
                                        </Stack>
                                      </Card>
                                    </FluentProvider>
                                  </StackItem>
                                </StackItem>

                                <StackItem>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <SearchBox
                                      size="small"
                                      placeholder="Search"
                                      onChange={(e: any) => {
                                        setDeviceSearchVal(e.target.value);
                                      }}
                                    />
                                    <StackItem>
                                      <MenuDropdown
                                        btn={
                                          <MenuButton shape="circular" size="small">
                                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                                              <Filter20Filled {...primaryIcon} />
                                              <Body1> Filter </Body1>
                                            </Stack>
                                          </MenuButton>
                                        }
                                        itemVal={
                                          <>
                                            <Body1Strong> Add a Filter </Body1Strong>

                                            <Divider />

                                            <MenuList>
                                              <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                  <Text></Text>
                                                </StackItem>
                                                <StackItem>
                                                  <Caption1> Filter </Caption1>
                                                </StackItem>
                                                <DropdownComponent optionData={columns} />
                                                <StackItem>
                                                  <Divider />
                                                </StackItem>
                                                <StackItem>
                                                  <Caption1> Value </Caption1>
                                                </StackItem>
                                                <DropdownComponent optionData={columns} />
                                                <StackItem>
                                                  <Divider />
                                                </StackItem>
                                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                                  <StackItem>
                                                    <Button appearance="primary">
                                                      <Caption1> Apply </Caption1>
                                                    </Button>
                                                  </StackItem>
                                                  <StackItem>
                                                    <Button>
                                                      <Caption1> Cancel </Caption1>
                                                    </Button>
                                                  </StackItem>
                                                </Stack>
                                              </Stack>
                                            </MenuList>
                                          </>
                                        }
                                      />
                                    </StackItem>
                                    <StackItem>
                                      <Button appearance="subtle" size="small">
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem>
                                            {isResetDisabled ? (
                                              <FilterDismiss20Filled {...secondaryIcon} />
                                            ) : (
                                              <FilterDismiss20Filled {...primaryIcon} />
                                            )}
                                          </StackItem>
                                          <StackItem>
                                            <Caption1> Reset Filters </Caption1>
                                          </StackItem>
                                        </Stack>
                                      </Button>
                                    </StackItem>
                                  </Stack>
                                </StackItem>

                                <StackItem>
                                  <Stack tokens={{ childrenGap: 10 }}>
                                    <FluentProvider theme={webLightTheme}>
                                      {devicesTableData.length > 0 && (
                                        <DataTable tableData={devicesTableData} columns={overlayColumns} isSubTable={true} />
                                      )}
                                    </FluentProvider>
                                  </Stack>
                                </StackItem>
                              </Stack>
                            </StackItem>

                            {/* {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) && <Divider vertical />} 
                            <StackItem>
                              <StackItem>
                                {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) &&  <DataTable tableData={selectedTableData} columns={[{ key: "selected", label: <Body1Strong> Selected ({contextValue.selectedRowIdInSubTable.length}) </Body1Strong>}]} />}
                              </StackItem>
                              <Stack tokens={{childrenGap: 10}} horizontalAlign="center">
                                <Label> <Body1Strong> Selected </Body1Strong> </Label>
                                {(contextValue.selectedRowIdInSubTable.length > 0 || selectedTableData.length > 0) && selectedTableData.map((val: any) => <StackItem><Text>{val}</Text></StackItem>)}
                              </Stack>
                            </StackItem> */}
                          </Stack>
                        </>
                      )}

                      {selectedValue == 2 && (
                        <>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>

                            <StackItem>
                              <StackItem>
                                <Body1> Start by downloading a file with the list of devices from the tenant you want. </Body1>
                              </StackItem>
                              <StackItem>
                                <Body1> You can choose devices from the same site or different ones to create groups. </Body1>
                              </StackItem>
                            </StackItem>

                            <StackItem>
                              <Text></Text>
                            </StackItem>

                            <StackItem>
                              <Body1Strong> Steps to create the CSV group </Body1Strong>
                            </StackItem>

                            <StackItem>
                              <Body1> 1. Download the file that contains the list of devices </Body1>
                              <FileDownloadComponent
                                fileName="devices_list"
                                data={'Machines\n' + devicesData.core_Census.map((val: any) => `${val.host}`).join('\n')}
                              />
                            </StackItem>

                            <Stack>
                              <StackItem>
                                <Body1> 2. Revise device grouping in the CSV. </Body1>
                              </StackItem>
                              <StackItem>
                                <Stack tokens={{ childrenGap: 15 }} horizontal>
                                  <StackItem>
                                    <Text></Text>
                                  </StackItem>
                                  <StackItem>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem>
                                        <Caption1> Watch the instructional video for guidance </Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Open20Regular />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </StackItem>
                            </Stack>

                            <StackItem>
                              <Body1> 3. Upload your .csv file </Body1>
                            </StackItem>

                            <StackItem>
                              <FileUploadComponent setDeviceList={setCsvDevicesList} />
                            </StackItem>

                            {/* <DropdownComponent optionData={ddData} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1}/> */}

                            {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length > 0 && (
                              <>
                                <StackItem>
                                  <Text> {selectedTableData.length} unique machine found. </Text>
                                </StackItem>
                                <StackItem>
                                  <Text>
                                    Kindly check the group names and confirm that everything is exactly the way you need them. If you have made any
                                    mistake,Kindly make the changes and upload the CSV with the corrections
                                  </Text>
                                </StackItem>
                              </>
                            )}

                            {devicesOfTenant != null && csvDevicesList.length > 0 && selectedTableData.length == 0 && (
                              <StackItem>
                                <Text> No unique machine found </Text>
                              </StackItem>
                            )}
                          </Stack>
                        </>
                      )}
                    </StackItem>
                  </Stack>
                </StackItem>

                {selectedValue === 1 && (
                  <StackItem>
                    <Stack horizontal style={dividerStyle} horizontalAlign="center">
                      <Divider vertical />
                    </Stack>
                  </StackItem>
                )}

                <StackItem grow shrink>
                  <Stack horizontalAlign="start">
                    <StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          {selectedValue === 1 && (
                            <>
                              <StackItem>
                                {(contextValue.selectedRowIdInSubTable.length >= 0 || selectedTableData.length >= 0) && (
                                  <StackItem>
                                    <Stack horizontal>
                                      <StackItem>
                                        {/* <DataTable 
                                          tableData={selectedTableData} 
                                          columns={[{ 
                                            key: "selected", 
                                            label: <Body1Strong> Selected ({contextValue.selectedRowIdInSubTable.length}) </Body1Strong> 
                                          }]} 
                                        /> */}
                                        <DataTable
                                          tableData={selectedTableData}
                                          columns={[
                                            {
                                              key: 'selected',
                                              label: (
                                                <Body1Strong>
                                                  Selected ({contextValue.selectedRowIdInSubTable.length})
                                                  <Button
                                                    appearance="transparent"
                                                    size="small"
                                                    onClick={() => contextValue.setSelectedRowIdInSubTable([])}
                                                  >
                                                    Reset
                                                  </Button>
                                                </Body1Strong>
                                              ),
                                            },
                                          ]}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                )}
                              </StackItem>
                            </>
                          )}
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </>
      ),

      footerData: (
        <>
          {selectedValue == 1 ? (
            <Button
              appearance="primary"
              disabled={selectedTableData.length > 0 && classificationName.length > 0 && classificationNameValidation ? false : true}
              onClick={() => {
                setAddClassification(true);
                userActivity(
                  'Add',
                  `{
                        "name":"${classificationName}",
                        "deviceCnt":"${selectedTableData.length}",
                        "type":"Manual",
                        "summary":"classification ${classificationName} is created with ${contextValue.selectedRowIdInSubTable.length} devices manually"
                      }`,
                );
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              appearance="primary"
              disabled={selectedTableData.length > 0 && classificationName.length > 0 && classificationNameValidation ? false : true}
              onClick={() => {
                setAddClassification(true);
                userActivity(
                  'Add',
                  `{
                        "name":"${classificationName}",
                        "deviceCnt":"${selectedTableData.length}",
                        "type":"CSV",
                        "summary":"classification ${classificationName} is created with ${selectedTableData.length} devices through csv"
                      }`,
                );
              }}
            >
              Submit
            </Button>
          )}
        </>
      ),
    });
  }, [devicesTableData, selectedValue, selectedTableData, classificationName, classificationNameValidation, csvDevicesList]);
  //overlay code ends here

  var validDevicesList: any = [];
  var inValidDevicesList: any = [];
  var alldDevicesList: any = [];

  useEffect(() => {
    if (csvDevicesList.length > 0) {
      validDevicesList = [];
      inValidDevicesList = [];
      alldDevicesList = [];

      devicesOfTenant?.core_Census.map((val: any) => {
        alldDevicesList.push(val.host);
      });

      csvDevicesList.map((val: any) => {
        var deviceName = val.replace(/[\r\n]/g, '');
        alldDevicesList.includes(deviceName) ? validDevicesList.push(deviceName) : inValidDevicesList.push(deviceName);
      });

      validDevicesList.length > 0 ? setSelectedTableData(validDevicesList) : setSelectedTableData([]);

      contextValue.setSelectedRowIdInSubTable(validDevicesList);
    }
  }, [devicesOfTenant, csvDevicesList]);

  useEffect(() => {
    !overlayOpen && setSelectedValue(0);
  }, [overlayOpen]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
    contextValue.setSelectedRowIdInSubTable([]);
  }, []);

  var dataArr: any = [];
  useEffect(() => {
    console.log('main Table=', contextValue.selectedRowIdInTable);
    console.log('sub Table=', contextValue.selectedRowIdInSubTable);

    contextValue.selectedRowIdInSubTable.map((val: any, i: any) => {
      dataArr.push({
        id: i,
        checkbox: 'hide',
        selected: { label: <Caption1> {val} </Caption1>, id: `${i}` },
      });
      //dataArr.push(val);
    });

    dataArr.length > 0 ? setSelectedTableData(dataArr) : setSelectedTableData([]);
    contextValue.selectedRowIdInTable.length > 0 ? setIconEnabled(true) : setIconEnabled(false);
  }, [contextValue.selectedRowIdInTable, contextValue.selectedRowIdInSubTable]);

  const userActivity = (action: String, activity: String, status: any = 'Success') => {
    setUserActivityData({
      pageName: 'Device Classification',
      moduleName: 'Devices',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });

    setTracker(true);
  };

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };

  return (
    <>
      <FluentProvider theme={webLightTheme}>
        {/* {
      permissions.includes("deviceClassification_Read") || permissions.includes("deviceClassification_Fullaccess") || permissions.includes("deviceClassification_ReadandExport") 
      ? */}
        <FullScreen className="fullScreenBackgroundColor" handle={handle}>
          <StackItem grow shrink>
            <Stack tokens={{ childrenGap: 10 }} horizontal>
              <StackItem grow shrink>
                <Stack horizontalAlign="start">
                  <StackItem grow shrink>
                    <Breadcrumbs pageName="Devices" subPageName="" subPageLink="#" />
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem grow shrink>
                <Stack horizontalAlign="end">
                  <StackItem grow shrink>
                    <Button
                      style={fullscreenIcon}
                      appearance="subtle"
                      size="small"
                      shape="square"
                      onClick={handleFullScreen}
                      title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                      icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                    />
                  </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          </StackItem>
          <Stack tokens={{ childrenGap: 20 }} horizontal>
            <StackItem>
              <Stack tokens={{ childrenGap: 10 }} horizontal>
                <StackItem>
                  {/* pages code starts here */}
                  <Stack verticalAlign="center">
                    {/* <StackItem> <Text> <Breadcrumbs pageName="Devices" /> </Text> </StackItem> */}
                    <Stack tokens={{ childrenGap: 25 }} horizontal>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Title3> Devices | Device Classification </Title3>
                      </StackItem>
                      {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
                    </Stack>
                    <Stack tokens={{ childrenGap: 20 }}>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                      <StackItem>
                        <Text></Text>
                      </StackItem>
                    </Stack>
                    <Stack horizontal>
                      {/* SPACING BETWEEN INLINE AND PAGE */}
                      <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                      <StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 1 }}>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                          <StackItem>
                            <Text></Text>
                          </StackItem>
                        </Stack>
                        <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                            {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                          </StackItem>
                          <StackItem>
                            <StackItem>
                              <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <SearchBox
                                  onChange={(e: any) => {
                                    setSearchVal(e.target.value);
                                  }}
                                  value={searchVal}
                                  placeholder="Search"
                                  size="small"
                                  dismiss={null}
                                  contentAfter={<DismissButton aria-label="Clear" />}
                                />

                                <StackItem>
                                  <MenuDropdown
                                    open={filterMenuOpen}
                                    btn={
                                      <MenuButton size="small" shape="circular" onClick={() => setFilterMenuOpen(true)}>
                                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                                          <Filter20Filled {...primaryIcon} />
                                          <Body1> Filter </Body1>
                                        </Stack>
                                      </MenuButton>
                                    }
                                    itemVal={
                                      <>
                                        <Body1Strong> Add a Filter </Body1Strong>

                                        <Divider />

                                        <MenuList>
                                          <Stack tokens={{ childrenGap: 5 }}>
                                            <StackItem>
                                              <Text></Text>
                                            </StackItem>
                                            <StackItem>
                                              <Caption1> Filter </Caption1>
                                            </StackItem>
                                            <DropdownComponent
                                              optionData={columns1}
                                              setDropDownValue={setDropDownValue1}
                                              dropDownvalue={dropDownValue1}
                                            />

                                            {/* <DropdownComponent optionData={columns}/> */}

                                            <StackItem>
                                              <Divider />
                                            </StackItem>
                                            <StackItem>
                                              <Caption1> Value </Caption1>
                                            </StackItem>

                                            {/* <DropdownComponent optionData={columns}  setDropDownValue={setDropDownValue2} dropDownvalue={dropDownValue2}/> */}

                                            <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />

                                            {/* <DropdownComponent optionData={columns}/> */}

                                            <StackItem>
                                              <Divider />
                                            </StackItem>
                                            <Stack tokens={{ childrenGap: 10 }} horizontal>
                                              <StackItem>
                                                <Button
                                                  appearance="primary"
                                                  onClick={() => {
                                                    handleFilterArray();
                                                    setFilterMenuOpen(false);
                                                  }}
                                                >
                                                  <Caption1> Apply </Caption1>
                                                </Button>
                                              </StackItem>
                                              <StackItem>
                                                <Button onClick={() => setFilterMenuOpen(false)}>
                                                  <Caption1> Cancel </Caption1>
                                                </Button>
                                              </StackItem>
                                            </Stack>
                                          </Stack>
                                        </MenuList>
                                      </>
                                    }
                                  />
                                </StackItem>
                                <StackItem>
                                  {filterArray.length > 0 &&
                                    filterArray.map((item, index) => (
                                      <Badge appearance="filled" color="informative">
                                        <Stack key={index} tokens={{ childrenGap: 5 }} horizontal>
                                          <StackItem> {item} </StackItem>
                                          <StackItem>
                                            <Dismiss16Regular
                                              onClick={() => {
                                                handleCloseFilter(index);
                                              }}
                                            />
                                          </StackItem>
                                          {/* <ArrowPrevious20Filled {...primaryIcon} onClick={() => {handleCloseFilter(index)}}></ArrowPrevious20Filled> */}
                                        </Stack>
                                      </Badge>
                                    ))}
                                </StackItem>
                                <StackItem
                                  onClick={() => {
                                    setFilterArray([]);
                                    setIsFilter(false);
                                    setDropDownValue2('');
                                    setResetDisabled(true);
                                  }}
                                >
                                  <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                                      <StackItem>
                                        {isResetDisabled ? <FilterDismiss20Filled {...secondaryIcon} /> : <FilterDismiss20Filled {...primaryIcon} />}
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Reset Filters </Caption1>
                                      </StackItem>
                                    </Stack>
                                  </Button>
                                </StackItem>
                              </Stack>
                            </StackItem>

                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                            </Stack>

                            {tableData.length > 0 ? (
                              <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                                <StackItem>
                                  {totalRecords > 0 && (
                                    <Caption1>
                                      Showing {(currentPageNo - 1) * 50 + 1} to
                                      {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of {totalRecords} Records
                                    </Caption1>
                                  )}
                                </StackItem>
                                <StackItem>
                                  <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                                </StackItem>
                              </Stack>
                            ) : (
                              <Stack horizontalAlign="start" tokens={{ childrenGap: 15 }}>
                                <StackItem>
                                  <Text></Text>
                                </StackItem>
                                <StackItem>
                                  <Caption1> No Results. </Caption1>
                                </StackItem>
                              </Stack>
                            )}
                          </StackItem>

                          {!configGroupBtnCliked && (
                            <Groups_func
                              data={final_data}
                              func={set_final_data}
                              searchVal={searchVal}
                              deviceSearchVal={deviceSearchVal}
                              pageNo={(currentPageNo - 1) * 50}
                              setTotalPages={setTotalPages}
                              setTotalRecords={setTotalRecords}
                              isFilter={isfilter}
                              dropDownValue1={dropDownValue1}
                              setIsFilter={setIsFilter}
                              dropDownValue2={dropDownValue2}
                              deviceData={devicesData}
                              setDevicesData={setDevicesData}
                              isRefresh={isRefresh}
                              setRefresh={setRefresh}
                              deleteClassification={deleteClassification}
                              setDeleteClassification={setDeleteClassification}
                              setDeleteEnabled={setIconEnabled}
                              setLinkedDeviceCnt={setLinkedDeviceCnt}
                              setIsToast={setIsToast}
                              csvDevicesList={csvDevicesList}
                              setDevicesOfTenant={setDevicesOfTenant}
                              setExportDetails={setExportDetails}
                              setRefreshToast={setRefreshToast}
                            />
                          )}

                          {configGroupBtnCliked && (
                            <Groups_func
                              data={final_data}
                              func={set_final_data}
                              searchVal={searchVal}
                              deviceSearchVal={deviceSearchVal}
                              pageNo={(currentPageNo - 1) * 50}
                              setTotalPages={setTotalPages}
                              setTotalRecords={setTotalRecords}
                              isFilter={isfilter}
                              dropDownValue1={dropDownValue1}
                              setIsFilter={setIsFilter}
                              dropDownValue2={dropDownValue2}
                              deviceData={devicesData}
                              setDevicesData={setDevicesData}
                              setConfigGroupBtnCliked={setConfigGroupBtnCliked}
                              configGroupBtnCliked={configGroupBtnCliked}
                              setGroupConfigCnt={setGroupConfigCnt}
                              setLinkedDeviceCnt={setLinkedDeviceCnt}
                              operationType={operationType}
                              tracker={tracker}
                              setTracker={setTracker}
                              userActivityData={userActivityData}
                              setUserActivityData={setUserActivityData}
                              isRefresh={isRefresh}
                              setRefresh={setRefresh}
                            />
                          )}

                          {addClassification && (
                            <Groups_func
                              data={final_data}
                              func={set_final_data}
                              pageNo={(currentPageNo - 1) * 50}
                              setTotalPages={setTotalPages}
                              setTotalRecords={setTotalRecords}
                              isFilter={isfilter}
                              dropDownValue1={dropDownValue1}
                              setIsFilter={setIsFilter}
                              dropDownValue2={dropDownValue2}
                              addClassification={addClassification}
                              setAddClassification={setAddClassification}
                              classificationName={classificationName}
                              setOverlayOpenNew={setOverlayOpenNew}
                              deviceSearchVal={deviceSearchVal}
                              setLinkedDeviceCnt={setLinkedDeviceCnt}
                              setIsToast={setIsToast}
                              selectedValue={selectedValue}
                              tracker={tracker}
                              setTracker={setTracker}
                              userActivityData={userActivityData}
                              setUserActivityData={setUserActivityData}
                            />
                          )}

                          {tableData.length > 0 && <DataTable tableData={tableData} columns={columns} />}
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <OverLay overlayopen={overlayOpen} setOverlayOpen={setOverlayOpen} overlayData={overLayData} />

                  <OverLay overlayopen={overlayOpenNew} setOverlayOpen={setOverlayOpenNew} overlayData={overLayDataNew} />

                  {isToast && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={isToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={addClassification ? 'New Classification Created Successfully' : 'Classification Deleted Successfully'}
                      footer=""
                    />
                  )}

                  {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}

                  {refreshToast && (
                    <ToastComponent
                      setmessagebar={setIsToast}
                      isToastEnabled={refreshToast}
                      toastIntent="success"
                      position="top-end"
                      title="Success"
                      message={'Data Table Refreshed'}
                      footer=""
                    />
                  )}
                </StackItem>
                <StackItem>
                  <Text></Text>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem>
              <Text></Text>
            </StackItem>
          </Stack>
        </FullScreen>
        {/* : <StackItem>No result</StackItem> } */}
      </FluentProvider>
    </>
  );
};
