import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getRemediationListQuery } from "../../graphql/remediation_queries";
import { setPolicyPatch } from '../../graphql/remediation_queries';

import { log } from 'console';
import { GlobalContext } from "../../hooks/globalContext";

type dataProps = {
    data?: any;
    func?: any;
    searchVal?: String;
    pageNo?: any;
    setTotalPages?: any;
    setTotalRecords?: any;
    isFilter?:any
    dropDownValue1?:any
    setIsFilter?:any
    dropdownValue2?:any
    setDartSpecs?: any;
    setJsonData?: any;
    setUiData?: any;
    deleteAutomation?: any;
    setDeleteAutomation?: any;
    setDeleteDisabled?: any;
    isRefresh?: any;
    setRefresh?: any;
    setExportDetails?: any;
    setRefreshToast?:any
}

const Policy_func = (props: any) => {
  console.log("props new data=",props?.newData);
  
  const client = useApolloClient();
  const contextValue: any = useContext(GlobalContext);
  var dartNo: any = contextValue.dartNo;
  // var policy288=props.policy288;
  var tenantName = contextValue.tenantName;
  let policyid:any =''
  policyid=sessionStorage.getItem('policyid')

  const getRemediationList = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getRemediationListQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data){
      props.func(data);
    }
  };

  const getRemediationListCnt = async (whereCondition: any, limit: any = null, offset: any = null) => {
    const { data, loading, error } = await client.query({
      query: getRemediationListQuery,
      variables: {whereCondition, limit, offset},
      fetchPolicy: 'network-only',
    });

    if(!loading && data.core_AutomationJSON.length > 0){
      props?.setExportDetails && props?.setExportDetails(data.core_AutomationJSON);
      props.setTotalRecords(data.core_AutomationJSON.length);
      props.setTotalPages(Math.ceil((data.core_AutomationJSON.length)/50));
    }
  }
  // const setPolicy_Patch = async ()=>{
  //   policyid = parseInt(policyid);
  //   const { data } = await client.mutate({
  //     mutation: setPolicyPatch,
  //     variables: {policyid,policy288},
  //     fetchPolicy: 'network-only',
  //   });
  //   if(data){
  //    console.log("successful set of policy data");
  //    props.setPolicyPatch(false);
     
  //   }

  // }
  
  useEffect(() => {    
    // if(props.policyPatch == true){
     
      // props.setPolicyPatch(false);
    // }
    
    if(props.searchVal?.length > 0 && props.searchVal !== ''){
        getRemediationList({automationName: {contains: props.searchVal}, types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
        getRemediationListCnt({automationName: {contains: props.searchVal}, types: {_in: ['policy']}});
    }
    else if(props.isFilter == true){
      if(props.dropDownValue1 == 'Name'){
        getRemediationList({automationName: {contains: props.dropDownValue2}, types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
        getRemediationListCnt({automationName: {contains: props.dropDownValue2}, types: {_in: ['policy']}});
      }else if(props.dropDownValue1 == 'Type'){
        getRemediationList({automationName: {contains: props.dropDownValue2}, types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
        getRemediationListCnt({automationName: {contains: props.dropDownValue2}, types: {_in: ['policy']}});
      }else if(props.dropDownValue1 == 'Created By'){
        getRemediationList({createdBy: {contains: props.dropDownValue2}, types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
        getRemediationListCnt({createdBy: {contains: props.dropDownValue2}, types: {_in: ['policy']}});
      }
      else if(props.dropDownValue1 == 'Status'){
        getRemediationList({status: {contains: props.dropDownValue2}, types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
        getRemediationListCnt({status: {contains: props.dropDownValue2}, types: {_in: ['policy']}});
      }
    }else{
      getRemediationList({types: {_in: ['policy']}}, 50, parseInt(props.pageNo));
      getRemediationListCnt({types: {_in: ['policy']}});
    }

    if(props.isRefresh){
        props.setRefresh(false);
        props.setRefreshToast(true);  
        setTimeout(() => {
          props.setRefreshToast(false);  
        }, 3000);
    }
    
  },[props.isRefresh, props.searchVal, props.pageNo,props.isFilter]);

  return (
    <></>
  )
}

export { Policy_func }
 