import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { getGroupIdOfTenantQuery, getComponentsDetailsOfDartQuery, insertValueOfComponentQuery, updateValueOfComponentQuery, updateAutomationStautsQuery, deleteValueOfComponentQuery } from "../../graphql/remediations_queries"
import { getAutomationListsQuery } from "../../graphql/configuration_queries";
import { GlobalContext } from "../../hooks/globalContext";
import { useNavigate } from "react-router-dom";
import { log } from 'console';

type dataProps = {
    data?: any;
    func?: any;
    searchVal?: String;
    pageNo?: any;
    setTotalPages?: any;
    setTotalRecords?: any;
    setDartSpecs?: any;
    setJsonData?: any;
    setUiData?: any;
    deleteAutomation?: any;
    setDeleteAutomation?: any;
    setDeleteDisabled?: any;
    isRefresh?: any;
    setRefresh?: any;
}

const Remediations_autoheal_func = (props: any) => {
    const client = useApolloClient();
    const navigate = useNavigate();

    const contextValue: any = useContext(GlobalContext);
    var selctedValue: any = '';
    var dartNo: any = contextValue.dartNo;
    var tenantName = contextValue.tenantName;
    var automationName = contextValue.automationDetails.automationName;
    // var mgroupuniq = '';
    var insertCounter = 0;
    var totalGrpDataCnt = 0;
    var failedAttachmentGroups: any = [];
    var failedDetachmentGroups: any = [];
    var mcatuniq = '';
    var varnameuniq = '';
    var automationData = {
        id: '',
        automation_name: '',
        automation_description: '',
        dartNo: '',
        automationExecute : '',
        issueDescription : '',
        issueResolution : '',
        referenceField : '',
        executionInterval : '',
        triggerAutoheal : '',
        status: ''
    };

    var autoHealConfigData: any = {};
    var serviceLogMasterData: any = {};
    var subAutomation: any = [];

    const getAutomationList = async (whereCondition: any, limit: any = null, offset: any = null, name: String = '') => {
        const { data, loading, error } = await client.query({
          query: getAutomationListsQuery,
          variables: {whereCondition, limit, offset},
          fetchPolicy: 'network-only',
        });
    
        if(!loading && data?.core_AutomationJSON?.length > 0){
            if(name === 'autohealConfig'){
                subAutomation = [...subAutomation,
                    {
                        id: data.core_AutomationJSON[0].id,
                        dartId: data.core_AutomationJSON[0].dartid,
                        automationName: data.core_AutomationJSON[0].automationName,
                        jsonVal: JSON.parse(data.core_AutomationJSON[0].jsonValue),
                        varValue: JSON.parse(data.core_AutomationJSON[0].varValues)
                    }
                ]
            }else{
                var jsonVal = JSON.parse(data.core_AutomationJSON[0].jsonValue);
                automationData.id = data.core_AutomationJSON[0].id;
                automationData.automation_name = data.core_AutomationJSON[0].automationName;
                automationData.automation_description = data.core_AutomationJSON[0].description;
                automationData.dartNo = data.core_AutomationJSON[0].dartid;
                automationData.status = data.core_AutomationJSON[0].status;
                
                automationData.automationExecute = jsonVal.AutomationExecute;
                automationData.issueDescription = jsonVal.Issue_description;
                automationData.issueResolution = jsonVal.Issue_resolution;
                automationData.referenceField = jsonVal.Reference_field;
                automationData.executionInterval = jsonVal.Execution_interval;
                automationData.triggerAutoheal = jsonVal.Trigger_autoheal;
            }
        }
    }
    
    //to check site/group is configured or not
    const getGroupIdOfTenant = async (whereCondition: any, action: any) => {
        insertCounter = 0;
        const { data, loading, error } = await client.query({
            query: getGroupIdOfTenantQuery,
            variables: { whereCondition },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data){
            totalGrpDataCnt = data.core_MachineGroups.length;
            data.core_MachineGroups.map(async (val: any) => {
                insertCounter++;
                mcatuniq = val.mcatuniq;
                
                await getComponentsDetailsOfDart([69, 306], val.mgroupuniq, val.name);
                await getAutomationList({automationName: {_eq: automationData.automationExecute}}, null, null,'autohealConfig');

                if(action == 'detach'){
                    console.log("deleteing...........");

                    // setTimeout(() => {
                        removeValueInServiceLogComponent(val.mgroupuniq, val.name);
                    // }, 2000);
                } 
                
                if(action == 'attach'){
                    console.log("inserting...........");
    
                    // setTimeout(() => {
                        setValueOfServiceLogComponent(val.mgroupuniq, val.name);
                    // }, 2000);
                }
            });
        }else{
            console.log("Site has not configured");

            props.setmessagebar1(false);
            props.setmessagebar2(true);
            props.setToastMessage("Assignment failed");
        }
    }

    //to fetch the name, varnameuniq, varscopuniq, varuniq of components in dart 69
    const getComponentsDetailsOfDart = async (dartId: any, groupId: any, name: String) => {
        const { data, loading, error } = await client.query({
            query: getComponentsDetailsOfDartQuery,
            variables: { dartId, groupId },
            fetchPolicy: 'network-only',
        });
  
        if(!loading && data) {
            data.core_Variables.map((val: any) => {
                if(val.name === 'S00069AutoHealConfig'){
                    var autoHealConfigIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    autoHealConfigData[`${name}`] = autoHealConfigIds;
                }else if(val.name === 'S00306_ServiceLogMaster'){
                    var serviceLogMasterIds = {
                        id : val.Variables_VarValues[0]?.valueid,
                        varuniq : val.varuniq,
                        varscopuniq : val.varscopuniq,
                        varnameuniq : val.varnameuniq,
                        componentValue : val.Variables_VarValues[0]?.valu,
                        revised : val.Variables_VarValues[0]?.revl
                    }

                    serviceLogMasterData[`${name}`] = serviceLogMasterIds;
                }
            });
        }
    }

    const setValueOfServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var triggerValue = automationData.triggerAutoheal;
        var serviceLogMasterVal = '';
        var newVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`].componentValue != undefined){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');
                serviceLogMasterArr = serviceLogMasterArr.filter((val: any) => val !== '');
    
                var serviceLogMasterLastVal = serviceLogMasterArr[(serviceLogMasterArr?.length - 1)];
    
                var lastSequenceId: any = serviceLogMasterLastVal?.split("#NXT#")[0];
                lastSequenceId = parseInt(lastSequenceId);
                lastSequenceId = isNaN(lastSequenceId) ? 0 : lastSequenceId;
    
                lastSequenceId++;
    
                newVal = `\n${lastSequenceId}#NXT#69#NXT#${automation_name}#NXT#"${triggerValue}"#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
    
                newVal = serviceLogMasterVal + newVal;
            }else{
                var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
                newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

                newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

                newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

                newVal += `5#NXT#69#NXT#${automation_name}#NXT#"${triggerValue}"#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;
            }

            // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

            await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));

            // await updateValueOfComponents({valueid: {_eq: serviceLogMasterData[`${name}`].id}}, newVal, (revised + 1));
        }else{
            var newVal = `1#NXT#148#NXT#Sync status#NXT#"RetrieveVariableValues":"Successful"#NXT#Last sync Successful#NXT#Last sync Failed#NXT#Tile\n`;
            
            newVal += `2#NXT#148#NXT#Sync status#NXT#Could not open connection with server#NXT#Last sync Failed#NXT#Last sync Failed#NXT#Tile\n`;

            newVal += `3#NXT#263#NXT#Drive Free Space#NXT#"autoUpdateStatus"#NXT#Executed Drive Free Space Successfully#NXT#Drive Free Space Terminated#NXT#Tile\n`;

            newVal += `4#NXT#31#NXT#System boot time#NXT#StartupStatus#NXT#System boot time captured successfully#NXT#System boot time capture terminated#NXT#Tile\n`;

            newVal += `5#NXT#69#NXT#${automation_name}#NXT#"${triggerValue}"#NXT#Executed ${automation_name} Successfully#NXT#${automation_name} Terminated#NXT#Tile`;

            await insertValueOfComponent(newVal, 0, serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq);
        }
        
        setValueOfAutoHealComponent(mgroupuniq, name);
    }

    const setValueOfAutoHealComponent = async (mgroupuniq: any, name: String) => {
        var varValue: any = '';
        var dartId = automationData.dartNo;

        var autoHealVal = '';
        var ID = '';
        var OF = '';
        var OS = '';
        var DN = '';
        var VC = '';
        var EI = '';
        var IDS = '';
        var IRS = '';
        var componentVal = '';
        var status: any = false;
    
        console.log("AutoHeal component loop entered=", autoHealConfigData[`${name}`].componentValue);
        
        if(autoHealConfigData[`${name}`].varuniq?.length > 0 && autoHealConfigData[`${name}`]?.componentValue != undefined){ // to check if autoHealConfig is present for particluar site/group in varvalues table 
            var res = autoHealConfigData[`${name}`].componentValue;
            var revised = autoHealConfigData[`${name}`].revised;
            
             console.log("imm res=",res);
            if(res?.length > 0){
                autoHealVal = res?.trim();
                
                var autoHealArr = autoHealVal.split('$$$');
                var lastAutoHealValArr = autoHealArr[autoHealArr.length - 1];
                var lastAutoHealId = parseInt(lastAutoHealValArr.split('\n')[0].split(":")[1]);

                lastAutoHealId++;
                ID = `ID:${lastAutoHealId}`;
                OF = "\n" + `OF:${automationData.referenceField}`;
                OS = "\n" + `OS:${automationData.triggerAutoheal}`;
                DN = "\n" + `DN:${subAutomation[0].dartId}`;
                VC = "\n" + 'VC:';
                EI = "\n" + `EI:${automationData.executionInterval}`;
                IDS = "\n" + `IDS:${automationData.issueDescription}`;
                IRS = "\n" + `IRS:${automationData.issueResolution}`;

                var data = subAutomation[0].varValue;
                var keys = Object.keys(data);
                
                keys.map((val: any, i: any) => {
                    var VarTypeVal = data[`${val}`];

                    if(val.includes('RunNow')){
                        VarTypeVal = 4;
                    }else if (typeof VarTypeVal === 'number' && Number.isInteger(VarTypeVal)) {
                        VarTypeVal = 3;
                    } else if (typeof VarTypeVal === 'string') {
                        VarTypeVal = 1;
                    }else if (typeof VarTypeVal === 'boolean') {
                        VarTypeVal = 2;
                    } 

                    var VarName = `VarName=${val}`;
                    var VarType = `VarType=${VarTypeVal}`;
                    var VarValue = `VarValue=${data[`${val}`]}`;

                    componentVal += (i == 0) ? '' : "\n";
                    componentVal += VarName + "\n" + VarType + "\n" + VarValue + "\n" + "&&&";
                });
                VC += componentVal;

                console.log("autoHealVal autoHealVal=",autoHealVal);
                console.log("grp name=",name);

                if(!autoHealVal.includes(automationData.triggerAutoheal)){
                    autoHealVal = autoHealVal + "\n" + "$$$" + "\n" + ID + OF + OS + DN + VC + EI + IDS + IRS; 
    
                    console.log("final updated value=",autoHealVal);
                    
                   //status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

                   status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: autoHealConfigData[`${name}`].varuniq}, varscopuniq: {_eq: autoHealConfigData[`${name}`].varscopuniq}}, autoHealVal, (revised + 1));

                  // status = await updateValueOfComponents({valueid: {_eq: serviceLogMasterData[`${name}`].id}}, autoHealVal, (revised + 1));
                }else{
                    props.setToastMessage(`(${name})already exists in auto heal component in varValues table`);
                    props.setErrorToast(true);
        
                    setTimeout(() => {
                        props.setErrorToast(false);
                    }, 5000);
                }
            }else{
                ID = `ID:1`;
                OF = "\n" + `OF:${automationData.referenceField}`;
                OS = "\n" + `OS:${automationData.triggerAutoheal}`;
                DN = "\n" + `DN:${subAutomation[0].dartId}`;
                VC = "\n" + 'VC:';
                EI = "\n" + `EI:${automationData.executionInterval}`;
                IDS = "\n" + `IDS:${automationData.issueDescription}`;
                IRS = "\n" + `IRS:${automationData.issueResolution}`;

                var data = subAutomation[0].varValue;
                console.log("sub automation data=",data);
                
                var keys = Object.keys(data);
                
                keys.map((val: any, i: any) => {
                    var VarName = `VarName=${val}`;
                    var VarType = `VarType=1`; //1 needs to be changed based on the varValue
                    var VarValue = `VarValue=${data[`${val}`]}`;

                    componentVal += (i == 0) ? '' : "\n";
                    componentVal += VarName + "\n" + VarType + "\n" + VarValue + "\n" + "&&&";
                });
                VC += componentVal;

                autoHealVal = ID + OF + OS + DN + VC + EI + IDS + IRS;

                // status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

                status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: autoHealConfigData[`${name}`].varuniq}, varscopuniq: {_eq: autoHealConfigData[`${name}`].varscopuniq}}, autoHealVal, (revised + 1));

                // status = await updateValueOfComponents({valueid: {_eq: autoHealConfigData[`${name}`].id}}, autoHealVal, (revised + 1));
            }
        }else{
            ID = `ID:1`;
            OF = "\n" + `OF:${automationData.referenceField}`;
            OS = "\n" + `OS:${automationData.triggerAutoheal}`;
            DN = "\n" + `DN:${subAutomation[0].dartId}`;
            VC = "\n" + 'VC:';
            EI = "\n" + `EI:${automationData.executionInterval}`;
            IDS = "\n" + `IDS:${automationData.issueDescription}`;
            IRS = "\n" + `IRS:${automationData.issueResolution}`;

            var data = subAutomation[0].varValue;
            console.log("sub automation data=",data);
            
            var keys = Object.keys(data);
            
            keys.map((val: any, i: any) => {
                var VarName = `VarName=${val}`;
                var VarType = `VarType=1`; //1 needs to be changed based on the varValue
                var VarValue = `VarValue=${data[`${val}`]}`;

                componentVal += (i == 0) ? '' : "\n";
                componentVal += VarName + "\n" + VarType + "\n" + VarValue + "\n" + "&&&";
            });
            VC += componentVal;

            autoHealVal = ID + OF + OS + DN + VC + EI + IDS + IRS;

            console.log("final inserted value=",autoHealVal);

           status = await insertValueOfComponent(autoHealVal, 0, autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq);
        }

        console.log("totalGrpDataCnt=",totalGrpDataCnt, "insertCounter=",insertCounter);

        
        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;
            
            if(selctedValue == 'tenant'){
        
                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":all";
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:all`);

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `${tenantName}:all`;
                    }
                }else{
                    selctedValue = `${automationStatus}|${tenantName}:all}`;
                }
            }else{
                var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');
                console.log("attached selectedGroups=",selectedGroups);
                selectedGroups = selectedGroups.filter((val: any) => !(failedAttachmentGroups.includes(val)));
                console.log("filtered attached selectedGroups=",selectedGroups);

                if(automationStatus == 'not attached'){
                    selctedValue = tenantName + ":" + selectedGroups.join(",");
                }else if(automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1];

                        console.log("attachment curSelectedGRp=",curSelectedGRp);

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));
                        statusArr.push(`${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp = automationStatus.split(":")[1];

                        selctedValue = `${tenantName}:${curSelectedGRp},${selectedGroups.join(",")}`;
                    }
                }else{

                    selctedValue = `${automationStatus}|${tenantName}:${selectedGroups.join(",")}`; 
                }
            }
            console.log("selctedValue=",selctedValue);
            
            var ids = subAutomation.map((val : any) => val.id);
            ids.push(automationData.id);
            
            await updateAutomationStauts({id: {_in: ids}}, selctedValue, 0, 'attach');

            // await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, 0, 'attach');
        }
    };

    const removeValueInServiceLogComponent = async (mgroupuniq: any, name: String) => {
        var automation_name = automationData.automation_name;
        var varValue = automation_name?.split(" ").join("");
        var triggerValue = automationData.triggerAutoheal;
        var serviceLogMasterVal = '';

        if(serviceLogMasterData[`${name}`].varuniq?.length > 0 && serviceLogMasterData[`${name}`]?.componentValue?.length > 0){
            var res = serviceLogMasterData[`${name}`].componentValue;
            var revised = serviceLogMasterData[`${name}`].revised;

            serviceLogMasterVal = res?.trim();
            if(serviceLogMasterVal?.length > 0){
                var serviceLogMasterArr = serviceLogMasterVal?.split('#NXT#Tile');

                var newVal = serviceLogMasterArr.filter((val: any) => !(val.includes('#NXT#69#NXT#') && val.includes(`#NXT#"${triggerValue}"#NXT#`))).join('#NXT#Tile');

                // await updateValueOfComponent(newVal, (revised + 1), serviceLogMasterData[`${name}`].varnameuniq, serviceLogMasterData[`${name}`].varscopuniq, serviceLogMasterData[`${name}`].varuniq, mgroupuniq, serviceLogMasterData[`${name}`].id);

                await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: serviceLogMasterData[`${name}`].varuniq}, varscopuniq: {_eq: serviceLogMasterData[`${name}`].varscopuniq}}, newVal, (revised + 1));

                //await updateValueOfComponents({valueid: {_eq: autoHealConfigData[`${name}`].id}}, newVal, (revised + 1));
            }
        }
        removeValueInAutoHealComponent(mgroupuniq, name);
    }

    const removeValueInAutoHealComponent = async (mgroupuniq: any, name: String) => {
        var status: any = false;
        var res = autoHealConfigData[`${name}`].componentValue;
        var revised = autoHealConfigData[`${name}`].revised;
        var attachedGrps = automationData.status.split("-").filter((val) => val != 'attached'); 
        var subDartNo = subAutomation[0].dartId;
        var dartId = automationData.dartNo;
        var autoHealVal = res?.trim();

        if(autoHealVal != undefined && autoHealVal?.length > 0){
            autoHealVal = autoHealVal.split('$$$').filter((val: any) => !(val.includes(`DN:${subDartNo}`))).join("$$$");

            // status = await updateValueOfComponent(autoHealVal, (revised + 1), autoHealConfigData[`${name}`].varnameuniq, autoHealConfigData[`${name}`].varscopuniq, autoHealConfigData[`${name}`].varuniq, mgroupuniq, autoHealConfigData[`${name}`].id);

            status = await updateValueOfComponents({mgroupuniq: {_eq: mgroupuniq}, varuniq: {_eq: autoHealConfigData[`${name}`].varuniq}, varscopuniq: {_eq: autoHealConfigData[`${name}`].varscopuniq}}, autoHealVal, (revised + 1));

            // status = await updateValueOfComponents({valueid: {_eq: autoHealConfigData[`${name}`].id}}, autoHealVal, (revised + 1));
        }else{
            status = true;
        } 

        if(status && (totalGrpDataCnt == insertCounter)){
            insertCounter = 0;
            var automationStatus: any = automationData.status;

            if(selctedValue == 'tenant'){
                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        selctedValue = statusArr.join("|");
                    }else{
                        selctedValue = `not attached`;
                    }
                }else{
                    selctedValue = automationStatus;
                }
            }else{
                var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');
                selectedGroups = selectedGroups.filter((val: any) => !(failedDetachmentGroups.includes(val)));

                if(automationStatus != 'not attached' && automationStatus.includes(`${tenantName}:`)) {
                    if(automationStatus.includes("|")){
                        var statusArr: any = automationStatus.split("|");
                        var curSelectedGRp = statusArr.filter((ele: any) => ele.includes(`${tenantName}:`))[0].split(":")[1].split(",");

                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        statusArr = statusArr.filter((ele: any) => !ele.includes(`${tenantName}:`));

                        curSelectedGRp.length > 0 && statusArr.push(`${tenantName}:${curSelectedGRp.join(",")}`);
                        selctedValue = statusArr?.join("|");
                    }else{
                        var curSelectedGRp: any = automationStatus.split(":")[1].split(",");
                        curSelectedGRp = curSelectedGRp.filter((val: any) => !selectedGroups.includes(val));

                        if(curSelectedGRp.length == 0){
                            selctedValue = 'not attached';
                        }else{
                            selctedValue = `${tenantName}:${curSelectedGRp.join(",")}`;
                        }
                    }
                }else{
                    selctedValue = automationStatus; 
                }
            }

            let flag = props.groupAttachment?.length ? props.groupAttachment?.length : 0;
            
            var ids = subAutomation.map((val : any) => val.id);
            ids.push(automationData.id);
            
            await updateAutomationStauts({id: {_in: ids}}, selctedValue, flag, 'detach');

            // await updateAutomationStauts({id: {_eq: automationData.id}, dartid: {_eq: dartId}}, selctedValue, flag, 'detach');
            
            // if(props.groupAttachment.length > 0){
            //     automationData.status = selctedValue;
            //     setTimeout(() => {
            //         attachRemediation();
            //     }, 1000);
            // }
        } 
    }

    const updateAutomationStauts = async (whereCondition: any, status: any, flag: any, type: String = '') => { 
        const { data } = await client.mutate({
            mutation: updateAutomationStautsQuery,
            variables: { whereCondition, status },
            fetchPolicy: 'network-only',
        });

        if(data.update_core_AutomationJSON.affected_rows > 0){
            if(flag == 0){
                if(type == 'attach'){
                    props.setToastMessage(`Automation attached to ${props.groupAttachment[0]} ${props.groupAttachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);
                    
                    props.setAllAttachmentGrp((prev: any) => [...prev.slice(1)]);
                }else if(type == 'detach'){
                    props.setToastMessage(`Automation detached from ${props.groupDetachment[0]} ${props.groupDetachment[0] !== 'All Devices' ? 'group' : ''} successfully.`);

                    props.setAllDetachmentGrp((prev: any) => [...prev.slice(1)]);
                } 

                // props.setToastMessage("Automation attached to selected devices successfully.");
                props.setmessagebar1(false);
                props.setmessagebar2(true);

                // setTimeout(() => {
                //     navigate("/manage/devices/remediation");
                // }, 5000);
            }
        }
    }

    const insertValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any ) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: insertValueOfComponentQuery,
          variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
    
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponent = async (valu: String, revl: any, varnameuniq: any, varscopuniq: any, varuniq: any, mgroupuniq: any, valueid: any) => {
        console.log("valueid=",valueid);
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
            mutation: insertValueOfComponentQuery,
            variables: {valu, revl, varnameuniq, varscopuniq, varuniq, mcatuniq, mgroupuniq, lastchange}
        });
        
        if(data.insert_core_VarValues.affected_rows > 0){
            console.log("new component value inserted");
            return true;
        }else{
            return false;
        }
    };

    const updateValueOfComponents = async (whereCondition: any, valu: String, revl: any) => {
        const lastchange: any = Math.floor(Date.now() / 1000);

        const { data } = await client.mutate({
          mutation: updateValueOfComponentQuery,
          variables: {whereCondition, valu, revl, lastchange}
        });
    
        if(data.update_core_VarValues.affected_rows > 0){
            console.log("component value updated");
            return true;
        }else{
            return false;
        }
    };

    const attachRemediation = async () => {
        var selected = contextValue.selectedRowIdInTable;
        var selectedGroups = props.groupAttachment.filter((val: any) => val != 'All Devices');

        if(selected.includes('All Devices')){
            selctedValue = 'tenant';
            await getGroupIdOfTenant({name: {_eq: tenantName}}, 'attach');
        }
        
        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification'; 
            await getGroupIdOfTenant({name: {_in: selectedGroups}}, 'attach');
        }
    };

    const detachRemediation = () => {
        var selectedGroups = props.groupDetachment.filter((val: any) => val != 'All Devices');

        if(props.groupDetachment.includes('All Devices')){
            selctedValue = 'tenant';
            getGroupIdOfTenant({name: {_eq: tenantName}}, 'detach');
        }
        
        if(selectedGroups.length > 0){
            selctedValue = 'deviceClassification';
            getGroupIdOfTenant({name: {_in: selectedGroups}}, 'detach');
        }
    };
  
    const modifyRemediation = async () => {
        await getAutomationList({dartid: {_eq: 69}, automationName: {_eq: automationName}});

        if(props.groupDetachment.length > 0){
            detachRemediation();
        }else if(props.groupAttachment.length > 0){
            attachRemediation();
        }
    }
  
    useEffect(() => {   
        modifyRemediation();
    },[props.saveBtnClicked, props.groupAttachment, props.groupDetachment]);

    return (
        <></>
    )
}

export { Remediations_autoheal_func }