import { useState, useEffect, useContext, ChangeEvent, useRef } from 'react';
import { Census_func } from '../../../api/devices/census_func';
import { SideMenu } from '../../../components/side_menu/inline_side_menu.component';
import { DataTable } from '../../../components/data_table/data_table.component';
import OverLay from '../../../components/side_menu/overlay_side_menu.component';
import { OverflowSetCustomExample } from '../../../components/side_menu/overFlowSet.component';
import { Stack, StackItem, TooltipHost } from '@fluentui/react';
import { CSVLink } from 'react-csv';
import { ButtonProps, Field, Link, Textarea, ToggleButton, Tooltip } from '@fluentui/react-components';
import {
  MenuItem,
  Button,
  MenuList,
  MenuButton,
  Divider,
  Text,
  Badge,
  Body1Strong,
  Title3,
  Body1,
  Caption1,
  FluentProvider,
  webLightTheme,
  Theme,
  createLightTheme,
  createDarkTheme,
  BrandVariants,
  Label,
  Input,
  useId,
  Card,
  Image,
} from '@fluentui/react-components';
import {
  AddSquare24Regular,
  AddSquare20Regular,
  SlideEraser20Regular,
  SaveCopy20Regular,
  PulseSquare20Regular,
  AppsAddIn20Regular,
  BoxCheckmark20Regular,
  UninstallApp20Regular,
  CheckboxCheckedSync20Regular,
  SquareHintApps20Regular,
  ShieldLock20Regular,
  BookTemplate20Regular,
  Filter20Filled,
  FilterDismiss20Filled,
  Desktop20Filled,
  Info20Filled,
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  Settings20Regular,
  ArrowMaximize16Regular,
  ArrowMaximize20Regular,
  DismissSquare24Filled,
  AddSquare24Filled,
  Delete24Regular,
  Dismiss16Regular,
} from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { Pagination } from '../../../components/pagination/pagination.component';
import { useNavigate } from 'react-router-dom';
import { MenuDropdown } from '../../../components/dropdown/menu.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { Resize } from '../../../components/textarea/textarea.component';
import { Checked } from '../../../components/switch/switch.component';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MultiselectWithTags } from '../../../components/dropdown/multiselect.component';
import { Install_Status_func } from '../../../api/app_install_status_func';
import { TroubleshooterDevice_fun } from '../../../api/troubleshooterDevice_fun';
import { CheckBox } from '../../../components/checkbox/checkbox_component';
import { App_Monitored_Func } from '../../../api/app_monitored_func';
import { GlobalContext } from '../../../hooks/globalContext';
import DialogBox from '../../../components/dialog/dialog.component';
// import { validate } from "uuid";
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ToastComponent } from '../../../components/toast/toast.component';
import { Activity_log_func } from '../../../api/activity_log/activity_log_func';
import {
  fullscreenIcon,
  primaryIcon,
  secondaryIcon,
  deleteIcon,
  exitIcon,
  messageTheme,
  lightTheme,
  inputsizestyle,
  overflowDivider,
} from '../../../styles/customStyles';
import { useLocation } from 'react-router-dom';
import { shouldLoadRoute } from '../../../Authentication/RouteProtection';

const handleExportCSV = () => {
  // Programmatically trigger the CSV export link
  const csvExportLink = document.getElementById('csv-export-link');
  if (csvExportLink) {
    csvExportLink.click();
  }
};

export const Appmonitored = () => {
  const location = useLocation();
  const contextValue: any = useContext(GlobalContext);
  const navigate = useNavigate();
  const [final_data, set_final_data]: any = useState(null);
  const [final_data1, set_final_data1]: any = useState(null);
  const [isdeletedisabled, setDeleteDisabled] = useState(true);
  const [isWebEnable, setWebEnable] = useState(false);
  const [isDesktopEnable, setDesktopEnable] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [leftOpen, setLeftOpen] = useState(false);
  const [leftOpenSubMenu, setleftOpenSubMenu] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayName, setOverlayName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [dropDownValue1, setDropDownValue1] = useState<any[]>([]);
  const [dropDownValue2, setDropDownValue2] = useState<any>();
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [isfilter, setIsFilter] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(true);
  const [deviceName, setDeviceName] = useState('');
  const [deviceList, setDeviceList]: any = useState(null);
  const [monitoredOverLayData, setMonitoredOverLayData]: any = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [rowID, setRowID]: any = useState(null);
  const [processName, setProcessname]: any = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [saveBtnClicked1, setSaveBtnClicked1]: any = useState(false);
  const [saveBtnClicked, setSaveBtnClicked]: any = useState(false);
  const [isRefresh, setIsRefresh]: any = useState(false);
  const [refreshToast, setRefreshToast]: any = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [score, setScore]: any = useState('');
  const [tracker, setTracker] = useState(false);
  const [userActivityData, setUserActivityData] = useState({});
  const [error, seterror] = useState(false);
  const [categorySearchVal, setCategorySearchVal] = useState('');
  const [categorySearchData, setCategorySearchData] = useState([
    'Business Application',
    'Productivity Application',
    'Collaboration Tools',
    'Developer Tools',
  ]);
  const [scoresCpuRequired, setScoreCpuRequired] = useState(false);
  const [scoresMemRequired, setScoreMemRequired] = useState(false);
  const [scoresNetRequired, setScoreNetRequired] = useState(false);
  const [permissions, setPermissions] = useState(['']);
  console.log('checking the permissions------>', permissions);

  const [formDataBasic, setFormDataBasic]: any = useState({
    Category: '',
    Subcategory: '',
    ApplicationName: '',

    ApplicationType: '',
    UrlPatterns: [
      {
        // urlpattern: '',
      },
    ],
    ApplicationBinaries: '',
    cpuUtilisation: [
      {
        cpuFrom: '',
        cpuTo: '',
        cpuScore: '',
        cpuRank: '',
      },
    ],
    memUtilisation: [
      {
        memFrom: '',
        memTo: '',
        memScore: '',
        memRank: '',
      },
    ],
    netUtilisation: [
      {
        netFrom: '',
        netTo: '',
        netScore: '',
        netRank: '',
      },
    ],
  });
  const [editformDataBasic, setEditFormDataBasic] = useState({
    Category: '',
    Subcategory: '',
    ApplicationName: '',

    ApplicationType: '',
    UrlPatterns: '',
    ApplicationBinaries: '',
    cpuUtilisation: [
      {
        cpuFrom: '',
        cpuTo: '',
        cpuScore: '',
        cpuRank: '',
      },
    ],
    memUtilisation: [
      {
        memFrom: '',
        memTo: '',
        memScore: '',
        memRank: '',
      },
    ],
    netUtilisation: [
      {
        netFrom: '',
        netTo: '',
        netScore: '',
        netRank: '',
      },
    ],
  });
  console.log('deviceName', deviceName);

  const [selectedOption, setSelectedOption] = useState('');
  const buttonref: any = useRef(null);
  const [exportDetails, setExportDetails]: any = useState([]);
  const [allDevive, setallDevice]: any = useState(1);

  const handleOutsideButton = () => {
    buttonref?.current?.click();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  console.log('formdata', formDataBasic);
  console.log('qwertyuio', formDataBasic.memUtilisation);
  const formattedUrlPatterns = formDataBasic.UrlPatterns.map((item: { urlpatterns: any }) => item.urlpatterns).join(',');
  const convertedDataCpu = {
    range: formDataBasic.cpuUtilisation.map((item: any, index: any) => ({
      cw: '1',
      mw: '1',
      sc: item.cpuScore,
      to: item.cpuTo,
      scw: '1',
      from: item.cpuFrom,
      rank: item.cpuRank,
    })),
  };

  const convertedDataMem = {
    range: formDataBasic.memUtilisation.map((item: any, index: any) => ({
      cw: '1',
      mw: '1',
      sc: item.memScore,
      to: item.memTo,
      scw: '1',
      from: item.memFrom,
      rank: item.memRank,
    })),
  };
  const convertedDataNet = {
    range: formDataBasic.netUtilisation.map((item: any, index: any) => ({
      cw: '1',
      mw: '1',
      sc: item.netScore,
      to: item.netTo,
      scw: '1',
      from: item.netFrom,
      rank: item.netRank,
    })),
  };
  const convertedDataCpustring = JSON.stringify(convertedDataCpu, null, 2);
  const convertedDataMemstring = JSON.stringify(convertedDataMem, null, 2);
  const convertedDataNetstring = JSON.stringify(convertedDataNet, null, 2);

  console.log('converted cpu to json string', convertedDataNetstring);
  const handleInput = (value: any, processName: String, compName: any, index: number) => {
    if (processName == 'cpuUtilisation' && value != '') {
      setScoreCpuRequired(true);
    } else {
      setScoreCpuRequired(false);
    }
    if (processName == 'memUtilisation' && value != '') {
      setScoreMemRequired(true);
    } else {
      setScoreMemRequired(false);
    }
    if (processName == 'netUtilisation' && value != '') {
      setScoreNetRequired(true);
    } else {
      setScoreNetRequired(false);
    }

    setFormDataBasic({
      ...formDataBasic,
      [`${processName}`]: [
        ...formDataBasic[`${processName}`].slice(0, index),
        { ...formDataBasic[`${processName}`][index], [`${compName}`]: value },
        ...formDataBasic[`${processName}`].slice(index + 1),
      ],
    });
  };
  useEffect(() => {
    console.log('formDataBasic=', formDataBasic);
  }, [formDataBasic]);
  const [cloneCpuForms, setCloneCpuForms] = useState([]);

  const handleDropdownChange = (e: any) => {
    // For a single-select dropdown, selectedValues should be an array with one element
    const selectedValue = e.target.value;
    const name = e.target.name;
    console.log('name', e.target.name);
    console.log('value', e.target.value);
    console.log('before checked', isChecked1);
    setFormDataBasic((prevData: any) => {
      console.log('prevalue', prevData);
      if (name === 'Application' && /\d/.test(selectedValue)) {
        if (selectedValue != '') {
          seterror(true);
        }
        return {
          ...prevData,
          ApplicationName: formDataBasic.ApplicationName,
        };
      }

      if (name === 'applicationsubcategory' && /\d/.test(selectedValue)) {
        return {
          ...prevData,
          Subcategory: formDataBasic.Subcategory,
        };
      }

      if (name === 'Application') {
        return {
          ...prevData,
          ApplicationName: selectedValue,
        };
      } else if (name === 'urlpatters') {
        return {
          ...prevData,
          UrlPatterns: selectedValue,
        };
      } else if (name === 'applicationbinaries') {
        return {
          ...prevData,
          ApplicationBinaries: selectedValue,
        };
      } else if (name === 'applicationsubcategory') {
        return {
          ...prevData,
          Subcategory: selectedValue,
        };
      } else {
        return {
          ...prevData,
          Category: '',
          Subcategory: '',
          ApplicationName: '',

          ApplicationType: '',
          UrlPatterns: [
            {
              // urlpattern: '',
            },
          ],
          ApplicationBinaries: '',
          cpuUtilisation: [
            {
              cpuFrom: '',
              cpuTo: '',
              cpuScore: '',
              cpuRank: '',
            },
          ],
          memUtilisation: [
            {
              memFrom: '',
              memTo: '',
              memScore: '',
              memRank: '',
            },
          ],
          netUtilisation: [
            {
              netFrom: '',
              netTo: '',
              netScore: '',
              netRank: '',
            },
          ],
        };
      }
    });

    console.log('slected values', selectedValue);
  };

  // const handlewebcheckbox=(e:any)=>{
  //   setFormDataBasic((prevData: any)=>{
  //   if(e.target.label == 'web'){
  //      if(e.target.checked )
  //      {
  //       return{...prevData,
  //         ApplicationType: 'Web',
  //       }
  //      }
  //   }
  //   else{
  //     return{...prevData,
  //       ApplicationType: 'Desktop',
  //     }
  //   }

  // })}
  const handleWebCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    setFormDataBasic((prevData: any) => {
      if (isChecked) {
        return {
          ...prevData,
          ApplicationType: 'Web',
          // Web: true,
          // Desktop: false
        };
      } else {
        if (!prevData.Desktop) {
          return {
            ...prevData,
            ApplicationType: '',
            // Web: false
          };
        } else {
          return {
            ...prevData,
            // Web: false
          };
        }
      }
    });
  };

  const handleDesktopCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    setFormDataBasic((prevData: any) => {
      if (isChecked) {
        return {
          ...prevData,
          ApplicationType: 'Desktop',
          // Desktop: true,
          // Web: false
        };
      } else {
        if (!prevData.Web) {
          return {
            ...prevData,
            ApplicationType: '',
            // Desktop: false
          };
        } else {
          return {
            ...prevData,
            // Desktop: false
          };
        }
      }
    });
  };

  {
    /* <Stack horizontal tokens={{ childrenGap: 105, padding: 10 }}> 
    <StackItem>
        <Body1>Application Type</Body1>
    </StackItem>
    <StackItem shrink grow>
        <CheckBox label="Web" checked={formDataBasic.Web} onChange={(e) => handleWebCheckbox(e)} onClick={() => { setWebEnable(true);setDesktopEnable(false);}} />
        <CheckBox label="Desktop" checked={formDataBasic.Desktop} onChange={(e) => handleDesktopCheckbox(e)} onClick={() => {setDesktopEnable(true);setWebEnable(false)}} />
    </StackItem>
</Stack> */
  }

  const [isChecked1, setIsChecked1]: any = useState(false);

  const handleChange = (name: any, val: any) => {
    // Special handling for multi-select dropdowns (ApplicationType, UrlPatterns, ApplicationBinaries)
    if (name == 'applicationtype') {
      isChecked1 ? setIsChecked1(!isChecked1) : setIsChecked1(!isChecked1);
      console.log('here CHECK inside', isChecked1);
      // Handle multi-select dropdown logic
      // Assuming your dropdown values are arrays (e.g., ApplicationType: [], UrlPatterns: [])
      //const selectedOptions = Array.from(e.target.selectedOptions, (option:any) => option.value);

      // setFormDataBasic((prevData) => ({
      //   ...prevData,
      //   [name]: selectedOptions,
      // }));
    } else {
      // Handle regular dropdowns
      setFormDataBasic((prevData: any) => ({
        ...prevData,
        [`${name}`]: val,
      }));
    }
  };
  // const [deleteMonitoredApp, setDeleteMonitoredApp]: any = useState(false);
  const [deleteAppMonitored, setDeleteAppMonitored]: any = useState(false);
  const inputId = useId('input');
  // const [formData, setFormData]: any = useState({
  //   name: '',
  //   description: ''
  // });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreen ? handle.exit() : handle.enter();
  };
  const inputChange = (e: any) => {
    const enteredValue = e.target.value;
    console.log('entered value', enteredValue);
    let name = e.target.name;
    if (name === 'filter') {
      setDropDownValue2(enteredValue);
    }
  };
  const handleFilterArray = (): any => {
    const value1 = dropDownValue1;
    const value2 = dropDownValue2;
    if (value1 !== undefined && value2 !== undefined && value2 !== '') {
      const result = value1 + ' : ' + value2;
      setFilterArray([...filterArray, result]);
      setIsFilter(true);
    }
  };

  const handleCloseFilter = (index: any): any => {
    console.log('clicked');
    const updatedArray = filterArray.filter((x, itemIndex) => itemIndex !== index);
    setFilterArray(updatedArray);
  };

  useEffect(() => {
    if (filterArray.length > 0) setResetDisabled(false);
  }, [filterArray]);

  interface FormCpu {
    id: number;
    data: {};
  }

  const handleAddClone = (index: any, processName: String) => {
    if (processName == 'cpuUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
      });
    } else if (processName == 'memUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { memFrom: '', memTo: '', memScore: '', memRank: '' }],
      });
    } else if (processName == 'netUtilisation' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({
        ...formDataBasic,
        [`${processName}`]: [...formDataBasic[`${processName}`], { netFrom: '', netTo: '', netScore: '', netRank: '' }],
      });
    } else if (processName == 'UrlPatterns' && formDataBasic[`${processName}`].length <= 3) {
      setFormDataBasic({ ...formDataBasic, [`${processName}`]: [...formDataBasic[`${processName}`], {}] });
    }
  };
  const handleRemoveClone = (id: any, processName: String) => {
    var tempArr = formDataBasic[`${processName}`].filter((val: any, i: any) => i !== id);

    setFormDataBasic({ ...formDataBasic, [`${processName}`]: tempArr });
  };

  const DismissButton: React.FC<ButtonProps> = props => {
    return <Button onClick={() => setSearchVal('')} {...props} appearance="transparent" icon={<Dismiss16Regular />} size="small" />;
  };
  interface FormMem {
    id: number;
    data: {};
  }
  const [cloneMemForms, setcloneMemForms] = useState<FormMem[]>([]);
  const handleMemAddClone = () => {
    if (cloneMemForms.length < 3) {
      setcloneMemForms([...cloneMemForms, { id: Date.now(), data: {} }]);
      console.log('cloneeeeeeeee', cloneMemForms);
    }
  };
  const handleRemoveMemClone = (id: any) => {
    setcloneMemForms(cloneMemForms.filter(formMem => formMem.id !== id));
  };

  interface FormNet {
    id: number;
    data: {};
  }
  const [cloneNetForms, setcloneNetForms] = useState<FormNet[]>([]);
  const handleNetAddClone = () => {
    if (cloneNetForms.length < 3) {
      setcloneNetForms([...cloneNetForms, { id: Date.now(), data: {} }]);
    }
  };
  const handleRemoveNetClone = (id: any) => {
    setcloneNetForms(cloneNetForms.filter(formNet => formNet.id !== id));
  };
  var menuItems: any = [];
  let options: any = ['Business Application', 'Productivity Application', 'Collaboration Tools', 'Developer Tools'];
  // const array = options;
  // let options1: any = ['Business Application','Productivity Application','Collaboration Tools','Developer Tools'];
  // const array1 = options;

  var dropdownData = [
    {
      key: 'businessapplication',
      label: (
        <Body1
          onClick={() => {
            handleChange('Category', 'Business Application');
          }}
        >
          Business Application
        </Body1>
      ),
    },
    {
      key: 'productapplication',
      label: <Body1 onClick={() => handleChange('Category', 'Productivity Application')}> Productivity Application </Body1>,
    },
    { key: 'collaborationtool', label: <Body1 onClick={() => handleChange('Category', 'Collaboration Tools')}> Collaboration Tools </Body1> },
    { key: 'developer', label: <Body1 onClick={() => handleChange('Category', 'Developer')}> Developer </Body1> },
  ];

  // var dropdownData1 = [
  //   { key: "businessapplications", label: <Body1 onClick={() => {handleChange('Subcategory','Business Application');} }> Business Application </Body1> },
  //   { key: "productapplications", label: <Body1 onClick={() => handleChange('Subcategory','Productivity Application')}> Productivity Application </Body1> },
  //   { key: "collaborationtools", label: <Body1 onClick={() => handleChange('Subcategory','Collaboration Tools')}>  Collaboration Tools </Body1> },
  //   { key: "developers", label: <Body1 onClick={() => handleChange('Subcategory','Developer')}> Developer </Body1> },
  // ];

  // const dropdownOptions: IDropdownOption[] = [
  //   { key: 'businessapplication', text: 'Business Application' },
  //   { key: 'productapplication', text: 'Productivity Application' },
  //   {key:'collaborationtool', text:'Collaboration Tools'},
  //   {key:'developer',text:'Developer'}
  // ];

  {
    !['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <FluentProvider theme={lightTheme}>
          <MenuItem
            onClick={() => navigate('/manage/apps/appmonitor')}
            icon={
              <Stack horizontal tokens={{ childrenGap: 40 }}>
                <StackItem>
                  <Text></Text>
                </StackItem>
                <StackItem>
                  <PulseSquare20Regular {...primaryIcon} />
                </StackItem>
              </Stack>
            }
          >
            <Stack tokens={{ childrenGap: 30 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Caption1> App&nbsp;Monitored</Caption1>
              </StackItem>
              <StackItem>
                <Stack tokens={{ childrenGap: 48 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                </Stack>
              </StackItem>
              {/* SPACING IN INLINE SIDEBAR */}
            </Stack>
          </MenuItem>
        </FluentProvider>,
      );
  }

  {
    ['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/appinstallation')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <AppsAddIn20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> App Installation </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }

  {
    !['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/appinstallstatus')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <BoxCheckmark20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> App Install Status </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }

  {
    ['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/appuninstallprofile')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <UninstallApp20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> App Uninstall Profiles </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }

  {
    !['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/appuninstallstatus')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <CheckboxCheckedSync20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> App Uninstall Status </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }
  {
    !['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/discoveredapps')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <SquareHintApps20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> Discovered Apps </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }

  {
    ['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/appprotectionpolicies')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <ShieldLock20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> App Protection Policies </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }
  {
    !['/automate/apps/appinstallation', '/automate/apps/appuninstallprofile', '/automate/apps/appprotectionpolicies'].includes(location.pathname) &&
      menuItems.push(
        <MenuItem
          onClick={() => navigate('/manage/apps/activitylogg')}
          icon={
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <BookTemplate20Regular {...primaryIcon} />
              </StackItem>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <StackItem>
              <Text></Text>
            </StackItem>
            <StackItem>
              <Caption1> Activity Log </Caption1>
            </StackItem>
          </Stack>
        </MenuItem>,
      );
  }
  //inline side menu content code ends here

  //data table column name code starts here
  var columns = [
    { key: 'category', label: <Body1Strong>&nbsp; Category </Body1Strong> },
    { key: 'subcategory', label: <Body1Strong> Subcategory </Body1Strong> },
    { key: 'name', label: <Body1Strong> Name </Body1Strong> },
    { key: 'url_pattern', label: <Body1Strong> Url Patterns </Body1Strong> },
    { key: 'desktop_pattern', label: <Body1Strong> Desktop Binaries</Body1Strong> },
  ];
  //data table column name code ends here
  //data table for filter starts here
  var columns1 = [
    { key: 'category', label: <Caption1> Category </Caption1> },
    { key: 'subcategory', label: <Caption1> Subcategory </Caption1> },
    { key: 'name', label: <Caption1> Name </Caption1> },
    { key: 'url_pattern', label: <Caption1> URL </Caption1> },
    { key: 'desktop_pattern', label: <Caption1> Binaries </Caption1> },
    { key: '', label: <Caption1> Scrore </Caption1> },
  ];

  // overflow set contents code starts here
  var overFlowComponent = {
    data: (
      <>
        <StackItem>
          {permissions.includes('appMonitored_Fullaccess') ? (
            <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
              <Stack
                tokens={{ childrenGap: 5 }}
                horizontal
                onClick={() => {
                  setOverlayOpen(true);
                  setOverlayName('addMonitoredApp');
                  setFormDataBasic({
                    Category: '',
                    Subcategory: '',
                    ApplicationName: '',
                    ApplicationType: '',
                    UrlPatterns: [{}],
                    ApplicationBinaries: '',
                    cpuUtilisation: [{ cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
                    memUtilisation: [{ memFrom: '', memTo: '', memScore: '', memRank: '' }],
                    netUtilisation: [{ netFrom: '', netTo: '', netScore: '', netRank: '' }],
                  });
                }}
              >
                <StackItem>
                  <AddSquare20Regular {...primaryIcon} />
                </StackItem>
                <StackItem>
                  <Caption1> Add Apps </Caption1>
                </StackItem>
              </Stack>
            </Button>
          ) : (
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <AddSquare20Regular {...secondaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Add Apps </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          )}

          {/* {
            permissions.includes("appMonitored_Read") || permissions.includes("appMonitored_ReadandExport") || permissions.includes("appMonitored_Fullaccess") ? (
              <Button appearance="subtle" size="small" onClick={() => permissions.includes("appMonitored_Fullaccess") && setCurrentPageNo(1)}>
                {permissions.includes("appMonitored_Read") || permissions.includes("appMonitored_ReadandExport") ? (
                  <TooltipHost content="This feature is not available for you!">
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem><AddSquare20Regular {...primaryIcon} /></StackItem>
                      <StackItem><Caption1>Add Apps</Caption1></StackItem>
                    </Stack>
                  </TooltipHost>
                ) : (
                  <Stack tokens={{ childrenGap: 5 }} horizontal onClick={() => {
                    if (permissions.includes("appMonitored_Fullaccess")) {
                      setOverlayOpen(true);
                      setOverlayName('addMonitoredApp');
                      setFormDataBasic({
                        Category: '',
                        Subcategory: '',
                        ApplicationName: '',
                        ApplicationType: '',
                        UrlPatterns: [{}],
                        ApplicationBinaries: '',
                        cpuUtilisation: [{ cpuFrom: '', cpuTo: '', cpuScore: '', cpuRank: '' }],
                        memUtilisation: [{ memFrom: '', memTo: '', memScore: '', memRank: '' }],
                        netUtilisation: [{ netFrom: '', netTo: '', netScore: '', netRank: '' }],
                      });
                    }
                  }}>
                    <StackItem><AddSquare20Regular {...primaryIcon} /></StackItem>
                    <StackItem><Caption1>Add Apps</Caption1></StackItem>
                  </Stack>
                )}
              </Button>
            ) : null
          } */}
        </StackItem>
        <StackItem>
          <Divider vertical />
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}>
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <SaveCopy20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Duplicate </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}

        {/* <StackItem>
        <Divider vertical/>
        </StackItem> */}

        <StackItem>
          {/* <Button appearance="subtle" size="small" onClick={() => setCurrentPageNo(1)}> */}
          {/* <Stack tokens={{childrenGap:5}} horizontal> */}
          <StackItem>
            <DialogBox
              headerButton={
                <>
                  <Button appearance="transparent" size="small" disabled={isdeletedisabled ? true : false}>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        {/* <SlideEraser20Regular {...primaryIcon} /> */}
                        {isdeletedisabled ? <SlideEraser20Regular {...secondaryIcon} /> : <SlideEraser20Regular {...primaryIcon} />}
                      </StackItem>
                      <StackItem>
                        <Caption1> Delete </Caption1>
                      </StackItem>
                    </Stack>
                  </Button>
                </>
              }
              content="Kindly verify your intent to remove the app from app monitor"
              footer={
                <>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          setDeleteAppMonitored(true);
                          userActivity('Delete', `{"summary":"${contextValue.selectedRowIdInTable.length} app deleted from app monitor"}`);
                        }}
                        size="small"
                      >
                        Confirm
                      </Button>
                    </StackItem>
                    <StackItem>
                      <Button size="small">Cancel</Button>
                    </StackItem>
                  </Stack>
                </>
              }
            />
          </StackItem>
          {/* </Stack> */}
          {/* </Button> */}
        </StackItem>

        <StackItem>
          <Divider vertical />
        </StackItem>

        <StackItem>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              setCurrentPageNo(1);
              setIsRefresh(true);
            }}
          >
            <Stack tokens={{ childrenGap: 5 }} horizontal>
              <StackItem>
                <ArrowClockwise20Regular {...primaryIcon} />
              </StackItem>
              <StackItem>
                <Caption1> Refresh </Caption1>
              </StackItem>
            </Stack>
          </Button>
        </StackItem>

        <StackItem>
          {/* <CSVLink id="csv-export-link" data={exportData} filename="appmonitored_list.csv">
            <Button appearance="subtle" size="small" onClick={() => { userActivity('Export', `App Monitor List Exported`) }}>
              <Stack tokens={{ childrenGap: 5 }} horizontal>
                <StackItem> <ArrowDownload20Regular {...primaryIcon} /> </StackItem>
                <StackItem> <Caption1> Export </Caption1> </StackItem>
              </Stack>
            </Button>
          </CSVLink> */}
          {permissions.includes('appMonitored_Read') ? (
            <TooltipHost content="This feature is not available for you!">
              <Button appearance="subtle" size="small">
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...secondaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </TooltipHost>
          ) : permissions.includes('appMonitored_Fullaccess') || permissions.includes('appMonitored_ReadandExport') ? (
            <CSVLink id="csv-export-link" data={exportData} filename="appmonitored_list.csv">
              <Button
                appearance="subtle"
                size="small"
                onClick={() => {
                  userActivity('Export', `{"summary" : "App Monitored List Exported"}`);
                }}
              >
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <ArrowDownload20Regular {...primaryIcon} />
                  </StackItem>
                  <StackItem>
                    <Caption1> Export </Caption1>
                  </StackItem>
                </Stack>
              </Button>
            </CSVLink>
          ) : (
            <Tooltip withArrow content={'You do not have access'} relationship="description" positioning="above">
              <Button appearance="subtle" size="small" disabled>
                <Stack tokens={{ childrenGap: 5 }} horizontal>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                      <StackItem>
                        <ArrowDownload20Regular {...secondaryIcon} />
                      </StackItem>
                      <StackItem>
                        <Caption1> Export </Caption1>
                      </StackItem>
                    </Stack>
                  </StackItem>
                </Stack>
              </Button>
            </Tooltip>
          )}
        </StackItem>

        {/* <StackItem>
          <Button appearance="subtle" size="small">
            <Stack tokens={{childrenGap:5}} horizontal>
              <StackItem> <Settings20Regular {...primaryIcon}/> </StackItem>
              <StackItem> <Caption1> Manage View </Caption1> </StackItem>
            </Stack>
          </Button>
        </StackItem> */}
      </>
    ),
  };
  // overflow set contents code ends here

  useEffect(() => {
    console.log('CategoryOptions=', dropDownValue1);
    console.log('Object.keys(categoryData)=');
    if (dropDownValue1?.length > 0) {
      setFormDataBasic({ ...formDataBasic, Category: dropDownValue1[0] });
    } else {
      console.log('cat else');
    }
  }, [dropDownValue1]);

  var tempTableData: any = [];
  var menuLists: any = [];
  var tempExportData: any = [];

  useEffect(() => {
    var tempExportData: any = [];

    exportDetails?.length > 0 &&
      exportDetails.map((val: any) => {
        tempExportData.push({
          category: val.Category,
          subcategory: val.subcategory,
          name: val.MetricDesc,
          url_pattern: val.UrlPatters,
          desktop_pattern: val.SpecificInfo,
        });
      });

    setExportData(tempExportData);
  }, [exportDetails]);

  useEffect(() => {
    // data table values append code starts here
    console.log('final_data=', final_data);
    //roles and permissions
    async function loadRoute() {
      console.log('role:-', sessionStorage.getItem('role'));
      const role = sessionStorage.getItem('role');
      try {
        const [routeAllowed, permissions] = await shouldLoadRoute(role);
        if (routeAllowed) {
          setPermissions(permissions);
        } else {
          console.log('Route is not allowed.');
        }
      } catch (error) {
        console.error('Error loading route:', error);
      }
    }
    loadRoute();
    final_data !== undefined && final_data !== null
      ? final_data.analytics_test_Scores.map((val: any, i: any) => {
          // tempTableData.push({
          //   id: val.id,
          //   category: { label: <Button appearance="transparent" size="small" onClick={(e: any) => { setRowID(val.id); setOverlayOpen(true); setOverlayName('editMonitoredApp'); setProcessname(val.MetricName); }}> {val.Category} </Button >, id: `${i}` },
          //   subcategory: { label: <Caption1> {val.subcategory} </Caption1>, status: "" },
          //   name: { label: <Caption1> {val.MetricDesc} </Caption1> },
          //   url_pattern: { label: <Caption1> {val.UrlPatters != null ? val.UrlPatters : "-"} </Caption1> },
          //   desktop_pattern: { label: <Caption1> {val.SpecificInfo} </Caption1> },
          // });

          if (permissions.includes('appMonitored_Read') || permissions.includes('appMonitored_ReadandExport')) {
            tempTableData.push({
              id: val.id,
              checkbox: 'hide',
              category: {
                label: (
                  <TooltipHost content="You do not have access">
                    <Button appearance="transparent" size="small">
                      {val.Category}
                    </Button>
                  </TooltipHost>
                ),
                id: `${i}`,
              },
              subcategory: { label: <Caption1> {val.subcategory} </Caption1>, status: '' },
              name: { label: <Caption1> {val.MetricDesc} </Caption1> },
              url_pattern: { label: <Caption1> {val.UrlPatters != null ? val.UrlPatters : '-'} </Caption1> },
              desktop_pattern: { label: <Caption1> {val.SpecificInfo} </Caption1> },
            });
          } else if (permissions.includes('appMonitored_Fullaccess')) {
            tempTableData.push({
              id: val.id,
              category: {
                label: (
                  <Button
                    appearance="transparent"
                    size="small"
                    onClick={(e: any) => {
                      setRowID(val.id);
                      setOverlayOpen(true);
                      setOverlayName('editMonitoredApp');
                      setProcessname(val.MetricName);
                    }}
                  >
                    {val.Category}
                  </Button>
                ),
                id: `${i}`,
              },
              subcategory: { label: <Caption1> {val.subcategory} </Caption1>, status: '' },
              name: { label: <Caption1> {val.MetricDesc} </Caption1> },
              url_pattern: { label: <Caption1> {val.UrlPatters != null ? val.UrlPatters : '-'} </Caption1> },
              desktop_pattern: { label: <Caption1> {val.SpecificInfo} </Caption1> },
            });
          }
        })
      : (tempTableData = []);

    tempTableData.length > 0 ? setTableData(tempTableData) : (tempTableData = []);
  }, [final_data]);
  // data table values append code ends here
  useEffect(() => {
    overlayName == 'addMonitoredApp' &&
      setMonitoredOverLayData({
        headerTitle: <Title3>Add New Applications to be monitored</Title3>,
        size: 'large',
        isTabs: true,
        tabItems: {
          Basic: (
            <form id="myFormAppmonitored" onSubmit={handleSubmit}>
              <>
                <Stack>
                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These details will make it easy to find the snippets later when you need to use them. </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 45, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Category <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    <MultiselectWithTags
                      style={inputsizestyle}
                      onChange={(e: any) => setCategorySearchVal(e.target.value)}
                      options={categorySearchData}
                      setSelectedOptions={setDropDownValue1}
                      selectedOptions={dropDownValue1}
                      multi={false}
                      tag={false}
                      placeholder="Please Search"
                      required
                    />
                    {/* <DropdownComponent optionData={dropdownData} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} /> */}
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 25, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Subcategory <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    <Input
                      style={inputsizestyle}
                      onChange={handleDropdownChange}
                      name="applicationsubcategory"
                      value={formDataBasic.Subcategory}
                      required
                    />
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 64, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Name <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    <Input
                      style={inputsizestyle}
                      onChange={handleDropdownChange}
                      name="Application"
                      value={formDataBasic.ApplicationName}
                      required
                      type="text"
                    />
                  </StackItem>
                </Stack>
                {/* <Stack horizontal tokens={{childrenGap:105, padding:10}}> 
        <StackItem >
        <Body1>Description</Body1>
        </StackItem>
        <StackItem shrink grow> 
        <Textarea onChange={handleDropdownChange} name="description" value={formDataBasic.Description} />
        </StackItem>
      </Stack> */}
                {/* <Stack horizontal tokens={{childrenGap:105, padding:10}}> 
        <StackItem >
        <Body1>Application Type</Body1>
        </StackItem>
        <StackItem shrink grow> */}
                {/* <CheckBox label="Web" onChange={(e)=>handlewebcheckbox(e)}/>
        <CheckBox label="Desktop" onChange={handlewebcheckbox}/> */}
                {/* <CheckBox label="Web" checked={formDataBasic.Web} onChange={(e) => handleWebCheckbox(e)} onClick={() => { setWebEnable(true);setDesktopEnable(false);}} />
        <CheckBox label="Desktop" checked={formDataBasic.Desktop} onChange={(e) => handleDesktopCheckbox(e)} onClick={() => {setDesktopEnable(true);setWebEnable(false)}}/>
        </StackItem>
      </Stack> */}

                {/* {isWebEnable && ( */}
                <Stack horizontal tokens={{ childrenGap: 110, padding: 10 }}>
                  <StackItem>{/* <Body1>Url Patters</Body1> */}</StackItem>
                  {/* <StackItem shrink grow> */}
                  {/* <MultiselectWithTags options={array} multi={true} tag={true} onChange={(e) => handleDropdownChange(e.target.value)} name="urlpatters" selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} /> */}
                  {/* <Input onChange={handleDropdownChange} name="urlpatters" value={formDataBasic.UrlPatterns}/> */}
                  {/* </StackItem> */}
                  <StackItem>
                    {formDataBasic.UrlPatterns?.map((form: any, i: any) => (
                      <Stack key={i} tokens={{ padding: 1 }}>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <StackItem align="center">
                            <Stack horizontal tokens={{ childrenGap: 11.5 }}>
                              <StackItem>
                                <Caption1>
                                  Url Patterns
                                  <Image src="/visualization/v2/image/required.svg" />
                                </Caption1>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Label size="small" htmlFor={inputId} />
                                <Input
                                  style={inputsizestyle}
                                  id={inputId}
                                  value={form.urlpatterns}
                                  onChange={(e: any) => handleInput(e.target.value, 'UrlPatterns', 'urlpatterns', i)}
                                  required
                                />
                              </StackItem>
                              {i == 0 ? (
                                <StackItem>
                                  <AddSquare24Regular
                                    {...primaryIcon}
                                    onClick={() => {
                                      handleAddClone(i + 1, 'UrlPatterns');
                                    }}
                                  />
                                </StackItem>
                              ) : (
                                <StackItem>
                                  <Delete24Regular
                                    {...deleteIcon}
                                    onClick={() => {
                                      handleRemoveClone(i, 'UrlPatterns');
                                    }}
                                  />
                                </StackItem>
                              )}
                            </Stack>
                          </StackItem>
                        </Stack>
                      </Stack>
                    ))}
                  </StackItem>
                </Stack>
                {/* )} */}
                {/* {isDesktopEnable && ( */}
                <Stack horizontal tokens={{ childrenGap: 55, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Binaries
                      <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    {/* <MultiselectWithTags options={array} multi={false} tag={false} /> */}
                    <Input
                      style={inputsizestyle}
                      onChange={handleDropdownChange}
                      name="applicationbinaries"
                      value={formDataBasic.ApplicationBinaries}
                      required
                    />
                  </StackItem>
                </Stack>
                {/* )} */}
              </>
              <Button ref={buttonref} style={{ display: 'none' }} type="submit">
                Validate
              </Button>
            </form>
          ),
          Scores: (
            <form id="myFormAppmonitored" onSubmit={handleSubmit}>
              <>
                <Stack>
                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> Here you can assign scores </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 40 }}>
                  <StackItem>
                    {/* <Stack tokens={{childrenGap:10}}> */}
                    <StackItem>
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Body1Strong>Data Classification</Body1Strong>
                        </StackItem>
                      </Stack>
                      <Divider />
                      <StackItem>
                        {/* <MenuItem> */}
                        <Stack tokens={{ childrenGap: 5 }} horizontal>
                          <StackItem onClick={() => setDeviceName('All Device')}>
                            <Button appearance="transparent" size="small">
                              <Body1>All&nbsp;Devices&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Body1>
                            </Button>
                          </StackItem>
                          {/* <StackItem> <Stack tokens={{childrenGap:1}} horizontal> <StackItem><Text></Text></StackItem> <StackItem><Text></Text></StackItem> </Stack> </StackItem> */}
                        </Stack>
                        {/* </MenuItem> */}
                        <Stack tokens={{ childrenGap: 3 }} verticalFill>
                          {deviceList !== undefined && deviceList !== null
                            ? deviceList &&
                              deviceList.core_DeviceClassification.map((val: any, i: any) => {
                                const deviceData = val && val.classificationName;
                                return (
                                  <StackItem onClick={() => setDeviceName(deviceData)}>
                                    <ToggleButton appearance="subtle" size="small">
                                      <Body1>{deviceData}</Body1>
                                    </ToggleButton>
                                  </StackItem>
                                );
                              })
                            : console.log('null')}
                        </Stack>
                      </StackItem>
                    </StackItem>
                    <TroubleshooterDevice_fun
                      groupData={deviceList}
                      groupFunc={setDeviceList}
                      data={final_data1}
                      func={set_final_data1}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 10}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      groupName={deviceName}
                      setGroupName={setDeviceName}
                      setallDevice={setallDevice}
                    />
                    {/* </Stack> */}
                  </StackItem>
                  {/*this is the second Stack of the overlay page it contain datatable  */}
                  <StackItem>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <FluentProvider theme={webLightTheme}>
                        <Stack horizontal tokens={{ childrenGap: 15, padding: 5 }}>
                          <StackItem>
                            <Body1Strong>
                              Application Scores <Image src="/visualization/v2/image/required.svg" />
                            </Body1Strong>
                          </StackItem>
                          <StackItem
                            onClick={() =>
                              setFormDataBasic({
                                Category: '',
                                Subcategory: '',
                                ApplicationName: '',

                                ApplicationType: '',
                                UrlPatterns: [
                                  {
                                    // urlpattern: '',
                                  },
                                ],
                                ApplicationBinaries: '',
                                cpuUtilisation: [
                                  {
                                    cpuFrom: '',
                                    cpuTo: '',
                                    cpuScore: '',
                                    cpuRank: '',
                                  },
                                ],
                                memUtilisation: [
                                  {
                                    memFrom: '',
                                    memTo: '',
                                    memScore: '',
                                    memRank: '',
                                  },
                                ],
                                netUtilisation: [
                                  {
                                    netFrom: '',
                                    netTo: '',
                                    netScore: '',
                                    netRank: '',
                                  },
                                ],
                              })
                            }
                          >
                            <Button appearance="primary" size="small">
                              <Caption1>Reset</Caption1>
                            </Button>
                          </StackItem>
                        </Stack>
                        <Stack tokens={{ childrenGap: 25 }}>
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Body1>&nbsp;Process Cpu Utilisation</Body1>
                            </StackItem>
                            <StackItem>
                              {formDataBasic.cpuUtilisation?.map((formCpu: any, i: any) => (
                                <Stack key={i} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    {/* From and To fields in one row */}
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="From"
                                            type="number"
                                            value={formCpu.cpuFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuFrom', i)}
                                            required={scoresCpuRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="To"
                                            type="number"
                                            id={inputId}
                                            value={formCpu.cpuTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuTo', i)}
                                            required={scoresCpuRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>

                                  {/* Score and Rank fields in another row */}
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Score"
                                            type="number"
                                            id={inputId}
                                            value={formCpu.cpuScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuScore', i)}
                                            required={scoresCpuRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Rank"
                                            type="number"
                                            id={inputId}
                                            value={formCpu.cpuRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuRank', i)}
                                            required={scoresCpuRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    {/* Add or Remove icons */}
                                    {i === 0 ? (
                                      <StackItem>
                                        <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'cpuUtilisation')} />
                                      </StackItem>
                                    ) : (
                                      <StackItem>
                                        <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'cpuUtilisation')} />
                                      </StackItem>
                                    )}
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>

                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Body1>&nbsp;Process Mem Utilisation</Body1>
                            </StackItem>
                            <StackItem>
                              {formDataBasic.memUtilisation.map((formMem: any, i: any) => (
                                <Stack key={i + 5} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    {/* From and To fields in one row */}
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="From"
                                            type="number"
                                            id={inputId}
                                            value={formMem.memFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memFrom', i)}
                                            required={scoresMemRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="To"
                                            type="number"
                                            id={inputId}
                                            value={formMem.memTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memTo', i)}
                                            required={scoresMemRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>

                                  {/* Score and Rank fields in another row */}
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Score"
                                            type="number"
                                            id={inputId}
                                            value={formMem.memScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memScore', i)}
                                            required={scoresMemRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Rank"
                                            type="number"
                                            id={inputId}
                                            value={formMem.memRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memRank', i)}
                                            required={scoresMemRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    {/* Add or Remove icons */}
                                    {i === 0 ? (
                                      <StackItem>
                                        <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'memUtilisation')} />
                                      </StackItem>
                                    ) : (
                                      <StackItem>
                                        <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'memUtilisation')} />
                                      </StackItem>
                                    )}
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>

                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Body1>&nbsp;Process Network Utilisation</Body1>
                            </StackItem>
                            <StackItem>
                              {formDataBasic.netUtilisation.map((formNet: any, i: number) => (
                                <Stack key={formNet.id} tokens={{ padding: i > 0 ? '10px 0' : '1px' }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    {/* From and To fields in one row */}
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="From"
                                            type="number"
                                            id={inputId}
                                            value={formNet.netFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netFrom', i)}
                                            required={scoresNetRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="To"
                                            type="number"
                                            id={inputId}
                                            value={formNet.netTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netTo', i)}
                                            required={scoresNetRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>

                                  {/* Score and Rank fields in another row */}
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Score"
                                            type="number"
                                            id={inputId}
                                            value={formNet.netScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netScore', i)}
                                            required={scoresNetRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem>
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            placeholder="Rank"
                                            type="number"
                                            id={inputId}
                                            value={formNet.netRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netRank', i)}
                                            required={scoresNetRequired}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    {/* Add or Remove icons */}
                                    {i === 0 ? (
                                      <StackItem>
                                        <AddSquare24Regular {...primaryIcon} onClick={() => handleAddClone(i + 1, 'netUtilisation')} />
                                      </StackItem>
                                    ) : (
                                      <StackItem>
                                        <Delete24Regular {...deleteIcon} onClick={() => handleRemoveClone(i, 'netUtilisation')} />
                                      </StackItem>
                                    )}
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>
                        </Stack>
                      </FluentProvider>
                    </Stack>
                  </StackItem>
                </Stack>
              </>
              <Button style={{ display: 'none' }} ref={buttonref} type="submit">
                Validate
              </Button>
            </form>
          ),
          'Review Data': (
            <Stack>
              <Stack>
                <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem>
                          <Info20Filled {...primaryIcon} />
                        </StackItem>
                        <StackItem>
                          <Caption1> Qorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>
                        </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 45, padding: 10 }}>
                <StackItem>
                  <Body1>Application Category</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} value={formDataBasic.Category} disabled />
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 25, padding: 10 }}>
                <StackItem>
                  <Body1>Application Subcategory</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} value={formDataBasic.Subcategory} disabled />
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 64, padding: 10 }}>
                <StackItem>
                  <Body1>Application Name</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} onChange={handleDropdownChange} name="Application" value={formDataBasic.ApplicationName} disabled />
                </StackItem>
              </Stack>
              {/* <Stack horizontal tokens={{childrenGap:105, padding:10}}> 
        <StackItem >
        <Body1>Application Type</Body1>
        </StackItem>
        <StackItem shrink grow> */}
              {/* <MultiselectWithTags options={array} multi={false} tag={false} /> */}
              {/* <Caption1>Web</Caption1>
        <CheckBox value={'web'} checked={formDataBasic.ApplicationType != 'Desktop' ? true : false} disabled onClick={() => { setWebEnable(true);setDesktopEnable(false);}}/>
        <Caption1>Desktop</Caption1>
        <CheckBox value={'desktop'} checked={formDataBasic.ApplicationType != 'Web' ? true : false} disabled onClick={() => {setDesktopEnable(true);setWebEnable(false)}}/>
        </StackItem>
      </Stack> */}
              {/* {isWebEnable && ( */}
              <Stack horizontal tokens={{ childrenGap: 12, padding: 10 }}>
                <StackItem>
                  <Caption1>Url Patterns</Caption1>
                </StackItem>
                <StackItem>
                  {formDataBasic.UrlPatterns?.map((form: any, i: any) => (
                    <Stack key={i} tokens={{ padding: 1 }}>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem align="center">
                          <Stack horizontal tokens={{ childrenGap: 11 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Label size="small" htmlFor={inputId} />
                              <Input
                                style={inputsizestyle}
                                disabled
                                id={inputId}
                                value={form.urlpatterns}
                                onChange={(e: any) => handleInput(e.target.value, 'UrlPatterns', 'urlpatterns', i)}
                              />
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>
                  ))}
                </StackItem>
              </Stack>
              {/* )} */}
              {/* {isDesktopEnable && ( */}
              <Stack horizontal tokens={{ childrenGap: 52, padding: 10 }}>
                <StackItem>
                  <Body1>Application Binaries</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} disabled name="applicationbinaries" value={formDataBasic.ApplicationBinaries} />
                  {/* <MultiselectWithTags options={array} multi={false} tag={false} /> */}
                </StackItem>
              </Stack>
              {/* )} */}

              <Stack>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <FluentProvider theme={webLightTheme}>
                      <Stack horizontal tokens={{ childrenGap: 25 }}>
                        {/* <StackItem> <Body1Strong> Application Scores </Body1Strong> </StackItem>
        <StackItem><Caption1>Reset</Caption1></StackItem> */}
                      </Stack>
                      <Stack tokens={{ childrenGap: 25 }}>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <Stack></Stack>
                          <StackItem>
                            <Body1>Process Cpu Utilisation</Body1>
                          </StackItem>
                          <StackItem>
                            {formDataBasic.cpuUtilisation?.map((formCpu: any, i: any) => (
                              <Stack key={i} tokens={{ padding: 1 }}>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* From */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="From"
                                          value={formCpu.cpuFrom}
                                          onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuFrom', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>

                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* To */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="To"
                                          id={inputId}
                                          value={formCpu.cpuTo}
                                          onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuTo', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Score */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Score"
                                          id={inputId}
                                          value={formCpu.cpuScore}
                                          onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuScore', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Rank */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Rank"
                                          id={inputId}
                                          value={formCpu.cpuRank}
                                          onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuRank', i)}
                                        />
                                      </StackItem>
                                      {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'cpuUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'cpuUtilisation')}} /></StackItem>} */}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Stack>
                            ))}
                          </StackItem>
                        </Stack>

                        <Stack tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <Body1>Process Mem Utilisation</Body1>
                          </StackItem>

                          <StackItem>
                            {formDataBasic.memUtilisation.map((formMem: any, i: any) => (
                              <Stack key={i + 5} tokens={{ padding: 1 }}>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* From */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="From"
                                          id={inputId}
                                          value={formMem.memFrom}
                                          onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memFrom', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>

                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* To */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="To"
                                          id={inputId}
                                          value={formMem.memTo}
                                          onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memTo', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Score */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Score"
                                          id={inputId}
                                          value={formMem.memScore}
                                          onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memScore', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Rank */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Rank"
                                          id={inputId}
                                          value={formMem.memRank}
                                          onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memRank', i)}
                                        />
                                      </StackItem>
                                      {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'memUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'memUtilisation')}} /></StackItem>} */}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Stack>
                            ))}
                          </StackItem>
                        </Stack>

                        <Stack tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <Body1>Process Network Utilisation</Body1>
                          </StackItem>
                          <StackItem>
                            {formDataBasic.netUtilisation.map((formNet: any, i: number) => (
                              <Stack key={formNet.id} tokens={{ padding: 1 }}>
                                <Stack horizontal tokens={{ childrenGap: 20 }}>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* From */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="From"
                                          id={inputId}
                                          value={formNet.netFrom}
                                          onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netFrom', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>

                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* To */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="To"
                                          id={inputId}
                                          value={formNet.netTo}
                                          onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netTo', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Score */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Score"
                                          id={inputId}
                                          value={formNet.netScore}
                                          onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netScore', i)}
                                        />
                                      </StackItem>
                                    </Stack>
                                  </StackItem>
                                  <StackItem align="center">
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Caption1>{/* Rank */}</Caption1>
                                      </StackItem>
                                      <StackItem>
                                        <Label size="small" htmlFor={inputId} />
                                        <Input
                                          disabled
                                          placeholder="Rank"
                                          id={inputId}
                                          value={formNet.netRank}
                                          onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netRank', i)}
                                        />
                                      </StackItem>
                                      {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'netUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'netUtilisation')}} /></StackItem>} */}
                                    </Stack>
                                  </StackItem>
                                </Stack>
                              </Stack>
                            ))}
                          </StackItem>
                        </Stack>
                      </Stack>
                    </FluentProvider>
                  </Stack>
                </StackItem>
              </Stack>
            </Stack>
          ),
        },
      });
    Object.keys(formDataBasic).length > 0 &&
      overlayName == 'editMonitoredApp' &&
      setMonitoredOverLayData({
        headerTitle: <Title3>Edit Applications</Title3>,
        size: 'large',
        isTabs: true,
        tabItems: {
          Basic: (
            <form id="myFormAppmonitored" onSubmit={handleSubmit}>
              <>
                <Stack>
                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> These details will make it easy to find the snippets later when you need to use them </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 45, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Category <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    {/* <MultiselectWithTags options={array} multi={false} tag={false} onSelect={handleChange} value={formDataBasic.Category}/> */}
                    {/* <Dropdown id="category" options={dropdownOptions} onOpenChange={handleChange}>
      </Dropdown> */}
                    <MultiselectWithTags
                      style={inputsizestyle}
                      onChange={(e: any) => setCategorySearchVal(e.target.value)}
                      options={categorySearchData}
                      setSelectedOptions={setDropDownValue1}
                      selectedOptions={[formDataBasic.Category]}
                      multi={false}
                      tag={false}
                      placeholder="Please Search"
                      required
                    />
                    {/* <DropdownComponent optionData={dropdownData} setDropDownValue = {setDropDownValue1} dropDownvalue = {dropDownValue1} defaultSelected={formDataBasic.Category} /> */}
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 25, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Subcategory <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    {/* <DropdownComponent optionData={dropdownData1} setDropDownValue = {setDropDownValue2} dropDownvalue = {dropDownValue2}/> */}
                    <Input style={inputsizestyle} onChange={handleDropdownChange} name="applicationsubcategory" value={formDataBasic.Subcategory} />
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 64, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Name <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    <Input
                      style={inputsizestyle}
                      onChange={handleDropdownChange}
                      name="Application"
                      type="text"
                      value={formDataBasic.ApplicationName}
                      required
                    />
                  </StackItem>
                </Stack>
                {/* <Stack horizontal tokens={{childrenGap:105, padding:10}}> 
        <StackItem >
        <Body1>Application Type </Body1>
        </StackItem>
        <StackItem shrink grow>
          
        <CheckBox label="Web" checked={formDataBasic.ApplicationType != 'Desktop' ? true : false} onChange={handleDropdownChange}  onClick={() => { setWebEnable(true);setDesktopEnable(false);}}/>
        <CheckBox label="Desktop" checked={formDataBasic.ApplicationType == 'Desktop' ? true : false} onChange={handleDropdownChange} onClick={() => {setDesktopEnable(true);setWebEnable(false)}}/>

        </StackItem>
      </Stack> */}

                {/* {isWebEnable && ( */}
                <Stack horizontal tokens={{ childrenGap: 110, padding: 10 }}>
                  <StackItem>{/* <Body1>Url Patters</Body1> */}</StackItem>
                  {/* <StackItem shrink grow> */}
                  {/* <MultiselectWithTags options={array} multi={true} tag={true} onChange={(e) => handleDropdownChange(e.target.value)} name="urlpatters" selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} /> */}
                  {/* <Input onChange={handleDropdownChange} name="urlpatters" value={formDataBasic.UrlPatterns}/> */}
                  {/* </StackItem> */}
                  <StackItem>
                    {formDataBasic.UrlPatterns?.map((form: any, i: any) => (
                      <Stack key={i} tokens={{ padding: 1 }}>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <StackItem align="center">
                            <Stack horizontal tokens={{ childrenGap: 11 }}>
                              <StackItem>
                                <Caption1>
                                  Url Patterns
                                  <Image src="/visualization/v2/image/required.svg" />
                                </Caption1>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Text></Text>
                              </StackItem>
                              <StackItem>
                                <Label size="small" htmlFor={inputId} />
                                <Input
                                  style={inputsizestyle}
                                  id={inputId}
                                  value={form.urlpatterns}
                                  onChange={(e: any) => handleInput(e.target.value, 'UrlPatterns', 'urlpatterns', i)}
                                />
                              </StackItem>
                              {i == 0 ? (
                                <StackItem>
                                  <AddSquare24Filled
                                    {...primaryIcon}
                                    onClick={() => {
                                      handleAddClone(i + 1, 'UrlPatterns');
                                    }}
                                  />
                                </StackItem>
                              ) : (
                                <StackItem>
                                  <Delete24Regular
                                    {...primaryIcon}
                                    onClick={() => {
                                      handleRemoveClone(i, 'UrlPatterns');
                                    }}
                                  />
                                </StackItem>
                              )}
                            </Stack>
                          </StackItem>
                        </Stack>
                      </Stack>
                    ))}
                  </StackItem>
                </Stack>
                {/* )} */}
                {/* {isDesktopEnable && (  */}
                <Stack horizontal tokens={{ childrenGap: 52, padding: 10 }}>
                  <StackItem>
                    <Body1>
                      Application Binaries <Image src="/visualization/v2/image/required.svg" />
                    </Body1>
                  </StackItem>
                  <StackItem shrink grow>
                    {/* <MultiselectWithTags options={array} multi={false} tag={false} /> */}
                    <Input
                      style={inputsizestyle}
                      onChange={handleDropdownChange}
                      name="applicationbinaries"
                      value={formDataBasic.ApplicationBinaries}
                    />
                  </StackItem>
                </Stack>
                {/* )} */}
              </>
              <Button style={{ display: 'none' }} ref={buttonref} type="submit">
                Validate
              </Button>
            </form>
          ),
          Scores: (
            <form id="myFormAppmonitored" onSubmit={handleSubmit}>
              <>
                <Stack>
                  <StackItem>
                    <FluentProvider theme={messageTheme}>
                      <Card appearance="subtle" size="small">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                          <StackItem>
                            <Info20Filled {...primaryIcon} />
                          </StackItem>
                          <StackItem>
                            <Caption1> fill the scores </Caption1>
                          </StackItem>
                        </Stack>
                      </Card>
                    </FluentProvider>
                  </StackItem>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 40 }}>
                  <StackItem>
                    {/* <Stack tokens={{childrenGap:10}}> */}
                    <StackItem>
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Body1Strong>Data Classification</Body1Strong>
                        </StackItem>
                      </Stack>
                      <Divider />
                      <StackItem>
                        <MenuItem>
                          <Stack tokens={{ childrenGap: 5 }} horizontal>
                            <StackItem onClick={() => setDeviceName('All Device')}>
                              <Body1>All&nbsp;Devices&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Body1>
                            </StackItem>
                          </Stack>
                        </MenuItem>
                        <Stack tokens={{ childrenGap: 10 }} verticalFill>
                          {deviceList !== undefined && deviceList !== null
                            ? deviceList &&
                              deviceList.core_DeviceClassification.map((val: any, i: any) => {
                                const deviceData = val && val.classificationName;
                                return (
                                  <StackItem onClick={() => setDeviceName(deviceData)}>
                                    <MenuItem>
                                      <Body1>{deviceData}</Body1>
                                    </MenuItem>
                                  </StackItem>
                                );
                              })
                            : console.log('null')}
                        </Stack>
                      </StackItem>
                    </StackItem>
                    <TroubleshooterDevice_fun
                      groupData={deviceList}
                      groupFunc={setDeviceList}
                      data={final_data1}
                      func={set_final_data1}
                      searchVal={searchVal}
                      pageNo={(currentPageNo - 1) * 10}
                      setTotalPages={setTotalPages}
                      setTotalRecords={setTotalRecords}
                      groupName={deviceName}
                      setGroupName={setDeviceName}
                      setallDevice={setallDevice}
                    />
                    {/* </Stack> */}
                  </StackItem>
                  {/*this is the second Stack of the overlay page it contain datatable  */}
                  <StackItem>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <FluentProvider theme={webLightTheme}>
                        <Stack horizontal tokens={{ childrenGap: 15, padding: 5 }}>
                          <StackItem>
                            <Body1Strong> Application Scores </Body1Strong>
                          </StackItem>
                          <StackItem
                            onClick={() =>
                              setFormDataBasic({
                                Category: '',
                                Subcategory: '',
                                ApplicationName: '',
                                ApplicationType: '',
                                UrlPatterns: [
                                  {
                                    // urlpattern: '',
                                  },
                                ],
                                ApplicationBinaries: '',
                                cpuUtilisation: [
                                  {
                                    cpuFrom: '',
                                    cpuTo: '',
                                    cpuScore: '',
                                    cpuRank: '',
                                  },
                                ],
                                memUtilisation: [
                                  {
                                    memFrom: '',
                                    memTo: '',
                                    memScore: '',
                                    memRank: '',
                                  },
                                ],
                                netUtilisation: [
                                  {
                                    netFrom: '',
                                    netTo: '',
                                    netScore: '',
                                    netRank: '',
                                  },
                                ],
                              })
                            }
                          >
                            <Button appearance="primary" size="small">
                              <Caption1>Reset</Caption1>
                            </Button>
                          </StackItem>
                        </Stack>
                        <Stack tokens={{ childrenGap: 25 }}>
                          {processName.includes('CPU') && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Body1>Process Cpu Utilisation</Body1>
                              </StackItem>
                              <StackItem>
                                {formDataBasic.cpuUtilisation?.map((formCpu: any, i: any) => (
                                  <Stack key={i} tokens={{ padding: 1 }}>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {/* From and To fields in one row */}
                                      <StackItem>
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* From */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="From"
                                              value={formCpu.cpuFrom}
                                              onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuFrom', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem>
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* To */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="To"
                                              id={inputId}
                                              value={formCpu.cpuTo}
                                              onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuTo', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>

                                    {/* Score and Rank fields in another row */}
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      <StackItem>
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Score */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Score"
                                              id={inputId}
                                              value={formCpu.cpuScore}
                                              onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuScore', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem>
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Rank */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Rank"
                                              id={inputId}
                                              value={formCpu.cpuRank}
                                              onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuRank', i)}
                                            />
                                          </StackItem>
                                          {i === 0 ? (
                                            <StackItem>
                                              <AddSquare24Filled {...primaryIcon} onClick={() => handleAddClone(i + 1, 'cpuUtilisation')} />
                                            </StackItem>
                                          ) : (
                                            <StackItem>
                                              <Delete24Regular {...primaryIcon} onClick={() => handleRemoveClone(i, 'cpuUtilisation')} />
                                            </StackItem>
                                          )}
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                            </Stack>
                          )}

                          {processName.includes('Memory') && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Body1>Process Mem Utilisation</Body1>
                              </StackItem>

                              <StackItem>
                                {formDataBasic.memUtilisation.map((formMem: any, i: any) => (
                                  <Stack key={i + 5} tokens={{ padding: 1 }}>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {/* From and To fields in one row */}
                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* From */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="From"
                                              id={inputId}
                                              value={formMem.memFrom}
                                              onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memFrom', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* To */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="To"
                                              id={inputId}
                                              value={formMem.memTo}
                                              onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memTo', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>

                                    {/* Score and Rank fields in another row */}
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Score */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Score"
                                              id={inputId}
                                              value={formMem.memScore}
                                              onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memScore', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Rank */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Rank"
                                              id={inputId}
                                              value={formMem.memRank}
                                              onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memRank', i)}
                                            />
                                          </StackItem>
                                          {i === 0 ? (
                                            <StackItem>
                                              <AddSquare24Filled {...primaryIcon} onClick={() => handleAddClone(i + 1, 'memUtilisation')} />
                                            </StackItem>
                                          ) : (
                                            <StackItem>
                                              <Delete24Regular {...primaryIcon} onClick={() => handleRemoveClone(i, 'memUtilisation')} />
                                            </StackItem>
                                          )}
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                            </Stack>
                          )}
                          {processName.includes('Network') && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <StackItem>
                                <Body1>Process Network Utilisation</Body1>
                              </StackItem>
                              <StackItem>
                                {formDataBasic.netUtilisation.map((formNet: any, i: number) => (
                                  <Stack key={formNet.id} tokens={{ padding: 1 }}>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      {/* From and To fields in one row */}
                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* From */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="From"
                                              id={inputId}
                                              value={formNet.netFrom}
                                              onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netFrom', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* To */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="To"
                                              id={inputId}
                                              value={formNet.netTo}
                                              onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netTo', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    </Stack>

                                    {/* Score and Rank fields in another row */}
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Score */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Score"
                                              id={inputId}
                                              value={formNet.netScore}
                                              onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netScore', i)}
                                            />
                                          </StackItem>
                                        </Stack>
                                      </StackItem>

                                      <StackItem align="center">
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                          <StackItem>
                                            <Caption1>{/* Rank */}</Caption1>
                                          </StackItem>
                                          <StackItem>
                                            <Label size="small" htmlFor={inputId} />
                                            <Input
                                              placeholder="Rank"
                                              id={inputId}
                                              value={formNet.netRank}
                                              onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netRank', i)}
                                            />
                                          </StackItem>
                                          {i === 0 ? (
                                            <StackItem>
                                              <AddSquare24Filled {...primaryIcon} onClick={() => handleAddClone(i + 1, 'netUtilisation')} />
                                            </StackItem>
                                          ) : (
                                            <StackItem>
                                              <Delete24Regular {...primaryIcon} onClick={() => handleRemoveClone(i, 'netUtilisation')} />
                                            </StackItem>
                                          )}
                                        </Stack>
                                      </StackItem>
                                    </Stack>
                                  </Stack>
                                ))}
                              </StackItem>
                            </Stack>
                          )}
                        </Stack>
                      </FluentProvider>
                    </Stack>
                  </StackItem>
                </Stack>
              </>
              <Button style={{ display: 'none' }} ref={buttonref} type="submit">
                Validate
              </Button>
            </form>
          ),
          'Review Data': (
            <Stack>
              <Stack>
                <StackItem>
                  <FluentProvider theme={messageTheme}>
                    <Card appearance="subtle" size="small">
                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <StackItem>
                          <Info20Filled {...primaryIcon} />
                        </StackItem>
                        <StackItem>
                          <Caption1> Qorem ipsum dolor sit amet, consectetur adipiscing elit. </Caption1>
                        </StackItem>
                      </Stack>
                    </Card>
                  </FluentProvider>
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 45, padding: 10 }}>
                <StackItem>
                  <Body1>Application Category</Body1>
                </StackItem>
                <StackItem shrink grow>
                  {/* <MultiselectWithTags options={array} multi={false} tag={false} onSelect={handleChange} value={formDataBasic.Category}/> */}
                  {/* <Dropdown id="category" options={dropdownOptions} onOpenChange={handleChange}>
      </Dropdown> */}
                  <Input style={inputsizestyle} value={formDataBasic.Category} disabled />
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 98, padding: 10 }}>
                <StackItem>
                  <Body1>Subcategory</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} value={formDataBasic.Subcategory} disabled />
                </StackItem>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 64, padding: 10 }}>
                <StackItem>
                  <Body1>Application Name</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input style={inputsizestyle} onChange={handleDropdownChange} name="Application" value={formDataBasic.ApplicationName} disabled />
                </StackItem>
              </Stack>
              {/* <Stack horizontal tokens={{childrenGap:105, padding:10}}> 
        <StackItem >
        <Body1>Application Type</Body1>
        </StackItem>
        <StackItem shrink grow>
        <CheckBox label="Web" checked={formDataBasic.ApplicationType != 'Desktop' ? true : false} disabled onClick={() => { setWebEnable(true);setDesktopEnable(false);}}/>
        <CheckBox label="Desktop" checked={formDataBasic.ApplicationType == 'Desktop' ? true : false} disabled onClick={() => {setDesktopEnable(true);setWebEnable(false)}}/>

        </StackItem>
      </Stack> */}

              {/* {isWebEnable && ( */}
              <Stack horizontal tokens={{ childrenGap: 12, padding: 10 }}>
                <StackItem>
                  <Caption1>Url Patterns</Caption1>
                </StackItem>
                <StackItem>
                  {formDataBasic.UrlPatterns?.map((form: any, i: any) => (
                    <Stack key={i} tokens={{ padding: 1 }}>
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem align="center">
                          <Stack horizontal tokens={{ childrenGap: 11 }}>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Text></Text>
                            </StackItem>
                            <StackItem>
                              <Label size="small" htmlFor={inputId} />
                              <Input
                                disabled
                                id={inputId}
                                value={form.urlpatterns}
                                onChange={(e: any) => handleInput(e.target.value, 'UrlPatterns', 'urlpatterns', i)}
                              />
                            </StackItem>
                          </Stack>
                        </StackItem>
                      </Stack>
                    </Stack>
                  ))}
                </StackItem>
              </Stack>
              {/* )} */}
              {/* {isDesktopEnable && ( */}
              <Stack horizontal tokens={{ childrenGap: 52, padding: 10 }}>
                <StackItem>
                  <Body1>Application Binaries</Body1>
                </StackItem>
                <StackItem shrink grow>
                  <Input disabled name="applicationbinaries" value={formDataBasic.ApplicationBinaries} />
                </StackItem>
              </Stack>
              {/* )} */}

              <Stack>
                <StackItem>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <FluentProvider theme={webLightTheme}>
                      <Stack horizontal tokens={{ childrenGap: 25 }}>
                        {/* <StackItem> <Body1Strong> Application Scores </Body1Strong> </StackItem>
        <StackItem><Caption1>Reset</Caption1></StackItem> */}
                      </Stack>
                      <Stack tokens={{ childrenGap: 25 }}>
                        {processName.includes('CPU') && (
                          <Stack tokens={{ childrenGap: 10 }}>
                            <Stack></Stack>
                            <StackItem>
                              <Body1>Process Cpu Utilisation</Body1>
                            </StackItem>
                            <StackItem>
                              {formDataBasic.cpuUtilisation?.map((formCpu: any, i: any) => (
                                <Stack key={i} tokens={{ padding: 1 }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="From"
                                            value={formCpu.cpuFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuFrom', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="To"
                                            id={inputId}
                                            value={formCpu.cpuTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuTo', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Score"
                                            id={inputId}
                                            value={formCpu.cpuScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuScore', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Rank"
                                            id={inputId}
                                            value={formCpu.cpuRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'cpuUtilisation', 'cpuRank', i)}
                                          />
                                        </StackItem>
                                        {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'cpuUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'cpuUtilisation')}} /></StackItem>} */}
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>
                        )}

                        {processName.includes('Memory') && (
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Body1>Process Mem Utilisation</Body1>
                            </StackItem>

                            <StackItem>
                              {formDataBasic.memUtilisation.map((formMem: any, i: any) => (
                                <Stack key={i + 5} tokens={{ padding: 1 }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="From"
                                            id={inputId}
                                            value={formMem.memFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memFrom', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="To"
                                            id={inputId}
                                            value={formMem.memTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memTo', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Score"
                                            id={inputId}
                                            value={formMem.memScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memScore', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Rank"
                                            id={inputId}
                                            value={formMem.memRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'memUtilisation', 'memRank', i)}
                                          />
                                        </StackItem>
                                        {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'memUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'memUtilisation')}} /></StackItem>} */}
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>
                        )}

                        {processName.includes('Network') && (
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem>
                              <Body1>Process Network Utilisation</Body1>
                            </StackItem>
                            <StackItem>
                              {formDataBasic.netUtilisation.map((formNet: any, i: number) => (
                                <Stack key={formNet.id} tokens={{ padding: 1 }}>
                                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* From */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="From"
                                            id={inputId}
                                            value={formNet.netFrom}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netFrom', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>

                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* To */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="To"
                                            id={inputId}
                                            value={formNet.netTo}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netTo', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Score */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Score"
                                            id={inputId}
                                            value={formNet.netScore}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netScore', i)}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                    <StackItem align="center">
                                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <StackItem>
                                          <Caption1>{/* Rank */}</Caption1>
                                        </StackItem>
                                        <StackItem>
                                          <Label size="small" htmlFor={inputId} />
                                          <Input
                                            disabled
                                            placeholder="Rank"
                                            id={inputId}
                                            value={formNet.netRank}
                                            onChange={(e: any) => handleInput(e.target.value, 'netUtilisation', 'netRank', i)}
                                          />
                                        </StackItem>
                                        {/* {i== 0 ? <StackItem><AddSquare24Filled {...primaryIcon} onClick={()=>{handleAddClone(i+1, 'netUtilisation')}} /></StackItem> : <StackItem><Delete24Regular {...primaryIcon} onClick={()=>{handleRemoveClone(i, 'netUtilisation')}} /></StackItem>} */}
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Stack>
                              ))}
                            </StackItem>
                          </Stack>
                        )}
                      </Stack>
                    </FluentProvider>
                  </Stack>
                </StackItem>
              </Stack>
            </Stack>
          ),
        },
      });
  }, [overlayName, formDataBasic, cloneCpuForms, cloneMemForms, cloneNetForms, deviceList, selectedValue, categorySearchData]);

  useEffect(() => {
    !overlayOpen && setOverlayName('');
    console.log(overlayName);
  }, [overlayOpen]);

  useEffect(() => {
    contextValue.setSelectedRowIdInTable([]);
  }, []);

  useEffect(() => {
    console.log('main Table=', contextValue.selectedRowIdInTable);
    console.log('dweugfbihsaxifhewi agyfheiue', contextValue.selectedRowIdInTable.length);

    console.log('sub Table=', contextValue.selectedRowIdInSubTable);
    contextValue.selectedRowIdInTable.length > 0 ? setDeleteDisabled(false) : setDeleteDisabled(true);
  }, [contextValue.selectedRowIdInTable]);

  const userActivity = (action: String, activity: String, status: any = 'success') => {
    setUserActivityData({
      pageName: 'App monitor',
      moduleName: 'Apps',
      action: action,
      time: Math.floor(Date.now() / 1000),
      activity: activity,
      status: status,
    });
    setTracker(true);
  };
  useEffect(() => {
    if (saveBtnClicked) {
      overlayName == 'addMonitoredApp' &&
        userActivity(
          'Add',
          `{"applicationname":"${formDataBasic.ApplicationName}","configuration":${JSON.stringify(formDataBasic)},"summary":"${
            formDataBasic.ApplicationName
          } app is added"}`,
        );
      overlayName == 'editMonitoredApp' &&
        userActivity(
          'Edit',
          `{"applicationname":"${formDataBasic.ApplicationName}","configuration":${JSON.stringify(formDataBasic)},"summary":"${
            formDataBasic.ApplicationName
          } app is edited"}`,
        );
    }
  }, [saveBtnClicked]);

  return (
    <>
      <FullScreen handle={handle} className="fullScreenBackgroundColor">
        <StackItem grow shrink>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <StackItem grow shrink>
              <Stack horizontalAlign="start">
                <StackItem grow shrink>
                  <Breadcrumbs pageName="Apps" subPageName="" subPageLink="#" />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem grow shrink>
              <Stack horizontalAlign="end">
                <StackItem grow shrink>
                  <Button
                    style={fullscreenIcon}
                    appearance="subtle"
                    size="small"
                    shape="square"
                    onClick={handleFullScreen}
                    title={isFullScreen ? 'Close Full Screen View' : 'Open Full Screen View'}
                    icon={isFullScreen ? <DismissSquare24Filled {...exitIcon} /> : <ArrowMaximize20Regular {...primaryIcon} />}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          {/* pages code starts here */}
          <Stack verticalAlign="center">
            <StackItem>{/* <Text> <Breadcrumbs pageName="Apps" /> </Text> */}</StackItem>
            <Stack tokens={{ childrenGap: 25 }} horizontal>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <FluentProvider theme={webLightTheme}>
                  <Title3> Apps | Apps Monitored </Title3>
                </FluentProvider>
              </StackItem>
              {/* <Button size="small" appearance="subtle"> <ArrowMaximize16Regular/> </Button> */}
            </Stack>
            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Text></Text>
              </StackItem>
              <StackItem>
                <Text></Text>
              </StackItem>
            </Stack>
            <FluentProvider theme={webLightTheme}>
              <Stack horizontal>
                {/* SPACING BETWEEN INLINE AND PAGE */}
                <SideMenu leftOpenSubMenu={leftOpenSubMenu} setleftOpenSubMenu={setleftOpenSubMenu} menuItems={menuItems} />
                <StackItem>
                  <StackItem>
                    <Text></Text>
                  </StackItem>
                  <Stack tokens={{ childrenGap: 1 }}>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                    <StackItem>
                      <Text></Text>
                    </StackItem>
                  </Stack>
                  <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <OverflowSetCustomExample overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} component={overFlowComponent} />
                      {tableData.length > 0 ? <Divider /> : <Divider style={overflowDivider} />}
                    </StackItem>
                    <StackItem>
                      <StackItem>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <SearchBox
                            onChange={(e: any) => {
                              setSearchVal(e.target.value);
                            }}
                            value={searchVal}
                            placeholder="Search"
                            size="small"
                            dismiss={null}
                            contentAfter={<DismissButton aria-label="Clear" />}
                          />
                          <StackItem>
                            <MenuDropdown
                              open={filterMenuOpen}
                              btn={
                                <MenuButton shape="circular" size="small" onClick={() => setFilterMenuOpen(true)}>
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <Filter20Filled {...primaryIcon} />
                                    <Body1> Filter </Body1>
                                  </Stack>
                                </MenuButton>
                              }
                              itemVal={
                                <>
                                  <Body1Strong> Add a Filter </Body1Strong>
                                  <Divider />
                                  <MenuList>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                      <StackItem>
                                        <Text></Text>
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Filter </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <DropdownComponent optionData={columns1} setDropDownValue={setDropDownValue1} dropDownvalue={dropDownValue1} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <StackItem>
                                        <Caption1> Value </Caption1>
                                      </StackItem>
                                      {/* <DropdownComponent optionData={columns}/> */}
                                      <Input size="small" onChange={inputChange} name="filter" value={dropDownValue2} />
                                      <StackItem>
                                        <Divider />
                                      </StackItem>
                                      <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <StackItem>
                                          <Button
                                            appearance="primary"
                                            onClick={() => {
                                              handleFilterArray();
                                              setFilterMenuOpen(false);
                                            }}
                                          >
                                            <Caption1> Apply </Caption1>
                                          </Button>
                                        </StackItem>
                                        <StackItem>
                                          <Button onClick={() => setFilterMenuOpen(false)}>
                                            <Caption1> Cancel </Caption1>
                                          </Button>
                                        </StackItem>
                                      </Stack>
                                    </Stack>
                                  </MenuList>
                                </>
                              }
                            />
                          </StackItem>
                          <StackItem>
                            {filterArray.length > 0 &&
                              filterArray.map((item, index) => (
                                <Button size="small" shape="circular">
                                  <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <StackItem> {item} </StackItem>
                                    <StackItem>
                                      <Stack tokens={{ childrenGap: 1 }}>
                                        <StackItem>
                                          <Text></Text>
                                        </StackItem>
                                        <StackItem>
                                          <Dismiss16Regular
                                            onClick={() => {
                                              handleCloseFilter(index);
                                              setFilterArray([]);
                                              setIsFilter(false);
                                              setDropDownValue2('');
                                              setResetDisabled(true);
                                            }}
                                          />
                                        </StackItem>
                                      </Stack>
                                    </StackItem>
                                  </Stack>
                                </Button>
                              ))}
                          </StackItem>
                          <StackItem
                            onClick={() => {
                              setFilterArray([]);
                              setIsFilter(false);
                              setDropDownValue2('');
                              setResetDisabled(true);
                            }}
                          >
                            <Button appearance="subtle" size="small" disabled={isResetDisabled}>
                              <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                  <FilterDismiss20Filled />
                                </StackItem>
                                <StackItem>
                                  <Caption1> Reset Filters </Caption1>
                                </StackItem>
                              </Stack>
                            </Button>
                          </StackItem>
                        </Stack>
                      </StackItem>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                        <StackItem>
                          <Text></Text>
                        </StackItem>
                      </Stack>
                      <Stack horizontal enableScopedSelectors horizontalAlign="space-between">
                        <StackItem>
                          <Caption1>
                            Showing {(currentPageNo - 1) * 50 + 1} to {currentPageNo * 50 > totalRecords ? totalRecords : currentPageNo * 50} of
                            {totalRecords} Records
                          </Caption1>
                        </StackItem>
                        <StackItem>
                          <Pagination totalPages={totalPages} currentPageNo={currentPageNo} setCurrentPageNo={setCurrentPageNo} />
                        </StackItem>
                      </Stack>
                    </StackItem>

                    {!saveBtnClicked && (
                      <App_Monitored_Func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        setDeleteDisabled={setDeleteDisabled}
                        deleteAppMonitored={deleteAppMonitored}
                        setDeleteAppMonitored={setDeleteAppMonitored}
                        // setOverlayOpen={setOverlayOpen}
                        setOverlayOpen={setOverlayOpen}
                        setFormData={setFormDataBasic}
                        formdata={formDataBasic}
                        overlayName={overlayName}
                        saveBtnClicked={saveBtnClicked}
                        setsaveBtnClicked={setSaveBtnClicked}
                        isRefresh={isRefresh}
                        setIsRefresh={setIsRefresh}
                        setRefreshToast={setRefreshToast}
                        setExportDetails={setExportDetails}
                        deviceName={deviceName}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}
                    {tableData.length > 0 && <DataTable tableData={tableData} columns={columns}></DataTable>}

                    {saveBtnClicked && overlayName != '' && (
                      <App_Monitored_Func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        rowID={rowID}
                        setDeleteDisabled={setDeleteDisabled}
                        deleteAppMonitored={deleteAppMonitored}
                        setDeleteAppMonitored={setDeleteAppMonitored}
                        setOverlayOpen={setOverlayOpen}
                        overlayName={overlayName}
                        setFormData={setFormDataBasic}
                        formdata={formDataBasic}
                        setIsToast={setIsToast}
                        saveBtnClicked={saveBtnClicked}
                        setsaveBtnClicked={setSaveBtnClicked}
                        isRefresh={isRefresh}
                        setIsRefresh={setIsRefresh}
                        setRefreshToast={setRefreshToast}
                        deviceName={deviceName}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}
                    {!saveBtnClicked && overlayName == 'editMonitoredApp' && (
                      <App_Monitored_Func
                        data={final_data}
                        func={set_final_data}
                        searchVal={searchVal}
                        rowID={rowID}
                        pageNo={(currentPageNo - 1) * 50}
                        setTotalPages={setTotalPages}
                        setTotalRecords={setTotalRecords}
                        setDeleteDisabled={setDeleteDisabled}
                        deleteAppMonitored={deleteAppMonitored}
                        setDeleteAppMonitored={setDeleteAppMonitored}
                        setOverlayOpen={setOverlayOpen}
                        overlayName={overlayName}
                        setFormData={setFormDataBasic}
                        formdata={formDataBasic}
                        setIsToast={setIsToast}
                        saveBtnClicked={saveBtnClicked}
                        setsaveBtnClicked={setSaveBtnClicked}
                        isRefresh={isRefresh}
                        setIsRefresh={setIsRefresh}
                        setRefreshToast={setRefreshToast}
                        deviceName={deviceName}
                        isFilter={isfilter}
                        dropDownValue1={dropDownValue1}
                        setIsFilter={setIsFilter}
                        dropDownValue2={dropDownValue2}
                      />
                    )}

                    {tracker && <Activity_log_func data={userActivityData} setTracker={setTracker} type="insertLog" />}
                  </Stack>
                </StackItem>
              </Stack>
            </FluentProvider>
          </Stack>
          <OverLay
            isNavBtn={true}
            overlayopen={overlayOpen}
            setOverlayOpen={setOverlayOpen}
            saveBtnFunc={setSaveBtnClicked}
            overlayData={monitoredOverLayData}
            handleOutsideButton={handleOutsideButton}
          />
          {saveBtnClicked && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={isToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={
                !saveBtnClicked && overlayName == ''
                  ? 'Monitored App Deleted Successfully'
                  : overlayName == 'addMonitoredApp'
                  ? 'New Monitoring App Added Successfully'
                  : 'Edited App Monitor Successfully'
              }
              footer=""
            />
          )}
          {refreshToast && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={refreshToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={'Data Table Refreshed'}
              footer=""
            />
          )}
          {deleteAppMonitored && (
            <ToastComponent
              setmessagebar={setIsToast}
              isToastEnabled={isToast}
              toastIntent="success"
              position="top-end"
              title="Success"
              message={'Monitored App Deleted Successfully'}
              footer=""
            />
          )}
        </StackItem>
      </FullScreen>
    </>
  );
};
